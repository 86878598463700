(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditCustomFieldController', EditCustomFieldController);

    function EditCustomFieldController($scope, $rootScope, $stateParams, $mdMedia, $mdDialog, $window, $document, customfields, pipelines, sfDiscardDialog, model, utils) {

        $scope.field = null;

        $scope.itemClass = this.itemClass || $stateParams.itemClass;

        $scope.id = this.id || $stateParams.id;

        $scope.$mdMedia = $mdMedia;

        if (!$scope.id) {
            $scope.field = { options: [] };
            $scope.isNew = true;
        }

        $scope.newOption = { order: 0 };

        let currentType = null;

        $scope.defaultValues = [{
            value: false,
            copy: 'False'
        }, {
            value: true,
            copy: 'True'
        }];

        $scope.defaultTriValues = [{
            value: false,
            copy: 'False'
        }, {
            value: true,
            copy: 'True'
        },
        {
            value: null,
            copy: 'Unknown'
        }];

        get();

        function get() {

            customfields.getCustomFieldTypes().then(function (response) {

                $scope.types = response.data;
            });

            if (!$scope.isNew) {
                return customfields.get($scope.id, $scope.itemClass).then(function (response) {

                    $scope.field = response.data;
                    currentType = angular.copy($scope.field.type);

                    if ($scope.field.options) {
                        $scope.newOption.order = $scope.field.options.length;
                    }

                    if ($scope.field.type.type === 'date') {
                        $scope.field.min_date = utils.UTCDateStringToLocalDateObject($scope.field.min_date);
                        $scope.field.max_date = utils.UTCDateStringToLocalDateObject($scope.field.max_date);
                    }

                    if ($scope.itemClass === 'opportunity') {
                        return getPipelines();
                    }
                });
            }

            if ($scope.itemClass === 'opportunity') {
                return getPipelines();
            }
        }

        function getPipelines() {

            return pipelines.get().then(function (response) {

                $scope.pipelines = response.data;
                const currentPipelineId = store.get('current_pipeline_' + model.me.id);
                if (currentPipelineId) {
                    $scope.field.pipeline = $scope.pipelines.find(function (pipeline) {

                        return pipeline.id === currentPipelineId;
                    });
                }

                if (!$scope.field.pipeline || !$scope.field.pipeline.id) {
                    $scope.field.pipeline = $scope.pipelines[0];
                }
            });
        }

        $scope.save = function () {

            if (['select', 'multiselect'].includes($scope.field.type.type)) {
                let options = null;
                if ($scope.field.options) {
                    options = $scope.field.options.filter(function (option) {

                        return option.name && option.name !== '' && !option.archived;
                    });
                }

                if (!options || options.length === 0) {
                    $scope.customFieldForm.type.$setValidity('noOptions', false);
                    return;
                }
                else {
                    $scope.customFieldForm.type.$setValidity('noOptions', true);
                }
            }

            if (!$scope.customFieldForm.$valid) {
                return;
            }

            const field = angular.copy($scope.field);

            if (field.type.type === 'date') {
                field.min_date = utils.localDateObjectToUTCDateObject(field.min_date);
                field.max_date = utils.localDateObjectToUTCDateObject(field.max_date);
            }

            delete field.api_field;

            if (field.options) {
                field.options = field.options.filter(function (option) {

                    return option.name;
                });
            }

            field.type = field.type.id;

            if ($scope.isNew) {
                switch ($scope.itemClass) {
                    case 'opportunity':

                        if (field.pipeline) {
                            field.pipeline = field.pipeline.id;
                        }

                        return customfields.createOpportunityField(field).then(close).catch(close);
                    case 'account':
                        delete field.pipeline;
                        return customfields.createAccountField(field).then(close).catch(close);
                    case 'contact':
                        delete field.pipeline;
                        return customfields.createContactField(field).then(close).catch(close);
                    default:
                        return;
                }
            }

            // When updating, we don't want to change the default value of a field or the type
            delete field.default_boolean_value;
            delete field.type;
            switch ($scope.itemClass) {
                case 'opportunity':

                    if (field.pipeline) {
                        field.pipeline = field.pipeline.id;
                    }

                    return customfields.updateOpportunityField(field).then(close).catch(close);
                case 'account':
                    delete field.pipeline;
                    return customfields.updateAccountField(field).then(close).catch(close);
                case 'contact':
                    delete field.pipeline;
                    return customfields.updateContactField(field).then(close).catch(close);
            }
        };

        $scope.back = function () {

            if ($scope.customFieldForm.$dirty) {
                return sfDiscardDialog.show($scope.isNew, $scope.itemClass + ' field').then(close);
            }

            return close();
        };

        $scope.addOption = function () {

            if (!$scope.field.options) {
                $scope.field.options = [];
            }

            $scope.field.options.push({ name: '', order: $scope.field.options.length });
        };

        $scope.disableOrEnableField = function () {

            $scope.field.enabled = !$scope.field.enabled;
        };

        function close() {

            $rootScope.$broadcast('refreshData');

            return $mdDialog.hide(false);
        }

        $scope.$watch('field.type', function (newValue, oldValue) {

            if (currentType && newValue && newValue.id !== currentType.id) {

                const confirm = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .textContent('All your data stored in this field might get lost. Are you sure?')
                    .ok('Yes')
                    .cancel('No');

                return $mdDialog.show(confirm).then(function () {

                    currentType = angular.copy(newValue);
                }, function () {

                    $scope.field.type = oldValue;
                });
            }

            if ($scope.field && $scope.field.type && ['tags', 'multiselect', 'select', 'autocomplete'].includes($scope.field.type.type)) {
                if (!$scope.field.options || $scope.field.options.length === 0) {
                    $scope.addOption();
                }
            }
        });

        $scope.$watch('field.order_alphabetically', function (newValue) {

            if (!newValue) {
                return;
            }

            // No need to fix the actual order property of the options, this will be done server-side
            // We just want to order the options in the view
            $scope.field.options.sort(function (optionA, optionB) {

                const textA = optionA.name.toUpperCase();
                const textB = optionB.name.toUpperCase();
                return (textA < textB) ? -1 : ((textA > textB) ? 1 : 0);
            });
        });

        $scope.deleteField = function () {

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .textContent('Are you sure you want to delete this customfield?')
                .ok('Yes')
                .cancel('No');

            return $mdDialog.show(confirm).then(function () {

                return customfields.delete($scope.field.id, $scope.itemClass).then(close);
            });
        };

        $scope.setReportSortOptions = function (containerId) {

            return {
                containment: ('#' + containerId),
                // The ng-sortable library doesn't work well in dialog views.
                // The drag item wouldn't be positioned to the mouse pointer
                containerPositioning: 'relative',
                longTouch: true,
                orderChanged: function () {

                    $scope.field.options.forEach(function (option, index) {

                        option.order = index + 1;
                    });
                },
                dragMove: function (itemPosition, containment, eventObj) {
                    if (eventObj) {
                        const targetY = eventObj.pageY - ($window.pageYOffset || $document[0].documentElement.scrollTop);
                        const scrollContainer = $document[0].querySelector('#scroll-container');
                        const scrollSpeed = 10;
                        // Would be better to use the actual top & bottom of the dragged item here but you don't have access to it
                        const scrollRange = 100;
                        if (targetY - scrollRange < scrollContainer.getBoundingClientRect().top) {
                            scrollContainer.scrollTop = scrollContainer.scrollTop - scrollSpeed;
                        }
                        else if (targetY + scrollRange > scrollContainer.getBoundingClientRect().bottom) {
                            scrollContainer.scrollTop = scrollContainer.scrollTop + scrollSpeed;
                        }
                    }
                }
            };
        };
    }
})();
