<form #verificationCodeForm="ngForm" id="verificationCodeForm" name="verificationCodeForm">
    <sfx-toolbar dialogToolbar (closeEvent)="close()">Enable two-factor authentication</sfx-toolbar>
    <div mat-dialog-content class="dialog-content">
        <p>Scan the image below or enter the key into the two-factor authentication app on your phone</p>
        <p>
            <img [src]="data.qrUrl">
        </p>
        <p class="secret">
            {{ data.secret }}
            <button mat-icon-button disableRipple [cdkCopyToClipboard]="data.secret">
                <mat-icon fontSet="salesflare" fontIcon="sf-icon-clipboard" class="icon-20 icon text-black"></mat-icon>
            </button>
        </p>
        <p>
            <mat-form-field>
                <mat-label>Enter verification code generated by your app</mat-label>
                <input matInput name="verificationCode" [(ngModel)]="verificationCode">
                <mat-error *ngIf="verificationCodeForm.controls.verificationCode.hasError('invalidCode')">
                    The verification code was incorrect
                </mat-error>
            </mat-form-field>
        </p>
    </div>
    <mat-dialog-actions align="end" class="primary">
        <button mat-button type="submit" (click)="enable()">Enable</button>
    </mat-dialog-actions>
</form>
