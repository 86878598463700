/* eslint-disable camelcase */
/* eslint-disable angular/timeout-service */
import { Component, Inject, OnInit } from '@angular/core';
import { NotificationService } from '@core/services/notifications.service';

import { DatePipe } from '@angular/common';

import { isEqual } from 'lodash';
import { BillingOverviewModel } from '@shared/models/billing.model';
import {
    DialogResponse,
    SfxDialog
} from '@shared/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
    EditBillingFrequencyContentComponent
} from '@feature/settings/components/billing-usage/dialogs/edit-billing-frequency-content.component';
import { from, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

interface SCAAResponse {
    data: {
        client_secret: string;
        status: string;
        type: string;
    }
}

interface BillingOverview {

    htmlEncodedBillingCurrency: string;
    subscription_renewal_date: any;
    next_charge: {
        date: any,
        amount: number
    },

    billing_email: string;
    billing_credits: number;
    billing_frequency: any;
    billing_currency: string;
    billing_amount: number;
    subscription_quantity: string;

    vat: string;

    plan_name: string;
    plan: string;
    credit_reset_date: any;
    credit_quota: string;
    credit_usage: any;

    applied_discount: string;
    applied_discount_type: string;

    historical_team_credit_usage: number;
    current_month_per_user_credit_usage: number;

    failed_payment_attempts: number;
    cancel_at_period_end: any;

    card_brand: any;
    card_last4: any;
}
@Component({
    selector: 'sfx-billing-usage-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss']
})
export class BillingUsageOverviewComponent implements OnInit {

    // Can't find any reference to this anywhere except for template - possibly redundant, but don't want to break anything.
    showPlans = false;

    doneLoading = false;
    showChargeText = false;
    showSCAButton = false;
    disableSCAButton = false;
    hasCreditPackage = false;

    subscribeText: string | null = null;
    payingHtmlText: string | null = null;
    discountText: string | null = null;

    restrictedReason: string | null = null;

    billingOverview: BillingOverview | null = null;

    SCAData?: any;

    constructor(
        private datePipe: DatePipe,
        private matDialog: MatDialog,
        private notificationService: NotificationService,
        @Inject('authenticationService') public authenticationService: any,
        @Inject('billingService') public billingService: any,
        @Inject('cfpLoadingBar') public cfpLoadingBar: any,
        @Inject('exceptionHandler') public exceptionHandler: any,
        @Inject('mdDialogService') public mdDialog: any,
        @Inject('modelService') public modelService: any,
        @Inject('planService') public planService: any,
        @Inject('rootScope') public rootScope: any,
        @Inject('stateParams') private stateParams: any,
        @Inject('stateService') private stateService: any,
        @Inject('statusBarService') private statusBarService: any,
        @Inject('stripeService') private stripeService: any,
        @Inject('utilsService') private utils: any,
        @Inject('windowService') private windowService: any
    ) {}

    public ngOnInit(): void {

        if (this.rootScope.history[this.rootScope.history.length - 1].fromState.name.includes('billingSettings.')) {
            this.rootScope.history.pop();
        }

        this.hasCreditPackage = this.modelService.me.team.credit_package;

        if (this.stateParams.subscribedFromCheckout) {

            setTimeout(() => {

                return this.mdDialog.show(this.mdDialog.referAFriendDialog());
            }, 500);
        }

        if (this.modelService.me.team.subscribed && this.modelService.me.team.payment_type === 'stripe') {

            this.billingService.get().then(this._billingResponseHandler.bind(this), null, this._billingResponseHandler.bind(this));
        }
        else if (this.modelService.me.team.payment_type === 'samsung') {
            // We don't need to set/get anything specific for Samsung AppStack teams, handled in the view
        }
        else if (this.modelService.me.team.payment_type === 'free') {

            this.subscribeText = 'Your team has free access to Salesflare!';
        }
        else if (this.modelService.me.team.discount_code && this.modelService.me.team.discount_code.lastIndexOf('SUMO', 0) === 0) {

            this.subscribeText = 'You\'re currently on a lifetime AppSumo subscription';

            if (this.modelService.me.restricted) {
                this.subscribeText += ', which was disabled because payments for additional team members (at 50% off) kept failing. Please resubscribe below.';
            }
            else {

                if (this.modelService.me.team.free_users > 0) {

                    this.subscribeText += ' with ' + (this.modelService.me.team.free_users + 1) + ' free users';
                }

                this.subscribeText += '. Additional team members can be added at 50% off.';
            }
        }
        else {
            this.subscribeText = 'Your team is not currently on an active subscription.';
        }
    }

    public showCreditUsage() {

        const options = {
            historical_team_credit_usage: this.billingOverview?.historical_team_credit_usage,
            current_month_per_user_credit_usage: this.billingOverview?.current_month_per_user_credit_usage
        };

        return this.mdDialog.show(this.mdDialog.viewCreditUsageDialog(options));
        // Show 'viewCreditUsageDialog'.
    }

    private _billingResponseHandler(response: any) {


        if (isEqual(response.data, {})) {
            this.billingOverview = null;
            this.doneLoading = true;
            return;
        }

        this.restrictedReason = this.modelService.me.restricted_reason;

        // Compose the billingOverview object
        this.billingOverview = response.data as BillingOverview;

        this.billingOverview.htmlEncodedBillingCurrency = response.data.billing_currency === 'EUR' ? '&#128;' : '&#36;';
        this.billingOverview.subscription_renewal_date = this.datePipe.transform(response.data.subscription_renewal_date, 'MMMM d, y');
        this.billingOverview.next_charge.date = this.datePipe.transform(response.data.next_charge.date, 'MMMM d, y');
        this.billingOverview.billing_credits = response.data.billing_credits >= 0 ? 0 : response.data.billing_credits / -100;
        this.billingOverview.plan_name = response.data.plan_name;
        this.billingOverview.plan = response.data.plan;
        this.billingOverview.credit_reset_date = this.datePipe.transform(response.data.credit_reset_date, 'MMMM d, y');
        this.billingOverview.credit_quota = response.data.plan === 5 ? 'Unlimited' : this.billingOverview.credit_quota;

        this.payingHtmlText = 'You currently have <b>' + response.data.enabled_user_count + (response.data.enabled_user_count === 1 ? ' user' : ' users') + '</b> on your team';
        if (this.billingOverview.applied_discount && this.billingOverview.applied_discount_type === 'threeForOne') {
            this.payingHtmlText += '. <b>You have an active 3 for 1 discount until ' + this.datePipe.transform(this.billingOverview.subscription_renewal_date, 'MMMM d, y') + '</b>';
            this.discountText = 'You\'re on a discounted <b>3 for 1</b> plan. You don\'t pay for the 2nd and 3rd user during the first year on the annual plan.';
        }
        else if (this.billingOverview.applied_discount && this.billingOverview.applied_discount.lastIndexOf('SUMO', 0) === 0 && this.billingOverview.applied_discount_type === 'firstFreeThen50Off') {
            this.payingHtmlText += '. <b>Thanks to AppSumo, one user is free.</b> You are currently paying for the <b>remaining ' + (response.data.enabled_user_count - 1) + ' at 50% off.</b>';
            this.discountText = 'You\'re on a discounted <b>AppSumo</b> plan: the first user is free, others are 50% off.';
        }
        else if (this.billingOverview.applied_discount_type === 'stripeDiscount' && response.data.stripe_discount) {
            const stripeDiscount = {
                duration: response.data.stripe_discount.duration,
                percentOff: response.data.stripe_discount.percent_off,
                end: this.datePipe.transform(response.data.stripe_discount.end, 'MMMM d, y'),
                description: response.data.stripe_discount.description
            };

            switch (stripeDiscount.duration) {
                case 'forever':
                    this.payingHtmlText += '. <b>You have a lifetime discount of ' + stripeDiscount.percentOff + '%</b>';
                    break;
                case 'repeating':
                    if (stripeDiscount.description) {
                        this.payingHtmlText += '. <b>You have an active discount of ' + stripeDiscount.description + ' of the Pro plan until ' + stripeDiscount.end + '</b>';
                        break;
                    }

                    this.payingHtmlText += '. <b>You have an active discount of ' + stripeDiscount.percentOff + '% until ' + stripeDiscount.end + '</b>';
                    break;
            }
        }

        this.payingHtmlText += '.';

        this.showChargeText = response.data.next_charge.date !== response.data.subscription_renewal_date && response.data.next_charge.amount !== response.data.billing_amount && response.data.next_charge.amount > 0;
        this.showSCAButton = !!response.data.outstanding_invoice_requires_sca;
        this.SCAData = response.data.outstanding_invoice_requires_sca;

        if (this.showSCAButton) {
            this.statusBarService.show({
                type: 'sca'
            });
        }
        else {
            this.statusBarService.hide('sca');
        }

        this.doneLoading = true;
    }

    public updateBillingFrequency() {

        const currentPlanPricing = this.planService.get()[this.utils.getPlanNameBySalesflarePlanId(this.modelService.me.team.plan)].price;
        const pricingSwitchOptions: BillingOverviewModel = {
            discounted: this.billingOverview?.applied_discount && this.billingOverview?.applied_discount.toLowerCase().includes('sumo'),
            htmlEncodedBillingCurrency: this.billingOverview?.htmlEncodedBillingCurrency,
            quantity: this.billingOverview?.subscription_quantity,
            left: {
                value: 'annually',
                title: 'Annually',
                pricePerMonth: currentPlanPricing.annually.pricePerMonth,
                pricePerBillingCycle: currentPlanPricing.annually.pricePerBillingCycle,
                discountedPricePerMonth: currentPlanPricing.annually.pricePerMonth / 2,
                discountedPricePerBillingCycle: currentPlanPricing.annually.pricePerBillingCycle / 2
            },
            right: {
                value: 'monthly',
                title: 'Monthly',
                pricePerMonth: currentPlanPricing.monthly.pricePerMonth,
                pricePerBillingCycle: currentPlanPricing.monthly.pricePerBillingCycle,
                discountedPricePerMonth: currentPlanPricing.monthly.pricePerMonth / 2,
                discountedPricePerBillingCycle: currentPlanPricing.monthly.pricePerBillingCycle / 2
            }
        };

        const editBillingFrequencyDialog = this.matDialog.open(SfxDialog, {
            panelClass: 'sfx-dialog-container-sm',
            data: {
                close: 'Cancel',
                confirm: 'Update',
                content: EditBillingFrequencyContentComponent,
                contentStyle: 'padding: 0 16px; overflow: hidden;',
                context: {
                    selectedValue: this.billingOverview?.billing_frequency,
                    pricingSwitchOptions,
                    editPlan: false
                }
            },
            autoFocus: false
        });

        editBillingFrequencyDialog.afterClosed().pipe(
            mergeMap((result: DialogResponse<EditBillingFrequencyContentComponent>) => {

                if (result.confirmed) {

                    const selectedValue = result.state.selectedValue;

                    if (!selectedValue || selectedValue === this.billingOverview?.billing_frequency) {
                        return of(null);
                    }
                }

                return of(result);
            }),
            mergeMap((result: DialogResponse<EditBillingFrequencyContentComponent> | null) => {

                if (!result || !result.confirmed) {
                    return of(null);
                }

                // TODO: Support Enterprise when available on Stripe
                const planName = this.modelService.me.team.plan === 3 ? 'GROWTH' : 'PRO';
                const stripePlanId = this.utils.getStripePlanId(planName, result.state.selectedValue, this.billingOverview?.billing_currency);

                // Show loading as billing update + SCA might take some time
                this.cfpLoadingBar.start();

                return from(this.billingService.update({ plan: stripePlanId }, { ignoreLoadingBar: true })
                    .then((response: any) => {

                        return this.handleSCAAndReFetchData(response, 'Your billing frequency has been updated.');
                    })
                    .catch((err: any) => {

                        this.cfpLoadingBar.complete();

                        if (err && err.data && err.data.message) {
                            this.notificationService.error(err.data.message);
                        }
                    }));
            })
        ).subscribe(() => {});
    }

    public updatePlan() {

        return this.stateService.go('plans', { fromBilling: true });
    }

    public askBillingQuestion(): void {

        this.windowService.Intercom('showNewMessage', '');
    }

    public cancelBilling(): void {

        this.windowService.Intercom('showNewMessage', 'Hello team Salesflare!\n\nI would like to cancel my subscription.\n\nThe feedback I\'d like to share about my experience is:\n\n\n\nThanks!');
    }

    public authorizePayment(): void {


        this.disableSCAButton = true;

        // Show loading as billing update + SCA might take some time
        this.cfpLoadingBar.start();

        return this.handleSCAAndReFetchData({ data: this.SCAData }, 'Payment was successfully authorized.')
            .then(() => {

                this.disableSCAButton = false;
            })
            .catch((err: any) => {

                this.disableSCAButton = false;
                return this.exceptionHandler(err);
            });
    }

    public updateCard(): void {

        return this.mdDialog.show({
            clickOutsideToClose: true,
            controller: 'EditCardDialogController',
            templateUrl: 'partials/editcarddialog.html',
            locals: {
                vat: this.billingOverview?.vat
            },
            bindToController: true
        }).then((data: any) => {

            // Show loading as billing update + SCA might take some time
            this.cfpLoadingBar.start();

            // IgnoreLoadingBar is used, 'cause we already started it above. We don't want the http call to complete the loading circle
            return this.billingService.update({ source: data.token.id, vat: data.vat }, { ignoreLoadingBar: true })
                .then((response: any) => {

                    return this.handleSCAAndReFetchData(response, 'Your card information has been updated. This card will be used for future charges.');
                })
                .catch((err: any) => {

                    this.cfpLoadingBar.complete();

                    if (err && err.data && err.data.message) {
                        this.notificationService.error(err.data.message);
                    }
                });
        });
    }

    /**
     *
     * Best to call `cfpLoadingBar.start` before you start a billing operation as the update + SCA might take a while
     * The z-index of the SCA dialog is the biggest it can be so it will be shown over the spinner
     *
     * @param {Object} response
     * @param {Object} response.data
     * @param {String} response.data.client_secret
     * @param {String} response.data.status
     * @param {String} successMessage
     * @returns {Promise}
     */
    public handleSCAAndReFetchData(response: SCAAResponse, successMessage: string): any {

        // `Requires action` basically means we need to do a SCA flow
        if (response.data.status === 'requires_action') {
            return this.stripeService.stripe[response.data.type === 'setup_intent' ? 'handleCardSetup' : 'handleCardPayment'](response.data.client_secret).then((result: any) => {

                // Hide loading as we are done
                this.cfpLoadingBar.complete();

                if (result.error) {

                    let message;
                    switch (result.error.code) {
                        case 'payment_intent_authentication_failure':
                            message = 'Please update your card details to complete the payment.';
                            break;
                        default:
                            message = result.error.message;
                    }

                    this.notificationService.error(message);

                    // Log the error
                    const formattedError: any = new Error(result.error.code);
                    formattedError.data = result.error;
                    this.exceptionHandler(formattedError);
                }
                else {
                    this.notificationService.success(successMessage);
                }

                // We can't rely on Stripe web hooks always arriving to the server faster than when we call the server to re-fetch data
                // That's why we want to explicitly sync data with Stripe first, before re-fetching.
                return this.billingService.syncStripeData()
                    .then((syncResponse: any) => {

                        if (syncResponse.error) {
                            // Log the error
                            const formattedError: any = new Error(result.error.code);
                            formattedError.data = result.error;
                            this.exceptionHandler(formattedError);
                        }

                        const getBilling = () => {

                            return this.billingService.get().then((billingResponse: any) => {

                                return this._billingResponseHandler(billingResponse);
                            });
                        };

                        // If errored still re-fetch data as the plan will have updated but will most likely be in a `payment required` state
                        return Promise.all([
                            // IgnoreLoadingBar is used, 'cause we already started it above. We don't want the http call to complete the loading circle
                            this.authenticationService.fetchMe({ ignoreLoadingBar: true }),
                            getBilling()
                        ]);
                    });
            });
        }

        // Hide loading as we are done
        this.cfpLoadingBar.complete();

        this.notificationService.success(successMessage);

        const getBilling = () => {

            return this.billingService.get().then((billingResponse: any) => {

                return this._billingResponseHandler(billingResponse);
            });
        };

        return Promise.all([
            // IgnoreLoadingBar is used, 'cause we already started it above. We don't want the http call to complete the loading circle
            this.authenticationService.fetchMe({ ignoreLoadingBar: true }),
            getBilling()
        ]);
    }
}
