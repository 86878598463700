(function () {
    'use strict';

    angular
        .module('salesflare.components.planflag', [])
        .component('sfPlanFlag', {
            controller: PlanFlagController,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/planflag/planflag.html',
            bindings: {
                plan: '<',
                flag: '<',
                inUpgradeDialog: '<'
            }
        });

    function PlanFlagController(sfUpgradeDialog) {

        const vm = this;
        vm.tooltipDescription = '';

        const tooltipDescriptions = {
            multiStepWorkflows: 'Want to send multi-step email workflows?',
            permissions: 'Want to set permissions?',
            dashboards: 'Want to create custom reports and dashboards?'
        };

        vm.$onInit = function () {

            if (!vm.inUpgradeDialog) {
                vm.tooltipDescription = tooltipDescriptions[vm.flag];
            }

            vm.plan = 'Pro'; // Currently you can only upgrade to the Pro plan
        };

        vm.showUpgradeDialogToTrial = function ($event) {

            // Prevent ng-clicks on parent elements from happening
            $event.stopPropagation();
            if (!vm.inUpgradeDialog) {
                return sfUpgradeDialog.show(vm.flag || 'multiStepWorkflows');
            }
        };
    }
}());
