(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('DomainDialogController', DomainDialogController);

    function DomainDialogController($scope, $mdDialog, possibleDomains) {

        $scope.domains = possibleDomains;

        $scope.setDomain = function (selectedDomain) {

            return $mdDialog.hide(selectedDomain);
        };
    }
})();
