(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('referralsService', referralsService);

    function referralsService(sfHttp, config) {

        this.create = function (email) {

            return sfHttp.post(config.apiUrl + 'referrals', { email });
        };

        this.getReferees = (requestConfig, options) => {

            const url = config.apiUrl + 'referrals';

            const request = {
                params: {}
            };

            if (requestConfig.limit) {
                request.params.limit = requestConfig.limit;
            }

            if (requestConfig.offset) {
                request.params.offset = requestConfig.offset;
            }

            if (requestConfig.orderBy) {
                request.params.q.order_by = requestConfig.orderBy;
            }

            if (options && options.returnCountOnly) {
                return sfHttp.head(url, request);
            }

            return sfHttp.get(url, request);
        };

        this.delete = function (referralId) {

            return sfHttp.delete(config.apiUrl + 'referrals/' + referralId);
        };
    }
})();
