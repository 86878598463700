(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('TargetsController', TargetsController);

    function TargetsController($transitions, $scope, model, target, pipelines) {

        const today = new Date();
        $scope.currentYear = today.getFullYear();
        $scope.targets = [];
        $scope.years = [
            $scope.currentYear - 2,
            $scope.currentYear - 1,
            $scope.currentYear,
            $scope.currentYear + 1,
            $scope.currentYear + 2
        ];
        $scope.pipelines = null;
        $scope.currentPipeline = null;

        init().then(function () {

            $scope.$watch('currentYear', function (current, previous) {

                if ($scope.targets.length > 0) {
                    return save(previous, true).then(get);
                }

                return get();
            });

            $scope.$watch('currentPipeline', function (current, previous) {

                if ($scope.targets.length > 0) {
                    return save(previous, false).then(get);
                }

                return get();
            });
        });

        // If you use $scope $destroy event and sign out the bearer token isn't available anymore therefore we use a transition hook
        const removeStartTransitionHook = $transitions.onStart({}, (transition) => {

            const toState = transition.to();
            const fromState = transition.from();

            if (fromState.name === 'settings.targets') {
                removeStartTransitionHook();

                // This means the targets setting screen was open when logout was pressed, in this case we do not want to save since we will get an authorization missing error
                // Ideally, this unbindStateChangeStartHandler should be removed and saving targets should be done with a save button/on the fly (to be discussed)
                if (toState.name === 'login') {
                    return;
                }

                if ($scope.targets.length > 0) {
                    return save($scope.currentYear, true);
                }

                return get();
            }
        });

        function save(value, isYear) {

            const targetsToBeSaved = $scope.targets.map(function (targetObj) {

                return {
                    user: targetObj.id,
                    target: targetObj.target,
                    pipeline: isYear ? $scope.currentPipeline.id : value.id
                };
            });
            const yearToBeSaved = isYear ? value : $scope.currentYear;

            return target.createTargets(yearToBeSaved, targetsToBeSaved);
        }

        function get() {

            return target.getTargets($scope.currentYear, $scope.currentPipeline.id).then(function (response) {

                $scope.targets = response.data;
            });
        }

        function init() {

            return pipelines.get().then(function (response) {

                $scope.pipelines = response.data;
                const currentPipelineId = store.get('current_pipeline_' + model.me.id);

                if (currentPipelineId) {
                    $scope.currentPipeline = $scope.pipelines.find(function (pipeline) {
                        return pipeline.id === currentPipelineId;
                    });
                }

                if (!$scope.currentPipeline) {
                    $scope.currentPipeline = $scope.pipelines[0];
                }
            });
        }
    }
})();
