(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('group', groupService);

    function groupService(sfHttp, config) {

        this.get = function (id) {

            return sfHttp.get(config.apiUrl + 'groups/' + id);
        };

        this.update = function (id, group) {

            return sfHttp.put(config.apiUrl + 'groups/' + id, group);
        };

        this.create = function (group) {

            return sfHttp.post(config.apiUrl + 'groups', group);
        };

        this.delete = function (group) {

            return sfHttp.delete(config.apiUrl + 'groups/' + group.id);
        };

        this.addUser = function (group, user) {

            return sfHttp.post(config.apiUrl + 'groups/' + group.id + '/users', user);
        };

        this.deleteUser = function (group, user) {

            return sfHttp.delete(config.apiUrl + 'groups/' + group.id + '/users/' + user.id);
        };
    }
})();
