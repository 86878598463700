(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('tagsService', tagsService);

    function tagsService(sfHttp, config, filterService) {

        const baseUrl = `${config.apiUrl}tags`;

        /**
         *
         * @param {Object} options
         * @param {String} [options.search]
         * @param {Array.<Object>} [options.filterRules]
         * @param {Number} [options.limit]
         * @param {Number} [options.offset]
         * @param {String} [options.orderBy]
         * @param {Boolean} [options.returnCountOnly=false]
         * @param {Boolean} [options.includeCount=false]
         * @param {Boolean} [options.ignoreLoadingBar=false]
         * @returns {Promise}
         */
        this.get = (options) => {

            const request = {
                params: {
                    q: {
                        condition: 'AND',
                        rules: options.filterRules ? filterService.cleanAdvancedFilterForHttp(options.filterRules) : []
                    }
                }
            };

            if (options.search) {
                request.cache = false;
                request.ignoreLoadingBar = true;
                request.params.q.search = options.search;
            }

            if (options.limit) {
                request.params.limit = options.limit;
            }

            if (options.offset) {
                request.params.offset = options.offset;
            }

            if (options.orderBy) {
                request.params.order_by = request.params.order_by ? [...request.params.order_by, ...options.orderBy] : options.orderBy;
            }

            if (options.ignoreLoadingBar) {
                request.ignoreLoadingBar = true;
            }

            if (options.returnCountOnly) {
                return sfHttp.head(baseUrl, request);
            }

            if (options.includeCount) {
                request.headers = { 'x-result-count': 'true' };
            }

            return sfHttp.get(baseUrl, request);
        };

        /**
         *
         * @param {Object} tag
         * @param {String} [tag.id]
         * @returns {Promise}
         */
        this.getUsage = function (tag) {

            return sfHttp.get(`${config.apiUrl}tags/${tag.id}/usage`);
        };

        /**
         *
         * @param {Object} tag
         * @param {String} [tag.name]
         * @returns {Promise}
         */
        this.create = function (tag) {

            return sfHttp.post(config.apiUrl + 'tags', { name: tag.name });
        };

        /**
         *
         * @param {Object} tag
         * @param {String} [tag.id]
         * @param {String} [tag.name]
         * @returns {Promise}
         */
        this.update = function (tag) {

            return sfHttp.put(config.apiUrl + 'tags/' + tag.id, { name: tag.name });
        };

        /**
         *
         * @param {Object} tag
         * @param {String} [tag.id]
         * @returns {Promise}
         */
        this.delete = function (tag) {

            return sfHttp.delete(config.apiUrl + 'tags/' + tag.id);
        };

        /**
         *
         * @param {Object} tag
         * @param {String} [tag.id]
         * @param {Array<String>} tagsToMerge
         * @returns {Promise}
         */
        this.merge = function (tag, tagsToMerge) {

            return sfHttp.post(config.apiUrl + 'tags/merge', { tags: [tag, ...tagsToMerge] });
        };
    }
})();
