(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditTaskController', EditTaskController);

    function EditTaskController($rootScope, $scope, $mdMedia, $state, $mdDialog, $q, sfWalkthrough, accounts, account, model, tasks, users) {

        $scope.$mdMedia = $mdMedia;

        $scope.am_pm_notation = model.me.am_pm_notation;
        $scope.selectedAccount = null;
        $scope.task = {
            description: '',
            reminder_date: new Date(),
            account: null,
            assignees: []
        };

        $scope.task.reminder_date.setMinutes(0, 0);

        $scope.resetSelectedAccount = function (text) {

            $scope.selectedAccount = null;
            if (text) {
                $scope.task.account = {};
                $scope.task.account.name = text;
            }
            else {
                $scope.task.account = null;
            }
        };

        $scope.back = function () {

            if (!sfWalkthrough.isShowing()) {
                return close(false);
            }
        };

        function close(reloadTasksList) {

            if (angular.isUndefined(reloadTasksList)) {
                reloadTasksList = true;
            }

            if ($mdMedia('gt-sm')) {
                // Need this to reload the tasks lists
                if (reloadTasksList && ($state.current.name === 'tasks.today' || $state.current.name === 'tasks.upcoming')) {
                    tasks.addTask($scope.task);
                }

                return $mdDialog.hide(reloadTasksList);
            }

            return $rootScope.back();
        }

        $scope.save = function () {

            if (!check()) {
                return;
            }

            $scope.updating = true;

            // Copy so when we make changes to send to server they to do not alter the view model so when the call fails it just displays the form as it was
            const task = angular.copy($scope.task);

            if (!task.description) {
                return;
            }

            if (!task.reminder_date) {
                task.reminder_date = new Date();
            }

            if ($scope.task.assignees) {
                task.assignees = $scope.task.assignees.map(function (user) {

                    return user.id;
                });
            }

            if (task.assignees && task.assignees.length === 0) {
                delete task.assignees;
            }

            if (!$scope.task.account) {
                delete task.account;
                return createTask(task);
            }

            if ($scope.task.account && !$scope.task.account.owner) {
                return account.create($scope.task.account).then(function (response) {

                    task.account = response.data.id;

                    return createTask(task);
                });
            }
            else {
                task.account = task.account.id;
                return createTask(task);
            }
        };

        function createTask(task) {

            // If an account and assignees are available, check if assignees have access to the account
            if (task.account && $scope.task.assignees.length > 0) {
                return checkIfAssigneesInAccount(task, $scope.task.assignees, $scope.task.account).then(function () {

                    // Check if all dialogs have been returned
                    return tasks.create(task).then(function (response) {

                        $scope.task.id = response.data.id;
                        $scope.task.type = 'manual_task';

                        return close(true);
                    }).catch(function () {

                        $scope.updating = false;
                    });
                });
            }

            return tasks.create(task).then(function (response) {

                $scope.task.id = response.data.id;
                $scope.task.type = 'manual_task';

                return close(true);
            }).catch(function () {

                $scope.updating = false;
            });
        }

        function check() {

            if ($scope.taskForm.$invalid) {
                return false;
            }

            return true;
        }

        $scope.getAccount = function (filter) {

            return accounts.get(filter).then(accountsResponse, null, accountsResponse);
        };

        function accountsResponse(response) {

            return $q.resolve(response.data);
        }

        $scope.searchUsers = function (searchQuery) {

            function handler(response) {

                // eslint-disable-next-line no-shadow
                let users = response.data;
                if ($scope.task.assignees && $scope.task.assignees.length > 0) {
                    users = users.filter(function (user) {

                        return !$scope.task.assignees.some(function (assignee) {

                            return assignee.id === user.id;
                        });
                    });
                }

                return $q.resolve(users);
            }

            if (!searchQuery || searchQuery === '') {

                if ($scope.task.account && $scope.task.account.id) {
                    return account.getUsers($scope.task.account.id).then(handler);
                }

                return users.get(null, true).then(handler);
            }
            else {
                return users.get(searchQuery).then(handler);
            }
        };

        function checkIfAssigneesInAccount(updateTask, assignees, accountFull) {

            return account.getUsers(updateTask.account).then(function (response) {

                // eslint-disable-next-line no-shadow
                const users = response.data;
                const userIds = new Set(users.map(function (user) {

                    return user.id;
                }));
                const notPartOfAccount = updateTask.assignees.filter(function (assignee) {

                    return !userIds.has(assignee);
                });

                if (notPartOfAccount.length === 0) {
                    return $q.resolve();
                }

                const confirms = [];
                let newMemberId;
                for (const newMemberIndex in notPartOfAccount) {
                    newMemberId = notPartOfAccount[newMemberIndex];
                    const confirm = $mdDialog.confirm({ multiple: true })
                        .clickOutsideToClose(true)
                        .escapeToClose(true)
                        .textContent('Do you want to add ' + assignees[updateTask.assignees.indexOf(newMemberId)].name + ' to the ' + accountFull.name + ' team as well?' )
                        .ok('Yes')
                        .cancel('No');

                    confirms.push(confirm);
                }

                const firstDialog = confirms.pop();
                return $mdDialog.show(firstDialog).then(onConfirmDialogClosedConfirm, onCancelDialogClosedConfirm);

                // We need this array of dialogs to make sure all dialogs are closed before closing the edittask partial
                function onConfirmDialogClosedConfirm() {

                    onlyUpdateAccountUsers(assignees, newMemberId, updateTask);
                    if (confirms.length > 0) {
                        const dialog = confirms.pop();
                        return $mdDialog.show(dialog).then(onConfirmDialogClosedConfirm, onCancelDialogClosedConfirm);
                    }

                    return $q.resolve();
                }

                function onCancelDialogClosedConfirm() {

                    if (confirms.length > 0) {
                        const dialog = confirms.pop();
                        return $mdDialog.show(dialog).then(onConfirmDialogClosedConfirm, onCancelDialogClosedConfirm);
                    }

                    return $q.resolve();
                }
            });
        }

        function onlyUpdateAccountUsers(assignees, newMemberId, updateTask) {

            const usersToUpdate = assignees.filter(function (assignee) {

                if (assignee.id === newMemberId) {
                    assignee._dirty = true;
                }

                return assignee.id === newMemberId;
            });

            const accountId = updateTask.account.id ? updateTask.account.id : updateTask.account;

            if (angular.isNumber(accountId)) {
                return account.updateUsers(accountId, usersToUpdate);
            }
        }
    }
})();
