(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('FilesController', FilesController);

    function FilesController($rootScope, $scope, $stateParams, $window, $mdMedia, $mdDialog, $state, account, model, fileService, config, utils, events) {

        $scope.files = null;
        $scope.accountId = $stateParams.id;

        if ($rootScope.history[$rootScope.history.length - 1].fromState.name.startsWith('accounts.')) {
            $rootScope.history.pop();
        }

        if ($scope.account) {
            get();

            if (model.me.is_admin) {
                $scope.requestAccessText = 'Add yourself to the ' + $scope.account.name + ' team to see the files here.';
            }
            else {
                $scope.requestAccessText = 'To access the files of this account, ask your colleague(s) to add you to the team of the account.';
            }
        }
        else {
            $scope.$on('account:loaded', function (event, loadedAccount) {

                if (model.me.is_admin) {
                    $scope.requestAccessText = 'Add yourself to the ' + $scope.account.name + ' team to see the files here.';
                }
                else {
                    $scope.requestAccessText = 'To access the files of this account, ask your colleague(s) to add you to the team of the account.';
                }


                $scope.account = loadedAccount;
                return get();
            });
        }

        $scope.$on(events.account.updated, function () {

            return get();
        });

        $scope.$on('refreshData', function () {

            return get();
        });

        $scope.refreshData = function (fileAdded) {

            if (fileAdded) {
                return get(fileAdded);
            }

            return get();
        };

        $scope.gotToAccountInfo = function () {

            if ($mdMedia('gt-sm')) {
                return $scope.$parent.toggleRight();
            }

            return $state.go('accounts.account.info');
        };

        $scope.download = function (requestFile) {

            // Open in browser and let it handle the download
            if (requestFile.service_type) {
                return $window.open(config.apiUrl + 'files/' + requestFile.file_id, '_blank', 'noopener');
            }

            $window.open(requestFile.download_url, '_blank', 'noopener');
        };

        $scope.getFilesBefore = function getFilesBefore() {

            let before;

            if ($scope.files.length > 0) {
                before = $scope.files[$scope.files.length - 1].date;
            }

            return account.getFiles($stateParams.id, before).then(function (response) {

                if (!response.data || response.data.length === 0) {
                    $scope.allFilesLoaded = true;
                }

                $scope.files = [...$scope.files, ...response.data.filter(function (file) {

                    return !$scope.files.some(function (f) {

                        return f.file_id === file.file_id;
                    });
                })];
            });
        };

        $scope.isSameDay = function (dt1, dt2) {

            return utils.isSameDay(dt1, dt2);
        };

        function get(fileAdded) {

            if ($scope.account.part_of || ($scope.account.part_of && fileAdded)) {
                return account.getFiles($stateParams.id).then(filesResponse, null, filesResponse);
            }
        }

        function filesResponse(response) {

            $scope.files = response.data;
        }

        $scope.upload = function (file) {

            if (!file || file.$error) {
                return;
            }

            $scope.showDragOverlay = false;
            return account.uploadFile($stateParams.id, file).then( function (id) {

                if (!id) {
                    return;
                }

                return get();
            });
        };

        $scope.drag = function (isDragging) {

            return $scope.$apply(function () {

                $scope.showDragOverlay = isDragging;
            });
        };

        $scope.showDeleteFileDialog = function (file) {

            const confirm = $mdDialog.confirm()
                .clickOutsideToClose(true)
                .textContent('Are you sure you want to delete this file?')
                .ok('Yes')
                .cancel('No');

            return $mdDialog.show(confirm).then(function () {

                return fileService.deleteFile(file.id).then(function () {

                    $rootScope.$broadcast(events.account.updated);

                    if ($mdMedia('gt-sm')) {
                        return $mdDialog.hide(true);
                    }
                });
            });
        };
    }
})();
