(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('setupStepsService', setupStepsService);

    function setupStepsService(sfHttp, config) {

        this.get = function get() {

            return sfHttp.get((config.apiUrl + 'setupsteps'));
        };
    }
})();
