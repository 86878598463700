<sfx-toolbar disableBack>
    <div data-layout="row" data-layout-align="start center" data-layout-gap="16px">
        <mat-icon track-id="close" fontSet="salesflare" fontIcon="sf-icon-close" class="button" (click)="back()"></mat-icon>
        Map your {{ importState?.type }} data to the Salesflare system
    </div>
</sfx-toolbar>

<mat-divider *ngIf="media.isActive('gt-sm')">>

</mat-divider>

<sfx-content class="import-map" data-layout="column" data-layout-align="start center">

    <div class="infobox">Select Salesflare fields from the dropdown menus to import those columns of data</div>

    <table class="import-table" mat-table [dataSource]="datasource">

        <ng-container matColumnDef="file">
            <th mat-header-cell *matHeaderCellDef class="import-table-header import-table-row-label">File headers:</th>
            <td mat-cell *matCellDef="let sample; let idx = index" class="import-table-field-sample import-table-row-label">Sample {{ idx + 1}}</td>
            <td mat-footer-cell *matFooterCellDef class="import-table-field-sample import-table-row-label">...</td>
        </ng-container>

        <ng-container matColumnDef="file_description">
            <th mat-header-cell *matHeaderCellDef class="import-table-field-select import-table-row-label">Salesflare field:</th>
        </ng-container>

        <ng-container *ngFor="let header of headers; let i = index;">
            <ng-container [matColumnDef]="header + '_description'">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-select [(ngModel)]="chosenHeaders[i]" [aria-label]="'select-' + header" (ngModelChange)="updateMapping()"
                                [compareWith]="compareHeaders" placeholder="Select Salesflare field" class="select-header" panelWidth=""
                    >
                        <mat-option>
                            <span class="default-column">
                                Select Salesflare field
                            </span>
                        </mat-option>
                        <mat-option
                                *ngFor="let heading of sfHeader; let idx = index;" [value]="heading"
                        >
                            {{ heading.name }}{{ heading.required? '*': ''}}
                        </mat-option>
                    </mat-select>
                </th>
            </ng-container>
            <ng-container [matColumnDef]="header">
                <th mat-header-cell *matHeaderCellDef class="import-table-header">{{ header }}</th>
                <td mat-cell *matCellDef="let sample" class="import-table-field-sample import-table-field-sample-value">{{ sample[header] }}</td>
                <td mat-footer-cell *matFooterCellDef class="import-table-field-sample">...</td>
            </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-header-row *matHeaderRowDef="secondaryHeaders"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>

    <div>
        <p *ngIf="showCreateAccountCheckbox" matTooltip="Map ‘account name’ to link contacts to accounts, optionally creating accounts if they don’t exist.">
            <mat-checkbox [disabled]="!enableCreateAccountCheckbox" [(ngModel)]="createAccountIfNotExists">
                Create an account if it doesn't exist yet. <a href="http://howto.salesflare.com/en/articles/5790347-can-i-create-accounts-on-a-contact-import" target="_blank" rel="noopener">Read more.</a>
            </mat-checkbox>
        </p>
        <p *ngIf="isFindEmailEnabled && showFindEmailCheckbox" matTooltip="Map ‘first name’ and ‘last name’ (or ‘full name’) and ‘account website’ (or ‘account name’) first to be able to find email addresses.">
            <mat-checkbox [disabled]="!enableFindEmailCheckbox" [(ngModel)]="findEmailIfNotExists">
                Find email address when none is specified (uses credits).<a href="https://howto.salesflare.com/en/articles/5797397-can-i-find-email-addresses-for-contacts-in-salesflare" target="_blank" rel="noopener">Read more.</a>
            </mat-checkbox>
        </p>
        <p *ngIf="importState?.type === 'account'">
            <mat-checkbox [(ngModel)]="updateAccountIfExists" aria-label="update account if exists">
                Update accounts if they have the same name (instead of duplicating)
            </mat-checkbox>
        </p>
    </div>
    <p>After importing, you can filter on the following tag to see your imported data: <span class="accent">{{ importState?.import_tag }}</span></p>
    <div class="tooltip-disabled-button-wrapper">
        <button class="start-import-button"
                mat-raised-button [disabled]="isRestricted || disableImportButton" (click)="startImport()" color="primary"
                matTooltip="You can't start an import because your team is restricted." [matTooltipDisabled]="!isRestricted"
        >
            Start import
        </button>
    </div>
</sfx-content>
