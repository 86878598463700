(function () {
    'use strict';

    angular
        .module('salesflare.components.scorecard', [])
        .component('sfScorecard', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/dashboards/scorecard/scorecard.html',
            bindings: {
                title: '<',
                data: '<',
                label: '<',
                previousPeriodData: '<',
                differenceUnit: '<',
                inverseTrendColor: '<'
            }
        });

    function controller() {

        const vm = this;

        ////////////////

        vm.$onChanges = function () {

            if (angular.isUndefined(vm.data) || vm.data === null) {
                vm.data = 0;
            }

            // Don't try to format strings as numbers
            vm.formatData = angular.isNumber(vm.data);

            vm.previousPeriodDifference = angular.isDefined(vm.previousPeriodData) && vm.previousPeriodData !== null && (vm.data - vm.previousPeriodData);

            if (vm.inverseTrendColor) {
                vm.trendColorClass = vm.previousPeriodDifference <= 0 ? 'positive' : 'negative';
            }
            else {
                vm.trendColorClass = vm.previousPeriodDifference >= 0 ? 'positive' : 'negative';
            }
        };
    }
})();
