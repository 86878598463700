<eco-fab-speed-dial (openChange)="toggleOpenState()">
    <eco-fab-speed-dial-trigger>
        <button mat-fab aria-label="menu" (click)="onMenuClick($event)">
            <mat-icon class="icon-36" fontSet="salesflare" fontIcon="sf-icon-add"></mat-icon>
        </button>

    </eco-fab-speed-dial-trigger>

    <eco-fab-speed-dial-actions>
        <button
                *ngFor="let action of actions; let idx = index;"
                mat-mini-fab
                [attr.track-id]="action['track-id']" [attr.aria-label]="action['aria-label']"
                [matTooltip]="action.label" matTooltipPosition="before"
                matTooltipClass="action-tooltip" [matTooltipDisabled]="Closed"
                [matTooltipHideDelay]="Open ? 3600000 : 0"
                (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()"
                (click)="action['action-type'] === 'file' ? input.click() : action.action()"
                (transitionend)="toggleTooltip(idx)"
        >
            <input type="file" [accept]="action['action-accept']" (change)="action.action($event)" #input>
            <mat-icon class="icon-22" fontSet="salesflare" [fontIcon]="action.icon">
            </mat-icon>
        </button>
    </eco-fab-speed-dial-actions>
</eco-fab-speed-dial>
