(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('emailMigrationService', emailMigrationService);

    function emailMigrationService(statusBarService, model) {

        this.check = function () {

            statusBarService.hide('email');

            if (model.me.data_sources_to_reconnect && model.me.data_sources_to_reconnect.length > 0) {
                return statusBarService.show({ type: 'email', text: 'The email infrastructure has been upgraded! Please ', class: 'warning' });
            }

            const dataSourcesToReconnect = model.me.data_sources && model.me.data_sources.filter(function (source) {

                return source.status === 'RECONNECT';
            });

            if (dataSourcesToReconnect && dataSourcesToReconnect.length > 0) {
                const currentDate = new Date();
                const deadline = new Date('2020-02-02');
                if (currentDate < deadline) {
                    return statusBarService.show({ type: 'email', text: 'We\'ve updated our Gmail integration to use more specific permissions. Please reconnect by February 1st.', linkText: 'Reconnect your emails.', class: 'warning' });
                }

                return statusBarService.show({ type: 'email', text: 'We\'ve updated our Gmail integration to use more specific permissions. Please ', class: 'warning' });
            }

            const erroredDataSources = model.me.data_sources && model.me.data_sources.filter(function (source) {

                return source.status === 'ERROR';
            });

            if (erroredDataSources && erroredDataSources.length > 0) {
                return statusBarService.show({ type: 'email', text: 'Email connection lost! Please ', class: 'warning' });
            }
        };
    }
})();
