(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('SignUpController', SignUpController);

    function SignUpController($scope, $state, $stateParams, $window, $exceptionHandler, $mdDialog, $document, $transitions, helperFunctionsService, team, config, utils, model, authentication, datasources, billing, statusBarService) {

        $scope.disableButtons = false;
        $scope.inviteToken = !!$stateParams.token;
        $scope.signUpAfterWalkthrough = !!this.data?.signUpAfterWalkthrough;
        $scope.body = angular.element($document[0].body);
        $scope.copyRightYear = new Date().getFullYear();
        $scope.shownLargerProspectFollowUpComponent = null;

        // We save the token when connecting with a non-company email address to identify the sign-up attempt server side
        let signUpAttemptToken = null;

        const connectInfo = {
            google: {}
        };

        const wasNotLoggedIn = this.data ? this.data.notLoggedIn : false;
        const skippedWalkthroughReason = this.data ? this.data.skippedReason : undefined;

        /**
         * This transition hook handles going back in the larger prospect follow up flow
         * It goes back to the previously shown component, when on the first component it prevents going back at all
         */
        const removeStartTransitionHook = $transitions.onStart({}, (transition) => {

            if ($scope.shownLargerProspectFollowUpComponent) {

                const toState = transition.to();

                if ($scope.shownLargerProspectFollowUpComponent === 'you-can-book-me-form' && toState.url === '/walkthrough') {
                    return removeStartTransitionHook();
                }

                transition.abort();
                // Since all components are show on /signup we prevent the transition and push the /signup onto the history again
                // When you press back again it lands back on /signup
                history.pushState(null, $document[0].title, location.href);

                switch ($scope.shownLargerProspectFollowUpComponent) {
                    case 'you-can-book-me-form':
                        $scope.shownLargerProspectFollowUpComponent = 'talk-to-us-info';
                        break;
                    case 'talk-to-us-info':
                        $scope.shownLargerProspectFollowUpComponent = 'onboarding-questions';
                        break;
                    case 'onboarding-questions':
                        break;
                }
            }
        });

        init();

        $scope.connectIMAP = function () {

            if (!$scope.email) {
                return;
            }

            $scope.logOut();

            datasources.getEmailType($scope.email)
                .then(function (response) {
                    switch (response.data.type) {
                        case 'google':
                            return $scope.connectGoogle(true);
                        case 'microsoft':
                            return $scope.connectMicrosoft(true);
                        case 'other':
                            $scope.disableButtons = true;
                            return showAuthenticationPopUp('imap', $window.isMobile ? '_blank' : undefined).then(connectCallback);
                    }
                });
        };

        $scope.logOut = function logOut() {

            if (model.isLoggedIn === true) {
                return authentication.logOut().finally(() => {

                    return $state.go('login');
                });
            }
        };

        $scope.connectGoogle = function (useHint) {

            $scope.logOut();

            $scope.disableButtons = true;

            if (!$window.isMobile) {
                return showAuthenticationPopUp('google', null, useHint).then(connectCallback);
            }

            return $window.plugins.googleplus.login(connectInfo.google, function (googleAuthData) {

                const tradePromise = datasources.trade(googleAuthData, { type: 'google' }, { noToast: true, noToastForStatusCode: [403] });

                tradePromise
                    .then(function (response) {

                        return connectCallback(response.data);
                    })
                    .catch(function (err) {

                        $exceptionHandler(err);
                        $scope.disableButtons = false;
                    });

                tradePromise.catch(function (err) {

                    // NON_COMPANY_EMAIL catch
                    if (err?.data?.message === 'NON_COMPANY_EMAIL') {
                        $scope.nonCompanyEmail = true;
                        $scope.authType = 'google';
                        signUpAttemptToken = err.data.token;
                        $scope.disableButtons = false;

                        const nonCompanyEmailSignUpAlertDialog = $mdDialog.alert()
                            .title('Please sign up with a business email address')
                            .textContent('Salesflare automatically tracks your leads based on your inbox, calendar, and more. Please use an active business email address to sign up.')
                            .clickOutsideToClose(true)
                            .ok('Got it');

                        return $mdDialog.show(nonCompanyEmailSignUpAlertDialog);
                    }

                    if (err?.data?.message === 'UNAUTHORIZED_SCOPES') {
                        $scope.disableButtons = false;

                        const insufficientPermissionsDialog = $mdDialog.confirm({ multiple: true })
                            .clickOutsideToClose(true)
                            .escapeToClose(true)
                            .title('Please enable all permissions')
                            .textContent('Salesflare needs all the requested permissions to be able to automate your CRM work.')
                            .ok('Enable')
                            .cancel('cancel');

                        return $mdDialog.show(insufficientPermissionsDialog)
                            .then(() => {
                                $scope.connectGoogle();
                            });
                    }

                    $exceptionHandler(err);
                    $scope.disableButtons = false;

                    return $window.plugins.googleplus.logout(function (msg) {

                        if (msg !== 'Logged user out') {
                            $exceptionHandler(msg);
                            return utils.showErrorToast('Oops! Something went wrong. Please try again.');
                        }
                    });
                });
            }, function (msg) {

                // Apply since not in angular digest loop here and we do want to update the view
                $scope.$apply(function () {

                    $scope.disableButtons = false;
                });

                // 12501 is user canceled the oauth flow
                if (msg && msg !== 12501) {
                    $exceptionHandler(msg);
                    utils.showErrorToast('Oops! Something went wrong. Please try again.');
                }

                $window.plugins.googleplus.logout(function (message) {

                    if (message !== 'Logged user out') {
                        $exceptionHandler(message);
                        return utils.showErrorToast('Oops! Something went wrong. Please try again.');
                    }
                });
            });
        };

        $scope.connectMicrosoft = function (useHint) {

            $scope.logOut();

            $scope.disableButtons = true;

            return showAuthenticationPopUp('office365', null, useHint).then(connectCallback);
        };

        $scope.authenticateLinkedIn = function () {

            $scope.logOut();

            $scope.disableButtons = true;

            const dialogConfig = {
                templateUrl: 'partials/signupexceptiondialog.html',
                controller: 'SignUpExceptionDialogController',
                controllerAs: 'vm',
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                multiple: true
            };

            return $mdDialog.show(dialogConfig).then(function (result) {

                if (result.type === 'linkedin') {
                    return showLinkedInPopUp(result.reason).then(connectCallback);
                }
                else if (result.type === 'security') {
                    const email = result.email;
                    return datasources.sendResearcherInviteEmail(email).then(function () {

                        $scope.securityEmailSent = true;
                        statusBarService.show({
                            type: 'sent_researcher_invite',
                            class: 'success'
                        });
                        $scope.disableButtons = false;
                        $scope.nonCompanyEmail = false;
                        return utils.showSuccessToast('We have sent you an email with a link to create your account.');
                    }).catch(function () {

                        $scope.disableButtons = false;
                        $scope.nonCompanyEmail = false;
                        return utils.showErrorToast('Oops! Something went wrong. Please try again.');
                    });
                }
            }).catch(() => {

                $scope.disableButtons = false;
            });
        };

        $scope.signin = function () {

            return $state.go('login');
        };

        function init() {

            // Sign up is not allowed on ios
            if ($window.device && $window.device.platform === 'iOS') {
                return $state.go('login');
            }

            // If there is a token we logout the user since the user probably
            // Wants to sign up for a new team
            if ($scope.inviteToken) {
                if (model.isLoggedIn) {
                    return authentication.logOut().finally(getInviteInfo);
                }

                return getInviteInfo();
            }

            // If the user is logged in already send them to tasks directly
            if (model.isLoggedIn) {
                return $state.go('tasks.today');
            }

            if ($window.isMobile) {
                datasources.getConnectInfo('google').then(function (response) {

                    connectInfo.google = response.data;
                });
            }

            if ($stateParams.discount_code) {
                return billing.validateCampaignDiscountCode($stateParams.discount_code).then(function () {

                    if ($stateParams.campaign) {
                        switch ($stateParams.campaign.toLowerCase()) {
                            case 'appsumo':
                                $scope.campaignText = 'Create your lifetime AppSumo account';
                                break;
                            case 'saasfriday':
                                $scope.campaignText = 'Start your free Salesflare trial - your SAASFRIDAY discount has been applied!';
                                break;
                            default:
                                $scope.campaignText = 'Start your free Salesflare trial - your ' + $stateParams.discount_code + ' discount has been applied!';
                        }
                    }
                    else {
                        $scope.campaignText = 'Start your free Salesflare trial - your ' + $stateParams.discount_code + ' discount has been applied!';
                    }

                    $scope.appliedDiscountCode = $stateParams.discount_code;
                });
            }

            if ($stateParams.refer) {
                $scope.refer = $stateParams.refer;
            }
        }

        function getInviteInfo() {

            return team.getInvite($stateParams.token).then(function (response) {

                $scope.email = response.data.email;
                $scope.tokenInfo = response.data;

                if (!$scope.email) {
                    return utils.showErrorToast('Looks like your invite was canceled');
                }
            });
        }

        function initializeThirdPartyServicesAndRedirect(isNewTeam) {

            if (config.segment && config.segment.key) {
                analytics.track('user-created', { gaClientId: model.gaClientId });

                if (isNewTeam) {
                    const isCompanyEmail = !['@gmail.', '@outlook.', '@hotmail.', '@yahoo.'].some((nonCompanyDomain) => model.me?.email?.includes(nonCompanyDomain));
                    analytics.track('team-created', {
                        category: 'conversion',
                        company_email: isCompanyEmail,
                        gaClientId: model.gaClientId
                    });
                }
            }

            if (config.growsumo) {
                const gs = $document[0].createElement('script');
                gs.src = 'https://snippet.growsumo.com/growsumo.min.js';
                gs.type = 'text/javascript';
                gs.async = 'true';
                gs.addEventListener('load', function () {
                    const rs = this.readyState;
                    if (rs && rs !== 'complete' && rs !== 'loaded') {
                        return;
                    }

                    try {
                        // eslint-disable-next-line no-underscore-dangle
                        growsumo._initialize(config.growsumo.key);
                        growsumo.data.email = model.me.email;
                        growsumo.data.customer_key = model.me.id.toString();
                        growsumo.createSignup();
                    }
                    catch (err) {

                        return $exceptionHandler(err);
                    }
                });

                gs.addEventListener('readystatechange', gs.onload);
                const gfs = $document[0].querySelectorAll('script')[0];

                gfs.parentNode.insertBefore(gs, gfs);
            }

            // Only show the onboarding questions to the first user of the team
            if (model.me && model.me.is_admin && model.me.team.total_user_count === 1) {
                $scope.shownLargerProspectFollowUpComponent = 'onboarding-questions';
            }
            else if ($state.is('signup')) {
                removeStartTransitionHook();
                return $state.go('walkthrough');
            }
            else {
                return $mdDialog.cancel();
            }
        }

        function connectCallback(queryParams) {


            if (!queryParams) {
                $scope.disableButtons = false;
                $scope.nonCompanyEmail = false;
                return $exceptionHandler(new Error('Lost connection to the popup window or it was manually closed'));
            }

            if (queryParams.get('success') !== 'true') {
                if (queryParams.get('error') === 'NON_COMPANY_EMAIL') {
                    $scope.nonCompanyEmail = true;
                    signUpAttemptToken = queryParams.get('token');
                    $scope.disableButtons = false;

                    const nonCompanyEmailSignUpAlertDialog = $mdDialog.alert()
                        .title('Please sign up with a business email address')
                        .textContent('Salesflare automatically tracks your leads based on your inbox, calendar, and more. Please use an active business email address to sign up.')
                        .clickOutsideToClose(true)
                        .ok('Got it');

                    return $mdDialog.show(nonCompanyEmailSignUpAlertDialog);
                }

                if (queryParams.get('error') === 'UNAUTHORIZED_SCOPES') {
                    $scope.disableButtons = false;

                    const insufficientPermissionsDialog = $mdDialog.confirm({ multiple: true })
                        .clickOutsideToClose(true)
                        .escapeToClose(true)
                        .title('Please enable all permissions')
                        .textContent('Salesflare needs all the requested permissions to be able to automate your CRM work.')
                        .ok('Enable')
                        .cancel('cancel');

                    return $mdDialog.show(insufficientPermissionsDialog)
                        .then(() => {
                            $scope.connectGoogle();
                        });
                }

                $scope.disableButtons = false;
                $scope.nonCompanyEmail = false;
                return utils.showErrorToast(queryParams.get('error'));
            }

            $scope.authType = null;

            model.setToken(queryParams.get('token'));

            // TODO: research if this is still needed, currently BREAKS flow
            if (queryParams.get('login') === 'true') {
                return $state.go('tasks.today');
            }

            const isNewTeam = queryParams.get('team_created') === 'true';

            return authentication.fetchMe().then(function () {

                // Set the walkthrough_before_email_connection field for the team for analyzing
                if (wasNotLoggedIn) {
                    const update = {
                        walkthrough_before_email_connection: true,
                        skipped_walkthrough: 'no'
                    };

                    if (angular.isDefined(skippedWalkthroughReason)) {
                        update.skipped_walkthrough = skippedWalkthroughReason;
                    }

                    team.update(update);
                }

                if (!isNewTeam) {
                    return initializeThirdPartyServicesAndRedirect(isNewTeam);
                }

                return team.getRelated().then((relatedTeamsResponse) => {

                    const relatedTeams = relatedTeamsResponse.data;

                    if (relatedTeams.length === 0) {
                        return initializeThirdPartyServicesAndRedirect(isNewTeam);
                    }

                    return $mdDialog.show({
                        clickOutsideToClose: false,
                        escapeToClose: false,
                        bindToController: true,
                        controller: 'JoinExistingTeamDialogController',
                        templateUrl: 'app-ajs/components/joinexistingteamdialog/joinExistingTeamDialog.html',
                        multiple: true,
                        controllerAs: 'vm',
                        onShowing: function (scope, element) {

                            element.children('md-dialog').addClass('join-existing-team-dialog');
                        },
                        locals: {
                            relatedTeams
                        }
                    }).then(function (selectedTeamId) {

                        if (selectedTeamId) {
                            return team.createJoinTeamRequest(selectedTeamId).then(() => {

                                $scope.joiningExistingTeam = true;
                            });
                        }

                        return initializeThirdPartyServicesAndRedirect(isNewTeam);
                    });
                }).catch(() => {
                    initializeThirdPartyServicesAndRedirect(isNewTeam);
                });
            }).catch(() => {

                $scope.disableButtons = false;
            });
        }

        $scope.onSaveOnboardingQuestions = ($event) => {

            team.update($event.answers);

            if ($event.answers.potential_users === '1-2') {
                exitLargerProspectFollowUpFlow();
            }

            $scope.answersToOnboardingQuestions = $event.answers;
            $scope.shownLargerProspectFollowUpComponent = 'talk-to-us-info';
        };

        $scope.onBookCall = ($event) => {

            $scope.shownLargerProspectFollowUpComponent = 'you-can-book-me-form';
            $scope.callDurationInMin = $event.durationInMin;
            $scope.bookingInfo = {
                firstName: model.me.firstname || model.me.name,
                company: model.me.team.name,
                email: model.me.email,
                currentlyUsing: $scope.answersToOnboardingQuestions.previous_crm,
                potentialUsers: $scope.answersToOnboardingQuestions.potential_users
            };
            $scope.skipWalkthroughAfterBooking = !$state.is('signup');
        };

        $scope.onDismiss = exitLargerProspectFollowUpFlow;

        function exitLargerProspectFollowUpFlow() {

            if ($state.is('signup')) {
                removeStartTransitionHook();
                return $state.go('walkthrough');
            }

            return $mdDialog.cancel();
        }

        function showAuthenticationPopUp(type, target, useHint) {

            $scope.nonCompanyEmailException = false;
            $scope.authType = null;

            let popUpUrl = config.apiUrl + 'datasources/connect?';

            popUpUrl += ('type=' + type);

            if (type === 'imap' || useHint) {
                popUpUrl += ('&email=' + $scope.email);
            }

            popUpUrl += '&time_zone=' + encodeURIComponent(moment.tz.guess() || 'UTC');

            if ($stateParams.token) {
                popUpUrl += ('&invite=' + $stateParams.token);
            }

            if ($stateParams.discount_code) {
                popUpUrl += ('&discount_code=' + $stateParams.discount_code);
            }

            if ($stateParams.campaign) {
                popUpUrl += ('&campaign=' + $stateParams.campaign);
            }

            if ($stateParams.refer) {
                popUpUrl += ('&refer=' + $stateParams.refer);
            }

            if ($window.isMobile) {
                popUpUrl += '&mobile=true';
            }

            if (signUpAttemptToken) {
                popUpUrl += `&non_company_email_signup_attempt_token=${signUpAttemptToken}`;
            }

            if (type === 'google') {
                popUpUrl = `${config.apiUrl}datasources/gmail/permissions?connectUrl=${encodeURIComponent(popUpUrl)}`;
            }

            if (config.mode === 'outlook-web') {
                return helperFunctionsService.handleOutlookPluginOAuthFlow(popUpUrl);
            }

            $scope.authType = type;

            return utils.popup(popUpUrl, target);
        }

        function showLinkedInPopUp(reason) {

            $scope.nonCompanyEmailException = false;

            const params = {
                time_zone: moment.tz.guess() || 'UTC'
            };

            if ($stateParams.token) {
                params.invite = $stateParams.token;
            }

            if ($stateParams.discount_code) {
                params.discount_code = $stateParams.discount_code;
            }

            if ($stateParams.campaign) {
                params.campaign = $stateParams.campaign;
            }

            if ($stateParams.refer) {
                params.refer = $stateParams.refer;
            }

            if ($window.isMobile) {
                params.mobile = true;
            }

            if (reason) {
                params.exception_reason = reason;
            }

            params.non_company_email_signup_attempt_token = signUpAttemptToken;

            const state = angular.toJson(params);

            const target = $window.isMobile ? '_system' : '_blank';

            return utils.popup(`${config.linkedIn.oauthUrl}?response_type=code&client_id=${config.linkedIn.clientId}&redirect_uri=${encodeURIComponent(config.linkedIn.redirectUrl)}&state=${encodeURIComponent(state)}&scope=openid%20profile%20email`, target);
        }

        $scope.$on('$destroy', function () {

            removeStartTransitionHook();
        });
    }
})();
