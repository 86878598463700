/* eslint angular/component-limit: ["error", 2] */
(function () {
    'use strict';

    angular
        .module('salesflare')
        .config(function ($mdPanelProvider, $windowProvider) {

            $mdPanelProvider.definePreset('reminderPanel', {
                attachTo: $windowProvider.$get().document.body,
                clickOutsideToClose: true,
                controller: ReminderPanelController,
                controllerAs: 'vm',
                templateUrl: 'partials/reminderpanel.html',
                propagateContainerEvents: false
            });
        });

    angular
        .module('salesflare')
        .controller('ReminderPanelController', ReminderPanelController);

    /**
     * {Date} vm.reminder_date
     *
     * @param {Object} $mdPanel
     * @param {Object} $timeout
     * @param {Object} mdPanelRef
     */
    function ReminderPanelController($mdPanel, $timeout, mdPanelRef) {

        const vm = this;

        vm.hidden = false;

        // Later today
        const later = new Date();
        later.setHours(later.getHours() + 3, 0, 0, 0);

        // Tomorrow morning
        const tomorrowMorning = new Date();
        tomorrowMorning.setDate(tomorrowMorning.getDate() + 1);
        tomorrowMorning.setHours(9, 0, 0, 0);

        // In 2 days
        const in2Days = new Date();
        in2Days.setDate(in2Days.getDate() + 2);
        in2Days.setHours(9, 0, 0, 0);

        // Next week
        const nextWeek = new Date();
        nextWeek.setDate(nextWeek.getDate() + 7);
        nextWeek.setHours(9, 0, 0, 0);

        vm.quickDates = [
            {
                name: 'later',
                description: 'Later today',
                date: later,
                dateString: 'in 3 hours'
            },
            {
                name: 'tomorrow',
                description: 'Tomorrow',
                date: tomorrowMorning
            },
            {
                name: 'two-days',
                description: 'In 2 days',
                date: in2Days
            },
            {
                name: 'next-week',
                description: 'Next week',
                date: nextWeek
            }
        ];

        /**
         *
         * @param {Date} date
         * @returns {void}
         */
        vm.setReminder = function (date) {

            // If locals is `null` the panel got destroyed somehow
            // I was not able to reproduce this behaviour though
            // See https://github.com/angular/material/blob/bc7833b45a5de5af3a01ba8445f7e24ca4311faa/src/components/panel/panel.js#L1620
            // This is to prevent this from throwing issue https://github.com/Salesflare/Client/issues/1775
            if (mdPanelRef.config.locals) {
                mdPanelRef.config.locals.reminder_date = date;
            }

            return mdPanelRef.close().then(function () {

                return mdPanelRef.destroy();
            });
        };

        let showingDateTimePanel = false;
        let blockMouseOver = false;

        vm.showCalendar = function ($event) {

            if (showingDateTimePanel || (blockMouseOver && $event.type === 'mouseover')) {
                return;
            }

            showingDateTimePanel = true;

            // Show panel next to current panel
            const position = $mdPanel.newPanelPosition()
                .relativeTo(mdPanelRef.panelEl)
                .addPanelPosition($mdPanel.xPosition.OFFSET_START, $mdPanel.yPosition.ALIGN_TOPS)
                .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ALIGN_TOPS)
                .addPanelPosition($mdPanel.xPosition.OFFSET_START, $mdPanel.yPosition.ABOVE)
                .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ABOVE);

            return $mdPanel.open('dateTimePanel', {
                attachTo: mdPanelRef.panelEl,
                openFrom: $event,
                position,
                locals: {
                    reminder_date: vm.reminder_date,
                    isNew: vm.isNew,
                    confirmed: false
                },
                onRemoving: function (panelRef) {

                    showingDateTimePanel = false;

                    if (panelRef.config.locals.confirmed) {

                        return vm.setReminder(panelRef.config.locals.reminder_date);
                    }
                }
            }).then(function (dateTimePanel) {

                // Set up handlers for mobile hide/show behaviour
                dateTimePanel.onScaled = function () {

                    vm.hidden = true;
                };

                dateTimePanel.onHide = function () {

                    // Block mouseover event to prevent instantly triggering open again
                    blockMouseOver = true;
                    $timeout(function () {

                        blockMouseOver = false;
                    }, 100);

                    showingDateTimePanel = false;
                    vm.hidden = false;
                };
            });
        };

        // Stop propagation of on mouse up, otherwise it thinks we are clicking next to the dateTimePanel and it wants to close it
        vm.onDateMouseUp = function ($event) {

            if (showingDateTimePanel) {
                $event.stopPropagation();
            }
        };
    }
})();
