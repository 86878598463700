<sfx-toolbar enableDivider>
    Regional settings
</sfx-toolbar>
<sfx-content>
    <sfx-header>
        Personal Settings
    </sfx-header>
    <sfx-section>
        <form #personalSettingsForm="ngForm" id="personalSettingsForm" name="personalSettingsForm" data-flex data-layout="column">
            <mat-form-field [hidden]="!timeNotations">
                <mat-label>Time format</mat-label>
                <mat-select name="ampmNotation" data-layout="row" class="ellipsis" [(ngModel)]="ampmNotation" (ngModelChange)="savePersonalSettings()">
                    <mat-option *ngFor="let timeNotation of timeNotations" [value]="timeNotation">{{ timeNotation.notation }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field [hidden]="!startDays">
                <mat-label>Week starts on</mat-label>
                <mat-select name="firstDayOfWeek" data-layout="row" class="ellipsis" [(ngModel)]="firstDayOfWeek" (ngModelChange)="savePersonalSettings()">
                    <mat-option *ngFor="let startDay of startDays" [value]="startDay">{{ startDay.day }}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </sfx-section>
    <ng-container *ngIf="modelService.me?.is_admin">
        <sfx-header>
            Team Settings
        </sfx-header>
        <sfx-section *ngIf="modelService.me?.is_admin">
            <form #teamSettingsForm="ngForm" id="teamSettingsForm" name="teamSettingsForm" data-flex data-layout="column">
                <mat-form-field [hidden]="!timeZones">
                    <mat-label>Time zone</mat-label>
                    <mat-select name="timeZone" data-layout="row" class="ellipsis" [(ngModel)]="timeZone" (ngModelChange)="saveTimeZone()">
                        <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone">{{ timeZone.text }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field [hidden]="!currencies">
                    <mat-label>Currency</mat-label>
                    <mat-select name="currency" data-layout="row" class="ellipsis" [(ngModel)]="currency" (ngModelChange)="saveCurrency()" [compareWith]="compareCurrencies">
                        <mat-select-trigger>
                            <div class="currency-flag currency-flag-{{ currency.iso | lowercase}}"></div>
                            <span class="currency-iso">{{currency.iso}}</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let currency of currencies" [value]="currency">
                            <div class="currency-flag currency-flag-{{currency.iso | lowercase}}"></div>
                            <span class="currency-iso">{{currency.iso}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </sfx-section>
    </ng-container>
</sfx-content>
