(function () {
    'use strict';

    angular
        .module('salesflare.components.dashboards.filterDialog', [])
        .factory('sfDashboardFilterDialog', sfDashboardFilterDialog);

    function sfDashboardFilterDialog($mdDialog) {

        return {
            show
        };

        function show() {

            return $mdDialog.show({
                clickOutsideToClose: true,
                escapeToClose: true,
                multiple: false,
                hasBackdrop: true,
                controller: 'FilterDialogController',
                controllerAs: 'vm',
                templateUrl: 'app-ajs/components/dashboards/filterDialog/filterDialog.html',
                locals: {
                    hidePipelineSelector: false
                },
                bindToController: true
            });
        }
    }
})();
