(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('OpportunitiesStagesController', OpportunitiesStagesController);

    function OpportunitiesStagesController($scope, $state, $mdDialog, $window, $mdMedia, $mdSidenav, $mdComponentRegistry, $timeout, $exceptionHandler, model, opportunities, opportunityService, utils, sfWalkthrough, customfields, filterService, helperFunctionsService, sortService) {

        /**
         * We use 2 loading strategies in here.
         * 1 for mobile and 1 for large screens.
         *
         * On large screens we use multiple virtual repeats that fetch opportunities on demand.
         * On mobile we load all opportunities at once and use 1 virtual repeat.
         *
         * The reason for this distinction is that
         * - On mobile we only need 1 virtual repeat.
         * - Doing an on demand one on mobile is annoying with the conditional headers, which would cause flickering when (un)loading in the repeater.
         */

        $scope.showOpenAccountInNewTab = !$window.isMobile;
        $scope.stages = null;
        $scope.opportunitiesPerStage = []; // Contains virtual repeat objects. @see `initVirtualRepeatObject`
        $scope.draggedOpportunity = null;
        $scope.usedBadFilter = false;
        $scope.selectedOpportunity.id = $state.params.id;
        $scope.me = model.me;
        $scope.insufficientPermissionsMessage = 'You don\'t have permission to edit this opportunity. Ask an admin.';

        const REPEATER_LOADING_STEP = $mdMedia('gt-sm') ? 15 : 30; // Loading in bigger steps seems to result in a better scrolling exp on mobile.

        if (!$scope.selectedOpportunity.id && $mdMedia('gt-sm') && $mdComponentRegistry.get('right')) {
            $mdSidenav('right').close();
        }

        // Auto scroller to enable dragging opportunities to stages that appear off-screen
        const scroll = autoScroll([angular.element('#opportunities')[0]], {
            margin: 200, // Inner area to detect when the pointer is close to the edge
            scrollWhenOutside: true, // Continues scrolling when the pointer is outside the container
            maxSpeed: 8, // Max scrolling speed in pixels per frame
            autoScroll: function () {
                // Only scroll when the pointer is down and when there is an opportunity being dragged
                return this.down && $scope.draggedOpportunity !== null;
            }
        });
        scroll.autoScroll();

        sfWalkthrough.advance();

        if (sfWalkthrough.isShowing()) {
            // Wait for the opportunity to finish rendering before drawing the drag arrow
            drawDragArrowWhenOpportunityInDOM();

            // Recalculate arrow width on window resize
            angular.element($window).bind('resize', setDragArrowWidth);

            $scope.$watch(() => {
                return sfWalkthrough.isCompleted();
            }, (newValue, oldValue) => {

                // If the walkthrough completed by pressing the 'DO IT FOR ME' button we want to still move the opportunity to won
                if (!oldValue && newValue) {
                    finishWalkthrough();
                }
            });
        }

        // We don't need to manually call `get` here to init as the OpportunitiesController will trigger this for us through the event.
        $scope.$on('get', get);

        $scope.$on('$destroy', function () {

            $scope.opportunities.items = [];
        });

        // Refetch data when switching screen size.
        // See comment at the start of the controller as to why.
        $scope.$watch(function () {

            return $mdMedia('gt-sm');
        }, function (newValue, oldValue) {

            if (newValue !== oldValue) {
                // Remove the arrow when resizing to a small screen
                if (!newValue && oldValue && $scope.showDragArrow) {
                    $scope.showDragArrow = false;
                }

                // Resetting items here to prevent showing the wrong list on screen size change while we wait for the proper new data to come through.
                $scope.opportunities.items = [];

                return get();
            }
        });

        $scope.hideShow = function (stage, $event) {

            if ($event.currentTarget.className.includes('md-sticky-clone')) {
                return;
            }

            if (stage.hide) {
                stage.hide = false;
            }
            else {
                stage.hide = true;
            }
        };

        $scope.onDragStart = function (opportunity) {

            if (sfWalkthrough.isShowing()) {
                sfWalkthrough.hideTooltip();
                angular.element('.drag-here-indicator').addClass('wiggle dragging');
                $scope.showDragArrow = false;
            }

            opportunity.selected = false;
            $scope.checkShowBulkToolbar();

            $scope.draggedOpportunity = opportunity;
        };

        // Gets triggered just before starting a drop, removing the opportunity from the old stage here will prevent a brief flicker of the opportunity in its old stage
        // We don't know yet in which stage the opportunity gets dropped, so we remove the opportunity even if the stage didn't change.
        // This means we'll have to add it back in onStageDropComplete
        $scope.onDragSuccess = function (opportunity, $event) {

            // Make sure the function is only called once per drag and drop
            $event.event.stopImmediatePropagation();

            if ($mdMedia('gt-sm')) {
                const draggedFromStage = $scope.opportunitiesPerStage.find((stage) => stage.stageInfo && stage.stageInfo.id === opportunity.stage.id);

                const index = draggedFromStage.items.findIndex((opp) => opp.id === opportunity.id);

                // Remove from previous stage
                if (index !== -1) {
                    // If the amount of items in the virtual repeat is equal to the numLoaded and toLoad, make sure to change these properties later
                    // (this means that the virtual repeat was ready to fetch more items,
                    // leaving these properties unaltered in this case would break further fetches)
                    const changeVirtualRepeatProperties = (draggedFromStage.numLoaded === draggedFromStage.toLoad && draggedFromStage.toLoad === draggedFromStage.items.length);

                    draggedFromStage.items.splice(index, 1);
                    // Alter the stage meta as well
                    draggedFromStage.count -= 1;
                    draggedFromStage.sum -= opportunity.calculated_value;

                    if (changeVirtualRepeatProperties) {
                        draggedFromStage.numLoaded -= 1;
                        draggedFromStage.toLoad -= 1;
                    }
                }
            }
            else {
                // Mobile
                const index = $scope.mobileOpportunities.items.findIndex((opp) => opp.id === opportunity.id);
                const foundStage = $scope.stages.find((stage) => stage.id === opportunity.stage.id);

                if (index !== -1) {
                    // If the amount of items in the virtual repeat is equal to the numLoaded and toLoad, make sure to change these properties later
                    // (this means that the virtual repeat was ready to fetch more items,
                    // leaving these properties unaltered in this case would break further fetches)
                    const changeVirtualRepeatProperties = ($scope.mobileOpportunities.numLoaded === $scope.mobileOpportunities.toLoad && $scope.mobileOpportunities.toLoad === $scope.mobileOpportunities.items.length);

                    $scope.mobileOpportunities.items.splice(index, 1);
                    // Alter the stage meta as well
                    foundStage.count -= 1;
                    foundStage.sum -= opportunity.calculated_value;

                    if (changeVirtualRepeatProperties) {
                        $scope.mobileOpportunities.numLoaded -= 1;
                        $scope.mobileOpportunities.toLoad -= 1;
                    }
                }
            }
        };

        $scope.onDragStop = function () {

            $scope.draggedOpportunity = null;
        };

        $scope.onStageDropComplete = function (opportunity, stage) {

            if (!opportunity) {
                // This can happen when you drag by accident so it drags/drops instantly
                return;
            }

            if (opportunity.can_edit === false) {
                return utils.showErrorToast('You don\'t have permission to move this opportunity to another stage.');
            }

            const opportunityCopy = angular.copy(opportunity);

            $scope.draggedOpportunity = null;

            opportunityCopy.stage = stage;
            opportunityCopy.probability = opportunityCopy.stage.probability;

            // If we are dropping somewhere else than won in the walkthrough, just reset
            if (sfWalkthrough.isShowing() && opportunityCopy.stage.fixed_stage !== 1) {
                positionOpportunityInStage(opportunity);
                $timeout(() => {
                    sfWalkthrough.showTooltip();
                    $scope.showDragArrow = true;
                }); // Needs a timeout so it properly attaches the tooltip to the opportunity
                return angular.element('.drag-here-indicator').removeClass('dragging');
            }

            if (opportunity.stage.id === stage.id) {
                // Put the opportunity back in its original spot, since it was removed after the dragging was completed
                positionOpportunityInStage(opportunity);
                return;
            }

            const mustBeFutureDate = opportunityCopy.stage.probability !== 0 && opportunityCopy.stage.probability !== 100;
            let toastMessage = '';
            let showDialog = false;
            const closeDateDialogLocals = {
                opportunity: opportunityCopy
            };

            // Stage is won
            if (opportunityCopy.stage.fixed_stage === 1) {
                toastMessage = 'Congrats! ';
                opportunityCopy.close_date = utils.localDateObjectToUTCDateObject(utils.getTodayAtMidnight());

                if ($scope.currentPipeline.recurring && !opportunityCopy.contract_start_date) {
                    toastMessage += 'The close date and contract start date have been set to today.';
                    opportunityCopy.contract_start_date = utils.localDateObjectToUTCDateObject(utils.getTodayAtMidnight());
                }
                else {
                    toastMessage += 'The close date has been set to today.';
                }

                if (sfWalkthrough.isShowing()) {
                    processDrop(opportunityCopy, stage);
                    finishWalkthrough();
                    return sfWalkthrough.advance();
                }
            }
            // Stage is lost
            else if (opportunityCopy.stage.fixed_stage === 2) {
                return customfields.getOpportunityFields(false, opportunityCopy.pipeline.id, 'Lost reason').then(function (response) {

                    if (response.data && response.data.length > 0 && response.data[0].enabled) {
                        showDialog = true;
                        closeDateDialogLocals.lostReasonCustomField = response.data[0];
                    }
                    else {
                        toastMessage += 'The close date has been set to today.';
                        opportunityCopy.close_date = utils.localDateObjectToUTCDateObject(utils.getTodayAtMidnight());
                    }

                    return showCloseDateDialogAndUpdateOpportunity(opportunityCopy, opportunity, toastMessage, closeDateDialogLocals, showDialog);
                });
            }
            // Stage probability is higher than 0 and opportunity has no close date or the close date is not in the future while the stage requires it to be in the future
            else if (opportunityCopy.stage.probability > 0 && (!opportunity.close_date || ((new Date(opportunity.close_date) < utils.getTodayAtMidnight()) && mustBeFutureDate))) {
                showDialog = true;
            }

            return showCloseDateDialogAndUpdateOpportunity(opportunityCopy, opportunity, toastMessage, closeDateDialogLocals, showDialog);
        };

        /**
         * @param {Object} opportunity
         * @param {Object} opportunityBeforeDrop Needed if the drag and drop needs to be undone
         * @param {String} [onUpdateToastMessage]
         * @param {Object} locals
         * @param {Boolean} showDialog
         * @returns {Promise}
         */
        function showCloseDateDialogAndUpdateOpportunity(opportunity, opportunityBeforeDrop, onUpdateToastMessage, locals, showDialog) {

            if (showDialog) {

                return $mdDialog.show({
                    clickOutsideToClose: true,
                    controller: 'CloseDateDialogController',
                    templateUrl: 'partials/closedatedialog.html',
                    locals,
                    bindToController: true // Allow access to locals
                }).then(function () {

                    opportunity.close_date = utils.localDateObjectToUTCDateObject(opportunity.close_date);

                    processDrop(opportunity, opportunity.stage);

                    return opportunityService.update(opportunity).then(function () {

                        return positionOpportunityInStage(opportunity);
                    }).catch(function () {
                        // Just get the affected stages, since we don't know if the update came through
                        return get({ stagesToReload: [opportunity.stage.id, opportunityBeforeDrop.stage.id] });
                    });
                }).catch(function () {
                    // Put the original opportunity back in its original spot when the update isn't completed
                    positionOpportunityInStage(opportunityBeforeDrop);
                });
            }

            processDrop(opportunity, opportunity.stage);

            return opportunityService.update(opportunity).then(function () {

                positionOpportunityInStage(opportunity);

                if (!onUpdateToastMessage) {
                    return;
                }

                return utils.showInfoToast(onUpdateToastMessage, 'EDIT', 10000).then(function (result) {

                    if (result === 'EDIT') {
                        return $state.go('opportunity', { id: opportunity.id });
                    }
                });
            });
        }

        function positionOpportunityInStage(opportunity) {

            // Check the current sorting selection
            const sortingObject = sortService.getCurrentSortOption('opportunity').clientSortObject;
            let virtualRepeatObject;

            if ($mdMedia('gt-sm')) {
                virtualRepeatObject = $scope.opportunitiesPerStage.find((stage) => (stage.stageInfo && stage.stageInfo.id) === (opportunity.stage && opportunity.stage.id));
                // Adjust the stage meta
                virtualRepeatObject.count += 1;
                virtualRepeatObject.sum += opportunity.calculated_value;
            }
            else {
                virtualRepeatObject = $scope.mobileOpportunities;
                const foundStage = $scope.stages.find((stage) => stage.id === opportunity.stage.id);

                // Adjust the stage meta
                foundStage.count += 1;
                foundStage.sum += opportunity.calculated_value;
            }

            // Add the opportunity to the virtual repeat object if the opportunity should be in its current items
            // Only add the opportunity if it's not the last element after sorting, or the amount of loaded opportunities is less then numLoaded
            // (this means there are no more opportunities to fetch from the server)
            const newIndex = getNewOpportunityIndex(virtualRepeatObject.items, opportunity, sortingObject);

            if (newIndex !== -1 || virtualRepeatObject.items.length < virtualRepeatObject.numLoaded) {
                if (newIndex !== -1) {
                    virtualRepeatObject.items.splice(newIndex, 0, opportunity);
                }
                // Only push the opportunity as the last element if the amount of loaded items is smaller then numLoaded
                // In other cases it will get fetched naturally when scrolling more in the virtual repeat
                else if (virtualRepeatObject.items.length < virtualRepeatObject.numLoaded) {
                    virtualRepeatObject.items.push(opportunity);
                }

                // Check if the virtual repeat object meta data needs to be updated (if the amount of items is larger then to)
                const changeVirtualRepeatProperties = (virtualRepeatObject.numLoaded === virtualRepeatObject.toLoad && virtualRepeatObject.toLoad < virtualRepeatObject.items.length);

                if (changeVirtualRepeatProperties) {
                    virtualRepeatObject.numLoaded += 1;
                    virtualRepeatObject.toLoad += 1;
                }
            }
        }

        function getNewOpportunityIndex(opportunitiesList, newOpportunity, sortingObject) {

            return opportunitiesList.findIndex((opportunity) => {

                // Check stages in case we're handling mobile opportunities
                // This is needed since on desktop, stage ordering is not relevant (we sort opportunities per stage),
                // but on mobile we have one big list, so we have order by stage first before ordering on the selected sort option
                if (opportunity.stage.order < newOpportunity.stage.order) {
                    return false;
                }

                // Use the property string in the sorting object to get the right values to compare from both opportunities
                // We use this split/reduce combo to handle strings with sub properties like 'last_interaction.date'
                let oldValue = sortingObject.property.split('.').reduce((a, b) => a[b], opportunity);
                let newValue = sortingObject.property.split('.').reduce((a, b) => a[b], newOpportunity);

                // Replicate our SQL string sorting
                if (sortingObject.type === 'string') {
                    oldValue = oldValue ? oldValue.toUpperCase() : oldValue;
                    newValue = newValue ? newValue.toUpperCase() : newValue;
                }

                if (sortingObject.direction === 'asc') {
                    if ((newValue < oldValue) || (newValue === null && !!oldValue)) {
                        return true;
                    }
                    else if (newValue === oldValue && sortingObject.property !== 'last_interaction.date') {
                        return newOpportunity.last_interaction.date > opportunity.last_interaction.date;
                    }

                    return false;
                }
                else {
                    if ((newValue > oldValue) || (oldValue === null && !!newValue)) {
                        return true;
                    }
                    else if (newValue === oldValue && sortingObject.property !== 'last_interaction.date') {
                        return newOpportunity.last_interaction.date > opportunity.last_interaction.date;
                    }

                    return false;
                }
            });
        }

        $scope.goToAccountFeed = function (opp, newTab) {

            if (sfWalkthrough.isShowing()) {
                return;
            }

            if (newTab) {
                const url = $state.href('accounts.account.feed', { id: opp.account.id });
                $window.open(url, '_blank', 'noopener');
                return;
            }

            return $state.go('accounts.account.feed', { id: opp.account.id });
        };

        $scope.goToOpportunity = function (id) {

            if (!$scope.isWalkthroughShowing() && id) {
                if ($mdMedia('gt-sm')) {
                    $mdSidenav('right').open();
                }

                $scope.selectedOpportunity.id = id;

                return $state.go('opportunities.stages.opportunity', { id });
            }
        };

        $scope.assign = function (opportunity, $event) {

            return helperFunctionsService.assign(opportunity, $event).then(function () {

                return get();
            });
        };

        $scope.setDone = function (opportunity) {

            opportunity.done = !opportunity.done;

            return opportunityService.update(opportunity).then(function () {

                if (opportunity.done) {
                    utils.showSuccessToast('Marked as done! You can still search or filter to find it back.');
                }

                // We use `isDrop` here to not reload everything
                return get({ stagesToReload: [opportunity.stage.id] });
            }).catch(function () {

                return get({ stagesToReload: [opportunity.stage.id] });
            });
        };

        $scope.isWalkthroughShowing = sfWalkthrough.isShowing;

        /**
         * @param {Number} fromStageId
         * @param {Number} toStageId
         * @returns {Array.<Object>}
         */
        $scope.getStagesBetween = function (fromStageId, toStageId) {

            if (!toStageId) {
                return [];
            }

            const stagesBetween = [];
            const stageIds = $scope.stages.map(function (stage) {

                return stage.id;
            });

            const fromStageIndex = fromStageId ? stageIds.indexOf(fromStageId) + 1 : 0;
            const toStageIndex = toStageId ? stageIds.indexOf(toStageId) : $scope.stages.length;

            for (let i = fromStageIndex; i <= toStageIndex; ++i) {
                const tempStage = $scope.stages[i];

                if (tempStage) {
                    stagesBetween.push(tempStage);
                }
            }

            return stagesBetween;
        };

        /**
         * - close date is in the future
         * - close date is null && (stage probability is 0 OR stage is the lost stage)
         * - close date is in the past && (stage is won or lost OR stage probability is 0 or 100)
         *
         * @param {Object} opportunity
         * @param {Number} opportunity.id
         * @param {Date} opportunity.close_date
         * @param {Object} opportunity.stage
         * @param {Number} opportunity.stage.probability
         * @param {Number} opportunity.stage.fixed_stage
         * @returns {Boolean}
         */
        $scope.isInvalidOpportunity = function (opportunity) {

            if (!opportunity || !opportunity.id) {
                return false;
            }

            if (!opportunity.close_date && (opportunity.stage.probability > 0 || opportunity.stage.fixed_stage === 2)) {
                return true;
            }

            if ((opportunity.stage.fixed_stage !== 1 && opportunity.stage.fixed_stage !== 2) && opportunity.stage.probability > 0 && opportunity.stage.probability < 100 && new Date(opportunity.close_date) < utils.getTodayAtMidnight()) {
                return true;
            }

            return false;
        };

        $scope.getStageByName = function (stageName) {

            return $scope.stages.find(function (stage) {

                return stage.name === stageName;
            });
        };

        $scope.doneLoading = function () {

            if (!$mdMedia('gt-sm')) {
                return $scope.stages && $scope.mobileOpportunities && $scope.mobileOpportunities.loaded;
            }

            return ($scope.opportunitiesPerStage.length > 0 || ($scope.stages && $scope.stages.length === 0)) && $scope.opportunitiesPerStage.every(function (stage) {

                return stage.loaded;
            });
        };

        $scope.showEmptyState = function () {

            if ($scope.badFilterError) {

                return true;
            }

            return $scope.entityCountObject.viewCurrentCount === 0 && $scope.isOpportunityFilterApplied() && !$scope.searchObject.getSearch() && $scope.doneLoading();
        };

        $scope.getEntityCountString = function () {

            return utils.getEntityCountString($scope.entityCountObject);
        };

        ////////////////////////

        /**
         * @param {Object} options
         * @param {Array.<Number>} [options.stagesToReload]
         */
        function get(options) {

            if (!options) {
                options = {};
            }

            if (sfWalkthrough.isShowing()) {
                $scope.currentPipeline = sfWalkthrough.getData('pipelines')[0];
            }

            if (!$scope.currentPipeline) {
                return;
            }

            $scope.usedBadFilter = false;

            $scope.stages = $scope.currentPipeline.stages;

            if ($mdMedia('gt-sm')) {
                if (options.stagesToReload) {
                    $scope.opportunitiesPerStage.filter(function (stage) {

                        return options.stagesToReload.includes(stage.stageInfo.id);
                    }).forEach(function (stage) {

                        stage.reload();
                    });
                }
                else {
                    // We need to reset the opportunities when reloading fully
                    // If we don't do this we get a mismatch between these opportunities and the virtual objects
                    $scope.opportunities.items = [];
                    $scope.opportunitiesPerStage = $scope.stages.map(function (stage) {

                        return initVirtualRepeatObject(stage);
                    });

                    /**
                     * Trigger fetching the opps manually.
                     * Since we hide the stages with an ng-if, the virtual repeat won't kick in by itself.
                     * So we do it here.
                     * This also kicks of all calls in parallel while it seemed that the virtual repeats were doing things in serial order.
                     * Probably also caused by the fact that they were rendering the repeats 1 by 1.
                     */
                    $scope.opportunitiesPerStage.forEach(function (stage) {

                        stage.getItemAtIndex(1);
                    });
                }
            }
            else {
                $scope.mobileOpportunities = initVirtualRepeatObject();
            }

            let defaultFilters = filterService.getCurrentPipelineFilter($scope.currentPipeline.id);
            let newFilters = filterService.getFilter('opportunity');
            // Filter out the mandatory/hidden pipeline rule, to see if there are actually user applied rules
            const appliedFilters = filterService.getFilter('opportunity').filter(function (rule) {

                return rule.id !== 'opportunity.pipeline.id';
            });

            if ($scope.searchObject.getSearch()) {
                newFilters = defaultFilters;
            }

            if (appliedFilters.length > 0 && !$scope.isOpportunityFilterApplied()) {
                defaultFilters = [...filterService.getDefaultFilters('opportunity', $scope.currentUser, true), ...defaultFilters];
            }

            // Delay the counts a bit as the actual opportunities are more important.
            $timeout(function () {

                // We don't need to refetch the counts when we only reload specific stages, this is usually after a drag/drop.
                // This is a performance optimization.
                if (!options.stagesToReload) {
                    opportunities.filterGet('', defaultFilters, null, null, null, null, null, { returnCountOnly: true }).then(function (response) {

                        countResponseHandler(response, 'totalCount');
                    }, handleServerError, function (response) {

                        countResponseHandler(response, 'totalCount');
                    });

                    opportunities.filterGet($scope.searchObject.getSearch(), [...newFilters, { id: 'opportunity.can_edit', operator: 'equal', value: [true] }], null, null, null, null, null, { returnCountOnly: true }).then(function (response) {

                        countResponseHandler(response, 'canEditCount');
                    }, handleServerError, function (response) {

                        countResponseHandler(response, 'canEditCount');
                    });

                    if ($scope.searchObject.getSearch() || $scope.isOpportunityFilterApplied()) {
                        opportunities.filterGet($scope.searchObject.getSearch(), newFilters, null, null, null, null, null, { returnCountOnly: true }).then(function (response) {

                            countResponseHandler(response, 'currentCount');
                        }, handleServerError, function (response) {

                            countResponseHandler(response, 'currentCount');
                        });
                    }
                    else {
                        // If we don't do a call for the current count, we need to reset it. Otherwise it will never update.
                        $scope.entityCountObject.viewCurrentCount = 0;
                    }
                }

                return opportunities.getStagesMetaInfo($scope.searchObject.getSearch(), newFilters).then(function (response) {

                    stageMetaResponseHandler(response);
                }, handleServerError, function (response) {

                    stageMetaResponseHandler(response);
                });
            }, 200);
        }

        /**
         * `$scope.entityCountObject` is on scope as it is inherited from the `opportunitiesController`.
         * This is needed as that controller handles select etc.
         *
         * @param {Object} response
         * @param {'totalCount' | 'currentCount' | 'canEditCount'} type
         */
        function countResponseHandler(response, type) {

            if (angular.isDefined(response.headers()['x-result-count'])) {
                const appliedFilters = filterService.getFilter('opportunity').filter(function (rule) {

                    return rule.id !== 'opportunity.pipeline.id';
                });
                $scope.entityCountObject.option = angular.isDefined($scope.searchObject.getSearch()) && $scope.searchObject.getSearch() !== '' ? 'search' :  (($scope.isOpportunityFilterApplied() && appliedFilters.length > 0) ? 'filter' : '');
                $scope.entityCountObject.sortString = sortService.getCurrentSortOption('opportunity').sort_string;

                if (type === 'totalCount') {
                    $scope.entityCountObject.viewTotalCount = Number.parseInt(response.headers()['x-result-count']);
                }

                if (type === 'currentCount') {
                    $scope.entityCountObject.viewCurrentCount = Number.parseInt(response.headers()['x-result-count']);
                }

                if (type === 'canEditCount') {
                    $scope.entityCountObject.canEditCount = Number.parseInt(response.headers()['x-result-count']);
                }

                $scope.entityCountObject.string = utils.getEntityCountString($scope.entityCountObject);
            }
        }

        function stageMetaResponseHandler(response) {

            // Easier access
            if ($scope.opportunitiesPerStage.length > 0) {
                response.data.forEach(function (stage) {

                    const virtualRepeatObject = $scope.opportunitiesPerStage.find(function (repeatObject) {

                        return repeatObject.stageInfo.id === stage.id;
                    });

                    virtualRepeatObject.sum = stage.sum;
                    virtualRepeatObject.count = stage.count;
                });
            }

            $scope.stages.forEach(function (stage) {

                const meta = response.data.find(function (sumInfo) {

                    return sumInfo.id === stage.id;
                });

                // If there are no opportunities in the stage, the stage isn't returned
                // So meta can be undefined
                stage.sum = meta ? meta.sum : 0;
                stage.count = meta ? meta.count : 0;
            });
        }

        /**
         * Initializes the object for md-virtual-repeat representing stages and their opportunities.
         * Added for https://github.com/Salesflare/Server/issues/4421.
         *
         * @param {Object} [stage={}] On mobile we show all opps in 1 repeater
         * @returns {Object}
         */
        function initVirtualRepeatObject(stage = {}) {

            return {
                toLoad: 0,
                numLoaded: 0, // Total loaded
                items: [],
                topIndex: 0,

                stageInfo: stage,
                count: 0,
                sum: 0,
                loaded: false,

                getItemAtIndex: function (index) {

                    if (index > this.numLoaded) {
                        this.fetchMoreItems(index);

                        return null;
                    }

                    return this.items[index];
                },

                getLength: function () {

                    if (this.items.length < this.numLoaded) {
                        return this.items.length;
                    }

                    return this.numLoaded + REPEATER_LOADING_STEP;
                },

                fetchMoreItems: function (index) {

                    // Only get new opportunities when not the initial call || this.items.length === 0
                    if (this.toLoad >= index) {
                        return;
                    }

                    this.toLoad += REPEATER_LOADING_STEP;
                    $scope.usedBadFilter = false;

                    return filterGet({ stageId: this.stageInfo.id, limit: REPEATER_LOADING_STEP, offset: this.numLoaded }, opportunitiesResponse(this.stageInfo.id));
                },

                // Forces reset of object
                reload: function () {

                    $scope.showBulkToolbar = false;
                    $scope.allSelected = false;

                    $scope.opportunities.items = [];
                    this.items = [];

                    this.toLoad = 0;
                    this.numLoaded = 0;
                    this.topIndex = 0;
                    this.count = 0;
                    this.sum = 0;
                }
            };
        }

        /**
         * Gets the opportunities
         *
         * @param {Object} options
         * @param {Number} [options.stageId]
         * @param {Number} [options.limit]
         * @param {Number} [options.offset]
         * @param {function(Number):function(Object):void} responseHandler
         * @returns {undefined}
         */
        function filterGet(options, responseHandler) {

            let defaultFilters = filterService.getCurrentPipelineFilter($scope.currentPipeline.id);
            let newFilters = filterService.getFilter('opportunity');
            // Filter out the mandatory/hidden pipeline rule, to see if there are actually user applied rules
            const appliedFilters = filterService.getFilter('opportunity').filter(function (rule) {

                return rule.id !== 'opportunity.pipeline.id';
            });

            if ($scope.searchObject.getSearch()) {
                newFilters = defaultFilters;
            }

            if (appliedFilters.length > 0 && !$scope.isOpportunityFilterApplied()) {
                defaultFilters = [...filterService.getDefaultFilters('opportunity', $scope.currentUser, true), ...defaultFilters];
            }

            if (options.stageId) {
                newFilters.push({ id: 'opportunity.stage.id', operator: 'in', value: [options.stageId] });
            }

            return opportunities.filterGet($scope.searchObject.getSearch(), newFilters, options.limit, options.offset, sortService.getCurrentSortOption('opportunity').order_by, null, null, { includeCount: false })
                .then(responseHandler, handleServerError, responseHandler);
        }

        function opportunitiesResponse(stageId) {

            const virtualRepeatObject = $mdMedia('gt-sm') ? $scope.opportunitiesPerStage.find(function (virtualRepeat) {

                return virtualRepeat.stageInfo.id === stageId;
            }) : $scope.mobileOpportunities;

            if (!virtualRepeatObject) {
                $exceptionHandler(new Error(`Didn't find a repeater object for stage ${stageId}`));
                return;
            }

            return function (response) {

                virtualRepeatObject.loaded = true;
                virtualRepeatObject.numLoaded = virtualRepeatObject.toLoad;

                // Check if the stages we get are from the correct pipeline and stage.
                // The stage check specifically prevents opportunities from the mock service to end up in the wrong stage.
                // This is to prevent showing the 1 opportunity we create during the walkthrough in all stages.
                if (response.data && response.data.length > 0 && response.data[0].pipeline) {
                    if ($scope.$parent && response.data[0].pipeline.name === $scope.$parent.currentPipeline.name && (!stageId || response.data[0].stage.id === stageId)) {
                        // When going through the walkthrough don't bother trying to load more opportunities.
                        // This will only result in dupes in the list as the mock service can't handle offset and always returns the same result.
                        if (virtualRepeatObject.items.length > 0 && sfWalkthrough.isShowing()) {
                            return;
                        }

                        virtualRepeatObject.items = [...virtualRepeatObject.items, ...response.data];

                        /**
                         * This is a very suboptimal way to keep the select/bulk actions working.
                         * It relies on the objects in both arrays to be the same (same pointers).
                         * A better solution would be to use a proper interface to let the OpportunitiesController know when a opp was selected and give the list in the event.
                         * But since this isn't a component this is to much scope creep for the issue.
                         */
                        $scope.opportunities.items = [...$scope.opportunities.items, ...response.data];
                    }
                }

                if ($scope.selectedOpportunity.id) {
                    $mdSidenav('right').open();
                }
            };
        }

        function handleServerError(response) {

            $scope.isLoading = false;

            return $scope.handleServerError(response);
        }

        /**
         * @param {Object} opp
         * @param {Object} stage
         * @returns {void}
         */
        function processDrop(opp, stage) {

            let newIndex = $scope.opportunities.items.length - 1;

            for (let i = 0; i < $scope.opportunities.items.length; ++i) {
                const tempOpp = $scope.opportunities.items[i];

                if (tempOpp.id === opp.id) {
                    $scope.opportunities.items.splice(i, 1); // Remove opportunity from list
                    break;
                }
            }

            for (let i = 0; i < $scope.opportunities.items.length; ++i) {
                const tempOpp2 = $scope.opportunities.items[i];

                if (stage.order < tempOpp2.stage.order) {
                    newIndex = i;
                    break;
                }

                if (stage.order === tempOpp2.stage.order) {
                    if (new Date(opp.last_interaction.date) > new Date(tempOpp2.last_interaction.date)) {
                        newIndex = i;
                        break;
                    }
                }
            }

            opp.stage = stage;
            $scope.opportunities.items.splice(newIndex, 0, opp);
        }

        function finishWalkthrough() {

            $scope.showDragArrow = false;
            $scope.hideDragIndicator = true;
            sfWalkthrough.setDummyOpportunityToWon();
            get({ stagesToReload: ['onboarding-stage-won', 'onboarding-stage-lead'] });
        }

        function getPositionAtCenter(element) {
            const { top, left, width, height } = element.getBoundingClientRect();

            return {
                x: left + (width / 2),
                y: top + (height / 2)
            };
        }

        function getDistanceBetweenElements(a, b) {
            const aPosition = getPositionAtCenter(a);
            const bPosition = getPositionAtCenter(b);

            return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y);
        }

        function setDragArrowWidth() {
            const fromElement = angular.element('.opportunity')[0];
            const toElement = angular.element('.drag-here-indicator:not(.ng-hide)')[0];

            angular.element('.drag-arrow')[0].width = getDistanceBetweenElements(fromElement, toElement);
            $scope.showDragArrow = true;
        }

        function drawDragArrowWhenOpportunityInDOM() {

            const opportunityElement = angular.element('.opportunity')[0];

            if (!opportunityElement) {
                return $timeout(drawDragArrowWhenOpportunityInDOM, 100);
            }

            setDragArrowWidth();
        }
    }
})();
