(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditPipelineDialogController', EditPipelineDialogController);

    function EditPipelineDialogController($scope, $mdDialog, currencies, flagsService, model) {

        $scope.pipeline = null;
        $scope.showPlanFlags = function () {

            const onTrial = model.me.team.payment_type !== 'free' && !model.me.team.subscribed && model.me.trial_expiry_date;
            return (onTrial || !model.me.plan_flags.permissions);
        };

        $scope.canAccessPermissions = function () {

            return model.me.plan_flags.permissions;
        };

        $scope.canAccessMultipleCurrencies = () => {

            return flagsService.get('multipleCurrencies') === true;
        };

        $scope.groups = this.groups;
        $scope.defaultCurrency = model.me.team.currency;

        currencies.get().then(currenciesResponse, null, currenciesResponse);

        if (this.pipeline) {
            $scope.pipeline = this.pipeline;
            $scope.wasRecurring = this.pipeline.recurring;
        }
        else {
            $scope.pipeline = {
                groups: angular.copy($scope.groups),
                currency: null,
                follows_default_team_currency: true
            };
            $scope.isNew = true;
        }

        const originalPipeline = angular.copy($scope.pipeline);

        $scope.setPipelineFollowsDefaultCurrency = (val) => {

            $scope.pipeline.follows_default_team_currency = val;
        };

        $scope.getGroupsSelectedText = function () {

            if (!$scope.pipeline.groups || $scope.pipeline.groups.length === 0) {
                return 'Group access';
            }

            if ($scope.pipeline.groups.length === $scope.groups.length) {
                return 'All groups';
            }

            return $scope.pipeline.groups.map(function (pipeline) {

                return pipeline.name;
            }).join(', ');
        };

        $scope.save = function () {

            if (!$scope.pipelineForm.$valid || !$scope.pipeline) {
                return;
            }

            if ($scope.canAccessPermissions() && model.me.group) {
                const updatedPipelineGroupsContainOwnGroup = !!($scope.pipeline.groups.some(function (group) {

                    return group.id === model.me.group;
                }));
                const originalPipelineGroupsContainOwnGroup = !!(originalPipeline.groups.some(function (group) {

                    return group.id === model.me.group;
                }));

                if (originalPipelineGroupsContainOwnGroup && !updatedPipelineGroupsContainOwnGroup) {
                    const confirmGroupRemoval = $mdDialog.confirm({ multiple: true })
                        .clickOutsideToClose(true)
                        .escapeToClose(true)
                        .title('Are you sure?')
                        .textContent('If you remove access for your own group, you will not be able to see this pipeline anymore.')
                        .ok('I\'m sure')
                        .cancel('cancel');

                    return $mdDialog.show(confirmGroupRemoval).then(function () {

                        if (!$scope.isNew && $scope.wasRecurring && !$scope.pipeline.recurring) {
                            const confirm = $mdDialog.confirm({ multiple: true })
                                .htmlContent('Switching off recurring revenue will make all opportunity values one-time values. <br/> You will lose all data about the recurring contracts. Do you want to continue?')
                                .ok('Yes')
                                .cancel('No');

                            return $mdDialog.show(confirm).then(function () {

                                // Hiding the parent dialog
                                return $mdDialog.hide($scope.pipeline);
                            });
                        }

                        return $mdDialog.hide($scope.pipeline);
                    }).catch(function () {

                        $scope.pipeline.groups = originalPipeline.groups;
                    });
                }
            }

            if (!$scope.isNew && $scope.wasRecurring && !$scope.pipeline.recurring) {
                const confirm = $mdDialog.confirm({ multiple: true })
                    .htmlContent('Switching off recurring revenue will make all opportunity values one-time values. <br/> You will lose all data about the recurring contracts. Do you want to continue?')
                    .ok('Yes')
                    .cancel('No');

                return $mdDialog.show(confirm).then(function () {

                    // Hiding the parent dialog
                    return $mdDialog.hide($scope.pipeline);
                });
            }

            return $mdDialog.hide($scope.pipeline);
        };

        $scope.close = $mdDialog.hide;

        function currenciesResponse(response) {

            $scope.currencies = response.data;
        }
    }
})();
