(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ChooseAudienceController', ChooseAudienceController);

    function ChooseAudienceController($mdDialog, $mdMedia, $scope, contactsService) {

        $scope.$mdMedia = $mdMedia;
        $scope.filterRules = (this.filter && this.filter.rules) || [];
        $scope.hasInternalRules = this.hasInternalRules;
        $scope.step = $scope.hasInternalRules ? 2 : 1;
        $scope.invalidFilterEmptyStateText = 'It looks like you are using filters that include custom fields that have been deleted. Please remove the filters to continue.';

        let currentFilterContactsCount;
        const isFilterApplied = this.isFilterApplied;

        function setFilterHeaderText() {

            $scope.filterHeaderText =  (currentFilterContactsCount + (currentFilterContactsCount === 1 ? ' contact' : ' contacts') + ' in this filter will receive this workflow');
        }

        // Virtual repeat + infinite scroll object that angular material uses
        $scope.contacts = {
            toLoad: 0,
            numLoaded: 0,
            items: [],

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + 20;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad < index) {
                    const isVirtualRepeatEmpty = this.toLoad === 0;
                    this.toLoad += 20;

                    const boundFirstResponseHandler = angular.bind(this, contactsResponse);
                    const boundSecondResponseHandler = angular.bind(this, function (response) {

                        this.items = [...this.items, ...response.data];
                        this.numLoaded = this.toLoad;
                    });
                    const boundErrorHandler = angular.bind(this, function () {

                        $scope.showInvalidFilterEmptyState = true;
                        this.numLoaded = this.toLoad;
                        this.doneLoading = true;
                    });

                    const filterGetOptions = {
                        filterRules: $scope.filterRules,
                        limit: 20,
                        offset: this.toLoad <= 20 ? 0 : (this.toLoad - 20),
                        ignoreLoadingBar: !$mdMedia('gt-sm'),
                        includeCount: true
                    };

                    return contactsService.filterGet(filterGetOptions).then(function (response) {

                        $scope.showInvalidFilterEmptyState = false;

                        if (isVirtualRepeatEmpty) {
                            return boundFirstResponseHandler(response);
                        }

                        return boundSecondResponseHandler(response);
                    }).catch(boundErrorHandler);
                }
            },

            // Forces reset of object
            reload: function () {

                this.toLoad = 0;
                this.numLoaded = 0;
                this.items = [];
                this.doneLoading = false;
            }
        };

        function contactsResponse(response) {

            this.items = [...this.items, ...response.data];
            this.numLoaded = this.toLoad;
            this.doneLoading = true;

            currentFilterContactsCount = Number.parseInt(response.headers()['x-result-count']);
            setFilterHeaderText();
        }

        $scope.ruleSetContainsDisabledRules = function () {

            return $scope.filterRules?.some(function (filterRule) {

                return filterRule.disabled;
            });
        };

        $scope.onFilterApply = function (appliedFilter) {

            $scope.filterRules = appliedFilter.rules;
            $scope.filters = {
                condition: 'AND',
                rules: $scope.filterRules
            };
            const filterGetOptions = {
                filterRules: $scope.filterRules,
                limit: 20,
                offset: 0,
                includeCount: true
            };

            return contactsService.filterGet(filterGetOptions).then(function (response) {

                currentFilterContactsCount = Number.parseInt(response.headers()['x-result-count']);
                setFilterHeaderText();

                return $scope.contacts.reload();
            }).catch(function (err) {

                if (err.status === 422) {
                    $scope.filterRules.forEach(function (filterRule) {

                        if (err.data['disabled-custom-fields'].includes(filterRule.id)) {
                            filterRule.disabled = true;
                        }
                    });

                    return $scope.contacts.reload();
                }
            });
        };

        $scope.nextStep = function () {

            $scope.step++;
        };

        $scope.previousStep = function () {

            $scope.step--;
        };

        $scope.saveAudience = function () {

            if ($scope.filterRules?.length === 0) {
                const confirm = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title('No filter applied')
                    .textContent('Please apply at least one filter to your contacts.')
                    .ok('Got it');

                return $mdDialog.show(confirm);
            }

            const filter = { condition: 'AND', rules: $scope.filterRules };

            return $mdDialog.hide({
                filterText: (isFilterApplied && isFilterApplied(filter)) ? $scope.filterHeaderText.split(' will receive this workflow')[0] : null,
                filter
            });
        };

        $scope.cancelDialog = $mdDialog.cancel;
    }
})();
