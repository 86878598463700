/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-eq-null */

import { Component, OnInit, Input, Inject } from '@angular/core';
import { CommonModule, KeyValue } from '@angular/common';

import { of, from, forkJoin } from 'rxjs';

import { filter, mergeMap, catchError } from 'rxjs/operators';

import { MediaService } from '@core/services/media.service';
import { NotificationService } from '@core/services/notifications.service';

import { PlanModel } from '@shared/models/plan.model';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import {
    DialogResponse,
    SfxDialog
} from '@shared/components/dialog/dialog.component';
import {
    UpgradePlanContentComponent
} from '@feature/settings/components/billing-usage/dialogs/upgrade-plan-content.component';
import {
    DowngradePlanContentComponent
} from '@feature/settings/components/billing-usage/dialogs/downgrade-plan-content.component';


@Component({
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule
    ],
    selector: 'sfx-plans',
    templateUrl: './plans.component.html',
    styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {

    @Input() fromBilling?: boolean;
    backToBilling?: boolean = false;

    private _showTooltip = false;

    currency = '';
    subscribedOrNonRestrictedSumo?: boolean;
    discount?: string;

    plans: { [key: string]: PlanModel } = {};
    currentPlan: PlanModel;

    constructor(
        public media: MediaService,
        public matDialog: MatDialog,
        public notificationService: NotificationService,
        @Inject('authenticationService') private authenticationService: any,
        @Inject('billingService') private billingService: any,
        @Inject('exceptionHandler') public exceptionHandler: any,
        @Inject('modelService') private modelService: any,
        @Inject('planService') private planService: any,
        @Inject('stateParams') private stateParams: any,
        @Inject('stateService') private stateService: any,
        @Inject('stripeService') private stripeService: any
    ) {}

    public ngOnInit(): void {

        this.backToBilling = this.fromBilling == null ? !!this.stateParams.fromBilling : this.fromBilling;
        this.subscribedOrNonRestrictedSumo = this.modelService.me.team.subscribed || (this.modelService.me.team.discount_type === 'firstFreeThen50Off' && !this.modelService.me.restricted);

        this.prepare();
    }

    public changePlan(newPlan: PlanModel): any {

        let action;

        if (!this.subscribedOrNonRestrictedSumo) {
            action = 'subscribe';
        }
        else if (this.currentPlan.id < newPlan.id) {
            action = 'upgrade';
        }
        else if (this.currentPlan.id > newPlan.id) {
            action = 'downgrade';
        }

        if (this.modelService.me.role.name !== 'Admin') {
            return this.matDialog.open(SfxDialog, {
                data: {
                    title: `Please ask an admin to ${action}`,
                    htmlBody: `<div>You don't have the permissions to ${action}.</div><div>Please ask a team admin to ${action} to your desired plan.</div>`,
                    confirm: 'Got it'
                }
            });
        }

        if (action === 'subscribe') {
            // If we didn't come from the billing page, we want the tab closed when subscribing is finished.
            // Otherwise we end up with 2 tabs of the app, since checkout normally returns to the billing overview in the settings.

            this.stateService.go('checkout', { plan: newPlan.name.toLowerCase(), closeTabOnFinish: !this.backToBilling });
        }

        if (action === 'upgrade') {
            this.billingService.get().then((response: any) => {

                const upgradeDialog = this.matDialog.open(SfxDialog, {
                    panelClass: 'sfx-dialog-container-sm',
                    data: {
                        close: 'Cancel',
                        confirm: 'Upgrade',
                        content: UpgradePlanContentComponent,
                        context: { plan: newPlan, billingFrequency: response.data.billing_frequency }
                    }
                });

                upgradeDialog.afterClosed()
                    .pipe(
                        filter((context: DialogResponse<UpgradePlanContentComponent>) => context?.confirmed),
                        mergeMap((context: DialogResponse<UpgradePlanContentComponent>) => {

                            return forkJoin([
                                of(context),
                                from(this.billingService.updatePlan(context.state.plan.id))
                            ]);
                        }),
                        catchError(() => {

                            return of([null, null]);
                        })
                    )
                    .subscribe({
                        next: ([context, result]) => {

                            if (result && context) {
                                this.notificationService.success(`Congratulations! Your plan has been successfully upgraded to ${context.state.plan.name}.`);
                            }

                            this.authenticationService.fetchMe();
                        }
                    });
            });
        }

        if (action === 'downgrade') {

            const downgradeDialog = this.matDialog.open(SfxDialog, {
                panelClass: 'sfx-dialog-container-sm',
                data: {
                    close: 'Cancel',
                    confirm: 'Upgrade',
                    content: DowngradePlanContentComponent,
                    context: { plans: this.plans, currentPlan: this.currentPlan, futurePlan: newPlan, onTrial: this.modelService.me.team.on_trial }
                }
            });

            downgradeDialog.afterClosed()
                .pipe(
                    filter((context: DialogResponse<DowngradePlanContentComponent>) => context?.confirmed),
                    mergeMap((context: DialogResponse<DowngradePlanContentComponent>) => {

                        return forkJoin([
                            of(context),
                            from(
                                this.billingService.downgrade(context.state.futurePlan.id).catch((err: any) => {

                                    this.notificationService.error(err.message);
                                    return of(null);
                                })
                            )
                        ]);
                    }),
                    mergeMap(([context, response]) => {

                        if (!response) {

                            return forkJoin([of(null), of(null)]);
                        }

                        const data = (response as any).data;

                        if (data.status === 'requires_action') {

                            return forkJoin([
                                of(context),
                                from(this.stripeService.stripe.handleCardPayment(data.client_secret))
                            ]);
                        }

                        return forkJoin([
                            of(context),
                            of(null)
                        ]);
                    })
                ).subscribe(
                    {
                        next: ([context, result]) => {

                            if (!context && !result) {

                                return;
                            }

                            const response = result as any;

                            if (response?.error) {

                                this.notificationService.error(response.error.message);
                                const formattedError: any = new Error(response.error.code);
                                formattedError.data = response.error;

                                this.exceptionHandler(formattedError);
                                return;
                            }

                            const planDowngradedText = `Your plan has been successfully downgraded to the ${context.state.futurePlan.name} plan.\`;`;

                            this.notificationService.success(planDowngradedText);

                            this.authenticationService.fetchMe();

                            if (this.backToBilling) {

                                this.stateService.go('settings.billingSettings.overview');
                            }

                            this.prepare();
                        }
                    });
        }
    }

    public prepare(): void {

        this.preparePlans();
        this.prepareCurrency();

        for (const [planName, plan] of Object.entries<PlanModel>(this.plans)) {

            this.prepareButtonText(plan);
            this.preparePlanFeatures(plan);
        }
    }

    public preparePlans(): void {

        this.plans = this.planService.get();

        const currentPlanId = this.modelService.me.team.plan;

        for (const plan in this.plans) {
            if (this.plans[plan].id === currentPlanId) {
                this.currentPlan = this.plans[plan];
            }
        }
    }

    public prepareButtonText(plan: PlanModel): void {

        plan.buttonText = this.getButtonText(plan);
    }

    public preparePlanFeatures(plan: PlanModel): void {

        plan.features.map((feature) => {

            if (!feature.name) {

                return feature;
            }

            const name = feature.name.split(' ');
            feature.lastWordName = name[name.length - 1];

            return feature;
        });
    }

    public prepareCurrency(): void {

        this.currency = this.modelService.me.team.billing_currency?.html === '&#128' ? this.modelService.me.team.billing_currency?.html : '&#36;';
    }

    public getButtonText(plan: PlanModel) {

        if (!this.subscribedOrNonRestrictedSumo) {

            return 'Subscribe to ' + plan.name;
        }

        if (this.modelService.me.team.plan < plan.id) {

            return 'Upgrade to ' + plan.name;
        }

        if (this.modelService.me.team.plan > plan.id) {

            return 'Downgrade to ' + plan.name;
        }

        return 'Currently on ' + plan.name;
    }

    customOrder = (a: KeyValue<string, PlanModel>, b: KeyValue<string, PlanModel>): number => {
        //eslint-disable-next-line unicorn/no-nested-ternary
        return a.key === 'Pro' ? 0 : (a.key === 'Growth' ? -1 : a.key === 'Enterprise' ? 1 : 0);
    }
}
