(function () {
    'use strict';

    angular
        .module('salesflare.components.workflowStepAnalytics', [
            'salesflare.components.workflowStepAnalytics.sendEmailAudience'
        ])
        .component('sfWorkflowStepAnalytics', {
            templateUrl: 'app-ajs/components/workflows/workflow/workflowstep/analytics/workflowStepAnalytics.html',
            controller,
            controllerAs: 'vm'
        });

    function controller($document, $state, $stateParams, $mdMedia, $timeout, workflowService, contactsService, utils, $exceptionHandler) {

        const vm = this;
        let searchDebounce;
        const allRecordTypeStrings = {
            contact: {
                singular: 'contact',
                plural: 'contacts'
            }
        };
        let currentCount = $stateParams.currentCount || 0;

        vm.$mdMedia = $mdMedia;
        vm.insufficientPermissionsMessage = 'You don\'t have permission to edit this workflow. Ask an admin.';

        vm.searchObject = {
            search: $stateParams.search,
            getSearch: function () {

                return this.search;
            },
            setSearch: function (s) {

                this.search = s;

                return utils.setSearchInUrl({ search: this.search });
            }
        };

        vm.searchMode = !!$stateParams.search;

        vm.$onInit = function () {

            // Set state params, workflow and workflowStep are only filled via the stateParams when changing tabs in the analytics view
            vm.workflowId = $stateParams.id;
            vm.viewingTab = $stateParams.viewing;
            vm.workflowStepId = Number.parseInt($stateParams.stepId);
            vm.workflow = $stateParams.workflow;
            vm.workflowStep = $stateParams.workflowStep;

            if (!vm.workflow || !vm.workflowStep) {
                return workflowService.get(vm.workflowId).then(function (response) {

                    vm.workflow = response.data;
                    vm.workflowStep = vm.workflow.steps.find(function (step) {

                        return step.id === vm.workflowStepId;
                    });

                    setDataWhenWorkflowStepAvailable();
                });
            }

            setDataWhenWorkflowStepAvailable();
        };

        vm.audience = {
            toLoad: 0,
            numLoaded: 0,
            items: [],
            topIndex: 0,

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + 20;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad < index) {
                    this.toLoad += 20;

                    const bound = angular.bind(this, audienceResponseHandler);

                    const filter = angular.copy(vm.viewingTabInfo.filter);

                    if (vm.searchObject.getSearch()) {
                        filter.search = vm.searchObject.getSearch();
                    }

                    const requestConfig = {
                        filter
                    };

                    if (this.items.length === 0) {
                        workflowService.getStepAudience(vm.workflowId, vm.workflowStepId, requestConfig, { returnCountOnly: true }).then(function (response) {

                            currentCount = Number.parseInt(response.headers('x-result-count'));
                        });
                        requestConfig.limit = this.toLoad;
                        return workflowService.getStepAudience(vm.workflowId, vm.workflowStepId, requestConfig).then(bound, null, bound);
                    }

                    requestConfig.limit = 20;
                    requestConfig.offset = this.numLoaded;

                    return workflowService.getStepAudience(vm.workflowId, vm.workflowStepId, requestConfig).then(angular.bind(this, function (response) {

                        this.items = [...this.items, ...response.data];
                        this.numLoaded = this.toLoad;
                    }));
                }
            },

            // Forces reset of object
            reload: function () {

                this.toLoad = 0;
                this.numLoaded = 0;
                this.items = [];
                this.doneLoading = false;
                this.topIndex = 0;
            }
        };

        function audienceResponseHandler(response) {

            this.items = response.data;
            this.numLoaded = this.toLoad;
            vm.showEmptyState = response.data.length === 0;
            this.doneLoading = true;
        }

        vm.doSearch = function (search) {

            vm.showEmptyState = false;
            $timeout.cancel(searchDebounce);

            searchDebounce = $timeout(function () {

                vm.searchObject.setSearch(search);

                return vm.audience.reload();
            }, 750);
        };

        vm.clearSearch = function () {

            // Don't execute debounced search if search cleared
            $timeout.cancel(searchDebounce);
            vm.searchObject.setSearch('');
            return vm.audience.reload();
        };

        vm.setSearchMode = function (mode) {

            if (vm.searchMode && !mode) {
                vm.searchMode = mode;

                if (!mode && vm.searchObject.getSearch()) {
                    // Since we're going to do a reload of the data, we don't know yet if we need to show the empty state
                    vm.showEmptyState = false;
                    return vm.clearSearch();
                }

                vm.searchObject.setSearch('');
            }

            vm.searchMode = mode;

            if (vm.searchMode) {
                utils.forceFocus($document[0].querySelector('#searchbox'));
            }
        };

        vm.back = function () {
            return $state.go('workflow', { id: $stateParams.id });
        };

        vm.selectTab = function (analyticName) {

            if (analyticName === vm.viewingTab) {
                return;
            }

            const filter = angular.copy(vm.stepInfo[analyticName][0].filter);

            const requestConfig = {
                filter
            };

            // Update count and refetch workflow step so analytics numbers update as well
            return workflowService.getStepAudience(vm.workflowId, vm.workflowStepId,requestConfig, { returnCountOnly: true }).then(function (countResponse) {

                return workflowService.get(vm.workflowId).then(function (response) {

                    return $state.go('workflow-stepAnalytics', {
                        id: $stateParams.id,
                        viewing: analyticName,
                        currentCount: Number.parseInt(countResponse.headers('x-result-count')),
                        workflow: response.data,
                        workflowStep: response.data.steps.find(function (step) {

                            return step.id === vm.workflowStepId;
                        })
                    });
                });
            });
        };

        vm.onSublistChange = function () {

            const filter = angular.copy(vm.viewingTabInfo.filter);

            if (vm.searchObject.getSearch()) {
                vm.searchObject.setSearch();
            }

            const requestConfig = {
                filter
            };

            return workflowService.getStepAudience(vm.workflowId, vm.workflowStepId, requestConfig, { returnCountOnly: true }).then(function (response) {

                currentCount = Number.parseInt(response.headers('x-result-count'));
                vm.audience.reload();
            });
        };

        // TODO: Make generic for different kind of workflow step actions
        vm.getTitle = function () {

            if (!vm.workflowStep || !vm.workflow) {
                return;
            }

            let title;
            const recordTypeStrings = allRecordTypeStrings[vm.workflow.record_type];

            switch (vm.viewingTabInfo.id) {
                case 'total':
                    return currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular + ' has') : (' ' + recordTypeStrings.plural + ' have')) + ' entered ' + vm.workflowStep.name;
                case 'sent':
                    title = vm.workflowStep.name + ' has been sent to ' + currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular) : (' ' + recordTypeStrings.plural));
                    return title;
                case 'opened':
                    title = currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular + ' has') : (' ' + recordTypeStrings.plural + ' have')) + ' opened ' + vm.workflowStep.name;
                    return title;
                case 'not_opened':
                    title = currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular + ' hasn\'t') : (' ' + recordTypeStrings.plural + ' haven\'t')) + ' opened ' + vm.workflowStep.name;
                    return title;
                case 'clicked':
                    title = currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular + ' has') : (' ' + recordTypeStrings.plural + ' have')) + ' clicked ' + vm.workflowStep.name;
                    return title;
                case 'not_clicked':
                    title = currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular + ' hasn\'t') : (' ' + recordTypeStrings.plural + ' haven\'t')) + ' clicked ' + vm.workflowStep.name;
                    return title;
                case 'replied':
                    title = currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular + ' has') : (' ' + recordTypeStrings.plural + ' have')) + ' replied to ' + vm.workflowStep.name;
                    return title;
                case 'not_replied':
                    title = currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular + ' hasn\'t') : (' ' + recordTypeStrings.plural + ' haven\'t')) + ' replied to ' + vm.workflowStep.name;
                    return title;
                case 'failed':
                    title = vm.workflowStep.name + ' has failed to send to ' + currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular) : (' ' + recordTypeStrings.plural));
                    return title;
                case 'not_failed':
                    title = vm.workflowStep.name + ' hasn\'t failed to send to ' + currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular) : (' ' + recordTypeStrings.plural));
                    return title;
                case 'unsubscribed':
                    title = currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular + ' has') : (' ' + recordTypeStrings.plural + ' have')) + ' unsubscribed from ' + vm.workflowStep.name;
                    return title;
                case 'not_unsubscribed':
                    title = currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular + ' hasn\'t') : (' ' + recordTypeStrings.plural + ' haven\'t')) + ' unsubscribed from ' + vm.workflowStep.name;
                    return title;
                case 'bounced':
                    title = currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular + '\'s email') : (' ' + recordTypeStrings.plural + '\' emails')) + ' bounced for ' + vm.workflowStep.name;
                    return title;
                case 'not_bounced':
                    title = currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular + '\'s email hasn\'t') : (' ' + recordTypeStrings.plural + '\' emails haven\'t')) + ' bounced for ' + vm.workflowStep.name;
                    return title;
            }

            return title;
        };

        vm.send = function () {

            // We need to include the 'internal' and 'query_builder_id' properties here for the humanReadableAdvancedFilter service to work correctly
            const filter = angular.copy(vm.viewingTabInfo.filter);
            filter.rules.push({
                id: 'workflow.step.id',
                query_builder_id: 'workflow_step_record.workflow_step',
                operator: 'equal',
                value: [vm.workflowStep.id],
                internal: true
            });

            return $state.go('composeWorkflow', {
                filter,
                disableEditFilter: true,
                filterText: getFilterText()
            });
        };

        function getFilterText() {

            switch (vm.viewingTabInfo.id) {
                case 'sent':
                    return 'All contacts receiving the email';
                case 'opened':
                    return 'Contacts who have opened this email';
                case 'not_opened':
                    return 'Contacts who haven\'t opened this email';
                case 'clicked':
                    return 'Contacts who have clicked this email';
                case 'not_clicked':
                    return 'Contacts who haven\'t clicked this email';
                case 'replied':
                    return 'Contacts who have replied to this email';
                case 'not_replied':
                    return 'Contacts who haven\'t replied to this email';
                default: {
                    const error = new Error('No filtertext match for "' + vm.viewingTabInfo.id + '"');
                    error.tabInfo = vm.viewingTabInfo;
                    $exceptionHandler(error);
                    return '';
                }
            }
        }

        vm.export = function (type) {

            if (!vm.workflowStep) {
                return;
            }

            const filter = angular.copy(vm.viewingTabInfo.filter);
            filter.rules.push({
                id: 'workflow.step.id',
                operator: 'equal',
                value: [vm.workflowStep.id]
            });

            if (vm.workflow.record_type === 'contact') {
                const filterGetOptions = {
                    filterRules: filter,
                    limit: 100000,
                    offset: 0,
                    downloadCSV: type === 'csv',
                    downloadExcel: type === 'excel'
                };

                return contactsService.filterGet(filterGetOptions);
            }
        };

        // Execute when vm.workflow and vm.workflowStep are initialized
        function setDataWhenWorkflowStepAvailable() {

            vm.stepInfo = workflowService.getActionTypeTabInfo(vm.workflowStep.action_type);
            vm.analytics = workflowService.prepareAnalyticData(vm.workflowStep.analytics);
            vm.viewingTabInfo = vm.stepInfo[vm.viewingTab][0];
            // TODO: Remove and handle in service to generalize the implementation
            vm.trackingEnabled = vm.workflowStep.action_config.message.tracked;
        }
    }
})();
