(function () {
    'use strict';

    angular
        .module('salesflare.components.bulkEditTasks', [])
        .component('sfBulkEditTasks', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/bulkedittasks/bulkedittasks.html',
            bindings: {
                options: '<'
            }
        });

    function controller($rootScope, $q, $mdMedia, $mdDialog, $state, $exceptionHandler, utils, bulkService, accounts) {

        const vm = this;

        vm.$onInit = function () {

            if (!vm.options || Object.keys(vm.options).length === 0) {
                return vm.back();
            }

            vm.$mdMedia = $mdMedia;
            vm.updating = false;
            vm.fields = [
                {
                    name: 'Account',
                    type: {}
                },
                {
                    name: 'Description',
                    type: {}
                }
            ];
        };

        vm.resetTask = function () {

            vm.task = {};

            if (vm.selectedField.name === 'Account') {
                vm.task.account = null;
            }

            if (vm.selectedField.name === 'Description') {
                vm.task.description = '';
            }
        };

        vm.searchAccounts = function searchAccounts(query) {

            return accounts.get(query).then(function (response) {

                return $q.resolve(response.data);
            }, null, function (response) {

                return $q.resolve(response.data);
            });
        };

        vm.back = function () {

            if ($mdMedia('gt-sm')) {
                return $mdDialog.hide(false);
            }

            return $rootScope.back();
        };

        vm.setAccountEditableValidity = function () {

            vm.taskForm.account.$setValidity('accountEditable', vm.task.account ? vm.task.account.can_edit : true);
        };

        vm.save = function () {

            if (!vm.taskForm.$valid) {
                return;
            }

            vm.updating = true;

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .title('Are you sure?')
                .textContent('These changes will be applied to all the selected tasks!')
                .ok('I\'m sure')
                .cancel('cancel');

            return $mdDialog.show(confirm).then(function () {

                const task = angular.copy(vm.task);

                // Remove all empty arrays []
                utils.stripEmptyArray(task);

                if (task.account) {
                    task.account = task.account.id;
                }

                return bulkService.updateTasks({ filter: vm.options, update: task })
                    .then(function () {

                        if ($mdMedia('gt-sm')) {
                            return $mdDialog.hide(true);
                        }

                        return $state.go('tasks.today');
                    }).catch(function (err) {

                        vm.updating = false;

                        $exceptionHandler(err);

                        return utils.showErrorToast();
                    });
            }).catch(function () {

                vm.updating = false;
            });
        };
    }
})();
