(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('PipelineSettingsController', PipelineSettingsController);

    function PipelineSettingsController($scope, $mdDialog, model, pipelines, filterService, utils, groups) {

        $scope.model = model;
        $scope.currentUser = model.me;
        $scope.pipelines = null;
        $scope.stages = null;
        $scope.currentPlan = model.me.team.plan;

        get();

        $scope.pipelineSortOptions = {
            longTouch: true,
            orderChanged: function () {

                return $scope.pipelines.forEach(function (pipeline, index) {

                    pipeline.order = index + 1;
                });
            },
            accept: function (sourceItemHandleScope, destSortableScope) {

                return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
            },
            dragStart: function () {

                $scope.draggingPipeline = true;
            },
            dragEnd: function () {

                $scope.draggingPipeline = false;

                return pipelines.update($scope.pipelines).then(get);
            }
        };

        $scope.setStageSortOptions = function (pipeline) {

            return {
                longTouch: true,
                orderChanged: function () {

                    return pipeline.stages.forEach(function (stage, index) {

                        if (stage.order !== index + 1) {
                            stage.order = index + 1;
                            stage._dirty = true;
                        }
                    });
                },
                accept: function (sourceItemHandleScope, destSortableScope) {

                    return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
                },
                dragStart: function (type) {

                    $scope.currentPipeline = pipeline.id;
                    $scope.draggingStage = type.source.index;
                },
                dragEnd: function () {

                    $scope.currentPipeline = undefined;
                    return pipelines.update($scope.pipelines).then(get);
                }
            };
        };

        $scope.createStage = function () {

            return $mdDialog.show({
                clickOutsideToClose: true,
                multiple: true,
                controller: 'EditStageDialogController',
                templateUrl: 'partials/editstagedialog.html',
                locals: {
                    stage: null,
                    pipelines: $scope.pipelines
                },
                bindToController: true,
                onComplete: function (scope, element) {

                    return element['0'].querySelectorAll('INPUT')[0].focus();
                }
            }).then(function (stage) {

                stage._dirty = true;

                const foundPipeline = $scope.pipelines.find(function (pipeline) {

                    return pipeline.id === stage.pipeline.id;
                });

                stage.order = foundPipeline.stages.length + 1;

                foundPipeline.stages.push(stage);

                delete stage.pipeline;

                return pipelines.update($scope.pipelines).then(get);
            });
        };

        $scope.updatePipeline = function (item, event) {

            event.stopPropagation();

            return $scope.editPipeline(item);
        };

        $scope.createPipeline = function () {

            return $scope.editPipeline();
        };

        $scope.editPipeline = function (item) {

            return $mdDialog.show({
                clickOutsideToClose: true,
                controller: 'EditPipelineDialogController',
                templateUrl: 'partials/editpipelinedialog.html',
                locals: {
                    pipeline: item,
                    groups: $scope.groups
                },
                bindToController: true,
                onComplete: function (scope, element) {

                    return element['0'].querySelectorAll('INPUT')[0].focus();
                }
            }).then(function (pipeline) {

                if (item) {
                    $scope.pipelines = $scope.pipelines.map(function (pl) {

                        if (pl.id === pipeline.id) {
                            return pipeline;
                        }

                        return pl;
                    });
                }
                else {
                    pipeline.order = $scope.pipelines.length + 1;
                    $scope.pipelines.push(pipeline);
                }

                return pipelines.update($scope.pipelines).then(get).catch(get);
            });
        };

        $scope.deletePipeline = function (pipeline, event) {

            // Prevent event triggering other stuff like dragging
            event.stopPropagation();

            pipeline.archived = true;

            return pipelines.update($scope.pipelines).then(function () {

                return get().then(function () {

                    const currentPipelineId = store.get('current_pipeline_' + model.me.id);

                    if (pipeline.id === currentPipelineId) {
                        filterService.setCurrentPipelineId($scope.pipelines[0].id);
                    }
                });
            }, get);
        };

        $scope.updateStage = function (item, pipeline, event) {

            // Prevent event triggering other stuff like dragging
            event.stopPropagation();

            return $mdDialog.show({
                clickOutsideToClose: true,
                multiple: true,
                controller: 'EditStageDialogController',
                templateUrl: 'partials/editstagedialog.html',
                locals: {
                    stage: angular.copy(item),
                    pipelines: $scope.pipelines,
                    pipeline
                },
                bindToController: true,
                onComplete: function (scope, element) {

                    return element['0'].querySelectorAll('INPUT')[0].focus();
                }
            }).then(function (stage) {

                if (!angular.equals(item, stage)) {
                    stage._dirty = true;
                }

                // eslint-disable-next-line no-shadow
                const currentPipeline = $scope.pipelines.find(function (pipeline) {

                    return pipeline.id === stage.pipeline.id;
                });

                let stageAlreadyInPipeline = false;
                for (let i = 0; i < currentPipeline.stages.length; ++i) {
                    if (currentPipeline.stages[i].id === stage.id) {
                        currentPipeline.stages[i] = stage;
                        stageAlreadyInPipeline = true;
                        break;
                    }
                }

                if (!stageAlreadyInPipeline) {
                    currentPipeline.stages.push(stage);
                }

                if (stage.oldPipeline.id !== stage.pipeline.id) {
                    // eslint-disable-next-line no-shadow
                    const oldPipeline = $scope.pipelines.find(function (pipeline) {

                        return pipeline.id === stage.oldPipeline.id;
                    });

                    for (let i = 0; i < oldPipeline.stages.length; ++i) {
                        if (oldPipeline.stages[i].id === stage.id) {
                            oldPipeline.stages.splice(i,1);
                            break;
                        }
                    }
                }

                delete stage.pipeline;
                delete stage.oldPipeline;

                return pipelines.update($scope.pipelines).then(get);
            });
        };

        $scope.deleteStage = function (stage, event) {

            // Prevent event triggering other stuff like dragging
            event.stopPropagation();

            stage._dirty = true;
            stage.archived = true;

            return pipelines.update($scope.pipelines).then(get, get);
        };

        $scope.duplicatePipeline = function (pipelineToDuplicate, event) {

            // Prevent event triggering other stuff like dragging
            event.stopPropagation();

            const pipeline = angular.copy(pipelineToDuplicate);
            pipeline.name = pipeline.name + ' Copy';

            delete pipeline.id;

            pipeline.stages = pipeline.stages.map(function (stage) {

                delete stage.id;

                return stage;
            });

            pipeline.order = $scope.pipelines.length + 1;
            $scope.pipelines.push(pipeline);

            utils.showSuccessToast('Pipeline duplicated.');

            return pipelines.update($scope.pipelines).then(get).catch(get);
        };

        $scope.getStageDeleteTitle = function (stage) {

            if (stage.fixed_stage === 1) {
                return 'The stage containing won opportunities can not be deleted as it\'s used to calculate your Insights.';
            }
            else if (stage.fixed_stage === 2) {
                return 'The stage containing lost opportunities can not be deleted as it\'s used to calculate your Insights.';
            }

            return 'Delete stage';
        };

        function get() {

            groups.get().then(function (response) {

                $scope.groups = response.data.filter(function (groupObject) {

                    return groupObject.id;
                }).map(function (groupObject) {

                    return { id: groupObject.id, name: groupObject.name };
                });
            });

            return pipelines.get().then(function (response) {

                $scope.pipelines = response.data;
            });
        }

        $scope.getCommaSeparatedGroupNames = function (pipelineGroups) {

            if (!$scope.groups) {
                return '';
            }

            if (pipelineGroups.length === $scope.groups.length) {
                return 'All groups';
            }

            return pipelineGroups.map(function (pipelineGroup) {

                return pipelineGroup.name;
            }).join(', ').replace(/, ([^,]*)$/, ' and $1');
        };
    }
})();
