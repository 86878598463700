import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';

import { AvatarComponent } from './avatar.component';


@NgModule({
    declarations: [
        AvatarComponent
    ],
    exports: [
        AvatarComponent
    ],
    imports: [
        CommonModule,
        MatIconModule
    ]
})
export class AvatarModule {}
