(function () {
    'use strict';

    /*
     * {type} can be [account, contact],
     * {id} is the id of the account or contact entity
     * {hideFeed} is a boolean that indicates wether there is a feed
     */
    angular
        .module('salesflare.components.socialFeed', [])
        .component('sfSocialFeed', {
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/socialfeed/socialfeed.html',
            bindings: {
                type: '@',
                ignoreSpinner: '@',
                id: '<',
                hideFeed: '=?', // Yes this is dirty but allows person to hide the header when there is no feed for now
                onRenderingComplete: '&'
            },
            controller
        });

    /*
     * Annotate the function so uglyfying (more specifically mangling) the file doesn't break it.
     * @ngInject
     */
    function controller($element, $scope, $http, $sce, account, company, contact, user) {

        const vm = this;

        vm.hideFeed = false;
        vm.showEmptyState = false;

        vm.$onInit = () => {

            vm.showSpinner = !vm.ignoreSpinner;
        };

        $scope.$on('refreshData', get);

        $scope.$watch('vm.id', function (newVal) {

            if (!newVal) {
                vm.showEmptyState = true;
                vm.showEmbeddedTimeline = false;
                return;
            }

            vm.hideFeed = false;
            vm.showEmptyState = false;

            return get();
        });

        function get() {

            // Clear the embedded timeline container
            $element[0].querySelectorAll('#embedded-timeline-container')[0].replaceChildren();

            switch (vm.type) {
                case 'account':
                    return account.get(vm.id).then(handleResponse).catch(() => {

                        vm.hideFeed = true;
                    });
                case 'company':
                    return company.get(vm.id).then(handleResponse).catch(() => {

                        vm.hideFeed = true;
                    });
                case 'contact':
                    return contact.get(vm.id).then(handleResponse).catch(() => {

                        vm.hideFeed = true;
                    });
                case 'user':
                    return user.get(vm.id).then(handleResponse).catch(() => {

                        vm.hideFeed = true;
                    });
                default:
                    // No types match
                    vm.hideFeed = true;
                    throw new Error(`Type: ${vm.type} is not a valid type`);
            }
        }

        function handleResponse(response) {

            const username = response.data.social_profiles.find((profile) => profile.type === 'twitter')?.username;

            if (!username) {
                vm.showEmptyState = true;
                return;
            }

            vm.isLoading = true;

            return twttr.widgets.createTimeline(
                {
                    sourceType: 'profile',
                    screenName: username
                },
                $element[0].querySelector('#embedded-timeline-container'),
                {
                    chrome: 'noheader nofooter transparent noborders',
                    dnt: true
                }
            ).then((res) => {

                // The Twitter script doesn't reject the promise when an invalid username is passed unfortunately, it just inserts an empty iframe
                // We check if the iframe is empty to see if the profile exists, if it doesn't we show the empty state
                const twitterProfileFound = !!res?.childNodes[0]?.clientHeight;

                if (twitterProfileFound && vm.onRenderingComplete) {
                    vm.onRenderingComplete();
                }

                vm.isLoading = false;
                // We don't show the empty state on contacts
                vm.hideFeed = !twitterProfileFound && vm.type === 'person';
                vm.showEmptyState = !twitterProfileFound;
            }).catch(() => {

                vm.showEmptyState = true;
            }).finally(() => {

                vm.isLoading = false;
            });
        }
    }
})();
