(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('DiscardDialogController', DiscardDialogController);

    function DiscardDialogController($scope, $mdDialog) {

        $scope.discard = function () {

            return $mdDialog.hide(false);
        };

        $scope.isNew = this.isNew;
        $scope.item = this.item;

        $scope.close = function () {

            return $mdDialog.cancel();
        };
    }
})();
