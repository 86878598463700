(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('favicoService', favicoService);

    function favicoService($timeout) {

        const favico = new Favico({
            animation: 'popFade',
            bgColor: '#f44336',
            textColor: '#fff',
            fontFamily: 'Nunito, sans-serif',
            fontStyle: 700,
            type: 'circle',
            position: 'down'
        });

        function badge(num) {

            $timeout(function () {

                favico.badge(num <= 99 ? num : '99+');
            });
        }

        function reset() {

            favico.reset();
        }

        return {
            badge,
            reset
        };
    }
})();
