<sfx-navigation-toolbar (closeEvent)="close()">
    <div navigationToolbarTitle>
        Billing
    </div>
    <a navigationToolbarButton icon="sf-icon-list" ref=".overview">
        Overview
    </a>
    <a navigationToolbarButton icon="sf-icon-history" ref=".history" *ngIf="Stripe">
        History
    </a>
    <a navigationToolbarButton icon="sf-icon-description" ref=".invoice" *ngIf="Stripe">
        Invoicing
    </a>
</sfx-navigation-toolbar>

<sfx-content>
    <sfx-section disableContentPadding>
        <ui-view class="panel-view">

        </ui-view>
    </sfx-section>
</sfx-content>
