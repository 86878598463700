(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('FilterDialogController', FilterDialogController);

    function FilterDialogController($mdDialog, $scope) {

        const vm = this;

        vm.onFilterChanged = function ($event) {

            if ($event.filter) {
                vm.dashboardFilter = $event.filter;
            }
        };

        vm.applyFilters = function () {

            return $mdDialog.hide(vm.dashboardFilter);
        };

        vm.resetFilters = function () {

            $scope.$broadcast('resetFilterForm');
        };
    }
})();
