(function () {
    'use strict';

    angular
        .module('salesflare.components.workflow.workflowStep.workflowStepAction', [])
        .component('sfWorkflowStepAction', {
            templateUrl: 'app-ajs/components/workflows/workflow/workflowstep/card/workflowStepAction.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                step: '<',
                editing: '<',
                filter: '<',
                individualRecordFilter: '<',
                triggerStepSubject: '<',
                onUpdateAction: '&'
            }
        });

    function controller($window, $mdMedia) {

        const vm = this;
        vm.$mdMedia = $mdMedia;
        vm.stepActionType = '';

        vm.$onInit = function () {

            vm.stepActionType = vm.step.action_type;

            const workflowStepActionMessage = vm.step.action_config && vm.step.action_config.message;

            if (workflowStepActionMessage) {
                vm.message = {
                    body: workflowStepActionMessage.body,
                    subject: workflowStepActionMessage.subject,
                    attachments: workflowStepActionMessage.attachments,
                    // If available we try to add a data source id and display name here as well: https://github.com/Salesflare/Server/issues/6819
                    from: workflowStepActionMessage.from.email ? { email: workflowStepActionMessage.from.email, displayName: workflowStepActionMessage.from.name, id: workflowStepActionMessage.data_source || vm.step.action_source, status: vm.step.action_status } : null,
                    tracked: workflowStepActionMessage.tracked,
                    includeUnsubscribeLink: workflowStepActionMessage.body ? workflowStepActionMessage.body.includes('{{ unsubscribe_url }}') : true,
                    workflow_reply: workflowStepActionMessage.workflow_reply
                };
            }
            else {
                vm.message = {};
            }

            // If trigger step index is defined (not the start of workflow) it can be a reply (trigger_step is not defined at this point yet)
            // TODO: need to check for action_type of trigger step as well, when workflow steps get different types than send_email
            vm.canBeReply = vm.step.trigger_step_index ? true : false;
        };

        vm.download = function (downloadUrl) {

            $window.open(downloadUrl, '_blank', 'noopener');
        };

        vm.onDataChange = function ($event) {

            if (angular.isDefined($event)) {
                let dirty = false;
                let changedAsReply = false;
                let validityChanged = false;

                // This function also gets called on initialization, so we only want to set dirty if editing is true
                if (vm.editing) {
                    dirty = true;
                }

                if (vm.step.action_type === 'send_email') {
                    if (!vm.step.action_config) {
                        vm.step.action_config = {};
                    }

                    if (vm.step.action_config && vm.step.action_config.message) {
                        changedAsReply = (vm.step.action_config.message.workflow_reply !== $event.messageObject.workflow_reply);
                    }

                    if ($event.valid !== vm.step.valid) {
                        validityChanged = true;
                    }

                    vm.step.action_config.message = $event.messageObject;
                    vm.step.action_config.data_source = $event.senderDataSource.id;
                    vm.step.valid = $event.valid;

                    /*
                    If (!['disconnected', 'disabled_user'].includes($event.senderDataSource.status) && ['disconnected', 'disabled_user'].includes(vm.step.action_config.data_source_status)) {
                        delete vm.step.action_config.data_source_status;
                    }

                     */

                    vm.onUpdateAction({ $event: { dirty, changedAsReply, validityChanged } });
                }
            }
        };

        vm.isDatasourceInvalid = (status) => {

            switch (status) {
                case 'disconnected':
                case 'disabled_user':
                    return true;
                case 'OK':
                default:
                    return false;
            }
        };

        vm.getDatasourceErrorMessage = (status) => {
            switch (status) {
                case 'disconnected':
                    return 'The email used to send this step has been disconnected. Please reconnect it or use another email account.';
                case 'disabled_user':
                    return 'The user of the email account used to send this step has been disabled. Please enable the user or use another email account';
                case 'OK':
                default:
                    return false;
            }
        };
    }
})();
