(function () {
    'use strict';

    angular
        .module('salesflare')
        .config(function ($mdPanelProvider) {

            $mdPanelProvider.definePreset('templateSelectionPanel', {
                clickOutsideToClose: true,
                controller: 'TemplateSelectionController',
                controllerAs: 'vm',
                templateUrl: 'app-ajs/components/templates/templateSelection/templateSelection.html'
            });
        });
})();
