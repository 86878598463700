(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('CantDoDialogController', CantDoDialogController);

    function CantDoDialogController($mdDialog, $element) {

        const vm = this;


        vm.$onInit = function () {

            // The change in z-index is needed to make sure the dialog always comes in front
            // Mainly applicable for Shepherd steps (see src/sass/dependencies/shepherd.scss)
            // This is done via js, because the correct element to alter wasn't selectable with pure css
            const dialog  = $element[0];
            if (dialog.style) {
                dialog.style.zIndex = '82';
            }

            vm.htmlContent = vm.htmlContent || 'We love that you try out all possibilities of the app.<br>However, during the walkthrough, let\'s stick to the story.';
            vm.title = vm.title || 'No, can\'t do 😉';
        };

        vm.close = function () {

            return $mdDialog.cancel();
        };
    }
})();
