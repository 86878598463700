(function () {
    'use strict';

    angular.module('salesflare')
        .filter('capitalize', capitalize);

    function capitalize() {
        return function (input) {
            return (input) ? input.charAt(0).toUpperCase() + input.slice(1).toLowerCase() : '';
        };
    }
})();
