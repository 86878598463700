(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('CreateContactWithoutEmailDialogController', CreateContactWithoutEmailDialogController);

    function CreateContactWithoutEmailDialogController($mdDialog, flagsService) {

        const vm = this;
        vm.noFutureWarning = false;

        vm.confirm = function () {

            if (vm.noFutureWarning) {
                flagsService.set('saveContactWithoutEmailWarning', false);
            }

            return $mdDialog.hide(true);
        };

        vm.close = function () {

            return $mdDialog.cancel();
        };
    }
})();
