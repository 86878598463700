<sfx-toolbar disableDivider *ngIf="!media.isActive('gt-sm')" (closeEvent)="close()">
    <ng-content select="[navigationToolbarTitle]"></ng-content>
</sfx-toolbar>
<sfx-toolbar role="navigation" height="100%" [width]="media.isActive('gt-sm') ? 'unset' : '100%'" enableDivider disableBack disableWatermark [ngClass]="{'mobile': !media.isActive('gt-sm') }">
    <nav
            mat-tab-nav-bar [tabPanel]="tabPanel" class="toolbar-navigation" [mat-stretch-tabs]="true" mat-align-tabs="start" disablePagination
    >
        <a *ngFor="let navButton of navButtons; let idx = index;"
           mat-tab-link [uiSref]="navButton.ref" uiSrefActive="active">
            <mat-icon *ngIf="media.isActive('gt-sm')" fontSet="salesflare" [fontIcon]="navButton.icon" ></mat-icon>
            <div [innerHTML]="navButton.innerContent"></div>
        </a>
    </nav>
</sfx-toolbar>

<mat-tab-nav-panel #tabPanel>

</mat-tab-nav-panel>
