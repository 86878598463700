(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('SelectIndividualContactsController', SelectIndividualContactsController);

    function SelectIndividualContactsController($mdDialog, $mdMedia, $timeout, contactsService, workflowService, sfDiscardDialog) {

        const vm = this;
        const dirtyContacts = [];
        let searchDebounce;
        let initializing = true;

        vm.$mdMedia = $mdMedia;

        vm.search = '';
        vm.submitting = false;

        vm.contactEnteredText = `This contact has already entered the workflow and can't be de-selected.
        You can exit the contact in the workflow analytics.`;

        vm.contacts = {
            toLoad: 0,
            numLoaded: 0,
            items: [],
            topIndex: 0,
            stepAmount: 20,

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + this.stepAmount;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad < index && !initializing) {
                    this.toLoad += this.stepAmount;

                    const boundResponseHandler = angular.bind(this, contactResponse);

                    const options = {
                        search: vm.search,
                        limit: this.stepAmount,
                        offset: this.toLoad - this.stepAmount
                    };

                    const selectedContacts = this.items.filter((contact) => contact.selected).map((contact) => contact.id);


                    if (selectedContacts.length > 0) {
                        // Don't fetch contacts that are already selected
                        const rule = {
                            id: 'contact.id',
                            operator: 'not_in',
                            value: selectedContacts
                        };
                        options.filterRules = [rule];
                    }

                    return contactsService.filterGet(options).then(boundResponseHandler);
                }
            },

            // Forces reset of object
            reload: function () {

                this.toLoad = 0;
                // Keep selected contacts when performing a new search
                this.items = this.items?.filter((item) => item.selected);
                this.numLoaded = this.items.length;
                this.doneLoading = false;
                this.topIndex = 0;
            }
        };

        function contactResponse(response) {

            if (response && response.data) {

                this.items = [...this.items, ...response.data];

                this.numLoaded += this.stepAmount;
            }
        }

        vm.$onInit = async function () {

            const promiseResponse = await Promise.all([
                (() => {

                    const selectedContactsAmount = vm.individualRecordFilter?.rules?.find((rule) => rule.id === 'contact.id')?.value?.length;
                    // Get contacts in current individual record filter, if there is one
                    const contactGetOptions = {
                        filterRules: vm.individualRecordFilter,
                        limit: selectedContactsAmount > 20 ? selectedContactsAmount : 20
                    };

                    if (contactGetOptions.filterRules) {
                        return contactsService.filterGet(contactGetOptions);
                    }

                    return [];
                })(),
                (() => {
                    // Get entered contacts if necessary, so we can show the check mark as disabled
                    if (vm.workflowId && vm.workflowId !== 'new') {
                        return workflowService.getAudience(vm.workflowId, {
                            filter: {
                                condition: 'AND',
                                rules: [] // A rule that specifies that the audience needs to be part of the workflow, is added on the server
                            }
                        });
                    }

                    return [];
                })()
            ]);

            if (promiseResponse) {
                const contactsResponse = promiseResponse[0];
                const audienceResponse = promiseResponse[1];
                if (contactsResponse.data) {
                    let selectedContacts = contactsResponse.data.map((contact) => {

                        contact.selected = true;
                        return contact;
                    });

                    if (audienceResponse.data) {
                        const contactIdsInWorkflow = new Set(audienceResponse.data.map((contact) => contact.id));
                        selectedContacts = selectedContacts.map((contact) => {

                            if (contactIdsInWorkflow.has(contact.id)) {
                                contact.entered = true;
                            }

                            return contact;
                        });
                    }

                    // Add the selected contacts to the virtual repeat
                    vm.contacts.items = [...selectedContacts, ...vm.contacts.items];
                }

                initializing = false;
            }
        };

        vm.get = function () {

            return vm.contacts.reload();
        };

        vm.doSearch = function () {

            $timeout.cancel(searchDebounce);

            searchDebounce = $timeout(function () {

                return vm.get();
            }, 750);
        };

        vm.back = function () {

            if (dirtyContacts.length > 0) {
                return sfDiscardDialog.show(false, 'contacts').then(close);
            }
            else {
                return close();
            }
        };

        function close() {

            return $mdDialog.hide();
        }

        vm.createContact = function () {

            return $mdDialog.show({
                controller: 'EditPersonController',
                templateUrl: 'partials/editperson.html',
                multiple: true,
                bindToController: true,
                clickOutsideToClose: true,
                fullscreen: !$mdMedia('gt-sm'),
                locals: {
                    dialogParams: {
                        type: 'contact',
                        createContact: true,
                        contactName: vm.search
                    }
                }
            }).then(function (response) {

                if (response.id) {
                    contactsService.get(null, { id: response.id }).then((contactsResponse) => {

                        if (contactsResponse.data) {
                            const createdContact = contactsResponse.data[0];
                            createdContact.selected = true;
                            vm.selectContact(createdContact);

                            vm.contacts.items = [createdContact, ...vm.contacts.items];
                        }
                    });
                }
            });
        };


        vm.submitOnEnter = function ($event) {

            if ($event.which === 13) {
                return vm.submit();
            }
        };

        vm.submit = function () {

            vm.submitting = true;

            if (dirtyContacts.length === 0) {
                return close();
            }

            let individualRecordFilter;

            const selectedContacts = vm.contacts.items.filter((contact) => contact.selected).map((contact) => contact.id);

            if (selectedContacts.length > 0) {
                // Don't fetch contacts that are already selected
                individualRecordFilter = {
                    condition: 'AND',
                    rules: [{
                        id: 'contact.id',
                        operator: 'in',
                        value: selectedContacts
                    }]
                };
            }
            else {
                // If no contacts are selected, make sure to send NULL as filter, this will empty out the individual contact filter
                // Sending a filter object with an empty rules array will result in ALL contacts getting selected!
                individualRecordFilter = null;
            }


            return $mdDialog.hide({
                individualRecordFilter
            });
        };

        vm.selectContact = function (contact) {

            contact._dirty = !contact._dirty;

            if (contact._dirty) {
                const dirtyContact = angular.copy(contact);
                delete dirtyContact.selected;

                dirtyContacts.push(dirtyContact);
            }
            else {
                for (let i = 0; i < dirtyContacts.length; ++i) {
                    if (dirtyContacts[i].id === contact.id) {
                        dirtyContacts.splice(i, 1);

                        break;
                    }
                }
            }
        };
    }
}
)();
