(function () {
    'use strict';

    angular
        .module('salesflare.components.files', [
            'salesflare.components.files.upload'
        ])
        .component('sfFiles', {
            templateUrl: 'app-ajs/components/files/files.html',
            controllerAs: 'vm'
        });

})();
