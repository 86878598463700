<sfx-toolbar enableDivider>
    Refer a friend
</sfx-toolbar>

<sfx-content cdkVirtualScrollingElement class="main-container">
    <sfx-header>
        Refer friends to Salesflare
    </sfx-header>
    <sfx-section>
        <div class="referral-program-section-content">
            <span class="refer-action-explanation">When your friend signs up and subscribes to Salesflare, you both earn:</span>
            <h2><span [innerHtml]="currencyHtml"></span>{{referralReward}} Amazon gift card! 🎉</h2>
            <span class="refer-action-explanation">Learn more about <a href="https://howto.salesflare.com/en/articles/6109303-what-are-the-conditions-to-refer-a-friend" target="_blank" rel="noopener">referral conditions</a>.</span>
        </div>
    </sfx-section>
    <sfx-header>
        Ways to refer friends
    </sfx-header>
    <sfx-section>

        <div class="referral-program-section-content no-margin-bottom">
            <h3 class="refer-action personal-share-code">
                Personal share code: {{ referralCode }}
                <button mat-icon-button class="button-20" disableRipple [cdkCopyToClipboard]="referralCode">
                    <mat-icon fontSet="salesflare" fontIcon="sf-icon-clipboard" class="icon-20 icon"></mat-icon>
                </button>
            </h3>
            <div class="refer-action-explanation">Your friend can apply this code when subscribing to a paid plan.</div>

            <h3 class="refer-action personal-share-link">
                Personal share link: {{ shareLink }}
                <button mat-icon-button class="button-20" disableRipple [cdkCopyToClipboard]="shareLink">
                    <mat-icon fontSet="salesflare" fontIcon="sf-icon-clipboard" class="icon-20 icon"></mat-icon>
                </button>
                <a mat-icon-button class="social-media-button" disableRipple href="{{ facebookShareLink }}" target="_blank">
                    <img src="images/socials/facebook.png">
                </a>
                <a mat-icon-button class="social-media-button x" disableRipple href="{{ XShareLink }}" target="_blank">
                    <img src="images/socials/x-round.png">
                </a>
                <a mat-icon-button class="social-media-button whatsapp" disableRipple href="{{ whatsappShareLink }}" target="_blank">
                    <img src="images/socials/whatsapp.png">
                </a>
            </h3>
            <div class="refer-action-explanation">Your friend can sign up through this link to automatically be linked to you.</div>

            <h3 class="refer-action">Personal email invite:</h3>
            <div class="refer-action-explanation">Send a pre-filled email with referral details & what your friend earns.</div>

            <div class="email-invite">
                <form class="no-padding" role="form" id="personalEmailInviteForm" name="personalEmailInviteForm" #personalEmailInviteForm="ngForm" novalidate>
                    <mat-form-field>
                        <input matInput #personalInviteEmail="ngModel" placeholder="name@company.com" type="text" name="personalInviteEmail" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" [(ngModel)]="personalInvite.email" />
                        <mat-error *ngIf="personalEmailInviteForm.controls['personalInviteEmail']?.errors?.required">
                            An email address is required
                        </mat-error>
                        <mat-error *ngIf="personalEmailInviteForm.controls['personalInviteEmail']?.errors?.pattern">
                            You must enter a valid email address
                        </mat-error>
                    </mat-form-field>
                    <button mat-button [disabled]="!personalInvite.email || personalEmailInviteForm.invalid" (click)="previewPersonalInviteEmail()">
                        <span class="personal-invite-button-text">Preview & send</span>
                    </button>
                </form>
            </div>
        </div>
    </sfx-section>
    <sfx-header>
        Has your friend already signed up?
    </sfx-header>
    <sfx-section>

        <div class="referral-program-section-content no-margin-bottom">
            <span class="refer-action-explanation">If your friend already signed up without your link or code, submit their email to link them to you. It must match the email your friend signed up with:</span>

            <div class="email-invite">
                <form class="no-padding" role="form" id="linkAlreadySignedUpFriendForm" #linkAlreadySignedUpFriendForm="ngForm"
                      name="linkAlreadySignedUpFriendForm" novalidate>
                    <mat-form-field>
                        <input matInput #alreadySignedUpFriendEmail="ngModel" name="alreadySignedUpFriendEmail" placeholder="name@company.com" type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" [(ngModel)]="alreadySignedUpFriend.email"/>
                        <mat-error *ngIf="linkAlreadySignedUpFriendForm.controls['alreadySignedUpFriendEmail']?.errors?.required">
                            An email address is required
                        </mat-error>
                        <mat-error *ngIf="linkAlreadySignedUpFriendForm.controls['alreadySignedUpFriendEmail']?.errors?.pattern">
                            You must enter a valid email address
                        </mat-error>
                    </mat-form-field>
                    <button mat-button
                            [disabled]="!alreadySignedUpFriend.email || linkAlreadySignedUpFriendForm.invalid"
                            (click)="createReferral()"><span>SUBMIT</span>
                    </button>
                </form>
            </div>
        </div>
    </sfx-section>
    <sfx-header>
        Friends you've referred so far
    </sfx-header>

    <sfx-section disableContentPadding>
        <div class="referral-program-section-content referees">
            <p class="referees-text">Thanks for spreading the Salesflare love! Here are the friends you've referred that have signed up so far:</p>

            <sfx-empty-state [hidden]="!showEmptyState" [image]="emptyStateImage" [text]="emptyStateText"></sfx-empty-state>

            <!-- 'hidden' won't work on a cdk viewport - need to use conditional rendering for this one. -->
            <cdk-virtual-scroll-viewport *ngIf="!showEmptyState" [itemSize]="rowSize">
                <mat-list class="list" *ngIf="(referees?.length || 0) > 0">

                    <mat-list-item [hidden]="!media.isActive('gt-sm')" class="list-item referral-header desktop-list-item">
                        <span data-layout="row" data-layout-gap="4px" data-flex class="list-item-content">
                            <div class="referral-list-item-content ellipsis" data-flex="25">
                                <span class="ellipsis" title="Name">Name</span>
                            </div>
                            <div class="referral-list-item-content ellipsis" data-flex="30">
                                <span class="ellipsis" title="Email">Email</span>
                            </div>
                            <div class="referral-list-item-content ellipsis" data-flex="15">
                                <span class="ellipsis" title="Status">Status</span>
                            </div>
                            <div class="referral-list-item-content ellipsis" data-flex="10">
                                <span class="ellipsis" title="Gift">Gift</span>
                            </div>
                            <div class="referral-list-item-content ellipsis" data-flex="15">
                                <span class="ellipsis" title="Gift Received">Gift Received?</span>
                            </div>
                            <div class="referral-list-item-actions" data-flex="5">
                                <!-- placeholder for delete action column -->
                            </div>
                        </span>
                    </mat-list-item>

                    <mat-divider></mat-divider>

                    <ng-container *cdkVirtualFor="let referee of referees">
                        <mat-list-item
                                class="list-item" [ngClass]="{'mobile-list-item': !media.isActive('gt-sm'), 'desktop-list-item': media.isActive('gt-sm') }">
                            <span data-layout="row" data-layout-align="start center" data-layout-gap="4px" data-flex class="list-item-content">
                            <ng-container *ngIf="media.isActive('gt-sm')">

                                <div class="ellipsis referral-list-item-content" data-flex="25" data-layout-align="start center">
                                    <sfx-avatar [src]="referee.picture" class="sf2-avatar_small"></sfx-avatar>
                                    <span data-flex class="ellipsis" title="Name">{{ referee.name }}</span>
                                </div>
                                <div class="ellipsis referral-list-item-content" data-flex="30">
                                    <span class="ellipsis">{{ referee.email }}</span>
                                </div>
                                <div class="ellipsis referral-list-item-content" data-flex="15">
                                    <span class="ellipsis mute" data-flex>
                                        {{ statusStrings[referee.status] }}
                                    </span>
                                </div>
                                <div class="ellipsis referral-list-item-content" data-flex="10">
                                    <span *ngIf="referee.reward" class="ellipsis mute" [innerHtml]="currencyHtml + referee.reward">

                                    </span>
                                    <span *ngIf="!referee.reward" class="ellipsis mute">
                                        Nothing
                                    </span>
                                </div>
                                <div class="ellipsis referral-list-item-content" data-flex="15">
                                    <!-- When trial is running, or person is subscribed and for less than a month -->
                                    <div *ngIf="!referee.received && ((!referee.team_subscription_date && referee.status === 'started_trial') || (referee.team_subscription_date && !moreThanOneMonthAgo(referee.team_subscription_date)))" class="ellipsis mute" data-flex>
                                        <span data-layout="row" data-layout-align="start center" data-layout-gap="4px">
                                            <span class="ellipsis">Not eligible yet</span>
                                            <mat-icon fontSet="salesflare" fontIcon="sf-icon-info" class="icon-16 inline-icon info" aria-label="info"
                                                      matTooltip="Your friend needs to be subscribed for a month and you need to be subscribed as well." matTooltipPosition="below">
                                            </mat-icon>
                                        </span>
                                    </div>
                                    <!-- Reward received -->
                                    <div *ngIf="referee.received" class="mute" data-flex>
                                        <span data-layout="row" data-layout-align="start end" data-layout-gap="4px">
                                            <span class="ellipsis">Yes</span>
                                            <mat-icon class="icon-20 inline-icon check" fontSet="salesflare" fontIcon="sf-icon-check" aria-label="check"></mat-icon>
                                        </span>
                                    </div>
                                    <!-- When trial is expired and the person is not subscribed -->
                                    <div *ngIf="!referee.received && referee.status === 'trial_expired' && !referee.team_subscription_date" class="ellipsis mute" data-flex>
                                        <span class="ellipsis">Give them a push</span>
                                    </div>
                                    <!-- The person is subscribed for more than a month -->
                                    <div *ngIf="!referee.received && moreThanOneMonthAgo(referee.team_subscription_date)" class="ellipsis mute" data-flex>
                                        <span data-layout="row" data-layout-align="start center" data-layout-gap="4px">
                                            <span class="ellipsis">On its way</span>
                                            <mat-icon class="icon-16 inline-icon failed info" fontSet="salesflare" fontIcon="sf-icon-info" aria-label="info"
                                                      matTooltip="Your gift is being processed. You'll hear from us shortly." matTooltipPosition="below">

                                            </mat-icon>
                                        </span>
                                    </div>
                                </div>
                                <div class="referral-list-item-actions" data-flex="5" data-layout-align="center">
                                    <div>
                                        <button mat-icon-button class="delete-referral-button" [disabled]="loading" disableRipple (click)="deleteReferral(referee.id)" color="primary">
                                            <div>
                                                <mat-icon fontSet="salesflare" fontIcon="sf-icon-delete" class="delete-referral-icon" aria-label="delete"></mat-icon>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!media.isActive('gt-sm')">
                                <sfx-avatar *ngIf="media.isActive('gt-xs')" [src]="referee.picture"></sfx-avatar>
                                <div class="mat-list-item-text referral-list-item-content ellipsis" data-flex>
                                    <div class="referee-info-row" data-layout="row" data-layout-gap="4px">
                                        <h1 class="ellipsis">{{ referee.name }}</h1>
                                        <h2  *ngIf="referee.email" class="ellipsis">{{ referee.email }}</h2>
                                    </div>
                                    <div class="ellipsis referee-reward-row" data-layout="row" data-layout-gap="8px">
                                        <span data-layout="row" data-layout-gap="4px">
                                            <mat-icon fontSet="salesflare" fontIcon="sf-icon-time" class="icon-16"></mat-icon>
                                            <span>{{ statusStrings[referee.status] }}</span>
                                        </span>
                                        <span data-layout="row" data-layout-gap="4px">
                                            <mat-icon fontSet="salesflare" fontIcon="sf-icon-gift" class="icon-16"></mat-icon>
                                            <ng-container *ngIf="referee.reward">
                                                <span [innerHtml]="currencyHtml + referee.reward"></span>
                                            </ng-container>
                                            <ng-container *ngIf="!referee.reward">
                                                Nothing
                                            </ng-container>
                                        </span>
                                        <div class="ellipsis received-status mute">
                                            <span *ngIf="!referee.received && ((!referee.team_subscription_date && referee.status === 'started_trial') || (referee.team_subscription_date && !moreThanOneMonthAgo(referee.team_subscription_date)))"
                                                  data-layout="row" data-layout-gap="4px"
                                            >
                                                <span class="ellipsis">Not eligible yet</span>
                                                <mat-icon fontSet="salesflare" fontIcon="sf-icon-info" class="icon-16 inline-icon info"
                                                          matTooltip="Your friend needs to be subscribed for a month and you need to be subscribed as well." matTooltipPosition="below">
                                                </mat-icon>
                                            </span>
                                            <span *ngIf="referee.received"
                                                  data-layout="row" data-layout-gap="4px"
                                            >
                                                <span class="ellipsis">Yes</span>
                                                <mat-icon fontSet="salesflare" class="icon-20 inline-icon check" fontIcon="sf-icon-check" aria-label="check"></mat-icon>
                                            </span>
                                            <span *ngIf="!referee.received && referee.status === 'trial_expired' && !referee.team_subscription_date"
                                                  data-layout="row" data-layout-gap="4px"
                                            >
                                                <span class="ellipsis">Give them a push</span>
                                            </span>
                                            <span *ngIf="!referee.received && moreThanOneMonthAgo(referee.team_subscription_date)"
                                                  data-layout="row"
                                                  data-layout-gap="4px"
                                            >
                                                <span class="ellipsis">On its way</span>
                                                <mat-icon fontSet="salesflare" fontIcon="sf-icon-info" class="icon-16 inline-icon failed info"
                                                          matTooltip="Your gift is being processed. You'll hear from us shortly." matTooltipPosition="below">
                                                </mat-icon>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mobile-action">
                                    <button mat-icon-button class="line-height-24 delete-referral-button" disableRipple (click)="deleteReferral(referee.id)" color="primary">
                                        <mat-icon fontSet="salesflare" fontIcon="sf-icon-delete" class="delete-referral-icon"></mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                       </span>
                        </mat-list-item>
                        <mat-divider>

                        </mat-divider>
                    </ng-container>
                </mat-list>
            </cdk-virtual-scroll-viewport>
        </div>
    </sfx-section>
</sfx-content>
