(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('TermsAndPoliciesController', TermsAndPoliciesController);

    function TermsAndPoliciesController($scope, $sce) {

        $scope.frameSource = 'https://salesflare.com/terms';

        $scope.setFrameSource = function (url) {

            $scope.frameSource = url;
        };

        $scope.getTrustedUrl = function () {

            return $sce.trustAsResourceUrl($scope.frameSource);
        };
    }
})();
