import { Component, Inject, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MediaService } from '@core/services/media.service';
import { CdkVirtualScrollableElement } from '@angular/cdk/scrolling';
import { catchError, debounceTime, filter, forkJoin, map, mergeMap, of, pairwise, Subject, throttleTime } from 'rxjs';

import {
    DialogResponse,
    SfxDialog
} from '@shared/components/dialog/dialog.component';
import {
    EditTagDialogContentComponent, EditTagDialogContentData
} from '@feature/settings/components/manage-tags/dialogs/edit-tag-dialog-content.component';
import {
    DisableAutomatedTaggingContentComponent, DisableAutomatedTaggingContentData
} from '@feature/settings/components/manage-tags/dialogs/disable-automated-tagging-content.component';

import { MatDialog } from '@angular/material/dialog';
import {
    MergeTagsContentComponent, MergeTagsContentData
} from '@feature/settings/components/manage-tags/dialogs/merge-tags-dialog-content.component';
import {
    DeleteTagDialogContentComponent
} from '@feature/settings/components/manage-tags/dialogs/delete-tag-dialog-content.component';

interface ToolbarFilter {
    search?: string;
    filterRules?: any;
    orderBy?: any;
}

@Component({
    selector: 'sfx-manage-tags',
    templateUrl: './manage-tags.component.html',
    styleUrls: ['./manage-tags.component.scss']
})
export class ManageTagsComponent implements OnInit, AfterViewInit {

    // Sorting
    sortOptions: any[] = [];

    // Virtual scroll
    @ViewChild(CdkVirtualScrollableElement)
    scrollable!: CdkVirtualScrollableElement;

    rowSize = this.media.isActive('gt-sm') ? 48 : 71;
    batchSize = 40;
    offset = 0;
    endReached = false;

    // Tags
    public tags: any[] = [];
    public filteredTagCount: number | null = 0;
    public tagCount = 0;

    // Loading indicator
    public loading = true;
    public doneLoading = false;

    // Search variables
    public searchActive = false;
    public search = '';
    @ViewChild('searchBox', { static: false })
    searchBox!: ElementRef;

    // Filter variables
    public filtersActive = false;
    public filtersBad = false;
    public filterErrorMessage: string | null = null;
    public filters: any = null;
    public filtersChanged = new Subject<ToolbarFilter>();

    // Bulk select variables
    public bulkSelectState: {
        active: boolean;
        selected: any[];
        selectedAll: boolean;
    } = {
        active: false,
        selected: [],
        selectedAll: false
    }

    constructor(
        public media: MediaService,
        private matDialog: MatDialog,
        @Inject('bulkService') public bulkService: any,
        @Inject('filterService') public filterService: any,
        @Inject('me') public me: any,
        @Inject('modelService') public modelService: any,
        @Inject('sortService') public sortService: any,
        @Inject('tagsService') public tagsService: any,
        @Inject('teamService') public teamService: any,
        @Inject('utilsService') public utilsService: any
    ) {}

    ngOnInit(): void {

        // Get sort options

        this.sortOptions = this.sortService.getSortOptions('tag');

        // Assign logic to filters subject.

        this.filtersChanged
            .pipe(
                debounceTime(500),
                mergeMap((appliedFilter: ToolbarFilter) => {
                    const options: any = {
                        limit: this.batchSize,
                        offset: 0,
                        ignoreLoadingBar: true
                    };

                    const orderBy = this.sortService.getCurrentSortOption('tag')?.order_by;

                    if (orderBy) {
                        options.orderBy = orderBy;
                    }

                    this.loading = true;
                    this.doneLoading = false;

                    if (appliedFilter?.filterRules?.length > 0 || appliedFilter?.search) {
                        return forkJoin([
                            this.tagsService.get({ returnCountOnly: true }),
                            this.tagsService.get({ ...appliedFilter, returnCountOnly: true }),
                            this.tagsService.get({ ...appliedFilter, ...options })
                        ]);
                    }
                    else {
                        return forkJoin([
                            this.tagsService.get({ returnCountOnly: true }),
                            of(null),
                            this.tagsService.get({ ...options })
                        ]);
                    }
                }),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                catchError((response, caught) => {

                    if (response && [402, 422].includes(response.status)) {
                        this.filtersBad = true;

                        if (response.data?.filter) {

                            const selectedRules = this.filterService.flatten(this.filterService.getFilter('tag', { raw: true }));
                            const hydratedRules = this.filterService.flatten(response.data.filter.rules);

                            const rulesToApply = this.filterService.intersectAndEnrich(selectedRules, hydratedRules);

                            this.filters = this.filterService.setFilter('tag', rulesToApply);
                        }
                    }

                    return of(null);
                })
            )
            .subscribe((results: any[] | null) => {

                if (results === null) {
                    this.tagCount = 0;
                    this.filteredTagCount = null;

                    this.loading = false;
                    this.doneLoading = true;

                    return;
                }

                this.filtersBad = false;

                this.tagCount = Number.parseInt(results[0].headers('x-result-count')) || 0;

                if (results[1]) {
                    this.filteredTagCount = Number.parseInt(results[1].headers('x-result-count')) || 0;
                }
                else {
                    this.filteredTagCount = null;
                }

                const response = results[2];

                const batch = response.data;

                this.tags = [...batch];

                this.bulkSelectState.selected = [];
                this.bulkSelectState.selectedAll = false;
                this.ValidateState();

                this.endReached = batch.length < this.batchSize;

                if (!this.doneLoading) {
                    this.loading = false;
                    this.doneLoading = true;
                }
            });

        // Fetch filters
        this.filters = this.filterService.getFilter('tag', { raw: true });

        this._applyFilterRules(this.filters);
    }

    public ngAfterViewInit(): void {

        // Assign logic for virtual scroll with lazy loading.
        this.scrollable.elementScrolled().pipe(
            map(() => this.scrollable.measureScrollOffset('bottom')),
            pairwise(),
            filter(([previousBottomOffset, currentBottomOffset]) => (!this.endReached && currentBottomOffset < previousBottomOffset && currentBottomOffset < this.rowSize * 2)),
            throttleTime(500)
        ).subscribe(() => {
            this.loadBatch();
        });

    }

    public loadBatch = () => {

        const filters: ToolbarFilter = {};

        if (this.searchActive && this.search && this.search !== '') {
            filters.search = this.search;
        }
        else if (this.filters) {
            filters.filterRules = this.filters;
        }

        this.tagsService.get({ ...filters, orderBy: this.sortService.getCurrentSortOption('tag')?.order_by, limit: this.batchSize, offset: this.tags.length, ignoreLoadingBar: true }).then((response: any) => {

            const batch = response.data;

            if (this.bulkSelectState.selectedAll) {
                batch.forEach((batchTag: any) => {
                    batchTag.selected = true;
                    this.SelectTag(batchTag);
                });
            }

            this.tags = [...this.tags, ...batch];

            if (batch.length < this.batchSize) {
                this.endReached = true;
            }

            this.loading = false;
            this.doneLoading = true;
        });
    }

    // Helpers
    public getUsageTooltip(tag: any): string {
        return `Amount of times used per entity:
                    - ${tag.account_count} accounts
                    - ${tag.person_count} contacts
                    - ${tag.opportunity_count} opportunities`;
    }

    public triggerSearchChanged(): void {

        if (this.searchActive && this.search && this.search !== '') {

            const appliedFilters: ToolbarFilter = { search: this.search };

            this.filtersChanged.next(appliedFilters);
        }
    }

    /**
     *
     * @param {Object} event Event containing applied filters
     * @param {Object} event.$event
     * @param {Array<any>} [event.$event.rules] Filter rules
     * @param {String} [event.$event.saved_filter_id] Saved filter ID
     */
    public onFilterApplied(event: any) {

        this.filters = event.$event.rules;

        this._applyFilterRules(this.filters);
    }

    private _applyFilterRules(rules: any[]): void {

        const appliedFilters: ToolbarFilter = { filterRules: rules };

        this.filterService.setFilter('tag', rules);

        this.filtersChanged.next(appliedFilters);
    }

    public getDefaultFilters() {

        return this.filterService.getDefaultFilters('tag', this.modelService.me);
    }

    public isTagFilterApplied = this.filterService.isTagFilterApplied;

    get MergeButtonDisabled(): boolean {

        return this.bulkSelectState.selectedAll || this.bulkSelectState.selected.length < 2;
    }

    get MergeButtonTooltip(): string {

        if (this.bulkSelectState.selectedAll) {
            return 'You can\'t merge all tags. Please manually select tags to enable merging.';
        }
        else if (this.bulkSelectState.selected.length < 2) {
            return 'Please select at least 2 tags to merge.';
        }

        return 'Merge';
    }

    // Button actions
    public toggleSearch(): void {
        this.searchActive = !this.searchActive;

        // eslint-disable-next-line angular/timeout-service
        setTimeout(() => {
            this.searchBox.nativeElement.focus();
        });

        if (this.searchActive) {
            this.triggerSearchChanged();
        }
        else {
            this._resetAfterBulkOperation();
        }
    }

    public updateAutomatedTagging(value: boolean): void {

        const updateAutomatedTaggingValue = () => {

            return this.teamService.update({ automated_tagging: value }).then(() => {

                this.utilsService.showSuccessToast(`Automated tagging has been ${value === true ? 'enabled' : 'disabled'}.`);
                this.modelService.me.team.automated_tagging = value;

                this.me.get().then((response: any) => {

                    this.modelService.me = response.data;
                });
            });
        };

        if (value) {
            return updateAutomatedTaggingValue();
        }

        const confirmationDialog = this.matDialog.open(SfxDialog, {
            panelClass: 'sfx-dialog-container-xs',
            autoFocus: false,
            data: {
                title: 'Turn off automated tagging',
                confirm: 'Turn off',
                close: 'Cancel',
                theme: 'blue',
                content: DisableAutomatedTaggingContentComponent
            }
        });

        confirmationDialog.afterClosed().subscribe((context: DialogResponse<DisableAutomatedTaggingContentData>) => {

            if (context?.confirmed) {
                updateAutomatedTaggingValue();

                if (context.state.deleteAutomatedTags) {
                    this.bulkService.deleteTags({
                        condition: 'AND',
                        rules: [{
                            id: 'tag.automated',
                            operator: 'equal',
                            value: [true]
                        }]
                    }).then((result: any) => {

                        if (result.data.success.warning) {

                            this.utilsService.showInfoToast(`${result.data.success.warning}<br>${result.data.success.results.map((tag: any) => tag.name + ', ')}`);
                        }

                        this._resetAfterBulkOperation();
                    });
                }
            }
        });
    }

    public createTag(): void {

        const createDialog = this.matDialog.open(SfxDialog, {
            panelClass: 'sfx-dialog-container-sm',
            data: {
                title: 'New tag',
                confirm: 'Create',
                theme: 'blue',
                content: EditTagDialogContentComponent,
                context: { tag: { name: '' } }
            }
        });

        createDialog.afterClosed()
            .pipe(
                filter((context: DialogResponse<EditTagDialogContentData>) => context?.confirmed),
                mergeMap((context: DialogResponse<EditTagDialogContentData>) => this.tagsService.create(context.state.tag)),
                catchError(() => {

                    return of(null);
                })
            )
            .subscribe({
                next: (result) => {

                    if (result) {
                        this.utilsService.showSuccessToast('Tag succesfully created.');
                    }

                    this._resetAfterBulkOperation();
                }
            });
    }

    public editTag(tag: any): void {

        const editDialog = this.matDialog.open(SfxDialog, {
            panelClass: 'sfx-dialog-container-sm',
            data: {
                title: 'Edit tag',
                confirm: 'Save',
                close: 'Cancel',
                theme: 'blue',
                content: EditTagDialogContentComponent,
                context: { tag }
            }
        });

        editDialog.afterClosed()
            .pipe(
                filter((context: DialogResponse<EditTagDialogContentData>) => context?.confirmed),
                mergeMap((context: DialogResponse<EditTagDialogContentData>) =>
                    forkJoin([this.tagsService.update(context.state.tag), of(context)])),
                catchError(() => {

                    return forkJoin([of(null), of(null)]);
                })
            )
            .subscribe({
                next: (results: any[]) => {

                    const [apiResult, context]: [any, DialogResponse<EditTagDialogContentData>] = results as [any, any];

                    if (apiResult && context) {
                        tag.name = context.state.tag.name;
                    }

                    this._resetAfterBulkOperation();
                }
            });

    }

    public async deleteTag(tag: any): Promise<void> {

        const usageDataResponse = await this.tagsService.getUsage(tag);

        const deleteDialog = this.matDialog.open(SfxDialog, {
            panelClass: 'sfx-dialog-container-xs',
            data: {
                title: 'Are you sure?',
                confirm: 'Yes',
                close: 'No',
                content: DeleteTagDialogContentComponent,
                context: { tag, ...usageDataResponse.data },
                theme: 'blue'
            }
        });

        deleteDialog.afterClosed()
            .pipe(
                filter((context: DialogResponse) => context?.confirmed),
                mergeMap(() => this.tagsService.delete(tag)),
                catchError(() => {

                    return of(null);
                })
            )
            .subscribe({
                next: () => {

                    this._resetAfterBulkOperation();
                }
            });
    }

    // Sort logic
    public selectSortOption(sortKey: string): void {

        this.sortService.setCurrentSortKey('tag', sortKey);

        this._applyFilterRules(this.filters);
    }

    public isSortOptionSelected(sortKey: string): boolean {

        return this.sortService.getCurrentSortKey('tag') === sortKey;
    }

    public selectedSortOption(): string {
        return this.sortService.getCurrentSortOption('tag')?.sort_string;
    }

    // Bulk select logic

    public ValidateState(): void {

        if (this.bulkSelectState.active) {

            if (!this.bulkSelectState.selectedAll && this.bulkSelectState.selected.length === 0) {
                this.bulkSelectState.active = false;
            }
        }
        else if (this.bulkSelectState.selectedAll || this.bulkSelectState.selected.length > 0) {
            this.bulkSelectState.active = true;
        }
    }

    public ToggleTag(tag: any): void {

        if (tag.selected) {
            this.SelectTag(tag);
        }
        else {
            this.DeselectTag(tag);
        }

        this.ValidateState();
    }


    public SelectTag(tag: any): void {
        this.bulkSelectState.selected.push(tag);
    }

    public DeselectTag(tag: any): void {

        if (this.bulkSelectState.selectedAll) {
            this.DisableSelectAll(tag);
        }
        else {
            this.bulkSelectState.selected = this.bulkSelectState.selected.filter((t) => tag.id !== t.id);
        }
    }

    public ToggleSelectAll(event: any): void {

        if (event.checked) {
            this.EnableSelectAll();
        }
        else {
            this.DisableSelectAll();
        }

        this.ValidateState();
    }

    public EnableSelectAll(): void {

        this.bulkSelectState.selected = [];
        this.tags.forEach((tag) => {
            tag.selected = true;
            this.SelectTag(tag);
        });
    }

    public DisableSelectAll(deselectedTag: any = null): void {

        if (deselectedTag) {
            this.bulkSelectState.selectedAll = false;
            this.bulkSelectState.selected = this.bulkSelectState.selected.filter((tag) =>  tag.id !== deselectedTag.id);
        }
        else {
            this.tags.forEach((tag) => {
                tag.selected = false;
            });
            this.bulkSelectState.selected = [];
        }
    }

    public MergeSelectedTags(): void {

        const mergeTagsDialog = this.matDialog.open(SfxDialog, {
            panelClass: 'sfx-dialog-container-sm',
            data: {
                title: 'Merge tags',
                confirm: 'Merge',
                theme: 'blue',
                content: MergeTagsContentComponent,
                context: {
                    mergeOptions: this.bulkSelectState.selected,
                    mergeToTag: this.bulkSelectState.selected[0]
                }
            }
        });

        mergeTagsDialog.afterClosed()
            .pipe(
                filter((context: DialogResponse<MergeTagsContentData>) => context?.confirmed),
                mergeMap((context: DialogResponse<MergeTagsContentData>) => {

                    const tag = context.state.mergeToTag.id;
                    const tagsToMerge = context.state.mergeOptions.filter((t) => t.id !== tag.id).map((t) => t.id);

                    return this.tagsService.merge(tag, tagsToMerge);
                }),
                catchError(() => {

                    return of(null);
                })
            )
            .subscribe({
                next: (result) => {

                    if (result) {
                        this.utilsService.showSuccessToast('Tags merged.');
                    }

                    this._resetAfterBulkOperation();
                }
            });
    }

    public BulkDeleteSelectedTags(): void {

        const amountOfTags = this.bulkSelectState.selectedAll ? (this.filteredTagCount || this.tagCount) : this.bulkSelectState.selected.length;

        const bulkDeleteDialog = this.matDialog.open(SfxDialog, {
            panelClass: 'sfx-dialog-container-unthemed',
            data: {
                title: 'Warning!',
                body: `You're about to delete ${ amountOfTags === 1 ? '1 tag' : amountOfTags + ' tags'}. Are you sure you want to do this?`,
                confirm: 'I\'m sure',
                close: 'Cancel'
            }
        });

        bulkDeleteDialog.afterClosed()
            .pipe(
                filter((context: DialogResponse) => context?.confirmed),
                mergeMap(() => {

                    let bulkFilter = {};

                    if (this.bulkSelectState.selectedAll) {

                        if (this.searchActive && this.search && this.search !== '') {

                            bulkFilter = {
                                condition: 'AND',
                                rules: [],
                                search: this.search
                            };
                        }
                        else {

                            bulkFilter = {
                                condition: 'AND',
                                rules: this.filterService.getFilter('tag')
                            };
                        }
                    }
                    else {

                        bulkFilter = {
                            condition: 'AND',
                            rules: [{
                                id: 'tag.id',
                                operator: 'in',
                                value: this.bulkSelectState.selected.map((tag) => tag.id)
                            }]
                        };
                    }

                    return this.bulkService.deleteTags(bulkFilter);
                }),
                catchError(() => {

                    return of(null);
                })
            )
            .subscribe({
                next: (result) => {

                    if (result) {
                        this.utilsService.showSuccessToast('Tags deleted.');
                    }

                    this._resetAfterBulkOperation();
                }
            });
    }

    private _resetAfterBulkOperation(): void {

        this.loading = true;
        this.doneLoading = false;

        this.DisableSelectAll();

        if (this.searchActive) {
            this.toggleSearch();
        }

        this._resetState();
        this.filters = this.filterService.getFilter('tag', { raw: true });

        this._applyFilterRules(this.filters);
        this.ValidateState();

        this.scrollable.scrollTo({ top: 0 });
    }

    private _resetState(): void {

        this.search = '';
        this.endReached = false;
    }

    // State management - toolbar
    public get ShowBulkSelect() {

        return this.bulkSelectState.active;
    }

    public get ShowSearch() {

        return this.searchActive && !this.bulkSelectState.active;
    }

    public get ShowDefault() {

        return !this.searchActive && !this.bulkSelectState.active;
    }

    public handleFilterError = ($event: any) => {

        this.filterErrorMessage = $event.message;
    }
}
