(function () {

    'use strict';

    angular
        .module('salesflare')
        .controller('ResearchSignUpController', ResearchSignUpController);

    function ResearchSignUpController($stateParams, $exceptionHandler, $state, authentication, datasources, model, team, utils) {

        const vm = this;
        const token = $stateParams.token;
        const email = $stateParams.email;

        vm.$onInit = function () {

            const timeZone = moment.tz.guess() || 'UTC';
            datasources.createResearcherTeam(token, email, timeZone).then(connectCallback);
        };

        function connectCallback(result) {

            if (!result.data) {
                return $exceptionHandler(new Error('Lost connection to the popup window or it was manually closed'));
            }

            const data = result.data;

            if (!data.success) {
                return utils.showErrorToast(data.error);
            }

            model.setToken(data.token);

            return authentication.fetchMe().then(function () {

                // Set the walkthrough_before_email_connection field for the team for analyzing
                const update = {
                    walkthrough_before_email_connection: true,
                    skipped_walkthrough: 'no'
                };

                team.update(update);

                return $state.go('walkthrough');
            }).catch(function () {

                vm.disableButtons = false;
            });
        }
    }
})();
