(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ListBottomSheetController', ListBottomSheetController);

    function ListBottomSheetController($document, $mdBottomSheet, $scope, email, utils) {

        const allActions = {
            'COPY': {
                handler: copy,
                icon: 'sf-icon-clipboard',
                text: 'Copy to clipboard'
            },
            'MAILTO': {
                handler: mailto,
                icon: 'sf-icon-send',
                text: 'Send an email'
            }
        };
        const selectedActions = this.actions || [];
        const text = this.text;
        $scope.actions = [];

        selectedActions.forEach(function (selectedAction) {

            const action = allActions[selectedAction];

            if (action) {
                $scope.actions.push(action);
            }
        });

        function mailto() {

            email.mailto(text);
            $mdBottomSheet.hide();
        }

        function copy() {

            const fakeInput = $document[0].createElement('input');
            $document[0].body.append(fakeInput);
            fakeInput.value = text;
            fakeInput.select();
            $document[0].execCommand('copy', false);
            fakeInput.remove();

            utils.showSuccessToast('Copied to clipboard!');
            $mdBottomSheet.hide();
        }
    }
})();
