(function () {
    'use strict';

    angular
        .module('salesflare.components.reportView', [])
        .component('sfReportView', {
            templateUrl: 'app-ajs/components/dashboards/reportView/reportView.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                reportData: '<',
                dashboardFilter: '<',
                afterRender: '&'
            }
        });

    function controller(reportService, filterService) {

        const vm = this;

        vm.$onChanges = function (changes) {

            if (changes.dashboardFilter && changes.dashboardFilter.currentValue && vm.reportData) {
                if (!vm.report) {
                    return reportService.get(vm.reportData.id).then(getResponseHandler, null, getResponseHandler);
                }

                return loadData();
            }
        };

        function getResponseHandler(response) {

            if (vm.report && (angular.toJson(vm.report) === angular.toJson(response.data))) {
                // Don't update the report if it didn't change from the cached response
                // This prevents loading the report data a potential 4 times
                return;
            }

            vm.report = response.data;
            return loadData();
        }

        function loadData() {

            vm.entity = vm.report.entity;
            vm.chartType = vm.report.chart_type;

            vm.badFilterError = filterService.parseErrorMessageFromRules(vm.report.filter.rules, 'This report uses a filter that contains');
            vm.badFieldsError = null;

            const apiOptions = {
                noToast: true,
                noToastForStatusCode: [422]
            };

            const options = {
                pipeline: vm.dashboardFilter.pipeline && vm.dashboardFilter.pipeline.id,
                to: vm.report.no_dashboard_time_filter ? null : vm.dashboardFilter.to,
                from: vm.report.no_dashboard_time_filter ? new Date(null) : vm.dashboardFilter.from, // Null would get defaulted to the current year on the server
                range: vm.dashboardFilter.range,
                group: vm.dashboardFilter.group,
                user: vm.dashboardFilter.user
            };

            return reportService.getData(vm.report.id, options, apiOptions).then(dataResponseHandler, handleServerError, dataResponseHandler);
        }

        function handleServerError(response) {

            if (response?.status === 422) {
                if (response.data?.attributes?.missing_fields) {

                    vm.badFieldsError = 'This report uses a dimension that contains a deleted custom field.';
                }
            }

        }

        function dataResponseHandler(response) {

            const data = response.data.data;

            vm.badFieldsError = null;

            // Set noData for the dashboard view to show the empty state when needed
            vm.reportData.noData = !data || (angular.isArray(data) && data.length === 0) || (angular.isArray(data.data) && data.data.length === 0);

            vm.chartData = vm.reportData.noData ? [] : data;
            vm.segmentByDate = vm.reportData.noData ? false : response.data.segment_by_date;
            vm.viewByDate = vm.reportData.noData ? false : response.data.view_by_date;

            // If the report is a predefined scorecard, map the properties so we can just render it as the new scorecards
            if (vm.report.chart_type === 'predefined' && reportService.predefinedScoreCards.includes(vm.report.predefined_type)) {
                vm.chartType = 'scorecard';

                // If there is a difference we are comparing to previous period
                if (angular.isNumber(data.difference)) {
                    vm.chartData = {
                        current_period_data: {
                            data: data.value,
                            label: data.unit
                        },
                        previous_period_data: {
                            data: data.value - data.difference,
                            label: data.unit
                        },
                        difference_unit: data.difference_unit === '%' ? data.difference_unit : undefined // We only pass this when `%` as there are cases where this is `days` but we don't want to show that
                    };
                }
                else if (angular.isNumber(data.value)) {
                    vm.chartData = {
                        data: data.value,
                        label: data.unit
                    };
                }
                else {
                    // For most sold by rep and most active rep we map the name to data and label
                    // This mimics the look of the old insights

                    // eslint-disable-next-line no-lonely-if
                    if (data.firstname && data.lastname) {
                        vm.chartData = {
                            data: data.firstname,
                            label: data.lastname
                        };
                    }
                    else if (data.name) {
                        vm.chartData = {
                            label: data.name
                        };
                    }
                    else {
                        vm.chartData = {
                            label: 'Nothing happened yet.'
                        };
                    }
                }

                if (vm.report.predefined_type === 'avg-sales-cycle') {
                    vm.chartData.inverse_trend_color = true;
                }
            }

        }
    }
})();
