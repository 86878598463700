(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('WorkflowsOverviewDialogController', WorkflowsOverviewDialogController);

    function WorkflowsOverviewDialogController($mdDialog, $mdMedia, $scope) {

        const vm = this;

        let allWorkflows = [];

        vm.search = '';

        vm.$onInit = () => {

            allWorkflows = vm.workflows;
        };

        vm.$mdMedia = $mdMedia;

        vm.back = $mdDialog.cancel;

        $scope.$watch('vm.search', (newValue, oldValue) => {

            if (newValue !== oldValue) {
                if (!vm.search) {
                    vm.workflows = allWorkflows;
                    return;
                }

                vm.workflows = allWorkflows.filter((workflow) => workflow.name.toLowerCase().includes(vm.search.toLowerCase()));
            }
        });
    }
})();
