import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'sfx-enter-recovery-code-dialog',
    templateUrl: './enter-recovery-code-dialog.component.html',
    styleUrls: ['./enter-recovery-code-dialog.component.scss']
})
export class SfxEnterRecoveryCodeDialog {
    @ViewChild('recoveryCodeForm') recoveryCodeForm!: FormGroup;
    recoveryCode = '';
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { email: string, password: string, captchaToken: string },
        @Inject('authenticationService') private authenticationService: any,
        public dialogRef: MatDialogRef<SfxEnterRecoveryCodeDialog>
    ) {
        dialogRef.updateSize('360px');
    }

    public verify = () => {

        if (this.recoveryCodeForm?.invalid) {
            return;
        }

        return this.authenticationService.logIn(this.data.email, this.data.password, this.data.captchaToken, null, this.recoveryCode).then((response: any) => {

            return this.dialogRef.close({ token: response.data.token });
        }).catch(() => {

            this.recoveryCodeForm?.controls.recoveryCode.setErrors({ 'invalidRecoveryCode': true });
        });
    };

    public close = () => {

        this.dialogRef.close();
    };
}
