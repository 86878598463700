/* eslint-disable camelcase */

import { Component, Inject, OnInit } from '@angular/core';
import { MediaService } from '@core/services/media.service';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, mergeMap, pairwise } from 'rxjs/operators';
import { NotificationService } from '@core/services/notifications.service';

import { ApiResponseModel as ApiResponse } from '@shared/models/services/api.response.model';
import { MatDialog } from '@angular/material/dialog';
import {
    DialogResponse,
    SfxDialog
} from '@shared/components/dialog/dialog.component';

interface EmailAlias {
    email: string;
    display_name: string;
}

interface EmailAccount {
    id: number;
    name: string;
    type: string;
    avatar: string | null;
    user: number;
    team: number;
    email: string;

    host: string | null;
    port: number | null;

    smtp_host: string | null;
    smtp_port: number | null;

    email_signature: string;
    add_signature_to_replies: boolean;
    add_signature_to_campaign: boolean;

    store_sent_emails: boolean | null;
    max_emails_hour: number;
    max_emails_day: number;

    status: string;
    sync_status: string;
    primary: boolean;

    aliases: EmailAlias[];

    disconnecting: boolean;
}

interface State {
    emailAccounts: EmailAccount[],
    isFirst?: boolean
}

@Component({
    selector: 'sfx-email-settings',
    templateUrl: './email-settings.component.html',
    styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit {

    public syncText: { [key: string]: string };

    public emailAccounts$: Observable<EmailAccount[]>;
    public emailAccountsToReconnect$: Observable<EmailAccount[]>;
    private emailAccountsToReconnect: EmailAccount[];
    public emailAccountsSubject$: BehaviorSubject<State>;

    public getEmailAccounts$: BehaviorSubject<null>;

    private connectInfo = {
        google: {} as { [key: string]: any }
    };

    public loading = false;

    constructor(
        @Inject('authenticationService') public authenticationService: any,

        @Inject('exceptionHandler') public exceptionHandler: any,

        @Inject('datasourceService') public datasourceService: any,
        @Inject('syncProgressService') public syncProgressService: any,

        @Inject('dialogService') private dialogService: any,

        @Inject('config') public config: any,
        @Inject('me') public me: any,
        @Inject('modelService') public modelService: any,

        @Inject('utilsService') public utilsService: any,
        @Inject('windowService') public windowService: any,

        public matDialog: MatDialog,
        public media: MediaService,
        public notificationService: NotificationService
    ) {
        this.emailAccountsSubject$ = new BehaviorSubject<State>({ emailAccounts: [], isFirst: true });

        this.emailAccounts$ = this.emailAccountsSubject$
            .pipe(
                pairwise(),
                tap(([previousState, currentState]: [State, State]) => {

                    if (!(currentState.isFirst || previousState.isFirst) && (currentState.emailAccounts.length > previousState.emailAccounts.length)) {

                        this.syncProgressService.startPolling();
                        this.notificationService.success('Great! Your mailbox is now connected. Salesflare is now syncing your mailbox.');
                    }
                }),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                map(([previousState, currentState]: [State, State]) => {

                    return currentState?.emailAccounts || [];
                })
            );

        this.emailAccountsToReconnect$ = this.emailAccounts$
            .pipe(
                map(() => {

                    const emailAccountsToReconnect = this.modelService.me.data_sources_to_reconnect as EmailAccount[];

                    emailAccountsToReconnect.forEach((emailAccount) => {

                        emailAccount.avatar = `https://lib.salesflare.com/providers/${emailAccount.type.toLowerCase()}.png`;
                        emailAccount.name = emailAccount.type;
                    });

                    this.emailAccountsToReconnect = emailAccountsToReconnect;
                    return emailAccountsToReconnect;
                })
            );


        this.getEmailAccounts$ = new BehaviorSubject(null);

        this.getEmailAccounts$
            .pipe(
                mergeMap(() => {

                    this.loading = true;

                    return this.datasourceService.getEmailSources() as Promise<ApiResponse<EmailAccount[]>>;
                }),
                map((response: ApiResponse<EmailAccount[]>) => {

                    response.data.forEach((emailAccount: EmailAccount) => {

                        if (emailAccount.type === 'nylas') {
                            emailAccount.name = 'IMAP';
                            emailAccount.type = 'imap';
                        }

                        // Office365 type should be renamed to Microsoft when everyone is migrated
                        if (emailAccount.type === 'office365') {
                            emailAccount.name = 'Microsoft';
                            emailAccount.type = 'microsoft';
                        }

                        emailAccount.avatar = `https://lib.salesflare.com/providers/${emailAccount.type.toLowerCase()}.png`;
                    });

                    return {
                        emailAccounts: response.data
                    };
                })
            )
            .subscribe({
                next: (state) => {

                    this.loading = false;

                    this.emailAccountsSubject$.next(state);
                }
            });

        this.syncText = this.syncProgressService.text;
    }

    public ngOnInit(): void {

        this.getEmailAccounts$.next(null);

        if (this.windowService.isMobile) {

            this.datasourceService.getConnectInfo('google')
                .then((response: ApiResponse<any>) => {

                    this.connectInfo.google = response.data;
                });
        }
    }

    private async runOauthFlow(options: { [key: string]: any }): Promise<any> {

        let url = `${this.config.apiUrl}datasources/connect?`;

        if (options.email) {
            url += 'email=' + options.email;
        }

        if (options.type) {
            if (!url.endsWith('?')) {
                url += '&';
            }

            url += 'type=' + options.type;
        }

        if (this.modelService.authStrategy === 'bearer') {
            if (!url.endsWith('?')) {
                url += '&';
            }

            url += 'access_token=' + this.modelService.getToken();
        }

        if (options.type === 'google') {
            url = `${this.config.apiUrl}datasources/gmail/permissions?connectUrl=${encodeURIComponent(url)}`;
        }

        const queryParams = await this.utilsService.popup(url);
        if (queryParams && queryParams.get('success') !== 'true') {
            const error = queryParams.get('error');
            if (error === 'UNAUTHORIZED_SCOPES') {
                // Show dialog with permission message
                const insufficientPermissionsDialog = this.dialogService.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title('Please enable all permissions')
                    .textContent('Salesflare needs all the requested permissions to be able to automate your CRM work.')
                    .ok('Enable')
                    .cancel('cancel');

                return this.dialogService.show(insufficientPermissionsDialog).then(() => {
                    this.connectGmail(true);
                });
            }

            this.notificationService.error(queryParams.get('error'));
        }
    }

    public makePrimary(account: EmailAccount): void {

        this.me.update({ email: account.email, _dirty: true }).then(() => {

            const previousPrimaryEmailSource = this.emailAccountsSubject$.value.emailAccounts.find(function (emailAccount) {

                return emailAccount.primary;
            });

            if (previousPrimaryEmailSource) {
                previousPrimaryEmailSource.primary = false;
            }

            account.primary = true;

            return this.notificationService.success(`${account.email} is now your primary email address.`);
        });
    }

    public disconnect(account: EmailAccount): void {

        account.disconnecting = true;

        this.matDialog.open(SfxDialog, {
            panelClass: 'sfx-dialog-container-unthemed',
            data: {
                title: 'Are you sure?',
                body: 'Any workflows that send emails from this email address will stop sending.',
                confirm: 'I\'m sure',
                close: 'Cancel'
            }
        }).afterClosed().subscribe((confirmationResult: DialogResponse) => {
            if (confirmationResult.confirmed) {
                return this.datasourceService.deleteEmailSource(account.id).then(() => {

                    this.notificationService.success('Disconnected');

                    return this.authenticationService.fetchMe().then(() => {
                        this.getEmailAccounts$.next(null);
                    });
                }).catch(() => {

                    account.disconnecting = false;
                    this.getEmailAccounts$.next(null);
                });
            }
            else {

                account.disconnecting = false;
            }
        });

        account.disconnecting = false;
    }

    public reconnect(account: EmailAccount) {

        if (account.type === 'google' && this.windowService.isMobile) {
            account.disconnecting = true;

            return this.datasourceService.deleteEmailSource(account.id).then(() => {

                account.disconnecting = false;

                let syncNeeded = true;

                if (account.status === 'RECONNECT' && account.sync_status === 'done') {
                    syncNeeded = false;
                }

                return this.connectGmail(syncNeeded);
            }).catch(() => {

                account.disconnecting = false;
                this.getEmailAccounts$.next(null);
            });
        }

        const forceOfficeReconnect = this.emailAccountsToReconnect.find((ds: EmailAccount) => {

            return ds.id === account.id;
        });

        let url = this.config.apiUrl + 'datasources/connect?email=' + account.email + '&type=' + (forceOfficeReconnect ? 'office365' : account.type) + '&reconnect=true';

        if (this.modelService.authStrategy === 'bearer') {
            url += '&access_token=' + this.modelService.getToken();
        }

        if (account.type === 'google') {
            url = `${this.config.apiUrl}datasources/gmail/permissions?connectUrl=${encodeURIComponent(url)}`;
        }

        return this.utilsService.popup(url).then((queryParams: any) => {

            if (queryParams && queryParams.get('success') !== 'true') {
                this.notificationService.error(queryParams.get('error'));
            }

            return this.authenticationService.fetchMe().then(() => {
                this.getEmailAccounts$.next(null);
            });
        });
    }

    public editSignature(account: EmailAccount): void {

        this.dialogService.show({
            fullscreen: true,
            clickOutsideToClose: true,
            controller: 'EditSignatureDialogController',
            templateUrl: 'partials/editsignaturedialog.html',
            locals: {
                email_signature: account.email_signature,
                add_signature_to_replies: account.add_signature_to_replies,
                add_signature_to_campaign: account.add_signature_to_campaign
            },
            bindToController: true,
            onShowing: (scope: any, element: any) => {

                element.children('md-dialog').addClass('edit-signature-dialog');
            }
        }).then((updatedSignatureData: any) => {

            if (updatedSignatureData) {
                return this.datasourceService.updateEmailSource(account.id, updatedSignatureData).then(() => {

                    account.email_signature = updatedSignatureData.email_signature;
                    account.add_signature_to_replies = updatedSignatureData.add_signature_to_replies;
                    account.add_signature_to_campaign = updatedSignatureData.add_signature_to_campaign;

                    return this.notificationService.success('Your signature has been updated successfully');
                });
            }
        });
    }

    public setQuota(account: EmailAccount): void {

        return this.dialogService.show({
            clickOutsideToClose: true,
            controller: 'SetSendingQuotaDialogController',
            templateUrl: 'app-ajs/components/setsendingquotadialog/setSendingQuotaDialog.html',
            controllerAs: 'vm',
            locals: {
                email: account.email,
                type: account.type,
                max_emails_hour: account.max_emails_hour,
                max_emails_day: account.max_emails_day
            },
            bindToController: true
        }).then((updatedQuota: any) => {

            if (updatedQuota) {
                return this.datasourceService.updateEmailSource(account.id, updatedQuota).then(() => {

                    account.max_emails_hour = updatedQuota.max_emails_hour;
                    account.max_emails_day = updatedQuota.max_emails_day;

                    return this.notificationService.success('Your quota has been updated successfully');
                });
            }
        });
    }

    private mobileGoogleLogin(syncNeeded: boolean) {

        return this.windowService.plugins.googleplus.login(this.connectInfo.google, (googleAuthData: any) => {

            googleAuthData.sync_needed = syncNeeded;

            return this.datasourceService.trade(googleAuthData, { type: 'google' }, { noToast: true, noToastForStatusCode: [403] })
                .then(() => {
                    return this.authenticationService.fetchMe().then(() => {
                        this.getEmailAccounts$.next(null);
                    });
                })
                .catch((err: any) => {

                    if (err?.data?.message === 'UNAUTHORIZED_SCOPES') {

                        const insufficientPermissionsDialog = this.dialogService.confirm({ multiple: true })
                            .clickOutsideToClose(true)
                            .escapeToClose(true)
                            .title('Please enable all permissions')
                            .textContent('Salesflare needs all the requested permissions to be able to automate your CRM work.')
                            .ok('Enable')
                            .cancel('cancel');

                        return this.dialogService.show(insufficientPermissionsDialog)
                            .then(() => {
                                this.connectGmail(syncNeeded);
                            });
                    }

                    return this.windowService.plugins.googleplus.logout((msg: string) => {

                        if (msg !== 'Logged user out') {
                            this.exceptionHandler(msg);
                            this.notificationService.error('Oops! Something went wrong. Please try again.');
                        }
                    });
                });
        }, (msg: string | number) => {

            // 12501 is user canceled the oauth flow
            if (msg && msg !== 12501) {
                this.exceptionHandler(msg);
                this.notificationService.error('Oops! Something went wrong. Please try again.');
            }

            return this.windowService.plugins.googleplus.logout((message: string) => {

                if (message !== 'Logged user out') {
                    this.exceptionHandler(message);
                    this.notificationService.error('Oops! Something went wrong. Please try again.');
                }
            });
        });
    }

    public async connectGmail(syncNeeded: boolean): Promise<any> {

        if (!this.windowService.isMobile) {
            await this.runOauthFlow({ type: 'google' });
            this.getEmailAccounts$.next(null);
        }

        // Try logout first to make sure the user gets a account selection dialog thingy
        return this.windowService.plugins.googleplus.logout((msg: string) => {

            if (msg !== 'Logged user out' && msg !== 'logged out') {
                this.exceptionHandler(msg);
                this.notificationService.error('Oops! Something went wrong. Please try again.');
            }

            return this.mobileGoogleLogin(syncNeeded);
        }, (err: string) => {

            if (err !== 'Please use login or trySilentLogin before logging out') {
                this.exceptionHandler(err);
                this.notificationService.error('Oops! Something went wrong. Please try again.');
            }

            return this.mobileGoogleLogin(syncNeeded);
        });
    }

    public async connectOffice365(): Promise<any> {

        await this.runOauthFlow({ type: 'office365' });
        this.getEmailAccounts$.next(null);
    }

    public connectOther($event: any): Promise<any> {

        const emailSettingsPromptDialogConfig = {
            targetEvent: $event,
            clickOutsideToClose: true,
            controller: 'EmailSettingsPromptDialogController',
            templateUrl: 'partials/emailsettingspromptdialog.html'
        };

        return this.dialogService.show(emailSettingsPromptDialogConfig)
            .then(() => {

                this.getEmailAccounts$.next(null);
            });
    }

    public async updateSMTPSettings(account: EmailAccount): Promise<any> {

        const updatedSMTPSettings = await this.dialogService.show({
            clickOutsideToClose: false,
            controller: 'UpdateSMTPSettingsController',
            templateUrl: 'partials/updatesmtpsettingsdialog.html',
            locals: {
                email: account.email,
                port: account.smtp_port,
                host: account.smtp_host,
                store_sent_emails: account.store_sent_emails === null ? !(account.host && account.host.includes('.zoho.')) : account.store_sent_emails
            },
            bindToController: true
        });
        if (updatedSMTPSettings) {
            account.port = updatedSMTPSettings.port;
            account.host = updatedSMTPSettings.host;
            account.store_sent_emails = updatedSMTPSettings.store_sent_emails;

            return this.notificationService.success('You\'re all set up for sending emails from Salesflare!');
        }
    }
}
