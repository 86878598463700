(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('persons', personsService);

    function personsService(sfHttp, config) {

        const baseUrl = config.apiUrl + 'persons';

        this.get = function (search, limit) {

            const url = baseUrl;
            const request = {
                ignoreLoadingBar: true,
                params: {}
            };

            if (search) {
                request.params.search = search;
            }

            if (limit) {
                request.params.limit = limit;
            }

            return sfHttp.get(url, request);
        };
    }
})();
