(function () {
    'use strict';

    angular
        .module('salesflare.components.emailDisplay', [])
        .component('sfEmailDisplay', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/emaildisplay/emaildisplay.html',
            bindings: {
                content: '<'
            }
        });

    function controller($sce, $element, $timeout) {

        const vm = this;
        let iframeObserver;

        vm.$onInit = function () {

            setIframeContent();
        };

        vm.$onDestroy = function () {

            if (iframeObserver) {
                iframeObserver.disconnect();
            }
        };

        vm.$onChanges = function (changes) {

            if (changes.content && changes.content.currentValue) {
                setIframeContent();
            }
        };

        // Srcdoc isn't supported in IE and older versions of Edge (below version 18) so we insert the content into the iframe via .open() and .write()
        // see https://developer.mozilla.org/en-US/docs/Web/API/Document/open
        // polyfilling srcdoc wasn't an option as it requires loading JS which didn't work with our iframe sandbox implementation.
        function setIframeContent() {

            const iframe = $element.children('iframe');
            const doc = iframe[0].contentWindow.document;
            doc.open();
            doc.write(vm.getIframeContent());
            doc.close();

            // Disconnecting the observer is needed for IE/Edge, throws Permission Denied errors otherwise
            if (iframeObserver) {
                iframeObserver.disconnect();
            }

            iframeObserver = new ResizeObserver(function (entries) {

                // Set the height on the next tick to prevent this from triggering another observer event in the same tick
                // This was causing infinite loops https://github.com/Salesflare/Client/issues/1764
                $timeout(function () {

                    return iframe.height(entries[0].contentRect.height);
                });
            });

            iframeObserver.observe(iframe[0].contentDocument.documentElement);
        }

        vm.getIframeContent = function () {

            // Doctype is needed see https://github.com/Foundry376/Mailspring/blob/110c44359875edf2ce19e9e9eb89610aa963174a/app/internal_packages/message-list/lib/email-frame.tsx#L81-L85
            // Add some custom styles to make it fit inside Salesflare. This means same font, bg color, font size, scrollbars, ...
            // We set overflow-y to hidden because we are going to show emails in full height but sometimes the vertical scrollbar messes with the full height calculation
            return $sce.trustAsHtml(`
                <!DOCTYPE html>
                <html>
                <head>
                    <base target="_blank" ></base>
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800;900">
                    <style type="text/css">
                        html, body {
                            margin: 0;
                            background-color: white;
                            font-family: 'Nunito', sans-serif;
                            color: rgba(0, 0, 0, 0.87);
                            font-size: 14px;
                            overflow-y: hidden;
                            -webkit-text-size-adjust: 100%;
                            -ms-text-size-adjust: 100%;
                            -moz-osx-font-smoothing: grayscale;
                            -webkit-font-smoothing: antialiased;
                        }

                        ::-webkit-scrollbar {
                            width: 8px;
                            height: 8px;
                            background-color: transparent;
                        }
                        
                        ::-webkit-scrollbar:hover {
                            background-color: #eee;
                        }
                        
                        ::-webkit-resizer {
                            -webkit-border-radius: 4px;
                            background-color: #666;
                        }
                        
                        ::-webkit-scrollbar-thumb {
                            min-height: 0.8em;
                            min-width: 0.8em;
                            background-color: rgba(0, 0, 0, 0.2);
                            box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset -1px -1px 0 rgba(0, 0, 0, 0.07);
                        }
                        
                        ::-webkit-scrollbar-thumb:hover {
                            background-color: #bbb;
                        }
                        
                        ::-webkit-scrollbar-thumb:active {
                            background-color: #888;
                        }
                        
                    </style>
                </head>
                ${vm.content}
                </html>
            `);
        };
    }
})();
