(function () {
    'use strict';

    angular
        .module('salesflare.components.dashboardEdit', [])
        .component('sfDashboardEdit', {
            templateUrl: 'app-ajs/components/dashboards/dashboardEdit/dashboardEdit.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                dashboard: '<',
                dashboardFilter: '<',
                pendingDashboardUpdates: '<'
            }
        });

    function controller($document, $state, $window, $mdDialog, utils, reportService, reportsService, dashboardService, model, sfMoveReportDialog) {

        const vm = this;

        vm.$onInit = function () {

            if (!['Admin', 'Manager'].includes(model.me.role.name)) {
                return $state.go('dashboards.dashboard', { id: vm.dashboard.id });
            }

            $document[0].title = ('Edit dashboard ' + vm.dashboard.name + ' | Salesflare');

            return dashboardService.getReports(vm.dashboard.id).then(function (response) {

                const reports = response.data;
                vm.scoreCards = reports.filter(function (report) {

                    return report.chart_type === 'scorecard' || (report.chart_type === 'predefined' && reportService.predefinedScoreCards.includes(report.predefined_type));
                });
                vm.charts = reports.filter(function (report) {

                    return (report.chart_type !== 'scorecard' && report.chart_type !== 'predefined') || (report.chart_type === 'predefined' && reportService.predefinedCharts.includes(report.predefined_type));
                });
            });
        };

        vm.deleteReport = function (reportId) {

            removeReportFromView(reportId);
            vm.pendingDashboardUpdates.deleteReports.push(reportId);
            // Filter out deleted report if the sort order previously was updated
            vm.pendingDashboardUpdates.updateReportOrder = vm.pendingDashboardUpdates.updateReportOrder.filter(function (orderObject) {

                return orderObject.id !== reportId;
            });
        };

        vm.moveReport = function (reportId) {

            const options = {
                reportId,
                preventUpdate: true
            };

            return sfMoveReportDialog.show(options).then(function (dashboardId) {

                if (dashboardId !== vm.dashboard.id) {
                    removeReportFromView(reportId);
                    vm.pendingDashboardUpdates.moveReports.push({ reportId, dashboardId });
                }
            });
        };

        vm.setReportSortOptions = function (containerId) {

            return {
                containment: ('#' + containerId),
                longTouch: true,
                orderChanged: function () {

                    vm.scoreCards.forEach(function (scoreCard, index) {

                        scoreCard.order = index + 1;
                    });
                    vm.charts.forEach(function (chart, index) {

                        chart.order = vm.scoreCards.length + index;
                    });
                    vm.pendingDashboardUpdates.updateReportOrder = [...vm.scoreCards, ...vm.charts].map(function (report) {

                        return {
                            id: report.id,
                            order: report.order
                        };
                    });
                },
                accept: function (sourceItemHandleScope, destSortableScope) {

                    return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
                },
                dragStart: angular.noop,
                dragEnd: angular.noop,
                dragMove: function (itemPosition, containment, eventObj) {

                    if (eventObj) {
                        const targetY = eventObj.pageY - ($window.pageYOffset || $document[0].documentElement.scrollTop);
                        const scrollContainer = $document[0].querySelector('#scroll-container').parentElement;
                        const scrollSpeed = 10;
                        // Would be better to use the actual top & bottom of the dragged item here but you don't have access to it
                        const scrollRange = 100;
                        if (targetY - scrollRange < scrollContainer.getBoundingClientRect().top) {
                            scrollContainer.scrollTop = scrollContainer.scrollTop - scrollSpeed;
                        }
                        else if (targetY + scrollRange > scrollContainer.getBoundingClientRect().bottom) {
                            scrollContainer.scrollTop = scrollContainer.scrollTop + scrollSpeed;
                        }
                    }
                }
            };
        };

        function removeReportFromView(reportId) {

            const removeScoreCardIndex = vm.scoreCards.map(function (scoreCard) {

                return scoreCard.id;
            }).indexOf(reportId);
            const removeChartIndex = vm.charts.map(function (chart) {

                return chart.id;
            }).indexOf(reportId);

            if (removeChartIndex >= 0) {
                vm.charts.splice(removeChartIndex, 1);
            }

            if (removeScoreCardIndex >= 0) {
                vm.scoreCards.splice(removeScoreCardIndex, 1);
            }
        }
    }
})();
