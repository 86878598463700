(function () {
    'use strict';

    angular
        .module('salesflare.components.emailActionCompose', [])
        .component('sfEmailActionCompose', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/workflows/workflow/workflowstep/emailAction/emailActionCompose.html',
            bindings: {
                /* General attributes */
                onEmailDataChanged: '&',
                onPreview: '&',
                /* Workflow specific attributes */
                filter: '<',
                individualRecordFilter: '<',
                canBeReply: '<',
                triggerStepSubject: '<',
                /* Initializing the editor */
                message: '<'
            }
        });

    function controller($document, $q, $mdMedia, $scope, $timeout, $window, $compile, $sce, $element, $mdDialog, datasources, workflowsService, contact, utils, fileService, model, email, persons, joditService, filterService, workflowService) {

        const vm = this;
        const unsubscribeUrl = '<div><a style="color: #6d7684;" href="{{ unsubscribe_url }}" target="_blank" rel="noopener noreferrer">Unsubscribe from our emails</a></div>';
        // We also match the preceding br added by the editor to make sure we remove that as well
        const unsubscribeUrlDetectionRegex = /((<div><br><\/div>)|(<br>))?<div><a[^>]*?{{ unsubscribe_url }}[^>]*?>.*?<\/a><\/div>/gm;
        const previewUnsubscribeUrl = '<div><a style="color: #6d7684;" href target="_blank" rel="noopener noreferrer">Unsubscribe from our emails</a></div>'; // Fake unsubscribe url
        let viewHTML = false;
        let viewPreview = false;
        let attachmentsContainer;
        let jodit;
        const maxAttachmentsSizes = {
            google: 25 * 1000 * 1000, // 25MB
            office365: 8 * 1000 *  1000, // 8MB // Set it to 8MB because of 150 megabytes (MB) upload limit in a 5 minute period which could cause issues for workflow emails (sending an email every 18s) (https://learn.microsoft.com/en-us/graph/throttling-limits#limits-per-app-id-and-mailbox-combination)
            smtp: 10 * 1000 * 1000, // 10MB
            individualFile: 10 * 1000 * 1000 // 10MB
        };
        let totalAttachmentsSize = 0;
        let thread = null;

        vm.preview = undefined;
        vm.$mdMedia = $mdMedia;
        vm.options = {
            includeUnsubscribeLink: false
        };

        vm.$onInit = function () {

            return init().then(function () {

                jodit = joditService.getJoditEditor({
                    queryStringToBindTo: $element.find('.editor')[0],
                    autofocus: false,
                    previewButton: {
                        enabled: true,
                        // eslint-disable-next-line no-shadow
                        execute: function (jodit) {

                            return $scope.$applyAsync(function () {

                                const workplace = angular.element(jodit.workplace);

                                if (viewPreview) {
                                    viewPreview = false;
                                    jodit.setReadOnly(false);
                                    jodit.container.style.height = '100%';
                                    return workplace.show();
                                }

                                if (isFilterFromSelectedContacts(vm.filter)) {
                                    const contactId = vm.filter.rules[0].value[0];

                                    return contact.get(contactId, { expandAccount: true }).then(function (response) {

                                        return showPreview(workplace, response.data);
                                    });
                                }
                                else {

                                    let filter = vm.filter || vm.individualRecordFilter;

                                    if (vm.filter && vm.filter.rules?.length < 1 && vm.individualRecordFilter) {

                                        filter = vm.individualRecordFilter;
                                    }

                                    return contact.getContactByFilter(filter, { expandAccount: true }).then(function (response) {

                                        return showPreview(workplace, response.data);
                                    });
                                }
                            });
                        }
                    },
                    mergeFieldButton: {
                        enabled: true,
                        mergeFields: vm.mergeFields
                    },
                    templatesButton: {
                        enabled: true,
                        onSelectTemplate: function (selectedTemplate, preventInsertTemplate) {

                            if (!preventInsertTemplate) {
                                // This code pastes the template into the editor
                                // The code is taken from https://github.com/xdan/jodit/blob/6b522897713fb7d3eecace7af431d93adaf497d3/src/core/selection/select.ts#L631
                                // Some changes are made so it doesn't insert a newline after inserting the template and it puts the cursor at the correct position
                                const node = jodit.createInside.div();
                                const fragment = jodit.createInside.fragment();
                                let lastChild;

                                if (jodit.selection.isFocused() && jodit.isEditorMode()) {
                                    jodit.selection.focus();
                                }

                                node.innerHTML = selectedTemplate.html_body;

                                lastChild = node.lastChild;

                                if (!lastChild) {
                                    return;
                                }

                                while (node.firstChild) {
                                    lastChild = node.firstChild;
                                    fragment.append(node.firstChild);
                                }

                                jodit.selection.insertNode(fragment, false);
                                jodit.selection.setCursorIn(lastChild);
                            }

                            if (selectedTemplate.subject && !vm.message.subject) {
                                vm.message.subject = selectedTemplate.subject;
                            }
                        }
                    },
                    attachmentsButton: {
                        enabled: true,
                        onAttachments: vm.uploadAttachments
                    },
                    sendTestButton: {
                        enabled: true,
                        execute: function () {

                            /*
                             * Renders a temporary form submit button in the DOM.
                             * We click it to submit the form and trigger the 'submit' event handler registered by the formErrorIndicator component to show form validation errors.
                             * We do it this way because making the 'Send email' button a submit button is not possible since Jodit automatically generates an anchor element for custom buttons.
                             * Submitting the form via JS was also not an option since that doesn't trigger the registered event handlers.
                             */
                            const hiddenEmailFormSubmitButton = $document[0].createElement('button');
                            hiddenEmailFormSubmitButton.setAttribute('type', 'submit');
                            hiddenEmailFormSubmitButton.setAttribute('form', $scope.emailForm.$name);
                            hiddenEmailFormSubmitButton.style.display = 'none';
                            angular.element(jodit.container).find('.jodit-toolbar__box')[0].append(hiddenEmailFormSubmitButton);
                            hiddenEmailFormSubmitButton.click();
                            hiddenEmailFormSubmitButton.remove();

                            return $scope.$applyAsync(function () {

                                const formattedMessage = getFormattedMessage();

                                if (!$scope.emailForm.$valid || !formattedMessage.body) {
                                    return;
                                }

                                // If no id, it means it is a data source from another user so we can't check if smtp is still needed. The server will handle this.
                                if (!vm.message.from.id) {
                                    return sendTest(formattedMessage);
                                }

                                return email.showConfigureSMTPSettingsDialogIfNeeded(vm.message.from).then(function () {

                                    return sendTest(formattedMessage);
                                });
                            });
                        }
                    }
                },
                // On change sync body text with our model
                function (bodyText) {

                    vm.message.body = bodyText;
                },
                // Sync mode changes with our model
                function (mode) {

                    viewHTML = mode === 'html';
                });

                jodit.container.classList.add('flex');

                if (vm.message) {
                    /*
                     * Adds the signature to the email body when:
                     *      creating a new (`!vm.message.body`) step from a data source (`vm.message.from`) with `add_signature_to_campaign` set to `true`
                     */
                    if (!vm.message.body && vm.message.from && vm.message.from.email_signature && vm.message.from.add_signature_to_campaign) {
                        vm.message.body = ('<br><br><div class="sf-signature">' + vm.message.from.email_signature + '</div>');
                    }
                    else if (vm.message.body) {
                        // Never show the unsub url in the editor
                        vm.message.body = vm.message.body.replace(unsubscribeUrlDetectionRegex, '');
                    }

                    jodit.setEditorValue(vm.message.body || '');
                }

                // Make sure we append attachments container after the source
                // Basically wait for Jodit to add the jodit_source
                $timeout(function () {

                    // The container allows us to easily add the attachments in 1 place and apply some styling (like clear:both), check jodit.scss
                    attachmentsContainer = $compile(''
                    + '<div class="jodit-attachments-container">'
                    + '    <md-chip ng-repeat="file in vm.message.attachments" title="{{ file.file_name }}">'
                    + '        <div layout="row" ng-click="vm.download(file.file_url)">'
                    + '            <md-progress-circular ng-if="!file.file_type" class="spinner" md-diameter="16" md-mode="indeterminate"></md-progress-circular>'
                    + '            <md-icon ng-if="file.file_type" class="icon icon-16 md-font material-icons" md-font-icon="{{ file.file_type | attachmentIcon }}"></md-icon>'
                    + '            <span class="accent ellipsis">{{ file.file_name }}</span>'
                    + '            <md-icon class="icon icon-18 md-font material-icons sf-icon-close" ng-click="vm.removeAttachment(file.id, file.file_size)"></md-icon>'
                    + '        </div>'
                    + '    </md-chip>'
                    + '</div>'
                    )($scope);
                    angular.element(jodit.container).find('.jodit-workplace').append(attachmentsContainer);
                });

                if (vm.message.from?.status) {

                    vm.checkDatasource();
                }
            });
        };

        vm.$onChanges = function (changes) {

            if (changes.triggerStepSubject && vm.message && vm.message.isWorkflowReply) {
                setTriggerStepSubject();
            }
        };

        $scope.$watch('emailForm.$valid', (newVal) => {

            if (vm.message.from) {
                return vm.onEmailDataChanged({
                    $event: {
                        messageObject: getFormattedMessage(),
                        senderDataSource: vm.message.from,
                        valid: newVal
                    }
                });
            }
        });

        $scope.$watch('vm.message', function (newVal, oldVal) {

            // The watch listener gets called with the same new and old val every time.
            // But we are only interested when the message has actually changed
            if (angular.equals(newVal, oldVal)) {
                return;
            }

            vm.customMessage = null;
            const isInvalid = vm.message && vm.message.from && totalAttachmentsSize > maxAttachmentsSizes[vm.message.from.type];

            if (isInvalid && $scope.emailForm.attachments.$valid) {
                const message = 'Total size of all attachments cannot be larger than ' + (maxAttachmentsSizes[vm.message.from.type] / 1000000) + 'MB';
                utils.showErrorToast(message);
                vm.customMessage = message;
            }

            $scope.emailForm.attachments.$setValidity('attachment size', !isInvalid);

            // If the from (data source) changes update the signature if needed
            if (jodit && oldVal.from && oldVal.from.email && (oldVal.from.email !== newVal.from.email)) {
                const dataSource = newVal.from;
                const addNewSignature = (
                    dataSource && dataSource.email_signature
                    && dataSource.add_signature_to_campaign
                );

                // If old signature -> remove
                const currentSignature = jodit.editor.querySelector('.sf-signature');
                if (currentSignature) {
                    jodit.editor.querySelector('.sf-signature').remove();
                    jodit.setEditorValue(); // Sync model
                }

                // If new sig
                //      if sf-message -> insertAdjacentHTML(beforebegin, sig) -- basically insert before sf-message
                //      else add to end
                if (addNewSignature) {
                    const sfMessage = jodit.editor.querySelector('.sf-message');
                    if (sfMessage) {
                        sfMessage.insertAdjacentHTML('beforebegin', '<div class="sf-signature">' + newVal.from.email_signature + '</div>');
                        jodit.setEditorValue(); // Sync model
                    }
                    else {
                        jodit.setEditorValue(jodit.getEditorValue() + '<div class="sf-signature">' + newVal.from.email_signature + '</div>');
                    }
                }
            }

            // When the message has changed the form is definitely dirty
            // This fixes the ng-quil-editor not setting the form to dirty
            $scope.emailForm.$setDirty();

            // // Change subject for reply steps if needed
            if (newVal.isWorkflowReply === true && !oldVal.isWorkflowReply) {
                setTriggerStepSubject();
            }

            // Change subject back if needed
            if (newVal.isWorkflowReply === false && oldVal.isWorkflowReply) {
                const unChanged = (vm.message.subject === ('Re: ' + vm.triggerStepSubject));
                vm.message.subject = unChanged ? '' : vm.message.subject;
            }

            if (vm.onEmailDataChanged && vm.message) {
                return vm.onEmailDataChanged({
                    $event: {
                        messageObject: getFormattedMessage(),
                        senderDataSource: vm.message.from,
                        valid: $scope.emailForm.$valid
                    }
                });
            }
        }, true);

        vm.preventEnterSubmit = function ($event) {

            if ($event.keyCode === 13) {
                $event.preventDefault();
                $event.stopPropagation();
            }
        };

        vm.showingPreview = function () {

            return viewPreview;
        };

        vm.showingHTML = function () {

            return viewHTML;
        };

        vm.onRecipientsChanged = function () {

            if (vm.onEmailDataChanged && vm.message) {
                return vm.onEmailDataChanged({
                    $event: {
                        messageObject: getFormattedMessage(),
                        senderDataSource: vm.message.from
                    }
                });
            }
        };

        vm.focusElement = function (id) {

            return angular.element('#' + id).focus();
        };

        vm.clickElement = function (id) {

            return angular.element('#' + id).click();
        };

        vm.uploadAttachments = function (files) {

            vm.showDragOverlay = false;

            if (!files || files.$error) {
                return;
            }

            vm.message.attachments = vm.message.attachments || [];

            files.forEach(function (file) {

                const displayFile = {
                    file_name: file.name,
                    file_size: file.size
                };

                if (file.size > Math.min(maxAttachmentsSizes.individualFile, maxAttachmentsSizes[vm.message.from.type])) {
                    const fileMessage = `A file cannot be larger than ${Math.min(maxAttachmentsSizes.individualFile, maxAttachmentsSizes[vm.message.from.type]) / 1000000}MB`;
                    return utils.showErrorToast(fileMessage);
                }

                if (totalAttachmentsSize + file.size > maxAttachmentsSizes[vm.message.from.type]) {
                    const message = 'Total file size cannot be larger than ' + (maxAttachmentsSizes[vm.message.from.type] / 1000000) + 'MB';
                    return utils.showErrorToast(message);
                }

                totalAttachmentsSize += file.size;

                vm.message.attachments.push(displayFile);

                return fileService.uploadFile(file, true, function (newFile) {

                    const index = vm.message.attachments.indexOf(displayFile);

                    if (!newFile) { // Ergo something went wrong
                        // if still in the list remove it
                        if (index !== -1) {
                            vm.message.attachments.splice(index, 1);
                        }

                        totalAttachmentsSize -= file.size;
                        return;
                    }

                    newFile.file_size = file.size;

                    if (newFile && index !== -1) {
                        vm.message.attachments[index] = newFile;
                    }
                });
            });

            // Scroll to bottom of the email
            return $timeout(function () {

                const element = angular.element(jodit.container).find('.jodit-workplace')[0];

                if (element) {
                    element.scrollTop = element.scrollHeight;
                }
            }, 100);
        };

        vm.removeAttachment = function (fileId, size) {

            vm.message.attachments = vm.message.attachments.filter(function (f) {

                return f.id !== fileId;
            });

            if (!vm.message.filesToDelete) {
                vm.message.filesToDelete = [];
            }

            vm.message.filesToDelete.push(fileId);
            totalAttachmentsSize = totalAttachmentsSize - size;
        };

        vm.download = function (downloadUrl) {

            $window.open(downloadUrl, '_blank', 'noopener');
        };

        vm.drag = function (isDragging, cl, event) {

            // Needs the sync apply to for it to actually show properly
            return $scope.$apply(function () {

                const files = event.dataTransfer && event.dataTransfer.items;

                if (!files) { // This can happen when you start dragging but then drag away of the page
                    vm.showDragOverlay = false;
                }
                else if (isDragging && files.length > 1) {
                    vm.showDragOverlay = true;
                }
                else {
                    vm.showDragOverlay = isDragging && !files[0].type.includes('image');
                }
            });
        };

        vm.searchPersons = function (queryString) {

            if (!queryString) {
                return [];
            }

            return $q(function (resolve) {

                $timeout.cancel(thread);
                thread = $timeout(function () {

                    return persons.get(queryString).then(function (response) {

                        const filteredPersons = response.data.filter(function (p) {

                            if (!p.email) {
                                return false;
                            }

                            const recipientEmails = [...(vm.message.to || []), ...(vm.message.cc || []), ...(vm.message.bcc || [])].map(function (recipient) {

                                return recipient.email;
                            });

                            return !recipientEmails.includes(p.email);
                        });

                        return resolve(filteredPersons.map(function (p) {

                            return {
                                name: p.name,
                                email: p.email,
                                picture: p.picture
                            };
                        }));
                    });
                }, 750);
            });
        };

        /**
         * When used in a workflow step, when needed, will show an SMTP error message with a link to open dialog
         * When used elsewhere, will check if SMTP settings are needed and show the dialog right away.
         *
         * @returns {undefined}
         */
        vm.checkDatasource = () => {

            vm.checkSmtpDatasource();

            if (vm.isDatasourceInvalid(vm.message?.from?.status)) {
                if (vm.message.from.status === 'disconnected') {
                    vm.hasDisconnectedDatasource = true;
                    $scope.emailForm.datasource.$setValidity('disconnected', false);
                }
                else if (vm.message.from.status === 'disabled_user') {
                    vm.hasDisabledUser = true;
                    $scope.emailForm.datasource.$setValidity('disabled_user', false);
                }
            }
            else {
                vm.hasDisconnectedDatasource = false;
                $scope.emailForm.datasource.$setValidity('disconnected', true);
                $scope.emailForm.datasource.$setValidity('disabled_user', true);
            }

        };


        vm.checkSmtpDatasource = () => {

            if (!vm.message || !vm.message.from || !vm.message.from.type) {
                return;
            }

            const dataSource = vm.message.from;
            // Don't show SMTP dialog, just show an error with a link to the dialog
            if ((dataSource.type !== 'google' && dataSource.type !== 'office365') && !dataSource.smtp_port && !dataSource.smtp_host) {
                vm.hasInvalidSMTP = true;
                $scope.emailForm.datasource.$setValidity('invalidSmtp', false);
            }
            else {
                vm.hasInvalidSMTP = false;
                $scope.emailForm.datasource.$setValidity('invalidSmtp', true);
            }

        };

        /**
         * Only used for workflow steps
         *
         * @returns {undefined}
         */
        vm.showSMTPDialog = function () {

            if (!vm.message || !vm.message.from || !vm.message.from.type || !vm.message.from.email) {
                return;
            }

            return email.showConfigureSMTPSettingsDialogIfNeeded(vm.message.from).then(function () {
                // Smtp settings are valid on success
                vm.hasInvalidSMTP = false;
            });
        };

        ////////////////////////

        function init() {

            // Register a helper function for Handlebars, needed to parse our merge fields
            Handlebars.registerHelper('merge', handlebarsMergeHelper);

            const defaultMessage = {
                automatic: false,
                tracked: true,
                includeUnsubscribeLink: true
            };

            vm.message = Object.assign({}, defaultMessage, vm.message);
            // Never show the unsub url in the editor`
            vm.message.body = vm.message.body && vm.message.body.replace(unsubscribeUrlDetectionRegex, '');
            vm.showCc = (vm.message.cc && vm.message.cc.length > 0);

            if (vm.message.attachments && vm.message.attachments.length > 0) {
                totalAttachmentsSize = vm.message.attachments.reduce(function sum(total, attachment) {

                    return total + attachment.file_size;
                }, 0);
            }

            if (vm.message.workflow_reply && !vm.message.isWorkflowReply) {
                vm.message.isWorkflowReply = vm.message.workflow_reply;
            }

            return $q.all([
                function getEmailSources() {

                    // Skip check when we get passed a from.
                    // We assume we are editing and the from is already vetted
                    const skipSMTPCheck = !!vm.message.from;

                    return datasources.getEmailSources({ includeTeamDataSources: true }).then(function (response) {

                        vm.datasources = prepareDataSourcesForUse(response.data);

                        vm.message.from = vm.fixBrokenDatasources(vm.message.from, vm.datasources);

                        vm.checkDatasource();

                        // Handle array of emails that should be used as the sending email address, if included in the available aliases
                        const dataSourceAliases = new Set(vm.datasources.map(function (dataSource) {

                            return dataSource.sendAs;
                        }));

                        if (angular.isArray(vm.message.potentialSenderEmails)) {
                            vm.message.from = vm.message.potentialSenderEmails.find(function (potentialDataSource) {

                                return dataSourceAliases.has(potentialDataSource.email);
                            });
                        }

                        vm.message.from = vm.message.from || response.data.find(function (dataSource) {

                            return dataSource.primary;
                        }) || response.data[0];

                        if (angular.isArray(vm.message.from) && vm.message.from.length > 0) {
                            vm.message.from = vm.message.from[0];
                        }

                        const fromInDataSources = vm.datasources.find(function (ds) {

                            return ds.sendAs.toLowerCase() === vm.message.from.email.toLowerCase();
                        });

                        // When there's a data source used that belongs to a different user, we need to add it manually to the possible options
                        // We need to set vm.message.from to a new object, otherwise validation won't recognize that the required datasource field on the form is filled https://github.com/Salesflare/Server/issues/6819
                        if (!fromInDataSources && vm.message.from) {
                            const otherUserDataSource = angular.copy(vm.message.from);
                            otherUserDataSource.sendAs = vm.message.from.sendAs || vm.message.from.email;
                            otherUserDataSource.status = vm.message.from.status;
                            vm.datasources.unshift(otherUserDataSource);
                            vm.message.from = vm.datasources[0];
                        }
                        else {
                            vm.message.from = fromInDataSources;
                        }

                        if (skipSMTPCheck) {
                            return;
                        }

                        return email.showConfigureSMTPSettingsDialogIfNeeded(vm.message.from);
                    });
                }(),
                function getMergeFields() {

                    return workflowsService.getMergeFields().then(function (response) {

                        const responseData = response.data;

                        const contactMergeFields = responseData.filter(function (mf) {

                            return mf.entity_type === 'contact';
                        });
                        contactMergeFields.unshift({ subheader: 'Contact' });

                        const accountMergeFields = responseData.filter(function (mf) {

                            return mf.entity_type === 'account';
                        });
                        accountMergeFields.unshift({ subheader: 'Account' });

                        vm.mergeFields = [...contactMergeFields, ...accountMergeFields];
                    });
                }()
            ]).then(function () {

                vm.doneLoading = true;
            }).catch(function () {

                vm.doneLoading = true;
            });
        }

        /**
         * If a datasource was disconnected and later reconnected, they will differ only in ID. This can be automagically fixed by re-assigning the sending datasource.
         *
         * @param {Object} existingDatasource
         * @param {Array.<Object>} datasourceOptions
         * @returns {Object} fixedDatasource
         */
        vm.fixBrokenDatasources = (existingDatasource, datasourceOptions) => {
            if (vm.isDatasourceInvalid(existingDatasource?.status)) {
                for (const ds of datasourceOptions) {
                    if (ds.id !== existingDatasource.id && ds.email === existingDatasource.email) {
                        const fixedSource = angular.copy(existingDatasource);

                        fixedSource.id = ds.id;
                        fixedSource.status = ds.status;

                        return fixedSource;
                    }
                }
            }

            return existingDatasource;
        };

        /**
         * List all aliases for each data source (in the order they're returned from the API), grouped per data source, with the primary data source first (and then in order of connecting them)
         * Order the aliases with the default alias first (in Gmail the default one set in Gmail, in Office 365 the main email address we connected with)
         *
         * @param {Array.<Object>} dataSources
         * @returns {Array.<Object>} preparedDataSources
         */
        function prepareDataSourcesForUse(dataSources) {

            let preparedDataSources = [];

            for (const i in dataSources) {
                const dataSource = dataSources[i];
                if (dataSource.aliases.length > 0) {
                    for (const j in dataSource.aliases) {
                        const aliasObject = dataSource.aliases[j];
                        const newDataSource = angular.copy(dataSource);
                        newDataSource.sendAs = aliasObject.email;
                        newDataSource.displayName = aliasObject.display_name;
                        preparedDataSources.push(newDataSource);
                    }
                }
            }

            // Dedupe when email addresses are shown twice
            //    *  1. default alias on one data source over non-default alias on another data source
            //    *  2. if the aliases are both non-default aliases, aliases on the primary data source have preference, or in general aliases higher up in the list specified in the first bullet point
            preparedDataSources = preparedDataSources.filter(function (dataSource, index, array) {

                const dupeDefaultAliasIndex = array.findIndex(function (ds) {

                    return ds.sendAs.toLowerCase() === dataSource.sendAs.toLowerCase() && ds.sendAs.toLowerCase() === ds.email.toLowerCase();
                });

                if (dupeDefaultAliasIndex !== -1) {
                    return index === dupeDefaultAliasIndex;
                }

                const dupeAliasIndex = array.findIndex(function (ds) {

                    return ds.sendAs.toLowerCase() === dataSource.sendAs.toLowerCase();
                });

                return index === dupeAliasIndex;
            });

            return preparedDataSources;
        }

        function getFormattedMessage() {

            // Default the alias name only to the model name when from is from the session user
            const aliasName = vm.message.from?.displayName || (vm.message.from?.id ? model.me.name : '');
            const message = {
                from: {
                    email: vm.message.from && vm.message.from.sendAs,
                    name: aliasName
                },
                to: vm.message.to ? vm.message.to.map(stripExcessContactProperties) : [],
                cc: vm.message.cc ? vm.message.cc.map(stripExcessContactProperties) : [],
                bcc: vm.message.bcc ? vm.message.bcc.map(stripExcessContactProperties) : [],
                thread_id: vm.message.thread_id,
                in_reply_to: vm.message.in_reply_to,
                tracked: vm.message.tracked
            };

            if (vm.message.body !== null) {
                message.body = vm.message.body;
            }

            if (vm.message.includeUnsubscribeLink && vm.message.body && !vm.message.body.includes('{{ unsubscribe_url }}')) {
                message.body += '<br>' + unsubscribeUrl;
            }

            if (vm.message.subject) {
                message.subject = vm.message.subject;
            }

            if (vm.message.filesToDelete) {
                message.filesToDelete = vm.message.filesToDelete;
            }

            if (vm.message.attachments) {
                message.attachments = vm.message.attachments.map(function (attachment) {

                    delete attachment.size;

                    return attachment;
                });
            }
            else {
                message.attachments = [];
            }

            if (vm.message.isWorkflowReply) {
                message.workflow_reply = vm.message.isWorkflowReply;
            }

            message.data_source = vm.message.from && vm.message.from.id;

            return message;
        }

        // eslint-disable-next-line no-shadow
        function stripExcessContactProperties(contact) {

            return {
                name: contact.name,
                email: contact.email
            };
        }

        // eslint-disable-next-line no-shadow
        function showPreview(workplace, contact) {

            let preview = getPreview(vm.message.body, contact);

            if (!preview) {
                preview = '';
            }

            jodit.container.style['min-height'] = '';
            jodit.container.style.height = 'auto';
            vm.preview = $sce.trustAsHtml(preview);
            viewPreview = true;
            jodit.setReadOnly(true);
            workplace.hide();
        }

        // eslint-disable-next-line no-shadow
        function getPreview(messageBody, contact) {

            messageBody = messageBody || '';

            if (vm.message.includeUnsubscribeLink) {
                if (messageBody) {
                    messageBody += '<br><br>';
                }

                messageBody += previewUnsubscribeUrl;
            }

            const messageSubject = vm.message.subject;

            const mergeObject = utils.getMergableContact(contact);

            // Check for subject
            if (messageSubject) {
                const subjectPreview = mergeFields(messageSubject, mergeObject);
                vm.message.subjectPreview = subjectPreview;
            }

            return mergeFields(messageBody, mergeObject);
        }

        function mergeFields(text, mergeObject) {

            // Check if text has merge fields
            if (text.includes('{{')) {
                // Remove | otherwise Handlebars will fail.
                text = text.replace(/\| fallback=/g, 'fallback=');
                text = text.replace(/{{/g, '{{ merge ');
                const template = Handlebars.compile(text || '');
                text = template(mergeObject);
            }

            return text;
        }

        function sendTest(formattedMessage) {

            return $mdDialog.show($mdDialog.sendTestEmailConfigDialog()).then(function (config) {

                const testEmailData = formattedMessage;
                testEmailData.to = config.to;
                testEmailData.mergeInSampleContactData = config.mergeInSampleContactData;

                if (vm.filter && vm.filter.rules.length > 0) {

                    testEmailData.filter = angular.copy(vm.filter);
                }
                else if (vm.individualRecordFilter) {

                    testEmailData.filter = vm.individualRecordFilter;
                }
                else {
                    testEmailData.filter = [];
                }

                testEmailData.from = testEmailData.from.email;

                if (testEmailData.filter && testEmailData.filter.rules) {
                    testEmailData.filter.rules = filterService.cleanAdvancedFilterForHttp(testEmailData.filter.rules);
                }

                return workflowService.sendTestEmail(testEmailData);
            });
        }

        function handlebarsMergeHelper(text, options) {

            // If we are handling files we don't need to escape since we want to put in the link tag
            if (angular.isArray(text) && options.data.root.files.length > 0) {
                const isFiles = text.some(function (t) {

                    return options.data.root.files.includes(t);
                });

                if (isFiles) {
                    text = text.join(', ').replace(/, ([^,]*)$/, ' & $1');
                }
            }

            if (options.hash.fallback === 'REPLACE_THIS') {
                options.hash.fallback = '';
            }

            return new Handlebars.SafeString(text || options.hash.fallback);
        }

        function isFilterFromSelectedContacts(filter) {

            return filter && filter.rules && filter.rules.length === 1 && filter.rules[0].id === 'contact.id';
        }

        function setTriggerStepSubject() {

            let triggerStepSubject = '';
            if (vm.triggerStepSubject) {
                triggerStepSubject = vm.triggerStepSubject.startsWith('Re: ') ? vm.triggerStepSubject : 'Re: ' + vm.triggerStepSubject;
            }

            vm.message.subject = vm.message.subject ? vm.message.subject : triggerStepSubject;
        }

        vm.isDatasourceInvalid = (datasourceStatus) => {

            switch (datasourceStatus) {
                case 'disconnected':
                case 'disabled_user':
                    return true;
                case 'OK':
                default:
                    return false;
            }
        };
    }
})();
