import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';

import { cloneDeep } from 'lodash';

import { Subject, debounceTime, distinctUntilChanged, filter } from 'rxjs';

import { MediaService } from '@core/services/media.service';

import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'sfx-manage-team',
    templateUrl: './manage-team.component.html',
    styleUrls: ['./manage-team.component.scss']
})
export class ManageTeamComponent implements OnInit {

    @ViewChild('teamForm') teamForm!: FormControl;
    teamNameChanged = new Subject<void>();
    team: any = null;
    reachedFreeUsers = false;
    groups: any = null;
    draggedUser: any = null;
    draggingUser = false;
    loading = true;
    billing: any;

    pipelines: any[] = [];
    roles: any[] = [];
    group: any = null;

    subscriptionCanceledAtPeriodEnd = false;

    constructor(
        @Inject('stateService') public stateService: any,
        @Inject('dialogService') private dialogService: any,
        @Inject('utilsService') private utilsService: any,
        @Inject('modelService') public modelService: any,
        @Inject('teamService') private teamService: any,
        @Inject('groupService') private groupService: any,
        @Inject('groupsService') private groupsService: any,
        @Inject('userService') private userService: any,
        @Inject('billingService') private billingService: any,
        @Inject('permissionService') private permissionService: any,
        @Inject('pipelinesService') private pipelinesService: any,
        public media: MediaService,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.get();

        this.teamNameChanged
            .pipe(
                filter(() => {
                    return this.teamForm.valid;
                }),
                debounceTime(1000),
                distinctUntilChanged()
            )
            .subscribe((name) => {
                this.teamService.update({ name }).then(() => {

                    return this.utilsService.showSuccessToast('Team name has been saved');
                });
            });
    }

    public teamNameChange = () => {

        this.teamNameChanged.next(this.team.name);
    }

    public canAccessPermissions = () => {
        return this.modelService.me?.plan_flags.permissions;
    }

    public get = (): any => {

        // ... What?? Why??
        if (!this.billing) {
            this.loading = true;
        }

        this.pipelinesService.get().then((response: any) => {

            this.pipelines = response.data.map((pipeline: any) => {

                return { id: pipeline.id, name: pipeline.name };
            });
        });

        return this.teamService.get().then((teamResponse: any) => {

            this.team = teamResponse.data;
            this.reachedFreeUsers = !this.modelService.me.team.subscribed && this.team.discount && this.team.payment_type !== 'free' && this.team.discount_type === 'firstFreeThen50Off' && !((this.team.enabled_user_count + this.team.invites.length) < this.team.free_users + 1);

            this.team.add_all_users_to_accounts = !!this.team.add_all_users_to_accounts;

            return this.permissionService.getAllRoles().then((rolesResponse: any) => {

                this.roles = rolesResponse.data;

                return this.groupsService.get().then((groupsResponse: any) => {

                    // We visually add all disabled users to a separate group
                    const disableUserGroup = groupsResponse.data.reduce((userGroup: any, groupObject: any) => {

                        const disabledUsers = groupObject.users.filter((u: any) => u.disabled);

                        if (disabledUsers.length > 0) {
                            userGroup.users.push(...disabledUsers.map((u: any) => {

                                const userCopy = cloneDeep(u);
                                userCopy.originalGroup = groupObject;

                                return userCopy;
                            }));
                        }

                        return userGroup;
                    }, { name: 'Disabled users', users: [], disabledUsers: true, hide: true });

                    // Remove disabled users from the normal groups
                    groupsResponse.data.forEach((groupObject: any) => {

                        if (groupObject.users) {
                            groupObject.users = groupObject.users.filter((u: any) => !u.disabled);
                        }
                    });

                    this.groups = [...groupsResponse.data, disableUserGroup];

                    if (this.modelService.me.team.payment_type === 'stripe') {
                        return this.billingService.get().then((billingResponse: any) => {

                            this.subscriptionCanceledAtPeriodEnd = billingResponse.data.cancel_at_period_end;
                            this.loading = false;
                        });
                    }

                    this.loading = false;
                });
            });
        });
    }

    public invite = () => {

        if (this.reachedFreeUsers) {
            return this.suggestSubscription();
        }

        return this.dialogService.show({
            clickOutsideToClose: true,
            controller: 'InviteDialogController',
            templateUrl: 'partials/invitedialog.html',
            multiple: true,
            onComplete: (scope: any, element: any) => {
                element['0'].querySelectorAll('INPUT')[0].focus();
            },
            locals: {
                roles: this.roles,
                groups: this.groups.filter((groupObject: any) => !groupObject.disabledUsers)
            },
            bindToController: true
        }).then((invite: any) => {

            return this.teamService.addInvite(invite).then(() => {

                this.get();
            });
        });
    }

    public resendInvite = (inviteId: string) => {
        return this.teamService.resendInvite(inviteId).then(() => {

            this.utilsService.showSuccessToast('The email has been resent');
        });
    }

    public acceptInvite = (inviteId: string) => {

        return this.teamService.acceptInvite(inviteId).then(() => {

            this.utilsService.showSuccessToast('Invite accepted! Your colleague will soon receive an email with the sign-up link.');

            return this.get();
        });
    }

    public deleteInvite = (inviteId: string) => {

        return this.teamService.deleteInvite(inviteId).then(() => {

            return this.get();
        });
    }

    public createGroup = () => {

        return this.dialogService.show({
            clickOutsideToClose: true,
            controller: 'EditGroupDialogController',
            templateUrl: 'partials/editgroupdialog.html',
            locals: {
                pipelines: this.pipelines
            },
            bindToController: true,
            onComplete: (scope: any, element: any) => {

                return element['0'].querySelectorAll('INPUT')[0].focus();
            }
        }).then((newGroup: any) => {

            return this.groupService.create({
                name: newGroup.name,
                pipelines: newGroup.pipelines.map((newGroupPipeline: any) => {

                    return newGroupPipeline.id;
                })
            }).then(() => {

                return this.get();
            });
        });
    }

    public updateGroup = (groupObject: any, event: any) => {

        if (groupObject.id) {
            event.stopPropagation();

            return this.dialogService.show({
                clickOutsideToClose: true,
                controller: 'EditGroupDialogController',
                templateUrl: 'partials/editgroupdialog.html',
                bindToController: true,
                locals: {
                    group: groupObject,
                    pipelines: this.pipelines
                },
                onComplete: (scope: any, element: any) => {

                    return element['0'].querySelectorAll('INPUT')[0].focus();
                }
            }).then((updatedGroupObject: any) => {

                return this.groupService.update(groupObject.id, {
                    name: updatedGroupObject.name,
                    pipelines: updatedGroupObject.pipelines.map((updatedGroupPipeline: any) => {

                        return updatedGroupPipeline.id;
                    })
                }).then(() => {

                    return this.get();
                });
            });
        }
    };

    public deleteGroup = (item: any, event: any) => {

        event.stopPropagation();

        const confirm = this.dialogService.confirm({ multiple: true })
            .clickOutsideToClose(true)
            .textContent('Are you sure you want to delete this group?')
            .ok('Yes')
            .cancel('No');

        return this.dialogService.show(confirm).then(() => {

            return this.groupService.delete(item).then(this.get);
        });
    }


    public disableUser = (event: any, userId: string, disabled: boolean) => {
        event.stopPropagation();

        const button = event.currentTarget;
        button.disabled = true;

        if (!disabled && this.reachedFreeUsers) {
            button.disabled = false;

            return this.suggestSubscription();
        }

        if (this.subscriptionCanceledAtPeriodEnd) {
            const confirm = this.dialogService.confirm()
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .title('Are you sure?')
                .textContent('Enabling or disabling users will re-instate your subscription.')
                .ok('Yes')
                .cancel('No');

            return this.dialogService.show(confirm).then(() => {

                return this.userService.disable(userId, { disabled }).then(() => {

                    this.setUserDisabledInGroups(this.groups, userId, disabled);
                }).finally(() => {

                    button.disabled = false;
                });
            }, function () {

                button.disabled = false;
            });
        }

        return this.userService.disable(userId, { disabled }).then(() => {

            this.setUserDisabledInGroups(this.groups, userId, disabled);
        }).finally(() => {

            button.disabled = false;
        });
    }

    public suggestSubscription = () => {

        let dialogHtmlContent = '<p>';

        if (this.team.free_users > 0) {
            dialogHtmlContent += 'You\'re at your limit of ' + (this.team.free_users + 1) + ' free users. ';
        }

        dialogHtmlContent += 'Thanks to ';

        if (this.team.discount.lastIndexOf('SUMO', 0) === 0) {
            dialogHtmlContent += 'Appsumo';
        }
        else {
            dialogHtmlContent += this.team.discount;
        }

        dialogHtmlContent += ', you can add as many team members as you want at 50% discount! <br>Just hit subscribe and enter your credit card details.</p>';

        const confirm = this.dialogService.confirm()
            .title('Let\'s get your team on Salesflare')
            .clickOutsideToClose(true)
            .htmlContent(dialogHtmlContent)
            .ok('Subscribe')
            .cancel('Cancel');

        return this.dialogService.show(confirm).then(() => {

            return this.stateService.go('checkout', { plan: this.utilsService.getPlanNameBySalesflarePlanId(this.team.plan) });
        });
    }

    /**
     * Enables/disables a user in memory
     * We want to keep the user in the same group so the user doesn't jump (from or to the 'Disabled users' group)
     * That's why we do it in mem instead of refetching the groups
     *
     * @param {Object[]} groupsArray
     * @param {Number} userId
     * @param {Boolean} disabled
     */
    public setUserDisabledInGroups = (groupsArray: any, userId: string, disabled: boolean) => {

        groupsArray.forEach((groupObject: any) => {

            const userObject = groupObject.users.find((u: any) => u.id === userId);

            if (userObject) {
                userObject.disabled = disabled;
            }
        });
    }

    public hideShow = (group: any) => {

        if (group.hide) {
            group.hide = false;
        }
        else {
            group.hide = true;
        }
    }

    public swapUserBetweenGroups = (user: any, fromGroup: any, toGroup: any) => {

        const originGroup = this.groups.find((group: any) => group.id === fromGroup.id);

        originGroup.users = originGroup.users.filter((filteredUser: any) => filteredUser.id !== user.id);

        toGroup.users.push(user);

        toGroup.users = toGroup.users.sort((a: any, b: any) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    }

    public onDropListDropped = ($event: any) => {

        const draggedUser = $event.item.data;
        const draggedToGroup = $event.container.data;

        this.swapUserBetweenGroups(draggedUser, draggedUser.originalGroup, draggedToGroup);

        if (draggedToGroup.id) {
            return this.groupService.addUser({ id: draggedToGroup.id }, draggedUser).then(() => {

                return this.get();
            });
        }

        return this.groupService.deleteUser({ id: 0 }, draggedUser).then(() => {

            return this.get();
        });
    }

    public onGroupUserSelectRole = ($event: any): void => {

        $event.stopPropagation();
    }

    public updateUserRole = (userId: string, roleId: string) => {

        return this.userService.update(userId, { role: roleId }).then(() => {

            return this.utilsService.showSuccessToast('Role updated.');
        });
    };

    public updateInvite = (inviteId: string, update: any) => {

        return this.teamService.updateInvite(inviteId, update).then(() => {

            return this.utilsService.showSuccessToast(update.role ? 'Role updated.' : 'Group updated.');
        });
    };

    public getCommaSeparatedPipelineNames = (groupPipelines: any[]) => {

        if (groupPipelines.length === this.pipelines.length) {
            return 'All pipelines';
        }

        return groupPipelines.map((groupPipeline: any) => {

            return groupPipeline.name;
        }).join(', ').replace(/, ([^,]*)$/, ' and $1');
    };

    public onAddAllUsersToAccountsChange = () => {

        if (this.team.add_all_users_to_accounts) {
            return this.teamService.update({ add_all_users_to_accounts: false }).then(() => {

                this.team.add_all_users_to_accounts = false;
                return this.utilsService.showSuccessToast('Your team settings have been updated').catch(() => {});

            });
        }
        else {
            return this.dialogService.show({
                template: ''
                    + '<md-dialog-content class="md-dialog-content">'
                    + '    <h2 class="md-title">Are you sure?</h2>'
                    + '    <div>All interactions (emails, meetings, phone calls, etc.) with accounts will be shared with all team members.</div>'
                    + '</md-dialog-content>'
                    + '<md-dialog-actions>'
                    + '    <md-button ng-click="hide()">Yes</md-button>'
                    + '    <md-button ng-click="cancel()">No</md-button>'
                    + '</md-dialog-actions>',
                controller: ['$scope', '$mdDialog',
                    function ($scope: any, $mdDialog: any) {

                        $scope.hide = $mdDialog.hide;
                        $scope.cancel = $mdDialog.cancel;
                    }
                ],
                clickOutsideToClose: true,
                escapeToClose: true
            }).then(() => {

                return this.teamService.update({ add_all_users_to_accounts: true }).then(() => {

                    this.team.add_all_users_to_accounts = true;
                    return this.utilsService.showSuccessToast('Your team settings have been updated').catch(() => {});
                });
            },
            () => {

                this.team.add_all_users_to_accounts = false;
            });
        }
    };

    public setItemDragState = (userItem: any, fromGroup: any, dragState: boolean): void => {
        userItem.dragging = dragState;
        userItem.originalGroup = { id: fromGroup.id, name: fromGroup.name };

        this.draggingUser = dragState;

        this.cdRef.detectChanges();
    }

    public canEnterDropzone = (item: CdkDrag, list: CdkDropList): boolean => {
        return !list.data.disabledUsers;
    }

    public openMenu = ($event: any) => {
        $event.stopPropagation();
    }

    public compareNames(o1: any, o2: any): boolean {
        return o1.name === o2.name;
    }
}
