import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';

import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EmptyStateModule } from '@shared/components/empty-state/empty-state.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/bundles/material/material.module';
import { PlanFlagModule } from '@shared/components/plan-flag/plan-flag.module';

import { BillingUsageModule } from './components/billing-usage/billing-usage.module';

import { CalendarSettingsComponent } from './components/calendar-settings/calendar-settings.component';
import { ConfigurePipelinesComponent } from './components/configure-pipelines/configure-pipelines.component';

import { ImportSettingsComponent } from './components/import-settings/import-settings.component';
import { ImportListComponent } from './components/import-settings/list/import-list.component';
import { ImportMapComponent } from './components/import-settings/map/import-map.component';

import { NotificationSettingsComponent } from './components/notification-settings/notification-settings.component';

import { ManageTagsComponent } from './components/manage-tags/manage-tags.component';
import {
    DeleteTagDialogContentComponent
} from '@feature/settings/components/manage-tags/dialogs/delete-tag-dialog-content.component';
import { DisableAutomatedTaggingContentComponent } from '@feature/settings/components/manage-tags/dialogs/disable-automated-tagging-content.component';
import { EditTagDialogContentComponent } from '@feature/settings/components/manage-tags/dialogs/edit-tag-dialog-content.component';
import {
    MergeTagsContentComponent
} from '@feature/settings/components/manage-tags/dialogs/merge-tags-dialog-content.component';

import { ManageTeamComponent } from './components/manage-team/manage-team.component';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { ReferAFriendComponent } from './components/refer-a-friend/refer-a-friend.component';
import { RegionalSettingsComponent } from './components/regional-settings/regional-settings.component';
import { CustomizeFieldsComponent } from '@feature/settings/components/customize-fields/customize-fields.component';
import { CustomFieldsSettingsListComponent } from '@feature/settings/components/customize-fields/customfieldssettingslist/customfieldssettingslist.component';

import { SfxEnableTwoFADialog } from './components/signin-security/enable-2fa-dialog/enable-two-f-a-dialog.component';
import { SfxRecoveryCodesDialog } from './components/signin-security/recovery-codes-dialog/recovery-codes-dialog.component';
import { SigninSecurityComponent } from './components/signin-security/signin-security.component';

import { TargetsComponent } from './components/targets/targets.component';
import { TaskSettingsComponent } from './components/task-settings/task-settings.component';

import { AvatarModule } from '@shared/components/avatar/avatar.module';
import { SpeedDialModule } from '@shared/components/speed-dial/speed-dial.module';
import { SfxLayoutModule } from '@shared/bundles/material/layout.module';

import { MatchValidator } from '@shared/validators/matchvalidator.directive';

import { PasswordMeterComponent } from '@shared/components/password-meter/password-meter.component';
import { SfFiltersComponentWrapper } from '@shared/upgraded/filters/filters.component.wrapper';


import { ApplicationsIntegrationsSettingsComponent } from '@feature/settings/components/applications-integrations-settings/applications-integrations-settings.component';
import { EmailSettingsComponent } from '@feature/settings/components/email-settings/email-settings.component';
import { MatTableModule } from '@angular/material/table';
import { DragDropDirective } from '@shared/directives/drag-drop/drag-drop.directive';

@NgModule({
    declarations: [
        ApplicationsIntegrationsSettingsComponent,
        CalendarSettingsComponent,
        ConfigurePipelinesComponent,
        ImportSettingsComponent,
        ImportListComponent,
        ImportMapComponent,
        NotificationSettingsComponent,
        ManageTagsComponent,
        DeleteTagDialogContentComponent,
        DisableAutomatedTaggingContentComponent,
        EditTagDialogContentComponent,
        MergeTagsContentComponent,
        ManageTeamComponent,
        CustomizeFieldsComponent,
        CustomFieldsSettingsListComponent,
        PermissionsComponent,
        ReferAFriendComponent,
        RegionalSettingsComponent,
        SfxEnableTwoFADialog,
        SfxRecoveryCodesDialog,
        SigninSecurityComponent,
        TargetsComponent,
        TaskSettingsComponent,

        EmailSettingsComponent
    ],
    exports: [
        ApplicationsIntegrationsSettingsComponent,

        BillingUsageModule,
        CalendarSettingsComponent,
        ConfigurePipelinesComponent,
        CustomizeFieldsComponent,
        CustomFieldsSettingsListComponent,
        ImportSettingsComponent,
        ImportListComponent,
        ImportMapComponent,

        NotificationSettingsComponent,
        ManageTagsComponent,
        ManageTeamComponent,
        PermissionsComponent,
        ReferAFriendComponent,
        RegionalSettingsComponent,
        SigninSecurityComponent,
        TargetsComponent,
        TaskSettingsComponent,

        EmailSettingsComponent
    ],
    imports: [
        AvatarModule,
        BillingUsageModule,
        BrowserAnimationsModule,
        CdkScrollableModule,
        ClipboardModule,
        CommonModule,
        DragDropModule,
        EmptyStateModule,
        FormsModule,
        MatchValidator,
        MaterialModule,
        PlanFlagModule,
        ReactiveFormsModule,
        ScrollingModule,
        SpeedDialModule,
        UIRouterModule,
        SfxLayoutModule,
        PasswordMeterComponent,
        SfFiltersComponentWrapper,
        MatTableModule,
        DragDropDirective
    ],
    providers: [
        {
            provide: 'authenticationService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('authentication');
            }
        },
        {
            provide: 'billingService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('billing');
            }
        },
        {
            provide: 'bulkService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('bulkService');
            }
        },
        {
            provide: 'calendarService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('calendars');
            }
        },
        {
            provide: 'calendarSyncProgressService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('calendarsyncprogress');
            }
        },
        {
            provide: 'cfpLoadingBar',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('cfpLoadingBar');
            }
        },
        {
            provide: 'config',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('config');
            }
        },
        {
            provide: 'currenciesService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('currencies');
            }
        },
        {
            provide: 'customFieldsService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('customfields');
            }
        },
        {
            provide: 'datasourceService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('datasources');
            }
        },
        {
            provide: 'dialogService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('$mdDialog');
            }
        },
        {
            provide: 'exceptionHandler',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('$exceptionHandler');
            }
        },
        {
            provide: 'flagsService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('flagsService');
            }
        },
        {
            provide: 'filterService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('filterService');
            }
        },
        {
            provide: 'flagsService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('flagsService');
            }
        },
        {
            provide: 'groupService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('group');
            }
        },
        {
            provide: 'groupsService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('groups');
            }
        },
        {
            provide: 'helperFunctionsService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('helperFunctionsService');
            }
        },
        {
            provide: 'importService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('imports');
            }
        },
        {
            provide: 'importProgress',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('importprogress');
            }
        },
        {
            provide: 'localeService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('$mdDateLocale');
            }
        },
        {
            provide: 'mdDialogService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('$mdDialog');
            }
        },
        {
            provide: 'me',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('me');
            }
        },
        {
            provide: 'modelService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('model');
            }
        },
        {
            provide: 'notificationService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('notifications');
            }
        },
        {
            provide: 'passwordService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('password');
            }
        },
        {
            provide: 'permissionService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('permissionService');
            }
        },
        {
            provide: 'personService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('persons');
            }
        },
        {
            provide: 'pipelinesService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('pipelines');
            }
        },
        {
            provide: 'planService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('plansService');
            }
        },
        {
            provide: 'referralsService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('referralsService');
            }
        },
        {
            provide: 'rootScope',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('$rootScope');
            }
        },
        {
            provide: 'sfSetupPanel',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('sfSetupPanel');
            }
        },
        {
            provide: 'sortService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('sortService');
            }
        },
        {
            provide: 'stateService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('$state');
            }
        },
        {
            provide: 'stateParams',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('$stateParams');
            }
        },
        {
            provide: 'statusBarService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('statusBarService');
            }
        },
        {
            provide: 'stripeService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('stripeService');
            }
        },
        {
            provide: 'syncProgressService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('syncprogress');
            }
        },
        {
            provide: 'tasksService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('tasks');
            }
        },
        {
            provide: 'tagsService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('tagsService');
            }
        },
        {
            provide: 'targetService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('target');
            }
        },
        {
            provide: 'teamService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('team');
            }
        },
        {
            provide: 'timeZoneService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('timeZoneService');
            }
        },
        {
            provide: 'upgradeDialogService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('sfUpgradeDialog');
            }
        },
        {
            provide: 'userService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('user');
            }
        },
        {
            provide: 'userSettingsService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('usersettings');
            }
        },
        {
            provide: 'utilsService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('utils');
            }
        },
        {
            provide: 'filterService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('filterService');
            }
        },
        {
            provide: 'windowService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('$window');
            }
        }
    ]
})
export class SettingsModule {}
