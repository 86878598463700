(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('pipelines', pipelinesService);

    function pipelinesService($timeout, sfSetupPanel, sfWalkthrough, sfHttp, config, $q, model, group) {

        this.get = function (search, filters) {

            // Use a head call to only receive the pipeline count
            if (filters && filters.countOnly && filters.includeCount) {
                return sfHttp.head(config.apiUrl + 'pipelines');
            }

            const request = {
                cache: true,
                params: angular.copy(filters) || {}
            };

            if (search) {
                request.params.search = search === '' ? undefined : search;
            }

            return sfHttp.get(config.apiUrl + 'pipelines', request);
        };

        this.getViewable = function () {

            // We always need pipelines.get for correct behaviour. It expects more pipeline properties than returned by GET /groups/{id}
            const pipelineFunctions = [this.get()];

            // Don't check pipeline access when working with mock data in the walkthrough
            if (model.me.group && !sfWalkthrough.isShowing()) {
                // When in a group, only get the pipelines viewable by that group and filter previously returned pipelines
                pipelineFunctions.push(group.get(model.me.group));
            }

            return $q(function (resolve) {

                return $q.all(pipelineFunctions).then(function (results) {

                    const responseObject = {
                        pipelines: results[0].data
                    };

                    // When the group pipelines were fetched, filter the results of the normal pipeline call
                    if (model.me.group && results.length === 2) {
                        const viewablePipelineIdsByGroup = new Set(results[1].data.pipelines.map(function (pipeline) {

                            return pipeline.id;
                        }));

                        responseObject.pipelines = responseObject.pipelines.filter(function (pipeline) {

                            return viewablePipelineIdsByGroup.has(pipeline.id);
                        });
                    }

                    return resolve(responseObject);
                });
            });
        };

        this.update = function (pipelines) {

            const formattedPipelines = pipelines.map((pipeline) => {

                const pipelineCopy = angular.copy(pipeline);

                if (angular.isObject(pipelineCopy.currency)) {
                    pipelineCopy.currency = pipeline.currency.id;
                }

                if (pipelineCopy.follows_default_team_currency === true) {
                    pipelineCopy.currency = null;
                }

                delete pipelineCopy.follows_default_team_currency;

                return pipelineCopy;
            });

            return sfHttp.put(config.apiUrl + 'pipelines', formattedPipelines).then(function (response) {

                if (model.me && model.me.team.subscribed) {
                    $timeout(sfSetupPanel.updateSetupSteps, 300);
                }

                return $q.resolve(response);
            });
        };
    }
})();
