(function () {
    'use strict';

    angular
        .module('salesflare.components.moveReportDialog', [])
        .factory('sfMoveReportDialog', sfMoveReportDialog);

    function sfMoveReportDialog($mdDialog, dashboardsService) {

        return {
            show
        };

        /**
         *
         * @param {Object} options
         * @param {Number} options.reportId
         * @param {Boolean} options.shouldShowInfoText
         * @param {Boolean} options.preventUpdate
         * @returns {Promise<Number>}
         */
        function show(options) {

            return dashboardsService.get().then(function (response) {

                return $mdDialog.show({
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    multiple: false,
                    hasBackdrop: true,
                    controller: 'MoveReportDialogController',
                    controllerAs: 'vm',
                    templateUrl: 'app-ajs/components/dashboards/moveReportDialog/moveReportDialog.html',
                    locals: {
                        dashboards: response.data,
                        reportId: options.reportId,
                        shouldShowInfoText: options.shouldShowInfoText,
                        preventUpdate: options.preventUpdate
                    },
                    bindToController: true
                });
            });
        }
    }
})();
