(function () {
    'use strict';

    angular
        .module('salesflare.components.topEarningAccounts', [])
        .component('sfTopEarningAccounts', {
            templateUrl: 'app-ajs/components/dashboards/predefinedReports/topEarningAccounts/topEarningAccounts.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                data: '<'
            }
        });

    function controller($state, $mdMedia, model) {

        const vm = this;

        vm.model = model;

        ////////////////

        vm.$onChanges = function (changes) {

            if (!changes || !changes.data || !changes.data.currentValue) {
                return;
            }

            // Show empty state
            if (vm.data.top_earning_accounts.length === 0) {
                vm.data.no_data = true;
                vm.data.top_earning_accounts = [
                    { name: 'Google', picture: 'https://logo.clearbit.com/google.com', total: 232000 },
                    { name: 'Facebook', picture: 'https://logo.clearbit.com/fb.com', total: 10000 },
                    { name: 'X', picture: 'images/socials/x-logo.png', total: 78000 },
                    { name: 'Youtube', picture: 'https://logo.clearbit.com/youtube.com', total: 63000 },
                    { name: 'Tesla', picture: 'https://logo.clearbit.com/tesla.com', total: 54000 },
                    { name: 'Linkedin', picture: 'https://logo.clearbit.com/linkedin.com', total: 17000 },
                    { name: 'Quora', picture: 'https://logo.clearbit.com/quora.com', total: 9000 }
                ];
            }
        };

        vm.goToAccount = function (account) {

            if ($mdMedia('gt-sm')) {
                return $state.go('accounts.account.feed', { id: account.id, name: account.name });
            }
            else {
                return $state.go('accounts.account.info', { id: account.id, name: account.name });
            }
        };
    }
})();
