(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('account', accountService);

    function accountService($timeout, sfHttp, $state, config, $q, Upload, utils, sfSetupPanel, model) {

        this.openAccountByEmail = function (emailId) {

            return sfHttp.get(config.apiUrl + 'accounts?emailId=' + emailId, { ignoreLoadingBar: true }).then(function (response) {

                return $state.go('account.feed', { id: response.data[0].account });
            });
        };

        /**
         *
         * @param {Number} id
         * @param {Object} options
         * @param {Boolean} [options.noToast]
         * @param {Array.<Number>} [options.noToastForStatusCode] `noToast` needs to be `true` for this to apply
         * @returns {Promise.<Object>}
         */
        this.get = function (id, options) {

            return sfHttp.get(config.apiUrl + 'accounts/' + id, {
                cache: true,
                noToast: options && options.noToast,
                noToastForStatusCode: options && options.noToastForStatusCode
            });
        };

        this.create = function (data) {

            let pictureBlob;

            if (!angular.isArray(data) && data.picture && data.picture.startsWith('data:image/')) {
                pictureBlob = utils.dataURIToBlob(data.picture);
                delete data.picture;
            }

            return sfHttp.post(config.apiUrl + 'accounts', data).then(function (response) {

                if (model.me && model.me.team.subscribed) {
                    $timeout(sfSetupPanel.updateSetupSteps, 300);
                }

                if (!pictureBlob) {
                    return response;
                }

                return Upload.upload({
                    url: config.apiUrl + 'accounts/' + response.data.id + '/picture',
                    data: {
                        file: pictureBlob
                    }
                }).then(function () {

                    return response;
                });
            });
        };

        /**
         * @param {{}} account
         *
         * @param {{ enrichAccount: Boolean }} options
         *
         * @returns {{}}
         */
        this.update = function (account, options) {

            let pictureBlob;

            if (account.picture && account.picture.startsWith('data:image/')) {
                pictureBlob = utils.dataURIToBlob(account.picture);
                delete account.picture;
            }

            let putAccountUrl = `${config.apiUrl}accounts/${account.id}`;

            if (options?.enrichAccount) {
                putAccountUrl += '?enrich=true';
            }

            return sfHttp.put(putAccountUrl, account).then(function (response) {

                if (!pictureBlob) {
                    return response;
                }

                return Upload.upload({
                    url: config.apiUrl + 'accounts/' + account.id + '/picture',
                    data: {
                        file: pictureBlob
                    }
                }).then(function () {

                    return response;
                });
            });
        };

        this.delete = function (account) {

            return sfHttp.delete(config.apiUrl + 'accounts/' + account.id);
        };

        /**
         *
         * @param {Number} id
         * @param {Object} [options]
         * @param {Date} [options.before]
         * @param {Date} [options.after]
         * @param {Number} [options.limit]
         * @param {String} [options.canceler]
         * @param {Array<'email' | 'message' | 'meeting-live' | 'meeting-phone' | 'forward' | 'webpage' | 'user_added' | 'user_removed' | 'account_created'>} [options.types]
         * @param {Array<Number>} [options.contacts]
         * @param {Boolean} [ignoreLoading=false]
         * @returns {Promise}
         */
        this.getFeed = function (id, options, ignoreLoading) {

            const url = config.apiUrl + 'accounts/' + id + '/feed';
            const request = {
                cache: true,
                params: {}
            };

            options = options || {};

            if (options.before) {
                request.params.before = options.before;
            }

            if (options.after) {
                request.params.after = options.after;
            }

            if (options.limit) {
                request.params.limit = options.limit;
            }

            if (options.types) {
                // Since the $httpParamSerializer removes empty array from the params we convert it to a string here
                request.params.types = angular.toJson(options.types);
            }

            if (options.contacts) {
                // Since the $httpParamSerializer removes empty array from the params we convert it to a string here
                request.params.contacts = angular.toJson(options.contacts);
            }

            if (options.canceler) {
                request.canceler = options.canceler;
            }

            if (ignoreLoading) {
                request.ignoreLoadingBar = true;
            }

            return sfHttp.get(url, request);
        };

        this.getFiles = function (id, before, after, limit, ignoreLoading) {

            const url = config.apiUrl + 'accounts/' + id + '/files';
            const request = {
                cache: true,
                params: {}
            };

            if (before) {
                request.params.before = before;
            }

            if (after) {
                request.params.after = after;
            }

            if (limit) {
                request.params.limit = limit;
            }

            if (ignoreLoading) {
                request.ignoreLoadingBar = true;
            }

            return sfHttp.get(url, request);
        };

        this.uploadFile = function (id, file) {

            const creationUrl = config.apiUrl + 'accounts/' + id + '/files';
            const json = {
                fileName: file.name,
                fileType: file.type
            };

            return sfHttp.post(creationUrl, json).then(function (response) {
                const fileId = response.data.id;

                return Upload.upload({
                    url: config.apiUrl + 'files/' + fileId + '/upload?file_type=' + file.type,
                    data: {
                        file
                    }
                }).then(function (reply) {

                    // If fail -> delete file in database
                    if (reply.status !== 200) {
                        const deleteUrl = config.apiUrl + 'files/' + fileId + '/delete';
                        return sfHttp.delete(deleteUrl).then(function () {

                            return $q.resolve(null);
                        });
                    }

                    return $q.resolve(fileId);
                }).catch(function () {

                    const deleteUrl = config.apiUrl + 'files/' + fileId + '/delete';
                    return sfHttp.delete(deleteUrl).then(function () {

                        return $q.resolve(null);
                    });

                });
            });
        };

        this.getUsers = function (id, options) {

            const url = config.apiUrl + 'accounts/' + id + '/users';

            return sfHttp.get(url, { ignoreLoadingBar: true, noToast: options && options.noToast });
        };

        this.updateUsers = function (account, users) {

            const url = config.apiUrl + 'accounts/' + account + '/users';

            return sfHttp.put(url, users);
        };

        this.deleteUser = function (account, id) {

            const url = config.apiUrl + 'accounts/' + account + '/users/' + id;

            return sfHttp.delete(url);
        };

        this.getContacts = function (id, filters) {

            const url = config.apiUrl + 'accounts/' + id + '/contacts';

            const request = {
                ignoreLoadingBar: true,
                params: angular.copy(filters) || {}
            };

            return sfHttp.get(url, request);
        };

        this.updateContacts = function (account, contacts) {

            const url = config.apiUrl + 'accounts/' + account + '/contacts';

            return sfHttp.put(url, contacts).then(function (response) {

                if (model.me && model.me.team.subscribed) {
                    $timeout(sfSetupPanel.updateSetupSteps, 300);
                }

                return $q.resolve(response);
            });
        };

        this.createContacts = function (account, contacts) {

            const url = config.apiUrl + 'accounts/' + account + '/contacts';

            return sfHttp.post(url, contacts).then(function (response) {

                if (model.me && model.me.team.subscribed) {
                    $timeout(sfSetupPanel.updateSetupSteps, 300);
                }

                return $q.resolve(response);
            });
        };

        this.getSuggestions = function (id, search, filters, limit, offset) {

            const url = config.apiUrl + 'accounts/' + id + '/suggestions';
            const request = {
                ignoreLoadingBar: true,
                params: angular.copy(filters) || {}
            };

            // Use if's to not override with undefined/null/false
            if (search) {
                request.params.search = search === '' ? undefined : search;
            }

            if (limit) {
                request.params.limit = limit;
            }

            if (offset) {
                request.params.offset = offset;
            }

            return sfHttp.get(url, request);
        };

        this.acceptSuggestion = function (id, contact) {

            const url = config.apiUrl + 'accounts/' + id + '/contacts';

            return sfHttp.put(url, [contact]);
        };

        this.acceptAllSuggestions = function (accountId) {

            const url = config.apiUrl + 'accounts/' + accountId + '/suggestions/accept';

            return sfHttp.post(url, {});
        };

        this.rejectAllSuggestions = function (accountId) {

            const url = config.apiUrl + 'accounts/' + accountId + '/suggestions/reject';

            return sfHttp.post(url, {});
        };

        this.rejectSuggestion = function (id, contact) {

            const url = config.apiUrl + 'accounts/' + id + '/suggestions/' + contact.id;

            return sfHttp.delete(url);
        };

        this.deleteContact = function (account, id) {

            const url = config.apiUrl + 'accounts/' + account + '/contacts/' + id;

            return sfHttp.delete(url);
        };

        this.getOpportunities = function (id) {

            const url = config.apiUrl + 'accounts/' + id + '/opportunities';

            return sfHttp.get(url, { ignoreLoadingBar: true });
        };

        this.updateDomain = function (id, domain) {

            const url = config.apiUrl + 'accounts/' + id + '/domain';

            return sfHttp.put(url, domain);
        };

        this.addEmail = function (id, email) {

            return sfHttp.post(config.apiUrl + 'accounts/' + id + '/emails', email);
        };

        this.removeEmail = function (id, emailId) {

            emailId = encodeURIComponent(emailId);
            return sfHttp.delete(config.apiUrl + 'accounts/' + id + '/emails/' + emailId);
        };
    }
})();
