(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('domainService', domainService);

    function domainService(sfHttp, config) {

        this.get = (options) => {

            const url = `${config.apiUrl}domains`;
            const request = {
                params: {}
            };

            if (options.url) {
                request.params.url = options.url;
            }
            else if (options.domain) {
                request.params.domain = options.domain;
            }

            return sfHttp.get(url, request);
        };
    }
})();
