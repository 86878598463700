(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('CompanyFilesController', CompanyFilesController);

    function CompanyFilesController($rootScope) {

        if ($rootScope.history[$rootScope.history.length - 1].fromState.name !== 'accounts') {
            $rootScope.history.pop();
        }
    }
})();
