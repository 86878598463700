/* eslint angular/component-limit: ['error', 2]*/
(function () {
    'use strict';

    angular
        .module('salesflare')
        .factory('authHttpInterceptor', authHttpInterceptorFactory)
        .config(authHttpInterceptorConfig);

    function authHttpInterceptorFactory($injector, $q, model, config) {

        const factory = {
            'request': requestInterceptor,
            'responseError': responseErrorInterceptor
        };

        return factory;


        function requestInterceptor(request) {

            if (request.url.includes(config.apiUrl)) {
                request.headers['x-salesflare-client'] = config.mode;

                if (model.authStrategy === 'bearer' && model.isLoggedIn) {
                    request.headers.Authorization = request.headers.Authorization || 'Bearer ' + model.getToken();
                }
            }

            return request;
        }

        /**
         * Set the `noToast` config option to disable the auto toast on error
         * the `noToastForStatusCode`config option can be added as well to specify on what response codes the toast won't show
         * For example: `sfHttp.post(url, data, { noToast: true, noToastForStatusCode: [402, 422] })`
         *
         * @param {{}} rejection
         * @returns {Promise}
         */
        function responseErrorInterceptor(rejection) {

            if (rejection.data) {
                let message = '';
                let authentication = {};

                if (rejection.data.statusCode === 401) {
                    if (!rejection.config?.preventLogoutOnUnsuccessfulAuth) {
                        // On sign up we don't want to redirect to the login page on a 401
                        // This happens when joining an existing team: the team is already removed in the backend when showing the "Your sign-up link is on its way" page
                        const redirectToLogin = !$injector.get('$state').is('signup');
                        authentication = $injector.get('authentication');
                        authentication.localLogOut(redirectToLogin);
                    }

                    return $q.reject(rejection);
                }
                // Only reject if only the noToast config option is set
                // or if the noToastForStatusCode config option is set and the status code is in the array
                else if (rejection.config.noToast &&
                    (!angular.isArray(rejection.config.noToastForStatusCode) ||
                    rejection.config.noToastForStatusCode.length === 0 ||
                    rejection.config.noToastForStatusCode.includes(rejection.data.statusCode))
                ) {
                    return $q.reject(rejection);
                }
                else if (rejection.data.statusCode >= 500) {
                    message = 'Oops! Something went wrong. Please try again.';
                }
                else if (rejection.data.statusCode === 402) {
                    // Refetch me because plan flags are out of sync with server
                    authentication = $injector.get('authentication');
                    const sfUpgradeDialog = $injector.get('sfUpgradeDialog');

                    return authentication.fetchMe().then(() => {

                        if (rejection.data.flag) {
                            sfUpgradeDialog.show(rejection.data.flag);
                        }

                        return $q.reject(rejection);
                    }).catch(angular.noop);
                }
                else {
                    message = rejection.data.message || (rejection.data.statusCode + ' ' + rejection.data.error);

                    if (rejection.data.statusCode === 413 && rejection.data.message.includes('Payload content length greater than maximum allowed')) {
                        message = rejection.data.message.replace('Payload content length greater than maximum allowed: ', 'File cannot be larger than ').replace(/000000$/, 'MB');
                    }
                }

                // Utils will require the interceptor in some way creating a circular dependency
                // This way we negate that
                const utils = $injector.get('utils');
                utils.showErrorToast(message);
            }

            return $q.reject(rejection);
        }
    }

    function authHttpInterceptorConfig($httpProvider) {

        $httpProvider.interceptors.push('authHttpInterceptor');
    }
})();
