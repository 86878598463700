(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('mockService', mockService);

    function mockService($http, $urlMatcherFactory, config, model, $q) {

        const self = this;

        this.isRunning = false;
        this.name = null;
        this.model = null;
        this.routes = null;
        // Only reset the model if it was a mock model
        const keepModelMap = {
            'walkthrough': true,
            'walkthrough-offline': false
        };

        this.start = function (name, options) {

            this.name = name;

            return $http.get('mock/' + name + '.json').then(function (response) {

                const data = JSON.parse(angular.toJson(response.data), function (key, value) { // eslint-disable-line angular/json-functions

                    if (options) {
                        if (angular.isString(value)) {
                            const regex = /{{([^\s}]*)}}/g;
                            const matches = value.match(regex);
                            if (matches) {
                                matches.forEach(function (match) {

                                    const replaceBy = match.slice(9, -2).split('.').reduce(function (o,i) {

                                        return o[i];
                                    }, options);

                                    if (value.length === match.length) {
                                        value = replaceBy;
                                    }
                                    else {
                                        value = value.replace(match, replaceBy);
                                    }
                                });
                            }
                        }
                    }

                    return value;
                });

                self.isRunning = true;
                self.model = data.model;
                self.routes = data.routes;

                return $q.resolve();
            });
        };

        this.stop = function () {

            this.isRunning = false;

            // Only reset the model if it was a mock model
            if (!keepModelMap[this.name]) {
                model.me = null;
            }

            this.name = null;
            this.model = null;
            this.routes = null;
        };

        this.get = function (url) {

            const route = this.findRoute('GET', url.replace(config.apiUrl, ''));
            if (route) {
                const data = this.model[route.data];
                if (route.find) {
                    return { data: data[0] };
                }

                return { data, headers: function () {

                    return {
                        'x-result-count': data.length
                    };
                } };
            }


        };

        /*
        This.delete = function (url) {

        };

        this.post = function (url, data) {

        };

        this.put = function (url, data) {

        };
        */

        this.findRoute = function (method, path) {

            let params;
            const foundRoute = angular.copy(this.routes.find(function (route) {

                if (route.method.toUpperCase() === method.toUpperCase()) {
                    const urlMatcher = $urlMatcherFactory.compile(route.path);

                    params = urlMatcher.exec(path);
                    return params;
                }

                return false;
            }));

            if (!foundRoute) {
                return false;
            }

            foundRoute.params = params;
            return foundRoute;
        };
    }
})();
