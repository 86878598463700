(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('MergeAccountsDialogController', MergeAccountsDialogController);

    function MergeAccountsDialogController($mdMedia, $mdDialog, accounts) {

        const vm = this;

        vm.$mdMedia = $mdMedia;

        vm.$onInit = function () {

            // Fill in a default for the primaryAccount from the given accounts
            vm.primaryAccount = vm.accountsToMerge[0].id;
        };

        vm.merge = function () {
            // Merge accounts with account service
            const otherAccounts = vm.accountsToMerge.filter(function (account) {

                return account.id !== vm.primaryAccount;
            }).map(function (account) {

                return account.id;
            });

            const accountsInOrder = [vm.primaryAccount, ...otherAccounts];

            vm.loading = true;
            return accounts.merge(accountsInOrder).then(function (response) {

                vm.loading = false;
                vm.back(null, response);
            }).catch(function (err) {

                vm.loading = false;
                vm.back(err);
            });
        };

        vm.back = function (err, response) {

            $mdDialog.hide({ err, data: response, primaryAccount: vm.primaryAccount });
        };

        vm.cancel = function () {

            $mdDialog.cancel();
        };
    }
})();
