(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('SetupController', SetupController);

    function SetupController($window, $mdMedia, $mdDialog, $state, $rootScope, $animate, $scope, $timeout, $transitions, authentication, utils, model, setupStepsService) {

        const vm = this;
        vm.$mdMedia = $mdMedia;
        vm.me = model.me;
        vm.setupSteps = [];

        const maxAmountOfDaysToUnlock = 30;

        getSetupSteps();

        function getSetupSteps() {

            // Refetch me to always show correct amount of trial days
            authentication.fetchMe({ ignoreLoadingBar: true });

            return setupStepsService.get().then(function (response) {

                const swapSteps = model.me.flags.find((flag) => {
                    return flag.name === 'showAccountSetupStepEarlier' && flag.state === true;
                });

                vm.setupSteps = response.data.map(function (setupStep) {

                    if (setupStep.type === 'subscribe') {
                        setupStep.completed = model.me.team.subscribed;
                    }

                    if (swapSteps) {
                        if (setupStep.type === 'email_sidebar') {
                            setupStep.order = 4;
                        }
                        else if (setupStep.type === 'accounts') {
                            setupStep.order = 2;
                        }
                    }

                    return setupStep;
                }).sort((a, b) => {
                    return a.order >= b.order ? 1 : -1;
                });

                vm.amountOfCompletedSteps = vm.setupSteps.filter(function (setupStep) {

                    return setupStep.completed;
                }).length;

                vm.maxAmountOfDaysUnlocked = maxAmountOfDaysToUnlock <= model.me.amount_of_trial_days_earned;

                // Don't show a new animation when there's an ongoing animation happening
                if (vm.minimized && !vm.nextItemMock) {
                    const refetchedMinimizedStep = vm.setupSteps.find(function (setupStep) {

                        return setupStep.id === vm.minimizedSetupStepId;
                    });

                    if (refetchedMinimizedStep.completed) {
                        $timeout(vm.showNextNonCompletedStep, 1000);
                    }
                }
            });
        }

        $scope.$watch(function () {

            return $mdMedia('gt-sm');
        }, function (newValue, oldValue) {

            if (newValue === true && oldValue === false) {
                return $rootScope.back();
            }
            else if (newValue === false && oldValue === true) {
                vm.close();
                return $state.go('setup');
            }
        });

        vm.shouldHideGamificationElements = function () {

            return (model.me.team.payment_type === 'free' || !model.me.trial_expiry_date || model.me.team.subscribed);
        };

        vm.getSetupSteps = getSetupSteps;

        vm.getReadableTimeUntilTrialEnds = utils.getReadableTimeUntilTrialEnds;

        vm.onClick = function (setupStep) {

            if (setupStep.scope === 'admin' && model.me.role.name !== 'Admin') {
                return $mdDialog.show($mdDialog.alert()
                    .textContent('Please ask an admin to complete this step.')
                    .clickOutsideToClose(true)
                    .ok('OK'));
            }
            else if (setupStep.scope === 'manager' && !['Manager', 'Admin'].includes(model.me.role.name)) {
                return $mdDialog.show($mdDialog.alert()
                    .textContent('Please ask a manager or an admin to complete this step.')
                    .clickOutsideToClose(true)
                    .ok('OK'));
            }

            vm.minimize(setupStep.id);

            let state = '';

            switch (setupStep.type) {
                case 'mobile':
                case 'integration':
                case 'email_sidebar':
                    state = 'settings.applicationsSettings';
                    break;
                case 'calendar':
                    state = 'settings.calendarSettings';
                    break;
                case 'accounts':
                    state = 'accounts';
                    break;
                case 'pipeline':
                    state = 'settings.pipelineSettings';
                    break;
                case 'invite':
                    state = 'settings.teamSettings';
                    break;
                case 'custom_field':
                    state = 'settings.customFieldsSettings.account';
                    break;
                case 'import':
                    state = 'settings.import.account';
                    break;
                case 'workflow':
                    state = 'workflows';
                    break;
                case 'subscribe':
                    state = 'checkout';
                    break;
            }

            if (state && !$state.includes(state)) {
                return $state.go(state);
            }
        };

        vm.openMenu = function (mdMenu, event) {

            return mdMenu.open(event);
        };

        vm.goToKnowledgeBase = function (articleUrl) {

            $window.open(articleUrl, '_blank', 'noopener');
        };

        vm.goToPlans = function () {

            vm.close();

            return $state.go('plans');
        };

        vm.playVideo = function (videoUrl) {

            lity(videoUrl);
        };

        vm.hasUncompletedStepsLeft = function () {

            const stepToMinimizeTo = vm.setupSteps.find(function (setupStep) {

                return !setupStep.completed && isSetupStepCompletableByUser(setupStep);
            });

            return !!stepToMinimizeTo;
        };

        vm.minimize = function (setupStepIdToMinimizeTo) {


            if (setupStepIdToMinimizeTo) {
                vm.minimizedSetupStepId = vm.setupSteps.find(function (setupStep) {

                    return setupStep.id === setupStepIdToMinimizeTo;
                }).id;
            }
            else {
                const lastCompletedStep = angular.copy(vm.setupSteps).reverse().find(function (setupStep) {

                    return setupStep.completed === true;
                });
                const nextUncompletedStep = vm.setupSteps.find(function (setupStep) {

                    return setupStep.order > (lastCompletedStep ? lastCompletedStep.order : 0) && !setupStep.completed && isSetupStepCompletableByUser(setupStep);
                });

                if (nextUncompletedStep) {
                    vm.minimizedSetupStepId = nextUncompletedStep.id;
                }
                else {
                    vm.minimizedSetupStepId = vm.setupSteps.find(function (setupStep) {

                        return !setupStep.completed && isSetupStepCompletableByUser(setupStep);
                    }).id;
                }
            }

            vm.minimized = true;
        };

        function isSetupStepCompletableByUser(setupStep) {

            return !['manager', 'admin'].includes(setupStep.scope) || (setupStep.scope === 'admin' && model.me.role.name === 'Admin') || (setupStep.scope === 'manager' && ['Admin', 'Manager'].includes(model.me.role.name));
        }

        vm.noop = angular.noop;

        vm.maximize = function () {

            vm.minimizedSetupStepId = null;
            vm.minimized = false;
        };

        vm.getPreviousNonCompletedSetupStep = function () {

            const minimizedStep = vm.setupSteps.find(function (setupStep) {

                return setupStep.id === vm.minimizedSetupStepId;
            });
            const previousNonCompletedSteps = vm.setupSteps.filter(function (setupStep) {

                return setupStep.order < minimizedStep.order && !setupStep.completed && isSetupStepCompletableByUser(setupStep);
            });

            if (previousNonCompletedSteps.length === 0) {
                return;
            }

            return previousNonCompletedSteps[previousNonCompletedSteps.length - 1];
        };

        vm.getNextNonCompletedSetupStep = function () {

            const minimizedStep = vm.setupSteps.find(function (setupStep) {

                return setupStep.id === vm.minimizedSetupStepId;
            });
            const nextNonCompletedSteps = vm.setupSteps.filter(function (setupStep) {

                return setupStep.order > minimizedStep.order && !setupStep.completed && isSetupStepCompletableByUser(setupStep);
            });

            if (nextNonCompletedSteps.length === 0) {
                return;
            }

            return nextNonCompletedSteps[0];
        };

        vm.showPreviousNonCompletedStep = function () {

            const previousStep = vm.getPreviousNonCompletedSetupStep();

            if (!previousStep) {
                return;
            }

            vm.nextItemMock = previousStep;

            // Wait for animation-mock to be in the dom
            $timeout(function () {

                const elem = angular.element('#animation-mock');

                elem[0].style.display = 'flex';
                elem[0].style['z-index'] = 1;

                $animate.addClass(elem, 'horizontal-sliding-list-item_in').then(function () {

                    vm.minimizedSetupStepId = previousStep.id;
                    elem[0].style['z-index'] = 0;
                    elem[0].style.display = 'none';
                }).finally(function () {

                    vm.nextItemMock = null;
                });
            }, 100);
        };

        vm.showNextNonCompletedStep = function () {

            const nextStep = vm.getNextNonCompletedSetupStep();

            if (!nextStep) {
                return;
            }

            vm.nextItemMock = nextStep;
            const elem = angular.element('#setup-step-' + vm.minimizedSetupStepId);

            return $animate.addClass(elem, 'horizontal-sliding-list-item_out').then(function () {

                elem[0].style.display = 'none';
                vm.minimizedSetupStepId = nextStep.id;
            }).finally(function () {

                vm.nextItemMock = null;
            });
        };

        vm.getSubtitle = function (setupStep) {

            if (!setupStep.completed || model.me.team.subscribed) {
                return setupStep.description;
            }

            return ((setupStep.completed_by.id === model.me.id ? 'You' : ((!setupStep.completed_by.firstname || setupStep.completed_by.firstname === '') ? setupStep.completed_by.name : setupStep.completed_by.firstname)) + ' earned ' + setupStep.days + (setupStep.days === 1 ? ' day' : ' days') + ' for the team');
        };

        vm.close = function () {

            // Using vm.mdPanelRef instead of injected mdPanelRef since controller is both used for component and panel
            if (vm.mdPanelRef) {
                return vm.mdPanelRef.close().then(function () {

                    return vm.mdPanelRef.destroy();
                });
            }

            return $rootScope.back();
        };

        const removeStartTransitionHook = $transitions.onStart({}, (transition) => {

            const toState = transition.to();

            vm.hide = (toState.data && toState.data.hideMainMenu);
        });

        $scope.$on('$destroy', function () {

            return removeStartTransitionHook();
        });
    }
})();
