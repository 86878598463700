(function () {
    'use strict';

    angular
        .module('salesflare.components.tasks.account', [])
        .component('sfTasksAccount', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/tasks/account/tasksaccount.html',
            bindings: {
                account: '<'
            }
        });

    function controller($rootScope, $scope, $stateParams, $interval, tasks, utils, model) {

        const vm = this;

        vm.completedTasks = [];
        vm.todayTasks = [];
        vm.upcomingTasks = [];
        vm.overdueTasks = [];
        vm.suggestedTasks = [];

        vm.isSameDay = utils.isSameDay;

        let interval;

        if ($rootScope.history[$rootScope.history.length - 1].fromState.name.startsWith('accounts.')) {
            $rootScope.history.pop();
        }

        vm.$onInit = function () {

            const doneQuotes = [
                'All done! You\'re amazing. \u2728', // ✨
                'You\'re simply the best.',
                'Hell yeah! \uD83D\uDCAA',  // 💪
                'Done, done aaaand done.'
            ];
            vm.doneQuote = doneQuotes[Math.floor(Math.random() * doneQuotes.length)];
            vm.noTasks = false;

            if (vm.account) {
                vm.part_of = vm.account.part_of;

                if (model.me.is_admin) {
                    vm.requestAccessText = 'Add yourself to the ' + vm.account.name + ' team to see the tasks here.';
                }
                else {
                    vm.requestAccessText = 'To access the tasks of this account, ask your colleague(s) to add you to the team of the account.';
                }
            }

            $scope.$on('account:loaded', function (event, account) {

                vm.part_of = account.part_of;

                if (model.me.is_admin) {
                    vm.requestAccessText = 'Add yourself to the ' + account.name + ' team to see the tasks here.';
                }
                else {
                    vm.requestAccessText = 'To access the tasks of this account, ask your colleague(s) to add you to the team of the account.';
                }
            });

            vm.get();
            interval = $interval(vm.poll, 10 * 1000);
        };

        vm.$onDestroy = function () {

            $interval.cancel(interval);
        };

        vm.$onChanges = function (changes) {

            if (changes.account.currentValue) {
                vm.part_of = changes.account.currentValue.part_of;

                if (model.me.is_admin) {
                    vm.requestAccessText = 'Add yourself to the ' + changes.account.currentValue.name + ' team to see the tasks here.';
                }
                else {
                    vm.requestAccessText = 'To access the tasks of this account, ask your colleague(s) to add you to the team of the account.';
                }

                if (vm.part_of) {
                    return vm.get();
                }
            }
        };

        vm.get = function (ignoreLoadingBar) {

            if (!vm.account) {
                return;
            }

            const editModeCompletedTasks = vm.completedTasks.find(function (task) {

                return task.editMode;
            });

            const editModeTodayTasks = vm.todayTasks.find(function (task) {

                return task.editMode;
            });

            const editModeUpcomingTasks = vm.upcomingTasks.find(function (task) {

                return task.editMode;
            });

            const editModeOverdueTasks = vm.overdueTasks.find(function (task) {

                return task.editMode;
            });

            const editModeSuggestedTasks = vm.suggestedTasks.find(function (task) {

                return task.editMode;
            });

            if (editModeCompletedTasks || editModeTodayTasks || editModeUpcomingTasks || editModeOverdueTasks || editModeSuggestedTasks) {
                return;
            }

            const startOfTomorrow = new Date(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0, 0, 0, 0));
            const startOfToday = new Date(new Date().setHours(0, 0, 0, 0));

            const filter = {
                q: {
                    condition: 'AND',
                    rules: [
                        {
                            id: 'task.account.id',
                            operator: 'in',
                            value: [vm.account.id]
                        }
                    ]
                },
                limit: 1000
            };

            return tasks.get(null, null, filter, { ignoreLoadingBar }).then(function (response) {

                vm.completedTasks = [];
                vm.overdueTasks = [];
                vm.todayTasks = [];
                vm.upcomingTasks = [];
                vm.suggestedTasks = [];

                response.data.forEach((task) => {

                    if (task.completed) {
                        vm.completedTasks.push(task);
                    }
                    else if (new Date(task.reminder_date) < startOfToday && !task.completed && (task.type === 'manual_task')) {
                        vm.overdueTasks.push(task);
                    }
                    else if (new Date(task.reminder_date) >= startOfToday && new Date(task.reminder_date) < startOfTomorrow && !task.completed && (task.type === 'manual_task' || task.type === 'meeting')) {
                        vm.todayTasks.push(task);
                    }
                    else if (new Date(task.reminder_date) >= startOfTomorrow && !task.completed && (task.type === 'manual_task' || task.type === 'meeting')) {
                        vm.upcomingTasks.push(task);
                    }
                    else if (!task.completed && task.type !== 'manual_task' && task.type !== 'meeting') {
                        vm.suggestedTasks.push(task);
                    }
                });

                vm.completedTasks.sort((a, b) => sortTasks(a, b, 'desc'));
                vm.overdueTasks.sort((a, b) => sortTasks(a, b, 'desc'));
                vm.todayTasks.sort((a, b) => sortTasks(a, b, 'asc'));
                vm.upcomingTasks.sort((a, b) => sortTasks(a, b, 'asc'));
                vm.suggestedTasks.sort((a, b) => sortTasks(a, b, 'desc'));

                vm.allTasks = [
                    ...vm.constructTaskHeaderWithList(vm.todayTasks, 'Today'),
                    ...vm.constructTaskHeaderWithList(vm.overdueTasks, 'Overdue'),
                    ...vm.fillFakeDateHeaders(vm.upcomingTasks),
                    ...vm.constructTaskHeaderWithList(vm.suggestedTasks, 'Suggested'),
                    ...vm.constructTaskHeaderWithList(vm.completedTasks, 'Completed')
                ];

                vm.noTasks = vm.todayTasks.length === 0 && vm.upcomingTasks.length === 0 && vm.suggestedTasks.length === 0 && vm.overdueTasks.length === 0 && vm.completedTasks.length === 0;
            });
        };

        vm.constructTaskHeaderWithList = (taskList, taskListName) => {

            if (taskList?.length > 0) {
                return [
                    {
                        header: taskListName,
                        fakeTask: true
                    },
                    ...taskList
                ];
            }

            return [];
        };

        vm.fillFakeDateHeaders = (taskList) => {
            return taskList.flatMap((task, idx, arr) => {
                if (!vm.isSameDay(task.reminder_date, arr[idx - 1]?.reminder_date)) {
                    return [
                        {
                            header: task.reminder_date,
                            date: true,
                            fakeTask: true
                        },
                        task
                    ];
                }

                return [task];
            });
        };

        vm.onDismissTask = function ($event) {

            vm.todayTasks = vm.todayTasks.filter(function (t) {

                return t.id !== $event.task.id;
            });

            vm.upcomingTasks = vm.upcomingTasks.filter(function (t) {

                return t.id !== $event.task.id;
            });

            vm.completedTasks = vm.completedTasks.filter(function (t) {

                return t.id !== $event.task.id;
            });

            vm.overdueTasks = vm.overdueTasks.filter(function (t) {

                return t.id !== $event.task.id;
            });

            vm.suggestedTasks = vm.suggestedTasks.filter(function (t) {

                return t.id !== $event.task.id;
            });

            vm.noTasks = vm.todayTasks.length === 0 && vm.upcomingTasks.length === 0 && vm.suggestedTasks.length === 0 && vm.overdueTasks.length === 0 && vm.completedTasks.length === 0;
        };

        vm.onUpdateTask = function () {

            return vm.get(true);
        };

        vm.onEditTask = function ($event) {

            vm.todayTasks.forEach(function (t) {

                t.editMode = false;
            });

            vm.upcomingTasks.forEach(function (t) {

                t.editMode = false;
            });

            vm.completedTasks.forEach(function (t) {

                t.editMode = false;
            });

            vm.suggestedTasks.forEach(function (t) {

                t.editMode = false;
            });

            vm.overdueTasks.forEach(function (t) {

                t.editMode = false;
            });

            if ($event.task.type === 'meeting') {
                $event.task.editMode = false;
                return vm.get(true);
            }

            $event.task.editMode = true;
        };

        vm.poll = function () {

            return vm.get(true);
        };

        /**
         * Compare function to sort tasks
         *
         * @param {Object} taskToPlace
         * @param {Object} taskInList
         * @param {'asc' | 'desc'} order 'asc' for ascending and 'desc' for descending
         * @returns {Number} Returns 0 when the order is right and 1 when taskToPlace should be sorted to a higher index in the array
         */
        function sortTasks(taskToPlace, taskInList, order) {

            const isDateEqual = new Date(taskToPlace.reminder_date).getTime() === new Date(taskInList.reminder_date).getTime();
            order = order || 'asc';

            if (isDateEqual) {
                if (taskInList.type === 'manual_task' && taskToPlace.type === 'meeting') {
                    return 0;
                }

                if (taskInList.type === taskToPlace.type && taskInList.id > taskToPlace.id) {
                    return 0;
                }

                if (taskInList.type === taskToPlace.type && taskInList.id === taskToPlace.id && taskToPlace.account && taskInList.account && taskToPlace.account.name < taskInList.account.name) {
                    return 0;
                }
            }

            if (order.toLowerCase() === 'asc') {
                if (new Date(taskToPlace.reminder_date) < new Date(taskInList.reminder_date)) {
                    return -1;
                }
            }

            if (order.toLowerCase() === 'desc') {
                if (new Date(taskToPlace.reminder_date) > new Date(taskInList.reminder_date)) {
                    return -1;
                }
            }

            return 1;
        }
    }
})();
