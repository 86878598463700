(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('CloseDateDialogController', CloseDateDialogController);

    function CloseDateDialogController($scope, $mdDialog, $timeout, customfields, model, utils) {

        const self = this;

        $scope.opportunity = self.opportunity;
        $scope.opportunity.close_date = $scope.opportunity.close_date && new Date($scope.opportunity.close_date);
        $scope.previousCloseDate = $scope.opportunity.close_date;
        $scope.previousCloseDateIsInPast = $scope.previousCloseDate && ($scope.previousCloseDate < utils.getTodayAtMidnight());
        $scope.lostReasons = self.lostReasonCustomField && self.lostReasonCustomField.options;
        $scope.isAdmin = model.me.is_admin;
        $scope.stageIsLost = $scope.opportunity.stage.fixed_stage === 2;
        $scope.mustBeFutureDate = $scope.opportunity.stage.probability !== 0 && $scope.opportunity.stage.probability !== 100;

        const today = utils.getTodayAtMidnight();

        if ($scope.mustBeFutureDate) {

            $scope.minDate = today;
            $scope.opportunity.close_date = today;
        }

        if (!$scope.opportunity.close_date) {
            $scope.opportunity.close_date = today;
        }

        $scope.setCloseDateAndLostReason = function () {
            return $mdDialog.hide();
        };

        $scope.$watch('closeDate', function () {

            $scope.closeDateIsInPast = $scope.mustBeFutureDate && $scope.opportunity.close_date && ($scope.opportunity.close_date < utils.getTodayAtMidnight());
        });

        $scope.addOption = function (currentOption) {

            return $timeout(function () {

                $scope.opportunity.lost_reason = currentOption;

                const config = {
                    dialogId: 'customfieldOptionDialog',
                    ariaLabel: 'Add an option',
                    title: 'Add an option',
                    formId: 'customfieldOptionForm',
                    required: true,
                    inputName: 'customfieldOption',
                    requiredErrorMessage: 'An option is required',
                    cancelText: 'Cancel',
                    okText: 'Add'
                };

                return $mdDialog.show($mdDialog.sfPromptDialog(config)).then(function (optionName) {

                    const foundCustomFieldOption = $scope.lostReasons.find(function (customFieldOption) {

                        return optionName.toLowerCase() === customFieldOption.name.toLowerCase();
                    });

                    $scope.opportunity.lost_reason = foundCustomFieldOption || { name: optionName };

                    if (!foundCustomFieldOption) {
                        $scope.lostReasons.push({ name: optionName });

                        const lostReasonField = angular.copy(self.lostReasonCustomField);
                        delete lostReasonField.api_field;
                        delete lostReasonField.type;

                        return customfields.updateOpportunityField(lostReasonField);
                    }
                });
            });
        };
    }
})();
