(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('DeleteContactDialogController', DeleteContactDialogController);

    function DeleteContactDialogController($scope, $mdDialog) {

        $scope.permanentlyDeleteContact = function () {

            return $mdDialog.hide({ showPermanentDeleteConfirmationDialog: true });
        };

        $scope.confirm = $mdDialog.hide;

        $scope.cancel = $mdDialog.cancel;
    }
})();
