(function () {
    'use strict';

    angular
        .module('salesflare.components.mrr', [])
        .component('sfMrr', {
            templateUrl: 'app-ajs/components/dashboards/predefinedReports/mrr/mrr.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                data: '<',
                afterRender: '&'
            }
        });

    function controller($state, $filter) {

        const vm = this;

        ////////////////

        vm.$onChanges = function (changes) {

            if (!changes || !changes.data || !changes.data.currentValue) {
                return;
            }

            const dataPoints = vm.data.dataPoints;
            let maxXAxisValue = 0;
            const series = [];
            const labels = [];

            for (let i = 0; i < dataPoints.length; ++i) {

                labels.push(i % 3 === 0 || dataPoints.length < 15 ? dataPoints[i].timespan : '');
                series.push(dataPoints[i].mrr || 0);

                if (dataPoints[i].mrr && dataPoints[i].mrr > maxXAxisValue) {
                    maxXAxisValue = dataPoints[i].mrr;
                }
            }

            vm.chartData = {
                labels,
                series: [series]
            };

            vm.chartOptions = {
                maintainAspectRatio: false,
                tooltips: {
                    titleFontFamily: '\'Nunito\', \'sans-serif\'',
                    bodyFontFamily: '\'Nunito\', \'sans-serif\'',
                    backgroundColor: 'rgb(97, 97, 97)',
                    enabled: true,
                    mode: 'single',
                    callbacks: {
                        title: function (tooltipItems) {

                            return dataPoints[tooltipItems[0].index].timespan;
                        },
                        beforeBody: function (tooltipItems) {

                            if (!tooltipItems[0].yLabel) {
                                return 0;
                            }

                            return `${$filter('numberDecimalsWhenThere')(tooltipItems[0].yLabel, 2)} ${vm.data.currency.iso}`;
                        },
                        afterBody: function (tooltipItems) {

                            return `${$filter('date')(new Date(dataPoints[tooltipItems[0].index].from), 'yyyy-MM-dd')} to ${$filter('date')(new Date(dataPoints[tooltipItems[0].index].to), 'yyyy-MM-dd')}`;
                        },
                        label: angular.noop
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontFamily: '\'Nunito\', \'sans-serif\'',
                            fontColor: '#8493A8',
                            beginAtZero: true,
                            callback: function (value) {

                                if (value !== 0 && maxXAxisValue === 0) {
                                    return;
                                }

                                if (Math.floor(value) !== value) {
                                    return;
                                }

                                return $filter('thousandSuffix')(Number.parseInt(value), 1);
                            },
                            min: 0
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            fontFamily: '\'Nunito\', \'sans-serif\'',
                            fontColor: '#8493A8'
                        }
                    }]
                }, plugins: {
                    afterRender: {
                        callback: function () {
                            vm.afterRender();
                        }
                    }
                }
            };

            if ($state.current.name === 'printDashboard') {
                vm.chartOptions.animation = false;
                vm.chartOptions.tooltips.enabled = false;
            }

            // Color is `$dashboard-palette-green`
            vm.chartDataSetOverride = [{
                lineTension: 0,
                backgroundColor: '#d3edc850',
                pointBackgroundColor: '#57a337',
                borderColor: '#57a337',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#57a337',
                pointHoverBorderColor: '#57a337'
            }];

            delete vm.data.dataPoints;
        };
    }
})();
