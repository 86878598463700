/*eslint angular/component-limit: 'off'*/

(function () {
    'use strict';

    angular.module('salesflare')
        .filter('htmlToPlaintext', htmlToPlaintext)
        .filter('plainTextToHTML', plainTextToHTML)
        .filter('numberDecimalsWhenThere', numberDecimalsWhenThere);

    function htmlToPlaintext() {

        return function (text) {

            return  text ? String(text).replace(/<[^>]+>/gm, '').replace(/&nbsp;/gm, ' ') : '';
        };
    }

    function numberDecimalsWhenThere($filter) {

        return function (number) {

            if (number % 1 === 0) {
                return $filter('number')(number);
            }

            return $filter('number')(number, 2);
        };
    }

    function plainTextToHTML($sanitize, utils) {

        return function (text) {

            return text && $sanitize(utils.nl2br(text));
        };
    }
})();
