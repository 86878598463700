(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('SaveFilterDialogController', SaveFilterDialogController);

    function SaveFilterDialogController($mdDialog, $mdMedia, $timeout, model, savedFiltersService) {

        const vm = this;

        vm.saveAction = vm.showUpdateAndCreateChoice ? 'UPDATE_EXISTING' : 'CREATE_NEW';
        vm.newFilterName = vm.showUpdateAndCreateChoice ? undefined : vm.savedFilter.name;

        vm.$mdMedia = $mdMedia;

        vm.back = $mdDialog.cancel;

        vm.onNameChange = function () {

            if (!vm.newFilterName) {
                return vm.saveFilterForm.name.$setValidity('unique', true);
            }

            if (vm.newFilterName.toLowerCase() === 'default') {
                vm.uniqueNameErrorMessage = 'A filter with this name already exists.';
                return vm.saveFilterForm.name.$setValidity('unique', false);
            }

            return savedFiltersService.get(vm.entity, true).then(function (response) {

                const savedFilterWithSameName = response.data.find(function (savedFilter) {

                    return savedFilter.name.toLowerCase() === vm.newFilterName.toLowerCase() && savedFilter.id !== vm.savedFilter.id;
                });

                if (savedFilterWithSameName) {
                    vm.uniqueNameErrorMessage = savedFilterWithSameName.user === model.me.id ? 'A filter with this name already exists.' : 'A filter with this name already exists for another user.';
                }

                return vm.saveFilterForm.name.$setValidity('unique', !savedFilterWithSameName);
            });
        };

        $timeout(vm.onNameChange, 700);

        vm.save = function () {

            if (!vm.saveFilterForm.$valid) {
                return;
            }

            return $mdDialog.hide({
                saveAction: vm.saveAction,
                name: vm.saveAction === 'CREATE_NEW' ? vm.newFilterName : vm.savedFilter.name,
                shared: vm.savedFilter.shared
            });
        };
    }
})();
