(function () {
    'use strict';

    angular
        .module('salesflare')
        .config(function ($mdDialogProvider) {

            $mdDialogProvider.addPreset('sfPromptDialog', {
                options: function () {
                    return {
                        templateUrl: 'app-ajs/components/promptDialog/promptDialogTemplate.html',
                        controllerAs: 'vm',
                        controller: promptDialogController,
                        bindToController: true,
                        clickOutsideToClose: true,
                        escapeToClose: true,
                        multiple: true
                    };
                }
            });

            /*
             * Annotate the function so uglyfying (more specifically mangling) the file doesn't break it.
             * @ngInject
             */
            function promptDialogController($mdDialog) {

                const vm = this;

                vm.hide = $mdDialog.hide;
                vm.cancel = $mdDialog.cancel;
            }
        });
})();
