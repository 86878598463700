<sfx-toolbar enableDivider>
    Calendar settings
</sfx-toolbar>
<sfx-content>

    <sfx-section disableContentPadding>

        <ng-container *ngIf="calendarAccountsSubject$.value.calendarAccounts.length === 0">

            <sfx-empty-state image="images/calendar_empty.png">
                <h1 class="accent">No calendars connected yet!</h1>
                <h1 class="accent">Click the plus button below to start synchronizing your meetings.</h1>
            </sfx-empty-state>

        </ng-container>

        <ng-container *ngFor="let calendarAccount of calendarAccounts$ | async">

            <mat-card>
                <mat-card-header>
                    <img [src]="calendarAccount.avatar" mat-card-avatar/>
                    <mat-card-title>{{ calendarAccount.name }}</mat-card-title>
                    <mat-card-subtitle>{{ calendarAccount.profile_name }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <mat-card-actions *ngFor="let calendar of calendarAccount.calendars">
                        <mat-slide-toggle color="primary"
                                          [(ngModel)]="calendar.active"
                                          (change)="changeActiveState(calendarAccount, calendar)"
                        >
                            <div class="accent">
                                {{ calendar.name }}
                            </div>
                        </mat-slide-toggle>
                    </mat-card-actions>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button
                            track-id="disconnect-calendar" (click)="disconnect(calendarAccount)" [disabled]="calendarAccount.disconnecting">
                        Disconnect
                    </button>
                </mat-card-actions>
            </mat-card>

        </ng-container>
    </sfx-section>

</sfx-content>

<sfx-speed-dial class="fab-dial" aria-label="connect-email" (click)="connectProvider()">
</sfx-speed-dial>
