(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('SetSendingQuotaDialogController', SetSendingQuotaDialogController);

    function SetSendingQuotaDialogController($mdDialog, $scope) {

        const vm = this;

        vm.quotaLimits = {
            hourly: {
                max: 200
            },
            daily: {
                // The maximum daily sending limit is 500 for personal Gmail accounts
                // We allow setting it up to 2000 for all other email providers
                max: vm.email.includes('@gmail') ? 500 : 2000
            }
        };

        $scope.$watch('vm.max_emails_hour', () => {

            vm.setSendingQuotaForm.maxEmailsPerHour.$setValidity('hourlyLimitExceedsDailyLimit', (vm.max_emails_hour && vm.max_emails_day && vm.max_emails_hour <= vm.max_emails_day));
        });

        $scope.$watch('vm.max_emails_day', () => {

            vm.setSendingQuotaForm.maxEmailsPerHour.$setValidity('hourlyLimitExceedsDailyLimit', (vm.max_emails_hour && vm.max_emails_day && vm.max_emails_hour <= vm.max_emails_day));
        });

        /**
         * @param {'hourly'|'daily'} interval
         *
         * @returns {String}
         */
        vm.getMaxExceededErrorMessage = (interval) => {

            const unit = interval === 'hourly' ? 'hour' : 'day';
            const max = vm.quotaLimits[interval].max;

            if (vm.type === 'google') {
                return `This mailbox can not send more than ${max} emails per ${unit}.`;
            }

            if (vm.type === 'office365') {
                return `This mailbox should not send more than ${max} emails per ${unit}.`;
            }

            if (vm.type === 'imap') {
                return `Most inboxes cannot send more than ${max} emails per ${unit}. If yours can, please contact support.`;
            }
        };

        vm.save = () => {

            return $mdDialog.hide({
                max_emails_hour: vm.max_emails_hour,
                max_emails_day: vm.max_emails_day
            });
        };

        vm.close = function () {

            return $mdDialog.cancel();
        };
    }
})();
