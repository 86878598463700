/* eslint angular/component-limit: ['error', 2]*/

import { BillingUsageComponent } from '@feature/settings/components/billing-usage/billing-usage.component';
import { BillingUsageHistoryComponent } from '@feature/settings/components/billing-usage/history/history.component';
import { BillingUsageInvoiceComponent } from '@feature/settings/components/billing-usage/invoice/invoice.component';
import { BillingUsageOverviewComponent } from '@feature/settings/components/billing-usage/overview/overview.component';

import { CalendarSettingsComponent } from '@feature/settings/components/calendar-settings/calendar-settings.component';

import { ImportSettingsComponent } from '@feature/settings/components/import-settings/import-settings.component';
import { ImportListComponent } from '@feature/settings/components/import-settings/list/import-list.component';
import { ImportMapComponent } from '@feature/settings/components/import-settings/map/import-map.component';

import { ManageTagsComponent } from '@feature/settings/components/manage-tags/manage-tags.component';
import { ManageTeamComponent } from '@feature/settings/components/manage-team/manage-team.component';
import { PermissionsComponent } from '@feature/settings/components/permissions/permissions.component';
import { ReferAFriendComponent } from '@feature/settings/components/refer-a-friend/refer-a-friend.component';
import { TargetsComponent } from '@feature/settings/components/targets/targets.component';
import { RegionalSettingsComponent } from '@feature/settings/components/regional-settings/regional-settings.component';
import { SigninSecurityComponent } from '@feature/settings/components/signin-security/signin-security.component';
import { ConfigurePipelinesComponent } from '@feature/settings/components/configure-pipelines/configure-pipelines.component';
import { NotificationSettingsComponent } from '@feature/settings/components/notification-settings/notification-settings.component';
import { TaskSettingsComponent } from '@feature/settings/components/task-settings/task-settings.component';
import { CustomizeFieldsComponent } from '@feature/settings/components/customize-fields/customize-fields.component';
import { CustomFieldsSettingsListComponent } from '@feature/settings/components/customize-fields/customfieldssettingslist/customfieldssettingslist.component';
import { ApplicationsIntegrationsSettingsComponent } from '@feature/settings/components/applications-integrations-settings/applications-integrations-settings.component';
import { EmailSettingsComponent } from '@feature/settings/components/email-settings/email-settings.component';

(function () {

    angular
        .module('salesflare')
        .config(routeConfig)
        .run(routeRun);

    function routeConfig($stateProvider, $urlRouterProvider) {

        // Do this to prevent inf digest loop on load
        $urlRouterProvider.otherwise(function ($injector) {

            return $injector.get('$state').go('tasks.today');
        });

        // Generic Main state
        $stateProvider.state('main', {
            abstract: true,
            url: '',
            reloadOnSearch: false,
            templateUrl: 'partials/main.html',
            data: {
                requiredLogin: true
            }
        });

        // Deals
        $stateProvider.state('deals', {
            parent: 'main',
            url: '/deals/{name:string}',
            template: '<sf-deals layout="column" flex></sf-deals>',
            data: {
                requiredLogin: true,
                pageTitle: 'Deals'
            }
        });

        // Walkthrough
        $stateProvider.state('walkthrough', {
            parent: 'main',
            url: '/walkthrough',
            templateUrl: 'partials/walkthrough.html',
            controller: 'WalkthroughController',
            data: {
                requiredLogin: false,
                pageTitle: 'Walkthrough'
            },
            params: {
                skip: undefined,
                state: null
            }
        });

        // Tasks
        $stateProvider.state('tasks', {
            parent: 'main',
            url: '/tasks?search',
            template: '<sf-tasks layout="column" flex></sf-tasks>',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Tasks'
            }
        }).state('createTask', {
            // Not a component, since it works like the rest of the create functions behind the plus button
            url: '/tasks/create',
            templateUrl: 'partials/edittask.html',
            controller: 'EditTaskController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Create Task'
            },
            params: {
                type: 'task'
            }
        }).state('tasks.today', {
            url: '/today',
            template: '<sf-tasks-today layout="column" flex entity-count-string="vm.entityCountObjectString" tasks="vm.tasks" loading-tasks="vm.loadingTasks" on-selected-changed="vm.onSelectedChanged()" on-add-task="vm.onAddTask($event)" on-dismiss-task="vm.onDismissTask($event)" on-edit-task="vm.onEditTask($event)" on-edited-task="vm.onEditedTask($event)" on-update-task="vm.onUpdateTask($event)" is-task-filter-applied="vm.isTaskFilterApplied()" used-bad-filter="vm.usedBadFilter" bad-filter-error="vm.badFilterError" set-filters="vm.setFilters($event)" get-search="vm.searchObject.getSearch()"></sf-tasks-today>',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Today Tasks'
            }
        }).state('tasks.upcoming', {
            url: '/upcoming',
            template: '<sf-tasks-upcoming layout="column" flex entity-count-string="vm.entityCountObjectString" tasks="vm.tasks" loading-tasks="vm.loadingTasks" on-selected-changed="vm.onSelectedChanged()" on-dismiss-task="vm.onDismissTask($event)" on-edit-task="vm.onEditTask($event)" on-update-task="vm.onUpdateTask($event)" is-task-filter-applied="vm.isTaskFilterApplied()" used-bad-filter="vm.usedBadFilter" bad-filter-error="vm.badFilterError" set-filters="vm.setFilters($event)" get-search="vm.searchObject.getSearch()"></sf-tasks-upcoming>',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Upcoming Tasks'
            }
        }).state('tasks.completed', {
            url: '/completed',
            template: '<sf-tasks-completed layout="column" flex entity-count-string="vm.entityCountObjectString" tasks="vm.tasks" loading-tasks="vm.loadingTasks" on-selected-changed="vm.onSelectedChanged()" on-dismiss-task="vm.onDismissTask($event)" on-edit-task="vm.onEditTask($event)" on-update-task="vm.onUpdateTask($event)" is-task-filter-applied="vm.isTaskFilterApplied()" used-bad-filter="vm.usedBadFilter" bad-filter-error="vm.badFilterError" set-filters="vm.setFilters($event)" get-search="vm.searchObject.getSearch()"></sf-tasks-completed>',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Completed Tasks'
            }
        }).state('bulkEditTask', {
            url: '/edit',
            template: '<sf-bulk-edit-tasks flex layout="column" options="$stateParams.options"></sf-bulk-edit-tasks>',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                dialogClass: 'bulk-dialog',
                pageTitle: 'Bulk Edit Tasks'
            },
            params: {
                options: undefined
            }
        });

        // Workflows
        $stateProvider.state('workflows', {
            parent: 'main',
            url: '/workflows?search',
            reloadOnSearch: false,
            template: '<sf-workflows layout="column" flex></sf-workflows>',
            data: {
                requiredLogin: true,
                pageTitle: 'Workflows'
            }
        });

        $stateProvider.state('workflow', {
            parent: 'main',
            url: '/workflows/{id}',
            template: '<sf-workflow-details layout="column" flex></sf-workflow-details>',
            data: {
                requiredLogin: true,
                pageTitle: 'Workflow'
            },
            params: {
                editing: false,
                focusedStepOrder: 0,
                workflow: undefined
            }
        });

        $stateProvider.state('workflow-analytics', { // Dash is used for contextual help
            parent: 'main',
            url: '/workflows/{id}/analytics?search',
            reloadOnSearch: false,
            template: '<sf-workflow-analytics layout="column" flex></sf-workflow-analytics>',
            data: {
                requiredLogin: true,
                pageTitle: 'Workflow Analytics'
            },
            params: {
                viewing: 'total',
                workflow: null,
                returnState: 'workflow'
            }
        });

        $stateProvider.state('workflow-stepAnalytics', { // Dash is used for contextual help, actual parent is set using the parent property
            parent: 'main',
            url: '/workflows/{id}/steps/{stepId}/analytics?search',
            reloadOnSearch: false,
            template: '<sf-workflow-step-analytics layout="column" flex></sf-workflow-step-analytics>',
            data: {
                requiredLogin: true,
                pageTitle: 'Workflow Analytics'
            },
            params: {
                viewing: 'total',
                workflow: null,
                workflowStep: null,
                currentCount: undefined
            }
        });

        $stateProvider.state('composeWorkflow', {
            url: '/contacts/workflowcompose',
            templateUrl: 'partials/workflowcomposedialog.html',
            controller: 'WorkflowComposeDialogController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Create Workflow'
            },
            params: {
                showAutomaticSwitch: undefined,
                dialogTitle: 'Create an email workflow',
                filter: null,
                disableEditFilter: null,
                filterText: null
            }
        });

        // Generic Account states
        $stateProvider.state('editAccount', {
            url: '/accounts/{id:int}/edit?search',
            templateUrl: 'partials/editaccountinfo.html',
            controller: 'EditAccountController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Edit Account'
            },
            params: {
                mode: 'edit' // Since we use the editAccount thing for multiple states and dialogs we need to tell it what state it is being called to
            }
        }).state('bulkEditAccount', {
            url: '/accounts/edit',
            template: '<sf-bulk-edit-accounts flex layout="column" options="$stateParams.options"></sf-bulk-edit-accounts>',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                dialogClass: 'bulk-dialog',
                pageTitle: 'Bulk Edit Accounts'
            },
            params: {
                options: undefined
            }
        }).state('createAccount', {
            url: '/accounts/create',
            templateUrl: 'partials/editaccountinfo.html',
            controller: 'EditAccountController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Create Account'
            },
            params: {
                mode: 'create' // Since we use the editAccount thing for multiple states and dialogs we need to tell it what state it is being called to
            }
        }).state('addEmailToAccount', {
            url: '/accounts/emails/:mode/:id?email&subject&date',
            templateUrl: 'partials/addemailtoaccount.html',
            controller: 'AddEmailToAccountController',
            data: {
                requiredLogin: false,
                pageTitle: 'Add Email'
            }
        }).state('editContacts', {
            url: '/accounts/{account:int}/contacts/edit',
            templateUrl: 'partials/editcontacts.html',
            controller: 'EditContactsController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Edit Contact'
            },
            params: {
                selectedContacts: null
            }
        }).state('editUsers', {
            url: '/accounts/{account:int}/users/edit',
            templateUrl: 'partials/editusers.html',
            controller: 'EditUsersController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Edit User'
            },
            params: {
                mode: 'edit'
            }
        }).state('bulkAddUsers', {
            url: '/accounts/users/add',
            templateUrl: 'partials/editusers.html',
            controller: 'EditUsersController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Bulk Add Users'
            },
            params: {
                mode: 'bulkAdd',
                options: undefined
            }
        }).state('bulkRemoveUsers', {
            url: '/accounts/users/remove',
            templateUrl: 'partials/editusers.html',
            controller: 'EditUsersController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Bulk Remove Users'
            },
            params: {
                mode: 'bulkRemove',
                options: undefined
            }
        }).state('suggestions', {
            url: '/accounts/{account:int}/suggestions',
            templateUrl: 'partials/suggestions.html',
            controller: 'SuggestionsController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Suggestions'
            }
        }).state('network', {
            url: '/accounts/{type:string}/{account:int}/network',
            templateUrl: 'partials/network.html',
            controller: 'NetworkController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Network'
            }
        });

        // Generic Contact States
        $stateProvider.state('editContact', {
            url: '/contacts/{id:int}/edit?search',
            templateUrl: 'partials/editperson.html',
            controller: 'EditPersonController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Edit Contact'
            },
            params: {
                type: 'contact'
            }
        }).state('createContact', {
            url: '/contacts/create',
            templateUrl: 'partials/editperson.html',
            controller: 'EditPersonController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Create Contact'
            },
            params: {
                type: 'contact',
                createContact: false,
                from: null,
                account: null,
                company: null,
                contactName: null,
                personObject: null,
                isLinkedInSuggestion: false,
                selectedContacts: null
            }
        }).state('bulkEditContact', {
            url: '/contacts/edit',
            template: '<sf-bulk-edit-contacts flex layout="column" options="$stateParams.options"></sf-bulk-edit-contacts>',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                dialogClass: 'bulk-dialog',
                pageTitle: 'Bulk Edit Contacts'
            },
            params: {
                options: undefined
            }
        }).state('composeEmail', {
            url: '/contacts/emailcompose',
            templateUrl: 'partials/emailcomposedialog.html',
            controller: 'EmailComposeDialogController',
            data: {
                autoFocus: false,
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Compose an email message'
            },
            params: {
                dialogTitle: 'Compose an email message',
                to: [],
                cc: [],
                attachments: [],
                body: null,
                subject: null,
                type: null,
                potentialSenderEmails: null,
                threadId: null,
                inReplyTo: null
            }
        }).state('contacts', {
            parent: 'main',
            abstract: true,
            url: '/contacts?search',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Contacts'
            },
            views: {
                '': {
                    templateUrl: 'partials/contacts.html',
                    controller: 'ContactsController'
                }
            }
        }).state('contacts.customers', {
            url: '/customers',
            templateUrl: 'partials/contactsList.html',
            controller: 'ContactsListController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Customers'
            },
            params: {
                ignoreCacheOnFetchContact: false
            }
        }).state('contacts.my', {
            url: '/my',
            templateUrl: 'partials/contactsList.html',
            controller: 'ContactsListController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'My Contacts'
            },
            params: {
                ignoreCacheOnFetchContact: false
            }
        }).state('contacts.customers.filters', {
            url: '/filters',
            template: '<sf-filters-contact class="bg-white" flex></sf-filters-contact>',
            data: {
                requiredLogin: true,
                pageTitle: 'Contact Filters'
            }
        }).state('contacts.my.filters', {
            url: '/filters',
            template: '<sf-filters-contact class="bg-white" flex></sf-filters-contact>',
            data: {
                requiredLogin: true,
                pageTitle: 'Contact Filter'
            }
        });

        // Opportunity states
        $stateProvider.state('opportunities', {
            parent: 'main',
            abstract: true,
            url: '/opportunities?search',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            },
            views: {
                '': {
                    templateUrl: 'partials/opportunities.html',
                    controller: 'OpportunitiesController'
                }
            }
        }).state('opportunities.stages', {
            url: '/stages',
            templateUrl: 'partials/opportunitiesstages.html',
            controller: 'OpportunitiesStagesController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Opportunities Stages'
            }
        }).state('opportunities.stages.opportunity', {
            url: '/{id:int}',
            templateUrl: 'partials/opportunitiesstages.html',
            controller: 'OpportunitiesStagesController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Opportunities Stages'
            }
        }).state('opportunities.timeline', {
            url: '/timeline',
            templateUrl: 'partials/opportunitiestimeline.html',
            controller: 'OpportunitiesTimelineController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Opportunities Timeline'
            }
        }).state('opportunities.timeline.opportunity', {
            url: '/{id:int}',
            templateUrl: 'partials/opportunitiestimeline.html',
            controller: 'OpportunitiesTimelineController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Opportunities Timeline'
            }
        }).state('opportunity', {
            url: '/opportunities/:id?account',
            templateUrl: 'partials/opportunity.html',
            controller: 'OpportunityController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Edit Opportunity'
            }
        }).state('bulkEditOpportunity', {
            url: '/edit',
            template: '<sf-bulk-edit-opportunities flex layout="column" options="$stateParams.options" pipeline-id="$stateParams.pipelineId"></sf-bulk-edit-opportunities>',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                dialogClass: 'bulk-dialog',
                pageTitle: 'Bulk Edit Opportunities'
            },
            params: {
                options: undefined,
                pipelineId: undefined
            }
        });

        // Insights states
        $stateProvider.state('dashboards', {
            parent: 'main',
            url: '/insights',
            data: {
                requiredLogin: true,
                pageTitle: 'Insights'
            },
            params: {
                selectedDashboardId: undefined
            },
            views: {
                '': {
                    template: '<sf-dashboards layout="column" flex></sf-dashboards>'
                }
            }
        }).state('dashboards.dashboard', {
            url: '/{id:int}',
            template: '<sf-dashboard ng-if="vm.doneLoading" class="bg-gray" dashboard="vm.selectedDashboard" dashboard-filter="vm.dashboardFilter" layout="column"></sf-dashboard>',
            data: {
                requiredLogin: true
            }
        }).state('printDashboard', {
            url: '/insights/{id:int}/print',
            template: '<sf-dashboard layout="column"></sf-dashboard>',
            data: {
                requiredLogin: true
            }
        }).state('dashboards.editDashboard', {
            url: '/{id:int}/edit',
            template: '<sf-dashboard-edit ng-if="vm.doneLoading" editing="true" dashboard="vm.selectedDashboard" dashboard-filter="vm.dashboardFilter" pending-dashboard-updates="vm.pendingDashboardUpdates" layout="column"></sf-dashboard-edit>',
            data: {
                requiredLogin: true
            }
        });

        $stateProvider.state('editReport', {
            parent: 'main',
            url: '/insights/reports/{id:int}/edit',
            template: '<sf-report-edit layout="column" flex></sf-report-edit>',
            data: {
                requiredLogin: true
            }
        });

        $stateProvider.state('createReport', {
            parent: 'main',
            url: '/insights/reports/new',
            template: '<sf-report-edit layout="column" flex></sf-report-edit>',
            data: {
                requiredLogin: true

            },
            params: {
                dashboardId: null,
                reportId: null
            }
        });

        // User states
        $stateProvider.state('user', {
            url: '/users/{id:int}/view',
            templateUrl: 'partials/personview.html',
            controller: 'PersonController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'User'
            },
            params: {
                type: 'user'
            }
        }).state('editUser', {
            url: '/users/{id:int}/edit?search',
            templateUrl: 'partials/editperson.html',
            controller: 'EditPersonController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Edit User'
            },
            params: {
                type: 'user'
            }
        });

        // Password states
        $stateProvider.state('requestPassword', {
            url: '/password/request',
            templateUrl: 'partials/requestpassword.html',
            controller: 'RequestPasswordController',
            data: {
                requiredLogin: false,
                pageTitle: 'Request Password'
            }
        }).state('resetPassword', {
            url: '/password/reset?t',
            component: SigninSecurityComponent,
            data: {
                requiredLogin: false,
                pageTitle: 'Reset Password'
            }
        });

        // SignUp/Login state
        $stateProvider.state('signup', {
            url: '/signup/{token}?discount_code&campaign&refer',
            templateUrl: 'partials/signup.html',
            controller: 'SignUpController',
            params: {
                token: ''
            },
            data: {
                requiredLogin: false,
                pageTitle: 'Sign Up'
            }
        }).state('signupderpicated', {
            url: '/user/new/{token}',
            templateUrl: 'partials/signup.html',
            controller: 'SignUpController',
            data: {
                requiredLogin: false,
                pageTitle: 'Sign Up'
            }
        }).state('login', {
            url: '/login?email',
            templateUrl: 'partials/login.html',
            controller: 'LogInController',
            data: {
                requiredLogin: false,
                pageTitle: 'Login'
            }
        }).state('researchsignup', {
            url: '/signup/research/{token}?email',
            controller: 'ResearchSignUpController',
            params: {
                token: ''
            },
            data: {
                requiredLogin: false,
                pageTitle: 'Research Sign Up'
            }
        });

        // Meeting state
        $stateProvider.state('meeting', {
            url: '/meetings/:id?type?account',
            templateUrl: 'partials/meeting.html',
            controller: 'MeetingController',
            params: {
                id: ''
            },
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Meeting'
            }
        });

        // Plans state
        $stateProvider.state('plans', {
            url: '/plans',
            templateUrl: 'partials/plans.html',
            data: {
                requiredLogin: true,
                allowWhenRestricted: true,
                pageTitle: 'Plans',
                restrictedForPlanIds: [5]
            },
            params: {
                fromBilling: false
            }
        });

        // Credits state
        $stateProvider.state('credits', {
            url: '/credits',
            templateUrl: 'partials/credits.html',
            data: {
                requiredLogin: true,
                allowWhenRestricted: true,
                pageTitle: 'Credits'
            }
        });

        // Checkout state
        $stateProvider.state('checkout', {
            url: '/checkout?plan',
            templateUrl: 'partials/checkout.html',
            controller: 'CheckoutController',
            data: {
                requiredLogin: true,
                allowWhenRestricted: true,
                pageTitle: 'Checkout',
                hideMainMenu: true
            },
            params: {
                closeTabOnFinish: false
            }
        });

        // Gmail Add-In Templates states
        $stateProvider.state('addInTemplateSelection', {
            url: '/addin/templates',
            template: '<sf-template-selection flex></sf-template-selection>',
            data: {
                requiredLogin: true,
                allowWhenRestricted: false,
                pageTitle: 'Templates'
            }
        });
        $stateProvider.state('addInTemplateManager', {
            url: '/addin/templates/manager?{createNew:bool}',
            template: '<sf-template-manager flex></sf-template-manager>',
            params: {
                createNew: false
            },
            data: {
                requiredLogin: true,
                allowWhenRestricted: false,
                pageTitle: 'Template Manager'
            }
        });

        /*Fullscreen specific states*/

        // Accounts states
        $stateProvider.state('accounts', {
            parent: 'main',
            url: '/accounts?search',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Accounts'
            },
            params: {
                accountsId: '',
                contacts: ''
            },
            views: {
                '': {
                    templateUrl: 'partials/accounts.html',
                    controller: 'AccountsController'
                }
            }
        }).state('accounts.account', {
            abstract: true,
            // `accounts` is for the carousel
            // `name` is to show a name in the no access empty state
            url: '/{id:int}?accounts&name',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Account Timeline'
            },
            views: {
                '': {
                    templateUrl: 'partials/account.html',
                    controller: 'AccountController'
                },
                'info@accounts.account': {
                    templateUrl: 'partials/accountinfo.html',
                    controller: 'AccountInfoController'
                }
            }
        }).state('accounts.account.info', {
            url: '/info/view',
            templateUrl: 'partials/accountinfo.html',
            controller: 'AccountInfoController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Account Info'
            }
        }).state('accounts.account.feed', {
            url: '/feed',
            templateUrl: 'partials/accountfeed.html',
            controller: 'AccountFeedController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Account Timeline'
            }
        }).state('accounts.account.files', {
            url: '/files',
            templateUrl: 'partials/files.html',
            controller: 'FilesController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Account Files'
            }
        }).state('accounts.account.social', {
            url: '/social',
            templateUrl: 'partials/accountsocialfeed.html',
            controller: 'AccountSocialFeedController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Account Social'
            }
        }).state('accounts.account.tasks', {
            url: '/tasks',
            template: '<sf-tasks-account account="account" class="bg-gray" flex></sf-tasks-account>',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Account Tasks'
            }
        }).state('accounts.company', {
            abstract: true,
            url: '/company/{id:int}?accounts',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Company Timeline'
            },
            params: {
                privateCompanyInfo: null
            },
            views: {
                '': {
                    templateUrl: 'partials/company.html',
                    controller: 'CompanyController'
                },
                'info@accounts.company': {
                    templateUrl: 'partials/companyinfo.html',
                    controller: 'CompanyInfoController'
                }
            }
        }).state('accounts.company.info', {
            url: '/info/view',
            templateUrl: 'partials/companyinfo.html',
            controller: 'CompanyInfoController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Company Info'
            }
        }).state('accounts.company.feed', {
            url: '/feed',
            templateUrl: 'partials/companyfeed.html',
            controller: 'CompanyFeedController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Company Timeline'
            }
        }).state('accounts.company.files', {
            url: '/files',
            templateUrl: 'partials/companyfiles.html',
            controller: 'CompanyFilesController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Company Files'
            }
        }).state('accounts.company.social', {
            url: '/social',
            templateUrl: 'partials/companysocialfeed.html',
            controller: 'CompanySocialFeedController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Company Social'
            }
        }).state('accounts.suggestion', {
            abstract: true,
            url: '/suggestion/{id:int}?accounts',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            },
            params: {
                privateCompanyInfo: null
            },
            views: {
                '': {
                    templateUrl: 'partials/company.html',
                    controller: 'CompanyController'
                },
                'info@accounts.suggestion': {
                    templateUrl: 'partials/companyinfo.html',
                    controller: 'CompanyInfoController'
                }
            }
        }).state('accounts.suggestion.info', {
            url: '/info/view',
            templateUrl: 'partials/companyinfo.html',
            controller: 'CompanyInfoController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            }
        }).state('accounts.suggestion.feed', {
            url: '/feed',
            templateUrl: 'partials/companyfeed.html',
            controller: 'CompanyFeedController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            }
        }).state('accounts.suggestion.files', {
            url: '/files',
            templateUrl: 'partials/companyfiles.html',
            controller: 'CompanyFilesController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            }
        }).state('accounts.suggestion.social', {
            url: '/social',
            templateUrl: 'partials/companysocialfeed.html',
            controller: 'CompanySocialFeedController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            }
        });

        $stateProvider.state('accountsuggestions', {
            parent: 'main',
            url: '/accounts/suggestions',
            reloadOnSearch: false,
            data: {
                requiredLogin: true,
                pageTitle: 'Account Suggestions'
            },
            params: {
                accountsId: '',
                contacts: ''
            },
            views: {
                '': {
                    templateUrl: 'partials/suggestedaccounts.html',
                    controller: 'SuggestedAccountsController'
                }
            }
        }).state('accountsuggestions.account', {
            abstract: true,
            // `accounts` is for the carousel
            // `name` is to show a name in the no access empty state
            url: '/{id:int}?accounts&name',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            },
            views: {
                '': {
                    templateUrl: 'partials/account.html',
                    controller: 'AccountController'
                },
                'info@accountsuggestions.account': {
                    templateUrl: 'partials/accountinfo.html',
                    controller: 'AccountInfoController'
                }
            }
        }).state('accountsuggestions.account.info', {
            url: '/info/view',
            templateUrl: 'partials/accountinfo.html',
            controller: 'AccountInfoController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            }
        }).state('accountsuggestions.account.feed', {
            url: '/feed',
            templateUrl: 'partials/accountfeed.html',
            controller: 'AccountFeedController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            }
        }).state('accountsuggestions.account.files', {
            url: '/files',
            templateUrl: 'partials/files.html',
            controller: 'FilesController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            }
        }).state('accountsuggestions.account.social', {
            url: '/social',
            templateUrl: 'partials/accountsocialfeed.html',
            controller: 'AccountSocialFeedController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            }
        }).state('accountsuggestions.account.tasks', {
            url: '/tasks',
            template: '<sf-tasks-account account="account" class="bg-gray" flex></sf-tasks-account>',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            }
        }).state('accountsuggestions.company', {
            abstract: true,
            url: '/company/{id:int}?accounts',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            },
            params: {
                privateCompanyInfo: null
            },
            views: {
                '': {
                    templateUrl: 'partials/company.html',
                    controller: 'CompanyController'
                },
                'info@accountsuggestions.company': {
                    templateUrl: 'partials/companyinfo.html',
                    controller: 'CompanyInfoController'
                }
            }
        }).state('accountsuggestions.company.info', {
            url: '/info/view',
            templateUrl: 'partials/companyinfo.html',
            controller: 'CompanyInfoController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            }
        }).state('accountsuggestions.company.feed', {
            url: '/feed',
            templateUrl: 'partials/companyfeed.html',
            controller: 'CompanyFeedController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            }
        }).state('accountsuggestions.company.files', {
            url: '/files',
            templateUrl: 'partials/companyfiles.html',
            controller: 'CompanyFilesController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            }
        }).state('accountsuggestions.company.social', {
            url: '/social',
            templateUrl: 'partials/companysocialfeed.html',
            controller: 'CompanySocialFeedController',
            reloadOnSearch: false,
            data: {
                requiredLogin: true
            }
        });

        //Contacts states
        $stateProvider.state('contacts.customers.contact', {
            url: '/{id:int}',
            data: {
                requiredLogin: true,
                pageTitle: 'Contact'
            },
            params: {
                type: 'contact'
            },
            reloadOnSearch: false
        }).state('contacts.my.contact', {
            url: '/{id:int}',
            data: {
                requiredLogin: true,
                pageTitle: 'Contact'
            },
            params: {
                type: 'contact'
            },
            reloadOnSearch: false
        }).state('contact', {
            url: '/contacts/{id:int}/view',
            templateUrl: 'partials/personview.html',
            controller: 'PersonController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true
            },
            params: {
                type: 'contact'
            }
        });

        // LinkedIn states
        $stateProvider.state('linkedin', {
            parent: 'main',
            url: '/linkedin',
            template: `<sf-linked-in flex
                layout="column"
                empty-state="linkedInEmptyState"
                linked-in-loading="linkedInLoading">
            </sf-linked-in>`,
            data: {
                requiredLogin: true
            }
        });

        // Online video conferencing states
        $stateProvider.state('meet', {
            /*Parent: 'main',*/
            url: '/meet/{id}',
            templateUrl: 'partials/conference.html',
            controller: 'ConferenceController',
            data: {
                requiredLogin: false
            }
        });

        // Settings states
        $stateProvider.state('settings', {
            parent: 'main',
            url: '/settings',
            templateUrl: 'partials/settings.html',
            controller: 'SettingsController',
            data: {
                requiredLogin: true,
                allowWhenRestricted: true,
                pageTitle: 'Settings'
            }
        }).state('settings.teamSettings', {
            url: '/team',
            component: ManageTeamComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Team Settings'
            }
        }).state('settings.permissionSettings', {
            url: '/permissions',
            component: PermissionsComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Set permissions'
            }
        }).state('settings.targets', {
            url: '/targets',
            component: TargetsComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Target Settings'
            }
        }).state('settings.regionalSettings', {
            url: '/regional',
            component: RegionalSettingsComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Regional Settings'
            }
        }).state('settings.pipelineSettings', {
            url: '/stage',
            component: ConfigurePipelinesComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Configure Stages'
            }
        }).state('settings.taskSettings', {
            url: '/tasks',
            data: {
                requiredLogin: true,
                pageTitle: 'Task Settings'
            },
            component: TaskSettingsComponent
        }).state('settings.tagSettings', {
            url: '/tags',
            component: ManageTagsComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Tag Settings'
            }
        }).state('settings.customFieldsSettings', {
            url: '/customfields',
            component: CustomizeFieldsComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Configure Custom fields'
            }
        }).state('settings.customFieldsSettings.opportunity', {
            url: '/opportunity',
            component: CustomFieldsSettingsListComponent,
            resolve: {
                itemClass: () => 'opportunity'
            },
            data: {
                requiredLogin: true,
                pageTitle: 'Opportunity custom fields'
            }
        }).state('settings.customFieldsSettings.account', {
            url: '/account',
            component: CustomFieldsSettingsListComponent,
            resolve: {
                itemClass: () => 'account'
            },
            data: {
                requiredLogin: true,
                pageTitle: 'Account custom fields'
            }
        }).state('settings.customFieldsSettings.contact', {
            url: '/contact',
            component: CustomFieldsSettingsListComponent,
            resolve: {
                itemClass: () => 'contact'
            },
            data: {
                requiredLogin: true,
                pageTitle: 'Contact custom fields'
            }
        }).state('settings.editCustomField', {
            url: '/customfields/{itemClass:string}/{id:int}/edit',
            templateUrl: 'partials/editcustomfield.html',
            controller: 'EditCustomFieldController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Edit Field'
            }
        }).state('settings.createCustomField', {
            url: '/customfields/{itemClass:string}/create',
            templateUrl: 'partials/editcustomfield.html',
            controller: 'EditCustomFieldController',
            data: {
                requiredLogin: true,
                shouldBeDialogInFullscreen: true,
                pageTitle: 'Edit Field'
            }
        }).state('settings.notificationSettings', {
            url: '/notification',
            data: {
                requiredLogin: true,
                pageTitle: 'Notification Settings'
            },
            component: NotificationSettingsComponent
        }).state('settings.emailSettings', {
            url: '/email',
            component: EmailSettingsComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Email Settings'
            }
        }).state('settings.calendarSettings', {
            url: '/calendar',
            component: CalendarSettingsComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Calendar Settings'
            }
        }).state('settings.applicationsSettings', {
            url: '/applications',
            component: ApplicationsIntegrationsSettingsComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Applications & Integrations'
            }
        }).state('settings.billingSettings', {
            url: '/billing',
            component: BillingUsageComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Billing'
            }
        }).state('settings.billingSettings.overview', {
            url: '/overview',
            component: BillingUsageOverviewComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Billing overview'
            },
            params: {
                subscribedFromCheckout: false
            }
        }).state('settings.billingSettings.invoice', {
            url: '/invoice',
            component: BillingUsageInvoiceComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Billing Invoice'
            }
        }).state('settings.billingSettings.history', {
            url: '/history',
            component: BillingUsageHistoryComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Billing history'
            }
        }).state('settings.apiKeySettings', {
            url: '/apikeys',
            data: {
                requiredLogin: true,
                pageTitle: 'Api Key Settings'
            },
            views: {
                '@settings': {
                    templateUrl: 'partials/apikeysettings.html',
                    controller: 'ApiKeySettingsController'
                }
            }
        }).state('settings.resetPassword', {
            url: '/security',
            component: SigninSecurityComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Sign in & Security'
            }
        }).state('settings.support', {
            url: '/support',
            data: {
                requiredLogin: true,
                pageTitle: 'Support'
            },
            views: {
                '@settings': {
                    templateUrl: 'partials/support.html',
                    controller: 'SupportController'
                }
            }
        }).state('settings.terms', {
            url: '/termsandpolicies',
            data: {
                requiredLogin: true,
                pageTitle: 'Terms & Policies'
            },
            views: {
                '@settings': {
                    templateUrl: 'partials/termsAndPolicies.html',
                    controller: 'TermsAndPoliciesController'
                }
            }
        }).state('settings.referralProgram', {
            url: '/referralprogram',
            component: ReferAFriendComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Refer a friend'
            }
        }).state('settings.import', {
            url: '/imports',
            component: ImportSettingsComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Imports'
            }
        }).state('settings.import.account', {
            url: '/account',
            component: ImportListComponent,
            resolve: {
                importType: () => 'account'
            },
            data: {
                requiredLogin: true,
                pageTitle: 'Account Imports',
                entity: 'account'
            }
        }).state('settings.import.contact', {
            url: '/contact',
            component: ImportListComponent,
            resolve: {
                importType: () => 'contact'
            },
            data: {
                requiredLogin: true,
                pageTitle: 'Contact Imports',
                entity: 'contact'
            }
        }).state('settings.import.opportunity', {
            url: '/opportunity',
            component: ImportListComponent,
            resolve: {
                importType: () => 'opportunity'
            },
            data: {
                requiredLogin: true,
                pageTitle: 'Opportunity Imports',
                entity: 'opportunity'
            }
        }).state('settings.importMap', {
            url: '/imports/{id:int}',
            component: ImportMapComponent,
            data: {
                requiredLogin: true,
                pageTitle: 'Import Mapping',
                hideMainMenu: true
            }
        });

        $stateProvider.state('notifications', {
            parent: 'main',
            url: '/notifications',
            template: '<sf-notifications flex layout="column"></sf-notifications>',
            data: {
                requiredLogin: true,
                pageTitle: 'Notifications'
            }
        });

        $stateProvider.state('setup', {
            parent: 'main',
            url: '/setup',
            template: '<sf-setup flex layout="column"></sf-setup>',
            data: {
                requiredLogin: true,
                pageTitle: 'Set up Salesflare'
            }
        });
    }

    function routeRun($rootScope, $state, $stateParams, $document, $mdMedia, $mdDialog, $timeout, $location, $transitions, events, config, model, authentication, sfRestrictedDialog, sfWalkthrough, $window) {

        $rootScope.$state = $state;
        // Needed for consistent binding to components (fullscreen and in dialog)
        // E.g. see `bulkEditAccounts`
        $rootScope.$stateParams = $stateParams;
        $rootScope.model = model;
        $rootScope.$mdMedia = $mdMedia;
        $rootScope.isProduction = config.env === 'production';
        if (!$rootScope.history) {
            $rootScope.history = [];
        }

        $transitions.onStart({}, (transition) => {

            const toState = transition.to();
            const toParams = transition.params('to');
            const fromState = transition.from();
            const fromParams = transition.params('from');
            const options = transition.options();

            // When Salesflare is being shown inside an iframe we don't want navigation inside the iframe to affect the browser history
            // Pressing 'back' in the browser should go the the previous page and not go back inside the iframe
            // We can achieve this behavior by replacing the location each time we navigate
            if ($window.self !== $window.top) {
                options.location = 'replace';
            }

            // When clicking back after completing the walkthrough we don't want to use the dummy account id anymore since it will give a permission error
            if (toState.name === 'accounts.account.feed' && toParams.id === 1 && fromState.name === 'opportunities.stages' && !sfWalkthrough.isShowing()) {
                return transition.router.stateService.target('accounts');
            }

            // Only close dialogs if we're actually changing state and not coming from the root state.
            // This, for example, prevents the call log sync dialog from showing and immediately closing.
            if (fromState.name && fromState.name !== toState.name) {
                if (!sfRestrictedDialog.isShowing()) {
                    $mdDialog.cancel();
                }
            }

            $rootScope.fireworks = false;
            $rootScope.showBackdrop = false;
            // Try to find a way to stop walkthrough only when you go to a route not related to the walkthrough
            //sfWalkthrough.stop();

            if (!(toState.data.shouldBeDialogInFullscreen && $mdMedia('gt-sm'))) {
                if (toState.data.pageTitle) {
                    $document[0].title = toState.data.pageTitle + ' | Salesflare';
                }
                else {
                    $document[0].title = toState.name;
                }
            }

            if (!$rootScope.fromBack) {
                $rootScope.history.push({
                    fromState: fromState.name === '' ? $state.get('tasks.today') : fromState,
                    fromParams,
                    toState,
                    toParams
                });
            }

            if (fromState.name === 'linkedin') {
                $rootScope.history.push({
                    fromState: $state.get('accounts'),
                    fromParams,
                    toState,
                    toParams
                });
            }

            $rootScope.fromBack = false;
            $rootScope.previousState = fromState;

            // Make sure user is logged in and that model.me is available
            if (toState.data.requiredLogin) {
                // Hide the dialog when going to `allowWhenRestricted` state
                if (toState.data.allowWhenRestricted) {
                    sfRestrictedDialog.hide();
                }
                else {
                    sfRestrictedDialog.check(toState);
                }

                if (!model.me) {
                    return authentication.fetchMe().then(function () {

                        return transition.router.stateService.target(toState.name, toParams);
                    }).catch(() => {

                        // If the user is not logged in, redirect the transition to login
                        return transition.router.stateService.target('login');
                    });
                }

                // Go to tasks when manually navigating to a page that isn't allowed on the user's current plan id
                if (toState.data.restrictedForPlanIds && toState.data.restrictedForPlanIds.includes(model.me.team.plan)) {
                    return transition.router.stateService.target('tasks.today');
                }
            }
            else if (!model.me) {
                // Make sure model.isLoggedIn is always properly set even on routes that do not require authentication
                return authentication.fetchMe({ preventLogoutOnUnsuccessfulAuth: true }).then(angular.noop).catch(angular.noop);
            }

            // When pressing the browser's back button while in the walkthrough, the walkthrough needs to be restarted in a certain state
            if (sfWalkthrough.isShowing()) {

                sfWalkthrough.hideTooltip();

                // Leaving the walkthrough flow
                if (fromState.name === 'walkthrough' && toState.name !== 'accounts.account.feed') {
                    sfWalkthrough.stop();
                }

                if (fromState.name === 'accounts.account.feed' && toState.name === 'walkthrough') {
                    sfWalkthrough.stop();

                    return transition.router.stateService.target('walkthrough', { state: 'accounts' });
                }

                if (fromState.name === 'opportunities.stages' && toState.name === 'accounts.account.feed') {
                    sfWalkthrough.stop();

                    return transition.router.stateService.target('walkthrough', { state: 'opportunities' });
                }
            }

            if (toState.data.shouldBeDialogInFullscreen && $mdMedia('gt-sm')) {
                transition.abort();

                const newScope = $rootScope.$new(); // Make sure to inherit from appController

                // Pass stateParams via scope for components
                // No other direct way of passing them afaik
                // `$scope.$stateParams`
                if (toState.template) {
                    newScope.$stateParams = toParams;
                }

                // FocusOnOpen is true by default, when specified in the data, override it
                return $mdDialog.show({
                    focusOnOpen: angular.isDefined(toState.data.autoFocus) ? toState.data.autoFocus : true,
                    multiple: true,
                    clickOutsideToClose: false,
                    escapeToClose: false,
                    template: toState.template,
                    templateUrl: toState.templateUrl,
                    controller: toState.controller,
                    scope: newScope,
                    onComplete,
                    onShowing: function (scope, element) {

                        if (toState.data.dialogClass) {
                            element.children('md-dialog').addClass(toState.data.dialogClass);
                        }
                    },
                    locals: {
                        $stateParams: toParams
                    }
                }).then(function (reload) {

                    // Should not reload view, only data
                    if (reload) {
                        $rootScope.$broadcast(events.refreshData);
                    }
                });
            }
        });

        function onComplete(scope, element) {

            // Do not close the dialog with escape/clicking outside when showing the walkthrough
            if (!sfWalkthrough.isShowing()) {
                exitDialogOnEscape(scope, element);
                exitDialogOnClickOutside(element, scope);
            }
        }

        /**
         * Dialog functions taken from Angular Material code
         *
         * @param {{}} scope scope object
         * @param {{}} element element object
         * @returns {undefined}
         */
        function exitDialogOnEscape(scope, element) {

            function keyHandlerFn(ev) {
                if (ev.keyCode === 27) {
                    ev.stopPropagation();
                    ev.preventDefault();

                    /**
                     * If scope does not have it's own back function we will call the rootScope's version
                     * This will mean the dialog won't hide so we do it here.
                     * The dialog hasn't been saved so we close the dialog without reloading
                     * The only way to solve this is with ui router 1.X where we can properly hook into the lifecycle (instead of acting on an event like we do here)
                     */
                    if (scope.back === $rootScope.back) {
                        return $mdDialog.hide();
                    }

                    return scope.back();
                }
            }

            element.on('keydown', keyHandlerFn);
        }

        function exitDialogOnClickOutside(element, scope) {

            let sourceElem;

            function mousedownHandler(ev) {
                sourceElem = ev.target;
            }

            function mouseupHandler(ev) {
                if (sourceElem === element[0] && ev.target === element[0]) {
                    ev.stopPropagation();
                    ev.preventDefault();

                    /**
                     * If scope does not have it's own back function we will call the rootScope's version
                     * This will mean the dialog won't hide so we do it here.
                     * The dialog hasn't been saved so we close the dialog without reloading
                     * The only way to solve this is with ui router 1.X where we can properly hook into the lifecycle (instead of acting on an event like we do here)
                     */
                    if (scope.back === $rootScope.back) {
                        return $mdDialog.hide();
                    }

                    return scope.back();
                }
            }

            element.on('mousedown', mousedownHandler);
            element.on('mouseup', mouseupHandler);
        }

        $transitions.onSuccess({}, (transition) => {

            // If Hotjar is enabled then manually track the state change, see https://help.hotjar.com/hc/en-us/articles/360034378534
            // This hopefully results in more correct recordings since HJ has known issues with tracking state changes in SPAs
            // eslint-disable-next-line angular/window-service
            if (window.hj) {
                hj('stateChange', $location.url());
            }

            // Temp workaround for iOS/Cordova issue that leaves the viewport shifted when hiding keyboard. Can be removed if actual issue is fixed. (also see app.js)
            // See https://github.com/apache/cordova-ios/issues/417
            // TODO: remove this when actual fix is implemented
            if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
                $timeout(function () {

                    $window.scrollTo(0, 0);
                });
            }

            const toState = transition.to();

            if (toState.controller === 'LogInController' || toState.controller === 'SignUpController') {
                Intercom('update', {
                    timestamp: new Date()
                });
            }
            else {
                Intercom('update', {
                    horizontal_padding: config.intercom.horizontal_padding,
                    alignment: config.intercom.alignment,
                    timestamp: new Date()
                });
            }

            if (config.google && config.google.appTrackingId) {
                ga('send', 'pageview', toState.name);
            }
        });

        $rootScope.back = function () {

            $rootScope.fromBack = true;
            const state = $rootScope.history.pop();

            return $state.go(state.fromState, state.fromParams);
        };
    }
})();
