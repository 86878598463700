/*eslint strict:[1, "global"]*/
'use strict';

export class Carousel {

    data: any[] = [];
    index: number = 0;

    constructor(data: any[], index: number) {
        this.data = data;
        this.index = index;
    }

    move(n?: number) {

        const l = this.data.length;

        return this.data[Math.abs(this.index + (n ? 1 : l - 1)) % l];
    }

    get next() {

        return this.move(1);
    }

    get previous() {

        return this.move(0);
    }

    get current() {

        return this.data[this.index];
    }
};