(function () {
    'use strict';

    angular
        .module('salesflare.components.workflow', [
            'salesflare.components.workflow.workflowCard',
            'salesflare.components.workflow.workflowStep'
        ])
        .component('sfWorkflowDetails', {
            templateUrl: 'app-ajs/components/workflows/workflow/workflow.html',
            controller,
            controllerAs: 'vm'
        });

    function controller($document, $q, $rootScope, $state, $stateParams, $mdMedia, $mdDialog, $location, $anchorScroll, $transitions, $timeout, authentication, model, sfDiscardDialog, sfUpgradeDialog, workflowService, workflowsService, humanReadableAdvancedFilterService, utils) {

        const vm = this;
        vm.$mdMedia = $mdMedia;
        // If a scheduled date is picked, this will show true, don't confuse with the actual 'scheduled' status a workflow can have
        vm.isScheduledWorkflow = false;
        vm.goalTextMap = {
            replied: 'contact replies to any email'
        };
        vm.creatingNewWorkflow = $state.params.id === 'new';
        vm.editing = vm.creatingNewWorkflow || $stateParams.editing;
        vm.customMessage = '';
        vm.savingDraft = true;
        vm.workflowConfigDetails = {};
        vm.insufficientPermissionsMessage = 'You don\'t have permission to edit this workflow. Ask an admin.';

        vm.showPlanFlags = function (workflowStep) {

            const onTrial = model.me.team.payment_type !== 'free' && !model.me.team.subscribed && model.me.trial_expiry_date;
            const workflowWasLiveBeforeReleaseDate = vm.workflow.status === 'live' || (vm.workflow.status === 'paused' && vm.workflow.scheduled_before_pro_release);
            return ((onTrial || !model.me.plan_flags.multiStepWorkflows) && workflowStep.order > 1 && !vm.workflow.multi_step_before_pro_release && !workflowWasLiveBeforeReleaseDate);
        };

        const stepForms = [];

        const initialFocusedStepOrder = $stateParams.focusedStepOrder || 0;
        const workflowTypeConfig = {
            contact: {
                title: 'email workflow',
                name: 'email workflow',
                icon: 'sf-icon-people'
            }
        };

        vm.workflowActionTypeIconMap = {
            'send_email': 'sf-icon-email'
        };

        const closestHalfHourDate = utils.getNextHalfHourDateTime();
        const halfHoursOfTheDay = utils.halfHoursOfTheDay.map(function (t) {

            return new Date(new Date().setHours(t[0], t[1], 0, 0));
        });
        const halfHoursOfTheDayAfterNow = halfHoursOfTheDay.filter(function (t) {

            return t >= closestHalfHourDate;
        });
        // Start schedule variables
        vm.timeChanged = false;
        vm.today = new Date();
        vm.scheduleDate = new Date();
        vm.minScheduleDate = new Date();
        vm.maxScheduleDate = new Date(new Date().getFullYear() + 2, 12, 0);
        vm.halfHoursOfTheDayForSetLive = utils.isToday(new Date()) ? halfHoursOfTheDayAfterNow : halfHoursOfTheDay;
        vm.time = closestHalfHourDate.getTime();
        vm.halfHoursOfTheDay = halfHoursOfTheDay;
        // End schedule variables

        vm.daysOfTheWeek = utils.getDaysOfTheWeekStartingOn(model.me.first_day_of_week);
        vm.daysToSendOn = [1, 2, 3, 4, 5]; // Weekdays
        vm.startHour = new Date(new Date().setHours(9, 0, 0, 0)).getTime();
        vm.endHour = new Date(new Date().setHours(18, 0, 0, 0)).getTime();

        function initWorkflowData(workflowData) {

            vm.workflow = workflowData;
            vm.workflow.exit_after_days = vm.workflow.exit_after_days || 0;

            vm.savingDraft = vm.workflow.status === 'draft';
            const preparedWorkflowAnalytics = workflowService.prepareAnalyticData(vm.workflow.analytics);
            vm.workflow.analytics = preparedWorkflowAnalytics;

            // When duplicating a flow we consider the workflow as not scheduled when no schedule_date is passed
            // For existing flows, a flow is scheduled when the schedule date differs from the creation_date
            vm.isScheduledWorkflow = $stateParams.workflow && vm.creatingNewWorkflow ? !!vm.workflow.schedule_date : (vm.workflow.schedule_date !== vm.workflow.creation_date);
            vm.scheduleDate = vm.workflow.schedule_date ? new Date(vm.workflow.schedule_date) : new Date();
            vm.workflow.schedule_date = vm.scheduleDate;
            vm.minScheduleDate = new Date(Math.min(vm.today, vm.scheduleDate));
            vm.daysToSendOn = vm.workflow.schedule_days;
            vm.startHour = new Date().setHours(vm.workflow.schedule_time_start.split(':')[0], vm.workflow.schedule_time_start.split(':')[1], 0, 0);
            vm.endHour = new Date().setHours(vm.workflow.schedule_time_end.split(':')[0], vm.workflow.schedule_time_end.split(':')[1], 0, 0);

            vm.halfHoursOfTheDayForSetLive = utils.isToday(vm.scheduleDate) && !vm.isScheduledWorkflow ? halfHoursOfTheDayAfterNow : halfHoursOfTheDay;

            if (vm.scheduleDate) {
                const matchingHalfHour = vm.halfHoursOfTheDay.find(function (halfHourDate) {

                    return utils.isSameHour(vm.scheduleDate, halfHourDate) && utils.isSameMinute(vm.scheduleDate, halfHourDate);
                });
                vm.time = matchingHalfHour ? matchingHalfHour.getTime() : closestHalfHourDate.getTime();
                vm.timeChanged = true;
            }

            // Generate text from workflow filter
            humanReadableAdvancedFilterService.getHumanReadableAdvancedFilter(vm.workflow.filter, vm.workflow.record_type).then(function (filterText) {

                vm.workflowAudienceText = filterText;
            });

            vm.workflowIndividualContactsText = getIndividualRecordsText();

            // Generate table of contents
            vm.workflowConfigDetails.name = 'Configure ' + workflowTypeConfig[vm.workflow.record_type].name;
            vm.workflowConfigDetails.title = 'Configure ' + workflowTypeConfig[vm.workflow.record_type].title;
            vm.workflowConfigDetails.icon = workflowTypeConfig[vm.workflow.record_type].icon;

            // Prep analytics for each step
            vm.workflow.steps.map(function (step) {

                step.analytics = workflowService.prepareAnalyticData(step.analytics);
                return step;
            });

            $timeout(setWorkflowValidity);

            if (vm.workflowConfigForm) {
                vm.workflowConfigForm.$setPristine();
            }

            // When a step order was passed as a state parameter, scroll to this step on initializing
            if (initialFocusedStepOrder !== 0) {
                vm.scrollToStep(initialFocusedStepOrder, 400);
            }
        }

        vm.$onInit = function () {

            return $q.all([
                workflowService.getActionTriggers().then(function (response) {

                    vm.actionTriggers = response.data;
                }),
                workflowsService.getGoals().then(function (response) {

                    vm.goals = response.data;
                    vm.goals.unshift({
                        description: 'No specific goal',
                        type: null
                    });
                })
            ]).then(function () {

                if ($stateParams.workflow && vm.creatingNewWorkflow) {
                    return initWorkflowData($stateParams.workflow);
                }

                if (!vm.creatingNewWorkflow) {
                    return workflowService.get($state.params.id).then(function (response) {

                        return initWorkflowData(response.data);
                    });
                }

                vm.workflow = {
                    record_type: 'contact',
                    continuous: true,
                    status: 'draft',
                    exit_after_days: 30,
                    goal: 'replied',
                    steps: [],
                    schedule_date: new Date(vm.scheduleDate)
                };

                // Generate text from workflow filter
                humanReadableAdvancedFilterService.getHumanReadableAdvancedFilter().then(function (filterText) {

                    vm.workflowAudienceText = filterText;
                });
                vm.workflowIndividualContactsText = getIndividualRecordsText();

                vm.workflowConfigDetails.name = 'Configure ' + workflowTypeConfig[vm.workflow.record_type].name;
                vm.workflowConfigDetails.title = 'Configure ' + workflowTypeConfig[vm.workflow.record_type].title;
                vm.workflowConfigDetails.icon = workflowTypeConfig[vm.workflow.record_type].icon;
            });
        };

        const removeStartTransitionHook = $transitions.onStart({}, (transition) => {

            if (!vm.workflowConfigForm || !vm.workflowConfigForm.$dirty) {
                return;
            }

            if (!vm.editing) {
                return;
            }

            // Redirecting after first save
            if (transition.to().name === 'workflow') {
                return;
            }

            return sfDiscardDialog.show(false, 'workflow').then(function () {

                vm.editing = false;
                return $state.go(transition.to(), transition.params('to'));
            }).catch(() => {
                transition.abort();
            });
        });

        vm.back = function () {

            if (!vm.workflowConfigForm || !vm.workflowConfigForm.$dirty) {
                vm.editing = false;
                return $state.go('workflows');
            }

            return sfDiscardDialog.show(false, 'workflow').then(function () {

                vm.editing = false;
                vm.workflowConfigForm.$setPristine();
                return $state.go('workflows');
            });
        };

        vm.preventEnterSubmit = function ($event) {

            if ($event.keyCode === 13) {
                $event.preventDefault();
                $event.stopPropagation();
            }
        };

        vm.chooseAudience = function () {

            const internalRule = vm.workflow.filter ? vm.workflow.filter.rules.find(function (rule) {

                return rule.internal;
            }) : undefined;
            const containsInternalRule = angular.isUndefined(internalRule) ? false : true;

            return $mdDialog.show({
                fullscreen: true,
                clickOutsideToClose: true,
                bindToController: true,
                controller: 'ChooseAudienceController',
                templateUrl: 'partials/chooseaudience.html',
                multiple: true,
                onShowing: function (scope, element) {

                    element.children('md-dialog').addClass('choose-audience-dialog');
                },
                locals: {
                    filter: vm.workflow.filter,
                    isFilterApplied,
                    hasInternalRules: containsInternalRule
                }
            }).then(function (audience) {

                vm.workflowConfigForm.hiddenFilter.$setDirty();

                vm.workflow.filter = audience.filter;

                humanReadableAdvancedFilterService.getHumanReadableAdvancedFilter(vm.workflow.filter, vm.workflow.record_type).then(function (filterText) {

                    vm.workflowAudienceText = filterText;
                });

                // Check if filter is valid
                return setWorkflowValidity();
            });
        };

        vm.selectIndividualContacts = function () {

            return $mdDialog.show({
                fullscreen: true,
                clickOutsideToClose: true,
                bindToController: true,
                controller: 'SelectIndividualContactsController',
                controllerAs: 'vm',
                templateUrl: 'partials/selectindividualcontacts.html',
                multiple: true,
                onShowing: function (scope, element) {

                    element.children('md-dialog').addClass('choose-audience-dialog');
                },
                locals: {
                    individualRecordFilter: vm.workflow.individual_record_filter,
                    workflowId: $state.params.id
                }
            }).then(function (audience) {

                if (audience) {

                    vm.workflowConfigForm.hiddenFilter.$setDirty();

                    vm.workflow.individual_record_filter = audience.individualRecordFilter;

                    vm.workflowIndividualContactsText = getIndividualRecordsText();

                    // Check if filter is valid
                    return setWorkflowValidity();
                }
            });
        };

        vm.changeScheduleStatus = function () {

            if (vm.isScheduledWorkflow) {
                // Only set the status to 'scheduled' when actually scheduling the workflow
                // When we're saving a draft, we want to keep the status on 'draft' as well
                vm.workflow.status = 'draft';
            }
            else {
                vm.time = utils.getNextHalfHourDateTime().getTime();
                vm.workflow.schedule_date = new Date();
                vm.scheduleDate = new Date(vm.workflow.schedule_date);
                vm.minScheduleDate = new Date(Math.min(vm.today, vm.scheduleDate));
                vm.workflow.status = 'draft';
            }
        };

        vm.focusElement = function (id) {

            return angular.element('#' + id).focus();
        };

        vm.onScheduleTimeChanged = function () {

            if (vm.startHour > vm.endHour) {
                vm.endHour = vm.startHour + 1800000; //Move to next half hour slot
            }
        };

        vm.onDateChanged = function () {

            vm.workflow.schedule_date = new Date(vm.scheduleDate);
            if (utils.isToday(vm.workflow.schedule_date)) {
                vm.halfHoursOfTheDayForSetLive = halfHoursOfTheDayAfterNow;

                // When a time was selected that is not allowed today reset to nextHalfHour
                if (vm.timeChanged && vm.time < utils.getNextHalfHourDateTime().getTime()) {
                    vm.time = utils.getNextHalfHourDateTime().getTime();
                    vm.timeChanged = false;
                }
            }
            else {
                vm.halfHoursOfTheDayForSetLive = halfHoursOfTheDay;
            }

            if (!vm.timeChanged) {
                if (utils.isToday(vm.workflow.schedule_date)) {
                    vm.time = utils.getNextHalfHourDateTime().getTime();
                }
                else {
                    vm.time = new Date(new Date().setHours(9, 0, 0, 0)).getTime();
                }
            }
        };

        /**
         * Adds step forms to an array to keep track of them and create a submit event when needed
         * Currently unused, since the form would stay valid even if not
         *
         * @param {Object} $event
         */
        vm.onStepFormCreation = function ($event) {

            if (!$event || !$event.form) {
                return;
            }

            stepForms.push($event.form);
        };

        vm.onValidityChange = () => {

            setWorkflowValidity();
        };

        vm.addStep = function () {

            const newWorkflowStep = {
                name: 'Send an email',
                action_type: 'send_email',
                trigger_after_days: null,
                order: vm.workflow.steps && vm.workflow.steps.length > 0 ? (Math.max(...vm.workflow.steps.map(function (workflowStep) {

                    return workflowStep.order;
                })) + 1) : 1
            };

            vm.workflow.steps.push(newWorkflowStep);

            vm.scrollToStep(newWorkflowStep.order, 400);

            if (vm.editing === false) {
                vm.editing = true;
            }

            vm.workflowConfigForm.$setDirty();
        };

        // Var updatedSteps = [];

        vm.onDeleteStep = function ($event) {

            vm.workflowConfigForm.$setDirty();
            if ($event.stepOrder) {
                vm.workflow.steps = vm.workflow.steps.filter(function (step) {

                    return step.order !== $event.stepOrder;
                });

                // UpdatedSteps = updatedSteps.filter(function (step) {
                vm.workflow.steps = vm.workflow.steps.filter(function (step) {
                    return step.order !== $event.stepOrder;
                });
            }

            setWorkflowValidity();
        };

        vm.save = function () {

            // Steps might still be invalid from trying to put the workflow live, this shouldn't be the case for saving a workflow
            // Check this to be sure
            const validSteps = areUpdateStepsValid(vm.workflow.status);

            if (validSteps) {
                vm.workflowConfigForm.hiddenStepValidity.$setValidity('triggerNotAllowed', true);
            }
            else {
                vm.customMessage = vm.workflow.steps.length === 0 ? 'No steps are added yet' : 'You have an error in one of your steps';
                vm.workflowConfigForm.hiddenStepValidity.$setValidity('triggerNotAllowed', false);
            }

            if (vm.workflowConfigForm.$invalid) {
                return;
            }

            if (vm.workflow.status === 'failed') {
                vm.workflow.status = 'draft';
                vm.workflow.status_message = null;
            }

            if (!vm.workflow.id) {
                return workflowsService.create(getUpdatePayload(vm.workflow)).then(function (response) {

                    return workflowResponseHandler('Workflow saved!', response);
                });
            }

            return workflowService.update(vm.workflow.id, getUpdatePayload(vm.workflow)).then(function () {

                return workflowService.get(vm.workflow.id).then(function (response) {

                    return workflowResponseHandler('The workflow is updated!', response);
                });
            });
        };

        vm.scrollToStep = function (order, timeout) {

            $timeout(function () {

                if (angular.isDefined(order)) {
                    $location.hash('step-' + order);
                    $anchorScroll();
                    return;
                }

                $location.hash('');
                // Don't use anchorScroll here since it would think the page was already at the top
                // This is probably because an inner element is being scrolled down, not the entire page
                angular.element('#workflowDetailsContainer')[0].scrollTop = 0;
            }, timeout || 0);
        };

        vm.showAnalytics = function (tab) {

            if (!vm.editing) {
                return $state.go('workflow-analytics', { id: $stateParams.id, workflow: vm.workflow, viewing: tab, returnState: 'workflow' });
            }
        };

        vm.getStepDescription = function (step) {

            const triggerStepString = step.trigger_step_index ? ('Step ' + step.trigger_step_index) : 'workflow is entered';

            const foundActionTrigger = vm.actionTriggers.find(function (actionTrigger) {

                return actionTrigger.trigger === step.trigger;
            });
            const triggerActionString = (step.trigger_step_index && step.trigger) ? ' ' + foundActionTrigger.description : '';

            return (angular.isNumber(step.trigger_after_days) ? step.trigger_after_days : 'x') + ' days after ' + triggerStepString + triggerActionString; //TODO: sentence based on actual workflow step filter
        };

        // Used to let child components set this component to dirty, so that changes aren't accidentally discarded
        vm.setDirty = function () {

            vm.workflowConfigForm.$setDirty();
        };

        vm.getCommaSeparatedSendingDays = function () {

            return vm.daysToSendOn.map(function (day) {

                return vm.daysOfTheWeek.find(function (dayOfTheWek) {

                    return dayOfTheWek.index === day;
                }).name;
            }).join(', ').replace(/,\s([^,]+)$/, ' and $1');
        };

        /**
         * Edit stuff
         */

        vm.edit = function () {

            if (vm.workflow.status === 'live' || vm.workflow.status === 'scheduled' ) {
                return workflowService.update(vm.workflow.id, { status: 'paused' }).then(function () {

                    vm.workflow.status = 'paused';
                    vm.editing = true;
                    return utils.showSuccessToast('The workflow is paused because you\'re editing it.');
                });
            }
            else {
                vm.editing = true;
            }
        };

        vm.duplicate = function (workflow) {

            const duplicateFlow = {
                name: (workflow.name + ' (Copy)'),
                status: 'draft',
                filter: angular.copy(workflow.filter),
                individual_record_filter: angular.copy(workflow.individual_record_filter),
                exit_after_days: workflow.exit_after_days,
                record_type: workflow.record_type,
                goal: workflow.goal,
                continuous: workflow.continuous,
                schedule_date: vm.isScheduledWorkflow ? vm.scheduleDate : undefined,
                schedule_days: vm.daysToSendOn,
                schedule_time_start: utils.formatTimestamp(vm.startHour),
                schedule_time_end: utils.formatTimestamp(vm.endHour),
                steps: formatWorkflowStepsForHttp(vm.workflow.steps, false)
            };

            return $state.go('workflow', {
                id: 'new',
                workflow: duplicateFlow
            });
        };

        vm.cancelEdit = function () {

            if (!vm.workflowConfigForm.$dirty) {
                discardChanges();
                return;
            }

            return sfDiscardDialog.show(false, 'workflow').then(function () {

                discardChanges();
                vm.workflowConfigForm.$setPristine();
            });

        };

        vm.start = function () {

            // Make sure to reset custom message, since it might show for other errors
            vm.customMessage = '';

            const workflowWasLiveBeforeReleaseDate = vm.workflow.status === 'live' || (vm.workflow.status === 'paused' && vm.workflow.scheduled_before_pro_release);
            if (!model.me.plan_flags.multiStepWorkflows && !workflowWasLiveBeforeReleaseDate && !vm.workflow.multi_step_before_pro_release && vm.workflow.step_amount > 1) {
                return sfUpgradeDialog.show('multiStepWorkflows');
            }

            if (!vm.editing) {
                vm.edit();
            }

            const status = vm.isScheduledWorkflow ? 'scheduled' : 'live';
            vm.savingDraft = false;

            setWorkflowValidity();

            const validSteps = areUpdateStepsValid(status);
            if (!validSteps) {
                vm.customMessage = vm.workflow.steps.length === 0 ? 'No steps are added yet' : 'You have an error in one of your steps';
                vm.workflowConfigForm.hiddenStepValidity.$setValidity('triggerNotAllowed', false);
                return;
            }

            vm.workflowConfigForm.hiddenStepValidity.$setValidity('triggerNotAllowed', true);

            // Name, filter, status, goal
            if (!vm.workflowConfigForm.$valid || vm.workflow.steps.length === 0) {
                if (vm.workflowConfigForm.hiddenFilter.$invalid) {
                    vm.customMessage = 'You can not save a workflow nor set it live without an audience if it\'s not continuous.';
                }

                return;
            }

            // Show confirmation dialog
            const confirmDialogTitle = status === 'scheduled' ? 'Are you sure you want to schedule this email workflow?' : 'Are you sure you want to set this workflow live?';
            let confirmDialogTextContent = '';
            if (status === 'scheduled') {
                if (vm.workflow.continuous) {
                    confirmDialogTextContent = 'It will start sending';
                }
                else {
                    confirmDialogTextContent = 'It will be sent';
                }

                confirmDialogTextContent += ' to all filtered and selected contacts on the schedule date.';
            }
            else {
                confirmDialogTextContent = 'It will be sent to all filtered and selected contacts';
                if (vm.workflow.continuous) {
                    confirmDialogTextContent += ' in the future.';
                }
                else {
                    confirmDialogTextContent += '.';
                }
            }

            const originalStatus = vm.workflow.status;

            return $mdDialog.show(
                $mdDialog.confirm()
                    .title(confirmDialogTitle)
                    .textContent(confirmDialogTextContent)
                    .ok('yes')
                    .cancel('no')
            ).then(function () {

                vm.workflow.status = vm.isScheduledWorkflow ? 'scheduled' : 'live';
                const successMessage = vm.isScheduledWorkflow ? 'The workflow was scheduled.' : 'The workflow started sending.';

                if (vm.workflow.id) {
                    // The user might have made more changes before pressing send, so we have to send the entire updatePayload.
                    return workflowService.update(vm.workflow.id, getUpdatePayload(vm.workflow))
                        .then(function (response) {

                            return workflowResponseHandler(successMessage, response);
                        });
                }

                return workflowsService.create(getUpdatePayload(vm.workflow))
                    .then(function (response) {

                        return workflowResponseHandler(successMessage, response);
                    });

            }).catch(function () {

                vm.workflow.status = originalStatus;
                vm.savingDraft = vm.workflow.status === 'draft';
            });
        };

        vm.pause = function () {

            return workflowService.update(vm.workflow.id, { status: 'paused' })
                .then(function () {

                    vm.workflow.status = 'paused';
                    return utils.showSuccessToast('The workflow is paused.');
                });
        };

        vm.getGoalDescription = function () {

            if (!vm.workflow) {
                return;
            }

            const currentGoal = vm.goals.find(function (goal) {

                return vm.workflow.goal === goal.type;
            });

            if (angular.isUndefined(currentGoal) || !currentGoal.description) {
                return;
            }

            return currentGoal.description;
        };

        vm.getEntireAudienceText = () => {

            return `${vm.workflowAudienceText} or ${vm.workflowIndividualContactsText}`;
        };

        vm.$onDestroy = function () {

            removeStartTransitionHook();
        };

        /////////////

        function getUpdatePayload(workflow) {

            const updatePayload = {
                name: workflow.name,
                filter: workflow.filter,
                individual_record_filter: workflow.individual_record_filter,
                exit_after_days: workflow.exit_after_days,
                record_type: workflow.record_type,
                goal: workflow.goal,
                continuous: workflow.continuous,
                schedule_date: vm.isScheduledWorkflow ? new Date(vm.workflow.schedule_date).setHours(new Date(vm.time).getHours(), new Date(vm.time).getMinutes(), 0, 0) : vm.workflow.creation_date,
                schedule_days: vm.daysToSendOn,
                schedule_time_start: utils.formatTimestamp(vm.startHour),
                schedule_time_end: utils.formatTimestamp(vm.endHour),
                status: workflow.status,
                status_message: workflow.status_message,
                steps: formatWorkflowStepsForHttp(vm.workflow.steps)
            };

            utils.stripNil(updatePayload);

            return updatePayload;
        }

        function formatWorkflowStepsForHttp(workflowSteps, includeIds) {

            if (angular.isUndefined(includeIds)) {
                includeIds = true;
            }

            return workflowSteps.map(function (step) {

                // Find the corresponding step object for all the trigger steps
                // This can be done based on step.id or step.order, depending on what's available at this point
                const foundTriggerStep = workflowSteps.find(function (triggerStep) {

                    if (angular.isDefined(step.trigger_step_index)) {
                        return triggerStep.order === step.trigger_step_index;
                    }

                    if (angular.isDefined(triggerStep.id)) {
                        return triggerStep.id === step.trigger_step;
                    }

                    return false;
                });

                // Workflow is entered doesn't have a trigger
                if (angular.isUndefined(foundTriggerStep)) {
                    delete step.trigger;
                }

                const stepPayload = {
                    name: step.name,
                    order: step.order,
                    trigger_step: foundTriggerStep && foundTriggerStep.id,
                    trigger_step_index: foundTriggerStep && foundTriggerStep.order,
                    trigger_after_days: step.trigger_after_days,
                    trigger: step.trigger,
                    action_type: step.action_type,
                    action_config: step.action_config,
                    action_source: step.action_config && step.action_config.data_source
                };

                if (step.id && includeIds) {
                    stepPayload.id = step.id;
                }

                return stepPayload;
            });
        }

        function isFilterApplied(filter) {

            if (!filter) {
                return false;
            }

            if (filter.search) {
                return true;
            }

            if (filter.rules) {
                return filter.rules.length > 0;
            }

            return Object.keys(filter).length > 0;
        }

        /**
         * Checks if the steps of the current workflow are valid for updating or starting the workflow
         *
         * @param {String} updateStatus
         * @returns {Boolean} are steps valid
         */
        function areUpdateStepsValid(updateStatus) {

            if (updateStatus !== 'scheduled' && updateStatus !== 'live') {
                return true;
            }

            const workflow = getUpdatePayload(vm.workflow);

            let stepsValid = true;
            if (workflow.steps.length === 0) {
                return false;
            }

            // TODO: Use stepForms to dispatch events to, instead of getting the elements here by id
            // using onStepFormCreation
            workflow.steps.forEach(function (step) {

                const actionConfig = step.action_config;
                if (!actionConfig) {
                    stepsValid = false;
                }

                if (isTriggerInvalid(step.trigger)) {
                    stepsValid = false;
                }

                // Create events programmatically. sf-error-indicator needs the trigger to work correctly
                const stepFormSubmitEvent = $document[0].createEvent('Event');
                stepFormSubmitEvent.initEvent('submit', false, true);
                const stepElement = $document[0].querySelector('#stepForm-' + step.order);
                $timeout(function () {

                    stepElement.dispatchEvent(stepFormSubmitEvent);
                }, 0);

                if (stepsValid) {
                    switch (step.action_type) {
                        case 'send_email': {
                            const valid = isSendEmailActionConfigValid(actionConfig);

                            if (valid === false) {
                                stepsValid = false;
                            }

                            break;
                        }

                        default:
                            stepsValid =  false;
                    }
                }
            });

            return stepsValid;
        }

        function isTriggerInvalid(trigger) {

            switch (trigger) {
                case 'opened':
                    if (vm.workflow.goal === 'opened') {
                        return true;
                    }

                    return false;
                case 'clicked':
                    if (vm.workflow.goal === 'opened' || vm.goal === 'clicked') {
                        return true;
                    }

                    return false;
                case 'replied':
                    if (vm.workflow.goal === 'opened' || vm.goal === 'replied') {
                        return true;
                    }

                    return false;
                default:
                    return false;
            }
        }

        function isSendEmailActionConfigValid(actionConfig) {

            const message = actionConfig.message;
            if (
                angular.isUndefined(message.subject) ||
                angular.isUndefined(message.body) ||
                angular.isUndefined(message.data_source) ||
                angular.isUndefined(message.from) ||
                angular.isUndefined(message.tracked)
            ) {
                return false;
            }

            return true;
        }

        function workflowResponseHandler(message, response) {

            const id = vm.workflow.id ? vm.workflow.id : response.data.id;

            if (!vm.workflow.id) {
                utils.showSuccessToast(message);
                return $state.go('workflow', { id: response.data.id });
            }

            return workflowService.get(id).then(function (workflowResponse) {

                initWorkflowData(workflowResponse.data);
                vm.editing = false;
                vm.workflowConfigForm.$setPristine();

                // Refetch `me` to potentially hide `failed_workflows` status bar when shown
                if (model.me.has_recently_failed_workflows) {
                    authentication.fetchMe({ ignoreLoadingBar: true }).finally(angular.noop);
                }

                return utils.showSuccessToast(message);
            });
        }

        function discardChanges() {

            if (!vm.workflow.id) {
                vm.editing = false;
                return $rootScope.back();
            }

            return workflowService.get(vm.workflow.id).then(function (response) {

                vm.workflow = response.data;
                // Generate text from workflow filter
                humanReadableAdvancedFilterService.getHumanReadableAdvancedFilter(vm.workflow.filter, vm.workflow.record_type).then(function (filterText) {

                    vm.workflowAudienceText = filterText;
                });
                vm.workflowIndividualContactsText = getIndividualRecordsText();

                vm.workflow.exit_after_days = vm.workflow.exit_after_days || 0;

                const preparedWorkflowAnalytics = workflowService.prepareAnalyticData(vm.workflow.analytics);
                vm.workflow.analytics = preparedWorkflowAnalytics;

                vm.workflow.steps.map(function (step) {

                    step.analytics = workflowService.prepareAnalyticData(step.analytics);
                    return step;
                });

                vm.editing = false;
                vm.workflowConfigForm.$setPristine();
            });
        }

        function setWorkflowValidity() {

            if (vm.workflow.status === 'draft') {
                return;
            }

            checkStepsValidity();

            if (!vm.workflowConfigForm) {
                return;
            }

            // Setting a filter is not required when saving a draft or when the workflow is continuous
            if (vm.savingDraft || vm.workflow?.continuous) {
                return vm.workflowConfigForm.hiddenFilter.$setValidity('required', true);
            }

            if (vm.workflow.filter &&  vm.workflow.filter.rules) {
                const containsDisabledRules =  vm.workflow.filter.rules.some(function (filterRule) {

                    return filterRule.disabled;
                });

                if (containsDisabledRules) {
                    return vm.workflowConfigForm.hiddenFilter.$setValidity('required', false);
                }
            }

            if (angular.isUndefined(vm.workflow.exit_after_days)) {
                vm.workflowConfigForm.exitDays.$setValidity('required', false);
            }
            else {
                vm.workflowConfigForm.exitDays.$setValidity('required', true);
            }

            const filterApplied = isFilterApplied(vm.workflow.filter) || isFilterApplied(vm.workflow.individual_record_filter);
            vm.workflowConfigForm.hiddenFilter.$setValidity('required', filterApplied);
        }


        function checkStepsValidity() {

            if (!vm.workflowConfigForm) {
                return;
            }

            const invalidSteps = vm.workflow.steps.filter((step) => step.valid === false);

            if (invalidSteps && invalidSteps.length > 0) {

                vm.customMessage = 'The following steps have an invalid configuration: ' + invalidSteps.map((s) => s.order).join(', ');
                vm.workflowConfigForm.hiddenStepValidity.$setValidity('stepsInvalid', false);
            }
            else {
                vm.workflowConfigForm.hiddenStepValidity.$setValidity('stepsInvalid', true);
            }
        }

        function getIndividualRecordsText() {

            const recordRule = vm.workflow?.individual_record_filter?.rules[0];
            const recordAmount = recordRule?.value.length;
            const recordType = vm.workflow.record_type;
            const recordTypeCapitalized = recordType.charAt(0).toUpperCase() + recordType.slice(1);

            if (!recordAmount || recordAmount === 0) {
                return `Select individual ${recordType}s`;
            }

            return `${recordTypeCapitalized} is one of ${recordAmount} selected ${recordType}s`;
        }
    }
})();
