(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('email', emailService);

    function emailService(sfHttp, $mdDialog, $window, $filter, $state, $q, config, model, utils) {

        const self = this;

        this.get = function (id, emailAccountId) {

            // Encode id to make sure special chars work in the url
            id = encodeURIComponent(id);

            let url = config.apiUrl + 'emails/' + id;

            if (emailAccountId) {
                url += '?email_account_id=' + emailAccountId;
            }

            return sfHttp.get(url, { ignoreLoadingBar: true });
        };

        this.create = function (email) {

            return sfHttp.post(config.apiUrl + 'emails', email, { ignoreLoadingBar: true });
        };

        this.syncAccount = function () {

            return sfHttp.post(config.apiUrl + 'datasources/email/sync');
        };

        this.mailto = function (contact, $event) {

            if ($event) {
                $event.preventDefault();
            }

            if (angular.isString(contact)) {
                contact = { email: contact };
            }

            // New outlook plugin likes objects
            const isPlugin = (config.mode === 'outlook' || config.mode === 'gmail');
            let to;

            if (isPlugin) {
                if (name) {
                    to = '&quot;' + contact.name + '&quot; <' + contact.email + '>';
                }
                else {
                    to = contact.email;
                }
            }
            else {
                to = [{ email: contact.email }];

                if (contact.name) {
                    to[0].name = contact.name;
                }
            }

            return self.compose({ to });
        };

        this.compose = function (email, type) {

            const message = {
                function: 'compose',
                email,
                data: email
            };

            switch (config.mode) {
                case 'outlook':

                    // Check if old version of plugin
                    if ($window.external.sendMail) {
                        $window.external.sendMail(angular.toJson(email));
                    }
                    else {
                        $window.external.notify(angular.toJson(message));
                    }

                    break;
                case 'outlook-web':
                    $window.parent.postMessage(message, '*');

                    break;
                case 'gmail':


                    $window.parent.postMessage(message, '*');

                    break;
                default: {
                    const emailComposeObject = {
                        body: email.html_body || email.text_body || null,
                        subject: email.subject || null,
                        to: email.to || [],
                        cc: email.cc || [],
                        attachments: email.attachments || [],
                        type,
                        threadId: email.threadId || undefined,
                        inReplyTo: email.inReplyTo || undefined
                    };

                    // Handle potentialSenderEmails field for replies
                    // It will contain an array of emails that should be used as the sending email,
                    // if available in the list of sender aliases
                    if (email.potentialSenderEmails) {
                        emailComposeObject.potentialSenderEmails = email.potentialSenderEmails;
                    }

                    return $state.go('composeEmail', emailComposeObject);
                }
            }
        };

        /**
         * From => To
         * To => To
         * CC => CC
         * This slightly differs from how Gmail does it. In Gmail when you are CC'd and pres reply all it will put everyone in To
         *
         * @param {String} type either `reply`, `replyAll` or `forward`
         * @param {Object} rawEmail
         * @returns {{ subject: String?, to: String | Array.<{ email: String, name: String }>, cc: String | Array.<{ email: String, name: String }>, html_body: String, text_body: String }}
         */
        this.formatRawEmailToComposeObject = function (type, rawEmail) {

            type = type || 'reply';
            // New outlook plugin likes objects
            const isPlugin = (config.mode === 'outlook' || config.mode === 'gmail');
            const subjectPrefix = type === 'forward' ?  'Fwd: ' : 'Re: ';
            const composeObj = {
                // eslint-disable-next-line unicorn/no-unsafe-regex
                subject: rawEmail.subject ? '' + subjectPrefix + rawEmail.subject.replace(/^(re: ?|fwd: ?)+/i,'') : null, // If no subject provided we also don't set one ourselves (like Gmail)
                to: isPlugin ? '' : [] // In the plugin the 'to' is a csv of recipients, in-app we user an array of contact objects
            };

            if (['reply', 'replyAll'].includes(type) && !isPlugin && rawEmail.service_thread_id) {
                if (rawEmail.service_type === 'gmail') {
                    composeObj.threadId = rawEmail.service_thread_id;
                }

                composeObj.inReplyTo = rawEmail.service_type === 'office365' ? rawEmail.service_message_id : rawEmail.email_message_id;
            }

            if (type !== 'forward' && rawEmail.addresses.from.email !== model.me.email) {
                if (isPlugin) {
                    if (rawEmail.addresses.from.name) {
                        composeObj.to += '"' + rawEmail.addresses.from.name + '" <' + rawEmail.addresses.from.email + '>';
                    }
                    else {
                        composeObj.to += rawEmail.addresses.from.email;
                    }
                }
                else {
                    composeObj.to.push(rawEmail.addresses.from);
                    // Pass the from property to try and get the correct sending data source, if applicable
                    composeObj.potentialSenderEmails = rawEmail.addresses.to;
                }
            }

            if (type === 'replyAll' || (type === 'reply' && rawEmail.addresses.from.email === model.me.email)) {
                if (rawEmail.addresses.to && rawEmail.addresses.to.length > 0) {
                    // Add all 'to' that are not the current user to the to of the reply all
                    const allToRecipientsWithoutLoggedInUser = rawEmail.addresses.to.filter(function (to) {

                        return to.email !== model.me.email;
                    });

                    if (isPlugin) {
                        composeObj.to = allToRecipientsWithoutLoggedInUser.map(function (to) {

                            if (to.name) {
                                return `"${to.name}" <${to.email}>`;
                            }

                            return to.email;
                        }).join(',');
                    }
                    else {
                        composeObj.to = [...composeObj.to, ...allToRecipientsWithoutLoggedInUser];
                    }
                }

                // Keep CC in cc
                if (isPlugin) {
                    if (rawEmail.addresses.cc && rawEmail.addresses.cc.length > 0) {
                        composeObj.cc = rawEmail.addresses.cc.map(function (cc) {

                            return '"' + cc.name + '" <' + cc.email + '>';
                        }).join(',');
                    }
                }
                else {
                    composeObj.cc = rawEmail.addresses.cc;
                }
            }

            if (rawEmail.body) {
                const htmlBody = rawEmail.body.find(function (b) {

                    return b.type === 'text/html';
                });

                const textBody = rawEmail.body.find(function (b) {

                    return b.type === 'text/plain';
                });

                if (htmlBody && htmlBody.content) {
                    composeObj.html_body = '<br/><br/>On ' + $filter('date')(new Date(rawEmail.date), 'medium') + ',';

                    if (rawEmail.addresses.from.name) {
                        composeObj.html_body += ' "' + rawEmail.addresses.from.name + '"';
                    }

                    composeObj.html_body += ' &lt;<a href="mailto:' + rawEmail.addresses.from.email + '" target="_blank" rel="noopener">' + rawEmail.addresses.from.email + '</a>&gt; wrote:<br/>';

                    composeObj.html_body += '<blockquote style="margin:0 0 0 .8ex;border-left:1px #ccc solid;padding-left:1ex">';
                    composeObj.html_body += htmlBody.content.toString();
                    composeObj.html_body += '</blockquote>';
                }

                if (textBody && textBody.content) {
                    if (isPlugin) {
                        composeObj.text_body = '\n\n';
                    }
                    else {
                        composeObj.text_body = '';
                    }

                    composeObj.text_body += ('On ' + $filter('date')(new Date(rawEmail.date), 'medium') + ', "' + rawEmail.addresses.from.name + '" <' + rawEmail.addresses.from.email + '> wrote:\n\n');
                    composeObj.text_body += textBody.content.toString();
                }
            }

            /**
             * Known limitation: forwarding attachments on development will NOT work
             * We use the Salesflare API url to download the attachment before sending but this will error when the server is running on localhost
             */
            if (type === 'forward' && rawEmail.files) {
                composeObj.attachments = rawEmail.files.map(function (file) {

                    return {
                        file_size: file.size,
                        file_type: file.type,
                        file_name: file.file_name,
                        file_url: config.apiUrl + 'files/' + file.file_id,
                        id: file.file_id
                    };
                });
            }

            return composeObj;
        };

        // Resolves when the datasource is already configured for SMTP or when the user updated the SMTP settings successfully in the dialog
        this.showConfigureSMTPSettingsDialogIfNeeded = function (datasource) {

            if (!datasource) {
                utils.showErrorToast('Seems like you didn\'t connect your email address to Salesflare yet. You can do this in Settings > Email Settings.');
                return $q.reject();
            }

            // Google uses OAuth and all other sources use basic username/password authentication.
            // If the data source is connected to another user (this can be the case for workflows) there is no datasource.type, and we don't care about SMTP verification, since that's already done on the other user's side
            if ((datasource.type !== 'google' && datasource.type !== 'office365' && angular.isDefined(datasource.type)) && !datasource.smtp_port && !datasource.smtp_host) {
                return $mdDialog.show({
                    clickOutsideToClose: false,
                    controller: 'UpdateSMTPSettingsController',
                    templateUrl: 'partials/updatesmtpsettingsdialog.html',
                    locals: {
                        email: datasource.email,
                        port: null,
                        host: null
                    },
                    bindToController: true,
                    multiple: true
                }).then(function (updatedSMTPSettings) {

                    if (!updatedSMTPSettings) {
                        return $q.reject();
                    }

                    datasource.smtp_port = updatedSMTPSettings.port;
                    datasource.smtp_host = updatedSMTPSettings.host;

                    utils.showSuccessToast('You\'re all set up for sending emails from Salesflare!');
                    return $q.resolve();
                });
            }

            return $q.resolve();
        };
    }
})();
