(function () {
    'use strict';

    angular
        .module('salesflare.components.import.upload', [])
        .component('sfImportUpload', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/import/upload/importupload.html',
            bindings: {
                type: '<',
                onDelete: '&'
            }
        });

    function controller($mdMedia, $scope, $window, imports) {

        const vm = this;

        vm.$mdMedia = $mdMedia;
        vm.isMobile = $window.isMobile;

        vm.$onInit = function () {

            vm.import = '';
        };

        $scope.$watch('files', function () {

            if ($scope.files && $scope.files.length === 1) {
                vm.upload($scope.files);
            }
        });

        $scope.$watch('file', function () {

            if ($scope.file !== null) {
                $scope.files = [$scope.file];
            }
        });

        // Upload on file select or drop
        vm.upload = function (files) {

            if (files && files.length > 0) {
                for (const file of files) {

                    if (file && !file.$error) {
                        imports.upload(vm.type, file);
                    }
                }
            }
        };

        vm.toMap = function (type, state) {

            return vm.onDelete({ type, state });
        };
    }
})();
