(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('notifications', notificationsService);

    // eslint-disable-next-line no-shadow
    function notificationsService(sfHttp, $window, $document, $timeout, $interval, $state, $exceptionHandler, $q, $mdMedia, model, config, notificationsService, favicoService, sfSetupPanel) {

        this.getSettings = function () {

            return sfHttp.get(config.apiUrl + 'settings/notifications');
        };

        this.updateSettings = function (settings) {

            return sfHttp.put(config.apiUrl + 'settings/notifications', settings);
        };

        this.mobileToken = null;
        const self = this;
        let incus;
        let pushPlugin;
        let pollInterval;
        let authenticationFetchMe;

        const emailCreditNotificationTypes = new Set([
            'email_credits_depleted',
            'bulk_find_email_done',
            'bulk_find_email_no_credits_left',
            'bulk_find_email_failed'
        ]);

        // Register mobile notifications as soon as we can since we need the token for login
        $document[0].addEventListener('deviceready', onDeviceReady, false);

        function onDeviceReady() {

            listenForMobileNotifications();
            $document[0].addEventListener('resume', pollForAmountOfNotViewedNotifications, false);
            $document[0].addEventListener('pause', stopPollingForAmountOfNotViewedNotifications, false);
        }

        // This is called from the authentication service and should have the fetchMe function as a parameter
        // This is done to avoid circular dependency
        this.init = function (fetchMeFunction) {

            if ($window.Notification && Notification.permission !== 'granted') {
                Notification.requestPermission();
            }

            authenticationFetchMe = fetchMeFunction;

            if (!$window.isMobile && !incus) {
                return listenForWebNotifications();
            }

            if ($window.isMobile && !pushPlugin) {
                listenForMobileNotifications();
                return pollForAmountOfNotViewedNotifications();
            }
        };

        this.end = function () {

            favicoService.reset();

            return $q(function (resolve) {

                // Mobile
                if (pushPlugin) {
                    stopPollingForAmountOfNotViewedNotifications();
                    self.mobileToken = null;

                    return pushPlugin.unregister(function () {

                        pushPlugin = undefined;

                        return resolve();
                    }, function (err) {

                        pushPlugin = undefined;

                        $exceptionHandler(err);
                        return resolve();
                    });
                }

                // Web
                if (incus) {
                    incus.MAXRETRIES = -1; // So it does not start retrying
                    incus.socket.close();
                    incus = undefined;
                }

                return resolve();
            });
        };

        this.setAmountOfNotViewedNotifications = function () {

            return notificationsService.get({ filters: { viewed: false } }, { returnCountOnly: true }).then(function (response) {

                if (angular.isDefined(response.headers()['x-result-count'])) {
                    model.me.amountOfNotViewedNotifications = Number.parseInt(response.headers()['x-result-count']);
                }

                favicoService.badge(model.me.amountOfNotViewedNotifications);
            });
        };

        function pollForAmountOfNotViewedNotifications() {

            if (!pollInterval) {
                self.setAmountOfNotViewedNotifications();
                pollInterval = $interval(self.setAmountOfNotViewedNotifications, 30 * 1000);
            }
        }

        function stopPollingForAmountOfNotViewedNotifications() {

            if (pollInterval) {
                $interval.cancel(pollInterval);
                pollInterval = undefined;
            }
        }

        function listenForWebNotifications() {

            incus = new Incus.Client(config.notificationsUrl, model.me.notification_channel_id);
            incus.MAXRETRIES = 20;

            incus.on('notification', function (data) {

                const notification = data.notification;

                self.setAmountOfNotViewedNotifications();

                if (notification.notification_type === 'setup_step_completed') {
                    // Refetch to update trial expiry status bar
                    sfSetupPanel.updateSetupSteps();
                }

                if (notification.notification_type === 'plan_changed') {
                    // We want to fetchMe for all users for which the plan changed
                    authenticationFetchMe();
                }

                if (config.mode === 'outlook') {
                    notification.icon = notification.image;

                    // Check if old version of plugin
                    if ($window.external.sendMail) {
                        return $window.external.notify(angular.toJson(notification));
                    }
                    else {
                        const message = {
                            function: 'notify',
                            data: notification
                        };

                        return $window.external.notify(angular.toJson(message));
                    }
                }

                const browserNotification = new Notification(notification.title, {
                    icon: notification.image,
                    body: notification.body,
                    tag: notification.id,
                    data: {
                        account_id: notification.account_id,
                        id: notification.id,
                        type: notification.notification_type
                    }
                });

                browserNotification.addEventListener('show', function (event) {

                    return $timeout(function () {

                        return event.target.close();
                    }, 10000);
                });

                browserNotification.addEventListener('click', function (event) {

                    return handleNotificationClick(event.target.data);
                });
            });

            $window.addEventListener('online', function () {

                // Reset retries, we might have retried already
                incus.pollRetries = 0;
                incus.socketRetries = 0;

                // Will trigger a reconnection but makes sure the previous one is closed
                return incus.socket.close();
            });


        }

        function listenForMobileNotifications() {

            pushPlugin = PushNotification.init({
                android: {
                    senderID: config.gcm,
                    icon: 'notification', // Must match a png in platforms/android/res/drawable-[dpi] folder
                    iconColor: '#33B5E5', // Background color, is used when dragging open the notification
                    clearNotifications: false,
                    forceShow: true
                },
                ios: {
                    alert: true,
                    badge: true,
                    sound: true,
                    clearBadge: false,
                    forceShow: true
                }
            });

            // Remove all the non Salesflare channels
            // This sometimes doesn't work, not sure why
            // (This will only do something for Android Oreo and above)
            PushNotification.listChannels(function (channels) {

                return channels.forEach(function (channel) {

                    if (channel.id === 'default') {
                        return;
                    }

                    return PushNotification.deleteChannel(angular.noop, $exceptionHandler, channel.id);
                });
            });

            // Create our own default channel, mainly to get to get rid of the default channel's description
            // See https://github.com/phonegap/phonegap-plugin-push/issues/2691#issuecomment-467416840
            // (This will only do something for Android Oreo and above)
            PushNotification.createChannel(angular.noop, $exceptionHandler,
                {
                    id: 'default',
                    description: 'Salesflare',
                    importance: 3,
                    vibration: true
                }
            );

            pushPlugin.on('registration', function (data) {

                self.mobileToken = data.registrationId;

                if (model.isLoggedIn) {
                    return sfHttp.put(config.apiUrl + 'users/mobiletoken', { token: self.mobileToken });
                }
            });

            $timeout(function () {

                return pushPlugin.on('notification', function (data) {

                    // Prevent ios notification triggering when in foreground since it does not yet support forceShow
                    if (device.platform === 'iOS' && data.additionalData.foreground) {
                        return;
                    }

                    // Android || ios
                    return handleNotificationClick(data.additionalData || data.additionalData.payload.data.notification);
                });
            }, 0);

            pushPlugin.on('error', function (e) {

                return $exceptionHandler(e);
            });
        }

        function handleNotificationClick(notificationData) {

            if (notificationData?.type === 'setup_step_completed') {
                if ($mdMedia('gt-sm')) {
                    return sfSetupPanel.show();
                }

                return $state.go('setup');
            }
            else if (emailCreditNotificationTypes.has(notificationData?.type)) {
                // On bulk find email completion we want to go to the contacts page
                // For other credit notifications we go to the plans or credits page
                if (notificationData.type === 'bulk_find_email_done' || notificationData.type === 'bulk_find_email_failed') {
                    $state.go('contacts.customers');
                    return top.focus();
                }

                const planId = model.me.team.plan;
                const userAmount = model.me.team.enabled_user_count;

                // Go to the plans page if the team is on the Growth plan, or if it's on the Pro plan with 5 or more users
                if (planId === 3 || (planId === 4 && userAmount >= 5)) {
                    $state.go('plans');
                }
                // Otherwise go to the credits page
                else {
                    $state.go('credits');
                }

                return top.focus();
            }
            else if (notificationData?.type === 'refer_a_friend') {
                $state.go('settings.referralProgram');
                return top.focus();
            }

            const accountId = Object.prototype.hasOwnProperty.call(notificationData, 'account_id') ? notificationData.account_id : notificationData.id;

            if (!accountId) {
                $state.go('tasks.today');
                return top.focus();
            }

            $state.go('accounts.account.feed', { id: accountId }, { inherit: false });

            return top.focus();
        }
    }
})();
