(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('CustomFieldsSettingsListController', CustomFieldsSettingsListController);

    function CustomFieldsSettingsListController($rootScope, $scope, customfields, $state) {

        $scope.itemClass = $state.current.url.replace('/', '');

        get();

        function get() {

            switch ($scope.itemClass) {
                case 'opportunity':
                    $rootScope.$broadcast('loadPipelines');
                    return;
                case 'account':
                    return customfields.getAccountFields(true).then(function (response) {

                        return setCustomFields(response);
                    });
                case 'contact':
                    return customfields.getContactFields(true).then(function (response) {

                        return setCustomFields(response);
                    });
            }
        }

        function setCustomFields(response) {

            $scope.standardFields = response.data.filter(function (field) {

                return field.isStandardField;
            });

            $scope.predefinedFields = response.data.filter(function (field) {

                return field.predefined_customfield;
            });

            $scope.customFields = response.data.filter(function (field) {

                return !field.isStandardField && !field.predefined_customfield;
            });
        }

        $scope.disableOrEnableField = function (event, field) {

            field.enabled = !field.enabled;
            field.type = field.type.id;

            const f = angular.copy(field);
            delete f.api_field;
            // No default values on update
            delete f.default_boolean_value;
            // Type is not allowed on update
            delete f.type;

            switch ($scope.itemClass) {
                case 'opportunity':
                    return customfields.updateOpportunityField(f).then(get);
                case 'account':
                    return customfields.updateAccountField(f).then(get);
                case 'contact':
                    return customfields.updateContactField(f).then(get);
            }
        };

        $scope.createCustomField = function (itemClass) {

            return $state.go('settings.createCustomField', { itemClass });
        };

        $scope.$on('refreshData', function () {

            return get();
        });

        $scope.customfieldSortOptions = {
            longTouch: true,
            orderChanged: function () {

                return $scope.customFields.forEach(function (customfield, index) {

                    customfield.order = index + 1;
                });
            },
            accept: function (sourceItemHandleScope, destSortableScope) {

                return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
            },
            dragEnd: function () {

                const orderedFields = $scope.customFields.map(function (field) {

                    return {
                        id: field.id,
                        order: field.order
                    };

                });

                switch ($scope.itemClass) {

                    case 'opportunity':
                        return customfields.updateOpportunityOrder(orderedFields).then(get);
                    case 'account':
                        return customfields.updateAccountOrder(orderedFields).then(get);
                    case 'contact':
                        return customfields.updateContactOrder(orderedFields).then(get);
                }
            }
        };
    }
})();
