(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('opportunityService', opportunityService);

    function opportunityService(sfHttp, config, utils) {

        this.get = function (id) {

            return sfHttp.get(config.apiUrl + 'opportunities/' + id);
        };

        this.create = function (opportunity) {

            utils.stripNilAndNull(opportunity);

            if (opportunity.currency?.id) {
                opportunity.currency = opportunity.currency.id;
            }

            return sfHttp.post(config.apiUrl + 'opportunities', opportunity);
        };

        this.update = function (opportunity) {

            if (opportunity.currency?.id) {
                opportunity.currency = opportunity.currency.id;
            }

            return sfHttp.put(config.apiUrl + 'opportunities/' + opportunity.id, opportunity);
        };

        this.delete = function (opportunity) {

            return sfHttp.delete(config.apiUrl + 'opportunities/' + opportunity.id);
        };

        this.getCustomFields = function (opportunityId, pipelineId) {

            return sfHttp.get(config.apiUrl + 'opportunities/' + opportunityId + '/customfields', { params: { pipeline: pipelineId } });
        };
    }
})();
