<sfx-toolbar enableDivider>
    Notification settings
</sfx-toolbar>

<sfx-content>
    <sfx-section data-layout="column" *ngIf="!loading">
        <ng-container *ngFor="let setting of notifications | keyvalue: keepOrder">
            <mat-slide-toggle class="setting-toggle" color="primary"
                              [aria-label]="setting.value.ariaLabel"
                              [(ngModel)]="setting.value.value"
                              (change)="updateSettings(setting.key)"
            >
                {{ setting.value.label }}
            </mat-slide-toggle>
        </ng-container>
    </sfx-section>
</sfx-content>
