(function () {
    'use strict';

    angular
        .module('salesflare.components.tasks.today', [])
        .component('sfTasksToday', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/tasks/today/today.html',
            bindings: {
                entityCountString: '<', // The amount of tasks in the db
                tasks: '<', // The md-virtual repeat object
                loadingTasks: '<',
                onAddTask: '&',
                onDismissTask: '&',
                onSelectedChanged: '&', // Directly bound to the task event
                onEditTask: '&',
                onEditedTask: '&',
                onUpdateTask: '&',
                isTaskFilterApplied: '&',
                usedBadFilter: '<',
                badFilterError: '<',
                setFilters: '&',
                getSearch: '&'
            }
        });


    function controller($timeout, $state, $scope, $mdMedia, flagsService, model) {

        const vm = this;
        let tasksTour;
        let walkThroughTimeout;
        vm.me = model.me;
        vm.$mdMedia = $mdMedia;

        vm.$onInit = function () {

            if (flagsService.get('showTaskWalkthrough') === true) {
                showWalkThrough();
            }

            vm.doneQuote = getRandomDoneQuote();
            vm.recentlyCompletedTasks = [];

            // Ideally this would be dynamic but virtual repeat only allows setting it on load basically
            vm.itemSize = $mdMedia('gt-sm') ? 48 : 72;
        };


        vm.$onDestroy = function () {

            if (tasksTour) {
                tasksTour.cancel();
            }

            if (walkThroughTimeout) {
                $timeout.cancel(walkThroughTimeout);
            }
        };

        vm.onAddTaskProxy = function ($event) {

            return vm.onAddTask({
                $event
            });
        };

        vm.onDismissTaskProxy = function ($event) {

            vm.recentlyCompletedTasks = vm.recentlyCompletedTasks.filter(function (t) {

                return t.id !== $event.task.id;
            });

            return vm.onDismissTask({
                $event
            });
        };

        vm.onEditTaskProxy = function ($event) {

            return vm.onEditTask({
                $event
            });
        };

        vm.onEditedTaskProxy = function ($event) {

            return vm.onEditedTask({
                $event
            });
        };

        vm.onUpdateTaskProxy = function ($event) {

            // If completed move to completed list otherwise remove from that list
            if ($event.task.completed === true) {
                vm.recentlyCompletedTasks.push($event.task);
            }
            else if (!$event.task.completed) {
                vm.recentlyCompletedTasks = vm.recentlyCompletedTasks.filter(function (t) {

                    return t.id !== $event.task.id;
                });
            }

            return vm.onUpdateTask({
                $event
            });
        };

        vm.go = function (target) {

            return $state.go(target);
        };

        function showWalkThrough() {

            walkThroughTimeout = $timeout(function () {

                tasksTour = new Shepherd.Tour({
                    defaultStepOptions: {
                        popperOptions: {
                            // Adds an offset of 12px to the tooltip so the arrow (instead of the content) starts on the target element
                            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }]
                        }
                    }
                });

                const text = 'Type here to add new tasks. &#x261D \n\n Salesflare will also create tasks automatically. It will remind you to respond to emails, add meeting notes, add customers, and more. These tasks also automatically get marked as completed when you complete them. You can always change this in the Settings.';

                tasksTour.addStep({
                    id: 'explain-tasks',
                    title: 'Welcome to your to do list!',
                    text,
                    attachTo: {
                        element: '.taskCreate',
                        on: 'bottom'
                    },
                    buttons: [{
                        text: 'GOT IT!',
                        action: tasksTour.next
                    }]
                });

                tasksTour.on('complete', function () {

                    return flagsService.set('showTaskWalkthrough', false);
                });

                return tasksTour.start();
            }, 1000);
        }

        function getRandomDoneQuote() {

            const doneQuotes = [
                'All done! You\'re amazing. \u2728', // ✨
                'You\'re simply the best.',
                'Hell yeah! \uD83D\uDCAA',  // 💪
                'Done, done aaaand done.'
            ];
            return doneQuotes[Math.floor(Math.random() * doneQuotes.length)];
        }
    }
})();
