(function () {
    'use strict';

    angular
        .module('salesflare')
        .factory('calendarsyncprogress', calendarSyncProgressFactory);

    function calendarSyncProgressFactory($interval, me, model, statusBarService) {

        let stop;
        const text = {
            initial: 'Creating meetings with customers',
            doing: 'Creating meetings with customers',
            error: 'The calendar sync did not complete successfully. We\'re on it! You can already start working.',
            done: 'Salesflare has finished syncing your meetings.'
        };

        return {
            startPolling,
            stopPolling,
            text
        };

        function stopPolling() {

            if (stop) {
                $interval.cancel(stop);
                stop = undefined;
            }
        }

        function startPolling() {

            if (!stop) {
                doPoll();
                stop = $interval(doPoll, 5 * 1000);
            }
        }

        function doPoll() {

            return me.get().then(function (response) {

                if (!model.me) {
                    return stopPolling();
                }

                model.me.calendar_sync_status = response.data.calendar_sync_status;

                if (model.me.calendar_sync_status === 'done') {
                    stopPolling();

                    return statusBarService.show({ type: 'calendar', text: text[model.me.calendar_sync_status], showSpinner: false, canClose: true, class: 'success' });
                }
                else if (model.me.calendar_sync_status === 'error') {
                    const statusBar = {
                        type: 'calendar',
                        text: text[model.me.calendar_sync_status],
                        showSpinner: false,
                        canClose: true,
                        onClose: stopPolling,
                        class: 'error'
                    };

                    return statusBarService.show(statusBar);
                }

                if (text[model.me.calendar_sync_status] && !model.me.first_calendar_sync_done) {
                    return statusBarService.show({ type: 'calendar', text: text[model.me.calendar_sync_status] });
                }

                // Only show status bar for initial/doing when it's the first time syncing
                if (text[model.me.calendar_sync_status] && model.me.first_calendar_sync_done) {
                    return statusBarService.hide('onboarding');
                }

                // Not a valid sync_status (actual error or just not a valid one to show in the bar)
                statusBarService.hide('onboarding');
                return stopPolling();
            });
        }
    }
})();
