(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('RegionalSettingsController', RegionalSettingsController);

    function RegionalSettingsController($scope, $mdDateLocale, $filter, $q, model, currencies, team, timeZoneService, usersettings) {

        $scope.currency = model.me.team.currency;

        // The options for the select element
        $scope.timeNotations = [
            {
                id: 0,
                notation: '24-hour clock',
                value: false
            },
            {
                id: 1,
                notation: '12-hour clock (AM-PM)',
                value: true
            }
        ];

        // Get the right entry
        if (model.me.am_pm_notation) {
            $scope.am_pm_notation = $scope.timeNotations[1];
        }
        else {
            $scope.am_pm_notation = $scope.timeNotations[0];
        }

        // The options for the select element
        $scope.startDays = [
            {
                id: 0,
                day: 'Sunday',
                value: 0
            },
            {
                id: 1,
                day: 'Monday',
                value: 1
            },
            {
                id: 2,
                day: 'Saturday',
                value: 6
            }
        ];

        // Get the right entry
        const firstDayOfWeek = $filter('filter')($scope.startDays, { value: Number.parseInt(model.me.first_day_of_week) }, true);
        if (firstDayOfWeek.length > 0) {
            $scope.first_day_of_week = firstDayOfWeek[0];
            $mdDateLocale.firstDayOfWeek = firstDayOfWeek[0].value;
        }

        get();

        $scope.save = function () {

            return $q.all([
                function updateCurrency() {

                    if (model.me.team.currency !== $scope.currency) {
                        model.me.team.currency = $scope.currency;
                        return team.updateCurrency($scope.currency.id);
                    }
                }(),
                function updateSettings() {

                    const timeSettings = {};

                    if (($scope.am_pm_notation !== null && angular.isDefined($scope.am_pm_notation)) && (model.me.am_pm_notation !== $scope.am_pm_notation.value)) {
                        timeSettings.am_pm_notation = $scope.am_pm_notation.value;
                        model.me.am_pm_notation = $scope.am_pm_notation.value;
                    }

                    if (($scope.first_day_of_week !== null && angular.isDefined($scope.first_day_of_week)) && (model.me.first_day_of_week !== $scope.first_day_of_week.value)) {
                        timeSettings.first_day_of_week = Number.parseInt($scope.first_day_of_week.value);
                        model.me.first_day_of_week = Number.parseInt($scope.first_day_of_week.value);
                        $mdDateLocale.firstDayOfWeek = Number.parseInt($scope.first_day_of_week.value);
                    }

                    if (angular.isUndefined(timeSettings.first_day_of_week) && angular.isUndefined(timeSettings.am_pm_notation)) {
                        return;
                    }

                    // Update UserSettings with the changed values
                    return usersettings.update(timeSettings);
                }(),
                function updateTeam() {

                    if (model.me.team.time_zone !== $scope.timeZone.value) {
                        model.me.team.time_zone = $scope.timeZone.value;
                        return team.update({ time_zone: $scope.timeZone.value });
                    }
                }()
            ]);
        };

        function get() {

            return $q.all([
                function getCurrencies() {

                    return currencies.get().then(currenciesResponse, null, currenciesResponse);
                }(),
                function getTimeZones() {

                    return timeZoneService.get().then(timeZonesResponse, null, timeZonesResponse);
                }()
            ]).then(function () {

                $scope.initialized = true;
            });
        }

        function timeZonesResponse(response) {

            $scope.timeZones = response.data;
            $scope.timeZone = $scope.timeZones.find(function (tz) {
                return tz.value === model.me.team.time_zone;
            }) || $scope.timeZones.find(function (tz) {
                return tz.value === 'UTC';
            });
        }

        function currenciesResponse(response) {

            // Setting the currencies twice (cache and http response)
            // causes the select to render the current currency twice
            // and only fixes itself after a few seconds
            // no idea as to why this happens though
            if (!angular.equals($scope.currencies, response.data)) {
                $scope.currencies = response.data;
            }
        }
    }
})();
