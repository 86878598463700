(function () {
    'use strict';

    angular
        .module('salesflare.components.mainmenu', [])
        .component('sfMainMenu', {
            templateUrl: 'app-ajs/components/mainmenu/mainmenu.html',
            controller,
            controllerAs: 'vm'
        });

    function controller($document, $mdMedia, $state, $scope, $mdSidenav, $mdPanel, model, sfWalkthrough, sfContextualHelp, usersettings, flagsService, sfSetupPanel) {

        const vm = this;

        vm.currentUser = model.me;
        vm.sfContextualHelp = sfContextualHelp;
        vm.isSetupPanelActive = sfSetupPanel.isActive;

        vm.$onInit = function () {

            Intercom('onHide', function () {

                return $scope.$applyAsync(function () {

                    vm.supportChatActive = false;
                });
            });

            Intercom('onShow', function () {

                return $scope.$applyAsync(function () {

                    vm.supportChatActive = true;
                });
            });

            Intercom('onUnreadCountChange', function () {

                return $scope.$applyAsync(function () {

                    vm.supportChatActive = true;
                });
            });
        };

        vm.shouldShowOnFullscreen = function () {

            return $mdMedia('gt-sm');
        };

        vm.openNotificationPanel = function () {

            vm.isNotificationPanelOpened = !vm.isNotificationPanelOpened;

            if (vm.isNotificationPanelOpened) {
                let position = $mdPanel.newPanelPosition();

                if ($mdMedia('gt-sm')) {
                    position = $mdPanel.newPanelPosition()
                        .relativeTo('.mainMenu')
                        .addPanelPosition($mdPanel.xPosition.OFFSET_END, $mdPanel.yPosition.ALIGN_TOPS);
                }

                return $mdPanel.open('notificationPanel', {
                    attachTo: $document[0].body,
                    position,
                    locals: {},
                    onRemoving: function () {

                        vm.isNotificationPanelOpened = false;
                        model.me.amountOfNotViewedNotifications = 0;
                        return usersettings.setLastVisitedNotificationsToNow();
                    }
                });
            }
        };

        vm.currentStateIncludes = function (testString) {

            return $state.includes(testString);
        };

        vm.toggleMenu = function () {

            return $mdSidenav('menu').toggle();
        };

        vm.workflowsEnabled = function () {

            return flagsService.get('workflowBeta');
        };

        vm.showNewFeatureIndicatorInsights = function () {

            return flagsService.get('showInsightsWalkthrough');
        };

        vm.hasDeal = function () {

            return (vm.currentUser && (vm.currentUser.team.total_user_count === 1 || vm.currentUser.team.free_users > 0) && vm.currentUser.team.discount_type === 'firstFreeThen50Off' && (new Date(vm.currentUser.team.creation_date) < new Date('2018-10-30')) && (new Date() < new Date(new Date('2018-11-10').setUTCHours(0,0,0,0))));
        };

        vm.showContextualHelp = function () {

            if (sfSetupPanel.isActive()) {
                return sfSetupPanel.close();
            }

            return sfContextualHelp.show();
        };

        vm.isWalkthroughShowing = sfWalkthrough.isShowing;

        vm.hideElement = sfWalkthrough.hideElement;
    }
})();
