(function () {
    'use strict';

    angular
        .module('salesflare.components.import.start', [])
        .component('sfImportStart', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/import/start/importstart.html',
            bindings: {
                type: '<',
                onDelete: '&'
            }
        });

    function controller($mdMedia, $state) {

        const vm = this;

        vm.$mdMedia = $mdMedia;

        vm.$onInit = function () {

            // Show import history + currently active imports
            vm.state = $state;

            return vm.getTypes();
        };

        vm.getTypes = function () {

            vm.types = [{ name: 'Accounts' }, { name: 'Contacts' }, { name: 'Opportunities' }];
            return vm.types;
        };

        vm.setType = function (type) {

            vm.type = type;

            vm.toUpload(vm.type, 'importUpload');
        };

        vm.toUpload = function (type, state) {

            vm.onDelete({ type, state });
        };
    }
})();
