(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('reportsService', reportsService);

    function reportsService(sfHttp, config, filterService) {

        this.create = function (report) {

            const clonedReport = angular.copy(report);

            if (report.filter && report.filter.rules) {
                clonedReport.filter = filterService.cleanAdvancedFilterForHttp(clonedReport.filter.rules);
            }

            return sfHttp.post(config.apiUrl + 'reports', clonedReport);
        };

        this.getData = function (getDataQueryParamsOriginal) {

            // Copy so our filter changes aren't reflected on passed object
            // This keeps the filter working on mobile, otherwise we start passing the cleaned version to the filter component, which it doesn't handle
            const getDataQueryParams = angular.copy(getDataQueryParamsOriginal);

            getDataQueryParams.filter = filterService.cleanAdvancedFilterForHttp(getDataQueryParams.filter);

            return sfHttp.get(config.apiUrl + 'reports/data', { params: getDataQueryParams });
        };

        this.getDimensions = function (entity) {

            return sfHttp.get(config.apiUrl + 'reports/dimensions/' + entity);
        };

        this.update = function (update) {

            return sfHttp.put(config.apiUrl + 'reports', update);
        };
    }
})();
