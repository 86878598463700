(function () {
    'use strict';

    angular
        .module('salesflare.components.credits', [])
        .component('sfCredits', {
            templateUrl: 'app-ajs/components/credits/credits.html',
            controller,
            controllerAs: 'vm'
        });

    function controller($state, $stateParams, $mdMedia, $mdDialog, sfCreditPackageConfirmationDialog, authentication, billing, plansService, model, utils) {

        const vm = this;

        let showTooltip = false;

        vm.$mdMedia = $mdMedia;
        vm.$state = $state;
        vm.creditPlans = [];
        vm.buttonText = {};
        vm.currentCreditPlan = {};
        vm.currency = '';
        vm.subscribedToCreditPlan = !!model.me.team.credit_package;

        vm.$onInit = function () {

            // You need an active subscription first to get a credit package
            if (!model.me.team.subscribed) {
                return $state.go('settings.billingSettings.overview');
            }

            init();
        };

        vm.showTooltip = function (show) {

            if (angular.isUndefined(show)) {
                return showTooltip;
            }

            showTooltip = show;
        };

        vm.changePlan = function (newPlan) {

            let action;

            if (!vm.subscribedToCreditPlan) {
                action = 'subscribe';
            }
            else if (newPlan.amount > vm.currentCreditPlan.amount) {
                action = 'upgrade';
            }
            else if (newPlan.amount < vm.currentCreditPlan.amount) {
                action = 'downgrade';
            }

            if (model.me.role.name !== 'Admin') {
                const adminAlert = $mdDialog.alert()
                    .title('Please ask an admin to ' + action)
                    .htmlContent('<div>You don\'t have the permissions to ' + action + '.</div><div>Please ask a team admin to ' + action + ' to your desired plan.</div>')
                    .multiple(true)
                    .ok('Got it');

                return $mdDialog.show(adminAlert);
            }

            return sfCreditPackageConfirmationDialog.show(vm.currentCreditPlan, newPlan, vm.subscribedToCreditPlan).then(function () {

                vm.changingPlans =  true;

                return billing.setCreditPackage(newPlan.size).then(() => {

                    return authentication.fetchMe().then(() => {

                        return vm.$state.go('settings.billingSettings.overview').then(() => {

                            let toastText;

                            switch (action) {
                                case 'subscribe':
                                    toastText = `Congratulations! You're now subscribed to the ${newPlan.name} credit package.`;
                                    break;
                                case 'upgrade':
                                    toastText = `Congratulations! You upgraded to the ${newPlan.name} credit package.`;
                                    break;
                                case 'downgrade':
                                    toastText = `You downgraded to the ${newPlan.name} credit package.`;
                                    break;
                            }

                            return utils.showSuccessToast(toastText);
                        });
                    });
                }).finally(() => {
                    vm.changingPlans = false;
                });
            });
        };

        function init() {

            const creditPlans = plansService.getCreditPlans();
            // Don't show XL plan
            delete creditPlans.extra_large;
            vm.creditPlans = creditPlans;
            vm.billingFrequency = model.me.team.billing_frequency;

            vm.currentCreditPlan = model.me.team.credit_package ? plansService.getCreditPlanBySize(model.me.team.credit_package) : null;
            prepareButtonText();
            prepareCurrency();
        }

        function prepareButtonText() {

            // The correct button needs to be disabled, also after down or upgrading
            for (const planName in vm.creditPlans) {
                const plan = vm.creditPlans[planName];
                vm.buttonText[planName] = getButtonText(plan);
            }
        }

        function getButtonText(plan) {

            if (!vm.subscribedToCreditPlan) {
                return 'Subscribe to ' + plan.name;
            }

            if (vm.currentCreditPlan.amount < plan.amount) {
                return 'Upgrade to ' + plan.name;
            }

            if (vm.currentCreditPlan.amount > plan.amount) {
                return 'Downgrade to ' + plan.name;
            }

            return 'Currently on ' + plan.name;
        }

        function prepareCurrency() {

            // Only show euro if it's the team currency else dollar
            if (model.me.team.billing_currency && model.me.team.billing_currency.html === '&#128;') {
                // Try to get the user location
                vm.currency = model.me.team.billing_currency.html;
            }
            else {
                vm.currency = '&#36;';
            }
        }
    }
})();
