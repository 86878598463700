import { Component, Inject, OnInit } from '@angular/core';

import { debounceTime, defer, distinctUntilChanged, from, map, Observable, Subject } from 'rxjs';

interface TaskSettingInput {
    description: string;
    label: string;
    value: any;
    inputChanged: Subject<number>
}

interface TaskSetting {
    id: string;
    name: string;
    title: string;
    subheader: string;
    description: string;
    icon: string;
    disabled: boolean;
    enabled: boolean;
    input?: TaskSettingInput;
    order: number;
    // Don't log as error, it maps this way from the API.
    // eslint-disable-next-line camelcase
    task_type: number;
}

@Component({
    selector: 'sfx-task-settings',
    templateUrl: './task-settings.component.html',
    styleUrls: ['./task-settings.component.scss']
})
export class TaskSettingsComponent implements OnInit {

    public settings$!: Observable<TaskSetting[]>;

    constructor(
        @Inject('tasksService') private tasksService: any,
        @Inject('teamService') private teamService: any,
        @Inject('modelService') private modelService: any
    ) {}

    public ngOnInit(): void {

        this.settings$ = defer(() => from(this.tasksService.getSettings()))
            .pipe(
                map((response: any) => response.data),
                map((settings: any) => {
                    return settings.filter((setting: any) => {
                        // Remove the suggested_contact and suggested_account settings from task settings screen for now
                        // Since they are not being planned in the nearby future.
                        if (setting.name !== 'suggested_contact' && setting.name !== 'suggested_account') {
                            return true;
                        }

                        return false;
                    });
                }),
                map<any, TaskSetting[]>((settings: any) => {

                    return settings.map((setting: any) => {

                        setting.enabled = !setting.disabled;

                        switch (setting.name) {
                            case 'meeting_notes':
                                setting.title = 'Meeting notes';
                                setting.subheader = '';
                                setting.icon = 'meeting-live';
                                setting.order = 1;
                                break;
                            case 'email_reply':
                                setting.title = 'Email needing a reply';
                                setting.subheader = '';
                                setting.icon = 'email';
                                setting.order = 2;
                                break;
                            case 'account_inactive':
                                setting.title = 'Account inactive for X days';
                                setting.subheader = '';
                                setting.icon = 'action-needed';
                                setting.order = 3;

                                if (this.modelService.me.is_admin) {
                                    setting.input = {
                                        description: 'Set the default # days to wait before an account becomes inactive for your team below. \n You can do this more in depth for each stage in the Configure Pipelines setting.',
                                        label: '# days',
                                        value: this.modelService.me.team.days_until_account_inactive,
                                        inputChanged: new Subject<number>().pipe(
                                            debounceTime(750),
                                            distinctUntilChanged()
                                        )
                                    };

                                    setting.input.inputChanged.subscribe((value: any) => {

                                        if (value !== null) {
                                            return this.teamService.update({ days_until_account_inactive: value }).then(() => {

                                                // We update the model here to ensure the user always sees the right value here (when leaving task settings and then coming back) so the user doesn't have to wait until the next /me call
                                                this.modelService.me.team.days_until_account_inactive = value;
                                            });
                                        }
                                    });
                                }

                                break;
                            // Case 'suggested_contact':
                            //     setting.title = 'Suggested contacts';
                            //     setting.subheader = '';
                            //     setting.icon = 'person';
                            //     break;
                            // case 'suggested_account':
                            //     setting.title = 'Suggested account';
                            //     setting.subheader = '';
                            //     setting.icon = 'accounts';
                            //     break;
                        }

                        return setting;
                    });
                }),
                map<TaskSetting[], TaskSetting[]>((settings: TaskSetting[]) => {
                    return settings.sort((a: TaskSetting, b: TaskSetting) => {

                        if (a.subheader === 'Coming soon...') {
                            return -1;
                        }
                        else {
                            return a.order === b.order ? 0 : (a.order > b.order ? 1 : -1);
                        }
                    });
                })
            );
    }

    public save(savedSetting: TaskSetting, settings: TaskSetting[]): void {

        savedSetting.disabled = !savedSetting.enabled;

        const settingsToSave = settings.map((setting: TaskSetting) => {

            return {
                id: setting.id,
                name: setting.name,
                icon: setting.icon,
                title: setting.title,
                subheader: setting.subheader,
                task_type: setting.task_type,
                disabled: !setting.enabled
            };
        });

        return this.tasksService.saveSettings(settingsToSave);
    }
}
