(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('conferenceService', conferenceService);

    function conferenceService(sfHttp, config) {

        this.get = function (id, ignoreLoadingBar) {

            const request = {};

            if (ignoreLoadingBar) {
                request.ignoreLoadingBar = true;
            }

            return sfHttp.get(config.apiUrl + 'conferences/' + id, request);
        };
    }
})();
