(function () {
    'use strict';

    angular
        .module('salesflare.components.createAccount', [])
        .component('sfCreateAccountFromPerson', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/person/createaccountfromperson.html',
            bindings: {
                person: '<'
            }
        });

    function controller($rootScope, $mdDialog, $mdMedia, $state, domainService, model, accounts, account, utils) {

        const vm = this;

        function createAccountFromContacts(contactArray) {

            const personDomain = vm.person?.email?.split('@')[1];

            return domainService.get({ domain: vm.person.domain }).then((response) => {

                const isNonCompanyDomain = response.data.is_non_company_email_domain;
                const isOwnDomain = personDomain === model.me.team.domain;

                if (isOwnDomain && !isNonCompanyDomain) {
                    return $mdDialog.show(utils.getAccountCreationConfirmDialog(isOwnDomain)).then(() => {

                        return account.create(contactArray).then((createResponse) => {

                            return showAccountToaster(createResponse.data);
                        });
                    });
                }

                return account.create(contactArray).then((createResponse) => {

                    return showAccountToaster(createResponse.data);
                });
            });
        }

        vm.createAccount = function (event) {

            event.currentTarget.disabled = true;

            if (!vm.person.domain) {
                vm.person.domain = '-';
            }

            return accounts.get(null, { domain: angular.toJson([vm.person.domain]) }, 100000, 0, null).then(function (response) {

                const suggestedAccounts = response.data;
                const contactArray = [{ name: vm.person.name, email: vm.person.email, id: vm.person.id }];

                if (!suggestedAccounts || suggestedAccounts.length === 0) {
                    return createAccountFromContacts(contactArray);
                }

                return $mdDialog.show({
                    clickOutsideToClose: true,
                    controller: 'SelectAccountDialogController',
                    templateUrl: 'partials/selectaccountdialog.html',
                    locals: {
                        suggestedAccounts
                    }
                }).then(function (selectedAccount) {

                    // Creating a new account
                    if (!selectedAccount) {
                        return createAccountFromContacts(contactArray);
                    }

                    // Adding contact to an existing account
                    return account.createContacts(selectedAccount.id, contactArray).then(function () {

                        return showAccountToaster(selectedAccount);
                    });
                });
            }).catch(function () {

                // We clicked outside of the dialog or encountered an error so we want to re-enable the button
                event.currentTarget.disabled = false;
            });
        };

        function showAccountToaster(acc) {

            return account.get(acc.id).then(function (response) {

                vm.person.account = response.data;

                $rootScope.$broadcast('contact:account_created', vm.person);

                return utils.showSuccessToast('The account has been created.', 'VIEW', 10000).then(function (toastResponse) {

                    if (toastResponse === 'VIEW') {
                        if ($mdMedia('gt-sm')) {
                            return $state.go('accounts.account.feed', { id: acc.id }, { reload: false });
                        }

                        return $state.go('accounts.account.info', { id: acc.id });
                    }
                });
            });

        }
    }
}());
