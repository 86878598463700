(function () {
    'use strict';

    angular
        .module('salesflare.components.files.upload', [])
        .component('sfFilesUpload', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/files/upload/upload.html',
            bindings: {
                account: '<',
                refreshData: '&'
            }
        });

    function controller($mdMedia, $exceptionHandler, account) {

        const vm = this;

        vm.$onInit = function () {

            vm.$mdMedia = $mdMedia;
        };

        vm.upload = function (file) {

            if (!file || file.$error) {
                return;
            }

            return account.uploadFile(vm.account, file).then(function (id) {

                return vm.onFileAdded(null, id);
            });
        };

        vm.onFileAdded = function (err, id) {

            if (err) {
                $exceptionHandler(err);
                return;
            }

            if (id && vm.refreshData) {
                return vm.refreshData({ fileAdded: true });
            }
        };
    }
})();
