(function () {
    'use strict';

    angular
        .module('salesflare.components.funnelAnalysis', [])
        .component('sfFunnelAnalysis', {
            templateUrl: 'app-ajs/components/dashboards/predefinedReports/funnelAnalysis/funnelAnalysis.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                data: '<'
            }
        });

    function controller(model, pipelines) {

        const vm = this;

        vm.currentPipeline = undefined;

        let pipelinesList = [];

        ////////////////

        vm.$onInit = function () {

            return pipelines.getViewable().then(function (response) {

                pipelinesList = response.pipelines;
                const currentPipelineId = store.get('current_pipeline_' + model.me.id);

                vm.currentPipeline = pipelinesList.find(function (pipeline) {

                    return pipeline.id === currentPipelineId;
                });
            });
        };

        vm.$onChanges = function (changes) {

            if (!changes || !changes.data || !changes.data.currentValue) {
                return;
            }

            // Show empty state
            if (vm.data.stages.length === 0 || (pipelinesList.length > 1 && vm.currentPipeline === 'all')) {
                vm.data.no_data = true;
                vm.data.max_opportunities_in_stage = 46;
                vm.data.stages = [
                    {
                        name: 'lead',
                        amount: 46,
                        average_duration_in_days: 9,
                        success_rate: (27 / 46) * 100,
                        win_rate: (8 / 46) * 100,
                        color: '255,68,68',
                        order: 1
                    },
                    {
                        name: 'contacted',
                        amount: 27,
                        average_duration_in_days: 4,
                        success_rate: (18 / 27) * 100,
                        win_rate: (8 / 27) * 100,
                        color: '250,187,61',
                        order: 2
                    },
                    {
                        name: 'qualified',
                        amount: 18,
                        average_duration_in_days: 5,
                        success_rate: (12 / 18) * 100,
                        win_rate: (8 / 18) * 100,
                        color: '253,221,101',
                        order: 3
                    },
                    {
                        name: 'proposal made',
                        amount: 12,
                        average_duration_in_days: 1,
                        success_rate: (8 / 12) * 100,
                        win_rate: (8 / 12) * 100,
                        color: '203,223,50',
                        order: 4
                    },
                    {
                        name: 'won',
                        amount: 8,
                        average_duration_in_days: 2,
                        color: '153,204,0',
                        order: 5,
                        won: true
                    }
                ];
            }
        };
    }
})();
