(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditAccountController', EditAccountController);

    function EditAccountController($rootScope, $scope, $stateParams, $state, $q, $mdMedia, $mdDialog, $timeout, domainService, events, account, accounts, companies, company, tagsService, sfDiscardDialog, customfields, utils, validateService, fileService, model) {

        $scope.updating = false;
        $scope.account = null;
        $scope.selectedCompanyObject = {}; // This is an object to make sure we can use it as a model inside a ng-if/child scope
        $scope.currentRoleName = model.me.role.name;

        const removedTags = [];
        let clearDeleteTagsWatcher = null;
        let accountSearchTimeout = null;
        let onPhoneNumberChangedTimeout = null;
        let onEmailChangedTimeout = null;
        const filesToDelete = [];
        let isNonCompanyDomain = false;
        let originalWebsite = null;

        if ($stateParams.mode === 'create') {
            $scope.mode = $stateParams.mode;
            $scope.title = 'New Account';
            $scope.saveLabel = 'CREATE';

            $scope.account = {
                name: null,
                domain: null,
                size: null,
                sector: null,
                website: null,
                description: null,
                phone_numbers: [{}],
                social_profiles: [{}],
                email_addresses: [{}],
                addresses: [{}],
                tags: [],
                custom: {},
                picture: 'https://lib.salesflare.com/avatars/avatar_company_default_' + (Math.floor(Math.random() * 6) + 1) + '.png'
            };

            loadCustomFields();
        }
        else if ($stateParams.mode === 'edit') {
            $scope.mode = $stateParams.mode;
            $scope.title = 'Edit Account';
            $scope.saveLabel = 'SAVE';

            get();
        }

        $scope.$watch('selectedCompanyObject.selectedCompany', function (newValue, oldValue) {

            if (newValue) {
                $scope.account.name = newValue.name;

                // If clearbit company create company
                if (newValue.logo) {
                    if (!$scope.account.picture.startsWith('data:image/png;base64')) {
                        $scope.account.picture = newValue.logo;
                    }

                    $scope.account.website = 'https://' + newValue.domain;
                    return company.create(newValue);
                }
                else if (oldValue && !$scope.account.picture.startsWith('data:image/png;base64')) {
                    delete $scope.account.picture;
                }
            }
        });

        $scope.$watch('account.name', function (newValue) {

            if (newValue && $scope.mode === 'create') {
                return $scope.searchAccounts();
            }
        });

        $scope.onWebsiteChange = () => {

            if (!$scope.account.website) {
                return;
            }

            return domainService.get({ url: $scope.account.website }).then((response) => {

                if (response.data.invalid_domain) {
                    isNonCompanyDomain = false;
                }

                isNonCompanyDomain = response.data.is_non_company_email_domain;
            }).catch(() => {

                isNonCompanyDomain = false;
            });
        };

        $scope.resetSelectedCompany = function (text) {

            $scope.selectedCompanyObject.selectedCompany = null;
            $scope.account.name = text;
        };

        $scope.back = function () {

            if ($scope.accountForm.$dirty) {
                return sfDiscardDialog.show($scope.mode === 'create', 'account').then(close);
            }
            else {
                return close();
            }
        };

        function close() {

            if ($mdMedia('gt-sm')) {
                return $mdDialog.hide(false);
            }

            return $rootScope.back();
        }

        $scope.delete = function () {

            if ($scope.mode === 'create') {
                if ($mdMedia('gt-sm')) {
                    return $mdDialog.hide(false);
                }

                return $rootScope.back();
            }
            else {
                const confirm = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .textContent('Are you sure you want to delete the account?')
                    .ok('Yes')
                    .cancel('No');

                return $mdDialog.show(confirm).then(function () {

                    $scope.updating = true;

                    return account.delete($scope.account).then(function () {

                        if ($mdMedia('gt-sm')) {
                            $mdDialog.hide(false);
                        }

                        if ($rootScope.history[$rootScope.history.length - 1]) {
                            return $state.go('accounts', $stateParams, { reload: true });
                        }
                    });
                }, angular.noop);
            }
        };

        function save(enrichAccount = false) {

            $scope.updating = true;

            const acc = angular.copy($scope.account);

            // Filter out empty addresses
            acc.addresses = acc.addresses.filter(function (address) {

                return Object.keys(address).length > 0;
            });

            // Filter out empty email addresses
            acc.email_addresses = acc.email_addresses.filter(function (email) {

                return !!email.email || (email._dirty && email.id);
            });

            // Tag phone numbers that were emptied as archived
            acc.phone_numbers.forEach(function (pn) {

                if (pn.id && pn._dirty && !pn.number) {
                    delete pn.number;
                    pn.archived = true;
                }

                return pn;
            });

            // Filter out empty phone numbers
            acc.phone_numbers = acc.phone_numbers.filter(function (pn) {

                return !!pn.number || (pn._dirty && pn.id);
            });

            // Tag social profiles that were emptied as archived
            acc.social_profiles.forEach(function (sp) {

                if (sp.id && sp._dirty && !sp.url) {
                    delete sp.url;
                    sp.archived = true;
                }

                return sp;
            });

            // Filter out empty social profiles
            acc.social_profiles = acc.social_profiles.filter(function (sp) {

                return !!sp.url || (sp._dirty && sp.id);
            });

            $scope.customFields.forEach(function (customField) {

                if (customField.type.type === 'select' && !customField.required) {
                    if (!acc.custom[customField.api_field]?.id) {
                        acc.custom[customField.api_field] = null;
                    }
                }

                if (customField.type.type === 'date') {
                    if (customField.predefined_customfield) {
                        acc[customField.api_field] = utils.localDateObjectToUTCDateObject(acc[customField.api_field]);
                    }
                    else if ($scope.account.custom && Object.keys($scope.account.custom).length > 0) {
                        acc.custom[customField.api_field] = utils.localDateObjectToUTCDateObject(acc.custom[customField.api_field]);
                    }
                }
            });


            if ($scope.mode === 'create') {
                return account.create(acc).then(function (response) {

                    return deleteFiles().then(function () {

                        if ($mdMedia('gt-sm')) {
                            $mdDialog.hide(false);
                            return $state.go('accounts.account.feed', { id: response.data.id }, { reload: true });
                        }

                        return $state.go('accounts.account.info', { id: response.data.id });
                    });
                }).catch(function () {

                    $scope.updating = false;
                });
            }

            if (!$scope.accountForm.$dirty) {
                if ($mdMedia('gt-sm')) {
                    return $mdDialog.hide(false);
                }

                return $state.go('accounts.account.info', { id: $scope.account.id });
            }

            // Unbinding the tag watcher, this way it'll not call the listener when removed tag are concatenated
            // on $scope.account.tags
            if (clearDeleteTagsWatcher) {
                clearDeleteTagsWatcher();
            }

            if (removedTags.length > 0) {
                acc.tags = [...acc.tags, ...removedTags];
            }

            // DEPRECATED
            acc.addresses.forEach(function (address) {

                delete address.region;
            });

            return account.update(acc, { enrichAccount }).then(function () {

                return deleteFiles().then(function () {

                    if ($mdMedia('gt-sm')) {
                        $rootScope.$broadcast(events.account.updated);
                        return $mdDialog.hide();
                    }

                    return $state.go('accounts.account.info', { id: $scope.account.id });
                });
            }).catch(function () {

                $scope.updating = false;
            });
        }

        $scope.save = function () {

            if (!$scope.accountForm.$valid) {
                return;
            }

            const accountHasOwnDomain = $scope.account.website && $scope.account.website.includes(model.me.team.domain);
            const accountHasNonCompanyEmail = $scope.account.website && isNonCompanyDomain;

            if (accountHasOwnDomain || accountHasNonCompanyEmail) {
                return $mdDialog.show(utils.getAccountCreationConfirmDialog(accountHasOwnDomain, accountHasNonCompanyEmail)).then(save);
            }

            if ($stateParams.mode === 'edit' && originalWebsite && $scope.account.website !== originalWebsite) {
                const confirm = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title('Delete and re-enrich account info?')
                    .htmlContent('Do you want to re-enrich this account with information linked to its new website?<br>This will permanently delete all existing info from the account except its name and custom field values.')
                    .ok('Yes')
                    .cancel('No');

                return $mdDialog.show(confirm).then(function () {

                    return save(true);
                });
            }

            return save();
        };

        $scope.getCompany = function (search) {

            let results = [];

            return companies.get(search).then(angular.bind(this, function (response) {

                results = [...results, ...response.data];
                // Slice so the results always shows the manual create suggestion
                results = results.slice(0, 4);

                results.push({ name: search });

                return results;
            }));
        };

        $scope.activeItems = function (items) {

            if (items) {
                return items.filter(function (item) {

                    return (!item.archived);
                });
            }
        };

        $scope.deleteItem = function (items, item) {

            if (item.id) {
                $scope.setDirty(item);

                item.archived = true;
            }
            else {
                items.splice(items.indexOf(item), 1);
            }
        };

        $scope.setDirty = function (item) {

            if (item) {
                item._dirty = true;
                $scope.accountForm.$setDirty();
            }
        };

        $scope.addItem = function (items) {

            const item = {};

            if ($scope.mode !== 'create') {
                item._dirty = true;
            }

            return items.push(item);
        };

        $scope.addTag = function (addedTag) {

            if (addedTag && addedTag.name) {
                for (let i = 0; i < removedTags.length; ++i) {
                    if (addedTag.name === removedTags[i].name) {
                        removedTags.splice(i, 1);
                        break;
                    }
                }
            }

            return $scope.setDirty(addedTag);
        };

        $scope.searchTags = function (queryString) {

            if (!queryString || queryString === '') {
                return [];
            }

            return $q(function (resolve) {

                return tagsService.get({ search: queryString }).then(function (response) {

                    const filteredTags = response.data.filter(function (item) {

                        return !$scope.account.tags.some(function (t) {

                            return item.name === t.name;
                        });
                    });

                    // eslint-disable-next-line no-var, @hapi/no-var
                    for (var i = 0; i < filteredTags.length; ++i) {
                        if (filteredTags[i].name.toLowerCase() === queryString.toLowerCase()) {
                            const matchedTag = filteredTags[i];

                            filteredTags[i] = filteredTags[0];
                            filteredTags[0] = matchedTag;
                            break;
                        }
                    }

                    if (i >= filteredTags.length || filteredTags.length === 0) {
                        filteredTags.unshift({ name: queryString });
                    }

                    return resolve(filteredTags);
                });
            });
        };

        $scope.searchAccounts = function () {

            $scope.accountForm.$setDirty();

            $timeout.cancel(accountSearchTimeout);

            let accountFormNameField;

            // Get the right form name field to set the validity of depending on whether we are creating or editing an account
            if ($scope.mode === 'create') {
                accountFormNameField = $scope.accountForm.autocompleteName; // Autocomplete element on edit
            }
            else {
                accountFormNameField = $scope.accountForm.name;
            }

            accountSearchTimeout = $timeout(function () {
                accountFormNameField.$setValidity('unique', true);

                if ($scope.account.name) {
                    return accounts.get(null, { name: $scope.account.name }).then(function (response) {

                        if ($scope.account.id) {
                            // We are editing an existing account so we filter out the current account id from the response if present
                            const filteredAccountResponse = response.data.filter(function (item) {

                                return item.id !== $scope.account.id;
                            });

                            if (filteredAccountResponse.length > 0) {
                                accountFormNameField.$setValidity('unique', false);
                            }
                        }
                        else if (response.data.length > 0) {
                            accountFormNameField.$setValidity('unique', false);
                        }
                    });
                }
            }, 750);
        };

        $scope.onPhoneNumberChanged = function (phoneNumber, $index) {

            $scope.setDirty(phoneNumber);

            $timeout.cancel(onPhoneNumberChangedTimeout);

            if (!phoneNumber.number) {
                return $scope.accountForm['phoneNumber' + $index].$setValidity('phoneNumber', true);
            }

            onPhoneNumberChangedTimeout = $timeout(function () {

                return validateService.phoneNumber(phoneNumber.number).then(function () {

                    $scope.accountForm['phoneNumber' + $index].$setValidity('phoneNumber', true);
                }).catch(function () {

                    $scope.accountForm['phoneNumber' + $index].$setValidity('phoneNumber', false);
                });
            }, 250);
        };

        $scope.onEmailChanged = function (email, $index) {

            $scope.setDirty(email);

            $timeout.cancel(onEmailChangedTimeout);

            if (!email.email) {
                return $scope.accountForm['email' + $index].$setValidity('email', true);
            }

            onEmailChangedTimeout = $timeout(function () {

                if ($scope.accountForm.$error.email) {
                    const foundErroringEmailInput = $scope.accountForm.$error.email.find(function (e) {
                        return e.name === 'email' + $index;
                    });

                    $scope.accountForm['email' + $index].$setValidity('email', !(foundErroringEmailInput));
                }

                $scope.accountForm['email' + $index].$setValidity('email', true);
            }, 250);
        };

        $scope.editPicture = function () {

            return $mdDialog.show({
                controller: 'EditPictureDialogController',
                templateUrl: 'partials/editpicturedialog.html',
                multiple: true,
                scope: $scope,
                preserveScope: true,
                locals: {
                    picture: $scope.account.picture
                },
                bindToController: true,
                fullscreen: true,
                clickOutsideToClose: true
            }).then(function (croppedImageDataURI) {

                if (croppedImageDataURI) {
                    $scope.account.picture = croppedImageDataURI;
                    $scope.accountForm.$setDirty();
                }
            });
        };

        $scope.onFilesChange = function () {

            return $scope.accountForm.$setDirty();
        };

        $scope.onFilesDelete = function ($event) {

            filesToDelete.push($event.fileId);
        };

        $scope.getNotPredefinedCustomFields = function () {

            if (!$scope.customFields) {
                return [];
            }

            return $scope.customFields.filter(function (field) {

                return !field.predefined_customfield;
            });
        };

        $scope.onNewCustomFieldOption = function (customField) {

            return customfields.updateAccountField(customField).then(function () {

                const customFieldId = customField.id;

                return customfields.getAllOptions(customFieldId, 'account').then(function (response) {

                    const foundCustomField = $scope.customFields.find((function (field) {

                        return field.id === customFieldId;
                    }));

                    foundCustomField.options = response.data.map(function (option) {

                        return { id: option.id, name: option.name, order: option.order };
                    });
                });
            });
        };

        function get() {

            return account.get($stateParams.id).then(function (response) {

                response.data.phone_numbers = response.data.phone_numbers.length > 0 ? response.data.phone_numbers : [{}];
                response.data.email_addresses = response.data.email_addresses.length > 0 ? response.data.email_addresses : [{}];
                response.data.social_profiles = response.data.social_profiles.length > 0 ? response.data.social_profiles : [{}];
                response.data.addresses = response.data.addresses.length > 0 ? response.data.addresses : [{}];

                // File creation_date is in UTC, because it's passed as a string
                if (response.data.custom && response.data.custom.files && response.data.custom.files.length > 0) {
                    response.data.custom.files.forEach(function (file) {

                        if (file.creation_date) {
                            file.creation_date = new Date(file.creation_date);
                        }
                    } );
                }

                $scope.account = response.data;
                $scope.selectedCompanyObject.selectedCompany = $scope.account;

                originalWebsite = $scope.account.website;

                if (!$scope.account.custom) {
                    $scope.account.custom = {};
                }

                loadCustomFields();

                clearDeleteTagsWatcher = $scope.$watch('account.tags', deleteTag, true);
            }, $scope.back);
        }

        function loadCustomFields() {

            return customfields.getAccountFields(false).then(function (response) {

                $scope.customFields = response.data;

                utils.setCustomFieldsOnEntity($scope.account, $scope.customFields);
            });
        }

        function deleteTag(newValue, oldValue) {

            if ($scope.mode !== 'create' && oldValue.length > newValue.length) {
                for (const [i, element] of oldValue.entries()) {
                    if (!element._dirty
                        && (angular.isUndefined(newValue[i]) || element.name !== newValue[i].name)) {
                        element._deleted = true;

                        $scope.setDirty(element);
                        removedTags.push(element);

                        break;
                    }
                }
            }
        }

        function deleteFiles() {

            return $q.all(filesToDelete.map(function (fileToDelete) {

                return fileService.deleteFile(fileToDelete);
            }));
        }
    }
})();
