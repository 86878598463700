(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ManageSavedFiltersDialogController', ManageSavedFiltersDialogController);

    function ManageSavedFiltersDialogController($mdDialog, $mdMedia, $scope, $timeout, filterService, utils, savedFiltersService, savedFilterService) {

        const vm = this;
        let thread = null;
        let allSavedFilters = vm.savedFilters;

        vm.$mdMedia = $mdMedia;

        vm.back = $mdDialog.cancel;

        vm.duplicateSavedFilter = function (savedFilter) {

            return savedFilterService.get(savedFilter.id, vm.entity).then(function (response) {

                const saveFilterDialogConfig = $mdDialog.saveFilterDialog({
                    locals: {
                        savedFilter: {
                            name: ('Copy of ' + savedFilter.name),
                            shared: savedFilter.shared
                        },
                        dialogTitle: 'Duplicate filter',
                        showUpdateAndCreateChoice: false,
                        entity: vm.entity
                    },
                    fullscreen: !$mdMedia('gt-sm')
                });

                return $mdDialog.show(saveFilterDialogConfig).then(function (savedFilterConfig) {

                    const filterToSave = {
                        name: savedFilterConfig.name,
                        shared: savedFilterConfig.shared,
                        filter_config: {
                            condition: 'AND',
                            rules: filterService.cleanAdvancedFilterForHttp(response.data.filter_config.rules)
                        }
                    };

                    return savedFiltersService.create(vm.entity, filterToSave).then(function () {

                        getSavedFilters();

                        return utils.showSuccessToast('Saved filter duplicated.');
                    });
                });
            });
        };

        vm.editSavedFilter = function (savedFilter) {

            const saveFilterDialogConfig = $mdDialog.saveFilterDialog({
                locals: {
                    savedFilter: {
                        id: savedFilter.id,
                        name: savedFilter.name,
                        shared: savedFilter.shared
                    },
                    dialogTitle: 'Edit filter',
                    showUpdateAndCreateChoice: false,
                    entity: vm.entity
                },
                fullscreen: !$mdMedia('gt-sm')
            });

            return $mdDialog.show(saveFilterDialogConfig).then(function (savedFilterConfig) {

                const filterToSave = {
                    name: savedFilterConfig.name,
                    shared: savedFilterConfig.shared
                };

                return savedFilterService.update(savedFilter.id, vm.entity, filterToSave).then(function () {

                    getSavedFilters();

                    return utils.showSuccessToast('Saved filter duplicated.');
                });
            });
        };

        vm.deleteSavedFilter = function (savedFilter) {

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .textContent('Are you sure you want to delete this saved filter?')
                .ok('Yes')
                .cancel('No');

            return $mdDialog.show(confirm).then(function () {

                return savedFilterService.delete(savedFilter.id, vm.entity).then(function () {

                    getSavedFilters();

                    return utils.showSuccessToast('Saved filter deleted.');
                });
            });
        };

        $scope.$watch('vm.search', function (newValue, oldValue) {

            if (newValue !== oldValue) {
                $timeout.cancel(thread);

                thread = $timeout(function () {

                    if (!vm.search) {
                        vm.savedFilters = allSavedFilters;
                        return;
                    }

                    vm.savedFilters = allSavedFilters.filter(function (savedFilter) {

                        return (savedFilter.name && savedFilter.name.toLowerCase().includes(vm.search.toLowerCase()));
                    });
                });
            }
        });

        function getSavedFilters() {

            return savedFiltersService.get(vm.entity).then(function (response) {

                allSavedFilters = response.data;

                if (vm.search) {
                    vm.savedFilters = allSavedFilters.filter(function (savedFilter) {

                        return (savedFilter.name && savedFilter.name.toLowerCase().includes(vm.search.toLowerCase()));
                    });
                }
                else {
                    vm.savedFilters = allSavedFilters;
                }
            });
        }
    }
})();
