(function () {
    'use strict';

    angular
        .module('salesflare.components.tags', [])
        .component('sfTags', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/tags/tags.html',
            bindings: {
                tags: '<',
                showEditButton: '<',
                disableEditButton: '<',
                disabledEditButtonTooltipText: '<',
                onEdit: '&'
            }
        });

    function controller() {

        const vm = this;

        vm.edit = function () {

            return vm.onEdit();
        };
    }
}());
