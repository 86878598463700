import { Validator, NG_VALIDATORS, FormControl, AbstractControl } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
    standalone: true,
    selector: '[match]',
    providers: [
        // eslint-disable-next-line no-use-before-define
        { provide: NG_VALIDATORS, useExisting: MatchValidator, multi: true }
    ]
})
export class MatchValidator implements Validator {

    @Input() match!: AbstractControl<any, any>;

    validate(control: FormControl) {

        if (this.match) {
            const subscription: Subscription = this.match.valueChanges.subscribe(() => {
                control.updateValueAndValidity();
                subscription.unsubscribe();
            });
        }


        if (control.value && control.value !== this.match.value) {
            return { 'match': true };
        }

        return null;
    }
}
