(function () {
    'use strict';

    angular
        .module('salesflare')
        .factory('importprogress', importProgressFactory);

    function importProgressFactory($interval, $state, imports, statusBarService) {

        let stop;
        let inProgressImports = [];
        const completedImports = [];
        const importBarShowing = false;

        return {
            startPolling
        };

        function stopPolling() {

            if (stop) {
                $interval.cancel(stop);
                stop = undefined;
            }
        }

        function startPolling() {

            if (!stop) {
                doPoll();
                stop = $interval(doPoll, 5 * 1000);
            }
        }

        function onClose() {

            const nextImportStatusToShow = completedImports.pop();

            if (!nextImportStatusToShow) {
                return;
            }

            return showStatusBarForImport(nextImportStatusToShow);
        }

        function showStatusBarForImport(importObject) {

            const statusBar = {
                type: 'import',
                showSpinner: false,
                canClose: true,
                onClose
            };

            if (importObject.failed_records === 0) {
                statusBar.text = 'Awesome! Your import was 100% successful';
                delete statusBar.linkText;
                delete statusBar.linkTrackId;
                delete statusBar.onLinkClick;
                statusBar.class = 'success';
            }
            else {
                statusBar.text = importObject.failed_records + ' record' + (importObject.failed_records > 1 ? 's' : '') + ' could not be imported.';
                statusBar.linkText = 'Check errors';
                statusBar.linkTrackId = 'statusBar-errors';
                statusBar.onLinkClick = function () {

                    return $state.go('settings.import.' + importObject.type);
                };

                statusBar.class = 'error';
            }

            return statusBarService.show(statusBar);
        }

        function doPoll() {

            return imports.getImports().then(function (response) {

                const importsList = response.data;
                const inProgressAfterPolling = importsList.filter(function (importObject) {

                    return importObject.status === 'in_process';
                });
                const completeAfterPolling = importsList.filter(function (importObject) {

                    return importObject.status === 'complete';
                });

                inProgressImports.forEach(function (inProgressImportBeforePolling) {

                    const completedImport = completeAfterPolling.find(function (completeImportAfterPolling) {

                        return completeImportAfterPolling.id === inProgressImportBeforePolling.id;
                    });

                    if (!completedImport) {
                        return;
                    }

                    if (importBarShowing) {
                        return completedImports.push(completedImport);
                    }

                    return showStatusBarForImport(completedImport);
                });

                inProgressImports = inProgressAfterPolling;

                if (!inProgressImports || inProgressImports.length === 0) {
                    return stopPolling();
                }
            });
        }
    }
})();
