<div class="plans-container">
    <div class="sf-plans" data-layout="column">
        <div data-layout="row">
            <div class="layout" data-layout="row" data-layout-lt-lg="column">
                <div class="plan" *ngFor="let item of plans | keyvalue : customOrder">
                    <div class="header" [ngClass]="{'pro-plan': item.value.name === 'Pro'}">
                        <div *ngIf="item.value.name === 'Pro'" class="popular-bar">
                            <span>most popular</span>
                        </div>
                        <h3>{{item.value.name}}</h3>
                        <h3><span class="currency" [innerHTML]="currency"></span>{{item.value.price.annually.pricePerMonth}}</h3>
                        <span>per user / month (billed annually)</span>
                        <span class="monthly-price-span" *ngIf="item.value.name !== 'Enterprise'"><span class="currency" [innerHTML]="currency"></span>{{item.value.price.monthly.pricePerMonth}} billed monthly</span>
                        <span class="monthly-price-span" *ngIf="item.value.name === 'Enterprise'"><a id="intercom-chat-button" href="mailto: support@salesflare.com" target="_blank">Contact us</a> (from 5 users)</span>
                    </div>
                    <ul class="feature-list">
                        <div class="summarization">{{ item.value.featureSummary }}:</div>
                        <li *ngFor="let feature of item.value.features">
                            <mat-icon
                                    *ngIf="media.isActive('gt-md')"
                                    fontSet="salesflare" fontIcon="sf-icon-check" aria-label="check" class="icon-20 inline-icon check"
                            >
                            </mat-icon>
                            <mat-icon
                                    *ngIf="!media.isActive('gt-md')"
                                    fontSet="salesflare" fontIcon="sf-icon-check" aria-label="check" class="icon-24 inline-icon check"
                            >
                            </mat-icon>
                            <span class="feature" [matTooltip]="feature.description">
                                <span>{{feature.name.slice(0, (feature.name.length - (feature.lastWordName.length)))}}</span>
                                <span class="icon-group">
                                    {{ feature.lastWordName }}
                                    <mat-icon
                                            fontSet="salesflare" fontIcon="sf-icon-info" aria-label="info" class="icon-16 inline-icon failed info"
                                    ></mat-icon>
                                </span>
                            </span>
                        </li>
                    </ul>
                    <div class="button-container" style="text-align: center;">
                        <button mat-button class="button" (click)="changePlan(item.value)" [hidden]="item.value.name === 'Enterprise'" [disabled]="(currentPlan.id === item.value.id) && (subscribedOrNonRestrictedSumo || false)">
                            <span> {{ item.value.buttonText }} </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <p *ngIf="!subscribedOrNonRestrictedSumo && discount" style="text-align: center;">* Your {{ discount }} discount will be applied on checkout.</p>
    </div>
</div>
