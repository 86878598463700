(function () {
    'use strict';

    angular
        .module('salesflare.components.notifications', [])
        .component('sfNotifications', {
            templateUrl: 'app-ajs/components/notification/notificationPanel.html',
            controller: 'NotificationsController',
            controllerAs: 'vm'
        });
})();
