(function () {
    'use strict';

    angular
        .module('salesflare.components.reorderDashboardsDialog', [])
        .factory('sfReorderDashboardsDialog', sfReorderDashboardsDialog);

    function sfReorderDashboardsDialog($mdDialog, $mdMedia) {

        return {
            show
        };

        function show(dashboards) {

            return $mdDialog.show({
                clickOutsideToClose: true,
                escapeToClose: true,
                multiple: false,
                hasBackdrop: true,
                controller: 'ReorderDashboardsDialogController',
                controllerAs: 'vm',
                templateUrl: 'app-ajs/components/dashboards/reorderDashboardsDialog/reorderDashboardsDialog.html',
                locals: {
                    dashboards
                },
                bindToController: true,
                fullscreen: !$mdMedia('gt-sm'),
                onShowing: function (scope, element) {

                    element.children('md-dialog').addClass('reorder-dashboards-dialog');
                }
            });
        }
    }
})();
