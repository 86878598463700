import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MediaService } from '@core/services/media.service';

@Component({
    selector: 'sfx-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    host: {
        'class': 'sfx-toolbar'
    }
})
export class ToolbarComponent {

    private _disableDivider = true;
    private _disableBack = false;
    private _dialogToolbar = false;
    private _disableWatermark = false;
    private _disableContentPadding = false;

    private _color: 'primary' | 'accent' | 'warn' | null;

    private _height: string;
    private _width = '100%';

    constructor(
        public media: MediaService,
        @Inject('rootScope') private rootScope: any
    ) {}

    public back(): void {

        // If a custom close function is provided, use it instead of the default back function
        if (this.closeEvent.observed) {
            this.close();
        }
        else {
            this.rootScope.back();
        }
    }

    @Input()
    get disableContentPadding(): boolean {
        return this._disableContentPadding;
    }
    set disableContentPadding(value: boolean | string) {
        this._disableContentPadding = typeof value === 'boolean' ? value : !!(value === '' || value);
    }

    @Output() closeEvent = new EventEmitter<boolean>();

    public close(): void {
        this.closeEvent.emit(true);
    }

    @Input()
    get disableWatermark(): boolean {
        return this._disableWatermark;
    }
    set disableWatermark(value: boolean | string) {
        this._disableWatermark = typeof value === 'boolean' ? value : !!(value === '' || value);
    }

    @Input()
    get disableBack(): boolean {
        return this._disableBack;
    }
    set disableBack(value: boolean | string) {
        this._disableBack = typeof value === 'boolean' ? value : !!(value === '' || value);
    }

    @Input()
    get disableDivider(): boolean {
        return this._disableDivider;
    }
    set disableDivider(value: boolean | string) {
        this._disableDivider = typeof value === 'boolean' ? value : !!(value === '' || value);
    }

    @Input()
    get enableDivider(): boolean {
        return !this._disableDivider;
    }
    set enableDivider(value: boolean | string) {
        this._disableDivider = !(typeof value === 'boolean' ? value : !!(value === '' || value));
    }

    @Input()
    get dialogToolbar(): boolean {
        return this._dialogToolbar;
    }
    set dialogToolbar(value: boolean | string) {
        this._dialogToolbar = typeof value === 'boolean' ? value : !!(value === '' || value);
    }

    @Input()
    get height(): number | string {
        return this._height;
    }
    set height(value: number | string) {
        if (value) {
            // eslint-disable-next-line angular/typecheck-string
            this._height = typeof value === 'string' ? value : `${value}px`;
        }
    }

    @Input()
    get width(): number | string {
        return this._width;
    }
    set width(value: number | string) {
        if (value) {
            // eslint-disable-next-line angular/typecheck-string
            this._width = typeof value === 'string' ? value : `${value}px`;
        }
    }

    @Input()
    get color(): 'primary' | 'accent' | 'warn' | '' {
        return this._color || '';
    }
    set color(value: 'primary' | 'accent' | 'warn' | '') {
        if (value === '') {

            this._color = null;
        }

        if (value) {
            this._color = value;
        }
    }
    public get Theme(): 'primary' | 'accent' | 'warn' {
        if (this.color) {
            return this.color;
        }
        else {
            return this.media.isActive('gt-sm') && (!this.dialogToolbar || this.disableWatermark) ? 'primary' : 'accent';
        }
    }
}
