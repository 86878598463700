import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlanFlagComponent } from './plan-flag.component';
import { MaterialModule } from '@shared/bundles/material/material.module';

@NgModule({
    declarations: [
        PlanFlagComponent
    ],
    exports: [
        PlanFlagComponent
    ],
    imports: [
        CommonModule,
        MaterialModule
    ],
    providers: [
        {
            provide: 'upgradeDialogService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('sfUpgradeDialog');
            }
        }
    ]
})
export class PlanFlagModule {}
