(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('imports', importService);

    function importService($q, $timeout, sfSetupPanel, sfHttp, config, Upload, model, $window) {

        const self = this;

        /**
         * Start the importing process via the API
         *
         * @param {Number} importId
         * @returns {Promise}
         */
        this.start = function (importId) {

            const url = config.apiUrl + 'imports/' + importId + '/start';

            return sfHttp.post(url).then(function (response) {

                if (model.me && model.me.team.subscribed) {
                    $timeout(sfSetupPanel.updateSetupSteps, 300);
                }

                return $q.resolve(response);
            });
        };

        /**
         * Uploads a file via the API
         *
         * @param {String} type
         * @param {Object} file
         * @returns {Promise}
         */
        this.upload = function (type, file) {

            const creationUrl = config.apiUrl + 'imports';
            const json = {
                type
            };

            return sfHttp.post(creationUrl, json).then(function (response) {

                const importId = response.data.id;

                return Upload.upload({
                    url: config.apiUrl + 'imports/' + importId + '/upload',
                    data: {
                        file
                    }
                })
                    .then(function () {

                        return $q.resolve(importId);
                    })
                    .catch(function () {

                        // If uploading fails delete the import
                        return self.delete(importId).then(function () {

                            return $q.reject();
                        });
                    });
            });
        };

        this.getSkipFile = function (importId) {

            let downloadUrl =  config.apiUrl + 'imports/' + importId + '/skip';

            if (model.authStrategy === 'bearer') {

                downloadUrl += '?access_token=' + model.getToken();
            }

            $window.open(downloadUrl, '_blank', 'noopener');
        };

        this.update = function (importId, data) {

            return sfHttp.put(config.apiUrl + 'imports/' + importId, data);
        };

        this.delete = function (importId, deleteUpdated) {

            let url = config.apiUrl + 'imports/' + importId;

            if (deleteUpdated) {
                url += '?delete_updated=' + !!deleteUpdated;
            }

            return sfHttp.delete(url);
        };

        this.getImportedEntities = function (importId, deleteUpdated) {

            let url = config.apiUrl + 'imports/' + importId + '/entities';

            if (deleteUpdated) {
                url += '?delete_updated=' + !!deleteUpdated;
            }

            return sfHttp.get(url, { ignoreLoadingBar: false });
        };

        /**
         * Get all imports for a user
         *
         * @returns {Promise}
         */
        this.getImports = () => {

            const url = config.apiUrl + 'imports';

            return sfHttp.get(url, { ignoreLoadingBar: true });
        };

        /**
         * Get all imports for a user, for a specific entity.
         *
         * @param {'account' | 'contact' | 'opportunity'} entity - The entity to get imports for
         * @returns {Promise}
         */
        this.getEntityImports = (entity) => {

            const url = config.apiUrl + 'imports';

            return sfHttp.get(url, { params: { type: entity }, ignoreLoadingBar: true });
        };

        /**
         * Get one import
         *
         * @param {Number} importId
         * @returns {Promise}
         */
        this.getImport = function (importId) {

            const url = config.apiUrl + 'imports/' + importId;
            return sfHttp.get(url);
        };

        /**
         * Get import header + 5 rows
         *
         * @param {Number} importId
         * @returns {{Promise}}
         */
        this.getImportSample = function (importId) {

            const url = config.apiUrl + 'imports/' + importId + '/sample';

            return sfHttp.get(url);
        };

        this.getMapping = (importId) => {

            const url = config.apiUrl + 'imports/' + importId + '/map';
            return sfHttp.get(url);
        };

        /**
         * Store mapping
         *
         * @param {Number} importId
         * @param {Object} mapping
         * @param {Boolean} findEmailIfNotExists
         * @param {Boolean} createAccountIfNotExists
         * @param {Boolean} updateAccountIfExists
         * @returns {Promise}
         */
        this.setMapping = function (importId, mapping, findEmailIfNotExists, createAccountIfNotExists, updateAccountIfExists) {

            const url = config.apiUrl + 'imports/' + importId + '/map';
            const payload = {
                options: {
                    find_email_if_not_exists: findEmailIfNotExists,
                    create_account_if_not_exists: createAccountIfNotExists,
                    update_account_if_exists: updateAccountIfExists
                },
                mapping
            };

            return sfHttp.post(url, payload);
        };

        this.getFields = function (itemClass) {

            return sfHttp.get(config.apiUrl + 'imports/' + itemClass + '/fields', { ignoreLoadingBar: true });
        };
    }
})();

