(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('OpportunitiesController', OpportunitiesController);

    function OpportunitiesController($scope, $timeout, $mdSidenav, $mdMedia, $document, $mdDialog, $stateParams, $state, $exceptionHandler, events, model, opportunities, utils, pipelines, sfWalkthrough, filterService, bulkService, sortService) {

        /**
         * Starting the opportunities loading happens through the pipeline change watcher.
         * We do need to wait for the filterfields fetch as some subsequent code in the stages controller expects it.
         */

        let thread = null;

        $scope.opportunities = {
            items: [] // Inheritance hacks, don't try this at home kids
        };
        $scope.showBulkToolbar = false;
        $scope.allSelected = false;
        $scope.searchObject = {
            search: $stateParams.search,
            getSearch: function () {

                return this.search;
            },
            setSearch: function (s) {

                this.search = s;

                return utils.setSearchInUrl({ search: this.search });
            }
        };
        $scope.searchMode = !!$stateParams.search;
        $scope.isOpportunityFilterApplied = filterService.isOpportunityFilterApplied;
        $scope.selectedOpportunity = {};
        $scope.currentUser = model.me;
        $scope.sortOptions = sortService.getSortOptions('opportunity');

        $scope.entityCountObject = {
            viewTotalCount: 0,
            viewCurrentCount: 0,
            selectedCount: 0,
            canEditCount: 0,
            option: '',
            entity: 'opportunities',
            entity_singular: 'opportunity',
            string: ''
        };

        init();

        $scope.$on(events.refreshData, get);

        $scope.$on(events.opportunity.deleted, afterDeleteHandler);

        $scope.$watch('searchMode', function (newValue, oldValue) {

            if (newValue) {
                let searchBoxId = 'searchbox';
                if ($mdMedia('gt-sm')) {
                    searchBoxId += '-fs';
                }

                utils.forceFocus($document[0].querySelector('#' + searchBoxId));
            }

            if (!oldValue && newValue) {
                $scope.searchObject.setSearch('');
            }

            if (oldValue && $scope.searchObject.getSearch()) {
                if (!newValue) {
                    $scope.searchObject.setSearch('');
                }

                return get();
            }
        });

        $scope.$watch(function () {

            return $mdMedia('gt-sm');
        }, function (newValue, oldValue) {

            if (oldValue && oldValue !== newValue) {
                $mdDialog.hide(false);
            }
        });

        // We add a watch on the state here because this controller is responsible for triggering a get for the stages and team views
        // Whenever the state changes e.g. on mobile, or when switching between team and stages views
        $scope.$watch(function () {

            return $state.current.name;
        }, function (newValue, oldValue) {

            const comingFromSpecificOpportunityToStages = (oldValue === 'opportunities.stages.opportunity' && newValue === 'opportunities.stages');
            const comingFromStagesToSpecificOpportunity = (oldValue === 'opportunities.stages' && newValue === 'opportunities.stages.opportunity');

            if (oldValue && oldValue !== newValue &&
                // On full screen we don't want to reload when coming from the stages view to a specific opportunity and vice versa
                // This happens when opening/closing the sidenav of a specific opportunity
                (!$mdMedia('gt-sm') || (!comingFromSpecificOpportunityToStages && !comingFromStagesToSpecificOpportunity))
            ) {
                $timeout(function () {

                    return get();
                });
            }
        });

        // Remove pipeline id from filters since we don't want to show it
        // We show it next to the filters in the UI
        $scope.filters = filterService.getFilter('opportunity', { raw: true });
        $scope.filters = $scope.filters.filter(function (rule) {

            return (rule.id !== 'pipeline.id' && rule.id !== 'opportunity.pipeline.id');
        });

        $scope.getDefaultFilters = function () {

            return filterService.getDefaultFilters('opportunity', model.me).filter(function (rule) {

                return (rule.id !== 'pipeline.id' && rule.id !== 'opportunity.pipeline.id');
            });
        };

        $scope.onFilterApply = function ($event) {

            filterService.setFilter('opportunity', $event.rules);

            if ($scope.badFilterError) {

                return;
            }

            return get();
        };

        $scope.doSearch = function (search) {

            $timeout.cancel(thread);

            thread = $timeout(function () {

                $scope.searchObject.setSearch(search);

                return get();
            }, 750);
        };

        $scope.$watch('currentPipeline', function (newValue, oldValue) {

            if (newValue !== oldValue) {
                return filterService.getCurrentFilterFields('opportunity', $scope.currentPipeline)
                    .then(function (response) {

                        if (response) {
                            $scope.filterFields = response;
                        }

                        return get();
                    });
            }
        });

        $scope.changePipeline = function (pipeline) {

            $scope.currentPipeline = pipeline;
            filterService.setCurrentPipelineId(pipeline.id);
        };

        $scope.setFilters = function () {

            if (sfWalkthrough.isShowing()) {
                return;
            }

            return $mdSidenav('filters').toggle()
                .then(function () {

                    return $mdSidenav('filters', true);
                });
        };

        $scope.export = function (type) {

            const isCSV = type !== 'excel';
            const isExcel = type === 'excel';

            let filters;

            if ($scope.searchObject.getSearch()) {
                filters = [...filterService.getCurrentPipelineFilter($scope.currentPipeline.id), ...filterService.getPredefinedFilters('opportunity')[$state.current.name]];
            }
            else {
                filters = [...filterService.getFilter('opportunity'), ...filterService.getPredefinedFilters('opportunity')[$state.current.name]];
            }

            return opportunities.filterGet($scope.searchObject.getSearch(), filters, 100000, 0, sortService.getCurrentSortOption('opportunity').order_by, isCSV, isExcel);
        };

        $scope.setSearchMode = function (searchMode) {

            if (sfWalkthrough.isShowing()) {
                return;
            }

            $scope.searchMode = searchMode;
        };

        $scope.openMenu = function (mdOpenMenu, event) {

            if (sfWalkthrough.isShowing()) {
                return;
            }

            return mdOpenMenu(event);
        };

        $scope.isWalkthroughShowing = sfWalkthrough.isShowing;

        $scope.selectOpportunity = function (opportunity) {

            if (sfWalkthrough.isShowing() || opportunity.can_edit === false) {
                return;
            }

            opportunity.selected = true;

            return $scope.checkShowBulkToolbar();
        };

        //// Bulk toolbar stuff

        $scope.bulkQuickActions = [
            {
                icon: 'sf-icon-delete',
                handler: function () {

                    const confirm = $mdDialog.confirm()
                        .clickOutsideToClose(true)
                        .escapeToClose(true)
                        .title('Warning!')
                        .htmlContent('<p>You\'re about to delete ' + $scope.entityCountObject.selectedCount + ($scope.entityCountObject.selectedCount > 1 ? ' opportunities' : ' opportunity') + '.<br />Are you sure you want to do this?</p>')
                        .ok('I\'m sure')
                        .cancel('cancel');

                    return $mdDialog.show(confirm).then(function () {

                        $scope.closeSidebar();

                        return bulkService.deleteOpportunities(getBulkOptions()).then(function () {

                            return $state.reload();
                        }).catch(function (err) {

                            $exceptionHandler(err);

                            return utils.showErrorToast();
                        });
                    });
                },
                config: {
                    toolTip: {
                        content: function () {
                            return 'Delete';
                        }
                    }
                }
            },
            {
                icon: 'sf-icon-edit',
                handler: function () {

                    return $state.go('bulkEditOpportunity', { options: getBulkOptions(), pipelineId: $scope.currentPipeline?.id });
                },
                config: {
                    toolTip: {
                        content: function () {
                            return 'Edit';
                        }
                    }
                }
            }
        ];

        // Returns options object with either the ids selected or the filter when all selected or searching
        function getBulkOptions() {

            if ($scope.allSelected) {
                if ($scope.searchObject.getSearch()) {
                    return {
                        condition: 'AND',
                        rules: [...filterService.getCurrentPipelineFilter($scope.currentPipeline.id), ...filterService.getPredefinedFilters('opportunity')[$state.current.name]],
                        search: $scope.searchObject.getSearch()
                    };
                }

                return {
                    condition: 'AND',
                    rules: [...filterService.getFilter('opportunity'), ...filterService.getPredefinedFilters('opportunity')[$state.current.name]]
                };
            }

            return {
                condition: 'AND',
                rules: [{
                    id: 'opportunity.id',
                    operator: 'in',
                    value: $scope.opportunities.items.filter(function (opportunity) {

                        return opportunity.selected;
                    }).map(function (opportunity) {

                        return opportunity.id;
                    })
                }]
            };
        }

        $scope.checkShowBulkToolbar = function () {

            // Next tick so if a `selected` value needs to be updated it does that first before our check
            return $timeout(function () {

                $scope.showBulkToolbar = $scope.opportunities.items.find(function (opp) {

                    return opp.selected;
                });

                $scope.allSelected = $scope.opportunities.items.every(function (opp) {

                    return opp.selected || opp.can_edit === false;
                });

                if ($scope.showBulkToolbar) {
                    const selectedOpportunities = $scope.opportunities.items.filter(function (account) {

                        return account.selected;
                    });

                    if ($scope.allSelected) {
                        $scope.entityCountObject.selectedCount = $scope.entityCountObject.canEditCount;
                    }
                    else {
                        $scope.entityCountObject.selectedCount = selectedOpportunities.length;
                    }

                    $scope.entityCountObject.string = utils.getEntityCountString($scope.entityCountObject);
                }
                else {
                    $scope.entityCountObject.selectedCount = 0;
                    $scope.entityCountObject.string = utils.getEntityCountString($scope.entityCountObject);
                }

            });
        };

        $scope.onAllSelected = function ($event) {

            $scope.allSelected = $event ? $event.allSelected : true;

            if ($scope.allSelected) {
                $scope.entityCountObject.selectedCount = $scope.entityCountObject.canEditCount;
                $scope.entityCountObject.string = utils.getEntityCountString($scope.entityCountObject);
            }
            else {
                $scope.showBulkToolbar = false;
                $scope.entityCountObject.selectedCount = 0;
                $scope.entityCountObject.string = utils.getEntityCountString($scope.entityCountObject);
            }

            $scope.opportunities.items.forEach(function (opp) {

                opp.selected = $scope.allSelected ? opp.can_edit : false;
            });
        };

        $scope.handleFilterError = ($event) => {

            if ($event.message === null && $scope.badFilterError) {
                $scope.usedBadFilter = false;

                get();
            }

            if ($event.message) {
                $scope.usedBadFilter = true;
            }

            $scope.badFilterError = $event.message;
        };

        $scope.handleServerError = (response) => {

            if (response.config.method === 'HEAD') {

                return;
            }

            if (response && (response.status === 422 || response.status === 402)) {
                $scope.usedBadFilter = true;

                if (response.data.filter) {

                    const selectedRules = filterService.flatten(filterService.getFilter('opportunity', { raw: true }));
                    const hydratedRules = filterService.flatten(response.data.filter.rules);

                    const rulesToApply = filterService.intersectAndEnrich(selectedRules, hydratedRules);

                    $scope.filters = filterService.setFilter('opportunity', rulesToApply);

                    $scope.$broadcast('setFilters', $scope.filters);
                }
            }
        };

        $scope.sideBarIsOpen = function () {

            // We don't have the sidebar on smaller screen, checking when it does not exists throws errors
            return $mdMedia('gt-sm') && $mdSidenav('right').isOpen();
        };

        $scope.closeSidebar = function () {

            if ($mdMedia('gt-sm')) {
                $mdSidenav('right').close();
            }

            $scope.selectedOpportunity = {};

            if ($state.current.name.includes('stages')) {
                return $state.go('opportunities.stages');
            }

            return $state.go('opportunities.timeline');
        };

        $scope.handleClick = function ($event) {

            if (!$mdMedia('gt-sm')) {
                return;
            }

            const sidebar = angular.element('.opportunity-sidebar');

            if (!sidebar) {
                return;
            }

            if ($scope.selectedOpportunity.id && $event.target.closest('.opportunity') === null) {
                return $scope.closeSidebar();
            }
        };

        $scope.selectSortOption = function (sortKey) {

            sortService.setCurrentSortKey('opportunity', sortKey);
            return $scope.$broadcast('get');
        };

        $scope.isSortOptionSelected = function (sortKey) {

            return sortService.getCurrentSortKey('opportunity') === sortKey;
        };

        function init() {

            return pipelines.getViewable().then(function (response) {

                // This timeout is needed so that when we make sure this runs after the stages controller has ran.
                // This is an issue in the walkthrough since there `getViewable` returns sync which causes this init to run before the stages one.
                // Of course this magic loading strategy isn't great but a proper rewrite of the whole opportunities thing is needed to fix it.
                // - I did try to make the response of sfHttp when using the mock service to return async by adding the timeout there but that caused other steps to fail 🥫🪱.
                $timeout(() => {

                    $scope.pipelines = response.pipelines;
                    model.pipelines = response.pipelines;

                    const currentPipelineId = store.get('current_pipeline_' + model.me.id);

                    if (currentPipelineId) {
                        $scope.currentPipeline = $scope.pipelines.find(function (pipeline) {

                            return pipeline.id === currentPipelineId;
                        });
                    }

                    if (!$scope.currentPipeline) {
                        //  No need to save the pipeline id when doing the walkthrough with dummy data
                        if (!sfWalkthrough.isShowing()) {
                            filterService.setCurrentPipelineId($scope.pipelines[0].id);
                        }

                        $scope.currentPipeline = $scope.pipelines[0];
                    }

                    return filterService.getCurrentFilterFields('opportunity', $scope.currentPipeline)
                        .then(function (filterFieldsResponse) {

                            if (filterFieldsResponse) {
                                $scope.filterFields = filterFieldsResponse;
                            }
                        });
                });
            });
        }

        function afterDeleteHandler() {

            $scope.closeSidebar();

            get();
        }

        function get() {

            $scope.showBulkToolbar = false;
            $scope.allSelected = false;

            return $scope.$broadcast('get');
        }
    }
})();
