(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EmailTrackingDialogController', EmailTrackingDialogController);

    function EmailTrackingDialogController($scope, $mdDialog, me) {

        init();

        function init() {
            new ClipboardJS('[data-clipboard-text]');

            me.get().then(function (response) {

                const emailTrackingDomain = response.data.team.email_tracking_domain.url;
                const emailTrackingSubdomain = response.data.team.email_tracking_subdomain;

                $scope.email_tracking_subdomain = emailTrackingSubdomain;
                $scope.email_tracking_domain = emailTrackingDomain;
                $scope.trackingToken = response.data.team.tracking_token;

                $scope.linkWithoutId = `https://${emailTrackingSubdomain}.${emailTrackingDomain}?u=[URL]&t=${$scope.trackingToken}`;
                $scope.linkWithId = `https://${emailTrackingSubdomain}.${emailTrackingDomain}?u=[URL]&t=${$scope.trackingToken}&ce=[EMAIL]`;
                $scope.linkBase64 = `https://${emailTrackingSubdomain}.${emailTrackingDomain}?u=[URL]&t=${$scope.trackingToken}&cee=[ENCODEDEMAIL]`;
            });
        }

        $scope.back = function () {

            $mdDialog.hide(false);
        };
    }
})();
