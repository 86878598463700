(function () {
    'use strict';

    /**
     * When no or an empty url is provided sf-avatar will be hidden with ng-hide
     */

    angular
        .module('salesflare.components.avatar', [])
        .component('sfAvatar', {
            controllerAs: 'vm',
            controller,
            template: '<div class=\'sf-avatar_face\'"><h2 ng-if="vm.editable"><md-icon md-font-icon="sf-icon-edit" class="icon-14"></md-icon>Edit</h2></div>',
            bindings: {
                url: '<sfSrc',
                editable: '<'
            }
        });

    function controller($element) {

        const vm = this;

        const face = $element.children('.sf-avatar_face');

        vm.$onChanges = function (changes) {

            if (changes.url && !changes.url.currentValue) {
                face.css({
                    'background-image': '',
                    'background-color': 'rgba(0, 0, 0, 0.2)'
                });
            }
            else if (vm.editable) {
                face.css({
                    background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url( ' + vm.url + ')',
                    'background-repeat': 'repeat-x',
                    'background-position': 'center',
                    'background-size': 'contain'
                });
            }
            else {
                face.css({
                    'background-image': 'url( ' + vm.url + ')',
                    'background-color': ''
                });
            }
        };
    }
})();
