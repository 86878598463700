(function () {
    'use strict';

    angular
        .module('salesflare.components.chipClass', [])
        .directive('sfChipClass', sfChipClassController);

    function sfChipClassController() {
        return {
            scope: {

                sfChipClass: '@'
            },
            link: (scope, element) => {

                const mdChip = angular.element(element).parent().parent();

                scope.$watch('sfChipClass', (newValue, oldValue) => {

                    if (oldValue) {
                        mdChip.removeClass(oldValue);
                    }

                    if (newValue) {
                        mdChip.addClass(newValue);
                    }
                });
            }
        };
    }
})();
