(function () {
    'use strict';

    angular
        .module('salesflare.components.largerprospectfollowup.youCanBookMeForm', [])
        .component('sfYouCanBookMeForm', {
            templateUrl: 'app-ajs/components/largerprospectfollowup/youCanBookMeForm/youCanBookMeForm.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                callDurationInMin: '<',
                bookingInfo: '<',
                skipWalkthroughAfterBooking: '<',
                onDismiss: '&'
            }
        });

    function controller($mdDialog, $mdMedia, $sce, config) {

        const vm = this;

        vm.$mdMedia = $mdMedia;

        const videoCallUrl = config.ycbm.videoCallUrl;
        const phoneCallUrl = config.ycbm.phoneCallUrl;

        vm.close = $mdDialog.hide;

        vm.$onInit = () => {

            vm.iFrameSource = getTrustedUrl();
            vm.titleString = `Book a ${vm.callDurationInMin === 30 ? 'video' : 'phone'} call`;
        };

        vm.dismiss = () => {

            if (vm.onDismiss) {
                return vm.onDismiss();
            }
        };

        function getTrustedUrl() {

            // Pick the correct booker
            let srcUrl = vm.callDurationInMin === 30 ? videoCallUrl : phoneCallUrl;

            // Append the user info to the url with the youcanbook.me shorthand codes
            // This will fill out the booking form with the supplied data
            if (vm.bookingInfo) {
                for (const info of Object.keys(vm.bookingInfo)) {
                    let urlParam;
                    const encodedValue = encodeURIComponent(vm.bookingInfo[info]);
                    switch (info) {
                        case 'firstName':
                            urlParam = `&FNAME=${encodedValue}`;
                            break;
                        case 'company':
                            urlParam = `&COMPANY=${encodedValue}`;
                            break;
                        case 'email':
                            urlParam = `&EMAIL=${encodedValue}`;
                            break;
                        case 'currentlyUsing':
                            urlParam = `&CURRENTCRM=${encodedValue}`;
                            break;
                        case 'potentialUsers':
                            urlParam = `&USERS=${encodedValue}`;
                            break;
                        default:
                            // Skip unknown keys
                            break;
                    }

                    if (urlParam) {
                        srcUrl += urlParam;
                    }
                }
            }

            srcUrl += `&GOAL=${encodeURIComponent('Onboarding call')}&SOURCE=app&SKIP-WALKTHROUGH=${!!vm.skipWalkthroughAfterBooking}`;

            return $sce.trustAsResourceUrl(srcUrl);
        }
    }
})();
