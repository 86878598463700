import { Component } from '@angular/core';

import { PlanModel } from '@shared/models/plan.model';

import { DialogContent } from '@shared/components/dialog/dialog.component';

export interface UpgradePlanContentData {
    plan: PlanModel,
    billingFrequency: 'monthly' | 'annually'
}

@Component({
    selector: 'sfx-billing-settings-upgrade-plan-content',
    template: `
        <h3>You are about to upgrade your subscription to the {{ plan.name}} plan 🎉</h3>

        <div class="text">
            <p>You will gain access to the following features:</p>
            <div class="list-container">
                <ul>
                    <li *ngFor="let feature of plan.features">{{ feature.name }}</li>
                </ul>
            </div>
            <p>Your subscription will be updated and any differences will be billed pro rata<span *ngIf="billingFrequency === 'annually'"> at the start of next month</span>.</p>
        </div>
    `
})
export class UpgradePlanContentComponent implements DialogContent<UpgradePlanContentData> {

    public valid: true;

    public plan: PlanModel;

    public billingFrequency: 'monthly' | 'annually';

    constructor(
    ) {}

    setInitialState(context: UpgradePlanContentData) {

        this.plan = context.plan;
    }

    getCurrentState(): UpgradePlanContentData {

        return { plan: this.plan, billingFrequency: this.billingFrequency };
    }
}
