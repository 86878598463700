import { Component, Inject } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { MediaService } from '@core/services/media.service';

import {
    DialogContent, DialogResponse,
    SfxDialog
} from '@shared/components/dialog/dialog.component';

import { PlansModel } from '@shared/models/plan.model';
import { BillingOverviewModel } from '@shared/models/billing.model';
import { BehaviorSubject } from 'rxjs';

export interface EditBillingFrequencyContentData {
    selectedValue: string;
    pricingSwitchOptions: BillingOverviewModel;
    editPlan: boolean;
    appliedDiscount?: boolean;
    appliedDiscountType?: string;
}

@Component({
    selector: 'sfx-billing-settings-edit-billing-frequency-content',
    template: `
        <h3>Update billing frequency</h3>
        <sfx-pricing-switch [options]="pricingSwitchOptions" [(selectedValue)]="selectedValue" [editPlan]="editPlan"></sfx-pricing-switch>
    `,
    styles: ['h3 { color: var(--sf-black); font-weight: 700; }']
})
export class EditBillingFrequencyContentComponent implements DialogContent<EditBillingFrequencyContentData> {

    public deleteAutomatedTags = false;

    public valid: true;

    public plans: PlansModel;
    private originalValue: string;
    public selectedValue = '';
    public editPlan = false;
    public pricingSwitchOptions: BillingOverviewModel;

    public appliedDiscount?: boolean;
    public appliedDiscountType?: string;

    public validateState$ = new BehaviorSubject<'valid' | 'invalid' | 'validating'>('invalid');

    constructor(
        public media: MediaService,
        private matDialog: MatDialog,
        @Inject('planService') private plansService: any,
        @Inject('stateService') private stateService: any
    ) {}

    setInitialState(context: EditBillingFrequencyContentData) {

        this.plans = this.plansService.get();

        this.selectedValue = context.selectedValue;
        this.originalValue = context.selectedValue;
        this.editPlan = context.editPlan;
        this.pricingSwitchOptions = context.pricingSwitchOptions;

        this.appliedDiscount = context.appliedDiscount;
        this.appliedDiscountType = context.appliedDiscountType;
    }

    getCurrentState(): EditBillingFrequencyContentData {

        return {
            selectedValue: this.selectedValue,
            editPlan: this.editPlan,
            pricingSwitchOptions: this.pricingSwitchOptions as BillingOverviewModel
        };
    }

    onConfirm(): void {

        this.validateState$.next('validating');

        if (!this.selectedValue || this.selectedValue === this.originalValue) {
            return this.validateState$.next('valid');
        }

        if (this.selectedValue === 'annually') {
            this.matDialog.open(SfxDialog, {
                data: {
                    htmlBody: '<h3>Are you sure?</h3><div style="color: var(--sf-black)">Upon switching to annual billing, you will be charged for the coming year starting today.<br>Any payments you\'ve already done for future usage will be used as credit towards the charge.</div>',
                    confirm: 'Yes',
                    close: 'No',
                    contentStyle: 'padding: 0 16px;'
                }
            }).afterClosed().subscribe((confirmationResult: DialogResponse) => {
                if (confirmationResult.confirmed) {
                    this.validateState$.next('valid');
                }
                else {
                    this.validateState$.next('invalid');
                }
            });
        }
        else {
            this.validateState$.next('valid');
        }
    }
}
