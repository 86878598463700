(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('AccountSocialFeedController', accountSocialFeedController);

    function accountSocialFeedController($rootScope, $scope, $stateParams) {
        $scope.id = $stateParams.id;

        if ($rootScope.history[$rootScope.history.length - 1].fromState.name.startsWith('accounts.')) {
            $rootScope.history.pop();
        }
    }
})();
