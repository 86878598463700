(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditCardDialogController', EditCardDialogController);

    function EditCardDialogController($scope, $mdDialog, utils) {

        $scope.vat = this.vat;
        $scope.updateButtonDisabled = true;
        let token = null;

        $scope.onCardValidated = function ($event) {

            $scope.updateButtonDisabled = false;
            token = $event.token;
            $scope.vat = $event.VATNumber || null;
        };

        $scope.onCardValidateError = function ($event) {

            $scope.updateButtonDisabled = true;
            token = null;
            $scope.vat = null;

            if ($event) {
                return utils.showErrorToast($event.error.message);
            }
        };

        $scope.onProcessingChange = function ($event) {

            $scope.updateButtonDisabled = $event.processing;
        };

        $scope.update = function () {

            return $mdDialog.hide({
                token,
                vat: $scope.vat
            });
        };
    }
})();
