(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('bulkService', bulkService);


    function bulkService($q, sfHttp, config, filterService, model) {

        // Accounts

        this.deleteAccounts = function (filter) {

            filter.rules = filterService.cleanAdvancedFilterForHttp(angular.copy(filter.rules));

            try {
                validateFilter(filter);
            }
            catch (err) {
                return $q.reject(err);
            }

            const request = {
                data: { filter }
            };

            return sfHttp.delete(config.apiUrl + 'bulk/accounts', request);
        };

        this.updateAccounts = function (payload) {

            payload.filter.rules = filterService.cleanAdvancedFilterForHttp(angular.copy(payload.filter.rules));

            try {
                validateFilter(payload.filter);
            }
            catch (err) {
                return $q.reject(err);
            }

            return sfHttp.put(config.apiUrl + 'bulk/accounts', payload);
        };

        this.addUsersToAccounts = function (data) {

            data.filter.rules = filterService.cleanAdvancedFilterForHttp(angular.copy(data.filter.rules));

            try {
                validateFilter(data.filter);
            }
            catch (err) {
                return $q.reject(err);
            }

            const payload = {
                userIds: data.users.map(function (user) {

                    delete user._dirty;
                    return user.id;
                }),
                filter: data.filter
            };

            return sfHttp.post(config.apiUrl + 'bulk/accounts/users', payload);
        };

        this.removeUsersFromAccounts = function (data) {

            data.filter.rules = filterService.cleanAdvancedFilterForHttp(angular.copy(data.filter.rules));

            try {
                validateFilter(data.filter);
            }
            catch (err) {
                return $q.reject(err);
            }

            const payload = {
                userIds: data.users.map(function (user) {

                    delete user._dirty;
                    return user.id;
                }),
                filter: data.filter
            };

            return sfHttp.delete(config.apiUrl + 'bulk/accounts/users', { data: payload });
        };

        // Contacts

        this.deleteContacts = function (filter) {

            filter.rules = filterService.cleanAdvancedFilterForHttp(angular.copy(filter.rules));

            try {
                validateFilter(filter);
            }
            catch (err) {
                return $q.reject(err);
            }

            const request = {
                data: { filter }
            };

            return sfHttp.delete(config.apiUrl + 'bulk/contacts', request).then((response) => {

                // Update it here so we don't need to refetch model.me entirely
                model.me.has_archived_contacts = true;

                return $q.resolve(response);
            });
        };

        this.updateContacts = function (payload) {

            payload.filter.rules = filterService.cleanAdvancedFilterForHttp(angular.copy(payload.filter.rules));

            try {
                validateFilter(payload.filter);
            }
            catch (err) {
                return $q.reject(err);
            }

            return sfHttp.put(config.apiUrl + 'bulk/contacts', payload);
        };

        this.findEmailsForContacts = function (filter) {

            filter.rules = filterService.cleanAdvancedFilterForHttp(angular.copy(filter.rules));

            try {
                validateFilter(filter);
            }
            catch (err) {
                return $q.reject(err);
            }

            return sfHttp.post(config.apiUrl + 'bulk/contacts/email/search', { filter });
        };

        // Tasks

        this.updateTasks = function (payload) {

            payload.filter.rules = filterService.cleanAdvancedFilterForHttp(angular.copy(payload.filter.rules));

            try {
                validateFilter(payload.filter);
            }
            catch (err) {
                return $q.reject(err);
            }

            return sfHttp.put(config.apiUrl + 'bulk/tasks', payload);
        };

        this.deleteTasks = function (filter) {

            filter.rules = filterService.cleanAdvancedFilterForHttp(angular.copy(filter.rules));

            try {
                validateFilter(filter);
            }
            catch (err) {
                return $q.reject(err);
            }

            const request = {
                data: { filter }
            };

            return sfHttp.delete(config.apiUrl + 'bulk/tasks', request);
        };

        // Opportunities

        this.updateOpportunities = function (payload) {

            payload.filter.rules = filterService.cleanAdvancedFilterForHttp(angular.copy(payload.filter.rules));

            try {
                validateFilter(payload.filter);
            }
            catch (err) {
                return $q.reject(err);
            }

            return sfHttp.put(config.apiUrl + 'bulk/opportunities', payload);
        };

        this.deleteOpportunities = function (filter) {

            filter.rules = filterService.cleanAdvancedFilterForHttp(angular.copy(filter.rules));

            try {
                validateFilter(filter);
            }
            catch (err) {
                return $q.reject(err);
            }

            const request = {
                data: { filter }
            };

            return sfHttp.delete(config.apiUrl + 'bulk/opportunities', request);
        };

        // Notifications

        this.updateNotifications = function (payload) {

            return sfHttp.put(config.apiUrl + 'bulk/notifications', payload);
        };

        // Tags

        this.deleteTags = function (filter) {

            filter.rules = filterService.cleanAdvancedFilterForHttp(angular.copy(filter.rules));

            try {
                validateFilter(filter);
            }
            catch (err) {
                return $q.reject(err);
            }

            const request = {
                data: { filter }
            };

            return sfHttp.delete(config.apiUrl + 'bulk/tags', request);
        };

        /**
         * Makes sure we didn't generate a bad filter and will throw if this is the case
         * e.g. no ids or ids = [] or plainly didn't pass a filter
         *
         * @param {Object} filter
         * @returns {Object}
         */
        function validateFilter(filter) {

            if (!filter || (Object.prototype.hasOwnProperty.call(filter, 'ids') && filter.ids.length === 0)) {
                const error = new Error('Generated a bad filter for bulk actions');
                error.filter = filter;
                throw error;
            }

            return filter;
        }
    }
})();
