(function () {
    'use strict';

    angular
        .module('salesflare.components.downgradeConfirmationDialog', [])
        .factory('sfDowngradeConfirmationDialog', sfDowngradeConfirmationDialog);

    function sfDowngradeConfirmationDialog($mdDialog) {

        return {
            show
        };

        function show(currentPlanName, futurePlan, currentPlan, futurePlanName, onTrial) {

            return $mdDialog.show({
                clickOutsideToClose: false,
                escapeToClose: false,
                multiple: true,
                hasBackdrop: true,
                controller: 'DowngradeConfirmationDialogController',
                controllerAs: 'vm',
                templateUrl: 'app-ajs/components/downgradeconfirmationdialog/downgradeconfirmationdialog.html',
                locals: {
                    currentPlan,
                    currentPlanName,
                    futurePlan,
                    futurePlanName,
                    onTrial
                },
                bindToController: true
            });
        }
    }
})();
