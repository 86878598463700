(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('joditNotesService', joditNotesService);

    function joditNotesService($rootScope, $document, $window, $compile, $timeout, utils) {

        this.getJoditEditor = getJoditEditor;

        ////////////////////////

        /**
         * Gives you a preconfigured instance of a Jodit editor attached to the DOM
         *
         * @param {Object} joditOptions
         * @param {String} joditOptions.queryStringToBindTo
         * @param {String} [joditOptions.placeholder="Type an email"]
         * @param {Object} joditOptions.attachmentsButton
         * @param {Boolean} [joditOptions.attachmentsButton.enabled=false]
         * @param {function(Array.<File>):void} [joditOptions.attachmentsButton.onAttachments]
         * @param {function(String):void} onBodyChange
         * @returns {Jodit}
         */
        function getJoditEditor(joditOptions, onBodyChange) {

            const joditConfig = {
                autofocus: joditOptions.autofocus ? true : false,
                readonly: joditOptions.readonly ? true : false,
                uploader: {
                    insertImageAsBase64URI: true
                },
                allowResizeY: false,
                toolbarSticky: false,
                showCharsCounter: false,
                showWordsCounter: false,
                showXPathInStatusbar: false,
                useExtraClassesOptions: false,
                askBeforePasteFromWord: false,
                askBeforePasteHTML: false,
                processPasteHTML: true,
                disablePlugins: 'mobile,xpath,stat,search,imageProperties',
                height: '100%',
                buttons: 'textsize,|,bold,underline,italic,brush,|,table,ul,ol,align' + (joditOptions.attachmentsButton.enabled ? ',|,attachment,|,eraser' : '') + ',|,link',
                showTooltip: true,
                useNativeTooltip: true,
                colorPickerDefaultTab: 'color',
                placeholder: joditOptions.placeholder,
                enter: 'DIV',
                spellcheck: true,
                dialog: { // Doesn't seem to work atm
                    resizable: false,
                    draggable: false,
                    buttons: [
                        'dialog.close'
                    ],
                    removeButtons: []
                },
                cleanHTML: {
                    timeout: 300,
                    removeEmptyElements: true,
                    fillEmptyParagraph: true,
                    replaceNBSP: true,
                    cleanOnPaste: false, // Is false as it would otherwise clean pasted html too much
                    replaceOldTags: {
                        i: 'em',
                        b: 'strong'
                    },
                    allowTags: false,
                    denyTags: {
                        style: true,
                        script: true
                    }
                },
                image: {
                    editClass: false,
                    editStyle: false
                },
                link: {
                    formTemplate: () => `
                    <form class="jodit-ui-form" dir="auto">
                        <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle">
                            <div class="jodit-ui-input jodit-ui-block__url jodit-ui-input_focused_true">
                                <span class="jodit-ui-input__label">URL</span>
                                <div class="jodit-ui-input__wrapper">
                                    <input id="url_input" class="jodit-ui-input__input" dir="auto" name="url" type="text"
                                           data-ref="url_input" ref="url_input" required="true" placeholder="https://">
                                </div>
                            </div>
                        </div>
                        <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle" data-ref="content_input_box"
                             ref="content_input_box">
                                <div class="jodit-ui-input jodit-ui-block__content">
                                    <span class="jodit-ui-input__label">Text</span>
                                    <div class="jodit-ui-input__wrapper"><input id="content_input" class="jodit-ui-input__input" dir="auto" name="content"
                                         type="text" data-ref="content_input" ref="content_input" placeholder="">
                                     </div>
                                </div>
                        </div>
                        <div class="jodit-ui-block jodit-ui-block_align_full jodit-ui-block_size_middle">
                            <button class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_unlink jodit-ui-button_variant_default jodit-ui-button_text-icons_true jodit-ui-block__unlink"
                                    type="button" role="button" aria-pressed="false" data-ref="unlink" ref="unlink" style="display: none;">
                                    <span class="jodit-ui-button__icon"></span>
                                    <span class="jodit-ui-button__text">Unlink</span>
                            </button>
                            <button class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_insert jodit-ui-button_variant_primary jodit-ui-button_text-icons_true jodit-ui-block__insert"
                                    type="submit" onclick="fixUrl();" role="button" aria-pressed="false" data-ref="insert" ref="insert">
                                <span class="jodit-ui-button__icon"></span>
                                <span class="jodit-ui-button__text">Insert</span>
                            </button>
                        </div>
                    </form>
                    `,
                    followOnDblClick: false,
                    noFollowCheckbox: false,
                    openInNewTabCheckbox: false,
                    modeClassName: false,
                    processVideoLink: false
                },
                controls: {
                    textsize: {
                        icon: 'fontsize',
                        command: 'fontsize',
                        exec: function (editor, current, execOptions) {

                            if (!execOptions.control.args) {
                                return false;
                            }

                            return editor.execCommand(execOptions.control.command, false, execOptions.control.args[0]);
                        },
                        list: {
                            '10': 'Small',
                            '13': 'Normal',
                            '18': 'Large',
                            '32': 'Huge'
                        },
                        childTemplate: function (editor, key, value) {

                            return '<span class="jodit_list_element" style="font-size:' + key + 'px; line-height: 1;">' + value + '</span>';
                        },
                        tooltip: 'Format text size'
                    }
                },
                beautifyHTMLCDNUrlsJS: ['https://cdn.jsdelivr.net/npm/js-beautify@1.13.0/js/lib/beautify-html.min.js']
            };

            // The paste handler expects there to be at least 1 handler.
            // Since we turn of the only internal one with `cleanOnPaste: false`
            // This means we need to at least create a dummy one here that does nothing but let the handler function properly
            // In our case however we will actually clean the HTML ourselves in this handler
            joditConfig.events = joditConfig.events || {};
            joditConfig.events.processPaste = function (event, html) {

                const purifiedBody = DOMPurify.sanitize(html);
                return purifiedBody;
            };

            joditConfig.events.getIcon = function (name, control, clearName) {

                switch (clearName) {
                    case 'brush':
                        return '<md-icon class="icon-16 md-font material-icons sf-icon-text-format"></md-icon>';
                    case 'eraser':
                        return '<md-icon class="icon-16 md-font material-icons sf-icon-clear-format"></md-icon>';
                }
            };

            if (joditOptions.attachmentsButton.enabled) {
                joditConfig.controls.attachment = {
                    controlName: 'Attachment',
                    name: 'Attachment',
                    tooltip: 'Attachment',
                    exec: function (jodit, current, execOptions) {

                        /**
                         * Jodit adds a touchend handler on the container li element of our button
                         * this handler does a .preventDefault which breaks our handling on touch devices.
                         * In other words on touch devices the file picker doesn't show up.
                         *
                         * What we do here is detect whether the button is being clicked via a touch event and if so send out a new event
                         * to trigger the file picker.
                         * We can't do this for mouse clicks as this would trigger the file picker twice.
                         */
                        if (execOptions.originalEvent.type === 'touchend') {
                            execOptions.button.firstElementChild.click();
                        }
                    }
                };
            }

            const joditInstance = new Jodit(joditOptions.queryStringToBindTo, joditConfig);
            let aceEditor;

            if (joditOptions.maxHeight) {
                joditInstance.editor.style.maxHeight = joditOptions.maxHeight;
            }

            // Focus editor when clicking on container but not editor
            joditInstance.workplace.addEventListener('click', function (evt) {

                // If we are in source mode select the source editor
                if (joditInstance.getMode() === Jodit.MODE_SOURCE && !$document[0].activeElement.classList.contains('ace_text-input')) {
                    aceEditor = aceEditor || ace.edit($document[0].querySelector('.ace_editor'));

                    aceEditor.focus();
                    return aceEditor.navigateFileEnd();
                }
                else if (evt.target === evt.currentTarget) {
                    joditInstance.editor.focus();

                    // Put caret at the end of the text
                    const range = $document[0].createRange();
                    range.selectNodeContents(joditInstance.editor);
                    range.collapse(false);
                    const sel = $window.getSelection();
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
            });

            // The editor supports dropping images but since our special layout (see supporting files/scrollable/...)
            // It wouldn't support dropping files on the container instead of the smaller editor
            // Here we basically catch the drop on the container and send it to the editor to handle the drop
            joditInstance.container.addEventListener('drop', function (evt) {

                evt.preventDefault();
                evt.stopPropagation();

                const event = new Event('drop');
                event.dataTransfer = evt.dataTransfer;

                joditInstance.container.click();
                joditInstance.editor.dispatchEvent(event);
            });
            // Make sure the browser knows that the container is a drop target in all cases, otherwise the drop event doesn't fire in all cases
            // See https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#droptargets
            joditInstance.container.addEventListener('dragover', function (evt) {

                // Needs a every.call since dataTransfer.items is not a real array but a DataTransferList
                const allFilesAreImages = Array.prototype.every.call(evt.dataTransfer.items, function (i) {

                    return i.type.includes('image');
                });

                // Only stop the event when we want to handle it ourselves
                // ergo when 1 or more images
                if (allFilesAreImages) {
                    evt.preventDefault();
                    evt.stopPropagation();
                }
            });

            // Overwrite the min height every time jodit tries to set it since it messes with our layout
            joditInstance.events.on('setMinHeight',function () {

                joditInstance.container.style.minHeight = '';
                joditInstance.editor.style.minHeight = '';
            });

            // Listen on image events to make sure we don't exceed the max size limit
            joditInstance.events.on('afterInsertImage', function (image) {

                if (joditInstance.getEditorValue().length > (15 * 1000 * 1000)) {
                    image.remove();
                    return utils.showErrorToast('Adding this image will exceed the maximum total email size of 15MB');
                }
            });

            if (onBodyChange) {
                joditInstance.events.on('change', function (bodyText) {

                    return $rootScope.$applyAsync(function () {

                        return onBodyChange(bodyText);
                    });
                });
            }


            joditInstance.events.on('beforeSetValueToEditor', function (html) {

                if (!html) {
                    return;
                }

                const purifiedBody = DOMPurify.sanitize(html);
                return purifiedBody;
            });

            // Prevent enter plugin without preventing the default behavior
            // If we don't do this then pressing enter won't select a template when doing a # template search but just insert an enter in the jodit editor
            joditInstance.events.on('beforeEnter', function () {

                return true;
            });

            // We need to wait for the next tick to be able to start modifying the dom
            // Since 3.3.8 the plugin system changed and became async https://github.com/xdan/jodit/compare/3.3.7...3.3.8?w=1#diff-70ea7a6a97e1a4e20f3beee88a8df185R66
            $timeout(function () {

                const linkScript = $document[0].createElement('script');
                linkScript.text = `function fixUrl() {

                    var contentInput = document.getElementById('content_input');
                    var urlInput = document.getElementById('url_input');
                    contentInput.value = contentInput.value?contentInput.value:urlInput.value;
                    urlInput.value = urlInput.value.replace(/^(?!https?:\\/\\/)(.*)/, 'https://$1');
                };`;
                $document[0].body.append(linkScript);

                // Use the container as reference for dom manipulation.
                // This is important when having more than 1 instance in the dom
                // We want to make sure we are only touching our current instance
                const container = angular.element(joditInstance.container);

                if (joditOptions.attachmentsButton.enabled) {
                    // We create our own compiled button here to get the file upload working out of the box
                    const attachmentToolbarButtonTemplate = ''
                    + '<button class="jodit-toolbar-button__button" ngf-pattern="" ngf-select ngf-change="vm.onAttachments($files)" accept="" ngf-multiple="true" type="button">'
                    + '     <md-icon md-font-icon="sf-icon-attachment" class="icon-16"></md-icon>'
                    + '</button>';
                    const attachmentToolbarButtonScope = $rootScope.$new();
                    attachmentToolbarButtonScope.vm = {
                        onAttachments: joditOptions.attachmentsButton.onAttachments
                    };

                    const attachmentToolbarButton = $compile(attachmentToolbarButtonTemplate)(attachmentToolbarButtonScope);
                    container.find('.jodit-toolbar-btn_Attachment button').replaceWith(attachmentToolbarButton);
                }
            });

            return joditInstance;
        }
    }
})();
