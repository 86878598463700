import { Component } from '@angular/core';
import { DialogContent } from '@shared/components/dialog/dialog.component';


import { Tag } from '../models/tag.model';

export interface DeleteTagDialogContentData {
    tag: Tag,
    workflows: { id: number, name: string }[];
    savedFilters: { id: number, name: string }[];
    reports: { id: number, title: string }[];
}

@Component({
    selector: 'sfx-manage-tags-delete-tag-content',
    template: `
        <p>
            Are you sure you want to delete this tag?
        </p>
        <p [innerHTML]="message">
            {{ message }}
        </p>
    `,
    styles: ['p { max-width: 500px; }']
})
export class DeleteTagDialogContentComponent implements DialogContent<DeleteTagDialogContentData> {

    context: DeleteTagDialogContentData;

    message: string;

    constructor(

    ) {}

    private _constructEntityMessage(entity: string, names: string[]): string {

        const amount = names.length;
        const namesString = names.join(', ');

        return `${amount > 1 ? amount : 'a'} ${entity}${amount > 1 ? 's' : ''} (${namesString})`;
    }

    setInitialState(context: DeleteTagDialogContentData): void {

        this.context = context;

        const usages = [];

        const filtersAmount = this.context.savedFilters.length;
        const workflowsAmount = this.context.workflows.length;
        const reportsAmount = this.context.reports.length;

        if (workflowsAmount > 0) {

            usages.push(this._constructEntityMessage('workflow', this.context.workflows.map((workflow) => workflow.name)));
        }

        if (filtersAmount > 0) {

            usages.push(this._constructEntityMessage('filter', this.context.savedFilters.map((filter) => filter.name)));
        }

        if (reportsAmount > 0) {

            usages.push(this._constructEntityMessage('report', this.context.reports.map((report) => report.title)));
        }

        if (usages.length > 0) {

            this.message = `Note that this tag is used in ${ usages.length > 2 ? `${usages.slice(0, -1).join(', ')  } and ${  usages.slice(-1)}` : usages.join(' and ')}.`;
        }
    }

    getCurrentState(): DeleteTagDialogContentData {

        return this.context;
    }

    public valid: true;
}
