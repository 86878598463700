(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('sfHttp', sfHttpService);

    function sfHttpService($q, $http, $httpParamSerializer, $timeout, $exceptionHandler, $window, model, mockService) {

        const cancelers = {};

        this.get = function (url, config) {

            /*eslint angular/deferred: 0*/
            // ignore since the new syntax does not support notify
            const deferred = $q.defer();
            let serializedQueryParams = '';
            let cache = false;

            // We use the mock service to return mock data for the walkthrough for example.
            if (mockService.isRunning) {
                const response = mockService.get(url);
                if (response) {
                    return $q.resolve(response);
                }
            }

            if (config) {
                if (!config.ignoreLoadingBar) {
                    config.ignoreLoadingBar = false;
                }

                if (config.params) {
                    // Do this manually since used in multiple places
                    // Same as passing as `params` on the `config` passed to $http
                    serializedQueryParams = '?' + $httpParamSerializer(config.params);
                }
            }
            else {
                config = { ignoreLoadingBar: false };
            }

            if (config.downloadCSV) {
                url += '.csv' + serializedQueryParams;
                if (model.authStrategy === 'bearer') {
                    url += '&access_token=' + model.getToken();
                }

                $window.open(url, '_system', 'noopener');
                return deferred.resolve();
            }

            if (config.downloadExcel) {
                url += '.xlsx' + serializedQueryParams;
                if (model.authStrategy === 'bearer') {
                    url += '&access_token=' + model.getToken();
                }

                $window.open(url, '_system', 'noopener');
                return deferred.resolve();
            }

            if (config.cache) {
                cache = config.cache;
                delete config.cache;
            }

            if (cache) {
                const cacheResult = model.cache.getItem(url + serializedQueryParams);

                if (cacheResult !== null) {
                    config.ignoreLoadingBar = true;

                    $timeout(function () {

                        return deferred.notify(cacheResult);
                    });
                }
            }

            if (config.canceler) {
                if (cancelers[config.canceler]) {
                    cancelers[config.canceler].resolve();
                }

                cancelers[config.canceler] = $q.defer();
                config.timeout = cancelers[config.canceler].promise;
                delete config.canceler;
            }

            $http.get(url, config).then(function (result) {

                if (cache) {
                    try {
                        model.cache.setItem(url + serializedQueryParams, result);
                    }
                    catch (err) {
                        if (err.name !== 'QuotaExceededError') {
                            $exceptionHandler(err);
                        }
                    }
                }

                return deferred.resolve(result);
            }, function (err) {

                if (cache) {
                    model.cache.removeItem(url + serializedQueryParams);
                }

                return deferred.reject(err);
            });

            return deferred.promise;
        };

        this.delete = function (url, config) {

            config = config || {};

            // Check explicitly for false value
            if (config.ignoreLoadingBar !== false) {
                config.ignoreLoadingBar = true;
            }

            return $http.delete(url, config);
        };

        this.post = function (url, data, config) {

            config = config || {};

            // Check explicitly for false value
            if (config.ignoreLoadingBar !== false) {
                config.ignoreLoadingBar = true;
            }

            return $http.post(url, data, config);
        };

        this.put = function (url, data, config) {

            config = config || {};

            // Check explicitly for false value
            if (config.ignoreLoadingBar !== false) {
                config.ignoreLoadingBar = true;
            }

            return $http.put(url, data, config);
        };

        this.head = function (url, config) {

            config = config || {};

            if (mockService.isRunning) {
                const response = mockService.get(url);
                if (response) {
                    return $q.resolve(response);
                }
            }

            // Check explicitly for false value
            if (config.ignoreLoadingBar !== false) {
                config.ignoreLoadingBar = true;
            }

            return $http.head(url, config);
        };
    }
})();
