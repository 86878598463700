<sfx-toolbar enableDivider>
    Email settings
</sfx-toolbar>
<sfx-content>

    <sfx-section disableContentPadding>

        <ng-container *ngIf="emailAccountsSubject$.value.emailAccounts.length === 0 && !loading">

            <sfx-empty-state image="images/email_empty.png">
                <h1 class="accent">No email accounts connected yet!</h1>
                <h1 class="accent">Click the plus button below to start.</h1>
            </sfx-empty-state>

        </ng-container>

        <ng-container *ngFor="let emailAccount of emailAccountsToReconnect$ | async">

            <mat-card>
                <mat-card-header>
                    <img [src]="emailAccount.avatar" mat-card-avatar/>
                    <mat-card-title>
                        {{ emailAccount.name }}
                        <span class="text-color font-weight_normal">{{ emailAccount.primary ? ' - primary' : ''}}</span>
                    </mat-card-title>
                    <mat-card-subtitle>{{ emailAccount.email }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p class="warning">
                        The email infrastructure has been upgraded! Please reconnect.
                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button (click)="reconnect(emailAccount)" [disabled]="emailAccount.disconnecting">Reconnect</button>
                    <button mat-button track-id="disconnect-email" (click)="disconnect(emailAccount)" [disabled]="emailAccount.disconnecting">Disconnect</button>
                </mat-card-actions>
            </mat-card>

        </ng-container>

        <ng-container *ngFor="let emailAccount of emailAccounts$ | async">

            <mat-card>
                <mat-card-header>
                    <img [src]="emailAccount.avatar" mat-card-avatar/>
                    <mat-card-title>
                        {{ emailAccount.name }}
                        <span class="text-color font-weight_normal">{{ emailAccount.primary ? ' - primary' : ''}}</span>
                    </mat-card-title>
                    <mat-card-subtitle>{{ emailAccount.email }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p *ngIf="emailAccount.status === 'RECONNECT'" class="warning">We've updated our Gmail integration to use more specific permissions. Please reconnect.</p>
                    <p *ngIf="emailAccount.status === 'ERROR'" class="warning">It looks like there is something wrong with your connection! Please reconnect.</p>
                    <p *ngIf="emailAccount.status !== 'ERROR' && emailAccount.status !== 'RECONNECT'">{{ syncText[emailAccount.sync_status] }}</p>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button *ngIf="emailAccount.status === 'ERROR' || emailAccount.status === 'RECONNECT'" (click)="reconnect(emailAccount)" [disabled]="emailAccount.disconnecting">Reconnect</button>
                    <button mat-button *ngIf="!emailAccount.primary && emailAccount.status !== 'ERROR' && emailAccount.status !== 'RECONNECT' && modelService.me.team.payment_type !== 'samsung'" (click)="makePrimary(emailAccount)" [disabled]="emailAccount.disconnecting">Make primary</button>
                    <button mat-button *ngIf="emailAccount.type !== 'google' && emailAccount.type !== 'microsoft' && emailAccount.status !== 'ERROR' && emailAccount.status !== 'RECONNECT' && (emailAccount.smtp_host && emailAccount.smtp_host)" (click)="updateSMTPSettings(emailAccount)" [disabled]="emailAccount.disconnecting">Edit SMTP settings</button>
                    <button mat-button *ngIf="emailAccount.type !== 'google' && emailAccount.type !== 'microsoft' && emailAccount.status !== 'ERROR' && emailAccount.status !== 'RECONNECT' && (!emailAccount.smtp_host || !emailAccount.smtp_host)" (click)="updateSMTPSettings(emailAccount)" [disabled]="emailAccount.disconnecting">Add SMTP settings</button>
                    <button mat-button track-id="edit-signature" (click)="editSignature(emailAccount)" [disabled]="emailAccount.disconnecting">Edit signature</button>
                    <button mat-button track-id="set-quota" (click)="setQuota(emailAccount)" [disabled]="emailAccount.disconnecting">Set quota</button>
                    <button mat-button track-id="disconnect-email" (click)="disconnect(emailAccount)" [disabled]="emailAccount.disconnecting">Disconnect</button>
                </mat-card-actions>
            </mat-card>

        </ng-container>

    </sfx-section>

</sfx-content>

<sfx-speed-dial class="fab-dial" aria-label="connect-email">
    <button speedDialAction icon="sf-icon-email" label="Other via IMAP" track-id="connect-other-email" [action]="this.connectOther.bind(this)"></button>
    <button speedDialAction icon="sf-icon-outlook" label="Outlook.com" track-id="connect-outlook-email" [action]="this.connectOffice365.bind(this)"></button>
    <button speedDialAction icon="sf-icon-office" label="Office 365" track-id="connect-office-email" [action]="this.connectOffice365.bind(this)"></button>
    <button speedDialAction icon="sf-icon-google" label="Gmail" track-id="connect-google-email" [action]="this.connectGmail.bind(this, true)"></button>
</sfx-speed-dial>
