<div cdkVirtualScrollingElement class="main-container">
    <cdk-virtual-scroll-viewport [itemSize]="rowSize">

        <mat-list>

            <!-- View for fullscreen -->
            <ng-container *ngIf="media.isActive('gt-sm')">

                <div *cdkVirtualFor="let entry of entries; let i = index;">

                    <ng-container *ngIf="entry.date">
                        <sfx-header data-flex class="list-header">
                            <span class="list-header__content">
                                {{ entry.date | date: 'MMMM yyyy'}}
                            </span>
                        </sfx-header>
                    </ng-container>

                    <ng-container *ngIf="entry.invoice">
                        <mat-list-item data-layout="row">
                            <div class="list-item-text list-item-date">
                                <p *ngIf="!entry.invoice.hideDate">{{entry.invoice.date}}</p>
                            </div>
                            <div class="list-item-text" data-flex>
                                <p>{{entry.invoice.description}}</p>
                            </div>
                            <div data-layout-align="end center" data-layout="row" class="list-item-text chargeColumn" data-flex>
                                <a track-id="download-invoice" [hidden]="!entry.invoice.invoice_pdf" [href]="entry.invoice.invoice_pdf " target="_blank" rel="noopener" class="reply">
                                    <mat-icon fontSet="salesflare" fontIcon="sf-icon-file-pdf" class="icon-18"></mat-icon>
                                </a>
                                <p [ngClass]="{'credited': entry.invoice.type === 'credited'}">
                                    {{entry.invoice.chargeLine}}
                                    <span class="amount">
                                        {{ entry.invoice.total  | currency : entry.invoice.currency }}
                                    </span>
                                </p>
                            </div>
                        </mat-list-item>
                        <mat-divider *ngIf="invoices.length > 0">

                        </mat-divider>
                    </ng-container>

                </div>
            </ng-container>

            <!-- View for mobile -->
            <ng-container *ngIf="!media.isActive('gt-sm')">

                <div *cdkVirtualFor="let entry of entries; let i = index;">

                    <ng-container *ngIf="entry.date">
                        <sfx-header data-flex class="list-header" aria-label="date" [disableDividers]="true">
                            <span class="list-header__content">
                                {{ entry.date | date: 'MMMM yyyy'}}
                            </span>
                        </sfx-header>
                    </ng-container>

                    <ng-container *ngIf="entry.invoice">
                        <mat-list-item aria-label="invoice">
                            <div matListItemLine>
                                <div data-flex data-layout="row" class="line-height-24">
                                    <div data-flex>
                                        <h1 class="ellipsis accent mobile-description" [title]="entry.invoice.description" data-flex>{{ entry.invoice.description }}</h1>
                                    </div>
                                    <div>
                                    <span>
                                        {{ entry.invoice.date | date: 'd MMM' }}
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <div matListItemLine class="list-item-detail line-height-24 chargeColumn mobile" data-flex data-layout="row" data-layout-align="start center">
                                <span [ngClass]="{'credited': entry.invoice.type === 'credited'}">
                                    {{ entry.invoice.chargeLine }}
                                    <span class="amount">
                                        {{ entry.invoice.total  | currency : entry.invoice.currency }}
                                    </span>
                                </span>
                                <a track-id="download-invoice" [hidden]="!entry.invoice.invoice_pdf" [href]="entry.invoice.invoice_pdf" target="_blank" rel="noopener" class="reply">
                                    <mat-icon fontSet="salesflare" fontIcon="sf-icon-file-pdf" class="icon-14"></mat-icon>
                                </a>
                            </div>
                        </mat-list-item>
                        <mat-divider *ngIf="invoices.length > 0">

                        </mat-divider>
                    </ng-container>

                </div>
            </ng-container>

        </mat-list>
    </cdk-virtual-scroll-viewport>

</div>
