(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditUsersController', EditUsersController);

    function EditUsersController($rootScope, $scope, $stateParams, $state, $timeout, $mdMedia, $mdDialog, $exceptionHandler, utils, events, users, account, model, bulkService, sfDiscardDialog) {

        const dirtyUsers = [];
        let accountUsers = [];
        let filterTimeout = null;

        $scope.users = [];

        if ($stateParams.mode === 'edit') {
            $scope.title = 'Manage team';
            account.getUsers($stateParams.account).then(function (response) {

                accountUsers = response.data;

                return getUsers();
            });
        }
        else if ($stateParams.mode === 'bulkAdd') {
            $scope.title = 'Bulk Add Users';
            getUsers();
        }
        else if ($stateParams.mode === 'bulkRemove') {
            $scope.title = 'Bulk Remove Users';
            getUsers();
        }

        $scope.back = function () {

            if (dirtyUsers.length > 0) {
                return sfDiscardDialog.show(false, 'users').then(close);
            }
            else {
                return close();
            }
        };

        function close(updated) {

            if ($mdMedia('gt-sm')) {
                if (updated) {
                    $rootScope.$broadcast(events.account.updated);
                }

                return $mdDialog.hide();
            }
            else {
                return $rootScope.back();
            }
        }

        $scope.$watch('filter', function (newValue, oldValue) {

            if (newValue !== oldValue) {
                $timeout.cancel(filterTimeout);

                filterTimeout = $timeout(function () {

                    return getUsers();
                }, 750);
            }
        });

        $scope.selectUser = function (user) {

            user._dirty = !user._dirty;

            if (user._dirty) {
                dirtyUsers.push({ id: user.id, _dirty: user._dirty });
            }
            else {
                let index;

                for (const [i, dirtyUser] of dirtyUsers.entries()) {
                    if (dirtyUser.id === user.id) {
                        index = i;

                        continue;
                    }
                }

                dirtyUsers.splice(index, 1);
            }
        };

        $scope.submit = function () {

            let cntDeletedUsers = 0;
            let cntAddedUsers = 0;
            let deletedMyself = false;
            let addedMyself = false;

            if (dirtyUsers.length === 0) {
                return close(true);
            }

            if ($stateParams.mode === 'bulkAdd') {
                const confirmAdd = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title('Are you sure?')
                    .textContent('All selected users will be added to the selected accounts!')
                    .ok('I\'m sure')
                    .cancel('cancel');

                return $mdDialog.show(confirmAdd).then(function () {

                    return bulkService.addUsersToAccounts({ filter: $stateParams.options, users: dirtyUsers })
                        .then(function () {

                            if ($mdMedia('gt-sm')) {
                                $rootScope.$broadcast(events.refreshData);
                            }

                            return close();
                        })
                        .catch(function (err) {

                            $exceptionHandler(err);

                            return utils.showErrorToast();
                        });
                });
            }

            if ($stateParams.mode === 'bulkRemove') {
                const confirmRemove = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title('Are you sure?')
                    .textContent('All selected users will be removed from the selected accounts!')
                    .ok('I\'m sure')
                    .cancel('cancel');

                return $mdDialog.show(confirmRemove).then(function () {

                    return bulkService.removeUsersFromAccounts({ filter: $stateParams.options, users: dirtyUsers })
                        .then(function () {

                            if ($mdMedia('gt-sm')) {
                                $rootScope.$broadcast(events.refreshData);
                            }

                            return close();
                        })
                        .catch(function (err) {

                            $exceptionHandler(err);

                            return utils.showErrorToast();
                        });
                });
            }

            // Remove view state properties and set _deleted if user was originally in account
            dirtyUsers.forEach(function (user) {

                accountUsers.forEach(function (accountUser) {

                    if (user.id === accountUser.id) {
                        cntDeletedUsers++;
                        user._deleted = true;

                        if (model.me.id === user.id) {
                            deletedMyself = true;
                        }
                    }
                    else {
                        cntAddedUsers++;
                    }
                });

                if (model.me.id === user.id && !deletedMyself) {
                    addedMyself = true;
                }
            });

            if (accountUsers.length === cntDeletedUsers && cntAddedUsers === 0) {
                const confirm = $mdDialog.confirm({ multiple: true })
                    .htmlContent('<p>You removed all users from the account.<br/>Do you want to delete the account?</p>')
                    .ok('Ok')
                    .cancel('Cancel');

                return $mdDialog.show(confirm).then(function () {

                    return account.delete({ id: $stateParams.account }).then(function () {

                        if ($mdMedia('gt-sm')) {
                            $mdDialog.hide();
                        }

                        if ($rootScope.history[$rootScope.history.length - 1].fromState.name.includes('accounts')) {
                            return $state.go('accounts', {}, { reload: true });
                        }
                        else {
                            return $state.go('tasks.today', {}, { reload: true });
                        }
                    });
                }, angular.noop);
            }
            else {
                return account.updateUsers($stateParams.account, dirtyUsers).then(function () {

                    if ($stateParams.mode === 'edit' && (deletedMyself || addedMyself)) {
                        if ($mdMedia('gt-sm')) {
                            $mdDialog.hide();
                        }

                        //Get the account to ensure proper cached data
                        return account.get($stateParams.account).then(function () {

                            if ($rootScope.history[$rootScope.history.length - 1].fromState.name.includes('accounts')) {
                                // ALso send along possible LinkedIn contacts that were in the state params of the previous state
                                // This makes it so you see the suggested contact after adding yourself to the account
                                if ($mdMedia('gt-sm')) {
                                    return $state.go('accounts.account.feed', { id: $stateParams.account, contacts: $rootScope.history[$rootScope.history.length - 1].fromParams.contacts }, { reload: true });
                                }

                                return $state.go('accounts.account.info', { id: $stateParams.account, contacts: $rootScope.history[$rootScope.history.length - 1].fromParams.contacts }, { reload: true });
                            }

                            return $state.go('tasks.today', {}, { reload: true });
                        });
                    }

                    return close(true);
                });
            }
        };

        function getUsers() {

            return users.get($scope.filter, false, ['user.disabled', 'name']).then(function (response) {

                // Select and set dirty on new user list
                for (let i = 0; i < response.data.length; ++i) {
                    for (const accountUser of accountUsers) {
                        if (accountUser.id === response.data[i].id) {
                            response.data[i].selected = true;
                        }
                    }

                    for (const [j, dirtyUser] of dirtyUsers.entries()) {
                        if (dirtyUser.id === response.data[j].id) {
                            response.data[j]._dirty = true;
                            response.data[j].selected = !response.data[j].selected;
                        }
                    }
                }

                $scope.users = response.data;
            });
        }
    }
})();
