(function () {
    'use strict';

    angular
        .module('salesflare.components.workflowAnalytics', [
            'salesflare.components.workflowAnalytics.contactAudience'
        ])
        .component('sfWorkflowAnalytics', {
            templateUrl: 'app-ajs/components/workflows/workflow/analytics/workflowAnalytics.html',
            controller,
            controllerAs: 'vm'
        });

    function controller($document, $state, $stateParams, $mdMedia, $timeout, workflowService, contactsService, utils) {

        const vm = this;
        let searchDebounce;
        const allRecordTypeStrings = {
            contact: {
                singular: 'contact',
                plural: 'contacts'
            }
        };

        // TODO: Make record_type generic, empty state text (so something else than 'no one')
        const tabInfo = {
            total: [{
                emptyStateText: 'No one has entered your workflow yet, hang tight!',
                emptySearchText: 'No contacts found.'
            }],
            in: [{
                emptyStateText: 'No one has entered your workflow yet, hang tight!',
                emptySearchText: 'No contacts found.'
            }],
            exited: [{
                emptyStateText: 'No one has exited the workflow yet, hang tight!',
                emptySearchText: 'No contacts found.'
            }],
            met_goal: [{
                emptyStateText: 'No one has met your workflow\'s goal yet, hang tight!',
                emptySearchText: 'No contacts found.'
            }]
        };

        vm.$mdMedia = $mdMedia;
        vm.workflow = $stateParams.workflow;
        vm.workflowId = $stateParams.id;
        vm.viewingTab = $stateParams.viewing;
        vm.returnState = $stateParams.returnState;
        vm.viewingTabInfo = tabInfo[vm.viewingTab][0];
        vm.insufficientPermissionsMessage = 'You don\'t have permission to edit this workflow. Ask an admin.';

        vm.searchObject = {
            search: $stateParams.search,
            getSearch: function () {

                return this.search;
            },
            setSearch: function (s) {

                this.search = s;

                return utils.setSearchInUrl({ search: this.search });
            }
        };

        vm.searchMode = !!$stateParams.search;

        vm.$onInit = function () {

            vm.activeFilter = getActiveFilter();

            if (!vm.workflow) {
                return workflowService.get(vm.workflowId).then(function (response) {

                    vm.workflow = response.data;

                    vm.analytics = workflowService.prepareAnalyticData(vm.workflow.analytics);
                });
            }

            vm.analytics = workflowService.prepareAnalyticData(vm.workflow.analytics);
        };

        vm.audience = {
            toLoad: 0,
            numLoaded: 0,
            items: [],
            topIndex: 0,

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + 20;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad < index) {
                    this.toLoad += 20;

                    const bound = angular.bind(this, audienceResponseHandler);

                    const filter = angular.copy(vm.activeFilter.filter);

                    if (vm.searchObject.getSearch()) {
                        filter.search = vm.searchObject.getSearch();
                    }

                    const requestConfig = {
                        filter
                    };

                    if (this.items.length === 0) {
                        requestConfig.limit = this.toLoad;
                        return workflowService.getAudience(vm.workflowId, requestConfig).then(bound, null, bound);
                    }

                    requestConfig.limit = 20;
                    requestConfig.offset = this.numLoaded;
                    return workflowService.getAudience(vm.workflowId, requestConfig).then(angular.bind(this, function (response) {

                        this.items = [...this.items, ...response.data];
                        this.numLoaded = this.toLoad;
                    }));
                }
            },

            // Forces reset of object
            reload: function () {

                this.toLoad = 0;
                this.numLoaded = 0;
                this.items = [];
                this.doneLoading = false;
                this.topIndex = 0;
            }
        };

        function audienceResponseHandler(response) {

            this.items = response.data;
            this.numLoaded = this.toLoad;
            vm.showEmptyState = response.data.length === 0;
            this.doneLoading = true;
        }

        vm.doSearch = function (search) {

            vm.showEmptyState = false;
            $timeout.cancel(searchDebounce);

            searchDebounce = $timeout(function () {

                vm.searchObject.setSearch(search);

                return vm.audience.reload();
            }, 750);
        };

        vm.clearSearch = function () {

            // Don't execute debounced search if search cleared
            $timeout.cancel(searchDebounce);
            vm.searchObject.setSearch();
            return vm.audience.reload();
        };

        vm.setSearchMode = function (mode) {

            if (vm.searchMode && !mode) {
                vm.searchMode = mode;

                if (!mode && vm.searchObject.getSearch()) {
                    // Since we're going to do a reload of the data, we don't know yet if we need to show the empty state
                    vm.showEmptyState = false;
                    return vm.clearSearch();
                }

                vm.searchObject.setSearch('');
            }

            vm.searchMode = mode;

            if (vm.searchMode) {
                utils.forceFocus($document[0].querySelector('#searchbox'));
            }
        };

        vm.back = function () {

            if (vm.returnState) {
                if (vm.workflowId) {
                    return $state.go(vm.returnState, { id: $stateParams.id });
                }

                return $state.go(vm.returnState);
            }

            return $state.go('workflows');
        };

        vm.selectTab = function (analyticName) {

            // Refetch workflow to update analytics
            return workflowService.get(vm.workflowId).then(function (response) {

                vm.workflow = response.data;
                vm.analytics = workflowService.prepareAnalyticData(vm.workflow.analytics);

                return $state.go('workflow-analytics', {
                    id: $stateParams.id,
                    viewing: analyticName,
                    workflow: vm.workflow,
                    returnState: vm.returnState
                });
            });
        };

        function getActiveFilter() {

            return workflowService.getActiveFilter(vm.viewingTab);
        }

        vm.getTitle = function () {

            if (!vm.workflow) {
                return;
            }

            let title;

            const recordTypeStrings = allRecordTypeStrings[vm.workflow.record_type];
            const currentCount = vm.workflow.analytics[vm.viewingTab].value;

            switch (vm.viewingTab) {
                case 'total':
                    return currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular + ' has') : (' ' + recordTypeStrings.plural + ' have')) + ' entered ' + vm.workflow.name;
                case 'in':
                    title = currentCount + (currentCount === 1 ? (' ' + recordTypeStrings.singular + ' is') : (' ' + recordTypeStrings.plural + ' are')) + ' in ' + vm.workflow.name;
                    return title;
                case 'exited':
                    title = currentCount + (currentCount === 1 ? ' ' + recordTypeStrings.singular : ' ' + recordTypeStrings.plural) + ' exited ' + vm.workflow.name;
                    return title;
                case 'met_goal':
                    title = currentCount + (currentCount === 1 ? ' ' + recordTypeStrings.singular : ' ' + recordTypeStrings.plural) + ' met the goal of ' + vm.workflow.name;
                    return title;
            }

            return title;
        };

        vm.send = function () {

            const filter = angular.copy(getActiveFilter().filter);
            // We need to include the 'internal' and 'query_builder_id' properties here for the humanReadableAdvancedFilter service to work correctly
            filter.rules.push({
                id: 'workflow.id',
                query_builder_id: 'workflow.id',
                operator: 'equal',
                value: [vm.workflow.id],
                internal: true
            });

            return $state.go('composeWorkflow', {
                filter,
                disableEditFilter: true,
                filterText: getFilterText()
            });
        };

        vm.onUpdateAudience = function () {

            return workflowService.get(vm.workflowId).then(function (response) {

                vm.workflow = response.data;

                vm.analytics = workflowService.prepareAnalyticData(vm.workflow.analytics);
            });
        };

        function getFilterText() {

            switch (vm.viewingTab) {
                case 'in':
                    return 'All contacts entering the workflow';
                case 'exited':
                    return 'All contacts exiting the workflow';
                case 'met_goal':
                    return 'All contacts successfully completing the workflow';
                default:
                    return '';
            }
        }

        vm.export = function (type) {

            if (!vm.workflow) {
                return;
            }

            const filter = angular.copy(getActiveFilter().filter);
            filter.rules.push({
                id: 'workflow.id',
                operator: 'equal',
                value: [vm.workflow.id]
            });

            if (vm.workflow.record_type === 'contact') {
                const filterGetOptions = {
                    filterRules: filter,
                    limit: 100000,
                    offset: 0,
                    downloadCSV: type === 'csv',
                    downloadExcel: type === 'excel'
                };

                return contactsService.filterGet(filterGetOptions);
            }
        };
    }
})();
