(function () {
    'use strict';

    angular
        .module('salesflare.components.creditPackageConfirmationDialog', [])
        .factory('sfCreditPackageConfirmationDialog', sfCreditPackageConfirmationDialog);

    function sfCreditPackageConfirmationDialog($mdDialog) {

        return {
            show
        };

        function show(currentPlan, futurePlan, subscribed) {

            return $mdDialog.show({
                clickOutsideToClose: false,
                escapeToClose: false,
                multiple: true,
                hasBackdrop: true,
                controller: 'CreditPackageConfirmationDialogController',
                controllerAs: 'vm',
                templateUrl: 'app-ajs/components/creditPackageConfirmationdialog/creditPackageConfirmationDialog.html',
                locals: {
                    currentPlan,
                    futurePlan,
                    subscribed
                },
                bindToController: true
            });
        }
    }
})();
