(function () {
    'use strict';

    angular
        .module('salesflare.components.workflowAnalytics.contactAudience', ['salesflare.components.workflowUtils.exitWorkflowRecordButton'])
        .component('sfWorkflowContactAudience', {
            templateUrl: 'app-ajs/components/workflows/workflow/analytics/audience/contactAudience.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                contacts: '<',
                workflowId: '<',
                onUpdateAudience: '<',
                viewingTab: '<'
            }
        });

    function controller($mdDialog, $mdMedia, $state) {

        const vm = this;
        vm.$mdMedia = $mdMedia;

        vm.goToContact = function (contactId) {

            return $state.go('contact', { id: contactId });
        };

        vm.goToAccount = function (account) {

            if (account && account.id) {
                return $state.go('accounts.account.feed', { id: account.id, name: account.name });
            }
        };

        vm.onExit = ($event) => {

            const exitedContact = vm.contacts.items.find((contact) => contact.id === $event.recordId);

            if (exitedContact) {
                exitedContact.exit_date = $event.exitDate;
                exitedContact.met_goal_date = $event.metGoalDate;
            }

            if (vm.onUpdateAudience) {
                vm.onUpdateAudience();
            }
        };

        vm.onReEnter = ($event) => {

            const reEnteredContact = vm.contacts.items.find((contact) => contact.id === $event.recordId);

            if (reEnteredContact) {
                reEnteredContact.exit_date = $event.exitDate;
                reEnteredContact.met_goal_date = $event.metGoalDate;
            }

            if (vm.onUpdateAudience) {
                vm.onUpdateAudience();
            }
        };
    }
})();
