(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('WalkthroughDialogController', WalkthroughDialogController);

    function WalkthroughDialogController($rootScope, $scope, $mdDialog, model) {

        $scope.model = model;
        $scope.steps = null;
        let activeIndex = 0;

        $scope.steps = [
            { id: 'get-equipped', templateUrl: 'partials/selfselectgetequipped.html' }
        ];

        $scope.steps[activeIndex].active = true;

        $scope.nextBenefit = function () {

            $scope.steps[activeIndex].active = false;
            ++activeIndex;
            $scope.steps[activeIndex].active = true;
        };

        $scope.previousBenefit = function () {

            $scope.steps[activeIndex].active = false;
            --activeIndex;
            $scope.steps[activeIndex].active = true;
        };

        $scope.saveAndNext = function (save) {

            return save().then(function () {

                return $scope.nextBenefit();
            });
        };

        $scope.saveAndClose = function (save) {

            return save().then(function () {

                $rootScope.$broadcast('refreshData');

                return $mdDialog.hide();
            });
        };

        $scope.closeDialog = $mdDialog.hide;
    }
})();
