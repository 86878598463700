(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('BillingSettingsOverviewController', BillingSettingsOverviewController);

    function BillingSettingsOverviewController(cfpLoadingBar, $scope, $rootScope, $filter, $mdDialog, $q, $state, $stateParams, $exceptionHandler, $timeout, authentication, model, billing, plansService, utils, stripeService, statusBarService) {

        if ($rootScope.history[$rootScope.history.length - 1].fromState.name.includes('billingSettings.')) {
            $rootScope.history.pop();
        }

        $scope.doneLoading = false;
        $scope.showSCAButton = false;
        $scope.disableSCAButton = false;
        $scope.hasCreditPackage = !!model.me.team.credit_package;

        let SCAData;

        if ($stateParams.subscribedFromCheckout) {
            $timeout(() => {

                return $mdDialog.show($mdDialog.referAFriendDialog());
            }, 500);
        }

        if (model.me.team.subscribed && model.me.team.payment_type === 'stripe') {
            billing.get().then(billingResponseHandler, null, billingResponseHandler);
        }
        else if (model.me.team.payment_type === 'samsung') {
            // We don't need to set/get anything specific for Samsung AppStack teams, handled in the view
        }
        else if (model.me.team.payment_type === 'free') {
            $scope.subscribeText = 'Your team has free access to Salesflare!';
        }
        else if (model.me.team.discount_code && model.me.team.discount_code.lastIndexOf('SUMO', 0) === 0) {
            $scope.subscribeText = 'You\'re currently on a lifetime AppSumo subscription';
            if (model.me.restricted) {
                $scope.subscribeText += ', which was disabled because payments for additional team members (at 50% off) kept failing. Please resubscribe below.';
            }
            else {
                if (model.me.team.free_users > 0) {
                    $scope.subscribeText += ' with ' + (model.me.team.free_users + 1) + ' free users';
                }

                $scope.subscribeText += '. Additional team members can be added at 50% off.';
            }
        }
        else {
            $scope.subscribeText = 'Your team is not currently on an active subscription.';
        }

        $scope.showCreditUsage = function () {

            const options = {
                historical_team_credit_usage: $scope.billingOverview.historical_team_credit_usage,
                current_month_per_user_credit_usage: $scope.billingOverview.current_month_per_user_credit_usage
            };

            return $mdDialog.show($mdDialog.viewCreditUsageDialog(options));
        };

        $scope.updateCard = function () {

            return $mdDialog.show({
                clickOutsideToClose: true,
                controller: 'EditCardDialogController',
                templateUrl: 'partials/editcarddialog.html',
                locals: {
                    vat: $scope.billingOverview.vat
                },
                bindToController: true
            }).then(function (data) {

                // Show loading as billing update + SCA might take some time
                cfpLoadingBar.start();

                // IgnoreLoadingBar is used, 'cause we already started it above. We don't want the http call to complete the loading circle
                return billing.update({ source: data.token.id, vat: data.vat }, { ignoreLoadingBar: true })
                    .then(function (response) {

                        return handleSCAAndReFetchData(response, 'Your card information has been updated. This card will be used for future charges.');
                    })
                    .catch(function (err) {

                        cfpLoadingBar.complete();

                        if (err && err.data && err.data.message) {
                            utils.showErrorToast(err.data.message);
                        }
                    });
            });
        };

        $scope.authorizePayment = function () {

            $scope.disableSCAButton = true;

            // Show loading as billing update + SCA might take some time
            cfpLoadingBar.start();

            return handleSCAAndReFetchData({ data: SCAData }, 'Payment was successfully authorized.')
                .then(function () {

                    $scope.disableSCAButton = false;
                })
                .catch(function (err) {

                    $scope.disableSCAButton = false;
                    return $exceptionHandler(err);
                });
        };

        $scope.askBillingQuestion = () => {
            Intercom('showNewMessage', '');
        };

        $scope.cancelBilling = () => {
            Intercom('showNewMessage', 'Hello team Salesflare!\n\nI would like to cancel my subscription.\n\nThe feedback I\'d like to share about my experience is:\n\n\n\nThanks!');
        };

        $scope.updateBillingFrequency = function () {

            const currentPlanPricing = plansService.get()[utils.getPlanNameBySalesflarePlanId(model.me.team.plan)].price;
            const pricingSwitchOptions = {
                discounted: $scope.billingOverview.applied_discount && $scope.billingOverview.applied_discount.toLowerCase().includes('sumo'),
                htmlEncodedBillingCurrency: $scope.billingOverview.htmlEncodedBillingCurrency,
                quantity: $scope.billingOverview.subscription_quantity,
                left: {
                    value: 'annually',
                    title: 'Annually',
                    pricePerMonth: currentPlanPricing.annually.pricePerMonth,
                    pricePerBillingCycle: currentPlanPricing.annually.pricePerBillingCycle,
                    discountedPricePerMonth: currentPlanPricing.annually.pricePerMonth / 2,
                    discountedPricePerBillingCycle: currentPlanPricing.annually.pricePerBillingCycle / 2
                },
                right: {
                    value: 'monthly',
                    title: 'Monthly',
                    pricePerMonth: currentPlanPricing.monthly.pricePerMonth,
                    pricePerBillingCycle: currentPlanPricing.monthly.pricePerBillingCycle,
                    discountedPricePerMonth: currentPlanPricing.monthly.pricePerMonth / 2,
                    discountedPricePerBillingCycle: currentPlanPricing.monthly.pricePerBillingCycle / 2
                }
            };

            return $mdDialog.show({
                clickOutsideToClose: true,
                controller: 'EditPlanDialogController',
                templateUrl: 'partials/editplandialog.html',
                locals: {
                    data: {
                        selectedValue: $scope.billingOverview.billing_frequency,
                        pricingSwitchOptions,
                        title: 'Update billing frequency',
                        editPlan: false
                    }
                }
            }).then(function (futureBillingFrequency) {

                // No need to update the billing frequency since it hasn't changed
                if (!futureBillingFrequency || futureBillingFrequency === $scope.billingOverview.billing_frequency) {
                    return;
                }

                // TODO: Support Enterprise when available on Stripe
                const planName = model.me.team.plan === 3 ? 'GROWTH' : 'PRO';
                const stripePlanId = utils.getStripePlanId(planName, futureBillingFrequency, $scope.billingOverview.billing_currency);

                // Show loading as billing update + SCA might take some time
                cfpLoadingBar.start();

                // IgnoreLoadingBar is used, 'cause we already started it above. We don't want the http call to complete the loading circle
                return billing.update({ plan: stripePlanId }, { ignoreLoadingBar: true })
                    .then(function (response) {

                        return handleSCAAndReFetchData(response, 'Your billing frequency has been updated.');
                    })
                    .catch(function (err) {

                        cfpLoadingBar.complete();

                        if (err && err.data && err.data.message) {
                            utils.showErrorToast(err.data.message);
                        }
                    });
            });
        };

        $scope.updatePlan = function () {

            return $state.go('plans', { fromBilling: true });
        };

        /**
         *
         * Best to call `cfpLoadingBar.start` before you start a billing operation as the update + SCA might take a while
         * The z-index of the SCA dialog is the biggest it can be so it will be shown over the spinner
         *
         * @param {Object} response
         * @param {Object} response.data
         * @param {String} response.data.client_secret
         * @param {String} response.data.status
         * @param {String} successMessage
         * @returns {Promise}
         */
        function handleSCAAndReFetchData(response, successMessage) {

            // `Requires action` basically means we need to do a SCA flow
            if (response.data.status === 'requires_action') {
                return stripeService.stripe[response.data.type === 'setup_intent' ? 'handleCardSetup' : 'handleCardPayment'](response.data.client_secret).then(function (result) {

                    // Hide loading as we are done
                    cfpLoadingBar.complete();

                    if (result.error) {

                        let message;
                        switch (result.error.code) {
                            case 'payment_intent_authentication_failure':
                                message = 'Please update your card details to complete the payment.';
                                break;
                            default:
                                message = result.error.message;
                        }

                        utils.showErrorToast(message);
                        // Log the error
                        const formattedError = new Error(result.error.code);
                        formattedError.data = result.error;
                        $exceptionHandler(formattedError);
                    }
                    else {
                        utils.showSuccessToast(successMessage);
                    }

                    // We can't rely on Stripe web hooks always arriving to the server faster than when we call the server to re-fetch data
                    // That's why we want to explicitly sync data with Stripe first, before re-fetching.
                    return billing.syncStripeData()
                        .then(function (syncResponse) {

                            if (syncResponse.error) {
                                // Log the error
                                const formattedError = new Error(result.error.code);
                                formattedError.data = result.error;
                                $exceptionHandler(formattedError);
                            }

                            // If errored still re-fetch data as the plan will have updated but will most likely be in a `payment required` state
                            return $q.all([
                                // IgnoreLoadingBar is used, 'cause we already started it above. We don't want the http call to complete the loading circle
                                authentication.fetchMe({ ignoreLoadingBar: true }),
                                function getBilling() {

                                    return billing.get().then(function (billingResponse) {

                                        return billingResponseHandler(billingResponse);
                                    });
                                }()
                            ]);
                        });
                });
            }

            // Hide loading as we are done
            cfpLoadingBar.complete();

            utils.showSuccessToast(successMessage);

            return $q.all([
                // IgnoreLoadingBar is used, 'cause we already started it above. We don't want the http call to complete the loading circle
                authentication.fetchMe({ ignoreLoadingBar: true }),
                function getBilling() {

                    return billing.get().then(function (billingResponse) {

                        return billingResponseHandler(billingResponse);
                    });
                }()
            ]);
        }

        function billingResponseHandler(response) {

            if (angular.equals(response.data, {})) {
                $scope.billingOverview = null;
                $scope.doneLoading = true;
                return;
            }

            $scope.restrictedReason = model.me.restricted_reason;

            // Compose the billingOverview object
            $scope.billingOverview = response.data;

            $scope.billingOverview.htmlEncodedBillingCurrency = response.data.billing_currency === 'EUR' ? '&#128;' : '&#36;';
            $scope.billingOverview.subscription_renewal_date = $filter('date')(response.data.subscription_renewal_date, 'MMMM d, y');
            $scope.billingOverview.next_charge.date = $filter('date')(response.data.next_charge.date, 'MMMM d, y');
            $scope.billingOverview.billing_credits = response.data.billing_credits >= 0 ? 0 : response.data.billing_credits / -100;
            $scope.billingOverview.plan_name = response.data.plan_name;
            $scope.billingOverview.plan = response.data.plan;
            $scope.billingOverview.credit_reset_date = $filter('date')(response.data.credit_reset_date, 'MMMM d, y');
            $scope.billingOverview.credit_quota = response.data.plan === 5 ? 'Unlimited' : $scope.billingOverview.credit_quota;

            $scope.payingHtmlText = 'You currently have <b>' + response.data.enabled_user_count + (response.data.enabled_user_count === 1 ? ' user' : ' users') + '</b> on your team';
            if ($scope.billingOverview.applied_discount && $scope.billingOverview.applied_discount_type === 'threeForOne') {
                $scope.payingHtmlText += '. <b>You have an active 3 for 1 discount until ' + $filter('date')($scope.billingOverview.subscription_renewal_date, 'MMMM d, y') + '</b>';
                $scope.discountText = 'You\'re on a discounted <b>3 for 1</b> plan. You don\'t pay for the 2nd and 3rd user during the first year on the annual plan.';
            }
            else if ($scope.billingOverview.applied_discount && $scope.billingOverview.applied_discount.lastIndexOf('SUMO', 0) === 0 && $scope.billingOverview.applied_discount_type === 'firstFreeThen50Off') {
                $scope.payingHtmlText += '. <b>Thanks to AppSumo, one user is free.</b> You are currently paying for the <b>remaining ' + (response.data.enabled_user_count - 1) + ' at 50% off.</b>';
                $scope.discountText = 'You\'re on a discounted <b>AppSumo</b> plan: the first user is free, others are 50% off.';
            }
            else if ($scope.billingOverview.applied_discount_type === 'stripeDiscount' && response.data.stripe_discount) {
                const stripeDiscount = {
                    duration: response.data.stripe_discount.duration,
                    percentOff: response.data.stripe_discount.percent_off,
                    end: $filter('date')(response.data.stripe_discount.end, 'MMMM d, y'),
                    description: response.data.stripe_discount.description
                };

                switch (stripeDiscount.duration) {
                    case 'forever':
                        $scope.payingHtmlText += '. <b>You have a lifetime discount of ' + stripeDiscount.percentOff + '%</b>';
                        break;
                    case 'repeating':
                        if (stripeDiscount.description) {
                            $scope.payingHtmlText += '. <b>You have an active discount of ' + stripeDiscount.description + ' of the Pro plan until ' + stripeDiscount.end + '</b>';
                            break;
                        }

                        $scope.payingHtmlText += '. <b>You have an active discount of ' + stripeDiscount.percentOff + '% until ' + stripeDiscount.end + '</b>';
                        break;
                }
            }

            $scope.payingHtmlText += '.';

            $scope.showChargeText = response.data.next_charge.date !== response.data.subscription_renewal_date && response.data.next_charge.amount !== response.data.billing_amount && response.data.next_charge.amount > 0;
            $scope.showSCAButton = !!response.data.outstanding_invoice_requires_sca;
            SCAData = response.data.outstanding_invoice_requires_sca;

            if ($scope.showSCAButton) {
                statusBarService.show({
                    type: 'sca'
                });
            }
            else {
                statusBarService.hide('sca');
            }

            $scope.doneLoading = true;
        }
    }
})();
