(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditStageDialogController', EditStageDialogController);

    function EditStageDialogController($scope, $mdDialog, model) {

        $scope.stage = this.stage;
        $scope.pipelines = this.pipelines;
        $scope.days_until_account_inactiveOptions = ['off', 'team default', 'custom'];
        $scope.setting = {};
        $scope.currentUser = model.me;

        if ($scope.stage) {
            $scope.stage.hexColor = rgbToHex($scope.stage.color);
            $scope.stage.pipeline = this.pipeline;
            $scope.stage.oldPipeline = { id: this.pipeline.id };
            $scope.stage.oldProbability = angular.copy($scope.stage.probability);

            if ($scope.stage.fixed_stage) {
                $scope.stageNameHintText = 'Even if you change this stage\'s name, opportunities in the stage will still be considered as ' + ($scope.stage.fixed_stage === 1 ? 'won' : 'lost') + ' and everything will keep working the same way.';
            }
        }
        else {
            $scope.stage = { probability: 0 };
            $scope.isNew = true;
        }

        switch ($scope.stage.days_until_account_inactive) {
            case 2147483647:

                $scope.setting.days_until_account_inactiveSelected = 'off';
                break;
            case null:
            case undefined:

                $scope.setting.days_until_account_inactiveSelected = 'team default';
                break;
            default:

                $scope.setting.days_until_account_inactiveSelected = 'custom';
                break;
        }

        $scope.colorSettings = {
            position: 'bottom right',
            swatches: ['FF8A65', 'FFB74D', 'FFD54F', 'FFF176', 'AED581', '81C784', '4FC3F7', 'BA68C8', 'E0E0E0',
                'FF7043', 'FFA726', 'FFCA28', 'FFEE58', '9CCC65', '66BB6A', '29B6F6', 'AB47BC', 'BDBDBD',
                'FF5722', 'FF9800', 'FFC107', 'FFEB3B', '8BC34A', '4CAF50', '2196F3', '9C27B0', '9E9E9E',
                'F4511E', 'FB8C00', 'FFB300', 'FDD835', '7CB342', '43A047', '039BE5', '8E24AA', '757575',
                'E64A19', 'F57C00', 'FFA000', 'FBC02D', '689F38', '388E3C', '0288D1', '7B1FA2', '616161',
                'D84315', 'EF6C00', 'FF8F00', 'F9A825', '558B2F', '2E7D32', '0277BD', '6A1B9A', '424242']
        };

        $scope.save = function () {

            if (!$scope.stageForm.$valid) {
                return;
            }

            if ($scope.stage) {
                // We only have one pipeline
                if ($scope.pipelines.length === 1) {
                    $scope.stage.pipeline = $scope.pipelines[0];
                }

                $scope.stage.color = hexToRgb($scope.stage.hexColor);
                delete $scope.stage.hexColor;

                if ($scope.setting.days_until_account_inactiveSelected === 'off') {
                    $scope.stage.days_until_account_inactive = 2147483647;
                }

                if ($scope.setting.days_until_account_inactiveSelected === 'team default') {
                    $scope.stage.days_until_account_inactive = null;
                }

                $mdDialog.hide($scope.stage);
            }
        };

        function rgbToHex(rgb) {

            const temp = rgb.split(',');
            const r = Number.parseInt(temp[0]);
            const g = Number.parseInt(temp[1]);
            const b = Number.parseInt(temp[2]);

            return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
        }

        function hexToRgb(hex) {

            if (!hex.includes('#')) {
                hex = '#' + hex;
            }

            /*            If (hex.length === 4) {
                hex = hex[0] + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
            }*/

            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

            if (hex.length === 4) {
                result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hex);
            }

            return Number.parseInt(result[1], 16) + ',' + Number.parseInt(result[2], 16) + ',' + Number.parseInt(result[3], 16);
        }

        $scope.close = $mdDialog.hide;
    }
})();
