(function () {
    'use strict';

    angular
        .module('salesflare.components.tasks', [
            'salesflare.components.tasks.account',
            'salesflare.components.tasks.completed',
            'salesflare.components.tasks.create',
            'salesflare.components.tasks.settings',
            'salesflare.components.tasks.today',
            'salesflare.components.tasks.upcoming'
        ])
        .component('sfTasks', {
            templateUrl: 'app-ajs/components/tasks/tasks.html',
            controller,
            controllerAs: 'vm'
        });

    function controller($rootScope, $state, $filter, $mdMedia, $stateParams, $mdDialog, $mdPanel, $document, $timeout, $scope, $mdSidenav, $exceptionHandler, $transitions, sfWalkthrough, utils, events, menu, filterService, bulkService, tasks, meeting, model) {

        const vm = this;
        let thread = null;
        let removeSuccessTransitionHook;
        let editing;

        const orderBy = {
            'tasks.today': 'task.type = 4 DESC, task.reminder_date asc',
            'tasks.upcoming': 'task.reminder_date asc',
            'tasks.completed': 'task.completion_date desc'
        };
        const entityCountObject = {
            viewTotalCount: 0,
            viewTotalMeetingCount: 0,
            viewCurrentCount: 0,
            selectedCount: 0,
            canEditCount: 0,
            option: '',
            entity: 'tasks',
            entity_singular: 'task',
            string: ''
        };

        vm.usedBadfilter = false;
        vm.me = model.me;

        vm.state = $state;
        vm.$mdMedia = $mdMedia;
        vm.menu = menu;

        vm.isTaskFilterApplied = filterService.isTaskFilterApplied;

        vm.searchMode = !!$stateParams.search;
        vm.searchObject = {
            search: $stateParams.search,
            getSearch: function () {

                return this.search;
            },
            setSearch: function (s) {

                this.search = s;

                return utils.setSearchInUrl({ search: this.search });
            }
        };
        vm.filters = filterService.getFilter('task', { raw: true });
        vm.getDefaultFilters = function () {

            return filterService.getDefaultFilters('task', model.me);
        };

        vm.onFilterApply = function ($event) {

            vm.filters = filterService.setFilter('task', $event.rules);

            if (vm.badFilterError) {
                return;
            }

            return get();
        };

        vm.showBulkToolbar = false;
        vm.allSelected = false;

        function refreshData() {

            vm.showBulkToolbar = false;
            vm.allSelected = false;

            setSelectedOnAllTasks(false);

            return get();
        }

        vm.$onInit = function () {

            $scope.$on(events.refreshData, function () {

                return refreshData();
            });

            // Reset on state change success so we reload the data when going from today to upcoming for example
            // also make sure the correct bulkActions are being used
            removeSuccessTransitionHook = $transitions.onSuccess({}, () => {

                vm.loadingTasks = true;

                if (vm.bulkQuickActions && completeHandler && uncompleteHandler) {
                    if (vm.state.current.name === 'tasks.completed') {
                        vm.bulkQuickActions = vm.bulkQuickActions.map(function (bulkAction) {

                            if (bulkAction.icon === 'sf-icon-check') {
                                bulkAction = {
                                    icon: 'sf-icon-undo',
                                    handler: uncompleteHandler,
                                    config: {
                                        toolTip: {
                                            content: function () {
                                                return (vm.state.current.name === 'tasks.completed') ? 'Undo complete' : 'Complete';
                                            }
                                        }
                                    }
                                };
                            }

                            return bulkAction;
                        });
                    }
                    else {
                        vm.bulkQuickActions = vm.bulkQuickActions.map(function (bulkAction) {

                            if (bulkAction.icon === 'sf-icon-undo') {
                                bulkAction = {
                                    icon: 'sf-icon-check',
                                    handler: completeHandler,
                                    config: {
                                        toolTip: {
                                            content: function () {
                                                return (vm.state.current.name === 'tasks.completed') ? 'Undo complete' : 'Complete';
                                            }
                                        }
                                    }
                                };
                            }

                            return bulkAction;
                        });
                    }
                }

                return vm.tasks.reset();
            });

            // PollInterval = $interval(poll, 10 * 1000);
        };

        // The possible handlers for complete/uncomplete
        function uncompleteHandler() {

            if (vm.state.current.name === 'tasks.completed') {
                const confirm = $mdDialog.confirm()
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title('Are you sure?')
                    .textContent('All selected tasks will be marked as uncompleted!')
                    .ok('I\'m sure')
                    .cancel('cancel');

                return $mdDialog.show(confirm).then(function () {

                    return bulkService.updateTasks({ filter: getBulkOptions(), update: { completed: false } })
                        .then(function () {

                            return $state.reload();
                        })
                        .catch(function (err) {

                            $exceptionHandler(err);

                            return utils.showErrorToast();
                        });
                });
            }
        }

        function completeHandler() {

            if (vm.state.current.name !== 'tasks.completed') {
                const confirm = $mdDialog.confirm()
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title('Are you sure?')
                    .textContent('All selected tasks will be marked as completed!')
                    .ok('I\'m sure')
                    .cancel('cancel');

                return $mdDialog.show(confirm).then(function () {

                    return bulkService.updateTasks({ filter: getBulkOptions(), update: { completed: true } })
                        .then(function () {

                            return $state.reload();
                        })
                        .catch(function (err) {

                            $exceptionHandler(err);

                            return utils.showErrorToast();
                        });
                });
            }
        }

        vm.bulkQuickActions = [
            {
                // If completed is the state, we use the undo icon and uncomplete handler
                icon: (vm.state.current.name === 'tasks.completed') ? 'sf-icon-undo' : 'sf-icon-check',
                handler: (vm.state.current.name === 'tasks.completed') ? uncompleteHandler : completeHandler,
                config: {
                    toolTip: {
                        content: function () {
                            return (vm.state.current.name === 'tasks.completed') ? 'Undo complete' : 'Complete';
                        }
                    }
                }
            },
            {
                icon: 'sf-icon-delete',
                handler: function () {

                    const confirm = $mdDialog.confirm({
                        // eslint-disable-next-line func-name-matching
                        onComplete: function afterShowAnimation() {

                            // eslint-disable-next-line angular/document-service
                            const $dialog = angular.element(document.querySelector('md-dialog'));
                            const $contentSection = $dialog.find('md-dialog-content');
                            const $title = $contentSection.children()[0];
                            angular.element($title).addClass('red-warning');
                        }
                    })
                        .clickOutsideToClose(true)
                        .escapeToClose(true)
                        .title('Warning!')
                        .htmlContent('<p>You\'re about to delete ' + entityCountObject.selectedCount + (entityCountObject.selectedCount > 1 ? ' tasks' : ' task') + '.<br />Are you sure you want to do this?</p>')
                        .ok('I\'m sure')
                        .cancel('cancel');

                    return $mdDialog.show(confirm).then(function () {

                        return bulkService.deleteTasks(getBulkOptions())
                            .then(function () {

                                return $state.reload();
                            })
                            .catch(function (err) {

                                $exceptionHandler(err);

                                return utils.showErrorToast();
                            });
                    });
                },
                config: {
                    toolTip: {
                        content: function () {
                            return 'Delete';
                        }
                    }
                }
            },
            {
                icon: 'sf-icon-edit',
                handler: function () {

                    return $state.go('bulkEditTask', { options: getBulkOptions() });
                },
                config: {
                    toolTip: {
                        content: function () {
                            return 'Edit';
                        }
                    }
                }
            }
        ];
        vm.bulkMenuItems = [
            {
                label: 'Assign users',
                handler: function ($event) {

                    const updateTask = {};
                    const position = $mdPanel.newPanelPosition()
                        .relativeTo(angular.element('sf-bulk-toolbar md-menu'))
                        .addPanelPosition($mdPanel.xPosition.ALIGN_END, $mdPanel.yPosition.ALIGN_TOPS)
                        .addPanelPosition($mdPanel.xPosition.ALIGN_END, $mdPanel.yPosition.CENTER);

                    return $mdPanel.open({
                        attachTo: angular.element($document[0].body),
                        clickOutsideToClose: true,
                        controller: 'AssignTaskPanelController',
                        controllerAs: 'vm',
                        templateUrl: 'partials/assigntaskpanel.html',
                        openFrom: $event,
                        position,
                        locals: {
                            accountId: null,
                            task: updateTask,
                            editing: true
                        },
                        onDomRemoved: function () {

                            if (!updateTask.assignees) {
                                return;
                            }

                            const confirm = $mdDialog.confirm()
                                .clickOutsideToClose(true)
                                .escapeToClose(true)
                                .title('Are you sure?')
                                .textContent('All users will be assigned to all selected tasks! The users will not automatically join the teams of the related accounts.')
                                .ok('I\'m sure')
                                .cancel('cancel');

                            return $mdDialog.show(confirm).then(function () {

                                const addedAssignees = updateTask.assignees.map(function (a) {

                                    return a.id;
                                });

                                return bulkService.updateTasks({ filter: getBulkOptions(), update: { added_assignees: addedAssignees }  })
                                    .then(function () {

                                        return $state.reload();
                                    })
                                    .catch(function (err) {

                                        $exceptionHandler(err);

                                        return utils.showErrorToast();
                                    });
                            });
                        }
                    });
                }
            },
            {
                label: 'Unassign users',
                handler: function ($event) {

                    const updateTask = {};
                    const position = $mdPanel.newPanelPosition()
                        .relativeTo(angular.element('sf-bulk-toolbar md-menu'))
                        .addPanelPosition($mdPanel.xPosition.ALIGN_END, $mdPanel.yPosition.ALIGN_TOPS)
                        .addPanelPosition($mdPanel.xPosition.ALIGN_END, $mdPanel.yPosition.CENTER);

                    return $mdPanel.open({
                        attachTo: angular.element($document[0].body),
                        clickOutsideToClose: true,
                        controller: 'AssignTaskPanelController',
                        controllerAs: 'vm',
                        templateUrl: 'partials/assigntaskpanel.html',
                        openFrom: $event,
                        position,
                        locals: {
                            accountId: null,
                            task: updateTask,
                            editing: true
                        },
                        onDomRemoved: function () {

                            if (!updateTask.assignees) {
                                return;
                            }

                            const confirm = $mdDialog.confirm()
                                .clickOutsideToClose(true)
                                .escapeToClose(true)
                                .title('Are you sure?')
                                .textContent('All users will be unassigned to all selected tasks!')
                                .ok('I\'m sure')
                                .cancel('cancel');

                            return $mdDialog.show(confirm).then(function () {

                                const removedAssignees = updateTask.assignees.map(function (a) {

                                    return a.id;
                                });

                                return bulkService.updateTasks({ filter: getBulkOptions(), update: { removed_assignees: removedAssignees } })
                                    .then(function () {

                                        return $state.reload();
                                    })
                                    .catch(function (err) {

                                        $exceptionHandler(err);

                                        return utils.showErrorToast();
                                    });
                            });
                        }
                    });
                }
            },
            {
                label: 'Snooze tasks',
                handler: function ($event) {

                    const position = $mdPanel.newPanelPosition()
                        .relativeTo(angular.element('sf-bulk-toolbar md-menu'))
                        .addPanelPosition($mdPanel.xPosition.ALIGN_END, $mdPanel.yPosition.ALIGN_TOPS)
                        .addPanelPosition($mdPanel.xPosition.ALIGN_END, $mdPanel.yPosition.CENTER);
                    let removing = false;

                    return $mdPanel.open('reminderPanel', {
                        openFrom: $event,
                        position,
                        locals: {
                            reminder_date: new Date()
                        },
                        onRemoving: function (panelRef) {

                            const pickedDateTime = panelRef.config.locals.reminder_date;

                            if (removing || !pickedDateTime) {
                                return;
                            }

                            removing = true;

                            const confirm = $mdDialog.confirm()
                                .clickOutsideToClose(true)
                                .escapeToClose(true)
                                .title('Are you sure?')
                                .textContent('All selected tasks will be snoozed!')
                                .ok('I\'m sure')
                                .cancel('cancel');

                            return $mdDialog.show(confirm).then(function () {

                                return bulkService.updateTasks({ filter: getBulkOptions(), update: { reminder_date: pickedDateTime }  })
                                    .then(function () {

                                        return $state.reload();
                                    })
                                    .catch(function (err) {

                                        $exceptionHandler(err);

                                        return utils.showErrorToast();
                                    });
                            });
                        }
                    });
                }
            }
        ];

        vm.loadingTasks = true;
        vm.tasks = {
            toLoad: 0,
            numLoaded: 0,
            stepAmount: 20,
            items: [],
            topIndex: 0,

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + this.stepAmount;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad < index) {
                    this.toLoad += this.stepAmount;

                    vm.usedBadFilter = false;

                    const boundTaskResponseHandler = angular.bind(this, this.tasksResponseHandler);

                    let newFilters = filterService.getFilter('task');

                    if (vm.searchObject.getSearch()) {
                        newFilters = [];
                    }

                    // We moved away so we don't move on
                    if (!$state.current.name.includes('tasks')) {
                        return;
                    }

                    let defaultFilters = filterService.getPredefinedFilters('tasks')[$state.current.name];
                    if (newFilters.length > 0 && !vm.isTaskFilterApplied()) {
                        defaultFilters = [...filterService.getDefaultFilters('task', model.me, true), ...defaultFilters];
                    }

                    newFilters = [...newFilters, ...filterService.getPredefinedFilters('tasks')[$state.current.name]];
                    tasks.filterGet('', defaultFilters, null, null, null, null, { returnCountOnly: true }).then(function (response) {

                        return countResponseHandler(response, 'totalCount');
                    }, handleServerError, function (response) {

                        return countResponseHandler(response, 'totalCount');
                    });

                    tasks.filterGet(vm.searchObject.getSearch(), [...newFilters, { id: 'task.can_edit', operator: 'equal', value: [true] }], null, null, null, null, { returnCountOnly: true }).then(function (response) {

                        return countResponseHandler(response, 'canEditCount');
                    }, handleServerError, function (response) {

                        return countResponseHandler(response, 'canEditCount');
                    });

                    if (vm.searchObject.getSearch() || vm.isTaskFilterApplied()) {
                        tasks.filterGet(vm.searchObject.getSearch(), newFilters, null, null, null, null, { returnCountOnly: true }).then(function (response) {

                            return countResponseHandler(response, 'currentCount');
                        }, handleServerError, function (response) {

                            return countResponseHandler(response, 'currentCount');
                        });
                    }
                    else {
                        // If we don't do a call for the current count, we need to reset it. Otherwise it will never update.
                        entityCountObject.viewCurrentCount = 0;
                    }

                    // Also get the count for meetings only, so that we can display a correct number for bulk actions
                    tasks.filterGet('', [...defaultFilters, { id: 'task.type',operator: 'in',value: [7] }], null, null, null, null, { returnCountOnly: true }).then(function (response) {

                        return countResponseHandler(response, 'totalCount');
                    }, handleServerError, function (response) {

                        return countResponseHandler(response, 'totalCount');
                    });

                    return tasks.filterGet(vm.searchObject.getSearch(), newFilters, this.stepAmount, (this.toLoad - this.stepAmount), orderBy[$state.current.name], null, { ignoreLoadingBar: this.toLoad !== this.stepAmount, includeCount: false }).then(boundTaskResponseHandler, handleServerError, boundTaskResponseHandler);
                }
            },

            tasksResponseHandler: function (response) {

                // We moved away so we don't move on
                if (!$state.current.name.includes('tasks')) {
                    return;
                }

                const params = response.config.params;

                // Make sure the response contains results for the right request

                // Rules applied to response
                const rules = params.q.rules.flatMap(returnFilterIds);

                // User applied filters
                const newFilters = filterService.getFilter('task').flatMap(returnFilterIds);
                // Predefined filters
                const currentPredefinedFilters = filterService.getPredefinedFilters('tasks')[$state.current.name].flatMap(returnFilterIds);
                // Rules sent with request
                const requestedFilters = [...newFilters, ...currentPredefinedFilters];

                const lengthDifference = (rules.length !== requestedFilters.length && !vm.searchObject.getSearch());

                const filterDifferences = rules.filter(function (rule) {

                    const index = requestedFilters.indexOf(rule);
                    if (index === -1) {
                        return true;
                    }
                    else {
                        requestedFilters.splice(index, 1);
                        return false;
                    }
                });

                // If no differences in rules, apply response
                if ((filterDifferences.length === 0 || filterDifferences.length <= 0) && response.config.method !== 'HEAD' && !lengthDifference) {
                    this.items = addHeaders(sortTasks([...this.items, ...response.data]));

                    this.numLoaded += this.stepAmount;
                }

                vm.loadingTasks = false;
            },

            reset: function () {

                this.toLoad = 0;
                this.numLoaded = 0;
                this.items = [];
                this.topIndex = 0;
            }
        };

        function returnFilterIds(filter) {

            if (filter.id) {
                return filter.id;
            }
            else {
                return filter.rules.map(returnFilterIds);
            }
        }

        vm.$onDestroy = function () {

            removeSuccessTransitionHook();
            // Return $interval.cancel(pollInterval);
        };

        vm.onEditTask = function ($event) {

            editing = true;

            if ($event.task.type === 'meeting') {
                if (editing) {
                    // Since we're coming here only when a meeting has been edited,
                    // editing needs to be false
                    editing = false;
                }

                vm.onUpdateTask($event);
            }

            // Only allow 1 edit at the same time
            vm.tasks.items.forEach(function (t) {

                if (t.id !== $event.task.id) {
                    t.editMode = false;
                }
            });
        };

        vm.onEditedTask = function ($event) {

            editing = false;

            return vm.onUpdateTask($event);
        };

        vm.onAddTask = function ($event) {

            if (!$event.task) {
                return;
            }

            if (currentState() === 'today') {
                if (getTodayTaskType($event.task) === 'upcoming') {
                    return;
                }

                vm.tasks.items.push($event.task);
                vm.tasks.items = addTodayHeaders(sortTasks(vm.tasks.items));
                vm.tasks.numLoaded++;
            }
            else if (currentState() === 'upcoming') {
                vm.tasks.items.push($event.task);
                vm.tasks.items = addUpcomingHeaders(sortTasks(vm.tasks.items));
                vm.tasks.numLoaded++;
            }
            // You can't add completed tasks when on completed screen
        };

        // Listens for task added from dialog for example
        tasks.onTaskAdded(vm.onAddTask);

        vm.onDismissTask = function ($event) {

            const origLength = vm.tasks.items.length;

            vm.tasks.items = vm.tasks.items.filter(function (t) {

                return t.id !== $event.task.id;
            });

            if (origLength !== vm.tasks.items.length) {
                vm.tasks.numLoaded--;

                vm.tasks.items = addHeaders(vm.tasks.items);
            }
        };

        vm.onUpdateTask = function ($event) {

            if (currentState() === 'today') {
                return onUpdateTodayTask($event.task);
            }
            else if (currentState() === 'upcoming') {
                return onUpdateUpcomingTask($event.task);
            }
            else if (currentState() === 'completed') {
                return onUpdateCompletedTask($event.task);
            }
        };

        /**
         * Sort tasks list correctly for the current state
         * We get the correct order from the server
         * but this makes it easy to do client side updates to the list
         *
         * Will unset `selected` on all tasks.
         * For easiness.
         *
         * @param {Object[]} tasksList
         * @returns {Object[]}
         */
        function sortTasks(tasksList) {

            if (currentState() === 'today') {
                /**
                 * TODAY SCREEN
                 * today: reminder_date ASC
                 *
                 * overdue: reminder_date DESC
                 *
                 * suggested: reminder_date DESC
                 */
                tasksList.sort(function (task1, task2) {

                    const taskType1 = getTodayTaskType(task1);
                    const taskType2 = getTodayTaskType(task2);

                    // Ascending today
                    if (taskType1 === 'today') {
                        if (taskType2 === 'today') {
                            if (new Date(task1.reminder_date) < new Date(task2.reminder_date)) {
                                return -1;
                            }
                            else if (new Date(task1.reminder_date) > new Date(task2.reminder_date)) {
                                return 1;
                            }

                            if (task1.id > task2.id) {
                                return -1;
                            }

                            return 1;
                        }
                        else {
                            return -1;
                        }
                    }
                    // Descending overdue
                    else if (taskType1 === 'overdue') {
                        if (taskType2 === 'today') {
                            return 1;
                        }
                        else if (taskType2 === 'suggested') {
                            return -1;
                        }
                        else {
                            if (new Date(task1.reminder_date) < new Date(task2.reminder_date)) {
                                return 1;
                            }
                            else if (new Date(task1.reminder_date) > new Date(task2.reminder_date)) {
                                return -1;
                            }

                            if (task1.id > task2.id) {
                                return -1;
                            }

                            return 1;
                        }
                    }
                    // Descending suggested
                    else if (taskType2 === 'suggested') {
                        if (new Date(task1.reminder_date) < new Date(task2.reminder_date)) {
                            return 1;
                        }
                        else if (new Date(task1.reminder_date) > new Date(task2.reminder_date)) {
                            return -1;
                        }

                        if (task1.id > task2.id) {
                            return -1;
                        }

                        return 1;
                    }
                    else {
                        return 1;
                    }
                });
            }
            else if (currentState() === 'upcoming') {
                // ASC
                tasksList.sort(function (task1, task2) {

                    if (new Date(task1.reminder_date) < new Date(task2.reminder_date)) {
                        return -1;
                    }
                    else if (new Date(task1.reminder_date) > new Date(task2.reminder_date)) {
                        return 1;
                    }

                    if (task1.id > task2.id) {
                        return -1;
                    }

                    return 1;
                });

            }
            else if (currentState() === 'completed') {
                // DESC
                tasksList.sort(function (task1, task2) {

                    if (new Date(task1.completion_date) < new Date(task2.completion_date)) {
                        return 1;
                    }
                    else if (new Date(task1.completion_date) > new Date(task2.completion_date)) {
                        return -1;
                    }

                    if (task1.id > task2.id) {
                        return -1;
                    }

                    return 1;
                });
            }

            // This follows `task.selected` when `vm.allSelected === false`
            // so that when you do `select all` and unselect 1 task (this sets `vm.allSelected` to `false`)
            // and you then start scrolling you still keep your previous selection and don't unselect everything
            tasksList.forEach(function (task) {

                task.selected = vm.allSelected ? vm.allSelected : (task.selected ? task.selected : false);
            });

            // Filter out dupes (possible when adding new tasks)
            // eslint-disable-next-line no-shadow
            return tasksList.filter(function (task, pos, tasks) {

                let sameTask = false;
                if (pos > 0) {
                    sameTask = (task.id === tasks[pos - 1].id);
                }

                return !sameTask;
            });
        }

        /**
         * Get the type/list where a task should be on the today screen
         *
         * @param {Object} task
         * @param {String | Date} task.reminder_date
         * @param {String} task.type
         * @returns {String}
         */
        function getTodayTaskType(task) {

            const taskDate = new Date(task.reminder_date);
            const today = new Date();

            if (task.type !== 'manual_task' && task.type !== 'meeting') {
                return 'suggested';
            }
            else if (taskDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
                return 'overdue';
            }
            else if (taskDate.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0)) {
                return 'upcoming';
            }

            return 'today';
        }

        function onUpdateTodayTask(task) {

            task.reminder_date = new Date(task.reminder_date);

            // If completed move to completed list
            if (task.completed === true) {
                removeTaskFromList(task.id);
            }

            // If incomplete move back to list
            if (!task.completed) {
                // If reminder_date is today put in the right place otherwise remove from the list
                if (task.reminder_date <= utils.getEndOfLocalDay(new Date())) {
                    if (task.type === 'meeting') {
                        return meeting.get(task.meeting.id).then(function (response) {

                            if ((!response.data || !response.data.id) && task.id) {
                                removeTaskFromList(task.id);
                                return;
                            }

                            const meetingEntity = response.data;

                            const meetingTask = meetingEntityToMeetingTasks(meetingEntity, task);

                            removeTaskFromList(task.id);

                            // Put non-overdue meetings back in the list
                            // Put meeting_notes back in the list when the reminder date of the task < now (-> stop showing it when we set the meeting's end date to a future date)
                            if ((task.type === 'meeting' && new Date(meetingTask.reminder_date) >= new Date(new Date().setHours(0, 0, 0, 0))) || (task.type === 'meeting_notes' && new Date(meetingTask.reminder_date) < new Date())) {
                                vm.tasks.items = [...vm.tasks.items, meetingTask];
                            }

                            vm.tasks.items = addTodayHeaders(sortTasks(vm.tasks.items));
                            vm.tasks.numLoaded += 1;
                        });
                    }

                    removeTaskFromList(task.id);

                    vm.tasks.items.push(task);
                    vm.tasks.items = addTodayHeaders(sortTasks(vm.tasks.items));
                    vm.tasks.numLoaded++;
                }
                else {
                    removeTaskFromList(task.id);
                }
            }
        }

        function onUpdateUpcomingTask(task) {

            task.reminder_date = new Date(task.reminder_date);

            if (!task.completed && task.reminder_date > utils.getEndOfLocalDay(new Date())) {
                if (task.type !== 'meeting-live' && task.type !== 'meeting') {
                    removeTaskFromList(task.id);

                    vm.tasks.items.push(task);
                    vm.tasks.items = addUpcomingHeaders(sortTasks(vm.tasks.items));
                    vm.tasks.numLoaded++;
                    return;
                }

                return meeting.get(task.meeting.id).then(function (response) {

                    if ((!response.data || !response.data.id) && task.id) {
                        removeTaskFromList(task.id);
                        return;
                    }

                    const meetingEntity = response.data;

                    const meetingTask = meetingEntityToMeetingTasks(meetingEntity, task);

                    removeTaskFromList(task.id);

                    vm.tasks.items = [...vm.tasks.items, meetingTask];
                    vm.tasks.items = addUpcomingHeaders(sortTasks(vm.tasks.items));
                    vm.tasks.numLoaded += 1;
                });
            }
            else {
                removeTaskFromList(task.id);
            }
        }

        function onUpdateCompletedTask(task) {

            task.reminder_date = new Date(task.reminder_date);

            if (task.completed && task.reminder_date < new Date(new Date().setHours(0,0,0,0))
                && ((task.type === 'meeting-live' || task.type === 'meeting')) && !task.deleted) {

                // TODO: (JASPER meetings) this might be able to be simplified, but works
                return meeting.get(task.meeting.id).then(function (response) {

                    if ((!response.data || !response.data.id) && task.id) {
                        removeTaskFromList(task.id);
                        return;
                    }

                    const meetingEntity = response.data;

                    const meetingTask = meetingEntityToMeetingTasks(meetingEntity, task);

                    removeTaskFromList(task.id);

                    vm.tasks.items = [...vm.tasks.items, meetingTask];
                    vm.tasks.items = addCompletedHeaders(sortTasks(vm.tasks.items));
                    vm.tasks.numLoaded += 1;
                });
            }

            if (task.deleted || !task.completed) {
                return removeTaskFromList(task.id);
            }
        }

        /**
         * Create a meeting task, using a meeting object
         *
         * @param {{accounts: [{id: Number, name: String}], participants: [Object]}} meetingEntity meeting object
         * @param {{}} task  original meeting task object being updated
         * @returns {Object} meetingTask
         */
        function meetingEntityToMeetingTasks(meetingEntity, task) {

            const meetingTask = {
                assignees: meetingEntity.participants ? meetingEntity.participants.filter(function (participant) {

                    return participant.type && participant.type === 'user';
                }) : null,
                completed: task.completed ? task.completed : (task.type === 'meeting_notes' && meetingEntity.notes),
                completion_date: task.completion_date ? task.completion_date : undefined,
                creation_date: meetingEntity.creation_date,
                description: task.type === 'meeting_notes' ? 'Add notes to <b>' + meetingEntity.subject + '</b>' : meetingEntity.subject,
                id: task.id,
                meeting: {
                    id: meetingEntity.id,
                    subject: meetingEntity.subject,
                    conference_url: meetingEntity.conference_url
                },
                modification_date: meetingEntity.modification_date,
                reminder_date: task.type === 'meeting_notes' ? meetingEntity.end_date : meetingEntity.date,
                type: task.type
            };

            // We used to create a task for every account but since the sort function
            // dedupes based on id this is not needed
            if (meetingEntity.accounts.length > 0) {
                meetingTask.account = {
                    id: meetingEntity.accounts[0].id,
                    name: meetingEntity.accounts[0].name,
                    picture: meetingEntity.accounts[0].picture
                };
            }

            return meetingTask;
        }

        function removeTaskFromList(taskId) {

            const origLength = vm.tasks.items.length;

            vm.tasks.items = vm.tasks.items.filter(function (t) {

                return t.id !== taskId;
            });

            if (origLength !== vm.tasks.items.length) {
                vm.tasks.numLoaded--;
                vm.tasks.items = addHeaders(vm.tasks.items);
            }
        }

        vm.onSelectedChanged = function () {

            const showBulkToolbar = vm.tasks.items.find(function (task) {

                return task.selected;
            });

            if (showBulkToolbar) {
                vm.onShowBulkToolbar();

                const selectedTasks = vm.tasks.items.filter(function (task) {

                    return task.selected;
                });

                vm.allSelected = vm.tasks.items.length === selectedTasks.length;

                if (vm.allSelected) {
                    entityCountObject.selectedCount = entityCountObject.option === '' ? (entityCountObject.canEditCount - entityCountObject.viewTotalMeetingCount) : entityCountObject.canEditCount;
                }
                else {
                    entityCountObject.selectedCount = selectedTasks.length;
                }

                entityCountObject.string = utils.getEntityCountString(entityCountObject);
                vm.entityCountObjectString = entityCountObject.string;
            }
            else {
                vm.onHideBulkToolbar();
                entityCountObject.selectedCount = 0;
                entityCountObject.string = utils.getEntityCountString(entityCountObject);
                vm.entityCountObjectString = entityCountObject.string;
            }
        };

        vm.onShowBulkToolbar = function () {

            vm.showBulkToolbar = true;
        };

        vm.onHideBulkToolbar = function () {

            vm.showBulkToolbar = false;

            return setSelectedOnAllTasks(false);
        };

        vm.onAllSelected = function ($event) {

            vm.allSelected = $event ? $event.allSelected : true;

            if (vm.allSelected) {
                entityCountObject.selectedCount = entityCountObject.option === '' ? (entityCountObject.canEditCount - entityCountObject.viewTotalMeetingCount) : entityCountObject.canEditCount;
                entityCountObject.string = utils.getEntityCountString(entityCountObject);
                vm.entityCountObjectString = entityCountObject.string;
            }
            else {
                vm.showBulkToolbar = false;
                entityCountObject.selectedCount = 0;
                entityCountObject.string = utils.getEntityCountString(entityCountObject);
                vm.entityCountObjectString = entityCountObject.string;
            }

            return setSelectedOnAllTasks(vm.allSelected);
        };

        vm.setSearchMode = function (mode) {

            vm.searchMode = mode;
            if (!vm.searchMode) {
                vm.searchObject.setSearch();
                return get();
            }
        };

        vm.doSearch = function (search) {

            $timeout.cancel(thread);

            thread = $timeout(function () {

                vm.searchObject.setSearch(search);
                return get();
            }, 750);
        };

        $scope.$watch('vm.searchMode', function (newValue, oldValue) {

            if (newValue) {
                let searchBoxId = '#searchbox';
                if ($mdMedia('gt-sm')) {
                    searchBoxId += '-fs';
                }

                utils.forceFocus(angular.element(searchBoxId));
            }

            if (!oldValue || (oldValue && !newValue)) {
                vm.searchObject.setSearch('');
            }

            if (oldValue && vm.searchObject.getSearch()) {
                return get();
            }
        });

        vm.setFilters = function () {

            if (sfWalkthrough.isShowing()) {
                return;
            }

            return $mdSidenav('filters').toggle()
                .then(function () {

                    return $mdSidenav('filters', true).onClose(function () {

                        // Return get();
                    });
                });
        };

        vm.export = function (type) {

            const isCSV = type !== 'excel';
            const isExcel = type === 'excel';
            let newFilters;

            if (vm.searchObject.getSearch()) {
                newFilters = [...filterService.getDefaultFilters('task', model.me, true), ...filterService.getPredefinedFilters('tasks')[$state.current.name]];
            }
            else {
                newFilters = [...filterService.getFilter('task'), ...filterService.getPredefinedFilters('tasks')[$state.current.name]];
            }

            return tasks.filterGet(vm.searchObject.getSearch(), newFilters, 100000, 0, orderBy[$state.current.name], isCSV, undefined, isExcel);
        };

        function countResponseHandler(response, type) {

            if (angular.isDefined(response.headers()['x-result-count'])) {
                const appliedFilters = filterService.getFilter('task');
                let meetingRule;

                entityCountObject.option = vm.searchObject.getSearch() === '' ? ((vm.isTaskFilterApplied() && appliedFilters.length > 0) ? 'filter' : '') : 'search';

                if (
                    response.config &&
                    response.config.params &&
                    response.config.params.q &&
                    response.config.params.q.rules
                ) {
                    meetingRule = response.config.params.q.rules.find(function (rule) {
                        return rule.id === 'task.type' && rule.operator === 'in' && angular.isArray(rule.value) && rule.value.length === 1 && rule.value[0] === 7;
                    });
                }

                if (type === 'totalCount') {
                    if (angular.isDefined(meetingRule)) {
                        entityCountObject.viewTotalMeetingCount = Number.parseInt(response.headers()['x-result-count']);
                    }
                    else {
                        entityCountObject.viewTotalCount = Number.parseInt(response.headers()['x-result-count']);
                    }
                }

                if (type === 'currentCount') {
                    entityCountObject.viewCurrentCount = Number.parseInt(response.headers()['x-result-count']);
                }

                if (type === 'canEditCount') {
                    entityCountObject.canEditCount = Number.parseInt(response.headers()['x-result-count']);
                }

                entityCountObject.string = utils.getEntityCountString(entityCountObject);
                vm.entityCountObjectString = entityCountObject.string;
            }
        }

        /**
         * Function used for polling
         * If scroll position of the virtual repeat is at the top then
         * Every x seconds we fetch the first `stepAmount` of tasks
         * We match them with the current list and if there is a mismatch
         * We replace the list with the new one and in doing so throw away the cache from the virtual repeat
         * Since the following tasks likely have changed as well
         *
         * @returns {undefined}
         */
        // function poll() {

        //     if (vm.tasks.topIndex !== 0 || editing || vm.allSelected || vm.showBulkToolbar || currentState() === 'completed') {
        //         return;
        //     }

        //     return get({ polling: true });
        // }

        /**
         * @param {{ polling: Boolean }} options
         * @returns {undefined}
         */
        function get(options) {

            options = options || {};

            if ((options.polling && vm.tasks.topIndex !== 0) || editing) {
                return;
            }

            let newFilters;

            // Do not filter when searching
            if (vm.searchObject.getSearch()) {
                newFilters = filterService.getDefaultFilters('task', model.me, true);
            }
            else {
                newFilters = filterService.getFilter('task');
            }

            /**
             * https://github.com/Salesflare/Client/issues/1652
             *
             * On higher resolution screens it's possible that more than 20 (default limit/stepAmount) tasks are displayed at once.
             * This caused multiple fetch calls every time we poll to fill the viewport with tasks, which caused flickering.
             * To prevent this we actually check the amount of tasks displayed in the viewport and use that amount as the new limit when it's higher than the original limit.
             * The + 1 is to trigger a mismatch when new tasks have been created in the polling interval and the viewport wasn't entirely filled up with tasks.
             */
            let limit = vm.tasks.stepAmount;
            const amountOfTasksInViewport = angular.element('sf-tasks .md-virtual-repeat-offsetter md-list div[md-virtual-repeat]').length;

            if (amountOfTasksInViewport > limit) {
                limit = amountOfTasksInViewport + 1;
            }

            let defaultFilters = filterService.getPredefinedFilters('tasks')[$state.current.name];
            if (newFilters.length > 0 && !vm.isTaskFilterApplied()) {
                defaultFilters = [...filterService.getDefaultFilters('task', model.me, true), ...defaultFilters];
            }

            newFilters = [...newFilters, ...filterService.getPredefinedFilters('tasks')[$state.current.name]];

            tasks.filterGet('', defaultFilters, null, null, null, null, { returnCountOnly: true }).then(function (response) {

                return countResponseHandler(response, 'totalCount');
            }, handleServerError, function (response) {

                return countResponseHandler(response, 'totalCount');
            });

            if (vm.searchObject.getSearch() || vm.isTaskFilterApplied) {
                tasks.filterGet(vm.searchObject.getSearch(), newFilters, null, null, null, null, { returnCountOnly: true }).then(function (response) {

                    return countResponseHandler(response, 'currentCount');
                }, handleServerError, function (response) {

                    return countResponseHandler(response, 'currentCount');
                });
            }

            // Also get the count for meetings only, so that we can display a correct number for bulk actions
            tasks.filterGet('', [...defaultFilters, { id: 'task.type',operator: 'in',value: [7] }], null, null, null, null, { returnCountOnly: true }).then(function (response) {

                return countResponseHandler(response, 'totalCount');
            }, handleServerError, function (response) {

                return countResponseHandler(response, 'totalCount');
            });

            return tasks.filterGet(vm.searchObject.getSearch(), newFilters, limit, 0, orderBy[$state.current.name], null, { ignoreLoadingBar: true, includeCount: false }).then(function (response) {

                if (!response.data || (options.polling && vm.tasks.topIndex !== 0) || editing) {
                    return;
                }

                // Make sure the gotten list is the same order as we already have
                response.data = addHeaders(sortTasks(response.data));

                let mismatch = !options.polling || (response.data.length !== vm.tasks.items.length && vm.tasks.items.length <= vm.tasks.stepAmount);

                if (!mismatch) {
                    mismatch = response.data.find(function (t, index) {

                        const correspondingTask = vm.tasks.items[index];

                        // Return true if mismatch
                        if (!correspondingTask || !correspondingTask.id) {
                            return true;
                        }

                        if (t.id !== correspondingTask.id) {
                            return true;
                        }

                        if (t.description !== correspondingTask.description) {
                            return true;
                        }

                        if ((t.account && t.account.id) !== (correspondingTask.account && correspondingTask.account.id)) {
                            return true;
                        }

                        if (t.reminder_date !== correspondingTask.reminder_date) {
                            return true;
                        }

                        return false;
                    });
                }

                // Set the tasks and make sure to reset virtual repeat props so it knows whats up
                if (mismatch) {
                    // No need to sort or add headers again since we already did
                    vm.tasks.items = response.data;
                    // Make sure toLoad and numLoaded are set, so that more tasks can be loaded again
                    vm.tasks.toLoad = vm.tasks.items.length;
                    vm.tasks.numLoaded = vm.tasks.toLoad;
                    // It is possible we modify the task list while scrolled so to reset the virtual repeat we scroll back to the top
                    vm.tasks.topIndex = 0;
                }
            }).catch(function (err) {

                return handleServerError(err);
            });
        }

        // Returns options object with either the ids selected or the filter when all selected or searching
        function getBulkOptions() {

            if (vm.allSelected) {
                if (vm.searchObject.getSearch()) {
                    return {
                        condition: 'AND',
                        rules: [...filterService.getDefaultFilters('task', model.me, true), ...filterService.getPredefinedFilters('tasks')[$state.current.name]],
                        search: vm.searchObject.getSearch()
                    };
                }

                return {
                    condition: 'AND',
                    rules: [...filterService.getFilter('task'), ...filterService.getPredefinedFilters('tasks')[$state.current.name]]
                };
            }

            return {
                condition: 'AND',
                rules: [{
                    id: 'task.id',
                    operator: 'in',
                    value: vm.tasks.items.filter(function (task) {

                        return task.id && task.selected;
                    }).map(function (task) {

                        return task.id;
                    })
                }]
            };
        }

        function setSelectedOnAllTasks(selected) {

            vm.tasks.items.forEach(function (task) {

                task.selected = selected;
            });
        }

        /**
         * Will add ghost tasks that represent headers (and their spacers).
         * We do it like this since just adding headers in the view breaks scrolling
         * in the virtual repeat. Since it would create tasks which are higher and this causes
         * jumping when they get removed while scrolling.
         *
         * Make sure to sort first.
         *
         * @param {Object[]} taskList
         * @returns {Object[]}
         */
        function addHeaders(taskList) {

            if (currentState() === 'today') {
                return addTodayHeaders(taskList);
            }
            else if (currentState() === 'upcoming') {
                return addUpcomingHeaders(taskList);
            }
            else if (currentState() === 'completed') {
                return addCompletedHeaders(taskList);
            }
        }

        /**
         * Will add ghost tasks that represent headers (and their spacers).
         * We do it like this since just adding headers in the view breaks scrolling
         * in the virtual repeat. Since it would create tasks which are higher and this causes
         * jumping when they get removed while scrolling.
         *
         * Make sure to sort first.
         *
         * @param {Object[]} taskList
         * @returns {Object[]}
         */
        function addTodayHeaders(taskList) {

            let putOverdueHeader = false;
            let putSuggestedHeader = false;
            const today = new Date();

            const taskListWithHeaders = [];
            taskList = taskList.filter(function (task) {

                return !task.fakeTask;
            });
            taskList.forEach(function (task) {

                if (task.type === 'manual_task' && !putOverdueHeader ) {
                    const taskDate = new Date(task.reminder_date);
                    // Only place header if the days aren't the same
                    if (taskDate.setHours(0,0,0,0) !== today.setHours(0,0,0,0)) {
                        // Only add spacer on larger screens
                        if ($mdMedia('gt-sm')) {
                            taskListWithHeaders.push(
                                {
                                    fakeTask: true,
                                    headerSpacer: true
                                }
                            );
                        }

                        taskListWithHeaders.push(
                            {
                                fakeTask: true,
                                overdueTasksHeader: true
                            }
                        );
                        putOverdueHeader = true;
                    }
                }

                if (task.type !== 'manual_task' && task.type !== 'meeting' && !putSuggestedHeader) {
                    // Only add spacer on larger screens
                    if ($mdMedia('gt-sm')) {
                        taskListWithHeaders.push(
                            {
                                fakeTask: true,
                                headerSpacer: true
                            }
                        );
                    }

                    taskListWithHeaders.push(
                        {
                            fakeTask: true,
                            suggestedTasksHeader: true
                        }
                    );
                    putSuggestedHeader = true;
                }

                taskListWithHeaders.push(task);
            });

            // If the first task is a spacer remove it
            // this ensures that the following header aligns better with the count
            // This also prevents jumping. Since otherwise we would have to show/hide the spacer conditionally
            // which basically means jumping when scrolling
            if (taskListWithHeaders.length > 0 && taskListWithHeaders[0].headerSpacer === true) {
                taskListWithHeaders.shift();
            }

            return taskListWithHeaders;
        }

        /**
         * Will add ghost tasks that represent headers.
         * We do it like this since just adding headers in the view breaks scrolling
         * in the virtual repeat. Since it would create tasks which are higher and this causes
         * jumping when they get removed while scrolling.
         *
         * Make sure to sort first.
         *
         * @param {Object[]} taskList
         * @returns {Object[]}
         */
        function addUpcomingHeaders(taskList) {

            const taskListWithHeaders = [];
            taskList = taskList.filter(function (task) {

                return !task.fakeTask;
            });
            taskList.forEach(function (task, index) {

                const previousTask = taskList[index - 1];
                if (!previousTask || !utils.isSameDay(task.reminder_date, previousTask.reminder_date)) {
                    taskListWithHeaders.push(
                        {
                            fakeTask: true,
                            dateSpacer: true,
                            reminder_date: $filter('date')(task.reminder_date, 'EEEE, MMMM d, yyyy')
                        }
                    );
                }

                taskListWithHeaders.push(task);
            });

            return taskListWithHeaders;
        }

        /**
         * Will add ghost tasks that represent headers.
         * We do it like this since just adding headers in the view breaks scrolling
         * in the virtual repeat. Since it would create tasks which are higher and this causes
         * jumping when they get removed while scrolling.
         *
         * Make sure to sort first.
         *
         * @param {Object[]} taskList
         * @returns {Object[]}
         */
        function addCompletedHeaders(taskList) {

            const taskListWithHeaders = [];
            taskList = taskList.filter(function (task) {

                return !task.fakeTask;
            });
            taskList.forEach(function (task, index) {

                const previousTask = taskList[index - 1];
                if (!previousTask || !utils.isSameDay(task.completion_date, previousTask.completion_date)) {
                    taskListWithHeaders.push(
                        {
                            fakeTask: true,
                            dateSpacer: true,
                            completion_date: $filter('date')(task.completion_date, 'EEEE, MMMM d, yyyy')
                        }
                    );
                }

                taskListWithHeaders.push(task);
            });

            return taskListWithHeaders;
        }

        function currentState() {

            const states = {
                'tasks.today': 'today',
                'tasks.upcoming': 'upcoming',
                'tasks.completed': 'completed'
            };

            return states[$state.current.name];
        }

        vm.handleFilterError = ($event) => {

            if ($event.message) {
                vm.usedBadFilter = true;
            }

            vm.badFilterError = $event.message;
        };

        function handleServerError(response) {

            if (response.config.method === 'HEAD') {

                return;
            }

            vm.loadingTasks = false;

            if (response && (response.status === 422 || response.status === 402)) {
                vm.usedBadFilter = true;

                if (response.data.filter) {

                    const selectedRules = filterService.flatten(filterService.getFilter('task', { raw: true }));
                    const hydratedRules = filterService.flatten(response.data.filter.rules);

                    const rulesToApply = filterService.intersectAndEnrich(selectedRules, hydratedRules);

                    vm.filters = filterService.setFilter('task', rulesToApply);

                    $scope.$broadcast('setFilters', vm.filters);
                }
            }
        }
    }
})();
