(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('SuggestedAccountsController', SuggestedAccountsController);

    function SuggestedAccountsController($state, $filter, $mdMedia, $scope, $transitions, $q, account, accounts, usersettings, model, events) {

        $scope.me = model.me;
        $scope.selected = {};

        let acceptedOrRejectedCount = 0;
        const lastVisitedDate = new Date();

        if ($scope.me.role.permissions.ced !== 'NONE') {
            accounts.getAmountOfNotViewedSuggestedAccounts().then((response) => {

                $scope.notViewedAccountSuggestions = response.data;
            });
        }

        init();

        // Virtual repeat + infinite scroll object that angular material uses
        $scope.suggestedAccounts = {
            toLoad: 0,
            numLoaded: 0, // Total loaded
            items: [],
            topIndex: 0,
            totalCount: 0,

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + 20;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad >= index) {
                    return;
                }

                this.toLoad += 20;

                const boundFirstResponseHandler = angular.bind(this, suggestedAccountsResponse);
                const boundSecondResponseHandler = angular.bind(this, (response) => {

                    this.items = [...this.items, ...response.data.map((suggestedAccount) => {
                        return { ...suggestedAccount, tooltipTitle: getSuggestedAccountListItemTitle(suggestedAccount) };
                    })];
                    this.numLoaded = this.toLoad;
                });

                if (this.items.length === 0) {
                    this.doneLoading = false;
                    accounts.getSuggestedAccounts({
                        limit: 20,
                        includeCount: true
                    }).then((response) => {
                        boundFirstResponseHandler(response);
                    }, null, (response) => {
                        boundFirstResponseHandler(response, true);
                    });
                }
                else {
                    accounts.getSuggestedAccounts({
                        limit: 20,
                        /*
                         * When a suggested account is accepted we want to still show the item in the list
                         * As this suggestion will no longer be returned by the server, it messes with the offset
                         * We need to deduct the amount of accepted/rejected suggestions from the amount of loaded suggestions to get the correct offset
                         */
                        offset: this.numLoaded - acceptedOrRejectedCount
                    }).then(boundSecondResponseHandler);
                }
            },

            // Forces reset of object
            reload: function () {

                this.toLoad = 0;
                this.numLoaded = 0;
                this.partOfLoaded = 0;
                this.items = [];
                this.topIndex = 0;
                this.totalCount = 0;
                this.doneLoading = false;
            }
        };

        // If you use $scope.$destroy event and sign out the bearer token isn't available anymore therefore we use $rootScope $stateChangeStart event
        const removeStartTransitionHook = $transitions.onStart({}, (transition) => {

            const toState = transition.to();
            const fromState = transition.from();

            if (fromState.name && toState.name && fromState.name.includes('accountsuggestions') && !toState.name.includes('accountsuggestions')) {
                removeStartTransitionHook();

                // Update the last visited time by passing the passed time since arriving on the suggestions page
                // This way we don't miss out on new suggestions since arriving here,
                // but also don't screw up ordering by updating the time before leaving
                const now = new Date();
                const passedTime = now.getTime() - lastVisitedDate.getTime();

                // Wait for call to resolve before completing the transition, otherwise the wrong count can still show on the tab
                return usersettings.setLastVisitedSuggestedAccounts(passedTime).then(() => {

                    if ($scope.notViewedAccountSuggestions) {
                        $scope.notViewedAccountSuggestions.amount_new = '0';
                    }
                }).finally($q.resolve);
            }
        });

        $scope.goToSuggestion = function (suggestedAccount) {

            const stateType = suggestedAccount.createdAccountId ? 'account' : 'company';
            const id = suggestedAccount.createdAccountId ? suggestedAccount.createdAccountId : suggestedAccount.company.id;

            if ($mdMedia('gt-sm')) {
                return $state.go(`accountsuggestions.${stateType}.feed`, { id });
            }

            return $state.go(`accountsuggestions.${stateType}.info`, { id });
        };

        $scope.goToAccounts = () => {

            return $state.go('accounts');
        };

        $scope.getSuggestedAccountDetailsFontIcon = (suggestedAccount) => {

            if (!suggestedAccount) {
                return;
            }

            if (suggestedAccount.accepted) {
                return 'sf-icon-account_created';
            }

            if (suggestedAccount.rejected) {
                return 'sf-icon-close';
            }

            if (suggestedAccount.last_interaction_type === 'meeting') {
                return 'sf-icon-meeting-live';
            }

            return `sf-icon-${suggestedAccount.last_interaction_type}`;
        };

        function getSuggestedAccountListItemTitle(suggestedAccount) {

            return `${suggestedAccount.company.name}\n${$scope.getAccountSuggestionSubtitle(suggestedAccount, true)}`;
        }

        $scope.getAccountSuggestionSubtitle = (suggestedAccount, plainText) => {

            if (suggestedAccount) {
                if (suggestedAccount.accepted || suggestedAccount.rejected) {
                    const action = suggestedAccount.accepted ? 'created' : 'rejected';

                    if (plainText) {
                        return `${model.me.name} ${action} ${suggestedAccount.company.name}`;
                    }

                    return `<span class="accent">${model.me.name}</span> ${action} ${suggestedAccount.company.name}`;
                }

                if (plainText) {
                    const lastInteractionTypeNameMap = { email: 'emailed', meeting: 'met' };
                    return `Last ${lastInteractionTypeNameMap[suggestedAccount.last_interaction_type]} ${$filter('timeAgo')(suggestedAccount.last_interaction_date, false)}. ${suggestedAccount.number_of_contacts} ${suggestedAccount.number_of_contacts === 1 ? 'contact' : 'contacts'} at the company.`;
                }

                return `<span class="accent">${$filter('timeAgo')(suggestedAccount.last_interaction_date, false)}. ${suggestedAccount.number_of_contacts} ${suggestedAccount.number_of_contacts === 1 ? 'contact' : 'contacts'}.</span>`;
            }
        };

        $scope.$watch(() => {

            return $mdMedia('gt-sm');
        }, function (newValue, oldValue) {

            if (newValue && !oldValue) {
                return $scope.goToAccount($scope.accounts.items[0]);
            }
        });

        $scope.$on(events.account.createdFromCompany, (event, data) => {

            const suggestedAccount = $scope.suggestedAccounts.items.find((x) => x.company.id === data.company);

            if (!suggestedAccount) {
                return;
            }

            if (suggestedAccount.rejected) {
                suggestedAccount.rejected = false;
            }
            else {
                acceptedOrRejectedCount++;
            }


            suggestedAccount.accepted = true;
            suggestedAccount.createdAccountId = data.account;
            suggestedAccount.tooltipTitle = getSuggestedAccountListItemTitle(suggestedAccount);
        });

        $scope.acceptAccountSuggestion = (suggestedAccount, $event) => {

            $event.stopPropagation();

            return account.create({ domain: suggestedAccount.company.domain }).then((response) => {

                if (suggestedAccount.rejected) {
                    accounts.updateSuggestedAccount(suggestedAccount.id, { rejected: false });
                    suggestedAccount.rejected = false;
                }
                else {
                    acceptedOrRejectedCount++;
                }

                suggestedAccount.createdAccountId = response.data.id;
                suggestedAccount.accepted = true;
                suggestedAccount.tooltipTitle = getSuggestedAccountListItemTitle(suggestedAccount);

                if ($mdMedia('gt-sm')) {
                    return $state.go($state.current.name.replace('company', 'account'), { id: response.data.id });
                }
            });
        };

        $scope.rejectAccountSuggestion = (suggestedAccount, $event) => {

            $event.stopPropagation();

            return accounts.deleteSuggestedAccount(suggestedAccount.id).then(() => {

                suggestedAccount.rejected = true;
                acceptedOrRejectedCount++;
                suggestedAccount.tooltipTitle = getSuggestedAccountListItemTitle(suggestedAccount);
            });
        };

        $scope.isSelected = (suggestedAccount) => {

            if (!suggestedAccount || !$mdMedia('gt-sm')) {
                return false;
            }

            if ($state.current.name.includes('company')) {
                return (suggestedAccount.company.id === $scope.selected.id);
            }

            return (suggestedAccount.createdAccountId === $scope.selected.id);
        };

        function init() {

            accounts.getAmountOfNotViewedSuggestedAccounts().then((response) => {

                $scope.notViewedAccountSuggestions = response.data;
            });
        }

        function suggestedAccountsResponse(response, isCacheResult) {

            if (angular.isDefined(response.headers?.()['x-result-count'])) {
                this.totalCount = Number.parseInt(response.headers()['x-result-count']);
            }

            this.items = response.data.map((suggestedAccount) => {
                return { ...suggestedAccount, tooltipTitle: getSuggestedAccountListItemTitle(suggestedAccount) };
            });
            this.numLoaded = this.toLoad;
            this.doneLoading = true;

            if (!isCacheResult && this.items.length > 0 && $mdMedia('gt-sm') && $state.is('accountsuggestions')) {
                return $scope.goToSuggestion(response.data[0]);
            }
        }
    }
}());
