(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('PersonController', PersonController);

    function PersonController($rootScope, $scope, $mdMedia, $mdDialog, $state, $stateParams) {

        $scope.id = $stateParams.id;
        $scope.type = $stateParams.type;

        $scope.back = function () {

            if ($mdMedia('gt-sm')) {
                $mdDialog.hide(false);
            }

            if ($state.current.name === 'contacts.my.contact') {
                if ( $rootScope.history[$rootScope.history.length - 1].fromState.name === 'createContact' ) {
                    $rootScope.history.pop();
                }
            }

            if (!$mdMedia('gt-sm')) {
                $rootScope.back();
            }
        };
    }
})();
