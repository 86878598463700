<sfx-toolbar enableDivider>
    Set targets
</sfx-toolbar>

<sfx-content>
    <sfx-section>
        <form #targetsForm="ngForm" id="targetsForm" name="targetsForm" data-layout="column">
            <mat-form-field>
                <mat-label>Pipeline</mat-label>
                <mat-select name="selectedPipeline" data-layout="row" class="ellipsis"
                            [disabled]="loadingState" [(ngModel)]="currentPipeline" (ngModelChange)="getTargetsForYearAndPipeline()">
                    <mat-option *ngFor="let pipeline of pipelines" [value]="pipeline">{{ pipeline.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Year</mat-label>
                <mat-select name="selectedYear" data-layout="row" class="ellipsis"
                            [disabled]="loadingState" [(ngModel)]="currentYear" (ngModelChange)="getTargetsForYearAndPipeline()">
                    <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-list class="target-list">
                <mat-list-item
                        class="target-list-item"
                        *ngFor="let target of targets"
                        [ngClass]="{'user-disabled': target.disabled }"
                >
                    <span class="list-item-content-wrapper"
                         data-layout="row" data-layout-align="start center">
                        <sfx-avatar [src]="target.picture" class="sf2-avatar_small"></sfx-avatar>
                        <p class="ellipsis target-name">{{ target.name }}</p>
                        <mat-form-field class="target-form-field">
                            <mat-icon class="currency-icon" matPrefix [innerHTML]="currentPipeline.currency.html"></mat-icon>
                            <input matInput [name]="'target-' + target.id" [(ngModel)]="target.target" (ngModelChange)="targetChange()" type="number" min="0" required/>
                            <mat-error *ngIf="targetsForm.controls['target-' + target.id]?.hasError('required')">
                                This field is required.
                            </mat-error>
                            <mat-error *ngIf="targetsForm.controls['target-' + target.id]?.hasError('min')">
                                Please enter a positive number.
                            </mat-error>
                        </mat-form-field>
                    </span>
                </mat-list-item>
            </mat-list>
        </form>
    </sfx-section>
</sfx-content>
