import { Component, Inject, Input, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { StateService } from '@uirouter/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MediaService } from '@core/services/media.service';

@Component({
    selector: 'sfx-custom-fields-settings-list',
    templateUrl: './customfieldssetingslist.component.html',
    styleUrls: ['./customfieldssettingslist.component.scss']
})
export class CustomFieldsSettingsListComponent implements OnInit {

    standardFields: any[] = [];
    predefinedFields: any[] = [];
    customFields: any[] = [];

    pipelines: any[] = [];
    currentPipeline: any;

    doneLoading = false;

    handleCustomFieldResponse = (response: any) => {

        this.standardFields = response.data.filter((field:any) => field.isStandardField);
        this.predefinedFields = response.data.filter((field:any) => field.predefined_customfield).sort((a: any, b: any) => {

            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();

            if (aName < bName) {
                return -1;
            }

            if (aName > bName) {
                return 1;
            }

            return 0;
        });
        this.customFields = response.data.filter((field:any) => !field.isStandardField && !field.predefined_customfield);
        this.doneLoading = true;
    };

    constructor(
        @Inject('customFieldsService') public customFieldsService: any,
        @Inject('mdDialogService') public mdDialog: any,
        @Inject('modelService') public modelService: any,
        @Inject('filterService') public filterService: any,
        @Inject('pipelinesService') private pipelinesService: any,
        public stateService: StateService,
        public media: MediaService
    ) {}

    @Input() itemClass: 'account' | 'contact' | 'opportunity' = 'account';

    public ngOnInit(): void {

        this.get();
    }

    public get = (): void => {

        this.doneLoading = false;

        switch (this.itemClass) {
            case 'opportunity':
                return this.pipelinesService.get().then((pipelinesResponse: any) => {

                    this.pipelines = pipelinesResponse.data;
                    const currentPipelineId = localStorage.getItem(`current_pipeline_${this.modelService.me.id}`);

                    if (currentPipelineId) {
                        this.currentPipeline = this.pipelines.find(function (pipeline) {

                            return pipeline.id.toString() === currentPipelineId;
                        });
                    }

                    if (!this.currentPipeline) {
                        this.filterService.setCurrentPipelineId(this.pipelines[0].id);
                        this.currentPipeline = this.pipelines[0];
                    }

                    return this.customFieldsService.getOpportunityFields(true, this.currentPipeline.id).then(this.handleCustomFieldResponse);
                });
            case 'account':
                return this.customFieldsService.getAccountFields(true).then(this.handleCustomFieldResponse);
            case 'contact':
                return this.customFieldsService.getContactFields(true).then(this.handleCustomFieldResponse);
        }
    }

    public changePipeline = (pipeline: any) => {

        this.currentPipeline = pipeline;
        this.filterService.setCurrentPipelineId(pipeline.id);

        return this.customFieldsService.getOpportunityFields(true, this.currentPipeline.id).then(this.handleCustomFieldResponse);
    };

    public comparePipelines = (o1: any, o2: any) => {

        return o1?.id === o2?.id;
    };

    public editCustomField = (customFieldId: number) => {

        return this.mdDialog.show({
            clickOutsideToClose: true,
            controller: 'EditCustomFieldController',
            templateUrl: 'partials/editcustomfield.html',
            locals: {
                id: customFieldId,
                itemClass: this.itemClass
            },
            bindToController: true,
            fullscreen: !this.media.isActive('gt-sm'),
            multiple: true
        }).then(this.get);
    };

    public disableOrEnableField = (field: any) => {

        field.enabled = !field.enabled;
        field.type = field.type.id;

        const updatedField = cloneDeep(field);
        delete updatedField.api_field;
        // No default values on update
        delete updatedField.default_boolean_value;
        // Type is not allowed on update
        delete updatedField.type;

        switch (this.itemClass) {
            case 'opportunity':
                return this.customFieldsService.updateOpportunityField(updatedField).then(this.get);
            case 'account':
                return this.customFieldsService.updateAccountField(updatedField).then(this.get);
            case 'contact':
                return this.customFieldsService.updateContactField(updatedField).then(this.get);
        }
    };

    public onDropCustomField = ($event: CdkDragDrop<string[]>) => {

        moveItemInArray(this.customFields, $event.previousIndex, $event.currentIndex);

        this.customFields.forEach((field: any, index: number) => {

            field.order = index + 1;
        });

        const orderUpdates = this.customFields.map((field: any) => {

            return {
                id: field.id,
                order: field.order
            };
        });

        switch (this.itemClass) {
            case 'opportunity':
                return this.customFieldsService.updateOpportunityOrder(orderUpdates).then(this.get);
            case 'account':
                return this.customFieldsService.updateAccountOrder(orderUpdates).then(this.get);
            case 'contact':
                return this.customFieldsService.updateContactOrder(orderUpdates).then(this.get);
        }
    }

    public createCustomField(): void {

        this.mdDialog.show({
            clickOutsideToClose: true,
            controller: 'EditCustomFieldController',
            templateUrl: 'partials/editcustomfield.html',
            locals: {
                itemClass: this.itemClass
            },
            bindToController: true,
            fullscreen: !this.media.isActive('gt-sm'),
            multiple: true
        }).then(this.get);
    }
}
