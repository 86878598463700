(function () {
    'use strict';

    angular
        .module('salesflare.components.import.map', [])
        .component('sfImportMap', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/import/map/importmap.html'
        });

    function controller($scope, $mdMedia, $state, $stateParams, $rootScope, model, flagsService, importprogress, imports) {

        if (!$stateParams.id) {
            return (new Error('NO_IMPORT_ID_FOUND'));
        }

        const vm = this;
        vm.$mdMedia = $mdMedia;
        vm.importId = $stateParams.id;
        vm.createAccountIfNotExists = false;
        vm.findEmailIfNotExists = false;
        vm.isFindEmailEnabled = (flagsService.get('findEmail') === true);
        vm.import = {};
        vm.sfHeader = [];
        vm.chosenHeaders = [];
        vm.isRestricted = model.me.restricted;

        vm.$onInit = function () {

            vm.disableImportButton = true;

            // Get import
            return imports.getImport(vm.importId).then(function (response) {

                vm.import = response.data;

                if (vm.import.type === 'contact') {
                    vm.showCreateAccountCheckbox = true;
                    vm.showFindEmailCheckbox = true;

                    $scope.$watchCollection('vm.chosenHeaders', function (newValue) {

                        if (newValue.length > 0) {
                            const accountNameField = newValue.find(function (field) {

                                return field && field.api_field === 'account__name';
                            });

                            vm.enableCreateAccountCheckbox = accountNameField;

                            // Check if find email checkbox needs to be enabled ((First name AND Last name) OR Full name) AND (Account name OR account website)
                            // Account name or account website needed
                            let accountWebsiteField;
                            if (!accountNameField) {
                                accountWebsiteField = newValue.find((field) => field && field.api_field === 'account__website');
                            }

                            if (accountWebsiteField || accountNameField) {
                                const fullNameField = newValue.find((field) => field && field.api_field === 'name');
                                let firstNameField;
                                let lastNameField;
                                if (!fullNameField) {
                                    firstNameField = newValue.find((field) => field && field.api_field === 'firstname');
                                    lastNameField = newValue.find((field) => field && field.api_field === 'lastname');
                                }

                                vm.enableFindEmailCheckbox = fullNameField || (firstNameField && lastNameField);
                            }
                            else {
                                vm.enableFindEmailCheckbox = false;
                            }
                        }
                    });
                }

                if (vm.import.status === 'waiting' || vm.import.status === 'mapping') {
                    return imports.getImportSample(vm.importId).then(function (sampleResponse) {

                        if (sampleResponse.data.length > 0) {
                            vm.headers = sampleResponse.data[0];
                            vm.importSample = [];

                            for (let i = 1; i < sampleResponse.data.length; ++i ) {
                                vm.importSample.push(sampleResponse.data[i]);
                            }

                            return imports.getMapping(vm.importId).then((mappingResponse) => {

                                if (mappingResponse) {
                                    const savedMapping = mappingResponse.data;
                                    return imports.getFields(vm.import.type).then(function (fieldsResponse) {

                                        return setFields(fieldsResponse.data, savedMapping);
                                    });
                                }
                            });

                        }
                    });
                }

                // Redirect settings/imports
                return $state.go('settings.import');
            });
        };

        vm.updateMapping = () => {

            importButtonStatusCheck();

            if (!vm.disableImportButton) {
                saveMapping();
            }
        };

        function importButtonStatusCheck() {

            const definedHeader = vm.chosenHeaders.find(function (header) {

                return angular.isDefined(header);
            });

            if (angular.isUndefined(definedHeader)) {
                vm.disableImportButton = true;
            }
            else {
                vm.disableImportButton = false;
            }
        }

        function saveMapping() {

            const mapping = {};

            vm.chosenHeaders.forEach(function (field, index) {

                if (angular.isUndefined(field)) {
                    return;
                }

                if (field.isStandardField || field.predefined_customfield) {
                    mapping[field.api_field] = vm.headers[index];
                    return;
                }

                let hierarchicalFieldName = 'custom__' + field.api_field;

                if (field.type.type.includes('select', 'autocomplete', 'multiselect', 'tags')) {
                    hierarchicalFieldName += '__name';
                }

                mapping[hierarchicalFieldName] = vm.headers[index];
            });

            return imports.setMapping(vm.import.id, mapping, vm.findEmailIfNotExists, vm.createAccountIfNotExists, vm.updateAccountIfExists)
                .catch(angular.noop); // Don't throw unhandled exception
        }

        vm.startImport = () => {

            vm.disableImportButton = true;

            saveMapping().then(() => {

                return imports.start(vm.import.id).then(function () {

                    importprogress.startPolling();

                    return $state.go('settings.import.' + vm.import.type);
                });
            })
                .catch(angular.noop) // Don't throw unhandled exception
                .finally(() => {

                    vm.disableImportButton = false;
                });
        };

        vm.back = $rootScope.back;

        function setFields(fields, savedMapping) {

            if (fields && fields.length > 0) {
                vm.sfHeader = fields;

                let enableImportButton = false;
                // Uppercase header array to do case insensitive comparison
                const upperCasedHeaders = vm.headers.map(function (headerEntry) {

                    return headerEntry.toUpperCase();
                });

                // If a mapping was saved, don't try to auto match headers
                if (Object.keys(savedMapping).length > 0) {
                    for (const header of Object.keys(savedMapping)) {
                        let field = vm.sfHeader.find((headerField) => headerField.api_field === header);

                        if (!field) {
                            // Try removing generated hierarchicalFieldName. See saveMapping function above
                            let cleanedHeader = header.replace(/^(custom__)/, '');
                            cleanedHeader = cleanedHeader.replace(/(__name)$/, '');
                            field =  vm.sfHeader.find((headerField) => headerField.api_field === cleanedHeader);

                            if (!field) {
                                // The saved mapping wasn't correct, abort
                                return;
                            }
                        }

                        const indexOf = upperCasedHeaders.indexOf(savedMapping[header].toUpperCase());

                        if (indexOf > -1) {
                            enableImportButton = true;
                            vm.chosenHeaders[indexOf] = field;
                        }
                    }
                }
                else {
                    for ( const header in vm.sfHeader) {
                        const indexOf = upperCasedHeaders.indexOf(vm.sfHeader[header].name.toUpperCase());

                        if (indexOf > -1) {
                            enableImportButton = true;
                            vm.chosenHeaders[indexOf] = vm.sfHeader[header];
                        }
                    }
                }

                if (enableImportButton) {
                    vm.disableImportButton = false;
                }
            }
        }
    }
})();
