(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('MoveReportDialogController', MoveReportDialogController);

    function MoveReportDialogController($mdDialog, reportService, utils) {

        const vm = this;

        vm.$onInit = function () {

            vm.dashboard = vm.dashboards[0];
        };

        vm.move = function () {

            if (vm.preventUpdate) {
                return $mdDialog.hide(vm.dashboard.id);
            }

            return reportService.update(vm.reportId, { dashboard: vm.dashboard.id }).then(function () {

                utils.showSuccessToast('Report moved');

                return $mdDialog.hide(vm.dashboard.id);
            });
        };

        vm.cancel = function () {

            return $mdDialog.cancel();
        };
    }
})();
