(function () {
    'use strict';

    angular
        .module('salesflare.components.templates.templateManager', [])
        .component('sfTemplateManager', {
            templateUrl: 'app-ajs/components/templates/templateManager/templateManager.html',
            controller: 'TemplateManagerController',
            controllerAs: 'vm'
        });
})();
