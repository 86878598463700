(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('AddEmailToAccountController', AddEmailToAccountController);

    function AddEmailToAccountController($scope, $window, $stateParams, $timeout, accounts, account, model, config) {

        $window.intercomSettings = {
            hide_default_launcher: true
        };

        $scope.model = model;
        $scope.accounts = [];
        $scope.mode = $stateParams.mode;
        $scope.email = {};
        $scope.downloadOutlook = false;

        if ($stateParams.email) {
            $scope.email.email = $stateParams.email;
        }

        if ($stateParams.subject) {
            $scope.email.subject = $stateParams.subject;
        }

        if ($stateParams.date) {
            $scope.email.date = $stateParams.date;
        }

        switch ($scope.mode) {
            case 'outlook':

                if (!$scope.email.subject) {
                    $scope.downloadOutlook = true;
                }

                $scope.email.email_message_id = $stateParams.id;
                break;

            case 'gmail':

                $scope.email.service_message_id = $stateParams.id;
                break;
        }

        getAccounts();

        $timeout(function () {

            angular.element('input')[0].focus();
        });

        $scope.$watch('searchText', function (newValue, oldValue) {

            if (newValue !== oldValue) {
                return getAccounts();
            }
        });

        $scope.addToAccount = function (accountObject) {

            if (accountObject.can_edit === false) {
                $scope.addEmailToAccountForm.account.$setValidity('accountEditable', false);
                return;
            }

            $scope.addEmailToAccountForm.account.$setValidity('accountEditable', true);

            const accountId = accountObject.id;
            const message = {
                function: 'hide'
            };

            switch ($scope.mode) {
                case 'outlook':

                    account.addEmail(accountId, $scope.email).then(function () {

                        if (config.mode === 'outlook') {
                            // Check if old version of plugin
                            if ($window.external.hide) {
                                $window.external.hide();
                            }
                            else {
                                $window.external.notify(angular.toJson(message));
                            }
                        }
                        else if (config.mode === 'outlook-web') {
                            $window.parent.postMessage({
                                function: 'closeAddEmailToAccountDialog'
                            }, '*');
                        }
                    });

                    break;
                case 'gmail':

                    account.addEmail(accountId, $scope.email).then(function () {

                        $window.parent.postMessage(message, '*');
                    });

                    break;
            }
        };

        function getAccounts() {

            if (!model.isLoggedIn) {
                return;
            }

            return accounts.get($scope.searchText).then(function (response) {

                $scope.accounts = response.data;
            });
        }
    }
})();
