(function () {
    'use strict';

    angular
        .module('salesflare.components.slippingDeals', [])
        .component('sfSlippingDeals', {
            templateUrl: 'app-ajs/components/dashboards/predefinedReports/slippingDeals/slippingDeals.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                data: '<'
            }
        });

    function controller($state, $mdMedia, model) {

        const vm = this;

        vm.model = model;

        ////////////////

        vm.$onChanges = function (changes) {

            if (!changes || !changes.data || !changes.data.currentValue) {
                return;
            }

            if (vm.data.deals.length === 0) {
                vm.data = {
                    no_data: true,
                    id: vm.data.id,
                    deals: [
                        { opportunity: 'Demo for Google', account: 'Google', account_logo: 'https://logo.clearbit.com/google.com', last_interaction_date: new Date().setDate(new Date().getDate() - 5) },
                        { opportunity: 'Demo for Facebook', account: 'Facebook', account_logo: 'https://logo.clearbit.com/facebook.com', last_interaction_date: new Date().setDate(new Date().getDate() - 7) },
                        { opportunity: 'Demo for Youtube', account: 'Youtube', account_logo: 'https://logo.clearbit.com/youtube.com', last_interaction_date: new Date().setDate(new Date().getDate() - 13) }
                    ]
                };
            }
        };

        vm.goToAccount = function (accountId, accountName) {

            if ($mdMedia('gt-sm')) {
                return $state.go('accounts.account.feed', { id: accountId, name: accountName });
            }
            else {
                return $state.go('accounts.account.info', { id: accountId, name: accountName });
            }
        };
    }
})();
