import {
    Component,
    ContentChildren, EventEmitter, Output,
    QueryList,
    ViewChildren
} from '@angular/core';

import { SpeedDialActionDirective } from '@shared/components/speed-dial/speed-dial-action/speed-dial-action.directive';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'sfx-speed-dial',
    templateUrl: './speed-dial.component.html',
    styleUrls: ['./speed-dial.component.scss']
})
export class SpeedDialComponent {

    toggleState = false;

    @ContentChildren(SpeedDialActionDirective) actions!: QueryList<SpeedDialActionDirective>;
    @ViewChildren(MatTooltip) tooltips!: QueryList<MatTooltip>;

    @Output() click = new EventEmitter();

    onMenuClick(event: any) {
        this.click.emit(event);
    }

    constructor() {}

    toggleOpenState() {
        this.toggleState = !this.toggleState;
    }

    get Open(): boolean {
        return this.toggleState;
    }

    get Closed(): boolean {
        return !this.toggleState;
    }

    toggleTooltip(tooltipIdx: number) {
        const tooltip = this.tooltips.get(tooltipIdx);

        tooltip?.toggle();
    }
}
