(function () {
    'use strict';

    angular
        .module('salesflare.components.dashboards', [
            'salesflare.components.dashboards.filter'
        ])
        .component('sfDashboards', {
            templateUrl: 'app-ajs/components/dashboards/dashboards.html',
            controller,
            controllerAs: 'vm'
        });

    function controller($q, $rootScope, $mdMedia, $mdDialog, $state, $stateParams, $timeout, $window, $transitions, pipelines, dashboardsService, dashboardService, model, menu, reportService, reportsService, sfReorderDashboardsDialog, utils, filterService, sfDashboardFilterDialog, flagsService, sfUpgradeDialog) {

        const vm = this;

        vm.$mdMedia = $mdMedia;

        vm.menu = menu;
        vm.role = model.me.role;
        vm.disableDelete = false;
        // Some top bar things change on edit, this var is handled in state change listener but we set initial value here based on the current state as a direct edit state load doesn't trigger the state change event
        vm.editing = $state.current.name === 'dashboards.editDashboard';
        vm.selectedDashboard = undefined;
        vm.dashboards = undefined;
        vm.pendingDashboardUpdates = {
            moveReports: [],
            deleteReports: []
        };
        vm.doneLoading = false;
        let insightsTour = null;

        vm.$onInit = function () {

            if (vm.editing && !['Admin', 'Manager'].includes(model.me.role.name)) {
                return goToViewState();
            }

            if (flagsService.get('showInsightsWalkthrough') === true) {
                showWalkthrough();
            }

            // This happens when directly visiting a dashboard edit URL
            // We don't pass the filter in that case so we need to get/set it here
            // On mobile we need to do this too as we don't load the sf-dashboards-filter component to init the filter
            if (!$mdMedia('gt-sm') || (vm.editing && !vm.dashboardFilter)) {
                return pipelines.getViewable().then(function (response) {

                    const currentPipelineId = store.get('current_pipeline_' + model.me.id);
                    let currentPipeline;

                    if (currentPipelineId) {
                        currentPipeline = response.pipelines.find(function (pipeline) {

                            return pipeline.id === currentPipelineId;
                        });
                    }

                    const insightsFilterCopy = angular.copy(filterService.filters.insights);
                    insightsFilterCopy.pipeline = currentPipeline;

                    if (insightsFilterCopy.owner.type === 'user') {
                        insightsFilterCopy.user = insightsFilterCopy.owner.id;
                    }
                    else {
                        insightsFilterCopy.group = insightsFilterCopy.owner.id;
                    }

                    vm.dashboardFilter = insightsFilterCopy;

                    return reloadList();
                });
            }

            return reloadList();
        };

        function showWalkthrough() {

            $timeout(function () {

                insightsTour = new Shepherd.Tour({
                    defaultStepOptions: {
                        popperOptions: {
                            // Adds an offset of 12px to the tooltip so the arrow (instead of the content) starts on the target element
                            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }]
                        }
                    }
                });

                insightsTour.addStep( {
                    title: 'NEW: custom reports & dashboards!',
                    text: 'You can now create your own custom reports and place them anywhere on your dashboards. <a href="https://howto.salesflare.com/en/articles/5511505-how-can-i-make-custom-dashboards-reports" target="_blank">Read more</a>.',
                    attachTo: {
                        element: $mdMedia('gt-sm') ? '#add-report-button' : '#dashboards-kebab-menu',
                        on: 'bottom'
                    },
                    buttons: [{
                        action: insightsTour.next,
                        text: 'GOT IT!'
                    }]
                });

                insightsTour.on('complete', function () {

                    return flagsService.set('showInsightsWalkthrough', false);
                });

                return insightsTour.start();
            }, 2000);
        }

        const removeSuccessTransitionHook = $transitions.onSuccess({}, (transition) => {

            const toState = transition.to();

            if (toState.name === 'dashboards.dashboard') {
                vm.editing = false;
            }
            else if (toState.name === 'dashboards.editDashboard') {
                vm.editing = true;
            }
        });

        vm.$onDestroy = function () {

            removeSuccessTransitionHook();

            if (insightsTour) {
                insightsTour.cancel();
            }
        };

        vm.getAmountOfNotViewedNotifications = function () {

            return model.me.amountOfNotViewedNotifications;
        };

        vm.onSelectedDashboardChange = function () {

            // We have check the state here and not `vm.editing` as this triggers before `$stateChangeSuccess`
            if (vm.editing) {
                goToEditState();
            }
            else {
                goToViewState();
            }
        };

        vm.onFilterChanged = function ($event) {

            if ($event.filter) {
                vm.dashboardFilter = $event.filter;
            }
        };

        // On create we technically select an empty option.
        // To keep the selector on the actually selected dashboard we 'reset' it back to the actual selected dashboard here.
        vm.setSelectedDashboard = function (selectedDashboard) {

            $timeout(function () {

                vm.selectedDashboard = selectedDashboard;
            });
        };

        vm.setFilters = function () {

            return sfDashboardFilterDialog.show().then(function (dashboardFilter) {

                vm.dashboardFilter = dashboardFilter;
            });
        };

        vm.showCreateDialog = function () {

            if (!hasFlagEnabled()) {
                return sfUpgradeDialog.show('customDashboards');
            }

            const config = {
                dialogId: 'createDashboardDialog',
                title: 'Create dashboard',
                ariaLabel: 'Dashboard name',
                label: 'Dashboard name',
                formId: 'dashboardCreateForm',
                required: true,
                inputName: 'dashboardName',
                requiredErrorMessage: 'A name is required',
                cancelText: 'Cancel',
                okText: 'Create'
            };

            return $mdDialog.show($mdDialog.sfPromptDialog(config)).then(function (dashboardName) {

                return dashboardsService.create(dashboardName).then(function (createResponse) {

                    const dashboardId = createResponse.data.id;

                    return reloadList({ select: dashboardId }).then(function () {

                        utils.showSuccessToast('Dashboard created');

                        return goToViewState();
                    });
                });
            });
        };

        vm.cancel = function () {

            goToViewState();
        };

        vm.save = function () {

            return $q.all([
                dashboardService.update(vm.selectedDashboard.id, { name: vm.selectedDashboard.name }),
                function moveReports() {

                    if (vm.pendingDashboardUpdates.moveReports.length > 0) {
                        return $q.all(vm.pendingDashboardUpdates.moveReports.map(function (reportToMove) {

                            return reportService.update(reportToMove.reportId, { dashboard: reportToMove.dashboardId });
                        }));
                    }
                }(),
                function deleteReports() {

                    if (vm.pendingDashboardUpdates.deleteReports.length > 0) {
                        return $q.all(vm.pendingDashboardUpdates.deleteReports.map(function (reportIdToDelete) {

                            return reportService.delete(reportIdToDelete);
                        }));
                    }
                }(),
                function updateReportOrder() {

                    if (vm.pendingDashboardUpdates.updateReportOrder) {
                        return reportsService.update(vm.pendingDashboardUpdates.updateReportOrder);
                    }
                }()
            ]).then(function () {

                utils.showSuccessToast('Dashboard saved');

                goToViewState();
            });
        };

        vm.edit = function () {

            goToEditState();
        };

        vm.reorder = function () {

            return sfReorderDashboardsDialog.show(vm.dashboards).then(function () {

                return reloadList({ select: vm.selectedDashboard.id });
            });
        };

        vm.print = function () {

            const url = $state.href('printDashboard', { id: vm.selectedDashboard.id });
            return $window.open(url,'_blank');
        };

        vm.deleteDashboard = function () {

            const confirm = $mdDialog.confirm()
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .title('Are you sure?')
                .textContent('Are you sure you want to delete dashboard ' + vm.selectedDashboard.name + '?')
                .ok('I\'m sure')
                .cancel('Cancel');

            return $mdDialog.show(confirm).then(function () {

                return dashboardService.delete(vm.selectedDashboard.id).then(function () {

                    utils.showSuccessToast('Dashboard ' + vm.selectedDashboard.name + ' has been deleted');

                    return reloadList();
                });
            });
        };

        vm.addReport = function () {

            // Complete walkthrough
            if (insightsTour) {
                insightsTour.next();
            }

            analytics.track('Create report started', { gaClientId: model.gaClientId });

            $state.go('createReport', { dashboardId: vm.selectedDashboard.id });
        };

        vm.triggerInsightsTourNext = function () {

            // Complete walkthrough
            if (insightsTour) {
                insightsTour.next();
            }
        };

        vm.showPlanFlags = function () {

            const onTrial = model.me.team.payment_type !== 'free' && !model.me.team.subscribed && model.me.trial_expiry_date;
            return (onTrial || !hasFlagEnabled());
        };

        ////////////////////////

        /**
         *
         * @param {Object} [options]
         * @param {Number} [options.select] dashboard id to select after reload of list, defaults to stateParam and then the first one in the list
         * @returns {Promise.<void>}
         */
        function reloadList(options) {

            options = options || {};

            return dashboardsService.get().then(function (response) {

                vm.dashboards = response.data;

                vm.selectedDashboard = response.data.find(function (dashboard) {

                    return dashboard.id === (options.select || $stateParams.id || filterService.getCurrentDashboardId());
                }) || response.data[0];
                vm.disableDelete = vm.dashboards.length === 1;
                vm.doneLoading = true;

                return vm.onSelectedDashboardChange();
            });
        }

        function goToEditState() {

            return $state.go('dashboards.editDashboard', { id: vm.selectedDashboard.id });
        }

        function goToViewState() {

            return $state.go('dashboards.dashboard', { id: vm.selectedDashboard.id });
        }

        function hasFlagEnabled() {

            return (model.me.plan_flags.customDashboards || flagsService.get('customDashboards'));
        }
    }
})();
