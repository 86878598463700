(function () {
    'use strict';

    /**
     * Contains the strings for all the events being emitted on rootScope
     * This makes it easy to reference and gives an easy overview
     *
     * TODO: (adri) replace all strings for $rootScope.$broadcast and $rootScope.$on
     */
    angular
        .module('salesflare')
        .constant('events', {
            refreshData: 'refreshData',
            opportunity: {
                deleted: 'opportunity:deleted'
            },
            account: {
                loaded: 'account:loaded',
                updated: 'account:updated',
                createdFromCompany: 'account:created_from_company'
            }
        });
})();
