(function () {
    'use strict';

    angular
        .module('salesflare.components.workflows', [])
        .component('sfWorkflows', {
            templateUrl: 'app-ajs/components/workflows/workflows.html',
            controller,
            controllerAs: 'vm'
        });

    function controller($scope, $state, $stateParams, $mdMedia, $mdDialog, $mdSidenav, $timeout, $document, authentication, filterService, menu, model, utils, workflowService, workflowsService, sortService, sfUpgradeDialog) {

        const vm = this;
        let searchDebounce;

        vm.$mdMedia = $mdMedia;
        vm.menu = menu;
        vm.isWorkflowFilterApplied = filterService.isWorkflowFilterApplied;
        vm.loading = true;
        vm.filters = filterService.getFilter('workflow', { raw: true });
        const entityCountObject = {
            viewTotalCount: 0,
            viewCurrentCount: 0,
            selectedCount: 0,
            option: '',
            entity: 'workflows',
            entity_singular: 'workflow',
            string: ''
        };
        vm.me = model.me;
        vm.showPlanFlags = function (workflow) {

            const onTrial = model.me.team.payment_type !== 'free' && !model.me.team.subscribed && model.me.trial_expiry_date;
            const workflowWasLiveBeforeReleaseDate = workflow.status === 'live' || (workflow.status === 'paused' && workflow.scheduled_before_pro_release);
            return ((onTrial || !model.me.plan_flags.multiStepWorkflows) && workflow.step_amount > 1 && !workflow.multi_step_before_pro_release && !workflowWasLiveBeforeReleaseDate);
        };

        vm.sortOptions = sortService.getSortOptions('workflow');
        vm.insufficientPermissionsMessage = 'You don\'t have permission to edit this workflow. Ask an admin.';

        vm.getDefaultFilters = function () {

            return filterService.getDefaultFilters('workflow', model.me);
        };

        vm.onFilterApply = function ($event) {

            filterService.setFilter('workflow', $event.rules);
            return vm.get();
        };

        vm.searchObject = {
            search: $stateParams.search,
            getSearch: function () {

                return this.search;
            },
            setSearch: function (s) {

                this.search = s;

                return utils.setSearchInUrl({ search: this.search });
            }
        };
        vm.searchMode = !!$stateParams.search;

        vm.workflows = {
            toLoad: 0,
            numLoaded: 0,
            stepAmount: 20,
            items: [],
            topIndex: 0,

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + this.stepAmount;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad < index) {
                    this.toLoad += this.stepAmount;

                    const boundWorkflowsResponseHandler = angular.bind(this, this.workflowsResponseHandler);
                    let workflowFilterRules;

                    // Do not filter when searching
                    if (vm.searchObject.getSearch()) {
                        workflowFilterRules = [];
                    }
                    else {
                        workflowFilterRules = filterService.getFilter('workflow');
                    }

                    const requestConfig = {
                        search: vm.searchObject.getSearch(),
                        filters: workflowFilterRules,
                        limit: this.stepAmount,
                        offset: (this.toLoad - this.stepAmount),
                        orderBy: sortService.getCurrentSortOption('workflow').order_by
                    };

                    workflowsService.get({}, { returnCountOnly: true }).then(function (response) {

                        return countResponseHandler(response, 'totalCount');
                    }, handleServerError, function (response) {

                        return countResponseHandler(response, 'totalCount');
                    });

                    // Adding this to get around the 'calculated ' count errors https://github.com/Salesflare/Server/issues/5614
                    if (vm.searchObject.getSearch() || vm.isWorkflowFilterApplied()) {
                        workflowsService.get(requestConfig, { returnCountOnly: true }).then(function (response) {

                            return countResponseHandler(response, 'currentCount');
                        }, handleServerError, function (response) {

                            return countResponseHandler(response, 'currentCount');
                        });
                    }
                    else {
                        // If we don't do a call for the current count, we need to reset it. Otherwise it will never update.
                        entityCountObject.viewCurrentCount = 0;
                    }

                    return workflowsService.get(requestConfig, { includeCount: false } ).then(boundWorkflowsResponseHandler, handleServerError, boundWorkflowsResponseHandler);
                }
            },

            workflowsResponseHandler: function (response) {

                const workflows = response.data;
                this.items = [...this.items, ...workflows];
                this.numLoaded += this.stepAmount;
                vm.loading = false;
            },

            // Forces reset of object
            reload: function () {

                if ((this.items && this.items.length > 0) || !vm.searchObject.getSearch()) {
                    vm.loading = true;
                }

                this.toLoad = 0;
                this.numLoaded = 0;
                this.items = [];
                this.topIndex = 0;
            }
        };

        function countResponseHandler(response, type) {

            if (angular.isDefined(response.headers()['x-result-count'])) {
                entityCountObject.option = angular.isDefined(vm.searchObject.getSearch()) && vm.searchObject.getSearch() !== '' ? 'search' : (vm.isWorkflowFilterApplied() ? 'filter' : '');
                if (response.config.method === 'HEAD') {
                    // Head calls are done to get the total view count

                    if (type === 'totalCount') {
                        entityCountObject.viewTotalCount = Number.parseInt(response.headers()['x-result-count']);
                    }

                    if (type === 'currentCount') {
                        entityCountObject.viewCurrentCount = Number.parseInt(response.headers()['x-result-count']);
                    }

                    // EntityCountObject.viewTotalCount = parseInt(response.headers()['x-result-count']);
                }
                else {
                    entityCountObject.viewCurrentCount = Number.parseInt(response.headers()['x-result-count']);
                }

                entityCountObject.string = utils.getEntityCountString(entityCountObject);
            }
        }

        vm.getEntityCountString = function () {

            return utils.getEntityCountString(entityCountObject);
        };

        vm.get = function () {

            return vm.workflows.reload();
        };

        vm.createNewWorkflow = function () {

            return $state.go('workflow', { id: 'new' });
        };

        vm.setSearchMode = function (mode) {

            if (vm.searchMode && !mode) {
                vm.searchMode = mode;

                if (!mode && vm.searchObject.getSearch()) {
                    vm.searchObject.setSearch('');
                    return vm.get();
                }

                vm.searchObject.setSearch('');
            }

            vm.searchMode = mode;

            if (vm.searchMode) {
                if (vm.$mdMedia('gt-sm')) {
                    utils.forceFocus($document[0].querySelector('#searchbox-fs'));

                }
                else {
                    utils.forceFocus($document[0].querySelector('#searchbox'));

                }
            }
        };

        vm.doSearch = function (search) {

            $timeout.cancel(searchDebounce);

            searchDebounce = $timeout(function () {

                vm.searchObject.setSearch(search);

                return vm.get();
            }, 750);
        };

        vm.setFilters = function () {

            return $mdSidenav('filters').toggle().then(function () {

                return $mdSidenav('filters', true);
            });
        };

        vm.selectSortOption = function (sortKey) {

            sortService.setCurrentSortKey('workflow', sortKey);
            return vm.get();
        };

        vm.isSortOptionSelected = function (sortKey) {

            return sortService.getCurrentSortKey('workflow') === sortKey;
        };

        vm.archive = function (workflowId) {

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .textContent('Are you sure you want to delete this workflow?')
                .ok('Yes')
                .cancel('No');

            return $mdDialog.show(confirm).then(function () {

                return workflowService.archive(workflowId).then(vm.get).then(() => {

                    // Refetch `me` to potentially hide `failed_workflows` status bar when shown
                    if (model.me.has_recently_failed_workflows) {
                        authentication.fetchMe({ ignoreLoadingBar: true }).finally(angular.noop);
                    }
                });
            });

        };

        vm.viewWorkflow = function (workflowId, viewing) {

            if (viewing) {
                return $state.go('workflow-analytics', { id: workflowId, viewing, returnState: 'workflows' });
            }

            return $state.go('workflow', { id: workflowId });
        };

        vm.edit = function (workflowId) {

            return $state.go('workflow', { id: workflowId, editing: true });
        };

        vm.play = function (workflow) {

            const workflowWasLiveBeforeReleaseDate = workflow.status === 'live' || (workflow.status === 'paused' && workflow.scheduled_before_pro_release);
            if (!model.me.plan_flags.multiStepWorkflows && !workflowWasLiveBeforeReleaseDate && !workflow.multi_step_before_pro_release && workflow.step_amount > 1) {
                return sfUpgradeDialog.show('multiStepWorkflows');
            }

            return workflowService.update(workflow.id, { status: 'live' }).then(vm.get);
        };

        vm.pause = function (workflowId) {

            return workflowService.update(workflowId, { status: 'paused' }).then(vm.get);
        };

        vm.handleFilterError = ($event) => {

            vm.badFilterError = $event.message;
        };

        function handleServerError(response) {

            if (response && (response.status === 422 || response.status === 402)) {
                $scope.usedBadFilter = true;

                if (response.data.filter) {

                    const selectedRules = filterService.flatten(filterService.getFilter('workflow', { raw: true }));
                    const hydratedRules = filterService.flatten(response.data.filter.rules);

                    const rulesToApply = filterService.intersectAndEnrich(selectedRules, hydratedRules);

                    $scope.filters = filterService.setFilter('workflow', rulesToApply);

                    // Vm.get();
                    // We don't want to reload with broken filters, since we already tried to fetch. We used to delete these.
                }
            }
        }

        $scope.$on('refreshData', vm.get);
    }
})();
