(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('UpdateSMTPSettingsController', UpdateSMTPSettingsController);

    function UpdateSMTPSettingsController($scope, $mdDialog, datasources) {

        $scope.smtpSettings = {
            email: this.email,
            username: null,
            password: null,
            host: this.host,
            port: this.port,
            store_sent_emails: this.store_sent_emails
        };
        const dontStoreSentEmailCopyByDefaultForThese = new Set(['imap.zoho.com', 'smtp.zoho.com', 'imappro.zoho.com', 'imap.zoho.eu', 'smtp.zoho.eu', 'imappro.zoho.eu', 'imap.gmail.com', 'outlook.office365.com', 'imap-mail.outlook.com']);

        $scope.save = function () {

            if ($scope.smtpSettingsForm.$invalid) {
                return;
            }

            $scope.verifyingSMTPConnection = true;

            return datasources.createSMTPSource($scope.smtpSettings).then(function () {

                $mdDialog.hide($scope.smtpSettings);
            }).finally(function () {

                $scope.verifyingSMTPConnection = false;
            });
        };

        $scope.setStoreSentEmailsDefault = function () {

            if (!$scope.smtpSettings.host) {
                return;
            }

            $scope.smtpSettings.store_sent_emails = !(dontStoreSentEmailCopyByDefaultForThese.has($scope.smtpSettings.host.toLowerCase()));
        };

        $scope.close = $mdDialog.hide;
    }
})();
