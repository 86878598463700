(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('reportService', reportService);

    function reportService(sfHttp, config, filterService) {

        this.predefinedScoreCards = ['revenue', 'avg-sales-cycle', 'avg-won-value', 'won-count', 'new-count', 'most-active-rep', 'most-sold-by-rep', 'avg-sent-mails', 'avg-calls', 'avg-meetings'];
        this.predefinedCharts = ['revenue-vs-goal', 'revenue-vs-prev-period', 'funnel-analysis', 'quota-per-rep', 'closing-percentage', 'closing-percentage-per-rep', 'top-earning-accounts', 'top-lost-reasons', 'top-lead-sources', 'activity-per-rep', 'slipping-deals', 'mrr'];

        this.get = function (reportId) {

            return sfHttp.get(config.apiUrl + 'reports/' + reportId, { cache: true });
        };

        this.update = function (reportId, update) {

            const clonedUpdate = angular.copy(update);

            if (update.filter && update.filter.rules) {
                clonedUpdate.filter = filterService.cleanAdvancedFilterForHttp(clonedUpdate.filter.rules);
            }

            return sfHttp.put(config.apiUrl + 'reports/' + reportId, clonedUpdate);
        };

        this.delete = function (reportId) {

            return sfHttp.delete(config.apiUrl + 'reports/' + reportId);
        };

        this.getData = function (reportId, params, options) {

            const request = {
                cache: true,
                ...options,
                params: angular.copy(params)
            };

            return sfHttp.get(config.apiUrl + 'reports/' + reportId + '/data', request);
        };
    }
})();
