(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditPlanDialogController', EditPlanDialogController);

    function EditPlanDialogController($scope, $state, $mdDialog, $mdMedia, data, plansService) {

        const originalValue = data.selectedValue;

        $scope.$mdMedia = $mdMedia;
        $scope.title = data.title;
        $scope.selectedValue = originalValue;
        $scope.pricingSwitchOptions = data.pricingSwitchOptions;
        $scope.editPlan = data.editPlan;
        $scope.$state = $state;
        $scope.plans = [];

        init();

        function init() {

            $scope.plans = plansService.get();
        }

        $scope.updatePlan = function () {

            if (data.appliedDiscount && $scope.selectedValue !== originalValue && data.appliedDiscountType === 'threeForOne') {
                const confirmDialogContent = 'Switching from the annual to the monthly plan will remove your current discount.<br>Are you sure?';
                const confirm = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .htmlContent(confirmDialogContent)
                    .ok('Yes')
                    .cancel('No');

                return $mdDialog.show(confirm).then(function () {

                    return $mdDialog.hide($scope.selectedValue);
                });
            }

            if ($scope.selectedValue === 'annually' && $scope.selectedValue !== originalValue) {
                const confirmDialogTitle = 'Are you sure?';
                const confirmDialogContent = 'Upon switching to annual billing, you will be charged for the coming year starting today.<br>Any payments you\'ve already done for future usage will be used as credit towards the charge.';
                const confirm = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .title(confirmDialogTitle)
                    .htmlContent(confirmDialogContent)
                    .ok('Yes')
                    .cancel('No');

                return $mdDialog.show(confirm).then(function () {

                    return $mdDialog.hide($scope.selectedValue);
                });
            }

            return $mdDialog.hide($scope.selectedValue);
        };

        $scope.cancel = function () {

            $mdDialog.hide();
        };
    }
})();
