(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ResetPasswordController', ResetPasswordController);

    function ResetPasswordController($scope, $stateParams, $state, utils, model, authentication, password, $window) {

        // Library needs to be placed on window. Quite big, so best load it on demand at controller level.
        $window.zxcvbn = require('zxcvbn');

        $scope.token = $stateParams.t;
        $scope.password = null;
        $scope.confirmation = null;
        $scope.saving = false;

        $scope.save = function save() {

            if (!$scope.passwordForm.$valid) {
                return;
            }

            $scope.saving = true;

            return password.update($scope.password, $scope.token).then(function (response) {

                $scope.saving = false;

                model.setToken(response.data.token);

                return authentication.fetchMe().then(function () {

                    if ($scope.token) {
                        model.isLoggedIn = true;

                        return $state.go('tasks.today');
                    }

                    $scope.password = '';
                    $scope.confirmation = '';

                    $scope.passwordForm.newPassword.$touched = false;
                    $scope.passwordForm.confirmPassword.$touched = false;

                    utils.showSuccessToast('Password changed');
                });
            }).catch(function () {

                $scope.saving = false;
            });
        };
    }
})();
