(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('NotificationSettingsController', NotificationSettingsController);

    function NotificationSettingsController($scope, model, notifications) {

        $scope.model = model;
        $scope.settings = null;

        init();

        function init() {

            notifications.getSettings().then(function (response) {

                $scope.settings = response.data;
            });

            $scope.$watch('settings.mention', function (oldVal) {

                if (oldVal) {
                    $scope.settings.message = !$scope.settings.mention;
                }
            });

            $scope.$watch('settings.message', function (oldVal) {

                if (oldVal) {
                    $scope.settings.mention = !$scope.settings.message;
                }
            });

            $scope.$watch('settings', function (newVal, oldVal) {

                if (newVal && oldVal) {
                    notifications.updateSettings(newVal);
                }
            }, true);
        }
    }
})();
