(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('calendars', calendarsService);

    function calendarsService(sfHttp, config) {

        this.update = function (accountId, calendarId, update) {

            return sfHttp.put(config.apiUrl + 'datasources/calendar/accounts/' + accountId + '/calendars/' + calendarId, update);
        };

        this.getAccounts = function (ignoreLoadingBar) {

            const request = {};

            if (ignoreLoadingBar) {
                request.ignoreLoadingBar = true;
            }

            return sfHttp.get(config.apiUrl + 'datasources/calendar/accounts', request);
        };

        this.disconnectAccount = function (accountId) {

            return sfHttp.delete(config.apiUrl + 'datasources/calendar/accounts/' + accountId);
        };
    }
}());
