<div class="sf-pricing-switch" [ngClass]="{'edit-plan': editPlan}" data-layout-gt-xs="row">
    <button mat-button class="sf-pricing-switch left" [ngClass]="{'selected': options.left.value === selectedValue, 'edit-plan': editPlan}" (click)="setValue(options.left.value)">
        <div class="button-content" data-layout="column">
            <div class="pricing-info">
                <div class="plan">{{ options.left.title }}</div>
                <div class="plan-pricing-amount">
                    <span [ngClass]="{ strikethrough: options.discounted }"><span dir="ltr" [innerHTML]="options.htmlEncodedBillingCurrency"></span>{{ options.left.pricePerMonth }}</span>
                    <span *ngIf="options.discounted"><span dir="ltr" [innerHTML]="options.htmlEncodedBillingCurrency"></span>{{ options.left.pricePerMonth / 2 }}</span>
                </div>
                <div class="plan-pricing-text"> user/month</div>
                <div class="plan-pricing-text">
                    <span [ngClass]="{ strikethrough_1: options.discounted }"><span dir="ltr" [innerHTML]="options.htmlEncodedBillingCurrency"></span>{{ options.left.pricePerBillingCycle * options.quantity }}</span>
                    <span *ngIf="options.discounted"><span dir="ltr" [innerHTML]="options.htmlEncodedBillingCurrency"></span>{{ (options.left.discountedPricePerBillingCycle * options.quantity) | number : '1.0-2' }}</span>/year for {{ options.quantity === 1 ? '1 user' : options.quantity + " users" }}
                </div>
            </div>
        </div>
    </button>
    <button mat-button class="sf-pricing-switch right" [ngClass]="{'selected': options.right.value === selectedValue, 'edit-plan': editPlan}" (click)="setValue(options.right.value)">
        <div class="button-content" layout="column">
            <div class="pricing-info">
                <div class="plan">{{ options.right.title }}</div>
                <div class="plan-pricing-amount">
                    <span [ngClass]="{ strikethrough: options.discounted }"><span dir="ltr" [innerHTML]="options.htmlEncodedBillingCurrency"></span>{{ options.right.pricePerMonth }}</span>
                    <span *ngIf="options.discounted"><span dir="ltr" [innerHTML]="options.htmlEncodedBillingCurrency"></span>{{ options.right.pricePerMonth / 2 }}</span>
                </div>
                <div class="plan-pricing-text"> user/month</div>
                <div class="plan-pricing-text">
                    <span [ngClass]="{ strikethrough_1: options.discounted }"><span dir="ltr" [innerHTML]="options.htmlEncodedBillingCurrency"></span>{{ options.right.pricePerBillingCycle * options.quantity }}</span>
                    <span *ngIf="options.discounted"><span dir="ltr" [innerHTML]="options.htmlEncodedBillingCurrency"></span>{{ (options.right.discountedPricePerBillingCycle * options.quantity) | number : '1.0-2' }}</span>/month for {{ options.quantity === 1 ? '1 user' : options.quantity + " users" }}
                </div>
            </div>
        </div>
    </button>
</div>
