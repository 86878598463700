(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('menu', menuService);

    function menuService($mdSidenav) {

        this.toggle = function () {

            return $mdSidenav('menu').toggle();
        };
    }
})();
