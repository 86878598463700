import { NgModule } from '@angular/core';

import { ContentModule } from '@shared/components/layout/content/content.module';
import { HeaderModule } from '@shared/components/layout/header/header.module';
import { SectionModule } from '@shared/components/layout/section/section.module';
import { ToolbarModule } from '@shared/components/layout/toolbar/toolbar.module';
import { NavigationToolbarModule } from '@shared/components/layout/navigation-toolbar/navigation-toolbar.module';

@NgModule({
    declarations: [],
    imports: [
        ContentModule,
        HeaderModule,
        SectionModule,
        ToolbarModule,
        NavigationToolbarModule
    ],
    exports: [
        ContentModule,
        HeaderModule,
        SectionModule,
        ToolbarModule,
        NavigationToolbarModule
    ]
})
export class SfxLayoutModule {}
