(function () {
    'use strict';

    angular
        .module('salesflare.components.emptystate', [])
        .component('sfEmptyState', {
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/emptystate/emptystate.html',
            bindings: {
                image: '@',
                text: '<',
                text2: '<',
                buttonText: '@'
            }
        });
}());
