/* eslint-disable camelcase */
/* eslint-disable angular/timeout-service */

import { Component, Inject } from '@angular/core';
import { MediaService } from '@core/services/media.service';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, mergeMap, pairwise } from 'rxjs/operators';
import { NotificationService } from '@core/services/notifications.service';

import { ApiResponseModel as ApiResponse } from '@shared/models/services/api.response.model';

interface Provider {
    id: string;
    label: string;
    icon: string;
}

interface CalendarEntity {
    id: number;
    data_source_cronofy: number;
    external_id: string;
    name: string;
    readonly: boolean;
    deleted: boolean;
    primary: boolean;
    active: boolean;
    channel_id: string;
}

interface CalendarAccount {
    id: number;
    user: number;
    profile_name: string;
    name: string;
    type: string;
    calendars: CalendarEntity[];
    avatar: string;
    disconnecting: boolean;
}

interface State {
    comesFromCreate: boolean;
    calendarAccounts: CalendarAccount[]
}

@Component({
    selector: 'sfx-calendar-settings',
    templateUrl: './calendar-settings.component.html',
    styleUrls: ['./calendar-settings.component.scss']
})
export class CalendarSettingsComponent {

    public calendarAccounts$: Observable<CalendarAccount[]>;
    public calendarAccountsSubject$: BehaviorSubject<State>;

    public getCalendarAccounts$: BehaviorSubject<boolean>;

    public readonly providers: Provider[] = [
        {
            id: 'apple',
            label: 'iCloud',
            icon: 'apple'
        },
        {
            id: 'exchange',
            label: 'Exchange',
            icon: 'exchange'
        },
        {
            id: 'outlook',
            label: 'Outlook.com',
            icon: 'outlook'
        },
        {
            id: 'office365',
            label: 'Office 365',
            icon: 'office'
        },
        {
            id: 'google',
            label: 'Google',
            icon: 'google'
        }
    ];

    constructor(
        @Inject('calendarService') public calendarService: any,
        @Inject('calendarSyncProgressService') public calendarSyncProgressService: any,

        @Inject('dialogService') private dialogService: any,

        @Inject('config') public config: any,
        @Inject('helperFunctionsService') public helperFunctionsService: any,
        @Inject('modelService') public modelService: any,
        @Inject('sfSetupPanel') public sfSetupPanel: any,

        @Inject('utilsService') public utilsService: any,
        @Inject('windowService') public windowService: any,

        public media: MediaService,
        public notificationService: NotificationService
    ) {
        this.calendarAccountsSubject$ = new BehaviorSubject<State>({ comesFromCreate: false, calendarAccounts: [] });

        this.calendarAccounts$ = this.calendarAccountsSubject$
            .pipe(
                pairwise(),
                tap(([previousState, currentState]: [State, State]) => {

                    if (currentState.comesFromCreate && currentState.calendarAccounts.length > previousState.calendarAccounts.length) {

                        this.calendarSyncProgressService.startPolling();
                        this.notificationService.success('Great! Your calendar is connected. Salesflare is now syncing your meetings.');

                        if (this.modelService.me?.team?.subscribed) {

                            setTimeout(this.sfSetupPanel.updateSetupSteps, 300);
                        }
                    }
                }),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                map(([previousState, currentState]: [State, State]) => {

                    currentState.calendarAccounts.forEach((calendarAccount) => {

                        calendarAccount.avatar =  `https://lib.salesflare.com/providers/${calendarAccount.name.toLowerCase()}.png`;
                    });

                    return currentState.calendarAccounts;
                })
            );

        this.getCalendarAccounts$ = new BehaviorSubject(false);

        this.getCalendarAccounts$
            .pipe(
                mergeMap(async (comesFromCreate) => {

                    const calendarAccountsResponse: ApiResponse<CalendarAccount[]> = await this.calendarService.getAccounts();

                    return {
                        comesFromCreate,
                        calendarAccounts: calendarAccountsResponse.data
                    };
                })
            )
            .subscribe({
                next: (state) => {

                    this.calendarAccountsSubject$.next(state);
                }
            });
    }

    private showAuthPopup = (popUpUrl: string, target: string) => {

        if (this.config.mode === 'outlook-web') {
            return this.helperFunctionsService.handleOutlookPluginOAuthFlow(popUpUrl);
        }

        return this.utilsService.popup(popUpUrl, target);
    }

    public create = () => {

        this.showAuthPopup(`${this.config.apiUrl}datasources/calendar/connect?id=${this.modelService.me.id}`, this.windowService.isMobile ? '_system' : '')
            .then((queryParams: any) => {

                if (queryParams && queryParams.get('success') !== 'true') {
                    if (queryParams.get('error') === 'UNAUTHORIZED_SCOPES') {

                        const insufficientPermissionsDialog = this.dialogService.confirm({ multiple: true })
                            .clickOutsideToClose(true)
                            .escapeToClose(true)
                            .title('Please enable all permissions')
                            .textContent('Salesflare needs all the requested permissions to be able to automate your CRM work.')
                            .ok('Enable')
                            .cancel('cancel');

                        return this.dialogService.show(insufficientPermissionsDialog)
                            .then(() => {

                                this.create();
                            });
                    }
                }

                this.getCalendarAccounts$.next(true);
            });
    }

    public connectProvider = () => {

        // For future reference: use datasources/calendar/connect/${providerId} for the new connector.
        return this.showAuthPopup(`${this.config.apiUrl}datasources/calendar/connect?id=${this.modelService.me.id}`, this.windowService.isMobile ? '_system' : '')
            .then((queryParams: any) => {

                if (queryParams && queryParams.get('success') !== 'true') {
                    if (queryParams.get('error') === 'UNAUTHORIZED_SCOPES') {

                        const insufficientPermissionsDialog = this.dialogService.confirm({ multiple: true })
                            .clickOutsideToClose(true)
                            .escapeToClose(true)
                            .title('Please enable all permissions')
                            .textContent('Salesflare needs all the requested permissions to be able to automate your CRM work.')
                            .ok('Enable')
                            .cancel('cancel');

                        return this.dialogService.show(insufficientPermissionsDialog)
                            .then(() => {

                                this.connectProvider();
                            });
                    }
                }

                this.getCalendarAccounts$.next(true);
            });
    }

    public disconnect = (account: CalendarAccount) => {


        account.disconnecting = true;

        this.calendarService.disconnectAccount(account.id).then(() => {

            this.notificationService.success('Disconnected');

            this.getCalendarAccounts$.next(false);
        }).catch(() => {

            account.disconnecting = false;

            this.getCalendarAccounts$.next(false);
        });
    }

    public changeActiveState = (account: CalendarAccount, calendar: CalendarEntity) => {

        this.calendarService.update(account.id, calendar.id, { active: calendar.active }).then(() => {

            this.getCalendarAccounts$.next(true);
        }).catch(() => {

            calendar.active = !calendar.active;
        });
    }
}
