<mat-drawer-container>

    <mat-drawer class="sidenav-filters" #drawer mode="over" position="end">

        <sfFilters entity="tag" group="tag" [filters]="filters" hideSavedFilters="true" (onClose)="drawer.close()" (onFilterApply)="onFilterApplied($event)" (getDefaultFilters)="getDefaultFilters()" (setErrorMessage)="handleFilterError($event)">

        </sfFilters>

    </mat-drawer>

    <sfx-toolbar enableDivider [ngClass]="{ 'bulk-select-toolbar': bulkSelectState.active }" [color]="ShowBulkSelect ? 'warn' : ''" [height]="'100%'" [disableBack]="searchActive || bulkSelectState.active">
        <ng-template #menuButton>
            <mat-icon [matMenuTriggerFor]="menu" track-id="menu-tag" fontSet="salesflare" fontIcon="sf-icon-menu-small-vertical" class="icon-24 toolbar-action">
            </mat-icon>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="updateAutomatedTagging(!modelService.me.team.automated_tagging)">
                    Turn {{ modelService.me.team.automated_tagging ? 'off' : 'on' }} automated tagging
                </button>
            </mat-menu>
        </ng-template>
        <ng-template #orderByMenuButton>
            <mat-icon [matMenuTriggerFor]="orderByMenu" track-id="sort-tag" fontSet="salesflare" fontIcon="sf-icon-sort" class="icon-24 toolbar-action">
            </mat-icon>
            <mat-menu #orderByMenu="matMenu">
                <button
                        *ngFor="let item of sortOptions | keyvalue" mat-menu-item (click)="selectSortOption(item.key)"
                        class="order-menu-item" [ngClass]="{'active': selectedSortOption() === item.value['sort_string'] }"
                >
                    {{ item.value.name }}
                </button>
            </mat-menu>
        </ng-template>
        <div [hidden]="!ShowDefault" class="toolbar-container" data-layout="row" data-layout-align="space-between center">

            <div class="toolbar-title" data-flex="grow" (click)="toggleSearch()"
                 data-layout="row" data-layout-align="space-between center">
                <span>
                    Manage tags
                </span>
            </div>
            <span class="toolbar-controls">
                <mat-icon (click)="toggleSearch()" track-id="search-tag" fontSet="salesflare" fontIcon="sf-icon-search" class="icon-24 toolbar-action">
                </mat-icon>
                <mat-icon track-id="filter" fontSet="salesflare" fontIcon="sf-icon-filter" class="icon-24 toolbar-action" (click)="drawer.open()">
                    <span *ngIf="isTagFilterApplied()" class="filter-applied-indicator"></span>
                </mat-icon>
                <ng-container *ngTemplateOutlet="orderByMenuButton"></ng-container>
                <ng-container *ngTemplateOutlet="menuButton"></ng-container>
            </span>

        </div>

        <div [hidden]="!ShowSearch" class="toolbar-container" data-layout="row" data-layout-align="space-between center">
            <span class="toolbar-search-container" data-flex data-layout="row" data-layout-align="start center">
                <mat-icon track-id="search-tag" fontSet="salesflare" fontIcon="sf-icon-search" class="icon-24">
                </mat-icon>
                <input data-flex="grow" class="toolbar-searchbox" [(ngModel)]="search" (ngModelChange)="triggerSearchChanged()" type="text" #searchBox>
            </span>
            <span class="toolbar-controls">
                <mat-icon (click)="toggleSearch()" track-id="close-search" fontSet="salesflare" fontIcon="sf-icon-close" class="icon-24 toolbar-action">
                </mat-icon>
                <ng-container *ngTemplateOutlet="menuButton"></ng-container>
            </span>

        </div>

        <div [hidden]="!ShowBulkSelect" class="toolbar-container bulk-toolbar-container" data-layout="row" data-layout-align="space-between center">

            <mat-checkbox class="bulk-toolbar-checkbox" [(ngModel)]="bulkSelectState.selectedAll" (change)="ToggleSelectAll($event)" color="primary" disableRipple>
                Select all
            </mat-checkbox>
            <span class="bulk-select-controls toolbar-controls" data-layout="row" data-layout-gap="16px">
                <button mat-icon-button class="button-24" (click)="MergeSelectedTags()" [disabled]="MergeButtonDisabled"
                        disableRipple
                        [matTooltip]="MergeButtonTooltip"
                >
                    <mat-icon track-id="tag-bulk-merge" fontSet="salesflare" fontIcon="sf-icon-merge" class="icon-24 toolbar-action">
                    </mat-icon>
                </button>
                <button mat-icon-button class="button-24" (click)="BulkDeleteSelectedTags()"
                        disableRipple
                        matTooltip="Delete"
                >
                    <mat-icon track-id="tag-bulk-delete" fontSet="salesflare" fontIcon="sf-icon-delete" class="icon-24 toolbar-action">
                    </mat-icon>
                </button>
            </span>
        </div>

    </sfx-toolbar>

    <sfx-content cdkVirtualScrollingElement class="main-container">

        <div class="tag-count" *ngIf="tagCount">
            <ng-container *ngIf="bulkSelectState.active">
                <ng-container *ngIf="filteredTagCount !== 0 && filteredTagCount !== null && bulkSelectState.selectedAll">
                    {{ filteredTagCount }} of {{ filteredTagCount }} tags selected
                </ng-container>
                <ng-container *ngIf="filteredTagCount !== 0 && filteredTagCount !== null && !bulkSelectState.selectedAll">
                    {{ bulkSelectState.selected.length }} of {{ filteredTagCount }} tags selected
                </ng-container>
                <ng-container *ngIf="filteredTagCount === null && bulkSelectState.selectedAll">
                    {{ tagCount }} of {{ tagCount }} tags selected
                </ng-container>
                <ng-container *ngIf="filteredTagCount === null && !bulkSelectState.selectedAll">
                    {{ bulkSelectState.selected.length }} of {{ tagCount }} tags selected
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!bulkSelectState.active && filteredTagCount !== 0">
                <ng-container *ngIf="filteredTagCount !== null">
                    {{ filteredTagCount }} of
                </ng-container>
                {{ tagCount }} tags
                <ng-container *ngIf="selectedSortOption()">
                    by {{ selectedSortOption() }}
                </ng-container>
            </ng-container>
        </div>
        <sfx-section disableContentPadding>

            <ng-container *ngIf="doneLoading && !loading">

                <!-- tagCount is the total amount of tags. Has specific empty state. -->
                <ng-container *ngIf="tagCount === 0">
                    <sfx-empty-state image="images/tags_empty.png">
                        <h1 class="accent">Add or import tags to see them appear here.</h1>
                    </sfx-empty-state>
                </ng-container>

                <!-- If it's not zero, handle other special cases. -->
                <ng-container *ngIf="tagCount !== 0">

                    <!-- Zero appears when a search happened, but no results were found. -->
                    <ng-container *ngIf="filteredTagCount === 0">
                        <sfx-empty-state image="images/search.png">
                            <ng-container *ngIf="searchActive && !filtersBad">
                                <h1 class="accent">
                                    No tags found
                                </h1>
                            </ng-container>
                            <ng-container *ngIf="!searchActive && !filtersBad">
                                <h1 class="accent">
                                    No tags match <a class="click" (click)="drawer.open()">this filter</a>
                                </h1>
                            </ng-container>
                        </sfx-empty-state>
                    </ng-container>

                    <!-- Null-state happens when a search errored, or hasn't happened at all. -->
                    <ng-container *ngIf="filteredTagCount === null">
                        <sfx-empty-state *ngIf="!searchActive && filtersBad" image="images/search.png">
                            <h1 class="accent"> {{ filterErrorMessage }} </h1>
                            <h1 class="accent">Please update <a (click)="drawer.open()">the filters</a> to continue.</h1>
                        </sfx-empty-state>
                    </ng-container>

                </ng-container>

            </ng-container>

            <cdk-virtual-scroll-viewport [itemSize]="rowSize">
                <mat-list class="list">
                    <div class="list-item-container" *cdkVirtualFor="let tag of tags; let isFirst = first">
                        <mat-divider *ngIf="!isFirst"></mat-divider>

                        <span class="list-item-content" data-layout="row" data-layout-align="center center">
                            <mat-list-item
                                    class="list-item" [ngClass]="
                                    {'mobile-list-item': !media.isActive('gt-sm'),
                                     'desktop-list-item': media.isActive('gt-sm'),
                                     'active-list-item': checkbox.checked
                                    }"
                            >
                                <!--
                                If you want to make ellipsis work: put on the container AND the element to ellipse itself.
                                -->
                                <span data-layout="row" data-layout-gap="8px" data-flex class="list-item-content-container ellipsis">
                                    <mat-checkbox [(ngModel)]="tag.selected" (change)="ToggleTag(tag)" class="bulk-select-checkbox" data-flex="none" data-layout="row" data-flex-align="start center" disableRipple #checkbox>

                                    </mat-checkbox>
                                    <div class="ellipsis tag-name-container" [ngClass]="{ 'tag-name-container-mobile': !media.isActive('gt-sm') }">
                                        <span class="ellipsis">
                                            {{ tag.name }}
                                        </span>
                                    </div>
                                    <div data-flex="40" data-layout-align="start center" class="ellipsis tag-usage-container">
                                        <span class="ellipsis tag-usage-tooltip-container" [matTooltip]="getUsageTooltip(tag)" matTooltipClass="tag-usage-tooltip">
                                            Used {{ tag.total_count }} times
                                        </span>
                                    </div>
                                    <span *ngIf="media.isActive('gt-sm')" data-flex="none" data-layout="row" data-layout-gap="8px">
                                        <mat-icon track-id="update-tag" fontSet="salesflare" fontIcon="sf-icon-edit" class="icon-20 icon-tag-action icon-tag-edit"
                                                  (click)="editTag(tag)"
                                        >
                                        </mat-icon>
                                        <mat-icon track-id="delete-tag" fontSet="salesflare" fontIcon="sf-icon-delete" class="icon-20 icon-tag-action icon-tag-remove"
                                                  (click)="deleteTag(tag)"
                                        >
                                        </mat-icon>
                                    </span>
                                    <span *ngIf="!media.isActive('gt-sm')" data-flex="shrink" data-layout-align="end center">
                                        <mat-icon [matMenuTriggerFor]="mobileListMenu" track-id="mobile-menu-tag" fontSet="salesflare" fontIcon="sf-icon-menu-small-vertical" class="icon-20 icon-tag-action icon-tag-menu">
                                        </mat-icon>
                                        <mat-menu #mobileListMenu="matMenu">
                                            <button mat-menu-item (click)="editTag(tag)">
                                                Edit
                                            </button>
                                            <button mat-menu-item (click)="deleteTag(tag)">
                                                Delete
                                            </button>
                                        </mat-menu>
                                    </span>
                                </span>
                            </mat-list-item>
                        </span>
                        <button class="list-item-button" #button mat-button disableRipple (click)="checkbox.toggle(); ToggleTag(tag)">

                        </button>
                    </div>

                </mat-list>

            </cdk-virtual-scroll-viewport>
        </sfx-section>
    </sfx-content>
</mat-drawer-container>

<sfx-speed-dial class="fab-dial" (click)="createTag()">
</sfx-speed-dial>

