import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@shared/bundles/material/material.module';

import { SpeedDialComponent } from './speed-dial.component';
import { SpeedDialActionDirective } from '@shared/components/speed-dial/speed-dial-action/speed-dial-action.directive';

import { EcoFabSpeedDialComponent, EcoFabSpeedDialTriggerComponent, EcoFabSpeedDialActionsComponent } from '@ecodev/fab-speed-dial';

@NgModule({
    declarations: [
        SpeedDialComponent,
        SpeedDialActionDirective
    ],
    exports: [
        SpeedDialComponent,
        SpeedDialActionDirective
    ],
    imports: [
        CommonModule,
        EcoFabSpeedDialComponent,
        EcoFabSpeedDialTriggerComponent,
        EcoFabSpeedDialActionsComponent,
        MaterialModule
    ]
})
export class SpeedDialModule {}
