(function () {
    'use strict';

    angular
        .module('salesflare')
        .factory('syncprogress', syncProgressFactory);

    function syncProgressFactory($interval, $state, me, model, statusBarService) {

        let stop;
        // Note that on the status top bar there can be text behind these
        const text = {
            cache: 'Step 1 of 3 : Searching contacts and emails',
            initial: 'Step 1 of 3 : Searching contacts and emails',
            sync: 'Step 2 of 3 : Creating contacts and account suggestions',
            enrich: 'Step 3 of 3 : Enriching contacts with public data and email signatures',
            error: 'The email sync did not complete successfully. We\'re on it! You can already start working.',
            done: 'Salesflare has finished syncing.'
        };

        return {
            startPolling,
            stopPolling,
            text
        };

        function stopPolling() {

            if (stop) {
                $interval.cancel(stop);
                stop = undefined;
            }
        }

        function startPolling() {

            if (!stop) {
                doPoll();
                stop = $interval(doPoll, 10 * 1000);
            }
        }

        function doPoll() {

            return me.get().then(function (response) {

                if (!model.me) {
                    return stopPolling();
                }

                model.me.sync_status = response.data.sync_status;
                model.me.has_received_account_suggestions = response.data.has_received_account_suggestions;
                model.me.flags = response.data.flags;

                const suggestedAccountsEnabled = model.me.flags?.some((flag) => flag.state === true && flag.name === 'suggestedAccounts');

                if (model.me.sync_status === 'done') {
                    stopPolling();

                    let statusBarOptions = {
                        type: 'onboarding',
                        showSpinner: false,
                        canClose: true,
                        class: 'success',
                        text: text[model.me.sync_status]
                    };

                    if (suggestedAccountsEnabled) {
                        if (model.me.has_received_account_suggestions) {

                            statusBarOptions = {
                                ...statusBarOptions,
                                linkText: 'Check out your account suggestions!',
                                linkTrackId: 'statusBar-account-suggestions',
                                onLinkClick: function () {

                                    statusBarService.hide('onboarding');
                                    $state.go('accountsuggestions');
                                }
                            };
                        }
                        else {

                            statusBarOptions = {
                                ...statusBarOptions,
                                text: 'Salesflare didn\'t find many emails.',
                                linkText: 'Try connecting another inbox.',
                                linkTrackId: 'statusBar-account-suggestions',
                                onLinkClick: function () {

                                    statusBarService.hide('onboarding');
                                    $state.go('settings.emailSettings');
                                }
                            };
                        }
                    }
                    else {

                        statusBarOptions = {
                            ...statusBarOptions,
                            linkText: 'Check out your contacts!',
                            linkTrackId: 'statusBar-my-contacts',
                            onLinkClick: function () {

                                statusBarService.hide('onboarding');
                                $state.go('contacts.my');
                            }
                        };
                    }

                    return statusBarService.show(statusBarOptions);
                }
                else if (model.me.sync_status === 'error') {
                    const statusBar = {
                        type: 'onboarding',
                        text: text[model.me.sync_status],
                        showSpinner: false,
                        canClose: true,
                        onClose: stopPolling,
                        class: 'error'
                    };

                    return statusBarService.show(statusBar);
                }

                if (text[model.me.sync_status]) {
                    return statusBarService.show({ type: 'onboarding', text: text[model.me.sync_status] });
                }

                // Not a valid sync_status (actual error or just not a valid one to show in the bar)
                statusBarService.hide('onboarding');
                return stopPolling();
            });
        }
    }
})();
