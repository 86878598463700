(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ConferenceController', ConferenceController);

    function ConferenceController($scope, $stateParams, $document, $element, $window, model, meeting, conferenceService, joditNotesService, $interval, $timeout, authentication) {

        $scope.id = $stateParams.id;
        $scope.me = model.me;
        $scope.isLoggedIn = model.isLoggedIn;
        $scope.meeting = null;
        $scope.participant_list = null;
        $scope.requestingEditMode = false;
        let jodit = null;

        $window.intercomSettings = {
            hide_default_launcher: true
        };

        authentication.fetchMe({ preventLogoutOnUnsuccessfulAuth: true }).finally(function () {

            $scope.me = model.me;
            $scope.isLoggedIn = model.isLoggedIn;
            return init();
        });

        function init() {

            get();
            startConference($scope.id);
            return initEditor();
        }

        function get() {

            return conferenceService.get($scope.id, { ignoreLoadingBar: true }).then(function (conferenceResponse) {

                if (model.isLoggedIn && conferenceResponse.data.team === model.me.team.id) {
                    meeting.get(conferenceResponse.data.id, { ignoreLoadingBar: true }).then(function (meetingResponse) {

                        const meetingResponseData = meetingResponse.data;

                        setMeeting(meetingResponseData);
                    });
                }
                else {
                    setMeeting(conferenceResponse.data);
                }
            });
        }

        function setMeeting(meetingObj) {

            // Delete deprecated minutes property returned on /GET meetings for older client support
            delete meetingObj.minutes;

            if (jodit && (!$scope.meeting || $scope.meeting.notes !== meetingObj.notes) && meetingObj.edited_by !== model.me.id) {
                jodit.setEditorValue(meetingObj.notes || '');
            }

            $scope.participant_list = Array.prototype.map.call(meetingObj.participants, function (participant) {
                return participant.name;
            }).join(', ');
            $scope.participant_list = $scope.participant_list.replace(/,([^,]*)$/, ' and$1');

            $scope.api.executeCommand('subject', meetingObj.subject);

            if ($scope.meeting && $scope.meeting.edited_by !== meetingObj.edited_by) {
                if (meetingObj.edited_by === model.me.id) {
                    jodit.setReadOnly(false);
                }
                else {
                    jodit.setReadOnly(true);
                }
            }

            $scope.meeting = meetingObj;
        }

        $scope.startEdit = function () {

            $scope.requestingEditMode = true;
            const meetingEditedBy = angular.copy($scope.meeting);
            // Don't update notes because someone else might be editing it
            delete meetingEditedBy.notes;
            meetingEditedBy.edited_by = model.me.id;
            update(meetingEditedBy);
            $timeout(function () {

                $scope.meeting.edited_by = model.me.id;
                jodit.setReadOnly(false);
                $scope.requestingEditMode = false;
            }, 5000);
        };

        $scope.stopEdit = function () {

            $scope.meeting.edited_by = null;
            jodit.setReadOnly(true);
            update($scope.meeting, true);
        };

        $window.addEventListener('beforeunload', function () {

            $scope.meeting.edited_by = null;
            if ($scope.meeting.edited_by === model.me.id) {
                update($scope.meeting);
            }
        });

        $interval(function () {

            if ($scope.meeting.edited_by === model.me.id) {
                const meetingWithoutEditedBy = angular.copy($scope.meeting);
                delete meetingWithoutEditedBy.edited_by;
                update(meetingWithoutEditedBy, true);
            }
            else {
                get();
            }
        }, 5000);

        function update(meetingToUpdate, refresh) {

            let afterUpdateFunction = angular.noop;
            if (refresh) {
                afterUpdateFunction = get;
            }

            if (model.isLoggedIn && meeting) {
                meeting.update(meetingToUpdate).then(afterUpdateFunction).catch(afterUpdateFunction);
            }
        }

        function startConference(roomName) {

            let userInfo = null;

            if (model.isLoggedIn) {
                userInfo = {
                    displayName: model.me.name,
                    email: model.me.email
                };
            }

            const domain = 'meet.jit.si';
            const options = {
                roomName,
                userInfo,
                width: '100%',
                height: '100%',
                parentNode: $document[0].querySelector('#jitsi-container'),
                configOverwrite: {
                    localRecording: {
                        enabled: true,
                        format: 'wav'
                    },
                    prejoinPageEnabled: false
                },
                interfaceConfigOverwrite: {
                    DEFAULT_REMOTE_DISPLAY_NAME: 'Guest',
                    MOBILE_APP_PROMO: false,
                    VIDEO_QUALITY_LABEL_DISABLED: true,
                    RECENT_LIST_ENABLED: false,
                    DISABLE_PRESENCE_STATUS: true,
                    SHOW_CHROME_EXTENSION_BANNER: false,
                    HIDE_KICK_BUTTON_FOR_GUESTS: true,
                    AUTHENTICATION_ENABLE: false,
                    CONNECTION_INDICATOR_DISABLED: true,
                    SHOW_DEEP_LINKING_IMAGE: false,
                    SHOW_POWERED_BY: false,
                    SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                    SHOW_JITSI_WATERMARK: false,
                    SHOW_WATERMARK_FOR_GUESTS: false,
                    TOOLBAR_BUTTONS: [
                        'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
                        'fodeviceselection', 'localrecording', 'profile', 'chat', 'etherpad', 'settings', 'raisehand',
                        'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
                        'tileview', 'download', 'help', 'mute-everyone', 'hangup'
                    ],
                    SETTINGS_SECTIONS: ['devices', 'language', 'moderator', 'profile']
                }
            };
            if ($scope.api) {
                $scope.api.dispose();
            }

            $scope.api = new JitsiMeetExternalAPI(domain, options);
            $scope.api.addEventListener('readyToClose', close);
        }

        function close() {

            $document[0].querySelector('#jitsi-container').innerHTML = '<h1 class="sf-hangup accent">Thanks for using Salesflare Meet</h1>';
        }

        function initEditor() {

            jodit = joditNotesService.getJoditEditor(getJoditInitializationObject(),
            // On change sync body text with our model
                function (bodyText) {

                    if ($scope.meeting) {

                        $scope.meeting.notes = bodyText;
                    }
                }
            );
            if ($scope.meeting) {
                jodit.setEditorValue($scope.meeting.notes || '');
            }

            function getJoditInitializationObject() {

                return {
                    autofocus: true,
                    readonly: true,
                    queryStringToBindTo: $element.find('.editor')[0],
                    placeholder: 'Write your meeting notes to save them on the meeting in Salesflare.',
                    previewButton: {
                        enabled: false
                    },
                    mergeFieldButton: {
                        enabled: false
                    },
                    attachmentsButton: {
                        enabled: false
                    },
                    templatesButton: {
                        enabled: false
                    },
                    sendTestButton: {
                        enabled: false
                    }
                };
            }

        }
    }
})();
