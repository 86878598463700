import Dexie, { Table } from 'dexie';

export interface Draft {
    to: { name: string, email: string };
    cc?: Array<{ name: string, email: string }>;
    bcc?: Array<{ name: string, email: string }>;
    subject?: string;
    body?: string;
    attachments?: Array<{}>;
    tracked?: boolean;
    timestamp?: Date;
}

export class SFIndexedDB extends Dexie {
    drafts!: Table<Draft, string>;

    constructor() {
        super('salesflare');
        this.version(1).stores({
            drafts: 'to.email, timestamp'
        });
    }
}

export const sfIndexedDB = new SFIndexedDB();
