(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('statusBarService', statusBarService);

    /**
     * This service is the brains behind the status bar component
     * All logic except some display stuff is in here
     * This service and the component communicate through events broadcasted on $rootScope
     *
     * @param {Object} $state
     * @param {Object} $rootScope
     * @param {Object} $timeout
     * @param {Object} $mdMedia
     * @param {Object} model
     * @param {Object} sfSetupPanel
     * @param {Object} sfUpgradeDialog
     * @param {Object} flagsService
     */
    function statusBarService($state, $rootScope, $timeout, $mdMedia, model, sfSetupPanel, sfUpgradeDialog, flagsService) {

        const self = this;
        const queue = [];
        const maxAmountOfDaysToUnlock = 30;
        let isReady = false;
        let forceHide = false;
        let barsShowing = {};
        let currentBar = {};

        let maxAmountOfDaysUnlocked = false;

        // Higher order means higher priority, means 3 will be shown before 1
        const barTypesMap = {
            trial: {
                type: 'trial',
                showSpinner: false,
                canClose: true,
                order: 1,
                linkText: 'Unlock more days', // This can be changed on the sfStatusBar:ready event, if the max amount of days has been unlocked
                linkTrackId: 'statusBar-earnDays',
                onLinkClick: function () {

                    if (flagsService.get('gamifiedTrial') === false || maxAmountOfDaysUnlocked) {
                        return $state.go('checkout');
                    }

                    if ($mdMedia('gt-sm')) {
                        return sfSetupPanel.show();
                    }

                    return $state.go('setup');
                },
                onClose: function () {

                    return store.set('statusbar_trial_last_closed', new Date());
                }
            },
            pro_discount: {
                type: 'pro_discount',
                showSpinner: false,
                canClose: true,
                order: 2,
                linkText: 'Read more',
                onLinkClick: function () {

                    return sfUpgradeDialog.show('multiStepWorkflows');
                },
                onClose: function () {

                    return store.set('last_promo_statusbar_close_date', new Date());
                }
            },
            onboarding: {
                type: 'onboarding',
                showSpinner: true,
                canClose: false,
                order: 3
            },
            workflow_failed: {
                type: 'workflow_failed',
                showSpinner: false,
                canClose: true,
                order: 4,
                linkText: 'Go to workflows',
                linkTrackId: 'statusBar-fixWorkflows',
                class: 'warning',
                onLinkClick: () => {

                    return $state.go('workflows');
                }
            },
            email: {
                type: 'email',
                showSpinner: false,
                canClose: false,
                order: 5,
                linkText: 'reconnect your emails.',
                linkTrackId: 'statusBar-reconnect',
                onLinkClick: function () {

                    return $state.go('settings.emailSettings');
                }
            },
            calendar: {
                type: 'calendar',
                showSpinner: true,
                canClose: false,
                order: 6
            },
            import: {
                type: 'import',
                showSpinner: false,
                canClose: true,
                order: 7
            },
            password: {
                type: 'password',
                showSpinner: false,
                canClose: true,
                order: 8,
                linkText: 'please set a password.',
                linkTrackId: 'statusBar-password',
                onLinkClick: function () {

                    return $state.go('settings.resetPassword');
                }
            },
            billing: {
                type: 'billing',
                showSpinner: false,
                canClose: false,
                order: 9
            },
            sca: {
                type: 'sca',
                showSpinner: false,
                canClose: false,
                order: 10,
                text: 'Your last payment needs bank authentication.',
                class: 'warning',
                linkText: 'Authenticate',
                linkTrackId: 'sca-auth',
                onLinkClick: function () {

                    return $state.go('settings.billingSettings.overview');
                }
            },
            email_connect_nudge: {
                type: 'email_connect_nudge',
                showSpinner: false,
                canClose: true,
                order: 11,
                linkText: 'Connect',
                linkTrackId: 'statusBar-emailNudge',
                onLinkClick: function () {

                    return $state.go('settings.emailSettings');
                },
                onClose: function () {

                    return store.set('statusbar_email_nudge_last_closed', new Date());
                }
            },
            sent_researcher_invite: {
                type: 'sent_researcher_invite',
                showSpinner: false,
                canClose: true,
                order: 12,
                text: 'We have sent you an email with a link to create your account.'
            }
        };

        // eslint-disable-next-line angular/on-watch
        $rootScope.$on('sfStatusBar:ready', function () {

            isReady = true;

            // When the component re initializes for some reason we restore the state
            // This happens when we show a bar and then the walkthrough starts and ends
            // The walkthrough does a reload causing the state of the component to be lost
            if (queue.length === 0 && Object.keys(currentBar).length > 0) {
                return set(currentBar);
            }

            if (model && model.me) {
                maxAmountOfDaysUnlocked = model.me.amount_of_trial_days_earned >= maxAmountOfDaysToUnlock;
                if (maxAmountOfDaysUnlocked) {
                    barTypesMap.trial.linkText = 'Subscribe';
                }
            }

            while (queue.length > 0) {
                const update = queue.shift();
                reset(); // Call reset to make sure we don't inherit stuff from the previous bar, the hide function normally takes care of this
                set(update, true);
            }
        });

        /**
         * The bar will also always have a class `sf-status-bar-{type}`
         *
         * @param {Object} options
         * @param {String} options.type type of the bar (see typesMap)
         * @param {String} options.text text to show
         * @param {Boolean} options.showSpinner show spinner
         * @param {Boolean} options.canClose show close icon
         * @param {String} options.class extra classes you want to add to the bar supports: `error` or `success`
         * @returns {void}
         */
        this.show = function (options) {

            if (!options) {
                throw new Error('Options are required');
            }

            if (!options.type) {
                throw new Error('A type is required');
            }

            if (!barTypesMap[options.type]) {
                throw new Error('Type ' + options.type + ' is not supported, either you made a small typo or you need to add your new type to the barTypesMap');
            }

            if (!options.text && !barTypesMap[options.type].text) {
                throw new Error('Text is required');
            }

            if (options.class && (options.class !== 'error' && options.class !== 'success' && options.class !== 'warning')) {
                throw new Error('Only `error` and `success` are supported as custom classes atm');
            }

            barsShowing[options.type] = angular.merge({}, barTypesMap[options.type], options);

            if (!currentBar.text) {
                return set(barsShowing[options.type]);
            }

            if (options.type === currentBar.type && options.text !== currentBar.text && options.showSpinner !== currentBar.showSpinner) {
                return set(barsShowing[options.type]);
            }

            if (barTypesMap[currentBar.type].order < barTypesMap[options.type].order) {
                return set(barsShowing[options.type]);
            }
        };


        /**
         * We reset before doing a set to make sure we don't inherit stuff from the previous bar
         *
         * @param {String} type
         * @returns {undefined}
         */
        this.hide = function (type) {

            delete barsShowing[type];

            if (currentBar.type === type) {
                reset();
                set({ hide: true });

                // Decide what bar needs to be shown next
                let next;
                for (const typeKey in barsShowing) {
                    if (!next || barTypesMap[next.type].order < barTypesMap[typeKey].order) {
                        next = barsShowing[typeKey];
                    }
                }

                if (!next) {
                    return;
                }

                // This timeout gives the bar time to animate itself away
                return $timeout(function () {

                    reset();
                    return set(next);
                }, 1000);
            }
        };

        /**
         * Use this when logging out as it resets the ready flag
         */
        this.clear = function () {

            barsShowing = {};

            reset();
            set({ hide: true });

            isReady = false;
        };

        /**
         * @param {Boolean} canBeVisible
         * @returns {void}
         */
        this.setCanBeVisible = function (canBeVisible) {

            forceHide = !canBeVisible;

            return set({ hide: forceHide });
        };

        ////////////////////////

        /**
         * This does incremental changes. e.g. if you already have `{ hide: true`} and call `set({ text: 'John Doe' })` it will result in `{ hide: false, text: 'John Doe' }`.
         * If you want to set a new bar call `reset` first
         *
         * @param {Object} update
         * @param {Boolean} update.hide
         * @param {String} update.text
         * @param {String} update.linkText
         * @param {String} update.linkTrackId
         * @param {Boolean} update.canClose
         * @param {Boolean} update.showSpinner
         * @param {'error'|'warning'|'success'} update.class
         * @param {Function} update.onLinkClick
         * @param {Function} update.onClose
         * @param {Boolean} [handlingQueue=false]
         * @returns {void}
         */
        function set(update, handlingQueue) {

            if (update.canClose || update.onClose) {
                update.onClose = onCloseWrapper(update.onClose);
            }

            // If we are setting a whole new type, reset first so no props linger from the previous type
            // Fix for https://github.com/Salesflare/Server/issues/7053
            if (!!update.type && !!currentBar.type && update.type !== currentBar.type) {
                reset();
            }

            angular.merge(currentBar, update);

            // Delete unneeded properties
            delete update.order;

            // We use a queue here so we can queue up updates before the component is ready
            // This allows us to call `set` without having to think about doing setTimeouts
            if (!isReady) {
                return queue.push(update);
            }

            // When we call set when ready and still handling the queue we push to the end of the queue
            if (queue.length > 0 && !handlingQueue) {
                return queue.push(update);
            }

            update.hide = forceHide || update.hide || false;

            return $rootScope.$broadcast('sfStatusBar:set', update);
        }

        function reset() {

            currentBar = {};

            return $rootScope.$broadcast('sfStatusBar:reset');
        }

        function onCloseWrapper(onClose) {

            return function () {

                self.hide(currentBar.type);
                return onClose && onClose();
            };
        }
    }
})();
