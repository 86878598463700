(function () {
    'use strict';

    angular
        .module('salesflare.components.addresses', [])
        .component('sfAddresses', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/info/addresses.html',
            bindings: {
                addresses: '<',
                entityName: '<'
            }
        });

    function controller($window) {

        const vm = this;

        vm.openInGoogleMaps = function (street, city, country, $event) {

            $event.stopPropagation();

            $window.open('https://www.google.com/maps/place/' + street + '+' + city + '+' + country, '_blank', 'noopener');
        };
    }
}());
