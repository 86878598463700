(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('AccountsController', AccountsController);

    function AccountsController($scope, $state, $stateParams, $mdMedia, $mdMenu, $mdDialog, $mdSidenav, $timeout, $interval, $document, $exceptionHandler, $filter, events, accounts, account, companies, company, flagsService, utils, sfWalkthrough, bulkService, filterService, model, sortService) {

        let thread = null;
        let newSuggestedAccountsPollingInterval;

        const entityCountObject = {
            viewTotalCount: 0,
            viewCurrentCount: 0,
            selectedCount: 0,
            canEditCount: 0,
            option: '',
            entity: 'accounts',
            entity_singular: 'account',
            string: ''
        };

        // The amount of accounts that are allowed to be merged in one merge
        const mergeAmount = 2;

        $scope.isAdmin = model.me.is_admin;
        $scope.me = model.me;
        $scope.allSelected = false;
        $scope.isAccountFilterApplied = filterService.isAccountFilterApplied;
        $scope.searchObject = {
            search: $stateParams.search,
            getSearch: function () {

                return this.search;
            },
            setSearch: function (s) {

                this.search = s;

                return utils.setSearchInUrl({ search: this.search });
            }
        };
        $scope.searchMode = !!$stateParams.search;
        $scope.selected = {};
        $scope.isLoading = true;
        $scope.hideElement = sfWalkthrough.hideElement;
        $scope.isWalkthroughShowing = sfWalkthrough.isShowing;
        $scope.isSuggestedAccountsEnabled = (flagsService.get('suggestedAccounts') === true);
        $scope.filters = filterService.getFilter('account', { raw: true });
        $scope.usedBadFilter = false;
        $scope.$mdMenu = $mdMenu;
        $scope.sortOptions = sortService.getSortOptions('account');
        // The virtual repeat will still fire a filter get after suggested accounts are loaded
        // loadedSuggestions is used to prevent loading the suggested accounts twice
        $scope.loadedSuggestions = false;
        // PendingFetches keeps track of ongoing filterGet calls for the virtual repeat
        // When scrolling fast "didNotLoadUntilWantedLimit" can be true, but a filterGet is still ongoing
        $scope.pendingFetches = 0;

        $scope.badFilterError = null;

        if ($scope.me.role.permissions.ced !== 'NONE' && $scope.isSuggestedAccountsEnabled) {
            setAmountOfNotViewedSuggestedAccounts();

            // Poll every 5 minutes for new suggestions
            newSuggestedAccountsPollingInterval = $interval(setAmountOfNotViewedSuggestedAccounts, 5 * 60 * 1000);
        }

        $scope.$on('$destroy', () => {

            return $interval.cancel(newSuggestedAccountsPollingInterval);
        });

        $scope.getDefaultFilters = function () {

            return filterService.getDefaultFilters('account', model.me);
        };

        $scope.onFilterApply = function ($event) {

            filterService.setFilter('account', $event.rules);

            if ($scope.badFilterError) {
                return;
            }

            return $scope.accounts.reload();
        };

        $scope.$on(events.refreshData, function () {

            return $scope.get();
        });

        $scope.$on(events.account.loaded, function ($event, loadedAccount) {

            const found = $scope.accounts.items.find(function (acc) {

                if (acc.id === loadedAccount.id && (acc.last_interaction.date !== loadedAccount.last_interaction.date || acc.name !== loadedAccount.name)) {
                    return true;
                }

                return false;
            });

            // If last interaction date/account name changed, move to the right place in the array
            if (found) {
                // Easy remove without creating a position in the array that is undefined
                $scope.accounts.items = $scope.accounts.items.filter(function (acc) {

                    return acc.id !== loadedAccount.id;
                });

                // Look for the account with a larger or equal last interaction date and place it before that
                const placeBeforeIndex = $scope.accounts.items.findIndex(function (acc) {

                    if (!acc.last_interaction || !loadedAccount.last_interaction) {
                        return false;
                    }

                    if (new Date(acc.last_interaction.date) <= new Date(loadedAccount.last_interaction.date)) {
                        return true;
                    }

                    return false;
                });

                $scope.accounts.items.splice(placeBeforeIndex, 0, loadedAccount);
            }
        });

        $scope.goToAccountSuggestions = function () {

            return $state.go('accountsuggestions');
        };

        function setAmountOfNotViewedSuggestedAccounts() {

            return accounts.getAmountOfNotViewedSuggestedAccounts().then((response) => {

                $scope.notViewedAccountSuggestions = response.data;
            });
        }

        //// bulk stuff
        $scope.bulkQuickActions = [
            {
                icon: 'sf-icon-delete',
                handler: function () {

                    const confirm = $mdDialog.confirm()
                        .clickOutsideToClose(true)
                        .escapeToClose(true)
                        .title('Warning!')
                        .htmlContent('<p>You\'re about to delete ' + entityCountObject.selectedCount + (entityCountObject.selectedCount > 1 ? ' accounts' : ' account') + '.<br />Are you sure you want to do this?</p>')
                        .ok('I\'m sure')
                        .cancel('cancel');

                    return $mdDialog.show(confirm).then(function () {

                        return bulkService.deleteAccounts(getBulkOptions())
                            .then(function () {

                                return $state.go('accounts', $stateParams, { reload: true });
                            })
                            .catch(function (err) {

                                $exceptionHandler(err);

                                return utils.showErrorToast();
                            });
                    });
                },
                config: {
                    toolTip: {
                        content: function () {
                            return 'Delete';
                        }
                    }
                }
            },
            {
                icon: 'sf-icon-edit',
                handler: function () {

                    return $state.go('bulkEditAccount', { options: getBulkOptions() });
                },
                config: {
                    toolTip: {
                        content: function () {
                            return 'Edit';
                        }
                    }
                }
            },
            {
                icon: 'sf-icon-merge',
                handler: function () {

                    const bulkOptions = getBulkOptions();

                    // Get accounts for the merge
                    return accounts.filterGet({
                        search: bulkOptions.search || null,
                        filterRules: bulkOptions.search ? [] : bulkOptions.rules,
                        limit: 10,
                        offset: 0
                    })
                        .then(function (response) {

                            const accountsToMerge = response && response.data;
                            if (!angular.isArray(accountsToMerge) || accountsToMerge.length !== mergeAmount) {
                                // This shouldn't be possible, don't merge!
                                return;
                            }

                            // Open dialog

                            $mdDialog.show({
                                clickOutsideToClose: true,
                                escapeToClose: true,
                                controller: 'MergeAccountsDialogController',
                                templateUrl: '/app-ajs/components/mergeAccounts/mergeaccountsdialog.html',
                                locals: {
                                    accountsToMerge
                                },
                                bindToController: true,
                                controllerAs: 'vm'
                            }).then(function (dialogResponse) {

                                const data = dialogResponse.data;
                                const err = dialogResponse.err;
                                const primaryAccount = dialogResponse.primaryAccount;
                                if (data) {
                                    utils.showSuccessToast('Accounts merged.');
                                }
                                else if (err) {
                                    utils.showErrorToast('Merging accounts failed.');
                                }

                                // Reload and go to the primary account
                                return $state.go('accounts.account.feed', { id: primaryAccount }, { reload: true });
                            }).catch(function (err) {

                                if (err) {
                                    utils.showErrorToast('Merging accounts failed.');
                                }
                            });
                        });

                },
                config: {
                    isDisabled: function () {

                        return entityCountObject.selectedCount !== mergeAmount;
                    },
                    toolTip: {
                        content: function () {

                            return (entityCountObject.selectedCount === mergeAmount) ? 'Merge accounts' : 'Select 2 accounts to merge them';
                        }
                    }
                }
            }
        ];

        $scope.bulkMenuItems = [
            {
                label: 'Add user to account',
                handler: function () {

                    return $state.go('bulkAddUsers', { options: getBulkOptions() });
                }
            },
            {
                label: 'Remove user from account',
                handler: function () {

                    return $state.go('bulkRemoveUsers', { options: getBulkOptions() });
                }
            }
        ];

        // Returns options object with either the ids selected or the filter when all selected or searching
        function getBulkOptions() {

            if ($scope.allSelected) {
                if ($scope.searchObject.getSearch()) {
                    return {
                        condition: 'AND',
                        rules: [],
                        search: $scope.searchObject.getSearch()
                    };
                }

                return {
                    condition: 'AND',
                    rules: filterService.getFilter('account')
                };
            }

            return {
                condition: 'AND',
                rules: [{
                    id: 'account.id',
                    operator: 'in',
                    value: $scope.accounts.items.filter(function (acc) {

                        return acc.selected;
                    }).map(function (acc) {

                        return acc.id;
                    })
                }]
            };
        }

        $scope.onSelectAll = function ($event) {

            $scope.allSelected = $event.allSelected;

            if ($scope.allSelected) {
                entityCountObject.selectedCount = entityCountObject.canEditCount;
                entityCountObject.string = utils.getEntityCountString(entityCountObject);
            }
            else {
                $scope.showBulkToolbar = false;
                entityCountObject.selectedCount = 0;
                entityCountObject.string = utils.getEntityCountString(entityCountObject);
            }

            return setSelectedOnAllAccounts();
        };

        $scope.checkShowBulkToolbar = function () {

            // Next tick so if a `selected` value needs to be updated it does that first before our check
            return $timeout(function () {

                $scope.showBulkToolbar = $scope.accounts.items.find(function (acc) {

                    return acc.selected && acc.can_edit;
                });

                $scope.allSelected = $scope.accounts.items.every(function (acc) {

                    // If the account does not have an owner it means that it's a suggested company
                    return acc.selected || !acc.owner || acc.can_edit === false;
                });

                if ($scope.showBulkToolbar) {
                    const selectedAccounts = $scope.accounts.items.filter(function (acc) {

                        return acc.selected;
                    });

                    if ($scope.allSelected) {
                        entityCountObject.selectedCount = entityCountObject.canEditCount;
                    }
                    else {
                        entityCountObject.selectedCount = selectedAccounts.length;
                    }

                    entityCountObject.string = utils.getEntityCountString(entityCountObject);
                }
                else {
                    entityCountObject.selectedCount = 0;
                    entityCountObject.string = utils.getEntityCountString(entityCountObject);
                }
            });
        };

        $scope.selectAccount = function (acc) {

            if (!acc.owner || acc.can_edit === false) {
                return;
            }

            acc.selected = true;

            return $scope.checkShowBulkToolbar();
        };
        //// end bulk stuff

        $scope.get = function () {

            return $scope.accounts.reload();
        };

        $scope.setFilters = function () {

            if (sfWalkthrough.isShowing()) {
                return;
            }

            return $mdSidenav('filters').toggle()
                .then(function () {

                    return $mdSidenav('filters', true).onClose(function () {

                        // Return $scope.accounts.reload();
                    });
                });
        };

        $scope.export = function (type) {

            const isCSV = type !== 'excel';
            const isExcel = type === 'excel';

            let filterRules;

            // Do not filter when searching
            if ($scope.searchObject.getSearch()) {
                filterRules = [];
            }
            else {
                filterRules = filterService.getFilter('account');
            }

            return accounts.filterGet({
                search: $scope.searchObject.getSearch(),
                filterRules,
                limit: 100000,
                offset: 0,
                orderBy: sortService.getCurrentSortOption('account').order_by,
                downloadCSV: isCSV,
                downloadExcel: isExcel
            });
        };

        $scope.openMenu = function (mdOpenMenu, event) {

            if (sfWalkthrough.isShowing()) {
                return;
            }

            return mdOpenMenu(event);
        };

        $scope.setSearchMode = function (searchMode) {

            if (sfWalkthrough.isShowing()) {
                return;
            }

            $scope.searchMode = searchMode;
        };

        // Virtual repeat + infinite scroll object that angular material uses
        $scope.accounts = {
            toLoad: 0,
            numLoaded: 0, // Total loaded
            partOfLoaded: 0, // Part of accounts loaded
            items: [],
            topIndex: 0,

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + 20;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad >= index) {
                    return;
                }

                this.toLoad += 20;
                $scope.usedBadFilter = false;

                if (this.items.length === 0) {
                    this.doneLoading = false;
                    filterGet({
                        initialCall: true,
                        partOf: true
                    });

                    // Do count calls after data calls as we want the data asap
                    // And only do this on initial load as the count doesn't change when loading more items
                    fetchCounts();
                }
                else {
                    filterGet({
                        initialCall: false,
                        // When scrolling we switch the part_of based on the last account in the list
                        // Which means that if the last account is one we're not part of, we continue to load accounts we're not part of
                        partOf: this.items[this.items.length - 1].part_of
                    });
                }
            },

            // Forces reset of object
            reload: function () {

                $scope.showBulkToolbar = false;
                $scope.allSelected = false;

                if ((this.items && this.items.length > 0) || !$scope.searchObject.getSearch()) {
                    $scope.isLoading = true;
                }

                this.toLoad = 0;
                this.numLoaded = 0;
                this.partOfLoaded = 0;
                this.items = [];
                this.topIndex = 0;
                this.doneLoading = false;
                $scope.loadedSuggestions = false;
                $scope.pendingFetches = 0;
            }
        };

        /**
         * @param {Object} options
         * @param {Boolean} [options.initialCall=false]
         * @param {Boolean} [options.partOf=true]
         * @returns {void}
         */
        function filterGet(options) {

            if (angular.isUndefined(options.partOf)) {
                options.partOf = true;
            }

            let filterRules;

            // Do not filter when searching
            if ($scope.searchObject.getSearch()) {
                filterRules = [];
            }
            else {
                filterRules = filterService.getFilter('account');
            }

            /**
             * We add our `(not) part of` rule in a sub filter
             * specifically so the api won't group it with other team_member rules which would results in the wrong query
             *
             * @see https://github.com/Salesflare/Server/issues/7390
             */
            filterRules.push({
                condition: 'AND',
                rules: [{
                    id: 'account.team_member.id',
                    operator: options.partOf ? 'in' : 'not_in',
                    value: [model.me.id]
                }]
            });

            const requestOptions = {
                search: $scope.searchObject.getSearch(),
                filterRules,
                orderBy: sortService.getCurrentSortOption('account').order_by
            };

            if (options.initialCall) {
                requestOptions.limit = $scope.accounts.toLoad;
            }
            else {
                requestOptions.limit = 20;
                requestOptions.offset = $scope.accounts.numLoaded;
            }

            if (!options.partOf) {
                // When we are setting an offset and loading not part of, we need to adjust for the already loaded part of accounts loaded
                if (requestOptions.offset) {
                    requestOptions.offset -= $scope.accounts.partOfLoaded;
                }

                // Only load enough to fill the `toLoad` request
                if (options.initialCall) {
                    requestOptions.limit -= $scope.accounts.partOfLoaded;
                }
            }

            const accountResponseOptions = {
                addToExistingList: !options.initialCall || !options.partOf, // We always want to append the not part of results to the existing results
                partOf: options.partOf,
                isExtraResult: options.initialCall && !options.partOf // Prevent infinitely trying to fill up our list with not part of accounts when we already tried
            };

            $scope.pendingFetches++;
            accounts.filterGet(requestOptions)
                .then(
                    function (response) {

                        if (options.initialCall) {
                            // If we are on 'accounts' on a big screen, go to the account state for the first account
                            // In practice this loads the feed state in the ui-view
                            if ($mdMedia('gt-sm') && response.data.length > 0 && $state.is('accounts')) {
                                $scope.goToAccount(response.data[0]);
                            }
                        }

                        return accountResponse(accountResponseOptions)(response);
                    },
                    handleServerError,
                    accountResponse(accountResponseOptions)
                );
        }

        /**
         * @param {Object} options
         * @param {Boolean} [options.addToExistingList=false]
         * @param {Boolean} [options.isExtraResult=false] when true we will not try to fetch more results (add not part of accounts)
         * @param {Boolean} options.partOf is this a response for a part of call?
         * @returns {function(Object):void}
         */
        function accountResponse(options) {

            return function (response) {

                $scope.pendingFetches--;
                const didNotLoadUntilWantedLimit = $scope.accounts.toLoad > $scope.accounts.items.length + response.data.length;

                // Mark previously selected accounts as selected in the current list
                response.data = response.data.map(function (newAccount) {

                    const matched = $scope.accounts.items.find(function (item) {

                        return item.id === newAccount.id;
                    });

                    if (matched) {
                        newAccount.selected = matched.selected;
                    }

                    // Set tooltip title here once since it is too costly binding this function in the html
                    newAccount.tooltipTitle = getAccountListItemTitle(newAccount);

                    return newAccount;
                });

                if (options.addToExistingList) {
                    $scope.accounts.items = [...$scope.accounts.items, ...response.data];
                }
                else {
                    $scope.accounts.items = response.data;
                }

                if (!$scope.accounts.doneLoading && $scope.accounts.items.length === entityCountObject.viewTotalCount) {
                    $scope.accounts.doneLoading = true;
                }

                // We always need to set `numLoaded` to `toLoad`
                // It might sound more intuitive to set it to something like `response.data.length` but in cases where we only 5 results or even none
                // the virtual repeat doesn't follow anymore. This cause it to show empty rows in those cases.
                $scope.accounts.numLoaded = $scope.accounts.toLoad;

                // Keep track of part of accounts loaded, we need this to calculate the correct offset when we fetch the not part of list
                if (options.partOf) {
                    $scope.accounts.partOfLoaded += response.data.length;
                }

                if (didNotLoadUntilWantedLimit && !$scope.loadedSuggestions &&  $scope.pendingFetches === 0) {
                    // We didn't get enough part of results so we will add more from the not part of list.
                    // Never fetch more when in the walkthrough though, this ends up showing dupes.
                    if (!options.isExtraResult && options.partOf && !sfWalkthrough.isShowing()) {
                        return filterGet({
                            initialCall: true,
                            partOf: false
                        });
                    }

                    if ($scope.searchObject.getSearch()) {
                        return companies.get($scope.searchObject.getSearch())
                            .then(function (companiesResponse) {

                                // Add _title property on first company to show 'Other companies' subheader
                                // when using !account.owner the 'Other companies' subheader was shown without reason
                                if (companiesResponse.data.length > 0) {
                                    // eslint-disable-next-line no-underscore-dangle
                                    companiesResponse.data[0]._title = 'company';
                                }

                                $scope.isLoading = false;
                                $scope.accounts.numLoaded = $scope.accounts.toLoad;
                                $scope.accounts.items = [...$scope.accounts.items, ...companiesResponse.data];

                                if ($scope.allSelected) {
                                    setSelectedOnAllAccounts();
                                }
                            })
                            .catch(function () {

                                $scope.isLoading = false;
                                $scope.accounts.numLoaded = $scope.accounts.toLoad;

                                if ($scope.allSelected) {
                                    setSelectedOnAllAccounts();
                                }
                            });
                    }

                    // Add three suggested accounts
                    if ($scope.isSuggestedAccountsEnabled && !$scope.isAccountFilterApplied()) {
                        return accounts.getSuggestedAccounts({
                            limit: 3
                        }).then(function (suggestedAccountsResponse) {

                            $scope.loadedSuggestions = true;
                            // Add _title property on first company to show 'Suggested accounts' subheader
                            if (suggestedAccountsResponse.data.length > 0) {
                            // eslint-disable-next-line no-underscore-dangle
                                suggestedAccountsResponse.data[0]._title = 'suggested_account';
                            }

                            $scope.isLoading = false;
                            $scope.accounts.numLoaded = $scope.accounts.toLoad;
                            const suggestions = suggestedAccountsResponse.data.map(function (suggestedAccount) {

                                return { ...suggestedAccount, tooltipTitle: getSuggestedAccountListItemTitle(suggestedAccount) };
                            });
                            $scope.accounts.items = [...$scope.accounts.items, ...suggestions];

                            if ($scope.allSelected) {
                                setSelectedOnAllAccounts();
                            }
                        }).catch(function () {

                            $scope.loadedSuggestions = true;
                            $scope.isLoading = false;
                            $scope.accounts.numLoaded = $scope.accounts.toLoad;

                            if ($scope.allSelected) {
                                setSelectedOnAllAccounts();
                            }
                        });
                    }
                }

                $scope.isLoading = false;

                if ($scope.allSelected) {
                    setSelectedOnAllAccounts();
                }
            };
        }

        function fetchCounts() {

            let filterRules;

            // Do not filter when searching
            if ($scope.searchObject.getSearch()) {
                filterRules = [];
            }
            else {
                filterRules = filterService.getFilter('account');
            }

            accounts.filterGet({
                search: '',
                filterRules: [],
                returnCountOnly: true
            }).then(function (response) {

                countResponseHandler(response, 'totalCount');
            }, handleServerError, function (response) {

                countResponseHandler(response, 'totalCount');
            });

            accounts.filterGet({
                search: $scope.searchObject.getSearch(),
                filterRules: [...filterRules, { id: 'account.can_edit', operator: 'equal', value: [true] }],
                returnCountOnly: true
            }).then(function (response) {

                countResponseHandler(response, 'canEditCount');
            }, handleServerError, function (response) {

                countResponseHandler(response, 'canEditCount');
            });

            if ($scope.searchObject.getSearch() || $scope.isAccountFilterApplied()) {
                accounts.filterGet({
                    search: $scope.searchObject.getSearch(),
                    filterRules,
                    returnCountOnly: true
                }).then(function (response) {

                    countResponseHandler(response, 'currentCount');
                }, handleServerError, function (response) {

                    countResponseHandler(response, 'currentCount');
                });
            }
            else {
                // If we don't do a call for the current count, we need to reset it. Otherwise it will never update.
                entityCountObject.viewCurrentCount = 0;
            }
        }

        function countResponseHandler(response, type) {

            if (angular.isDefined(response.headers()['x-result-count'])) {
                entityCountObject.option = angular.isDefined($scope.searchObject.getSearch()) && $scope.searchObject.getSearch() !== '' ? 'search' :  ($scope.isAccountFilterApplied() ? 'filter' : '');
                entityCountObject.sortString = sortService.getCurrentSortOption('account').sort_string;

                if (type === 'totalCount') {
                    entityCountObject.viewTotalCount = Number.parseInt(response.headers()['x-result-count']);
                }

                if (type === 'currentCount') {
                    entityCountObject.viewCurrentCount = Number.parseInt(response.headers()['x-result-count']);
                }

                if (type === 'canEditCount') {
                    entityCountObject.canEditCount = Number.parseInt(response.headers()['x-result-count']);
                }

                entityCountObject.string = utils.getEntityCountString(entityCountObject);
            }
            else if (response.data.length === 0) {
                entityCountObject.viewCurrentCount = 0;
            }
        }

        $scope.selectSortOption = function (sortKey) {

            sortService.setCurrentSortKey('account', sortKey);
            $scope.accounts.reload();
        };

        $scope.isSortOptionSelected = function (sortKey) {

            return sortService.getCurrentSortKey('account') === sortKey;
        };

        $scope.getEntityCountString = function () {

            return utils.getEntityCountString(entityCountObject);
        };

        $scope.$watch('searchMode', function (newValue, oldValue) {

            if (newValue) {
                utils.forceFocus($document[0].querySelector('#searchbox'));
            }

            if (!oldValue && newValue) {
                $scope.searchObject.setSearch('');
            }

            if (oldValue && $scope.searchObject.getSearch()) {
                if (!newValue) {
                    $scope.searchObject.setSearch('');
                }

                return $scope.get();
            }
        });

        $scope.doSearch = function (search) {

            $timeout.cancel(thread);

            thread = $timeout(function () {

                $scope.searchObject.setSearch(search);

                return $scope.get();
            }, 750);
        };

        $scope.$watch(function () {
            return $mdMedia('gt-sm');
        }, function (newValue, oldValue) {

            if (newValue !== oldValue) {
                if (newValue && $state.is('accounts') && $scope.accounts.items.length > 0) {
                    return $scope.goToAccount($scope.accounts.items[0]);
                }
                else if (newValue && $state.is('accounts.account.info') && $scope.selected.id) {
                    return $scope.goToAccount({ id: $scope.selected.id });
                }

                if (oldValue) {
                    $mdSidenav('right').close();
                    $mdDialog.hide(false);
                }
            }
        });

        $scope.showPerson = function (person) {

            if (person.id) {
                if (person.type === 'user') {
                    return $state.go('user', { id: person.id });
                }

                return $state.go('contact', { id: person.id });
            }
        };

        $scope.goToAccount = function (acc) {

            if (acc.company) {
                return goToSuggestion(acc);
            }

            const type = acc.owner ? 'account' : 'company';

            if (!acc.id) {
                acc.picture = acc.logo;

                return company.create(acc).then(function (response) {

                    acc.id = response.data.id;

                    if ($mdMedia('gt-sm')) {
                        return $state.go('accounts.' + type + '.feed', { id: acc.id });
                    }

                    return $state.go('accounts.' + type + '.info', { id: acc.id } );
                });
            }

            if ($mdMedia('gt-sm')) {
                return $state.go('accounts.' + type + '.feed', { id: acc.id });
            }

            return $state.go('accounts.' + type + '.info', { id: acc.id } );
        };

        function goToSuggestion(suggestedAccount) {

            const stateType = suggestedAccount.createdAccountId ? 'account' : 'suggestion';
            const id = suggestedAccount.createdAccountId ? suggestedAccount.createdAccountId : suggestedAccount.company.id;

            if ($mdMedia('gt-sm')) {
                return $state.go(`accounts.${stateType}.feed`, { id });
            }

            return $state.go(`accounts.${stateType}.info`, { id });
        }

        $scope.getSuggestedAccountDetailsFontIcon = (suggestedAccount) => {

            if (!suggestedAccount) {
                return;
            }

            if (suggestedAccount.accepted) {
                return 'sf-icon-account_created';
            }

            if (suggestedAccount.rejected) {
                return 'sf-icon-close';
            }

            if (suggestedAccount.last_interaction_type === 'meeting') {
                return 'sf-icon-meeting-live';
            }

            return `sf-icon-${suggestedAccount.last_interaction_type}`;
        };

        function getSuggestedAccountListItemTitle(suggestedAccount) {

            return `${suggestedAccount.company.name}\n${$scope.getAccountSuggestionSubtitle(suggestedAccount, true)}`;
        }

        $scope.getAccountSuggestionSubtitle = (suggestedAccount, plainText) => {

            if (suggestedAccount) {
                if (suggestedAccount.accepted || suggestedAccount.rejected) {
                    const action = suggestedAccount.accepted ? 'created' : 'rejected';

                    if (plainText) {
                        return `${model.me.name} ${action} ${suggestedAccount.company.name}`;
                    }

                    return `<span class="accent">${model.me.name}</span> ${action} ${suggestedAccount.company.name}`;
                }

                if (plainText) {
                    const lastInteractionTypeNameMap = { email: 'emailed', meeting: 'met' };
                    return `Last ${lastInteractionTypeNameMap[suggestedAccount.last_interaction_type]} ${$filter('timeAgo')(suggestedAccount.last_interaction_date, false)}. ${suggestedAccount.number_of_contacts} ${suggestedAccount.number_of_contacts === 1 ? 'contact' : 'contacts'} at the company.`;
                }

                return `<span class="accent">${$filter('timeAgo')(suggestedAccount.last_interaction_date, false)}. ${suggestedAccount.number_of_contacts} ${suggestedAccount.number_of_contacts === 1 ? 'contact' : 'contacts'}.</span>`;
            }
        };

        $scope.$on(events.account.createdFromCompany, (event, data) => {

            const suggestedAccount = $scope.suggestedAccounts?.items.find((x) => x.company.id === data.company);

            if (!suggestedAccount) {
                return;
            }

            if (suggestedAccount.rejected) {
                suggestedAccount.rejected = false;
            }

            suggestedAccount.accepted = true;
            suggestedAccount.createdAccountId = data.account;
            suggestedAccount.tooltipTitle = getSuggestedAccountListItemTitle(suggestedAccount);
        });

        $scope.acceptAccountSuggestion = (suggestedAccount, $event) => {

            $event.stopPropagation();

            return account.create({ domain: suggestedAccount.company.domain }).then((response) => {

                if (suggestedAccount.rejected) {
                    accounts.updateSuggestedAccount(suggestedAccount.id, { rejected: false });
                    suggestedAccount.rejected = false;
                }

                suggestedAccount.createdAccountId = response.data.id;
                suggestedAccount.accepted = true;
                suggestedAccount.tooltipTitle = getSuggestedAccountListItemTitle(suggestedAccount);

                if ($mdMedia('gt-sm')) {
                    return $state.go($state.current.name.replace('suggestion', 'account'), { id: response.data.id });
                }
            });
        };

        $scope.rejectAccountSuggestion = (suggestedAccount, $event) => {

            $event.stopPropagation();

            return accounts.deleteSuggestedAccount(suggestedAccount.id).then(() => {

                suggestedAccount.rejected = true;
                suggestedAccount.tooltipTitle = getSuggestedAccountListItemTitle(suggestedAccount);
            });
        };

        function getAccountListItemTitle(acc) {

            let title = acc.name;

            if (acc.logo) {
                title += `\n${acc.domain}`;
            }
            else if (acc.last_interaction) {
                title += '\n';

                if (acc.last_interaction.person?.name) {
                    title += `${acc.last_interaction.person.name} `;
                }

                title += `${$filter('htmlToPlaintext')(acc.last_interaction.description)}`;
            }

            return title;
        }

        function setSelectedOnAllAccounts() {

            $scope.accounts.items.forEach(function (acc) {

                // Don't select companies
                if (acc.owner) {
                    acc.selected = $scope.allSelected ? acc.can_edit : false;
                }
            });
        }

        $scope.handleFilterError = ($event) => {

            if ($event.message === null && $scope.badFilterError) {

                $scope.accounts.reload();
            }

            if ($event.message) {
                $scope.usedBadFilter = true;
            }

            $scope.badFilterError = $event.message;
        };

        function handleServerError(response) {

            if (response.config.method === 'HEAD') {

                return;
            }

            $scope.pendingFetches--;
            $scope.isLoading = false;
            $scope.accounts.numLoaded = $scope.accounts.toLoad; // Makes sure the virtual repeat doesn't show anymore

            if (response && (response.status === 422 || response.status === 402)) {
                $scope.usedBadFilter = true;

                if (response.data.filter) {

                    const selectedRules = filterService.flatten(filterService.getFilter('account', { raw: true }));
                    const hydratedRules = filterService.flatten(response.data.filter.rules);

                    const rulesToApply = filterService.intersectAndEnrich(selectedRules, hydratedRules);

                    $scope.filters = filterService.setFilter('account', rulesToApply);

                    $scope.$broadcast('setFilters', $scope.filters);
                }
            }
        }
    }
})();
