<div class="custom-fields-settings-list" data-flex data-layout="column">
    <!-- Pipeline selector when showing opportunity fields -->
    <ng-container *ngIf="pipelines.length > 1 && itemClass === 'opportunity'">
        <form id="pipelineForm" class="pipeline-selector">
            <mat-form-field class="no-subscript">
                <mat-select aria-label="pipeline"
                            [(ngModel)]="currentPipeline"
                            [ngModelOptions]="{standalone: true}"
                            [compareWith]="comparePipelines"
                            (selectionChange)="changePipeline(currentPipeline)">
                    <mat-select-trigger>
                        <div data-layout="row" data-layout-align="start center" data-layout-gap="4px">
                            <span>{{ currentPipeline.name }}</span>
                            <mat-icon *ngIf="currentPipeline.recurring" fontSet="salesflare" fontIcon="sf-icon-recurring" class="icon-18"></mat-icon>
                        </div>
                    </mat-select-trigger>
                    <mat-option *ngFor="let pipeline of pipelines" [value]="pipeline">
                        <div data-layout="row" data-layout-align="start center" data-layout-gap="4px">
                            <div>{{ pipeline.name }}</div>
                            <mat-icon *ngIf="pipeline.recurring" fontSet="salesflare" fontIcon="sf-icon-recurring" class="icon-18"></mat-icon>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        <mat-divider></mat-divider>
    </ng-container>

    <!-- Standard fields -->
    <mat-list>
        <sfx-header *ngIf="standardFields.length > 0">
            Standard fields
        </sfx-header>
        <ng-container *ngFor="let field of standardFields">
            <mat-list-item>
                <div data-layout="row" data-layout-align="start center" data-flex>
                    <div data-layout="row" data-flex="45">
                        <h1 class="ellipsis">{{ field.name }}<span *ngIf="field.required">*</span></h1>
                    </div>
                    <div data-layout="row" data-flex="55">
                        <p style="vertical-align: middle; line-height: 24px;" class="ellipsis"
                           data-flex>{{ field.type.displayName || field.type.name }}</p>
                    </div>
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-list>

    <!-- Predefined custom fields -->
    <mat-list>
        <sfx-header *ngIf="predefinedFields.length > 0">Predefined fields</sfx-header>
        <ng-container *ngFor="let field of predefinedFields">
            <mat-list-item [ngClass]="{ 'field-disabled': !field.enabled }">
                <div data-layout="row" data-layout-align="start center" data-flex>
                    <div data-flex="45">
                        <h1 class="ellipsis">{{ field.name }}<span *ngIf="field.required">*</span></h1>
                    </div>
                    <div data-flex="20">
                        <p class="ellipsis">{{ field.type.displayName || field.type.name }}</p>
                    </div>
                    <div data-layout="row" data-layout-align="end center" data-flex="35">
                        <button mat-icon-button disableRipple (click)="editCustomField(field.id)">
                            <mat-icon fontSet="salesflare" fontIcon="sf-icon-edit"></mat-icon>
                        </button>
                        <button mat-button
                                [color]="field.enabled ? 'warn' : 'primary'"
                                (click)="disableOrEnableField(field)"
                                matTooltip="Show/hide the field in the app. The field is always available for filtering and through the API.">
                            {{ field.enabled ? "hide" : "show" }}
                        </button>
                    </div>
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-list>

    <!-- Custom fields -->
    <mat-list
        cdkDropList
        (cdkDropListDropped)="onDropCustomField($event)">
        <sfx-header>Custom fields</sfx-header>
        <p style="margin: 16px;" *ngIf="doneLoading && !customFields || customFields.length === 0">No custom fields present for {{itemClass}}</p>
        <ng-container *ngFor="let field of customFields">
            <mat-list-item
                    [ngClass]="{ 'field-disabled': !field.enabled }"
                    cdkDrag
                    [cdkDragStartDelay]="media.hasTouch()?300:0">
                <div class="grab" data-layout="row" data-layout-align="start center" data-flex>
                    <div data-flex="45">
                        <h1 class="ellipsis">{{ field.name }}<span *ngIf="field.required">*</span></h1>
                    </div>
                    <div data-flex="20">
                        <p class="ellipsis">{{ field.type.displayName || field.type.name }}</p>
                    </div>
                    <div data-layout="row" data-layout-align="end center" data-flex="35">
                        <button mat-icon-button disableRipple (click)="editCustomField(field.id)">
                            <mat-icon fontSet="salesflare" fontIcon="sf-icon-edit"></mat-icon>
                        </button>
                        <button mat-button
                                [color]="field.enabled ? 'warn' : 'primary'"
                                (click)="disableOrEnableField(field)"
                                matTooltip="Show/hide the field in the app. The field is always available for filtering and through the API.">
                            {{ field.enabled ? "hide" : "show" }}
                        </button>
                    </div>
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-list>
</div>

<sfx-speed-dial class="fab-dial" (click)="createCustomField()"></sfx-speed-dial>


