(function () {
    'use strict';

    angular
        .module('salesflare.components.revenueVsPreviousPeriod', [])
        .component('sfRevenueVsPreviousPeriod', {
            templateUrl: 'app-ajs/components/dashboards/predefinedReports/revenueVsPreviousPeriod/revenueVsPreviousPeriod.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                data: '<'
            }
        });

    function controller($filter, model) {

        const vm = this;

        vm.model = model;

        ////////////////

        vm.$onChanges = function (changes) {

            if (!changes || !changes.data || !changes.data.currentValue) {
                return;
            }

            const allRevenueValues = [];

            // Assemble array with both revenue values for the selected period and the previous period
            vm.data.revenues.forEach(function (revenue) {

                allRevenueValues.push(revenue.now, revenue.previous);
            });

            // Find the maximum value in this array
            const max = Math.max(...allRevenueValues.map(function (entry) {

                if (angular.isDefined(entry.revenue)) {
                    return entry.revenue;
                }

                return 0;
            }));

            vm.bars = [];

            // Assemble array with bars and their percentages based on the max value retrieved in the previous step
            vm.data.revenues.forEach(function (entry) {

                entry.nowPercentage = (entry.now.revenue / max) * 100;
                entry.previousPercentage = (entry.previous.revenue / max) * 100;
                entry.total = entry.previous.revenue - entry.now.revenue;

                const nowBar = {
                    percent: entry.nowPercentage,
                    type: 'now',
                    from: $filter('date')(new Date(entry.now.from_date), 'yyyy-MM-dd'),
                    to: $filter('date')(new Date(entry.now.to_date), 'yyyy-MM-dd'),
                    revenue: $filter('thousandSuffix')(entry.now.revenue)
                };

                const previousBar = {
                    percent: entry.previousPercentage,
                    type: 'prev'
                };

                if (entry.previous.to_date && entry.previous.from_date) {
                    previousBar.from = $filter('date')(new Date(entry.previous.from_date), 'yyyy-MM-dd');
                    previousBar.to = $filter('date')(new Date(entry.previous.to_date), 'yyyy-MM-dd');
                    previousBar.revenue = $filter('thousandSuffix')(entry.previous.revenue);
                }

                if (nowBar.percent < previousBar.percent) {
                    nowBar.trend = 'down';
                }

                if (previousBar.percent < nowBar.percent) {
                    previousBar.trend = 'up';
                }

                vm.bars.push(previousBar, nowBar, { percent: 0 });
            });
        };
    }
})();
