(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('target', targetService);

    function targetService(sfHttp, config) {

        this.getTargets = function (year, pipelineId) {

            const url = config.apiUrl + 'teams/targets';
            const request = {
                cache: true,
                params: {
                    year,
                    pipeline: pipelineId
                },
                ignoreLoadingBar: true
            };

            return sfHttp.get(url, request);
        };

        this.createTargets = function (year, targets) {

            return sfHttp.put(config.apiUrl + 'teams/targets/' + year, { targets });
        };
    }
})();
