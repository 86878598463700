import {
    AfterViewInit, OnDestroy,
    Component,
    ComponentRef,
    Inject,
    Type,
    ViewChild, ViewContainerRef, HostListener
} from '@angular/core';

import { NgClass, NgComponentOutlet, NgIf, NgTemplateOutlet } from '@angular/common';

import { BehaviorSubject, filter } from 'rxjs';

import { MediaService } from '@core/services/media.service';

import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { ToolbarModule } from '@shared/components/layout/toolbar/toolbar.module';
export interface DialogContent<DialogContentData> {

    valid: boolean;
    validateState$?: BehaviorSubject<'valid' | 'invalid' | 'validating'>;

    setInitialState(context: DialogContentData): void;

    getCurrentState(): DialogContentData;

    onConfirm?: () => void;
}

export interface DialogData<ContentData> {
    title: string;
    body?: string;
    htmlBody?: string;
    confirm: string;
    close: string;
    theme: 'default' | 'blue';
    contentStyle?: string;
    content: Type<DialogContent<ContentData>>;
    context: ContentData;
}

export interface DialogResponse<ContentData = void> {
    confirmed: boolean,
    state: ContentData
}

@Component({
    standalone: true,
    selector: 'sfx-dialog',
    templateUrl: 'dialog.component.html',
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        NgTemplateOutlet,
        NgComponentOutlet,
        NgIf,
        NgClass,
        ToolbarModule
    ],
    styleUrls: ['./dialog.component.scss']
})
export class SfxDialog<ContentData> implements AfterViewInit, OnDestroy {

    public theme: 'default' | 'blue' = 'default';

    public contentStyle: string;

    @ViewChild('content', { read: ViewContainerRef }) vcContent!: ViewContainerRef
    @ViewChild('content', { read: ComponentRef }) content!: ComponentRef<DialogContent<ContentData>>;

    constructor(
        public media: MediaService,
        public dialogRef: MatDialogRef<SfxDialog<ContentData>>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData<ContentData>
    ) {
        this.theme = data.theme || 'default';

        this.contentStyle = data.contentStyle || '';
    }

    public ngOnDestroy(): void {

        this.content?.destroy();
    }

    public ngAfterViewInit() {

        if (this.data.content) {
            this.content = this.vcContent.createComponent(this.data.content);

            if (this.data.context) {
                this.content.instance.setInitialState(this.data.context);
            }
        }
    }

    public get ContentState(): ContentData {

        return this.content?.instance?.getCurrentState?.();
    }

    public get Valid(): boolean {

        if (this.content?.instance?.valid === true || this.content?.instance?.valid === false) {
            return this.content.instance.valid;
        }

        return true;
    }

    public onCloseButton = () => {

        this.dialogRef.close({ state: this.ContentState, confirmed: false });
    };

    public onConfirmButton = () => {

        if (this.content?.instance.onConfirm) {
            this.content.instance.onConfirm();
        }

        if (this.content?.instance.validateState$?.value === 'validating') {

            return this.content.instance.validateState$.pipe(
                filter((state) => state !== 'validating')
            ).subscribe((state) => {

                if (state === 'valid') {
                    this.dialogRef.close({ state: this.ContentState, confirmed: true });
                }
                else {
                    // eslint-disable-next-line angular/document-service
                    (document?.activeElement as HTMLElement).blur();
                }
            });
        }

        if (this.Valid) {
            return this.dialogRef.close({ state: this.ContentState, confirmed: true });
        }
        else {
            // eslint-disable-next-line angular/document-service
            return (document?.activeElement as HTMLElement).blur();
        }
    };

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {

        switch (event.key) {
            case 'Enter':

                this.onConfirmButton();

                break;
            case 'Escape':
                this.onCloseButton();
                break;
        }
    }
}
