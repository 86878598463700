(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('interactiongroup', interactionGroupService);

    function interactionGroupService(sfHttp, config) {

        this.get = function (id) {

            return sfHttp.get(config.apiUrl + 'interactiongroups/' + id, { ignoreLoadingBar: true });
        };
    }
})();
