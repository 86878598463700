import { Component, Inject, OnInit } from '@angular/core';
import { MediaService } from '@core/services/media.service';

import { ImportStateModel as ImportState } from '@shared/models/import.model';
import { ApiResponseModel as ApiResponse } from '@shared/models/services/api.response.model';

import { isUndefined } from 'lodash';

@Component({
    selector: 'sfx-import-map',
    templateUrl: './import-map.component.html',
    styleUrls: ['./import-map.component.scss']
})
export class ImportMapComponent implements OnInit {

    public disableImportButton = false;
    public enableCreateAccountCheckbox = false;
    public enableFindEmailCheckbox = false;
    public showCreateAccountCheckbox = false;
    public showFindEmailCheckbox = false;

    public createAccountIfNotExists = false;
    public updateAccountIfExists = false;
    public findEmailIfNotExists = false;
    public isFindEmailEnabled = false;
    public isRestricted = false;

    public importState: ImportState

    importId: string;

    public headers: any[] = [];
    public secondaryHeaders: any[] = [];
    public importSample: any[] = [];
    public sfHeader: any[] = [];
    public chosenHeaders: any[] = [];

    public datasource: any[] = [];
    public displayedColumns: string[] = ['file'];

    constructor(
        public media: MediaService,
        @Inject('importService') public importService: any,
        @Inject('importProgress') public importProgress: any,
        @Inject('modelService') public modelService: any,
        @Inject('stateService') public stateService: any,
        @Inject('stateParams') public stateParams: any,
        @Inject('flagsService') public flagsService: any,
        @Inject('rootScope') private rootScope: any
    ) {
        this.importId = this.stateParams.id;

        this.isRestricted = this.modelService.me.restricted;
        this.isFindEmailEnabled = this.flagsService.get('findEmail') === true;

    }

    public ngOnInit(): void {

        this.disableImportButton = true;

        // Get import
        return this.importService.getImport(this.importId).then((response: ApiResponse<ImportState>) => {

            this.importState = response.data;

            if (this.importState.type === 'contact') {
                this.showCreateAccountCheckbox = true;
                this.showFindEmailCheckbox = true;
            }

            if (this.importState.status === 'waiting' || this.importState.status === 'mapping') {
                return this.importService.getImportSample(this.importId).then((sampleResponse: ApiResponse<any>) => {

                    if (sampleResponse.data.length > 0) {
                        this.headers = sampleResponse.data[0];

                        this.displayedColumns = this.displayedColumns.concat(...this.headers);

                        this.importSample = [];

                        for (let i = 1; i < sampleResponse.data.length; ++i ) {
                            this.importSample.push(sampleResponse.data[i]);

                            const datasourceEntry: any = {
                                file: `Sample ${i + 1}`
                            };

                            this.headers.forEach((header, index) => {
                                datasourceEntry[header] = sampleResponse.data[i][index];
                            });

                            this.datasource.push(datasourceEntry);
                        }

                        this.secondaryHeaders = ['file_description'].concat(...this.headers.map((header) => header.concat('_description')));

                        return this.importService.getMapping(this.importId).then((mappingResponse: ApiResponse<any>) => {

                            if (mappingResponse) {
                                const savedMapping = mappingResponse.data;
                                return this.importService.getFields(this.importState.type).then((fieldsResponse: ApiResponse<any>) => {

                                    return this.setFields(fieldsResponse.data, savedMapping);
                                });
                            }
                        });

                    }
                });
            }

            // Redirect settings/imports
            return this.stateService.go('settings.import');
        });

    }

    public back(): void {

        this.rootScope.back();
    }

    private enableCheckboxes(): void {

        const accountNameField = this.chosenHeaders.find(function (field) {

            return field && field.api_field === 'account__name';
        });

        this.enableCreateAccountCheckbox = accountNameField;

        // Check if find email checkbox needs to be enabled ((First name AND Last name) OR Full name) AND (Account name OR account website)
        // Account name or account website needed
        let accountWebsiteField;
        if (!accountNameField) {
            accountWebsiteField = this.chosenHeaders.find((field) => field && field.api_field === 'account__website');
        }

        if (accountWebsiteField || accountNameField) {
            const fullNameField = this.chosenHeaders.find((field) => field && field.api_field === 'name');
            let firstNameField;
            let lastNameField;
            if (!fullNameField) {
                firstNameField = this.chosenHeaders.find((field) => field && field.api_field === 'firstname');
                lastNameField = this.chosenHeaders.find((field) => field && field.api_field === 'lastname');
            }

            this.enableFindEmailCheckbox = fullNameField || (firstNameField && lastNameField);
        }
        else {
            this.enableFindEmailCheckbox = false;
        }
    }
    public updateMapping(): void {
        this.enableCheckboxes();
        this.importButtonStatusCheck();

        if (!this.disableImportButton) {
            this.saveMapping();
        }
    }

    public importButtonStatusCheck(): void {

        const definedHeader = this.chosenHeaders.find((header: any) => {

            return !isUndefined(header);
        });

        this.disableImportButton = !!isUndefined(definedHeader);
    }

    public saveMapping(): Promise<any> {

        const mapping: any = {};

        this.chosenHeaders.forEach((field, index) => {

            if (isUndefined(field)) {
                return;
            }

            if (field.isStandardField || field.predefined_customfield) {
                mapping[field.api_field] = this.headers[index];
                return;
            }

            let hierarchicalFieldName = 'custom__' + field.api_field;

            if (field.type.type.includes('select', 'autocomplete', 'multiselect', 'tags')) {
                hierarchicalFieldName += '__name';
            }

            mapping[hierarchicalFieldName] = this.headers[index];
        });

        return this.importService.setMapping(this.importState.id, mapping, this.findEmailIfNotExists, this.createAccountIfNotExists, this.updateAccountIfExists)
            .catch(() => {}); // Don't throw unhandled exception
    }

    public startImport(): void {

        this.disableImportButton = true;

        this.saveMapping().then(() => {

            return this.importService.start(this.importState.id).then(() => {

                this.importProgress.startPolling();

                return this.stateService.go('settings.import.' + this.importState.type);
            });
        })
            .catch(() => {}) // Don't throw unhandled exception
            .finally(() => {

                this.disableImportButton = false;
            });
    }

    public setFields(fields: any[], savedMapping: any): void {

        if (fields && fields.length > 0) {
            this.sfHeader = fields;

            let enableImportButton = false;
            // Uppercase header array to do case insensitive comparison
            const upperCasedHeaders = this.headers.map((headerEntry: any) => {

                return headerEntry.toUpperCase();
            });

            // If a mapping was saved, don't try to auto match headers
            if (Object.keys(savedMapping).length > 0) {
                for (const header of Object.keys(savedMapping)) {
                    let field = this.sfHeader.find((headerField) => headerField.api_field === header);

                    if (!field) {
                        // Try removing generated hierarchicalFieldName. See saveMapping function above
                        let cleanedHeader = header.replace(/^(custom__)/, '');
                        cleanedHeader = cleanedHeader.replace(/(__name)$/, '');
                        field =  this.sfHeader.find((headerField) => headerField.api_field === cleanedHeader);

                        if (!field) {
                            // The saved mapping wasn't correct, abort
                            return;
                        }
                    }

                    const indexOf = upperCasedHeaders.indexOf(savedMapping[header].toUpperCase());

                    if (indexOf > -1) {
                        enableImportButton = true;
                        this.chosenHeaders[indexOf] = field;
                    }
                }
            }
            else {
                for ( const header in this.sfHeader) {
                    const indexOf = upperCasedHeaders.indexOf(this.sfHeader[header].name.toUpperCase());

                    if (indexOf > -1) {
                        enableImportButton = true;
                        this.chosenHeaders[indexOf] = this.sfHeader[header];
                    }
                }
            }

            this.enableCheckboxes();

            if (enableImportButton) {
                this.disableImportButton = false;
            }
        }
    }

    public compareHeaders(option: any, selected: any): boolean {

        if (selected) {

            if (selected.id) {
                return option.id === selected.id;
            }

            if (selected.isStandardField && option.isStandardField) {
                return option.api_field === selected.api_field;
            }
        }

        return false;
    }
}
