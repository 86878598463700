(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ApiKeySettingsController', ApiKeySettingsController);

    function ApiKeySettingsController($scope, $mdDialog, $window, utils, apikeys, apikey, config) {

        $scope.keys = null;

        get();

        $scope.create = function () {

            return $mdDialog.show({
                clickOutsideToClose: true,
                controller: 'EditApiKeyDialogController',
                templateUrl: 'partials/editapikeydialog.html',
                locals: {
                    key: null
                },
                onComplete: function (scope, element) {

                    return element['0'].querySelectorAll('INPUT')[0].focus();
                }
            }).then(function (key) {

                return apikey.create(key).then(function () {

                    utils.showSuccessToast('Success! Your API key has been created.');

                    return get();
                });
            });
        };

        $scope.update = function (item) {

            return $mdDialog.show({
                clickOutsideToClose: true,
                controller: 'EditApiKeyDialogController',
                templateUrl: 'partials/editapikeydialog.html',
                locals: {
                    key: angular.copy(item)
                },
                onComplete: function (scope, element) {

                    return element['0'].querySelectorAll('INPUT')[0].focus();
                }
            }).then(function (key) {

                return apikey.update(key).then(function () {

                    return get();
                });
            });
        };

        $scope.delete = function (key) {

            const confirm = $mdDialog.confirm()
                .clickOutsideToClose(true)
                .textContent('Are you sure you want to delete this API key?')
                .ok('Yes')
                .cancel('No');
            // Do not hide parent dialog (for fullscreen)
            confirm._options.multiple = true;

            return $mdDialog.show(confirm).then(function () {

                return apikey.delete(key).then(function () {

                    return get();
                });
            });
        };

        $scope.openAPIDocs = function () {

            $window.open(config.apiUrl + 'docs', '_blank', 'noopener');
        };

        function get() {

            return apikeys.get().then(function (response) {

                $scope.keys = response.data;
            });
        }
    }
})();
