(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditSignatureDialogController', EditSignatureDialogController);

    function EditSignatureDialogController($rootScope, $scope, $mdDialog, $mdMedia, $timeout, sfDiscardDialog, joditService) {

        let viewHTML = false;

        $scope.$mdMedia = $mdMedia;
        $scope.signatureData = {
            email_signature: this.email_signature,
            add_signature_to_replies: this.add_signature_to_replies,
            add_signature_to_campaign: this.add_signature_to_campaign
        };
        $scope.originalSignatureData = angular.copy($scope.signatureData);

        init();

        $scope.showingHTML = function () {

            return viewHTML;
        };

        $scope.save = function () {

            $mdDialog.hide($scope.signatureData);
        };

        $scope.back = function (reload) {

            if (!angular.equals($scope.originalSignatureData, $scope.signatureData)) {
                return sfDiscardDialog.show(false, 'signature', $scope).then(function () {

                    return close(reload);
                });
            }

            return close(reload);
        };

        function init() {

            // Instantiate jodit on next tick so the dialog and .editor is there before we try to attach jodit to it
            $timeout(function () {

                const jodit = joditService.getJoditEditor({
                    queryStringToBindTo: '.editor',
                    placeholder: 'Enter your signature here',
                    previewButton: {
                        enabled: false
                    },
                    mergeFieldButton: {
                        enabled: false
                    },
                    templatesButton: {
                        enabled: false
                    },
                    attachmentsButton: {
                        enabled: false
                    },
                    sendTestButton: {
                        enabled: false
                    }
                }, function (bodyText) {

                    $scope.signatureData.email_signature = bodyText;
                }, function (mode) {

                    viewHTML = mode === 'html';
                });

                if ($scope.signatureData && $scope.signatureData.email_signature) {
                    jodit.setEditorValue($scope.signatureData.email_signature);
                }
            });
        }

        function close(reload) {

            if ($mdMedia('gt-sm')) {
                return $mdDialog.hide(reload);
            }

            return $rootScope.back();
        }
    }
})();
