(function () {
    'use strict';

    angular
        .module('salesflare.components.card', [])
        .component('sfCard', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/card/card.html',
            bindings: {
                vatNumber: '<', // If you want to pass an existing vat number to prefill
                hideCurrencyInfo: '<',
                onInvalid: '&', // Called every time the card is invalid again, use this to reset your state
                onValidated: '&', // When everything filled out and validated
                onValidateError: '&', // When validation fails
                onProcessingChanged: '&' // When processing status changes
            }
        });

    /**
     * VAT logic described here https://www.notion.so/adri/Checkout-logic-VAT-2f48d7b1c6534579a8d8bb6d43eb9679
     *
     * @param {Object} $document
     * @param {Object} $element
     * @param {Object} $scope
     * @param {Object} $q
     * @param {Object} billing
     * @param {Object} stripeService
     * @returns {void}
     */
    function controller($document, $element, $scope, $q, billing, stripeService) {

        const vm = this;

        const elements = stripeService.stripe.elements({
            locale: 'en',
            fonts: [{
                cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito:wght@400'
            }]
        });
        const style = {
            base: {
                fontSize: '16px',
                fontFamily: 'Nunito, san-serif',
                color: 'rgba(0, 0, 0, 0.87)',
                lineHeight: '26px',
                fontSmoothing: 'antialiased'
            },
            invalid: {
                color: 'rgba(0, 0, 0, 0.87)'
            }
        };
        const cardNumber = elements.create('cardNumber', { style, placeholder: '' });
        const cardExpiry = elements.create('cardExpiry', { style, placeholder: '' });
        const cardCvc = elements.create('cardCvc', { style, placeholder: '' });

        let token;
        const currency = {};
        // BE is not in the list, although it technically should be, but BE is a special case since we always charge VAT there
        const EUVATCountries = new Set(['AT', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE']);

        vm.$onInit = function () {

            vm.name = null;

            vm.processing = false;
            vm.VAT = {
                number: vm.vatNumber,
                info: null,
                currencyInfo: null,
                charge: false,
                askNumber: false,
                numberHint: null,
                validNumber: false,
                validatingNumber: false,
                reset: function () {

                    this.number = null;
                    this.info = null;
                    this.currencyInfo = null;
                    this.charge = false;
                    this.askNumber = false;
                    this.numberHint = null;
                    this.validNumber = false;
                    this.validatingNumber = false;
                }
            };

            // Set VAT number to empty string when it's undefined to properly trigger onChange when pattern is invalid
            if (!vm.VAT.number) {
                vm.VAT.number = '';
            }

            cardNumber.on('change', stripeElementOnChange);
            cardExpiry.on('change', stripeElementOnChange);
            cardCvc.on('change', stripeElementOnChange);

            cardNumber.on('focus', stripeElementOnFocus);
            cardExpiry.on('focus', stripeElementOnFocus);
            cardCvc.on('focus', stripeElementOnFocus);

            cardNumber.on('blur', stripeElementOnBlur);
            cardExpiry.on('blur', stripeElementOnBlur);
            cardCvc.on('blur', stripeElementOnBlur);
        };

        vm.$postLink = function () {

            // Mount Stripe elements to DOM
            cardNumber.mount('#cardNumber');
            cardExpiry.mount('#cardExpiry');
            cardCvc.mount('#cardCvc');
        };

        vm.onVATNumberChange = function () {

            setProcessing(true);
            vm.sfCardForm.VATNumber.$setValidity('valid', true);
            vm.sfCardForm.VATNumber.$setValidity('unknown', true);

            vm.VAT.validNumber = false;

            // This happens when the pattern doesn't match
            if (angular.isUndefined(vm.VAT.number)) {

                setProcessing(false);

                return vm.onInvalid();
            }

            if (!vm.VAT.number) {
                return setVATBasedOnCountryCode(token.card.country).then(function () {

                    setProcessing(false);

                    return triggerValidated();
                });
            }

            vm.VAT.validatingNumber = true;

            const country = vm.VAT.number.slice(0, 2);
            const number = vm.VAT.number.slice(2);

            return billing.validateVAT(country, number, { noToast: true })
                .then(function (response) {

                    vm.VAT.validatingNumber = false;
                    vm.VAT.validNumber = response.data.valid;
                    vm.VAT.vatValidationToken = response.data.vat_validation_token;

                    return setVATBasedOnCountryCode().then(function () {

                        setProcessing(false);

                        return triggerValidated();
                    });
                })
                .catch(function (err) {

                    vm.VAT.validatingNumber = false;
                    vm.VAT.validNumber = false;
                    vm.VAT.vatValidationToken = null;
                    vm.VAT.charge = true;

                    if (err.status >= 400 && err.status !== 503) {
                        vm.sfCardForm.VATNumber.$setValidity('valid', false);
                    }
                    else if (err.status === 503) {
                        vm.sfCardForm.VATNumber.$setValidity('unknown', false);
                    }

                    return setVATBasedOnCountryCode().then(function () {

                        setProcessing(false);

                        return vm.onInvalid();
                    });
                });
        };

        /**
         *
         * @returns {Promise}
         */
        function setVATBasedOnCountryCode() {

            let countryCode = token.card.country;

            // If the country from the VAT number is different then the card use the VAT one
            // This is more correct
            if (vm.VAT.number && vm.VAT.validNumber) {
                countryCode = vm.VAT.number.slice(0, 2);
            }

            if (countryCode === 'BE') {
                vm.VAT.askNumber = true;
                vm.VAT.charge = true;
                vm.VAT.currencyInfo = 'As you are based in Europe, we charge in EUR.';
                vm.VAT.info = 'Within Belgium we charge 21% VAT.';
                vm.VAT.numberHint = 'If you are VAT registered, please fill out your VAT number';

                currency.iso = 'EUR';
                currency.html = '&#128';

                return $q.resolve();
            }

            // IgnoreLoadingBar is used, 'cause we possibly started it in the SCA flow. We don't want the http call to complete the loading circle
            return billing.getCountries({ code: countryCode }, { ignoreLoadingBar: true }).then(function (response) {

                if (response.data[0].continent_code === 'EU') {
                    currency.iso = 'EUR';
                    currency.html = '&#128';

                    vm.VAT.askNumber = false;
                    vm.VAT.charge = false;
                    vm.VAT.info = null;
                    vm.VAT.numberHint = null;

                    if (EUVATCountries.has(countryCode)) {
                        vm.VAT.askNumber = true;
                        vm.VAT.charge = !vm.VAT.validNumber;
                        vm.VAT.currencyInfo = 'As you are based in Europe, we charge in EUR.';
                        vm.VAT.info = 'To waive the VAT for EU companies, we require a VAT number.';
                        vm.VAT.numberHint = 'If no VAT number is filled out, VAT needs to be charged';
                    }
                }
                else {
                    vm.VAT.askNumber = false;
                    vm.VAT.charge = false;
                    vm.VAT.currencyInfo = 'As you are based outside Europe, we charge in USD.';
                    vm.VAT.numberHint = null;

                    currency.iso = 'USD';
                    currency.html = '&#36;';
                }

                return $q.resolve();
            });
        }

        /**
         * Do some manual class setting to mimic materials behaviour
         * and validate the card when everything is filled in
         *
         * @param {Object} event https://stripe.com/docs/stripe-js/reference#element-on
         * @returns {void}
         */
        function stripeElementOnChange(event) {

            const displayError = angular.element('.' + event.elementType + '-errors');
            const displayContainer = angular.element('.' + event.elementType + '-container');

            displayContainer.removeClass('ng-valid');

            if (event.error) {
                displayError.text(event.error.message);
                displayContainer.addClass('md-input-invalid');

                // Use evalAsync since the Stripe callback is outside of the angular loop
                return $scope.$evalAsync(function () {

                    token = undefined;
                    vm.VAT.reset();

                    return vm.onInvalid();
                });
            }
            else {
                // Reset error state
                displayError.text('');
                displayContainer.removeClass('md-input-invalid');

                if (event.empty) {
                    displayContainer.removeClass('md-input-has-value');
                }
                else {
                    displayContainer.addClass('md-input-has-value');

                    if (event.complete) {
                        displayContainer.addClass('ng-valid');

                        if ($element.find('md-input-container.ng-valid, md-input-container .ng-valid').length === $element.find('md-input-container').length) {
                            return validateCard();
                        }
                    }
                }
            }
        }

        function stripeElementOnFocus(event) {

            return angular.element('.' + event.elementType + '-container').addClass('md-input-focused');
        }

        function stripeElementOnBlur(event) {

            angular.element('.' + event.elementType + '-container').removeClass('md-input-focused');

            const activeElement = $document[0].activeElement;

            // Change is only called when something actually changed
            // When there is a complete valid number and you paste in a new complete valid number
            // no element data changes so onChange is not being called so we catch that here
            if ($element.find('md-input-container.ng-valid, md-input-container .ng-valid').length === $element.find('md-input-container').length) {
                return validateCard().then(function () {

                    if (activeElement.classList.contains('pay-button')) {
                        activeElement.click();
                    }

                }).catch(function () {

                    if (activeElement.classList.contains('pay-button')) {
                        activeElement.click();
                    }
                });
            }
        }

        function validateCard() {

            return $q(function (resolve, reject) {

                setProcessing(true);

                return stripeService.stripe.createToken(cardNumber, { name: vm.name })
                    .then(function (result) {

                        // Use evalAsync since the Stripe callback is outside of the angular loop
                        return $scope.$evalAsync(function () {

                            setProcessing(false);

                            if (result.error) {
                                token = undefined;
                                vm.VAT.reset();

                                vm.onValidateError({
                                    $event: {
                                        error: result.error
                                    }
                                });
                                return reject();
                            }

                            token = result.token;

                            return setVATBasedOnCountryCode().then(function () {

                                if (vm.vatNumber && vm.VAT.askNumber) {
                                    delete vm.vatNumber;

                                    return vm.onVATNumberChange().then(function () {

                                        triggerValidated();
                                        return resolve();
                                    }).catch(function () {

                                        return reject();
                                    });
                                }

                                triggerValidated();
                                return resolve();
                            }).catch(function () {

                                return reject();
                            });
                        });
                    })
                    .catch(function () {

                        setProcessing(false);
                        return reject();
                    });
            });
        }

        function triggerValidated() {

            return vm.onValidated({
                $event: {
                    token,
                    VATNumber: vm.VAT.number,
                    VATValidationToken: vm.VAT.vatValidationToken,
                    chargeVAT: vm.VAT.charge,
                    currency
                }
            });
        }

        function setProcessing(isProcessing) {

            vm.processing = isProcessing;

            return vm.onProcessingChanged({
                $event: {
                    processing: isProcessing
                }
            });
        }
    }
})();
