(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditContactsController', EditContactsController);

    function EditContactsController($rootScope, $scope, $stateParams, $state, $timeout, $mdMedia, $mdDialog, events, contactsService, account, sfDiscardDialog) {

        let thread = null;
        let accountObject;
        const dirtyContacts = [];

        $scope.accountId = $stateParams.account;
        $scope.search = '';
        $scope.contacts = [];
        $scope.selectedContacts = $stateParams.selectedContacts || [];
        $scope.submitting = false;

        get();

        $scope.back = function () {

            if (dirtyContacts.length > 0) {
                return sfDiscardDialog.show(false, 'contacts').then(close);
            }
            else {
                return close();
            }
        };

        $scope.$watch('search', function (newValue, oldValue) {

            if (newValue !== oldValue) {
                $timeout.cancel(thread);

                thread = $timeout(function () {

                    return getContacts();
                }, 750);
            }
        });

        $scope.selectContact = function (contact) {

            contact._dirty = !contact._dirty;

            if (contact._dirty) {
                const dirtyContact = angular.copy(contact);

                if (dirtyContact.selected) {
                    dirtyContact.account = $scope.accountId;
                }

                delete dirtyContact.selected;

                dirtyContacts.push(dirtyContact);
            }
            else {
                for (let i = 0; i < dirtyContacts.length; ++i) {
                    if (dirtyContacts[i].id === contact.id) {
                        dirtyContacts.splice(i, 1);

                        break;
                    }
                }
            }
        };

        $scope.createContact = function () {

            // We don't go to edit person via state changes because of https://github.com/Salesflare/Server/issues/8626
            return $mdDialog.show({
                controller: 'EditPersonController',
                templateUrl: 'partials/editperson.html',
                multiple: true,
                bindToController: true,
                clickOutsideToClose: true,
                fullscreen: !$mdMedia('gt-sm'),
                scope: $rootScope.$new(),
                locals: {
                    dialogParams: {
                        type: 'contact',
                        createContact: true,
                        contactName: $scope.search,
                        account: accountObject
                    }
                }
            }).then(function (response) {

                if (response.id) {
                    return contactsService.get(null, { id: response.id }).then(function (res) {

                        res.data.forEach(function (c) {

                            $scope.selectContact(c);
                        });

                        $scope.search = '';

                        return getContacts();
                    });
                }
            });
        };

        $scope.submitOnEnter = function ($event) {

            if ($event.which === 13) {
                return $scope.submit();
            }
        };

        $scope.submit = function () {

            $scope.submitting = true;

            if (dirtyContacts.length === 0) {
                return close(true);
            }

            for (const dirtyContact of dirtyContacts) {
                for (let i = 0; i < accountObject.customers.length; ++i) {
                    if (dirtyContact.id === accountObject.customers[i].id) {
                        dirtyContact.account = null;
                        dirtyContact._deleted = true;

                        continue;
                    }
                }
            }

            return account.updateContacts($scope.accountId, dirtyContacts).then(function () {

                return close(true);
            }).catch(function () {

                $scope.submitting = false;
            });
        };

        function close(updated) {

            if ($mdMedia('gt-sm')) {
                if (updated) {
                    $rootScope.$broadcast(events.account.updated);
                }

                return $mdDialog.hide();
            }
            else {
                return $state.go('accounts.account.info', { id: $scope.accountId });
            }
        }

        function get() {

            return account.get($scope.accountId).then(function (response) {

                accountObject = response.data;

                if ($scope.selectedContacts && $scope.selectedContacts.length > 0) {
                    return contactsService.get(null, { id: $scope.selectedContacts }).then(function (res) {

                        res.data.forEach(function (c) {

                            $scope.selectContact(c);

                        });

                        return getContacts();
                    });
                }

                return getContacts();
            });
        }

        /**
         * Get 15 contacts, update dirty and selected information about it
         *
         * @returns {void}
         */
        function getContacts() {

            return contactsService.get($scope.search, null, 15).then(function (response) {

                $scope.contacts = [];

                if ($scope.search === '') {
                    for (const accountContact of accountObject.customers) {
                        let found = false;

                        for (const dirtyContact of dirtyContacts) {
                            if (accountContact.id === dirtyContact.id ) {
                                found = true;
                            }
                        }

                        if (!found) {
                            const selectedContact = angular.copy(accountContact);
                            selectedContact.selected = true;

                            $scope.contacts.push(selectedContact);

                            for (let i = response.data.length - 1; i >= 0; --i) {
                                if (response.data[i].id === selectedContact.id) {
                                    response.data.splice(i, 1);
                                }
                            }
                        }
                    }

                    for (const dirtyContact of dirtyContacts) {
                        let dirtyFound = false;

                        for (let i = 0; i < accountObject.customers.length; ++i) {
                            if (dirtyContact.id === accountObject.customers[i].id ) {
                                dirtyFound = true;
                            }
                        }

                        if (!dirtyFound) {
                            const selectedDirtyContact = angular.copy(dirtyContact);
                            selectedDirtyContact.selected = true;

                            $scope.contacts.push(selectedDirtyContact);

                            for (let i = response.data.length - 1; i >= 0; --i) {
                                if (response.data[i].id === selectedDirtyContact.id) {
                                    response.data.splice(i, 1);
                                }
                            }
                        }
                    }

                    $scope.contacts = [...$scope.contacts, ...response.data];
                }
                else {
                    for (let i = 0; i < response.data.length; ++i) {
                        for (const accountContact of accountObject.customers) {
                            if (response.data[i].id === accountContact.id) {
                                response.data[i].selected = true;
                            }
                        }

                        for (const dirtyContact of dirtyContacts) {
                            if (response.data[i].id === dirtyContact.id) {
                                response.data[i]._dirty = true;
                                response.data[i].selected = !response.data[i].selected;
                            }
                        }

                        $scope.contacts.push(response.data[i]);
                    }
                }
            });
        }
    }
})();
