(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('OpportunityDialogController', OpportunityDialogController);

    function OpportunityDialogController($scope, $mdDialog, accountId, opportunityService, pipelines, model, utils) {

        $scope.pipelines = [];
        $scope.opportunity = { account: accountId };
        $scope.closeDateIsRequired = false;
        $scope.minDate = utils.getTodayAtMidnight();

        init();

        function init() {

            pipelines.getViewable().then(function (response) {

                $scope.pipelines = response.pipelines;

                if ($scope.pipelines.length > 0) {
                    const currentPipelineId = store.get('current_pipeline_' + model.me.id);
                    if (currentPipelineId) {
                        $scope.selectedPipeline = $scope.pipelines.find(function (pipeline) {
                            return pipeline.id === currentPipelineId;
                        });
                    }

                    if (!$scope.selectedPipeline) {
                        $scope.selectedPipeline = $scope.pipelines[0];
                    }

                    $scope.stages = $scope.selectedPipeline.stages;

                    if ($scope.stages.length > 0) {
                        $scope.opportunity.stage = $scope.stages[0];
                        $scope.closeDateIsRequired = ($scope.opportunity.stage.probability > 0 || $scope.opportunity.stage.fixed_stage === 2);
                    }
                }
            });
        }

        $scope.createOpportunity = function () {

            if ($scope.opportunityDialogForm.$valid) {
                $scope.opportunity.close_date = utils.localDateObjectToUTCDateObject($scope.opportunity.close_date);

                return opportunityService.create($scope.opportunity).then(function () {

                    return $mdDialog.hide(true);
                });
            }
        };

        $scope.cancel = function () {

            return $mdDialog.cancel();
        };

        $scope.changePipeline = function (pipeline) {

            if ($scope.selectedPipeline.id !== pipeline.id) {
                $scope.selectedPipeline = pipeline;
                $scope.stages = $scope.selectedPipeline.stages;

                if ($scope.stages.length > 0) {
                    $scope.opportunity.stage = $scope.stages[0];
                }

                $scope.opportunity.close_date = null;
                $scope.closeDateIsRequired = ($scope.opportunity.stage.probability > 0 || $scope.opportunity.stage.fixed_stage === 2);
            }
        };
    }
})();
