(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('password', passwordService);

    function passwordService(sfHttp, config) {

        this.update = function (password, token) {

            if (token) {
                return sfHttp.put(config.apiUrl + 'password', { password }, { headers: { Authorization: 'Bearer ' + token } });
            }

            return sfHttp.put(config.apiUrl + 'password', { password });
        };

        this.request = function (email) {

            return sfHttp.post(config.apiUrl + 'password', { email });
        };
    }
})();
