(function () {
    'use strict';

    angular
        .module('salesflare.components.pricingSwitch', [])
        .component('sfPricingSwitch', {
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/pricingswitch/pricingswitch.html',
            bindings: {
                options: '<',
                selectedValue: '=',
                editPlan: '<'
            }
        });
})();
