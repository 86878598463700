(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('OpportunitiesTimelineController', OpportunitiesTimelineController);

    function OpportunitiesTimelineController($scope, $state, $filter, $window, $mdMedia, $mdSidenav, $mdComponentRegistry, utils, opportunities, opportunityService, filterService, helperFunctionsService, sortService) {

        $scope.showOpenAccountInNewTab = !$window.isMobile;
        $scope.timeLine = {};
        $scope.draggedOpportunity = null;
        $scope.opportunitiesWithCloseDate = null;
        $scope.isLoading = true;
        $scope.isOpportunityFilterApplied = filterService.isOpportunityFilterApplied;
        $scope.usedBadFilter = false;
        $scope.selectedOpportunity.id = $state.params.id;
        $scope.insufficientPermissionsMessage = 'You don\'t have permission to edit this opportunity. Ask an admin.';

        if (!$scope.selectedOpportunity.id && $mdMedia('gt-sm') && $mdComponentRegistry.get('right')) {
            $mdSidenav('right').close();
        }

        // We don't need to manually call `get` here to init as the OpportunitiesController will trigger this for us through the event.
        $scope.$on('get', get);

        $scope.$on('$destroy', function () {

            $scope.opportunities.items = [];
        });

        $scope.isSameMonth = function (dt1, dt2) {

            return utils.isSameMonth(dt1, dt2);
        };

        /**
         * - close date is in the future
         * - close date is null && (stage probability is 0 OR stage is the lost stage)
         * - close date is in the past && (stage is won or lost OR stage probability is 0 or 100)
         *
         * @param {Object} opportunity
         * @param {Number} opportunity.id
         * @param {Date} opportunity.close_date
         * @param {Object} opportunity.stage
         * @param {Number} opportunity.stage.probability
         * @param {Number} opportunity.stage.fixed_stage
         * @returns {Boolean}
         */
        $scope.isInvalidOpportunity = function (opportunity) {

            if (!opportunity.id) {
                return false;
            }

            if (!opportunity.close_date && (opportunity.stage.probability > 0 || opportunity.stage.fixed_stage === 2)) {
                return true;
            }

            if ((opportunity.stage.fixed_stage !== 1 && opportunity.stage.fixed_stage !== 2) && opportunity.stage.probability > 0 && opportunity.stage.probability < 100 && new Date(opportunity.close_date) < utils.getTodayAtMidnight()) {
                return true;
            }

            return false;
        };

        /* ACTION MENU FUNCTIONS (2 other functions in appController.js) */

        $scope.goToAccountFeed = function (opp, newTab) {

            if (newTab) {
                const url = $state.href('accounts.account.feed', { id: opp.account.id });
                $window.open(url, '_blank', 'noopener');
                return;
            }

            return $state.go('accounts.account.feed',{ id: opp.account.id });
        };

        $scope.goToOpportunity = function (id) {

            if (id) {
                if ($mdMedia('gt-sm')) {
                    $mdSidenav('right').open();
                }

                $scope.selectedOpportunity.id = id;

                return $state.go('opportunities.timeline.opportunity', { id });
            }
        };

        $scope.assign = function (opp, $event) {

            return helperFunctionsService.assign(opp, $event).then(function () {

                return get();
            });
        };

        $scope.setDone = function (opp) {

            opp.done = !opp.done;

            return opportunityService.update(opp).then(function () {

                if (opp.done) {
                    utils.showSuccessToast('Marked as done! You can still search or filter to find it back.');
                }

                return get(true);
            }).catch(function () {

                return get(true);
            });
        };

        function get() {

            if (!$scope.currentPipeline) {
                return;
            }

            $scope.usedBadFilter = false;

            $scope.isLoading = true;

            const orderBy = sortService.getCurrentSortOption('opportunity').order_by.map(function (value) {

                if (value === 'stage.order asc') {
                    return 'close_date asc';
                }

                return value;
            });

            let defaultFilters = [...filterService.getCurrentPipelineFilter($scope.currentPipeline.id), ...filterService.getPredefinedFilters('opportunity')[$state.current.name]];
            let newFilters;
            // Filter out the mandatory/hidden pipeline rule, to see if there are actually user applied rules
            const appliedFilters = filterService.getFilter('opportunity').filter(function (rule) {

                return rule.id !== 'opportunity.pipeline.id';
            });

            // Do not filter when searching
            if ($scope.searchObject.getSearch()) {
                newFilters = defaultFilters;
            }
            else {
                newFilters = filterService.getFilter('opportunity');
            }

            if (appliedFilters.length > 0 && !filterService.isOpportunityFilterApplied()) {
                defaultFilters = [...filterService.getDefaultFilters('opportunity', $scope.currentUser, true), ...defaultFilters];
            }

            opportunities.filterGet('', defaultFilters, null, null, null, null, null, { returnCountOnly: true }).then(function (response) {
                countResponseHandler(response, 'totalCount');
            }, handleServerError, function (response) {
                countResponseHandler(response, 'totalCount');
            });

            opportunities.filterGet($scope.searchObject.getSearch(), [...newFilters, { id: 'contact.can_edit', operator: 'equal', value: [true] }], null, null, null, null, null, { returnCountOnly: true }).then(function (response) {
                countResponseHandler(response, 'canEditCount');
            }, handleServerError, function (response) {
                countResponseHandler(response, 'canEditCount');
            });

            if ($scope.searchObject.getSearch() || $scope.isOpportunityFilterApplied()) {
                opportunities.filterGet($scope.searchObject.getSearch(), newFilters, null, null, null, null, null, { returnCountOnly: true }).then(function (response) {
                    countResponseHandler(response, 'currentCount');
                }, handleServerError, function (response) {
                    countResponseHandler(response, 'currentCount');
                });
            }
            else {
                // If we don't do a call for the current count, we need to reset it. Otherwise it will never update.
                $scope.entityCountObject.viewCurrentCount = 0;
            }

            return opportunities.filterGet($scope.searchObject.getSearch(), newFilters, null, null, orderBy, null, null, { includeCount: false }).then(opportunitiesResponse, handleServerError, opportunitiesResponse);
        }

        function opportunitiesResponse(response) {

            // Filter out the opportunities without a close_date since they are irrelevant for this view
            // and check if response contains opportunities for the correct pipeline
            if (response.data && response.data.length > 0 && response.data[0].pipeline) {
                if ($scope.$parent && response.data[0].pipeline.name === $scope.$parent.currentPipeline.name) {
                    $scope.opportunities.items = response.data.filter(function (opportunity) {

                        return !!opportunity.close_date;
                    });
                }
            }
            else if (response.data.length === 0) {
                // Allow empty responses to be applied
                $scope.opportunities.items = response.data;
            }

            $scope.timeLine = {};

            for (let i = 0; i < $scope.opportunities.items.length; ++i) {
                const opportunity = $scope.opportunities.items[i];

                opportunity.close_date = utils.UTCDateStringToLocalDateObject(opportunity.close_date);

                const month = $filter('date')(opportunity.close_date, 'MMMM yyyy');

                if (!$scope.timeLine[month]) {
                    $scope.timeLine[month] = {
                        sum: 0,
                        opportunities: []
                    };
                }

                if (opportunity.stage.fixed_stage !== 2) {
                    $scope.timeLine[month].sum += opportunity.calculated_value;
                }

                $scope.timeLine[month].opportunities.push(opportunity);
            }

            if ($scope.selectedOpportunity.id) {
                $mdSidenav('right').open();
            }

            $scope.isLoading = false;
        }

        function handleServerError(response) {

            $scope.isLoading = false;

            return $scope.handleServerError(response);
        }

        function countResponseHandler(response, type) {

            if (angular.isDefined(response.headers()['x-result-count'])) {
                const appliedFilters = filterService.getFilter('opportunity').filter(function (rule) {

                    return rule.id !== 'opportunity.pipeline.id';
                });
                $scope.entityCountObject.option = angular.isDefined($scope.searchObject.getSearch()) && $scope.searchObject.getSearch() !== '' ? 'search' : ((filterService.isOpportunityFilterApplied() && appliedFilters.length > 0) ? 'filter' : '');
                $scope.entityCountObject.sortString = sortService.getCurrentSortOption('opportunity').sort_string;

                if (type === 'totalCount') {
                    $scope.entityCountObject.viewTotalCount = Number.parseInt(response.headers()['x-result-count']);
                }

                if (type === 'currentCount') {
                    $scope.entityCountObject.viewCurrentCount = Number.parseInt(response.headers()['x-result-count']);
                }

                if (type === 'canEditCount') {
                    $scope.entityCountObject.canEditCount = Number.parseInt(response.headers()['x-result-count']);
                }

                $scope.entityCountObject.string = utils.getEntityCountString($scope.entityCountObject);
            }
        }

        $scope.getEntityCountString = function () {

            return utils.getEntityCountString($scope.entityCountObject);
        };
    }
})();
