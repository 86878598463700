import { Component, Input } from '@angular/core';

@Component({
    selector: 'sfx-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {

  @Input() image = '';
  @Input() text = '';
  @Input() text2 = '';

  constructor() {}
}
