(function () {
    'use strict';

    angular
        .module('salesflare.components.tasks.completed', [])
        .component('sfTasksCompleted', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/tasks/completed/completed.html',
            bindings: {
                entityCountString: '<', // The amount of tasks in the db
                tasks: '<', // The md-virtual repeat object
                loadingTasks: '<',
                onAddTask: '&',
                onDismissTask: '&',
                onSelectedChanged: '&', // Directly bound to the task event
                onEditTask: '&',
                onEditedTask: '&',
                onUpdateTask: '&',
                isTaskFilterApplied: '&',
                usedBadFilter: '<',
                badFilterError: '<',
                setFilters: '&',
                getSearch: '&'
            }
        });

    function controller($mdMedia) {

        const vm = this;

        vm.$onInit = function () {

            vm.doneQuote = 'No completed tasks yet!';
            // Ideally this would be dynamic but virtual repeat only allows setting it on load basically
            vm.itemSize = $mdMedia('gt-sm') ? 48 : 72;
        };

        vm.onUpdateTaskProxy = function ($event) {

            return vm.onUpdateTask({
                $event
            });
        };

        vm.onDismissTaskProxy = function ($event) {

            return vm.onDismissTask({
                $event
            });
        };

        vm.onEditTaskProxy = function ($event) {

            return vm.onEditTask({
                $event
            });
        };

        vm.onEditedTaskProxy = function ($event) {

            return vm.onEditedTask({
                $event
            });
        };
    }
})();
