(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('billing', billingService);

    function billingService(sfHttp, config) {

        this.create = function (customer) {

            return sfHttp.post(config.apiUrl + 'billing', customer);
        };

        this.updatePlan = function (planId, trial, options) {

            let url = config.apiUrl + 'billing/plan';

            if (trial) {
                url += '?trial=true';
            }

            // We don't want the loading circle to be completed when in the SCA flow, that's why this options is added
            const ignoreLoadingBar = options && options.ignoreLoadingBar ? true : false;

            return sfHttp.put(url, { plan: planId }, { ignoreLoadingBar });
        };

        this.downgrade = function (planId) {

            const url = config.apiUrl + 'billing/plan';

            return sfHttp.put(url, { plan: planId }, { ignoreLoadingBar: false });
        };

        this.get = function () {

            return sfHttp.get(config.apiUrl + 'billing', { cache: true });
        };

        this.update = function (billingInformation) {

            return sfHttp.put(config.apiUrl + 'billing', billingInformation);
        };

        this.setCreditPackage = function (creditPackageSize) {

            return sfHttp.post(config.apiUrl + 'billing/credits', { credit_package: creditPackageSize }, { ignoreLoadingBar: false });
        };

        this.createBillingAddress = function (billingAddress) {

            return sfHttp.post(config.apiUrl + 'billing/invoice', billingAddress);
        };

        this.updateBillingAddress = function (billingAddress) {

            return sfHttp.put(config.apiUrl + 'billing/invoice', billingAddress);
        };

        this.getBillingAddress = function () {

            return sfHttp.get(config.apiUrl + 'billing/invoice', { cache: true });
        };

        this.getCountries = function (filters, options) {

            const ignoreLoadingBar = options && options.ignoreLoadingBar ? true : false;

            return sfHttp.get(config.apiUrl + 'billing/countries', { params: angular.copy(filters), ignoreLoadingBar } || {});
        };

        this.validateVAT = function (countryCode, VAT, options) {

            return sfHttp.post(config.apiUrl + 'billing/vats/validate', { country_code: countryCode, vat_number: VAT }, options);
        };

        this.getBillingHistory = function (fromDate) {

            let url = config.apiUrl + 'billing/history';

            if (fromDate) {
                url += '?from_date=' + fromDate;
            }

            return sfHttp.get(url, { cache: true });
        };

        this.validateDiscountCode = function (discountCode, planId) {

            let url = config.apiUrl + 'billing/discounts?discount_code=' + discountCode;

            if (planId) {
                url += ('&plan=' + planId);
            }

            return sfHttp.get(url);
        };

        this.validateCampaignDiscountCode = function (discountCode) {

            const url = config.apiUrl + 'discounts?discount_code=' + discountCode;

            return sfHttp.get(url);
        };

        this.createDeal = function (deal) {

            return sfHttp.post(config.apiUrl + 'billing/deals', deal);
        };

        this.SCANeeded = function (options) {

            const ignoreLoadingBar = options && options.ignoreLoadingBar ? true : false;

            return sfHttp.get(config.apiUrl + 'billing/sca', { ignoreLoadingBar });
        };

        this.syncStripeData = function () {

            return sfHttp.get(config.apiUrl + 'billing/sync');
        };
    }
})();
