(function () {
    'use strict';

    angular
        .module('salesflare.components.import', [
            'salesflare.components.import.list',
            'salesflare.components.import.map'
        ])
        .component('sfImport', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/import/import.html'
        });

    function controller($mdMedia, $state, $scope, $window, imports, utils, model) {

        const vm = this;
        vm.$mdMedia = $mdMedia;
        vm.pollingperiod = 20;
        vm.progressValues = [];
        vm.me = model.me;
        vm.isMobile = $window.isMobile;

        vm.$onInit = function () {

            vm.state = $state;
        };

        /**
         * When the response from the api arrives, go to the mapping stage
         *
         * @param {Number} importId
         * @returns {void}
         */
        vm.goToMap = function (importId) {

            return $state.go('settings.importMap', { id: importId });
        };

        /**
         * Upload the file via the import service
         *
         * @param {Object} [file]
         * @param {Array} [invalidFiles]
         * @param {String} [type]
         * @returns {void}
         */
        vm.upload = function (file, invalidFiles, type) {

            $scope.$apply(function () {

                vm.showDragOverlay = false;
            });

            if (!file || (invalidFiles && invalidFiles.length > 0)) {
                return utils.showErrorToast('Please upload a valid .csv file.');
            }

            return imports.upload(type || $state.current.name.split('.')[2], file).then(function (importId) {

                return vm.goToMap(importId);
            });
        };

        vm.back = function () {

            $state.go('settings');
        };

        vm.drag = function (isDragging) {

            $scope.$apply(function () {

                vm.showDragOverlay = isDragging;
            });
        };
    }
})();
