(function () {
    'use strict';

    angular
        .module('salesflare.components.reportEdit', [])
        .component('sfReportEdit', {
            templateUrl: 'app-ajs/components/dashboards/reportEdit/reportEdit.html',
            controller,
            controllerAs: 'vm'
        });

    function controller($scope, $document, $state, $stateParams, $mdDialog, $mdMedia, $timeout, sfReportFilterDialog, filterService, humanReadableAdvancedFilterService, dashboardsService, reportsService, reportService, sfDiscardDialog, sfMoveReportDialog, utils, model, flagsService, sfUpgradeDialog) {

        const vm = this;
        const reportTypeDimensionsMemory = {};

        vm.$mdMedia = $mdMedia;
        vm.creatingNewReport = $state.current.name === 'createReport';
        const duplicatingReport = $state.params && $state.params.reportId && vm.creatingNewReport;

        vm.entities = ['account', 'contact', 'opportunity', 'task'];
        vm.entityTypeNameMap = {
            account: 'Accounts',
            contact: 'Contacts',
            opportunity: 'Opportunities',
            task: 'Tasks'
        };
        vm.entityTypeFilterGroupMap = {
            account: 'Account',
            contact: 'Contact',
            opportunity: 'Opportunity',
            task: 'Task'
        };
        vm.filterTimeOnEntityDefaultMap = {
            account: 'account.creation_date',
            contact: 'contact.creation_date',
            opportunity: 'opportunity.close_date',
            task: 'task.completion_date'
        };

        vm.noDashboardFilterTitleSubString = ' (dashboard time filter not applied)';

        vm.badFilterError = null;

        vm.$onInit = function () {

            if (!['Admin', 'Manager'].includes(model.me.role.name)) {
                return $state.go('dashboards');
            }

            if (!vm.creatingNewReport || duplicatingReport) {
                const reportId = $stateParams.id || $state.params.reportId;
                return reportService.get(reportId).then(function (response) {

                    vm.report = response.data;
                    vm.entity = vm.report.entity;

                    if (duplicatingReport) {
                        vm.report.title = vm.report.title + ' (Copy)';
                    }

                    $document[0].title = ('Edit report ' + vm.report.title + ' | Salesflare');
                    setReportFilterText();

                    // Get filter fields
                    return filterService.loadFilterFields(vm.entity, { types: ['date', 'datetime'], allPipelines: true, includePipelineSpecificPredefinedFilterFields: false }).then(function (fields) {

                        vm.filterTimeOnFields = fields.filter(function (field) {

                            return field.id.includes(vm.entity);
                        });
                    });
                });
            }

            $document[0].title = 'Create report | Salesflare';

            vm.entity = 'opportunity';

            // Get filter fields
            return filterService.loadFilterFields('opportunity', { types: ['date', 'datetime'], allPipelines: true, includePipelineSpecificPredefinedFilterFields: false }).then(function (fields) {

                vm.filterTimeOnFields = fields.filter(function (field) {

                    return field.id.includes('opportunity');
                });

                const filterTimeOn = vm.filterTimeOnFields.find(function (field) {

                    return field.id === vm.filterTimeOnEntityDefaultMap.opportunity;
                });

                vm.report = {
                    title: 'New report',
                    entity: 'opportunity',
                    chart_type: 'column',
                    filter_time_on: filterTimeOn.id,
                    filter: {
                        condition: 'AND',
                        rules: []
                    },
                    include_unspecified_view_by_data: false,
                    include_unspecified_segment_by_data: false,
                    no_dashboard_time_filter: false,
                    group_and_return_other_segment_data: true,
                    stack_chart: true
                };

                setReportFilterText();

                if ($stateParams.dashboardId) {
                    vm.report.dashboard = $stateParams.dashboardId;
                    return;
                }

                return dashboardsService.get().then(function (response) {

                    vm.report.dashboard = response.data[0].id;
                });
            });
        };

        vm.onEntityChanged = function () {

            return $timeout(function () {

                const entityToChangeTo = vm.entity;
                vm.entity = vm.report.entity; // Switch back to old entity, only switch after confirming

                const confirm = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title('Are you sure?')
                    .textContent('Changing the entity will reset the whole report.')
                    .ok('I\'m sure')
                    .cancel('cancel');

                return $mdDialog.show(confirm).then(function () {

                    vm.report.entity = entityToChangeTo;
                    vm.entity = entityToChangeTo;

                    vm.report.view_by = null;
                    vm.report.measure_by = null;
                    vm.report.segment_by = null;

                    $scope.$broadcast('resetFilterPane', true);

                    return filterService.loadFilterFields(entityToChangeTo, {
                        types: ['date', 'datetime'],
                        allPipelines: true
                    }).then(function (fields) {

                        vm.filterTimeOnFields = fields.filter(function (field) {

                            return field.id.includes(entityToChangeTo);
                        });

                        const filterTimeOn = vm.filterTimeOnFields.find(function (field) {

                            return field.id === vm.filterTimeOnEntityDefaultMap[vm.report.entity];
                        });
                        vm.report.filter_time_on = filterTimeOn.id;
                    });
                });
            });
        };

        vm.showReportFilterDialog = function () {

            const locals = {
                entity: vm.report.entity,
                filterRules: (vm.report.filter && vm.report.filter.rules) || [],
                group: vm.entityTypeFilterGroupMap[vm.report.entity],
                filterFields: vm.filterFields,
                filterHeaderText: ('Report on ' + vm.entityTypeNameMap[vm.report.entity].toLowerCase() + ' matching the filters')
            };

            return sfReportFilterDialog.show(locals).then(function (filter) {

                vm.report.filter = filter;
                setReportFilterText();
            });
        };

        vm.onDashboardFilterChanged = function ($event) {

            vm.dashboardFilter = angular.copy($event.filter);
        };

        vm.onChartConfigChanged = function ($event) {

            // Keep track of dimensions selected previously for all types.
            // By default some dimensions are getting null-ed as they aren't being used by all types.
            // To make sure the user doesn't have to set everything again on type change we remember it for them here.
            if (vm.report.chart_type !== $event.chartConfig.chart_type) {
                reportTypeDimensionsMemory[vm.report.chart_type] = {
                    segment_by: vm.report.segment_by,
                    segment_by_date_period: vm.report.segment_by_date_period,
                    segment_by_limit: vm.report.segment_by_limit,
                    view_by: vm.report.view_by,
                    view_by_date_period: vm.report.view_by_date_period,
                    view_by_limit: vm.report.view_by_limit,
                    measure_by: vm.report.measure_by
                };

                if (reportTypeDimensionsMemory[$event.chartConfig.chart_type]) {
                    // Merge in the memorized dimensions when the new config has them as `null`.
                    // We can't use the regular merge here as it would always overwrite regardless if a dimension changed.
                    // For example if I select a new view by and move to another type, I want the new view by not the old one.
                    // But when I move to a scorecard and then come back to a bar, I want my original view by back.
                    utils.shallowMergeIfNull($event.chartConfig, reportTypeDimensionsMemory[$event.chartConfig.chart_type]);
                }
            }
            // Segment by is a bit special as you can unset it (for table, line, bar and column charts), which means that when that happens we also need to unset it in the memory
            else if ($event.chartConfig.segment_by === null && ['line', 'bar', 'column', 'table'].includes($event.chartConfig.chart_type)) {
                const types = Object.keys(reportTypeDimensionsMemory);
                types.forEach(function (type) {

                    if (['line', 'bar', 'column', 'table'].includes(type)) {
                        reportTypeDimensionsMemory[type].segment_by = null;
                        reportTypeDimensionsMemory[type].segment_by_date_period = null;
                        reportTypeDimensionsMemory[type].segment_by_limit = null;
                    }
                });
            }

            angular.merge(vm.report, $event.chartConfig);
        };


        vm.onReportFilterChanged = function ($event) {

            // Re assign the whole filter as to trigger the `onChanges` of the chartBuilder
            vm.report.filter = {
                condition: 'AND',
                rules: $event.rules
            };
            setReportFilterText();
        };

        function setReportFilterText() {

            if (!vm.report.filter || !vm.report.filter.rules || vm.report.filter.rules.length === 0) {
                vm.reportFilterText = ('Choose the ' + vm.entityTypeNameMap[vm.entity].toLowerCase() + ' to report on');
                return;
            }

            return humanReadableAdvancedFilterService.getHumanReadableAdvancedFilter(vm.report.filter, vm.report.entity).then(function (filterText) {

                vm.reportFilterText = filterText;
            });
        }

        vm.getFilterHeaderText = function () {

            if (!vm.report || !vm.report.entity) {
                return;
            }

            return 'Report on ' + vm.entityTypeNameMap[vm.report.entity].toLowerCase() + '  matching the filters';
        };

        vm.move = function () {

            const options = {
                reportId: vm.report.id,
                shouldShowInfoText: true,
                // If we don't have an id, so creating, we don't want the dialog to do the update we just adjust the dashboard in memory and create it on the chosen one.
                preventUpdate: !vm.report.id
            };

            return sfMoveReportDialog.show(options).then(function (dashboardId) {

                vm.report.dashboard = dashboardId;
            });
        };

        vm.delete = function () {

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .title('Are you sure?')
                .textContent('Are you sure you want to delete this report?')
                .ok('I\'m sure')
                .cancel('Cancel');

            return $mdDialog.show(confirm).then(function () {

                return reportService.delete(vm.report.id).then(function () {

                    utils.showSuccessToast('Report successfully deleted');
                    return $state.go('dashboards.dashboard', { id: vm.report.dashboard });
                });
            });
        };

        vm.back = function () {

            if (!vm.reportConfigForm.$dirty) {
                return $state.go('dashboards.dashboard', { id: vm.report.dashboard });
            }

            return sfDiscardDialog.show(vm.creatingNewReport, 'report').then(function () {

                return $state.go('dashboards.dashboard', { id: vm.report.dashboard });
            });
        };

        vm.save = function () {

            if (!hasFlagEnabled()) {
                return sfUpgradeDialog.show('customDashboards');
            }

            if (vm.reportConfigForm.$invalid) {
                return;
            }

            if (vm.creatingNewReport) {
                return reportsService.create(getPayload(vm.report)).then(function () {

                    utils.showSuccessToast('Report created');
                    return $state.go('dashboards.dashboard', { id: vm.report.dashboard });
                });
            }

            return reportService.update(vm.report.id, getPayload(vm.report)).then(function () {

                utils.showSuccessToast('Report saved');
                return $state.go('dashboards.dashboard', { id: vm.report.dashboard });
            });
        };

        vm.showPlanFlags = function () {

            const onTrial = model.me.team.payment_type !== 'free' && !model.me.team.subscribed && model.me.trial_expiry_date;
            return (onTrial || !hasFlagEnabled());
        };

        ////////////////////////

        function getPayload(report) {

            return {
                title: report.title,
                description: report.description,
                dashboard: report.dashboard,
                entity: report.entity,
                filter: report.filter,
                chart_type: report.chart_type,
                segment_by: report.segment_by,
                segment_by_date_period: report.segment_by_date_period,
                segment_by_limit: report.segment_by_limit,
                view_by: report.view_by,
                view_by_date_period: report.view_by_date_period,
                view_by_limit: report.view_by_limit,
                measure_by: report.measure_by,
                measure_by_aggregate_function: report.measure_by_aggregate_function,
                filter_time_on: report.filter_time_on,
                compare_to_previous_period: report.chart_type === 'scorecard' ? report.compare_to_previous_period : undefined,
                include_unspecified_view_by_data: report.include_unspecified_view_by_data,
                include_unspecified_segment_by_data: report.include_unspecified_segment_by_data,
                no_dashboard_time_filter: report.no_dashboard_time_filter,
                group_and_return_other_segment_data: report.group_and_return_other_segment_data,
                stack_chart: ['bar', 'column'].includes(report.chart_type) ? report.stack_chart : undefined
            };
        }

        function hasFlagEnabled() {

            return (model.me.plan_flags.customDashboards || flagsService.get('customDashboards'));
        }

        vm.handleFilterError = ($event) => {

            vm.badFilterError = $event.message;
        };
    }
})();
