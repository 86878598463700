(function () {
    'use strict';

    angular
        .module('salesflare.components.deals', [])
        .component('sfDeals', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/deals/deals.html'
        });

    function controller($mdMedia, $state, $stateParams, config, model, menu, billing, authentication) {

        const vm = this;
        vm.menu = menu;
        vm.name = $stateParams.name;
        vm.team = model.me.team;
        vm.units = 0;
        vm.$mdMedia = $mdMedia;

        vm.$onInit = function () {

            vm.state = $state;
            vm.handler = StripeCheckout.configure({
                key: config.stripe.key,
                name: 'Salesflare',
                email: model.me.email,
                image: 'images/fav/favicon-192x192.png',
                locale: 'auto',
                token: function (token) {

                    return billing.createDeal({
                        name: vm.name,
                        token: token.id,
                        units: vm.units
                    }).then(function () {

                        return authentication.fetchMe().then(function () {

                            return $state.reload();
                        });
                    });
                }
            });
        };

        vm.checkOut = function (units) {

            vm.units = units;
            vm.handler.open({
                description: units + ' LTD user' + (units > 1 ? 's' : '') + ' at $69/user',
                zipCode: true,
                amount: units * 69 * 100
            });
        };

        vm.hasDeal = function () {

            return vm.name === 'appsumo-ltd' && (vm.team.total_user_count === 1 || vm.team.free_users > 0) && vm.team.discount_type === 'firstFreeThen50Off' && (new Date(vm.team.creation_date) < new Date('2018-10-30')) && (new Date() < new Date(new Date('2018-11-10').setUTCHours(0, 0, 0, 0)));
        };
    }
})();
