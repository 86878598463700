(function () {
    'use strict';

    angular
        .module('salesflare.components.largerprospectfollowup.talkToUsInfo', [])
        .component('sfTalkToUsInfo', {
            templateUrl: 'app-ajs/components/largerprospectfollowup/talkToUsInfo/talkToUsInfo.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                previousCrm: '<',
                onBookCall: '&',
                onDismiss: '&'
            }
        });

    function controller($mdDialog, $mdMedia) {

        const vm = this;

        vm.$mdMedia = $mdMedia;

        vm.$onInit = () => {

            vm.previousCrmString = getPreviousCrmString(vm.previousCrm);
        };

        vm.dismiss = () => {

            if (vm.onDismiss) {
                return vm.onDismiss();
            }
        };

        vm.bookCall = (durationInMin) => {

            if (vm.onBookCall) {
                return vm.onBookCall({
                    $event: {
                        durationInMin
                    }
                });
            }
        };

        function getPreviousCrmString(previousCrm) {

            let string;

            switch (previousCrm) {
                case 'Spreadsheet':
                    string = 'move your data over from your spreadsheet';
                    break;
                case 'Other':
                case 'Nothing':
                    string = 'import your data';
                    break;
                default:
                    string = `move your data over from ${previousCrm}`;
                    break;
            }

            return string;
        }
    }
})();
