<!--
We only render the toolbar if there is a title or if there is no close button defined.
-->
<ng-container *ngIf="data.title || !data.close">
    <ng-container *ngIf="theme === 'default'">
        <h2 mat-dialog-title data-layout-align="space-between center">
        <span>
            <ng-container *ngIf="data.title">
                {{ data.title }}
            </ng-container>
        </span>
            <mat-icon *ngIf="!data.close && media.isActive('gt-sm')" class="toolbar-close-action" track-id="close-tag" fontSet="salesflare" fontIcon="sf-icon-close" (click)="onCloseButton()"></mat-icon>
        </h2>
    </ng-container>
    <ng-container *ngIf="theme === 'blue'">
        <sfx-toolbar dialogToolbar [disableBack]="!!data.close" (closeEvent)="onCloseButton()">
            <ng-container *ngIf="data.title">
                {{ data.title }}
            </ng-container>
        </sfx-toolbar>
    </ng-container>
</ng-container>
<mat-dialog-content [style]="contentStyle">
    <p *ngIf="data.body">
        {{ data.body }}
    </p>
    <div *ngIf="data.htmlBody" [innerHTML]="data.htmlBody">

    </div>
    <ng-container *ngIf="data.content">
        <ng-container #content>

        </ng-container>
    </ng-container>
</mat-dialog-content>
<!-- We only render the dialog actions when there is a close or confirm button defined -->
<ng-container *ngIf="theme === 'default' && (data.close || data.confirm)">
    <mat-dialog-actions align="end">
        <button *ngIf="data.close" mat-button (click)="onCloseButton()"> {{ data.close }}</button>
        <button mat-button (click)="onConfirmButton()" [disabled]="!Valid"> {{ data.confirm }} </button>
    </mat-dialog-actions>
</ng-container>
<ng-container *ngIf="theme === 'blue' && (data.close || data.confirm)">
    <mat-dialog-actions align="end" class="primary">
        <button *ngIf="data.close" mat-button (click)="onCloseButton()"> {{ data.close }}</button>
        <button mat-button (click)="onConfirmButton()" [disabled]="!Valid"> {{ data.confirm }} </button>
    </mat-dialog-actions>
</ng-container>
