import { Component, Input } from '@angular/core';

import { MatProgressBarModule } from '@angular/material/progress-bar';

type Indicator = {
    strengthThreshold: number,
    theme: 'primary' | 'accent' | 'warn',
    label: string
}

@Component({
    standalone: true,
    selector: 'sfx-password-meter',
    templateUrl: './password-meter.component.html',
    imports: [
        MatProgressBarModule
    ],
    styleUrls: ['./password-meter.component.scss']
})
export class PasswordMeterComponent {

    private _strength = 0;
    private _theme = 'warn';
    private _colorLabel = '';

    private _showError = true;

    private _indicators: Indicator[] = [
        {
            strengthThreshold: 0,
            theme: 'warn',
            label: 'Very Weak'
        },
        {
            strengthThreshold: 25,
            theme: 'warn',
            label: 'Weak'
        },
        {
            strengthThreshold: 50,
            theme: 'accent',
            label: 'Moderate'
        },
        {
            strengthThreshold: 75,
            theme: 'primary',
            label: 'Strong'
        },
        {
            strengthThreshold: 100,
            theme: 'primary',
            label: 'Very Strong'
        }
    ];
    private _currentIndicator: Indicator = this._indicators[0];

    @Input()
    get showError(): boolean {
        return this._showError;
    }
    set showError(value: boolean | string) {
        this._showError = typeof value === 'boolean' ? value : !!(value === '' || value);
    }

    @Input()
    get indicators(): Indicator[] {
        return this._indicators;
    }
    set indicators(value: Indicator[] | string) {
        // Ignore - angularJS error, not angular2.
        // eslint-disable-next-line angular/typecheck-string
        this._indicators = typeof value === 'string' ? this._indicators : value;

        this._indicators = this._indicators.sort((a, b) => (a.strengthThreshold > b.strengthThreshold ? -1 : 1));
    }

    @Input()
    get strength(): number {
        return this._strength;
    }
    set strength(value: number | string) {
        // Ignore - angularJS error, not angular2.
        // eslint-disable-next-line angular/typecheck-number
        this._strength = typeof value === 'number' ? value : Number(value);
        this._currentIndicator = this._findCurrentIndicator();
    }

    private _findCurrentIndicator(): Indicator {
        for (const indicator of this._indicators) {
            if (indicator.strengthThreshold <= this._strength) {
                return indicator;
            }
        }

        return this._currentIndicator;
    }

    get Theme(): 'primary' | 'accent' | 'warn' {
        return this._currentIndicator.theme;
    }

    get Label(): string {
        return this._currentIndicator.label;
    }

    constructor() {
        this._indicators = this._indicators.sort((a, b) => (a.strengthThreshold > b.strengthThreshold ? -1 : 1));
    }
}
