<sfx-navigation-toolbar (closeEvent)="close()">
    <div navigationToolbarTitle>
        Import data
    </div>
    <a navigationToolbarButton icon="sf-icon-accounts" ref=".account" aria-label="account">
        Account
    </a>
    <a navigationToolbarButton icon="sf-icon-contact" ref=".contact" aria-label="contacts">
        Contact
    </a>
    <a navigationToolbarButton icon="sf-icon-opportunities" ref=".opportunity" aria-label="opportunity">
        Opportunity
    </a>
</sfx-navigation-toolbar>

<sfx-content>
    <sfx-section disableContentPadding sfxDragDrop accept=".csv" (onDrop)="handleFileDrop($event)" (onDragLeave)="handleDragLeave()" (onDragOver)="handleDragOver()">
        <ui-view class="panel-view">

        </ui-view>
        <div [ngClass]="{ 'drag-drop' : isDraggingFile }"></div>
    </sfx-section>
</sfx-content>

<sfx-speed-dial class="fab-dial" aria-label="connect-email">
    <button
            speedDialAction icon="sf-icon-contact" label="Import contacts" track-id="import-contacts" action-type="file" action-accept=".csv" [action]="handleButtonFileUpload.bind(this, 'contact')"
    >
    </button>
    <button
            speedDialAction icon="sf-icon-accounts" label="Import accounts" track-id="import-accounts" action-type="file" action-accept=".csv" [action]="handleButtonFileUpload.bind(this, 'account')"
    >
    </button>
    <button
            speedDialAction icon="sf-icon-opportunities" label="Import opportunities" track-id="import-opportunities" action-type="file" action-accept=".csv" [action]="handleButtonFileUpload.bind(this, 'opportunity')"
    >
    </button>
</sfx-speed-dial>
