(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('customfields', customFieldsService);

    function customFieldsService($q, $timeout, sfSetupPanel, sfHttp, config, Upload, model) {

        this.get = function (id, itemClass) {

            switch (itemClass) {
                case 'opportunity':
                    return sfHttp.get(config.apiUrl + 'customfields/opportunities/' + id);
                case 'account':
                    return sfHttp.get(config.apiUrl + 'customfields/accounts/' + id);
                case 'contact':
                    return sfHttp.get(config.apiUrl + 'customfields/contacts/' + id);
            }
        };

        this.getFields = function (itemClass, includeDisabled, pipeline, name) {

            switch (itemClass) {
                case 'opportunity':
                    return this.getOpportunityFields(includeDisabled, pipeline, name);
                case 'account':
                    return this.getAccountFields(includeDisabled, name);
                case 'contact':
                    return this.getContactFields(includeDisabled, name);
            }
        };

        this.getOpportunityFields = function (includeDisabled, pipeline, name) {

            let url = config.apiUrl + 'customfields/opportunities?includeDisabled=' + includeDisabled;

            if (pipeline) {
                url += '&pipeline=' + pipeline;
            }

            if (name) {
                url += '&name=' + name;
            }

            return sfHttp.get(url);
        };

        this.getAccountFields = function (includeDisabled, name) {

            let url = config.apiUrl + 'customfields/accounts?includeDisabled=' + includeDisabled;

            if (name) {
                url += '&name=' + name;
            }

            return sfHttp.get(url);
        };

        this.getContactFields = function (includeDisabled, name) {

            let url = config.apiUrl + 'customfields/contacts?includeDisabled=' + includeDisabled;

            if (name) {
                url += '&name=' + name;
            }

            return sfHttp.get(url);
        };

        this.getCustomFieldTypes = function () {

            return sfHttp.get(config.apiUrl + 'customfields/types');
        };

        this.createOpportunityField = function (field) {

            return sfHttp.post(config.apiUrl + 'customfields/opportunities', field).then(function (response) {

                if (model.me && model.me.team.subscribed) {
                    $timeout(sfSetupPanel.updateSetupSteps, 300);
                }

                return $q.resolve(response);
            });
        };

        this.createAccountField = function (field) {

            return sfHttp.post(config.apiUrl + 'customfields/accounts', field).then(function (response) {

                if (model.me && model.me.team.subscribed) {
                    $timeout(sfSetupPanel.updateSetupSteps, 300);
                }

                return $q.resolve(response);
            });
        };

        this.createContactField = function (field) {

            return sfHttp.post(config.apiUrl + 'customfields/contacts', field).then(function (response) {

                if (model.me && model.me.team.subscribed) {
                    $timeout(sfSetupPanel.updateSetupSteps, 300);
                }

                return $q.resolve(response);
            });
        };

        this.updateOpportunityField = function (field) {
            return sfHttp.put(config.apiUrl + 'customfields/opportunities/' + field.id, field);
        };

        this.updateAccountField = function (field) {
            return sfHttp.put(config.apiUrl + 'customfields/accounts/' + field.id, field);
        };

        this.updateContactField = function (field) {
            return sfHttp.put(config.apiUrl + 'customfields/contacts/' + field.id, field);
        };

        this.updateAccountOrder = function (customFields) {
            return sfHttp.put(config.apiUrl + 'customfields/accounts/order', customFields);
        };

        this.updateContactOrder = function (customFields) {
            return sfHttp.put(config.apiUrl + 'customfields/contacts/order', customFields);
        };

        this.updateOpportunityOrder = function (customFields) {
            return sfHttp.put(config.apiUrl + 'customfields/opportunities/order', customFields);
        };

        this.delete = function (id, itemClass) {

            switch (itemClass) {
                case 'opportunity':
                    return sfHttp.delete(config.apiUrl + 'customfields/opportunities/' + id);
                case 'account':
                    return sfHttp.delete(config.apiUrl + 'customfields/accounts/' + id);
                case 'contact':
                    return sfHttp.delete(config.apiUrl + 'customfields/contacts/' + id);
            }
        };

        this.getOptions = function (customFieldName, itemclass, searchString) {

            const request = {
                params: {
                    searchString
                }
            };

            let apiItemClass = itemclass;
            switch (itemclass) {
                case 'opportunity':
                    apiItemClass = 'opportunities';
                    break;
                case 'account':
                    apiItemClass = 'accounts';
                    break;
                case 'contact':
                    apiItemClass = 'contacts';
                    break;
            }

            return sfHttp.get(config.apiUrl + 'customfields/' + apiItemClass + '/' + encodeURIComponent(customFieldName) + '/options', request);
        };

        this.getAllOptions = function (customFieldId, itemClass) {

            const request = {
                params: {
                    itemClass
                }
            };

            return sfHttp.get(config.apiUrl + 'customfields/' + customFieldId + '/options', request);
        };

        this.uploadFile = function (file, callback) {

            const creationUrl = config.apiUrl + 'customfields/files';
            const json = {
                fileName: file.name,
                fileType: file.type
            };

            return sfHttp.post(creationUrl, json).then(function (response) {

                const fileId = response.data.id;

                return Upload.upload({
                    url: config.apiUrl + 'files/' + fileId + '/upload?file_type=' + file.type,
                    data: {
                        file
                    }
                }).then(function (reply) {

                    if (reply.status !== 200) {
                        const deleteUrl = config.apiUrl + 'files/' + fileId + '/delete';
                        return sfHttp.delete(deleteUrl).then(function () {

                            return callback();
                        });
                    }

                    const newFile = {
                        file_id: fileId,
                        name: file.name,
                        file_type: file.type
                    };

                    return callback(newFile);
                });
            });
        };
    }
})();
