(function () {
    'use strict';

    angular
        .module('salesflare.components.templates.templateSelection', [])
        .component('sfTemplateSelection', {
            templateUrl: 'app-ajs/components/templates/templateSelection/templateSelection.html',
            controller: 'TemplateSelectionController',
            controllerAs: 'vm'
        });
})();
