(function () {
    'use strict';

    /**
     * @typedef {Object} QuickActionObject
     * @property {{ isDisabled: Boolean?, toolTip: Object? }} [config]
     */

    angular
        .module('salesflare.components.bulkToolbar', [])
        .component('sfBulkToolbar', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/bulktoolbar/bulktoolbar.html',
            bindings: {
                quickActions: '<',
                menuItems: '<',
                allSelected: '<',
                // Hooks
                onSelectAll: '&'
            }
        });

    function controller() {

        const vm = this;

        vm.$onInit = function () {

            vm.allSelected = vm.allSelected || false;
        };

        vm.selectAll = function () {

            return vm.onSelectAll({
                $event: {
                    allSelected: vm.allSelected
                }
            });
        };

        /**
         * Returns false by default, if available, execute the action's isDisabled function
         *
         * @param {QuickActionObject} action
         * @returns {Boolean}
         */
        vm.isDisabled = function (action) {

            if (action.config && action.config.isDisabled) {
                return action.config.isDisabled();
            }

            return false;
        };

        /**
         * Checks if a tool tip is available and return it's content if needed
         *
         * @param {QuickActionObject} action
         * @returns {String}
         */
        vm.getToolTipContent = function (action) {

            if (action.config && action.config.toolTip) {

                return action.config.toolTip.content ? action.config.toolTip.content() : '';
            }

            return '';
        };

        vm.handleQuickActionClick = ($event, handler) => {

            $event.stopPropagation();

            handler();
        };
    }
})();
