(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EmailComposeDialogController', EmailComposeDialogController);

    function EmailComposeDialogController($rootScope, $scope, $stateParams, $mdDialog, $mdMedia, $mdToast, email, sfDiscardDialog, utils, fileService, events, draftsService) {

        $scope.$mdMedia = $mdMedia;
        $scope.filters = angular.copy($stateParams.filter); // Always expects the UI Filter object here
        $scope.showAutomaticSwitch = $stateParams.showAutomaticSwitch;
        $scope.bulk = $stateParams.bulk;
        $scope.dialogTitle = $stateParams.dialogTitle;
        $scope.message = {
            to: angular.copy($stateParams.to),
            cc: angular.copy($stateParams.cc),
            body: angular.copy($stateParams.body),
            subject: angular.copy($stateParams.subject),
            attachments: angular.copy($stateParams.attachments),
            thread_id: $stateParams.threadId,
            in_reply_to: $stateParams.inReplyTo,
            includeUnsubscribeLink: false
        };

        // Handle array of emails that should be used as the sending email address, if included in the available aliases
        if ($stateParams.potentialSenderEmails) {
            $scope.message.potentialSenderEmails = $stateParams.potentialSenderEmails;
        }

        $scope.type = $stateParams.type;

        let isEmailFormDirty = false;
        let isEmailFormValid = false;
        let messageObject = {};
        let senderDataSource = {};

        $scope.sendEmail = function () {

            if (!isEmailFormValid) {
                return;
            }

            const multipleRecipients = messageObject.to && messageObject.to.length > 1;
            const bodyContainsMergeFields = /{{ [a-zA-Z_]* | fallback="(.*)" }}/.test(messageObject.body);

            if (bodyContainsMergeFields && multipleRecipients) {
                const confirm = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .title('Warning before sending')
                    .textContent('When using merge fields in an email to multiple recipients, the fallback option will be used.')
                    .ok('Send')
                    .cancel('Cancel');

                return $mdDialog.show(confirm).then(sendMail, angular.noop);
            }

            return sendMail();
        };

        function sendMail() {

            deleteFiles(messageObject);

            return email.showConfigureSMTPSettingsDialogIfNeeded(senderDataSource).then(close).then(function () {

                utils.showInfoToast('Sending message...', null, false);

                if (messageObject.attachments) {
                    messageObject.attachments = messageObject.attachments.map(function (attachment) {

                        const formattedMessage = {
                            file_name: attachment.file_name,
                            file_url: attachment.file_url
                        };

                        if (attachment.file_type) {
                            formattedMessage.file_type = attachment.file_type;
                        }

                        if (attachment.file_size) {
                            formattedMessage.file_size = attachment.file_size;
                        }

                        return formattedMessage;
                    });
                }

                return email.create(messageObject).then(function () {

                    return $mdToast.hide().then(function () {

                        utils.showSuccessToast('Message sent.');

                        draftsService.delete(messageObject.to.map((toRecipient) => toRecipient.email));

                        return $rootScope.$broadcast(events.account.updated);
                    });
                });
            });
        }

        $scope.onEmailFormDirtyChanged = function ($event) {

            isEmailFormDirty = $event.$dirty;
        };

        $scope.onEmailFormValidityChanged = function ($event) {

            isEmailFormValid = $event.$valid;
        };

        $scope.onEmailDataChanged = function ($event) {

            $scope.filterText = $event.filterText;
            messageObject = $event.messageObject;
            senderDataSource = $event.senderDataSource;

            if (messageObject && messageObject.attachments) {
                $scope.updating = !!messageObject.attachments.some(function (file) {

                    return !file.id;
                });
            }
            else {
                $scope.updating = false;
            }
        };

        $scope.back = function (reload) {

            if (!isEmailFormDirty && !messageObject.body) {
                discardUploadedFiles(messageObject);

                return close(reload);
            }

            return sfDiscardDialog.show(true, 'email message').then(function () {

                draftsService.delete(messageObject.to.map((toRecipient) => toRecipient.email));

                discardUploadedFiles(messageObject);

                return close(reload);
            });
        };

        function close(reload) {

            if ($mdMedia('gt-sm')) {
                return $mdDialog.hide(reload);
            }

            return $rootScope.back();
        }

        // When closing a message draft without saving delete the uploaded files
        function discardUploadedFiles(message) {

            if (message.attachments) {
                message.attachments.forEach(function (file) {

                    const boundMessageFileIds = $scope.message && $scope.message.attachments && $scope.message.attachments.map(function (attachment) {

                        return attachment.id;
                    });

                    // Do not remove files that were already there before binding it to the compose window
                    if (!boundMessageFileIds || !boundMessageFileIds.includes(file.id)) {
                        fileService.deleteFile(file.id);
                    }
                });
            }
        }

        function deleteFiles(message) {

            if (!message.filesToDelete) {
                return;
            }

            message.filesToDelete.forEach(function (fileId) {

                fileService.deleteFile(fileId);
            });

            delete message.filesToDelete;
        }
    }
})();

