(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditGroupDialogController', EditGroupDialogController);

    function EditGroupDialogController($scope, $mdDialog, model, sfUpgradeDialog) {

        $scope.showPlanFlags = function () {

            const onTrial = model.me.team.payment_type !== 'free' && !model.me.team.subscribed && model.me.trial_expiry_date;
            return (onTrial || !model.me.plan_flags.permissions);
        };

        $scope.canAccessPermissions = function () {

            return model.me.plan_flags.permissions;
        };

        $scope.group = angular.copy(this.group) || {
            pipelines: angular.copy(this.pipelines)
        };
        $scope.pipelines = angular.copy(this.pipelines);

        $scope.save = function () {

            if ($scope.groupForm.$valid) {
                return $mdDialog.hide($scope.group);
            }
        };

        $scope.changePipelinesSelected = function (group) {

            if (!model.me.plan_flags.permissions) {
                if (group.pipelines) {

                    group.pipelines = angular.copy(this.pipelines);
                }

                sfUpgradeDialog.show('permissions');
                return $mdDialog.hide();
            }
        };

        $scope.getPipelinesSelectedText = function () {

            if (!$scope.group.pipelines || $scope.group.pipelines.length === 0) {
                return 'Pipeline access';
            }

            if ($scope.group.pipelines.length === $scope.pipelines.length) {
                return 'All pipelines';
            }

            return $scope.group.pipelines.map(function (group) {

                return group.name;
            }).join(', ');
        };
    }
})();
