(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('task', taskService);

    function taskService(sfHttp, config) {

        this.update = function update(id, updateObj) {

            const url = config.apiUrl + 'tasks/' + id;
            const request = updateObj;

            return sfHttp.put(url, request);
        };

        this.archive = function archive(id) {

            const url = config.apiUrl + 'tasks/' + id;

            return sfHttp.delete(url);
        };
    }
})();
