(function () {
    'use strict';

    angular
        .module('salesflare')
        .filter('thousandSuffix', thousandSuffix);

    function thousandSuffix($filter) {

        /*
         * 0.11 or 1.23 (2 decimals)
         * 10+: 11.2 (1 decimal)
         * 100+: 123 or 2789 (0 decimals)
         * 10k+: 10.2k (1 decimal)
         * 100k+: 123k (0 decimals)
         * 1M+: 1.23M (2 decimals)
         * 10M+: 67.2M (1 decimal)
         * 100M+: 124M (0 decimals)
         * ...repeat
         */
        return function (input) {

            if (Number.isNaN(input)) {
                return input;
            }

            const absInput = Math.abs(input);
            let decimals = 0;

            if (absInput < 10000) {
                if (absInput % 1 === 0) {
                    return $filter('currency')(input, '', 0);
                }

                if (absInput < 10) {
                    decimals = 2;
                }
                else if (absInput < 100) {
                    decimals = 1;
                }
                else {
                    decimals = 0;
                }

                return $filter('currency')(input, '', decimals);
            }

            const suffixes = ['k', 'M', 'B', 'T', 'qd', 'Qn', 'sx'];

            const exp = Math.floor(Math.log(absInput) / Math.log(1000));

            if (absInput < 100000) {
                decimals = 1;
            }
            else if (absInput < 1000000) {
                decimals = 0;
            }
            else {
                const suffixedNumber = input / Math.pow(1000, exp);
                const suffixedNumberAmountOfDigitsBeforeDecimal = suffixedNumber.toString().split('.')[0].length;
                decimals = 3 - suffixedNumberAmountOfDigitsBeforeDecimal;
            }

            const result = (input / Math.pow(1000, exp)).toFixed(decimals);

            if (decimals === 0) {
                return result + suffixes[exp - 1];
            }

            const resultDecimals = result.split('.')[1];

            if ((decimals === 1 && resultDecimals === '0') || (decimals === 2 && resultDecimals === '00')) {
                return result.split('.')[0] + suffixes[exp - 1];
            }

            return result + suffixes[exp - 1];
        };
    }
})();
