import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MediaService } from '@core/services/media.service';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { BillingOverviewModel } from '@shared/models/billing.model';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule
    ],
    selector: 'sfx-pricing-switch',
    templateUrl: './pricing-switch.component.html',
    styleUrls: ['./pricing-switch.component.scss']
})
export class PricingSwitchComponent implements OnInit {

    @Input() options: BillingOverviewModel;

    @Input() selectedValue: string;
    @Output() selectedValueChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() editPlan: boolean;
    @Input() fromBilling?: boolean;

    constructor(
        public media: MediaService
    ) {}

    public ngOnInit(): void {}

    public setValue(value: string): void {

        this.selectedValue = value;
        this.selectedValueChange.emit(value);
    }
}
