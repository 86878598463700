(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('companies', companiesService);

    function companiesService(sfHttp, $exceptionHandler, utils) {

        this.get = function (search) {

            search = search.slice(0, 29);

            const url = 'https://autocomplete.clearbit.com/v1/companies/suggest';
            const request = {
                cache: false,
                ignoreLoadingBar: true,
                params: {
                    query: search === '' ? undefined : search
                },
                withCredentials: false,
                noToast: true // We don't want to show clearbit errors to the user
            };

            return sfHttp.get(url, request)
                .then((response) => {

                    // For some reason the api started to return dupes so we unique the list based on domain here.
                    // See https://github.com/Salesflare/Server/issues/8015.
                    response.data = utils.unique(response.data, 'domain');

                    return response;
                })
                .catch(function (err) {

                    // We do want to log these errors as we do not log them server side (since not our server) like on our api
                    const error = new Error('Clearbit /companies called failed');
                    error.original = err;
                    $exceptionHandler(error);
                });
        };
    }
})();
