(function () {
    'use strict';

    angular
        .module('salesflare.components.socialProfiles', [])
        .component('sfSocialProfiles', {
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/socialprofiles/socialprofiles.html',
            bindings: {
                socialProfiles: '<',
                shortTabs: '<'
            }
        });
}());
