(function () {
    'use strict';

    angular
        .module('salesflare.components.customfieldsDisplay', [])
        .component('sfCustomfieldsDisplay', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/customfields/customfieldsdisplay.html',
            bindings: {
                entity: '<',
                itemClass: '<',
                disableEditButton: '<',
                disabledEditButtonTooltipText: '<',
                hideHeader: '<',
                showFieldsIcon: '<',
                onEdit: '&'
            }
        });

    function controller($document, $mdMedia, $state, customfields) {

        const vm = this;

        vm.$onChanges = function (changes) {

            if (vm.entity && changes.entity !== vm.entity) {
                get();
            }
        };

        vm.changeExtended = function (customField) {

            customField.extended = !customField.extended;
        };

        vm.edit = function () {

            return vm.onEdit();
        };

        vm.goToEntity = (type, entity) => {

            let state;
            const stateParams = {
                id: entity.id
            };

            switch (type) {
                case 'account':
                case 'accounts':
                    stateParams.name = entity.name;
                    if ($mdMedia('gt-sm')) {
                        state = 'accounts.account.feed';
                    }
                    else {
                        state = 'accounts.account.info';
                    }

                    break;
                case 'contact':
                case 'contacts':
                    state = 'contact';
                    break;
                case 'user':
                case 'users':
                    state = 'user';
                    break;
            }

            return $state.go(state, stateParams);
        };

        function get() {

            let pipeline;
            if (vm.itemClass === 'opportunity') {
                if (!vm.entity.stage || !vm.entity.stage.pipeline) {
                    return;
                }

                pipeline = vm.entity.stage.pipeline;
            }

            return customfields.getFields(vm.itemClass, false, pipeline).then(function (response) {

                vm.customFields = response.data;

                if (vm.showFieldsIcon) {
                    let firstFound = false;

                    vm.customFields.forEach(function (customField) {

                        if (firstFound) {
                            return;
                        }

                        if (angular.isDefined(vm.entity.custom?.[customField.api_field]) && vm.entity.custom?.[customField.api_field] !== null && vm.entity.custom?.[customField.api_field] !== '') {
                            if ((customField.type.type === 'tags' || customField.type.type === 'multiselect') && vm.entity.custom[customField.api_field].length > 0) {
                                customField.first = true;
                                firstFound = true;
                            }
                            else if (customField.type.type !== 'tags' && customField.type.type !== 'multiselect') {
                                customField.first = true;
                                firstFound = true;
                            }
                        }
                    });
                }

                setShowCustomFieldsHeader();
                setCustomfieldDisplayOptions();
            });
        }

        function setShowCustomFieldsHeader() {

            vm.showCustomFieldsHeader = false;

            vm.entity.custom = vm.entity.custom || {};

            vm.showCustomFieldsHeader = !!vm.customFields.some(function (customField) {

                const entityValue = customField.predefined_customfield ? vm.entity[customField.api_field] : vm.entity.custom[customField.api_field];

                if (angular.isDefined(entityValue) && entityValue !== null && entityValue !== '') {
                    return !(angular.isArray(entityValue) && entityValue.length === 0);
                }

                return false;
            });
        }

        function setCustomfieldDisplayOptions() {

            vm.customFields.forEach(function (customField) {

                if (customField.predefined_customfield) {
                    vm.entity.custom[customField.api_field] = vm.entity[customField.api_field];
                }

                if ((customField.type.type === 'tags' || customField.type.type === 'multiselect') && vm.entity.custom[customField.api_field]) {
                    vm.entity.custom[customField.api_field].title = vm.entity.custom[customField.api_field].map(function (value) {

                        return value.name;
                    }).join(', ');
                }

                // Check if large text fields need the ellipsis class,
                // if so make sure its collapsed first
                if (customField.type.type === 'large_text') {
                    if (!vm.entity.custom[customField.api_field]) {
                        return;
                    }

                    const longTextValueLength = vm.entity.custom[customField.api_field].length;
                    let element;
                    try {
                        // Custom field names with special characters might make this error
                        element = $document[0].querySelector('#' + customField.name);
                    }
                    catch {
                        // Do nothing and use the fallback
                    }

                    if (element) {
                        // This is the precise answer we're looking for
                        customField.extendable = element.parentElement.scrollWidth > element.scrollWidth;
                    }
                    else if (longTextValueLength > 27) {
                        // When the above method fails, this is the fallback check
                        customField.extendable = true;
                    }
                    else {
                        customField.extendable = false;
                    }
                }
            });
        }
    }
}());
