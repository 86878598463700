<form #recoveryCodeForm="ngForm" id="recoveryCodeForm" name="recoveryCodeForm">
    <sfx-toolbar [dialogToolbar]="true" (closeEvent)="close()">Recovery code</sfx-toolbar>
    <div mat-dialog-content>
        <p>If you are unable to access your phone, enter one of your recovery codes to verify your identity.</p>
        <mat-form-field>
            <mat-label>Recovery code</mat-label>
            <input #codeInput="ngModel" required matInput name="recoveryCode" [(ngModel)]="recoveryCode">
            <mat-error *ngIf="codeInput.hasError('required')">
                Recovery code is required
            </mat-error>
            <mat-error *ngIf="codeInput.hasError('invalidRecoveryCode')">
                The recovery code was incorrect
            </mat-error>
        </mat-form-field>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button type="submit" (click)="verify()">Verify</button>
    </div>
</form>
