(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditApiKeyDialogController', EditApiKeyDialogController);

    function EditApiKeyDialogController($scope, $mdDialog, key) {

        if (!key) {
            $scope.isNew = true;
        }

        $scope.key = key;

        $scope.save = function () {

            if (!$scope.form.$valid) {
                return;
            }

            return $mdDialog.hide($scope.key);
        };
    }
})();
