(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('UserBenefitsDialogController', UserBenefitsDialogController);

    function UserBenefitsDialogController($scope, $mdDialog, model) {


        $scope.model = model;
        $scope.benefits = null;
        let activeIndex = 0;

        $scope.benefits = [
            { id: 'work-less', src: 'images/work_less.gif', title: 'Work less', caption: 'Salesflare does the data input for you, so you can focus on what matters.' },
            { id: 'control', src: 'images/control.gif', title: 'Be in control', caption: 'We bring overview and help you to follow up at the right time. And you rock.' },
            { id: 'sell-smart', src: 'images/sell_smart.gif', title: 'Sell smart', caption: 'Salesflare shows you how you are doing and how to improve.' }
        ];

        $scope.benefits[activeIndex].active = true;

        $scope.nextBenefit = function () {

            if (activeIndex === $scope.benefits.length - 1) {
                return $mdDialog.hide();
            }

            $scope.benefits[activeIndex].active = false;
            ++activeIndex;
            $scope.benefits[activeIndex].active = true;

            return refreshImage();
        };

        $scope.previousBenefit = function () {

            $scope.benefits[activeIndex].active = false;
            --activeIndex;
            $scope.benefits[activeIndex].active = true;

            return refreshImage();
        };

        function refreshImage() {

            // Reset the src with jquery so browser will replay
            angular.element('.benefit-image').attr('src', $scope.benefits[activeIndex].src);
        }
    }
})();
