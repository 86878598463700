import { Component } from '@angular/core';

@Component({
    selector: 'sfx-manage-outlook-add-in-guide-content',
    template: `
        <div>
            <p class="underline bold">1. Install the Salesflare Outlook add-in for Windows, Mac and on the Web</p>
            <p>If you're a Microsoft 365 Admin, then <a href="https://appsource.microsoft.com/en-us/product/office/WA200006789" target="_blank">click here to install</a> the Salesflare Outlook add-in through Microsoft AppSource. This allows you to install the add-in for multiple users at once.</p>
            <p>If you're not a Microsoft 365 Admin, then you can click the Apps icon (a rounded square containing four little squares) in Outlook's left navigation (on Outlook for Mac it is located behind the 3 dots in the top ribbon instead), search for "Salesflare" and select it, and then click "Add".</p>
            <p>This will install the Salesflare Outlook add-in for a specific mailbox. The add-in will then at once be
                accessible for that mailbox on Outlook for Windows, Outlook for Mac and Outlook on the Web.</p>
            <p>For more details and screenshots, <a href="https://howto.salesflare.com/en/articles/8926469-how-do-i-install-and-set-up-the-outlook-add-in#h_3c11ed4c8a" target="_blank">check out this detailed guide</a>.
            <p class="underline bold">2. How to find and pin the Salesflare Outlook add-in</p>
            <p>Follow these 4 steps (for more details, <a href="https://howto.salesflare.com/en/articles/8926469-how-do-i-install-and-set-up-the-outlook-add-in#h_49fbec1df8" target="_blank">check out our article with screenshots</a>):</p>
            <ol>
                <li>First, click on an email in your Outlook email inbox.</li>
                <li>
                    Then, click the Salesflare icon in the email application to open the sidebar. Note that it may take
                    a few minutes to sync and appear. Where you can find the icon is dependent on the platform:
                    <ul>
                        <li><b>New Outlook for Windows</b>: behind the <b>...</b> menu icon at the top right of the email.</li>
                        <li><b>Old Outlook for Windows & Outlook for Mac</b>: in the ribbon at the top of Outlook (it can be hidden behind a menu at the right of the ribbon).</li>
                        <li><b>Outlook on the Web</b>: behind the <b>Apps</b> icon next to the <b>...</b> icon at the top right of the email.</li>
                    </ul>
                </li>
                <li>In the dropdown, click <b>Salesflare sidebar</b> to start showing the sidebar.</li>
                <li>At the top right of the sidebar, click the 📌 pin icon. The sidebar will now stay open and show
                    relevant Salesflare context as you work through your mailbox.
                </li>
            </ol>
        </div>
    `
})
export class OutlookAddInGuideDialogContentComponent {}
