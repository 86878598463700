import { Component } from '@angular/core';
import { DialogContent } from '@shared/components/dialog/dialog.component';

export interface MergeTagsContentData {
    mergeToTag: any;
    mergeOptions: any[];
}

@Component({
    selector: 'sfx-manage-tags-merge-tags-content',
    template: `
        <p>
            Choose which tag to keep.
        </p>
        <span>
            <mat-form-field class="select-tags">
                <mat-label>Tag to keep</mat-label>
                <mat-select [(ngModel)]="mergeToTag">
                    <mat-option *ngFor="let tag of mergeOptions" [value]="tag">
                        {{ tag.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </span>
    `,
    styles: ['.mat-form-field { width: 100% }; .select-tags { min-width: 300px }']
})
export class MergeTagsContentComponent implements DialogContent<MergeTagsContentData> {

    public mergeToTag: any = null;
    public mergeOptions: any[] = [];

    public valid: true;

    setInitialState(context: MergeTagsContentData) {

        this.mergeOptions = context.mergeOptions;
        this.mergeToTag = context.mergeToTag;
    }

    getCurrentState(): MergeTagsContentData {

        return {
            mergeToTag: this.mergeToTag,
            mergeOptions: this.mergeOptions
        };
    }
}
