import { NgModule } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { BrowserModule } from '@angular/platform-browser';

import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { AppComponent } from './app.component';

import { LoginModule } from '@feature/login/login.module';
import { SettingsModule } from '@feature/settings/settings.module';

import { CoreModule } from '@core/core.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        CoreModule.forRoot(),
        UpgradeModule,
        UIRouterUpgradeModule,
        LoginModule,
        SettingsModule
    ]
})
export class AppModule {
    ngDoBootstrap = () => {};
}
