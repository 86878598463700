(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('CreditPackageConfirmationDialogController', creditPackageConfirmationDialogController);

    function creditPackageConfirmationDialogController($mdDialog) {

        const vm = this;

        init();

        vm.confirm = function () {

            return $mdDialog.hide(true);
        };

        vm.close = function () {

            return $mdDialog.cancel();
        };

        function init() {

            vm.upgrading = vm.subscribed && (vm.currentPlan.amount < vm.futurePlan.amount);
        }
    }
})();
