(function () {
    'use strict';

    angular
        .module('salesflare.components.statusBar', [])
        .component('sfStatusBar', {
            controller: sfStatusBarController,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/statusbar/statusbar.html',
            bindings: {}
        });

    function sfStatusBarController($rootScope, $scope, $element, $timeout) {

        const vm = this;
        vm.hide = true;

        // Make sure we start hidden
        // We can't rely on ng-hide since it involves animations and we basically want to force hide it until we're ready
        $element.hide();

        const props = ['type', 'hide', 'text', 'linkText', 'linkTrackId', 'canClose', 'showSpinner', 'class', 'onLinkClick', 'onClose'];
        $scope.$on('sfStatusBar:set', function (ev, update) {

            Object.keys(update).forEach(function (updateProperty) {

                if (props.includes(updateProperty)) {
                    vm[updateProperty] = update[updateProperty];
                }
                else {
                    throw new Error(updateProperty + ' is not an allowed property of the status bar scope. Use one of ' + props.join(', ') + '.');
                }
            });
        });

        $scope.$on('sfStatusBar:reset', function () {

            props.forEach(function (prop) {

                delete vm[prop];
            });
        });

        vm.$onInit = function () {

            // Only start showing and broadcast ready after 3 seconds
            // This gives the rest of the app time to render and catches the attention of the user more
            // Tech wise this also helps u prevent showing a white bar on load due to easein animation we have on the bar
            return $timeout(function () {

                $element.show();

                // Let the service now we are ready so it can start sending its queued updates
                $rootScope.$broadcast('sfStatusBar:ready', true);
            }, 3 * 1000);
        };
    }
})();
