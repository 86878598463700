(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('CompanySocialFeedController', companySocialFeedController);

    function companySocialFeedController($rootScope, $scope, $stateParams) {
        $scope.id = $stateParams.id;

        if ($rootScope.history[$rootScope.history.length - 1].fromState.name !== 'accounts') {
            $rootScope.history.pop();
        }
    }
})();
