(function () {
    'use strict';

    angular
        .module('salesflare.components.customfields', [])
        .component('sfCustomfields', {
            templateUrl: 'app-ajs/components/customfields/customfields.html',
            controllerAs: 'vm',
            controller,
            bindings: {
                customfields: '<',
                entityForm: '<',
                entity: '<',
                bulkMode: '<',
                onFilesChange: '&',
                onFilesDelete: '&',
                onNewOptionCreation: '&'
            }
        });

    function controller($mdDialog, $timeout, $q, model) {

        const vm = this;
        vm.isAdmin = model.me.is_admin;

        vm.deleteFile = function ($event) {

            return vm.onFilesDelete({
                $event
            });
        };
    }
})();
