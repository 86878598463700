(function () {
    'use strict';

    angular
        .module('salesflare.components.setup', [])
        .component('sfSetup', {
            templateUrl: 'app-ajs/components/setup/setupPanelTemplate.html',
            controller: 'SetupController',
            controllerAs: 'vm'
        });
})();
