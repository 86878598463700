(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EmailSettingsPromptDialogController', EmailSettingsPromptDialogController);

    function EmailSettingsPromptDialogController($scope, $mdDialog, config, model, utils) {

        $scope.save = function () {

            if (!$scope.form.$valid) {
                return;
            }

            let url = config.apiUrl + 'datasources/connect?email=' + $scope.email;

            if (model.authStrategy === 'bearer') {
                url += '&access_token=' + model.getToken();
            }

            return utils.popup(url).then(function (queryParams) {

                if (queryParams && queryParams.get('success') !== 'true') {
                    utils.showErrorToast(queryParams.get('error'));
                }

                return $mdDialog.hide();
            });
        };
    }
})();
