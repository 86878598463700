(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('OnlineOfflineDialogController', OnlineOfflineDialogController);

    function OnlineOfflineDialogController($scope, $mdDialog, $timeout, $document, authentication) {

        $scope.retry = function () {

            return authentication.fetchMe().then($mdDialog.hide).catch(angular.noop);
        };

        poll(1 * 1000);

        // Make sure splashscreen is gone on mobile boot with no connection, otherwise you see the splashscreen forever
        $timeout(function () {

            $document[0].addEventListener('deviceready', function () {

                return navigator.splashscreen.hide();
            }, false);
        }, 0);


        function poll(retryTime) {

            return $timeout(function () {

                authentication.fetchMe().then($mdDialog.hide).catch(angular.noop);

                if (retryTime > 60 * 1000) {
                    return;
                }

                retryTime *= 2;

                return poll(retryTime);
            }, retryTime);
        }
    }
})();
