(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('AccountInfoController', AccountInfoController);

    function AccountInfoController($rootScope, $scope, $stateParams, $state, $mdDialog, $mdMedia, $timeout, $mdSidenav, $mdBottomSheet, events, account, utils, customfields, network, sfWalkthrough, email, helperFunctionsService, opportunityService, model) {

        $scope.id = $stateParams.id;
        $scope.type = $state.current.data.type;
        $scope.editable = true;
        $scope.showDetails = sfWalkthrough.isShowing() || !!store.get('show_account_details');
        $scope.showDisabledUsers = !!store.get('show_disabled_users');
        $scope.hasMoreSuggestions = false;
        $scope.hasMoreNetwork = false;
        $scope.hasDoneOpportunities = false;
        $scope.me = model.me;
        $scope.insufficientPermissionsMessage = 'You don\'t have permission to edit this account. Ask an admin.';
        $scope.descriptionExtendable = false;
        $scope.descriptionExtended = false;

        // These are contacts suggested by the LinkedIn sidebar or existing contacts related to a LinkedIn company
        let contacts = $stateParams.contacts ? angular.fromJson($stateParams.contacts) : null;

        // Must be the same as default_Limit on account entity in server
        const networkLimit = 21;

        // Must be the same as suggestionLimit on account entity in server
        const suggestionLimit = 21;

        // Check if the description is extendable when the description's element is loaded and its height is known
        $scope.$on('$viewContentLoaded', function () {

            $timeout(() => {

                isDescriptionExtendable();
            }, 0);
        });

        if ($scope.account) {
            prepareSuggestions();
            showInfo();
        }
        else {
            $scope.$on(events.account.loaded, function (event, loadedAccount) {

                $scope.account = loadedAccount;

                if ($scope.showDoneOpportunities) {
                    $scope.opportunities = $scope.account.opportunities;
                }
                else {
                    $scope.opportunities = $scope.account.opportunities.filter(function (opp) {

                        return !opp.done;
                    });
                }

                $scope.hasDoneOpportunities =  $scope.account.opportunities.some(function (opp) {

                    return opp.done;
                });

                prepareSuggestions();

                if ($scope.account.custom && Object.keys($scope.account.custom).length > 0) {
                    loadCustomFields();
                }

                checkIfCustomFieldsAreEmpty();

                return network.get({ account: $scope.account_id }).then(prepareNetwork, angular.noop, prepareNetwork);
            });
        }

        if ($state.current.name === 'accounts.account.info' && $rootScope.history[$rootScope.history.length - 1].fromState.name.startsWith('accounts.')) {
            $rootScope.history.pop();
        }

        $scope.$on(events.refreshData, function () {

            return $scope.get();
        });

        $scope.$on(events.account.updated, function () {

            return $scope.get();
        });

        $scope.edit = function () {

            return $state.go('editAccount', angular.merge({}, $stateParams, { id: $scope.account.id }));
        };

        $scope.close = function () {

            return $mdSidenav('right').close();
        };

        $scope.accountTeamIncludesDisabledUsers = function () {

            return !!$scope.account?.users?.find((u) => u.disabled);
        };

        $scope.accountTeamOnlyIncludesDisabledUsers = function () {

            return $scope.account?.users?.every((u) => u.disabled);
        };

        $scope.acceptSuggestion = function (contact, $event) {

            $event.stopPropagation();

            if (sfWalkthrough.isShowing()) {
                sfWalkthrough.acceptDummyContactSuggestion(contact.name);
                return;
            }

            contact._dirty = true;

            return account.acceptSuggestion($scope.id, contact).then(function () {

                if ($scope.account.customers.length === 0 && $scope.account.opportunities.length === 0) {
                    // Set part_of to true so calls for which you need to be part of account also get called
                    $scope.account.part_of = true;

                    return $mdDialog.show({
                        clickOutsideToClose: true,
                        controller: 'OpportunityDialogController',
                        templateUrl: 'partials/opportunitydialog.html',
                        locals: {
                            accountId: $scope.id
                        },
                        onComplete: function (scope, element) {

                            return element['0'].querySelectorAll('INPUT')[0].focus();
                        }
                    }).finally(function () {

                        return $rootScope.$broadcast(events.account.updated);
                    });
                }

                return $rootScope.$broadcast(events.account.updated);
            });
        };

        $scope.acceptAllSuggestions = function () {

            if ($scope.hasMoreSuggestions) {
                const confirm = $mdDialog.confirm()
                    .clickOutsideToClose(true)
                    .htmlContent('Are you sure you want to add 20+ contacts to this account?<br>This may slow down the account timeline significantly.')
                    .ok('Yes')
                    .cancel('No');

                return $mdDialog.show(confirm).then(function () {

                    return account.acceptAllSuggestions($scope.id).then(function () {

                        return $rootScope.$broadcast(events.account.updated);
                    });
                });
            }

            return account.acceptAllSuggestions($scope.id).then(function () {

                return $rootScope.$broadcast(events.account.updated);
            });
        };

        $scope.rejectSuggestion = function (contact, $event) {

            $event.stopPropagation();

            if (sfWalkthrough.isShowing()) {
                return $mdDialog.show({
                    controller: 'CantDoDialogController',
                    controllerAs: 'vm',
                    templateUrl: 'app-ajs/components/cantDoDialog/cantDoDialog.html',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: {
                        title: 'That\'s not the checkmark 😉'
                    }
                });
            }

            return account.rejectSuggestion($scope.id, contact).then(function () {

                account.getSuggestions($scope.id, null, null, suggestionLimit + 1).then(function (response) {

                    $scope.account.suggestions = response.data;

                    return prepareSuggestions();
                });

                account.getContacts($scope.id, { includeArchived: true }).then(function (response) {

                    $scope.account.customers = response.data;
                });
            });
        };

        $scope.createSuggestion = (personObject, $event) => {

            $event.stopPropagation();

            if (sfWalkthrough.isShowing()) {
                return $mdDialog.show({
                    controller: 'CantDoDialogController',
                    controllerAs: 'vm',
                    templateUrl: 'app-ajs/components/cantDoDialog/cantDoDialog.html',
                    bindToController: true,
                    clickOutsideToClose: false
                });
            }

            const cleanedPersonObject = { ...personObject };
            delete cleanedPersonObject.creatable;
            delete cleanedPersonObject.role;

            return $state.go('createContact', {
                personObject: cleanedPersonObject,
                account: $scope.account,
                isLinkedInSuggestion: true,
                from: 'accounts.account.info'
            });
        };

        $scope.createOpportunity = function (accountId) {

            sfWalkthrough.advance();

            return $state.go('opportunity', { id: 'new', account: accountId });
        };

        $scope.toggleDoneOpportunities = function () {

            $scope.showDoneOpportunities = !$scope.showDoneOpportunities;

            if ($scope.showDoneOpportunities) {
                $scope.opportunities = $scope.account.opportunities;
            }
            else {
                $scope.opportunities = $scope.account.opportunities.filter(function (opp) {

                    return !opp.done;
                });
            }
        };

        $scope.setDone = function (opportunity) {


            opportunity.done = !opportunity.done;

            return opportunityService.update(opportunity).then(function () {

                if (opportunity.done) {
                    utils.showSuccessToast('Marked as done! You can still search or filter to find it back.');
                }

                $scope.hasDoneOpportunities = $scope.account.opportunities.some(function (opp) {

                    return opp.done;
                });

                if ($scope.showDoneOpportunities) {
                    $scope.opportunities = $scope.account.opportunities;
                }
                else {
                    $scope.opportunities = $scope.account.opportunities.filter(function (opp) {

                        return !opp.done;
                    });
                }

            }).catch(function () {

                return $scope.get();
            });
        };

        $scope.goToContact = function (contactId) {

            if (sfWalkthrough.isShowing()) {
                return;
            }

            return $state.go('contact', { id: contactId });
        };

        $scope.mailto = email.mailto;
        $scope.openBottomSheet = function (text) {

            $mdBottomSheet.show({
                templateUrl: 'partials/listbottomsheet.html',
                controller: 'ListBottomSheetController',
                bindToController: true,
                locals: {
                    actions: ['COPY', 'MAILTO'],
                    text
                }
            });
        };

        $scope.openWebsite = helperFunctionsService.openWebsite;
        $scope.callNumber = helperFunctionsService.callNumber;
        $scope.callPerson = function (person, $event) {

            return helperFunctionsService.callPerson(person, $event).then(function () {

                return $rootScope.$broadcast(events.account.updated);
            });
        };

        $scope.hideElement = sfWalkthrough.hideElement;

        $scope.isWalkthroughShowing = sfWalkthrough.isShowing;

        $scope.getWalkthroughCurrentStepId = sfWalkthrough.getCurrentStepId;

        $scope.toggleDescriptionExtended = () => {

            $scope.descriptionExtended = !$scope.descriptionExtended;
        };

        function showInfo() {

            if ($scope.showDoneOpportunities) {
                $scope.opportunities = $scope.account.opportunities;
            }
            else {
                $scope.opportunities = $scope.account.opportunities.filter(function (opp) {

                    return !opp.done;
                });
            }

            $scope.hasDoneOpportunities =  $scope.account.opportunities.some(function (opp) {

                return opp.done;
            });

            // Do not show on fullscreen since empty state gives info
            if ($scope.account && $scope.account.customers.length === 0 && !$mdMedia('gt-sm')) {
                const key = 'INFO_ACCEPT_SUGGESTION';

                if ((store.get(key) || 0) < 3) {
                    const acceptSuggestionTimer = $timeout(function () {

                        return utils.showInfoToast('Start by accepting a contact suggestion.', 'GOT IT').then(function (response) {

                            if (response === 'GOT IT') {
                                store.set(key, (store.get(key) || 0) + 1);
                            }
                        });
                    }, 1000);

                    // Prevent info to show when not on account info screen anymore
                    $scope.$on('$destroy', function scopeDestroyAcceptSuggestionTimer() {

                        return $timeout.cancel(acceptSuggestionTimer);
                    });
                }
            }
        }

        function prepareSuggestions() {

            if (contacts?.length > 0) {
                const suggestionIds = new Set($scope.account.suggestions.map((suggestion) => suggestion.id));

                // Only add contacts that aren't already in the suggestions or a part of the account
                // Update the contacts variable with the filtered array, so that contacts don't get added again after rejecting
                contacts = contacts.filter((contact) => !suggestionIds.has(contact.id) && !$scope.account.customers.some((customer) => customer.id === contact.id));
                $scope.account.suggestions.unshift(...contacts.map((contact) => {

                    if (contact.positions?.length > 0) {
                        contact.role = contact.positions[0].role;
                    }

                    contact.creatable = contact.id ? false : true;
                    return contact;
                }));
                $scope.suggestionsIncludeLinkedInContacts = $scope.account.suggestions.some((suggestion) => suggestion.creatable);
                $scope.suggestionsOnlyIncludeLinkedInContacts = $scope.account.suggestions.every((suggestion) => suggestion.creatable);
            }

            if ($scope.account.suggestions.length >= suggestionLimit) {
                $scope.hasMoreSuggestions = true;
                $scope.account.suggestions.pop();
            }
        }

        function isDescriptionExtendable() {

            if ($scope.account?.description) {
                const longTextValueLength = $scope.account.description.length;
                let element;
                try {
                    element = angular.element('.sf-account-description')[0];
                }
                catch {
                    // Do nothing and use the fallback
                }

                if (element?.scrollHeight && element?.clientHeight) {
                    // When the scrollHeight is larger than the actual height of the element, it means there is overflowing content, so the description is extendable
                    $scope.descriptionExtendable = element.scrollHeight > element.clientHeight;
                }
                // Fallback is not ideal, since new line characters might surpass 5 lines
                else if (longTextValueLength > 170) {
                    $scope.descriptionExtendable = true;
                }
                else {
                    $scope.descriptionExtendable = false;
                }
            }
        }

        function prepareNetwork(response) {

            $scope.account.network = response.data;

            if ($scope.account.network && $scope.account.network.length >= networkLimit) {
                $scope.hasMoreNetwork = true;
                $scope.account.network.pop();
            }
        }

        function loadCustomFields() {

            return customfields.getAccountFields(false).then(function (response) {

                $scope.customFields = response.data;

                let firstFound = false;

                $scope.customFields.forEach(function (customField) {

                    if (!firstFound && angular.isDefined($scope.account.custom[customField.api_field]) && $scope.account.custom[customField.api_field] !== null && $scope.account.custom[customField.api_field] !== '') {
                        if ((customField.type.type !== 'tags' && customField.type.type !== 'select') || $scope.account.custom[customField.api_field].length === 0) {
                            customField.first = true;
                            firstFound = true;
                        }
                    }

                    if (customField.predefined_customfield) {
                        $scope.account.custom[customField.api_field] = $scope.account[customField.api_field];
                    }

                    if (['tags', 'select'].includes(customField.type.type) && $scope.account.custom[customField.api_field] && angular.isArray($scope.account.custom[customField.api_field]) ) {
                        $scope.account.custom[customField.api_field].title = $scope.account.custom[customField.api_field].map(function (value) {

                            return value.name;
                        }).join(', ');
                    }

                    // Check if large text fields need the ellipsis class,
                    // if so make sure its collapsed first
                    // We can't use the element attributes here (PersonController)
                    // Since the field doesn't have attributes before Show More is clicked
                    if (customField.type.type === 'large_text') {
                        const longTextValueLength = $scope.account.custom[customField.api_field] ? $scope.account.custom[customField.api_field].length : 0;

                        if (longTextValueLength > 27) {
                            // When the above method fails, this is the fallback check
                            customField.extendable = true;
                        }
                        else {
                            customField.extendable = false;
                        }

                        customField.extended = false;
                    }
                });
            });
        }

        $scope.changeExtended = function (customField) {

            customField.extended = !customField.extended;
        };

        /**
         * @param {Boolean} show
         */
        $scope.setShowDetails = function (show) {

            $scope.showDetails = show;
            store.set('show_account_details', show);
        };

        /**
         * @param {Boolean} show
         */
        $scope.setShowDisabledUsers = function (show) {

            $scope.showDisabledUsers = show;
            store.set('show_disabled_users', show);
        };

        function checkIfCustomFieldsAreEmpty() {

            $scope.showCustomFieldsHeader = false;

            if ($scope.account.custom && Object.keys($scope.account.custom).length > 0) {
                for (const key in $scope.account.custom) {
                    if ($scope.account.custom[key] !== null && $scope.account.custom[key] !== '') {
                        if (angular.isArray($scope.account.custom[key]) && $scope.account.custom[key].length === 0) {
                            continue;
                        }

                        $scope.showCustomFieldsHeader = true;

                        break;
                    }
                }
            }
        }
    }
})();
