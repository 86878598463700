(function () {
    'use strict';

    angular
        .module('salesflare.components.workflowUtils.exitWorkflowRecordButton', [])
        .component('sfExitWorkflowRecordButton', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/workflows/workflow/exitWorkflowRecordButton/exitWorkflowRecordButton.html',
            bindings: {
                recordId: '<',
                exitDate: '<',
                workflowId: '<',
                recordType: '<',
                showCompact: '<',
                onExit: '<',
                onReEnter: '<'
            }
        });

    function controller($mdDialog, $mdMenu, utils, workflowService) {

        const vm = this;

        vm.recordTypeNameMap = {
            contact: {
                lowerCased: 'contact',
                properCased: 'Contact'
            }
        };
        vm.buttonTypes = {
            EXIT: 'EXIT',
            ENTER: 'ENTER'
        };

        vm.$onInit = () => {

            vm.recordType = vm.recordType || 'contact';
        };

        vm.getTooltipText = (buttonType) => {

            switch (buttonType) {
                case vm.buttonTypes.EXIT:
                    if (vm.exitDate) {
                        return `${vm.recordTypeNameMap[vm.recordType].properCased} has already exited the workflow.`;
                    }
                    else {
                        return `Exit ${vm.recordTypeNameMap[vm.recordType].lowerCased} manually. No future workflow steps will happen for this contact (no emails will be sent).`;
                    }

                case vm.buttonTypes.ENTER:
                    return `Re-enter the ${vm.recordTypeNameMap[vm.recordType].properCased} in the workflow. Future workflow steps will be sent.`;
                default:
                    throw new Error('Invalid button type');
            }
        };

        vm.exitRecord = (metGoal) => {

            const update = {
                exited: true
            };

            if (metGoal) {
                update.met_goal = true;
            }

            return workflowService.updateWorkflowRecord(vm.workflowId, vm.recordId, update).then(() => {

                vm.exitDate = new Date();

                if (vm.onExit) {
                    vm.onExit({
                        workflowId: vm.workflowId,
                        recordId: vm.recordId,
                        exitDate: new Date(),
                        metGoalDate: metGoal ? new Date() : null
                    });
                }

                return utils.showSuccessToast(`${vm.recordTypeNameMap[vm.recordType].properCased} has been exited`);
            });
        };

        vm.reEnterRecord = () => {

            const update = {
                exited: false
            };
            const apiOptions = {
                noToast: true,
                noToastForStatusCode: [422]
            };

            return workflowService.updateWorkflowRecord(vm.workflowId, vm.recordId, update, apiOptions).then(function () {

                vm.exitDate = null;

                if (vm.onReEnter) {
                    vm.onReEnter({
                        workflowId: vm.workflowId,
                        recordId: vm.recordId,
                        exitDate: null,
                        metGoalDate: null
                    });
                }

                return utils.showSuccessToast(`${vm.recordTypeNameMap[vm.recordType].properCased} has been re-entered`);
            }).catch((err) => {

                if (err.status === 422 && err.data.message.includes('can not be re-entered')) {
                    const confirm = $mdDialog.confirm({ multiple: true })
                        .clickOutsideToClose(true)
                        .title('Change workflow setting first')
                        .htmlContent(err.data.message)
                        .ok('Got it');
                    return $mdDialog.show(confirm);
                }
            });
        };
    }
})();
