/* eslint angular/component-limit: ["error", 2] */
(function () {
    'use strict';

    angular
        .module('salesflare')
        .config(function ($mdPanelProvider) {

            $mdPanelProvider.definePreset('dateTimePanel', {
                clickOutsideToClose: true,
                controller: DateTimePanelController,
                controllerAs: 'vm',
                templateUrl: 'app-ajs/components/dateTimePanel/dateTimePanelTemplate.html',
                propagateContainerEvents: true
            });
        });

    angular
        .module('salesflare')
        .controller('DateTimePanelController', DateTimePanelController);

    /**
     * {Date} vm.reminder_date
     * {function} panelRef.onScaled - called when panel is being scaled ergo on mobile
     * {function} panelRef.onHide - called when back button is pressed (can only happen when scaled ergo on mobile)
     *
     * @param {Object} $element
     * @param {Object} $document
     * @param {Object} $window
     * @param {Object} $timeout
     * @param {Object} utils
     * @param {Object} mdPanelRef
     */
    function DateTimePanelController($element, $document, $window, $timeout, utils, mdPanelRef) {

        const vm = this;

        const closestHalfHourDate = utils.getNextHalfHourDateTime();
        const halfHoursOfTheDay = utils.halfHoursOfTheDay.map(function (t) {

            return new Date(new Date().setHours(t[0], t[1], 0, 0));
        });
        const halfHoursOfTheDayAfterNow = halfHoursOfTheDay.filter(function (t) {

            return t >= closestHalfHourDate;
        });
        let timeChanged = false;

        vm.timeContainerClass = 'dateTimePanelTimeContainer';
        vm.showBackButton = false;

        vm.today = new Date();

        vm.halfHoursOfTheDay = utils.isToday(vm.date) ? halfHoursOfTheDayAfterNow : halfHoursOfTheDay;

        if (vm.reminder_date && new Date(vm.reminder_date) >= closestHalfHourDate) {
            vm.date = new Date(vm.reminder_date);
        }
        else {
            vm.date = new Date();
        }

        if (vm.reminder_date) {
            const matchingHalfHour = vm.halfHoursOfTheDay.find(function (halfHourDate) {

                return utils.isSameHour(vm.date, halfHourDate) && utils.isSameMinute(vm.date, halfHourDate);
            });
            vm.time = matchingHalfHour ? matchingHalfHour.getTime() : closestHalfHourDate.getTime();
            timeChanged = true;
        }
        else {
            vm.time = closestHalfHourDate.getTime();
        }

        checkMobileScaling();

        vm.onTimeChanged = function () {

            timeChanged = true;
        };

        vm.onDateChanged = function () {

            if (utils.isToday(vm.date)) {
                vm.halfHoursOfTheDay = halfHoursOfTheDayAfterNow;

                // When a time was selected that is not allowed today reset to nextHalfHour
                if (timeChanged && vm.time < utils.getNextHalfHourDateTime().getTime()) {
                    vm.time = utils.getNextHalfHourDateTime().getTime();
                    timeChanged = false;
                }
            }
            else {
                vm.halfHoursOfTheDay = halfHoursOfTheDay;
            }

            if (!timeChanged) {
                if (utils.isToday(vm.date)) {
                    vm.time = utils.getNextHalfHourDateTime().getTime();
                }
                else {
                    vm.time = new Date(new Date().setHours(9, 0, 0, 0)).getTime();
                }
            }
        };

        /**
         *
         * @param {Date} date
         * @returns {void}
         */
        vm.setReminder = function (date) {

            date.setHours(new Date(vm.time).getHours(), new Date(vm.time).getMinutes(), 0, 0);

            mdPanelRef.config.locals.reminder_date = date;
            mdPanelRef.config.locals.confirmed = true;

            return mdPanelRef.close().then(function () {

                return mdPanelRef.destroy();
            });
        };

        /**
         * We prevent the mouseup event on the time select options to propagate
         * This prevents the event from reaching the panel container
         * and so prevents the panel container from thinking we are clicking outside of the panel
         * which would cause the panel container to close the panel
         */
        vm.onTimeSelectOpen = function () {

            const timeContainer = angular.element('.' + vm.timeContainerClass);
            timeContainer.on('mouseup', function (ev) {

                ev.stopPropagation();
            });
        };

        vm.goBack = function () {

            mdPanelRef.destroy();

            if (mdPanelRef.onHide) {
                mdPanelRef.onHide();
            }
        };

        function checkMobileScaling() {

            // Timeout so we allow the panel to render first
            return $timeout(function () {

                // If we don't remove this function the panel will resize again the moment you start scrolling the md-calendar
                // eslint-disable-next-line no-underscore-dangle
                mdPanelRef.config.position._setPanelPosition = angular.noop;

                /**
                 * These calculations were taken from
                 * https://github.com/angular/material/blob/41c9d00dbb3cd85a31c3a02693854f25b2f7a8df/src/components/datepicker/js/datepickerDirective.js#L681-L760
                 * We do less here since we don't care about a bunch of the other stuff they do
                 *
                 * The md-calendar component is not really made to self embed and so we have to mimic the scaling behaviour the datepicker normally does
                 */

                const fakeThisLeftMargin = 20;
                const calendarPane = $element[0].children[0];
                const body = $document[0].body;

                calendarPane.style.transform = '';

                const elementRect = calendarPane.getBoundingClientRect();
                const bodyRect = body.getBoundingClientRect();

                // Check to see if the calendar pane would go off the screen. If so, adjust position
                // accordingly to keep it within the viewport.
                // If `elementRect.left` is negative negate it
                let paneLeft = Math.max(elementRect.left, 0) - bodyRect.left;

                // If ng-material has disabled body scrolling (for example, if a dialog is open),
                // then it's possible that the already-scrolled body has a negative top/left. In this case,
                // we want to treat the "real" top as (0 - bodyRect.top). In a normal scrolling situation,
                // though, the top of the viewport should just be the body's scroll position.

                const viewportLeft = (bodyRect.left < 0 && body.scrollLeft === 0) ? -bodyRect.left : body.scrollLeft;

                const viewportRight = viewportLeft + $window.innerWidth;

                // Make sure pane doesn't fall off the screen
                if (elementRect.top + elementRect.height > $window.innerHeight) {
                    calendarPane.style.top = $window.innerHeight - elementRect.height + 'px';
                    // eslint-disable-next-line no-underscore-dangle
                    mdPanelRef.config.position._top = calendarPane.style.top;
                }

                // If the right edge of the pane would be off the screen and shifting it left by the
                // difference would not go past the left edge of the screen. If the calendar pane is too
                // big to fit on the screen at all, move it to the left of the screen and scale the entire
                // element down to fit.
                if (paneLeft + elementRect.width > viewportRight) {
                    if (viewportRight - elementRect.width > 0) {
                        paneLeft = viewportRight - elementRect.width;
                    }
                    else {
                        paneLeft = viewportLeft;
                        const scale = $window.innerWidth / elementRect.width;
                        calendarPane.style.transform = 'scale(' + scale + ')';
                        calendarPane.style.left = paneLeft - fakeThisLeftMargin + 'px';

                        // This prevents wrong calculations by angular material
                        // Initially it is 8px but it causes the panel to jitter left and right on scrolling the md-calendar
                        // So we set it to the actual/new left here
                        // eslint-disable-next-line no-underscore-dangle
                        mdPanelRef.config.position._left = calendarPane.style.left;

                        // Trigger onScaled event
                        if (mdPanelRef.onScaled) {
                            mdPanelRef.onScaled();
                        }

                        // Show back button
                        vm.showBackButton = true;
                    }
                }
            }, 10);
        }
    }
})();
