(function () {
    'use strict';

    angular
        .module('salesflare.components.email', [])
        .component('sfEmail', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/info/email.html',
            bindings: {
                email: '<',
                entityName: '<'
            }
        });

    function controller($mdBottomSheet, $mdDialog, sfWalkthrough, email) {

        const vm = this;

        vm.mailto = (emailObject, $event) => {

            if (sfWalkthrough.isShowing()) {
                $mdDialog.show({
                    controller: 'CantDoDialogController',
                    controllerAs: 'vm',
                    templateUrl: 'app-ajs/components/cantDoDialog/cantDoDialog.html',
                    bindToController: true,
                    clickOutsideToClose: true,
                    multiple: true,
                    locals: {
                        htmlContent: 'We love that you try out all possibilities of the app.<br>However, during the walkthrough, let\'s not start emailing yet.'
                    }
                });
                // Stop click on the input field
                $event.preventDefault();
                return;
            }

            return email.mailto(emailObject, $event);
        };

        vm.openBottomSheet = function (text) {

            $mdBottomSheet.show({
                templateUrl: 'partials/listbottomsheet.html',
                controller: 'ListBottomSheetController',
                bindToController: true,
                locals: {
                    actions: ['COPY', 'MAILTO'],
                    text
                }
            });
        };

    }
}());
