import { Component, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'sfx-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements AfterViewInit {

    private avatarSrc: string;

    public avatarStyling: any = {};

    @Input() set src(value: string) {

        this.avatarSrc = value;

        if (this.avatarContainer) {
            this.setStyling(this.avatarSrc, this.avatarEditable);
        }
    }

    get src(): string {

        return this.avatarSrc;
    }

    private avatarEditable: boolean;

    @Input() set editable(value: boolean) {

        this.avatarEditable = value;

        if (this.avatarContainer) {
            this.setStyling(this.avatarSrc, this.avatarEditable);
        }
    }

    get editable(): boolean {

        return this.avatarEditable;
    }

    @ViewChild('avatarContainer') avatarContainer!: ElementRef<HTMLElement>

    constructor() {
        this.avatarSrc = '';
        this.avatarEditable = false;
    }

    ngAfterViewInit(): void {

        this.setStyling(this.avatarSrc, this.avatarEditable);
    }

    private setStyling = (avatarSrc: string, avatarEditable: boolean): void => {

        if (!avatarSrc || avatarSrc === '') {

            this.avatarStyling = {
                'background-image': '',
                'background-color': 'rgba(0, 0, 0, 0.2)'
            };
        }
        else if (avatarEditable) {

            this.avatarStyling = {
                'background': `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${this.avatarSrc}')`,
                'background-repeat': 'repeat-x',
                'background-position': 'center',
                'background-size': 'contain'
            };
        }
        else {

            this.avatarStyling = {
                'background-image': `url('${this.avatarSrc}')`,
                'background-color': ''
            };
        }
    }
}
