(function () {
    'use strict';

    angular
        .module('salesflare.components.topLostReasons', [])
        .component('sfTopLostReasons', {
            templateUrl: 'app-ajs/components/dashboards/predefinedReports/topLostReasons/topLostReasons.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                data: '<'
            }
        });

    function controller() {

        const vm = this;

        ////////////////

        vm.$onChanges = function (changes) {

            if (!changes || !changes.data || !changes.data.currentValue) {
                return;
            }

            // Show empty state
            if (vm.data.top_lost_reasons.length === 0) {
                vm.data.no_data = true;
                vm.data.top_lost_reasons = [
                    { name: 'Price too high', amount: 34 },
                    { name: 'Momentum lost', amount: 19 },
                    { name: 'Lost to competitor', amount: 19 }
                ];
                vm.data.total_lost_count = 72;
            }

            vm.data.maxOpportunitiesPerLostReason = Math.max(...vm.data.top_lost_reasons.map(function (lostReason) {

                return lostReason.amount;
            }));
        };
    }
})();
