<div class="container" [ngClass]="{'bg-gray': subscribeText && !modelService.me.team.subscribed}">

    <div class="accent" [hidden]="!subscribeText">
        <h1 class="accent">{{ subscribeText }}</h1>
        <sfx-plans class="sfx-plans" [hidden]="modelService.me.team.payment_type === 'free'" [fromBilling]="true" data-layout="column">

        </sfx-plans>
    </div>

    <div class="billingInfoText" [hidden]="!(modelService.me.team.payment_type === 'samsung')">
        <p>Your team is currently on the <b>{{ modelService.me.team.plan === 4 ? 'Pro' : 'Growth' }}</b> plan.</p>
        <p class="billingPadding">You can manage your subscription and billing details from <a href="https://www.samsung.com/us/business/account/login/" target="_blank" rel="noopener">your Samsung Business account</a>.</p>
    </div>

    <div class="billingInfoText" [hidden]="!(billingOverview && doneLoading && modelService.me.team.subscribed && modelService.me.team.payment_type !== 'free' && !showPlans)">
        <p class="warning" *ngIf="modelService.me.is_delinquent">We have unsuccessfully attempted to bill you <b>{{ billingOverview?.failed_payment_attempts }}</b> {{ billingOverview?.failed_payment_attempts === 1 ? "time. " : "times. " }}{{ restrictedReason !== "PAYMENT_FAILED" && !showSCAButton ? "The next payment attempt will happen in the next few days." : "" }} {{ showSCAButton ? "The failed payment needs bank authentication." : "Please ensure your card information is valid as soon as possible." }}</p>
        <p *ngIf="!modelService.me.is_delinquent && !billingOverview?.cancel_at_period_end">
            Your team is currently paying <b>{{ billingOverview?.billing_frequency }}</b><span *ngIf="modelService.me.team.plan !== 5"> (<a role="button" (click)="updateBillingFrequency()">update billing frequency</a>)</span>
            <span> on the <b>{{ billingOverview?.plan_name }}</b> plan<span *ngIf="modelService.me.team.plan !== 5"> (<a role="button" (click)="updatePlan()">update plan</a>)</span></span>.
        </p>
        <p *ngIf="!modelService.me.is_delinquent && !billingOverview?.cancel_at_period_end" class="billingPadding">
            Your plan will renew on <b>{{ billingOverview?.subscription_renewal_date }}</b> for
            <b><span dir="ltr" [innerHTML]="billingOverview?.htmlEncodedBillingCurrency"></span>{{ (billingOverview?.billing_amount || 0)|  number:'1.2-2' }}</b><span *ngIf="billingOverview?.billing_currency === 'EUR'"> excl. VAT</span> (If you have a credit this will be used).
        </p>
        <p *ngIf="billingOverview?.cancel_at_period_end">
            Your subscription will cancel on <b>{{ billingOverview?.subscription_renewal_date }}</b>
            <span *ngIf="(billingOverview?.billing_amount || 0) > 0">
                    and you will be charged for <b><span dir="ltr" [innerHTML]="billingOverview?.htmlEncodedBillingCurrency"></span>{{ (billingOverview?.billing_amount || 0) | number: '1.2-2' }}</b>
                </span>.
        </p>
        <p *ngIf="showChargeText" class="billingPadding">You have an outstanding balance of <b><span dir="ltr" [innerHTML]="billingOverview?.htmlEncodedBillingCurrency"></span>{{ (billingOverview?.next_charge?.amount || 0) | number: '1.2-2' }}</b> which will be charged on <b>{{ billingOverview?.next_charge?.date }}</b>. <a uiSref="settings.billingSettings.history">More info in the history.</a></p>
        <p *ngIf="discountText" class="billingDiscountText" [innerHTML]="discountText"></p>

        <p class="billingInfoSubheader">Account status</p>
        <ul class="billingInfoList">
            <li [hidden]="!(billingOverview?.card_brand && billingOverview?.card_last4)">Future charges will be billed to your <b>{{ billingOverview?.card_brand }}</b> card ending in <b>{{ billingOverview?.card_last4 }}</b> (<a role="button" (click)="updateCard()">update card details</a>).</li>
            <li [innerHTML]="payingHtmlText"></li>
            <li>You have used <b><a role="button" (click)="showCreditUsage()">{{ billingOverview?.credit_usage }}</a> of {{ billingOverview?.credit_quota }} email finding credits</b> (<a role="button" uiSref="credits">{{ hasCreditPackage ? 'update credit package' : 'get more credits' }}</a>). Your monthly usage will reset on {{ billingOverview?.credit_reset_date }}.</li>
            <li>
                You currently have a <b>credit of <span dir="ltr" [innerHTML]="billingOverview?.htmlEncodedBillingCurrency"></span>{{ (billingOverview?.billing_credits  || 0) | number:'1.2-2' }}</b> to use against future payments. <a href="https://howto.salesflare.com/salesflare/billing-how-does-salesflare-billing-work" target="_blank" rel="noopener">More info on our billing policy.</a></li>
            <li [hidden]="!(!billingOverview?.card_brand || !billingOverview?.card_last4)">You currently do not have a valid card associated with your account. Please update your card information. (<a role="button" (click)="updateCard()">update card details</a>)</li>
            <li>Billing emails are sent to <b>{{ billingOverview?.billing_email }}</b></li>
        </ul>

        <div class="billingPadding" *ngIf="showSCAButton">
            <p class="billingInfoSubheader">Authentication needed</p>
            <p>Because of European regulation to protect consumers, your bank requires extra authentication on your latest payment.</p>
            <p class="billingPadding_bottom_half">Failure to authenticate your payment within 15 days will result in your account being canceled.</p>
            <button mat-raised-button (click)="authorizePayment()" [disabled]="disableSCAButton">Authenticate payment</button>
        </div>

        <p class="billingPadding">
            Please contact us for <a role="button" (click)="askBillingQuestion()"> billing questions </a> or to <a role="button" (click)="cancelBilling()"> cancel your account. </a>
        </p>
    </div>
</div>
