(function () {
    'use strict';

    angular
        .module('salesflare.components.topLeadSources', [])
        .component('sfTopLeadSources', {
            templateUrl: 'app-ajs/components/dashboards/predefinedReports/topLeadSources/topLeadSources.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                data: '<'
            }
        });

    function controller() {

        const vm = this;

        ////////////////

        vm.$onChanges = function (changes) {

            if (!changes || !changes.data || !changes.data.currentValue) {
                return;
            }

            // Show empty state
            if (vm.data.top_lead_sources.length === 0) {
                vm.data.no_data = true;
                vm.data.top_lead_sources = [
                    { name: 'Email', amount: 34 },
                    { name: 'Customer referral', amount: 19 },
                    { name: 'Partner', amount: 19 }
                ];
                vm.data.total_lead_source_count = 72;
            }

            vm.data.maxOpportunitiesPerLeadSource = Math.max(...vm.data.top_lead_sources.map(function (leadSource) {

                return leadSource.amount;
            }));
        };
    }
})();
