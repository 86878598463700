(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('timeZoneService', timeZoneService);

    function timeZoneService(sfHttp, config) {

        this.get = function get() {

            return sfHttp.get(config.apiUrl + 'timezones');
        };
    }
})();
