(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('SelectAccountDialogController', SelectAccountDialogController);

    function SelectAccountDialogController($scope, $mdDialog, suggestedAccounts) {

        $scope.suggestedAccounts = suggestedAccounts;

        $scope.selectAccount = function (selectedAccount) {

            $mdDialog.hide(selectedAccount);
        };

        $scope.createNew = function () {

            $mdDialog.hide();
        };
    }
})();
