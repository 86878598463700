(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EmailSettingsController', EmailSettingsController);

    function EmailSettingsController($scope, $mdDialog, $window, $exceptionHandler, utils, datasources, syncprogress, config, model, authentication, me) {

        $scope.syncText = syncprogress.text;
        $scope.emailAccounts = null;

        const connectInfo = {
            google: {}
        };
        let count;

        init();

        $scope.connectGmail = function (syncNeeded) {

            if (!$window.isMobile) {
                return runOauthFlow({ type: 'google' }).then(get);
            }

            // Try logout first to make sure the user gets a account selection dialog thingy
            return $window.plugins.googleplus.logout(function (msg) {

                if (msg !== 'Logged user out' && msg !== 'logged out') {
                    $exceptionHandler(msg);
                    return utils.showErrorToast('Oops! Something went wrong. Please try again.');
                }

                return mobileGoogleLogin(syncNeeded);
            }, function (err) {

                if (err !== 'Please use login or trySilentLogin before logging out') {
                    $exceptionHandler(err);
                    return utils.showErrorToast('Oops! Something went wrong. Please try again.');
                }

                return mobileGoogleLogin(syncNeeded);
            });
        };

        function mobileGoogleLogin(syncNeeded) {

            return $window.plugins.googleplus.login(connectInfo.google, function (googleAuthData) {

                googleAuthData.sync_needed = syncNeeded;

                return datasources.trade(googleAuthData, { type: 'google' }, { noToast: true, noToastForStatusCode: [403] })
                    .then(function () {
                        return authentication.fetchMe().then(get);
                    })
                    .catch(function (err) {

                        if (err?.data?.message === 'UNAUTHORIZED_SCOPES') {
                            $scope.disableButtons = false;

                            const insufficientPermissionsDialog = $mdDialog.confirm({ multiple: true })
                                .clickOutsideToClose(true)
                                .escapeToClose(true)
                                .title('Please enable all permissions')
                                .textContent('Salesflare needs all the requested permissions to be able to automate your CRM work.')
                                .ok('Enable')
                                .cancel('cancel');

                            return $mdDialog.show(insufficientPermissionsDialog)
                                .then(() => {
                                    $scope.connectGoogle();
                                });
                        }


                        return $window.plugins.googleplus.logout(function (msg) {

                            if (msg !== 'Logged user out') {
                                $exceptionHandler(msg);
                                return utils.showErrorToast('Oops! Something went wrong. Please try again.');
                            }
                        });
                    });
            }, function (msg) {

                // 12501 is user canceled the oauth flow
                if (msg && msg !== 12501) {
                    $exceptionHandler(msg);
                    utils.showErrorToast('Oops! Something went wrong. Please try again.');
                }

                return $window.plugins.googleplus.logout(function (message) {

                    if (message !== 'Logged user out') {
                        $exceptionHandler(message);
                        return utils.showErrorToast('Oops! Something went wrong. Please try again.');
                    }
                });
            });
        }

        $scope.connectOffice365 = function () {

            return runOauthFlow({ type: 'office365' }).then(get);
        };

        $scope.connectOther = function ($event) {

            const emailSettingsPromtDialogConfig = {
                targetEvent: $event,
                clickOutsideToClose: true,
                controller: 'EmailSettingsPromptDialogController',
                templateUrl: 'partials/emailsettingspromptdialog.html'
            };

            return $mdDialog.show(emailSettingsPromtDialogConfig).then(get);
        };

        $scope.disconnect = function (account) {

            account.disconnecting = true;

            return datasources.deleteEmailSource(account.id).then(function () {

                utils.showSuccessToast('Disconnected');

                return authentication.fetchMe().then(get);
            }).catch(function () {

                account.disconnecting = false;
                return get();
            });
        };

        $scope.updateSMTPSettings = function (account) {

            return $mdDialog.show({
                clickOutsideToClose: false,
                controller: 'UpdateSMTPSettingsController',
                templateUrl: 'partials/updatesmtpsettingsdialog.html',
                locals: {
                    email: account.email,
                    port: account.smtp_port,
                    host: account.smtp_host,
                    store_sent_emails: account.store_sent_emails === null ? !(account.host && account.host.includes('.zoho.')) : account.store_sent_emails
                },
                bindToController: true
            }).then(function (updatedSMTPSettings) {

                if (updatedSMTPSettings) {
                    account.port = updatedSMTPSettings.port;
                    account.host = updatedSMTPSettings.host;
                    account.store_sent_emails = updatedSMTPSettings.store_sent_emails;

                    return utils.showSuccessToast('You\'re all set up for sending emails from Salesflare!');
                }
            });
        };

        $scope.reconnect = function (dataSource) {

            if (dataSource.type === 'google' && $window.isMobile) {
                dataSource.disconnecting = true;

                return datasources.deleteEmailSource(dataSource.id).then(function () {

                    dataSource.disconnecting = false;

                    let syncNeeded = true;

                    if (dataSource.status === 'RECONNECT' && dataSource.sync_status === 'done') {
                        syncNeeded = false;
                    }

                    return $scope.connectGmail(syncNeeded);
                }).catch(function () {

                    dataSource.disconnecting = false;
                    return get();
                });
            }

            const forceOfficeReconnect = $scope.data_sources_to_reconnect.find(function (ds) {

                return ds.id === dataSource.id;
            });

            let url = config.apiUrl + 'datasources/connect?email=' + dataSource.email + '&type=' + (forceOfficeReconnect ? 'office365' : dataSource.type) + '&reconnect=true';

            if (model.authStrategy === 'bearer') {
                url += '&access_token=' + model.getToken();
            }

            return utils.popup(url).then(function (queryParams) {

                if (queryParams && queryParams.get('success') !== 'true') {
                    utils.showErrorToast(queryParams.get('error'));
                }

                return authentication.fetchMe().then(get).catch(angular.noop);
            });
        };

        function init() {

            get();

            if ($window.isMobile) {
                datasources.getConnectInfo('google').then(function (response) {

                    connectInfo.google = response.data;
                });
            }
        }

        function get() {

            return datasources.getEmailSources().then(function (response) {

                for (let i = 0; i < response.data.length; ++i) {
                    const emailAccount = response.data[i];

                    if (emailAccount.type === 'nylas') {
                        emailAccount.name = 'IMAP';
                        emailAccount.type = 'imap';
                    }

                    // Office365 type should be renamed to Microsoft when everyone is migrated
                    if (emailAccount.type === 'office365') {
                        emailAccount.name = 'Microsoft';
                        emailAccount.type = 'microsoft';
                    }

                    emailAccount.avatar = 'https://lib.salesflare.com/providers/' + emailAccount.type.toLowerCase() + '.png';
                }

                if (response.data.length > count) {
                    utils.showSuccessToast('Great! Your mailbox is now connected. Salesflare is now syncing your mailbox.');
                    syncprogress.startPolling();
                }

                count = response.data.length;

                $scope.emailAccounts = response.data;

                $scope.data_sources_to_reconnect = model.me.data_sources_to_reconnect;

                if (model.me.data_sources_to_reconnect) {
                    for (let i = 0; i < $scope.data_sources_to_reconnect.length; ++i) {
                        $scope.data_sources_to_reconnect[i].avatar = 'https://lib.salesflare.com/providers/' + $scope.data_sources_to_reconnect[i].type.toLowerCase() + '.png';
                        $scope.data_sources_to_reconnect[i].name = $scope.data_sources_to_reconnect[i].type;

                    }
                }
            });
        }

        $scope.makePrimary = function (account) {

            return me.update({ email: account.email, _dirty: true }).then(function () {

                const previousPrimaryEmailSource = $scope.emailAccounts.find(function (emailAccount) {

                    return emailAccount.primary === true;
                });

                if (previousPrimaryEmailSource) {
                    previousPrimaryEmailSource.primary = false;
                }

                account.primary = true;

                return utils.showSuccessToast(account.email + ' is now your primary email address.');
            });
        };

        $scope.editSignature = function (account) {

            return $mdDialog.show({
                fullscreen: true,
                clickOutsideToClose: true,
                controller: 'EditSignatureDialogController',
                templateUrl: 'partials/editsignaturedialog.html',
                locals: {
                    email_signature: account.email_signature,
                    add_signature_to_replies: account.add_signature_to_replies,
                    add_signature_to_campaign: account.add_signature_to_campaign
                },
                bindToController: true,
                onShowing: function (scope, element) {

                    element.children('md-dialog').addClass('edit-signature-dialog');
                }
            }).then(function (updatedSignatureData) {

                if (updatedSignatureData) {
                    return datasources.updateEmailSource(account.id, updatedSignatureData).then(function () {

                        account.email_signature = updatedSignatureData.email_signature;
                        account.add_signature_to_replies = updatedSignatureData.add_signature_to_replies;
                        account.add_signature_to_campaign = updatedSignatureData.add_signature_to_campaign;

                        return utils.showSuccessToast('Your signature has been updated successfully');
                    });
                }
            });
        };

        $scope.setQuota = (account) => {

            return $mdDialog.show({
                clickOutsideToClose: true,
                controller: 'SetSendingQuotaDialogController',
                templateUrl: 'app-ajs/components/setsendingquotadialog/setSendingQuotaDialog.html',
                controllerAs: 'vm',
                locals: {
                    email: account.email,
                    type: account.type,
                    max_emails_hour: account.max_emails_hour,
                    max_emails_day: account.max_emails_day
                },
                bindToController: true
            }).then((updatedQuota) => {

                if (updatedQuota) {
                    return datasources.updateEmailSource(account.id, updatedQuota).then(() => {

                        account.max_emails_hour = updatedQuota.max_emails_hour;
                        account.max_emails_day = updatedQuota.max_emails_day;

                        return utils.showSuccessToast('Your quota has been updated successfully');
                    });
                }
            });
        };

        function runOauthFlow(options) {

            let url = config.apiUrl + 'datasources/connect?';

            if (options.email) {
                url += 'email=' + options.email;
            }

            if (options.type) {
                if (!url.endsWith('?')) {
                    url += '&';
                }

                url += 'type=' + options.type;
            }

            if (model.authStrategy === 'bearer') {
                if (!url.endsWith('?')) {
                    url += '&';
                }

                url += 'access_token=' + model.getToken();
            }

            return utils.popup(url).then(function (queryParams) {

                if (queryParams && queryParams.get('success') !== 'true') {
                    utils.showErrorToast(queryParams.get('error'));
                }
            });
        }
    }
})();
