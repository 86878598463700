import { Component, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from '@core/services/notifications.service';

import { ApiResponseModel as ApiResponse } from '@shared/models/services/api.response.model';
import { ImportStateModel as ImportState } from '@shared/models/import.model';
import { Subscription, interval, switchMap } from 'rxjs';

@Component({
    selector: 'sfx-import-list',
    templateUrl: './import-list.component.html',
    styleUrls: ['./import-list.component.scss']
})
export class ImportListComponent implements OnInit, OnDestroy {

    @Input() importType: 'account' | 'opportunity' | 'contact';

    public importableEntityMapping = {
        account: 'accounts',
        opportunity: 'opportunities',
        contact: 'contacts'
    };

    public complete: ImportState[] = [];
    public inProgress: ImportState[] = [];

    private pollingPeriod = 5;

    public showEmptyState = false;
    public emptyStateMessage = '';

    private _pollingSubscription?: Subscription;

    constructor(
        public notificationService: NotificationService,
        @Inject('importService') public importService: any,
        @Inject('dialogService') public dialogService: any,
        @Inject('modelService') public modelService: any,
        @Inject('stateService') public stateService: any
    ) {}

    public ngOnInit(): void {

        this.emptyStateMessage = 'To import your ' + this.importableEntityMapping[this.importType] + ', drop a .csv file here or click the plus button.';

        this._pollingSubscription = interval(this.pollingPeriod * 1000)
            .pipe(
                switchMap(() => {

                    const response: Promise<ApiResponse<ImportState[]>> = this.importService.getEntityImports(this.importType);

                    return response;
                })
            )
            .subscribe((response: ApiResponse<ImportState[]>) => {

                this.handleImportResponse(response);
            });

        return this.importService.getEntityImports(this.importType).then(this.handleImportResponse).then(() => {

            if (this.complete.length === 0 && this.inProgress.length === 0) {
                this.showEmptyState = true;
            }
        });
    }

    public ngOnDestroy(): void {

        this._pollingSubscription?.unsubscribe();
    }

    public goToMap = (importId: number) => {

        this.stateService.go('settings.importMap', { id: importId });
    }

    public getSkipFile = (importState: ImportState) => {

        return this.dialogService.show({
            clickOutsideToClose: true,
            controller: 'SkipFilePromptDialogController',
            templateUrl: 'partials/skipfilepromptdialog.html',
            bindToController: true,
            locals: {
                importId: importState.id
            }
        });
    };

    public removeImport = (importState: ImportState, index: number) => {

        if (importState.status !== 'complete') {
            return this.importService.delete(importState.id).then(() => {

                this.inProgress.splice(index, 1);
                this.showEmptyState = (this.inProgress.length === 0) && (this.complete.length === 0);
            });
        }

        return this.dialogService.show({
            clickOutsideToClose: true,
            templateUrl: 'partials/deleteimportdialog.html',
            controller: 'DeleteImportDialogController',
            locals: {
                importType: importState.type,
                importId: importState.id
            },
            bindToController: true
        }).then((result: any) => {

            if (!result || !result.delete) {
                return;
            }

            return this.importService.delete(importState.id, result.deleteUpdated).then(() => {

                this.complete.splice(index, 1);
                this.showEmptyState = (this.inProgress.length === 0) && (this.complete.length === 0);
            });
        });
    };

    public handleImportResponse = (response: ApiResponse<ImportState[]>) => {

        const imports = response.data;

        const complete: ImportState[] = [];
        const inProgress: ImportState[] = [];

        for (const importState of imports) {

            if (importState.total_records === 0) {
                importState.completed = 0;
            }
            else {
                importState.completed = Math.round((importState.processed_records / importState.total_records) * 100);
            }

            if (importState.status === 'complete') {
                if (importState.failed_records !== 0) {
                    importState.skipFile = true;
                }

                complete.push(importState);
            }
            else {
                inProgress.push(importState);
            }
        }

        this.complete = complete;
        this.inProgress = inProgress;
    }
}
