'use strict';

import * as angular from "angular";

import { sfIndexedDB } from '@app/data/indexed-db';

class DraftsService {

    public async get(email: string): Promise<any> {

        if (!email) {
            return;
        }

        const draft = await sfIndexedDB.drafts.get(email);

        if (!draft) {
            return;
        }

        return {
            ...draft,
            to: [draft.to]
        };
    }

    public async save(draft: any) {

        if (draft.to.length === 0) {
            return;
        }

        await Promise.all(draft.to.map(async (toRecipient: any) => {

            return sfIndexedDB.drafts.put({
                to: toRecipient,
                cc: draft.cc,
                bcc: draft.bcc,
                subject: draft.subject,
                body: draft.body,
                attachments: draft.attachments,
                tracked: draft.tracked,
                timestamp: new Date()
            })
        }))
    }

    public async delete(email: string|string[]) {

        const emails = Array.isArray(email) ? email : [email];

        await Promise.all(emails.map(async (email: string) => {

            return sfIndexedDB.drafts.delete(email);
        }));
    }

    public async clearOldDrafts() {

        const THREE_DAYS = 1000 * 60 * 60 * 24 * 3;

        await sfIndexedDB
            .drafts
            .where('timestamp')
            .below(new Date(new Date().getTime() - THREE_DAYS))
            .delete();
    }
}

(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('draftsService', DraftsService);
})();
