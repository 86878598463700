(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('dashboardsService', dashboardsService);

    function dashboardsService(sfHttp, config) {

        this.get = function () {

            return sfHttp.get(config.apiUrl + 'dashboards');
        };

        this.create = function (name) {

            return sfHttp.post(config.apiUrl + 'dashboards', { name });
        };

        this.update = function (dashboards) {

            return sfHttp.put(config.apiUrl + 'dashboards', dashboards);
        };
    }
})();
