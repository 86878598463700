import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';

import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/bundles/material/material.module';

import { SfxAuthenticationCodeDialog } from '@feature/login/components/authentication-code-dialog/authentication-code-dialog.component';
import { SfxEnterRecoveryCodeDialog } from '@feature/login/components/enter-recovery-code-dialog/enter-recovery-code-dialog.component';

import { ToolbarModule } from '@shared/components/layout/toolbar/toolbar.module';

@NgModule({
    declarations: [
        SfxEnterRecoveryCodeDialog,
        SfxAuthenticationCodeDialog
    ],
    exports: [],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        UIRouterModule,
        ToolbarModule
    ],
    providers: [
        {
            provide: 'authenticationService',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('authentication');
            }
        }
    ]
})
export class LoginModule {}
