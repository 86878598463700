(function () {
    'use strict';

    angular
        .module('salesflare.components.discardDialog', [])
        .factory('sfDiscardDialog', sfDiscardDialog);

    function sfDiscardDialog($mdDialog) {

        return {
            show
        };

        function show(isNew, item) {

            return $mdDialog.show({
                clickOutsideToClose: false,
                escapeToClose: true,
                multiple: true,
                hasBackdrop: false,
                controller: 'DiscardDialogController',
                templateUrl: 'app-ajs/components/discarddialog/discarddialog.html',
                locals: {
                    isNew,
                    item
                },
                bindToController: true
            });
        }

    }
})();
