(function () {
    'use strict';

    angular
        .module('salesflare.components.workflow.workflowStep', [
            'salesflare.components.workflow.workflowStep.workflowStepAction'
        ])
        .component('sfWorkflowStep', {
            templateUrl: 'app-ajs/components/workflows/workflow/workflowstep/workflowStep.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                step: '<',
                allSteps: '<',
                editing: '<',
                goal: '<',
                actionTriggers: '<',
                filter: '<',
                individualRecordFilter: '<',
                showPlanFlags: '<',
                onStepFormCreation: '&',
                onStepDelete: '&',
                onSetDirty: '&',
                onValidityChanged: '&'
            }
        });

    function controller($document, $mdMedia, $state, $stateParams, $timeout, model, workflowService) {

        const vm = this;
        const actionLabels = {
            send_email: 'Send'
        };
        let formCreated = false;

        vm.customMessage = '';
        vm.availableActionTriggers = [];
        vm.$mdMedia = $mdMedia;

        vm.$onInit = function () {

            vm.workflowItems = workflowService.getStepTypeMetrics(vm.step);

            if (!vm.step.trigger_after_days && vm.step.trigger_after_days !== 0) {
                vm.step.trigger_after_days = vm.step.order === 1 ? 0 : 3;
            }

            if (!vm.step.trigger_step_index && vm.step.trigger_step_index !== 0 && vm.step.trigger_step_index !== null) {
                vm.step.trigger_step_index = vm.step.order > 1 ? vm.step.order - 1 : null;
            }

            if (!vm.step.trigger && vm.step.trigger !== null && vm.step.trigger !== 0) {
                vm.step.trigger = 'sent';
            }

            if ((!vm.step.action_config || !vm.step.action_config.message.subject) && vm.step.trigger_step_index) {
                const triggerStep = vm.selectableSteps.find(function (step) {

                    return step.order === vm.step.trigger_step_index;
                });
                vm.triggerStepSubject = triggerStep.action_config && triggerStep.action_config.message && triggerStep.action_config.message.subject;
            }

            setCurrentActionTriggerValidity();
        };

        vm.$onChanges = function (changes) {

            if (changes.allSteps && angular.isArray(changes.allSteps.currentValue)) {
                // Set selectable trigger steps
                vm.selectableSteps = vm.allSteps.filter(function (step) {

                    return step.order !== vm.step.order;
                });

                vm.selectableSteps = vm.selectableSteps.reverse();
            }

            if (changes.goal && vm.goal && vm.step && vm.step.trigger) {
                setCurrentActionTriggerValidity();
            }
            else if (changes.editing && changes.editing.currentValue === true) {
                setCurrentActionTriggerValidity();
            }
        };

        vm.$doCheck = function () {

            // Checks if the form has been created and inform the workflow
            // Only add the form once when it's created
            if (formCreated || !vm.stepForm) {
                return;
            }

            const formElement = $document[0].querySelector('#stepForm-' + vm.step.order);

            if (!formElement) {
                return;
            }

            formCreated = true;

            vm.onStepFormCreation({
                $event: {
                    form: formElement
                }
            });
        };

        // Triggered by ng-change directive on form fields
        vm.setDirty = function () {

            if (vm.stepForm.$dirty) {
                return vm.onSetDirty();
            }
        };

        vm.triggerStepChanged = function () {

            if (vm.step.trigger_step_index) {
                const triggerStep = vm.selectableSteps.find(function (step) {

                    return step.order === vm.step.trigger_step_index;
                });

                if (!triggerStep) {
                    return;
                }

                vm.triggerStepSubject = triggerStep.action_config && triggerStep.action_config.message && triggerStep.action_config.message.subject;
            }

            vm.setDirty();
        };

        vm.onStepActionChanged = function ($event) {

            if ($event.changedAsReply) {
                vm.triggerStepChanged();
            }

            if ($event.validityChanged) {
                vm.onValidityChanged();
            }

            // When the step action is dirty, the parent form should be to so that the discard dialog is triggered when needed
            if ($event.dirty) {
                vm.stepForm.$setDirty();
                return vm.onSetDirty();
            }
        };

        vm.deleteStep = function () {

            return vm.onStepDelete({
                $event: {
                    stepOrder: vm.step.order
                }
            });
        };

        vm.getActionLabel = function () {

            return actionLabels[vm.step.action_type];
        };

        vm.showStepAnalytics = function (tab) {

            if (!vm.editing) {
                return $state.go('workflow-stepAnalytics', { id: $stateParams.id, stepId: vm.step.id, viewing: tab });
            }
        };

        vm.getTriggerActionDescription = function (trigger) {

            if (!vm.actionTriggers || angular.isUndefined(trigger)) {
                return;
            }

            const foundActionTrigger = vm.actionTriggers.find(function (actionTrigger) {

                return actionTrigger.trigger === trigger;
            });

            if (angular.isUndefined(foundActionTrigger)) {
                return;
            }

            return foundActionTrigger.description;
        };

        vm.getTriggerStepName = function () {

            if (vm.step.trigger_step_index === null) {
                return 'workflow is entered';
            }

            return 'step ' + vm.step.trigger_step_index;
        };

        ////////////////////////

        /**
         * Return all valid action triggers (depending on exit reason)
         *
         * @returns {Array.<Object>}
         */
        function getAvailableActionTriggers() {

            if (!vm.goal || !vm.actionTriggers || vm.actionTriggers.length === 0) {
                return vm.actionTriggers;
            }

            const actionTriggers = vm.actionTriggers.filter(function (actionTrigger) {

                return actionTrigger.action_type === vm.step.action_type;
            });

            return actionTriggers.map(function (actionTriggerOriginal) {

                // Make a copy
                const actionTrigger = Object.assign({}, actionTriggerOriginal);

                switch (actionTrigger.trigger) {
                    case 'opened':
                        if (vm.goal === 'opened') {
                            actionTrigger.disabled = true;
                            actionTrigger.description += ' (exit reason)';
                        }
                        else {
                            actionTrigger.disabled = false;
                        }

                        break;
                    case 'clicked':
                        if (vm.goal === 'opened' || vm.goal === 'clicked') {
                            actionTrigger.disabled = true;
                            actionTrigger.description += ' (exit reason)';
                        }
                        else {
                            actionTrigger.disabled = false;
                        }

                        break;
                    case 'replied':
                        if (vm.goal === 'opened' || vm.goal === 'replied') {
                            actionTrigger.disabled = true;
                            actionTrigger.description += ' (exit reason)';
                        }
                        else {
                            actionTrigger.disabled = false;
                        }

                        break;
                    default:
                        break;
                }

                return actionTrigger;
            });
        }

        function setCurrentActionTriggerValidity() {

            const actionTriggers = getAvailableActionTriggers();
            vm.availableActionTriggers = actionTriggers;

            if (!vm.editing) {
                return;
            }

            // Wait for vm.stepForm to be there
            $timeout(function () {

                if (!vm.step.trigger || (angular.isArray(actionTriggers) && actionTriggers.length === 0)) {
                    vm.step.invalid = false;
                    return;
                }

                const currentActionTrigger = actionTriggers.find(function (trigger) {

                    return trigger.trigger === vm.step.trigger;
                });

                if (currentActionTrigger && currentActionTrigger.disabled) {
                    // Set form error && validity
                    if (vm.stepForm.triggerAction) {
                        vm.stepForm.triggerAction.$setValidity('triggerNotAllowed', false);
                        vm.stepForm.$error.triggerNotAllowed[0].$setTouched();
                        vm.step.invalid = true;
                        vm.onSetDirty();
                    }
                }
                else {
                    vm.stepForm.triggerAction.$setValidity('triggerNotAllowed', true);
                    vm.step.invalid = false;
                }
            });
        }
    }
})();
