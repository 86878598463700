(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('fileService', fileService);

    function fileService(sfHttp, config, Upload) {

        const self = this;

        this.deleteFile = function (fileId) {

            const url = config.apiUrl + 'files/' + fileId;

            return sfHttp.delete(url, { ignoreLoadingBar: true });
        };

        this.uploadFile = function (file, ignoreLoadingBar, callback) {

            const creationUrl = config.apiUrl + 'files';
            const json = {
                fileName: file.name,
                fileType: file.type
            };

            return sfHttp.post(creationUrl, json, { ignoreLoadingBar }).then(function (response) {

                const fileId = response.data.id;

                return Upload.upload({
                    url: config.apiUrl + 'files/' + fileId + '/upload?file_type=' + file.type,
                    data: {
                        file
                    },
                    ignoreLoadingBar
                }).then(function (reply) {

                    if (reply.status !== 200) {
                        return self.deleteFile(fileId).then(callback, callback);
                    }

                    const newFile = {
                        id: fileId,
                        file_name: file.name,
                        file_type: response.data.file_type || file.type,
                        file_url: response.data.download_url
                    };

                    return callback(newFile);
                }, function () {

                    return self.deleteFile(fileId).then(callback, callback);
                });
            });
        };
    }
})();
