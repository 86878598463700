(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ApplicationsSettingsController', ApplicationsSettingsController);

    // This controller is also used in the self select dialog (get equipped step)
    function ApplicationsSettingsController($document, $scope, $mdDialog, $mdMedia, $window, utils, config, model, calendarsyncprogress, calendars) {

        let initial = true;
        $scope.zaps = [];
        $scope.currentUser = model.me;
        $scope.zapierAppDirectoryElementId = 'zapier-app-directory';

        initializeZapierAppDirectory();

        // For onboarding
        getCalendars();

        // Moved go function to this controller so it can be used via the self select dialog (putting go on rootScope doesn't work because dialogs do not inherit from rootScope)
        $scope.go = function (url) {

            $window.open(url, '_blank', 'noopener');
            // eslint-disable-next-line no-useless-return
            return; // Otherwise we return window object
        };

        $scope.connectCalendar = function () {

            return utils.popup(config.apiUrl + 'datasources/calendar/connect?id=' + model.me.id, $window.isMobile ? '_system' : '').then(getCalendars);
        };

        $scope.showScript = function () {

            return $mdDialog.show({
                clickOutsideToClose: true,
                controller: 'TrackingScriptDialogController',
                templateUrl: 'partials/trackingscriptdialog.html',
                fullscreen: !$mdMedia('gt-sm')
            });
        };

        $scope.showGuide = function () {

            return $mdDialog.show({
                clickOutsideToClose: true,
                controller: 'EmailTrackingDialogController',
                templateUrl: 'partials/emailtrackingdialog.html',
                fullscreen: !$mdMedia('gt-sm')
            });
        };

        $scope.installChromePlugin = function () {

            const link = 'https://chrome.google.com/webstore/detail/iodbiefeiiififbdcdckdbigakehaemb';

            $scope.chromePluginInstalled = true;
            return $scope.go(link);
        };

        function initializeZapierAppDirectory() {

            // This script needs to run each time the page is rendered so we add a cache buster parameter so the script reloads each time
            // This is done in js as we can't set the src of the script tag via interpolation in angularjs
            const script = $document[0].createElement('script');
            script.src = `https://zapier.com/partner/embed/app-directory/wrapper.js?cache-buster-param=${Date.now()}&id=${$scope.zapierAppDirectoryElementId}&app=salesflare&theme=light&applimit=0&zaplimit=100&zapstyle=row&introcopy=hide`;
            script.type = 'module';
            $document[0].body.append(script);
        }

        function getCalendars() {

            return calendars.getAccounts().then(function (response) {

                if (!initial && response.data.length > 0) {
                    $scope.calendarConnected = true;

                    return calendarsyncprogress.startPolling();
                }

                initial = false;
            });
        }
    }
})();
