(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('CalendarSettingsController', CalendarSettingsController);

    function CalendarSettingsController($scope, $window, $timeout, $mdDialog, helperFunctionsService, model, config, utils, calendars, calendarsyncprogress, sfSetupPanel) {

        $scope.calendarAccounts = null;
        let calendarAccountsCount = 0;

        get();

        function showAuthenticationPopUp(popUpUrl, target) {

            if (config.mode === 'outlook-web') {
                return helperFunctionsService.handleOutlookPluginOAuthFlow(popUpUrl);
            }

            return utils.popup(popUpUrl, target);
        }

        $scope.create = function () {

            return showAuthenticationPopUp(`${config.apiUrl}datasources/calendar/connect?id=${model.me.id}`, $window.isMobile ? '_system' : '')
                .then(function (queryParams) {

                    if (queryParams && queryParams.get('success') !== 'true') {
                        if (queryParams.get('error') === 'UNAUTHORIZED_SCOPES') {

                            const insufficientPermissionsDialog = $mdDialog.confirm({ multiple: true })
                                .clickOutsideToClose(true)
                                .escapeToClose(true)
                                .title('Please enable all permissions')
                                .textContent('Salesflare needs all the requested permissions to be able to automate your CRM work.')
                                .ok('Enable')
                                .cancel('cancel');

                            return $mdDialog.show(insufficientPermissionsDialog)
                                .then(() => {

                                    $scope.create();
                                });
                        }
                    }

                    return get(true);
                });
        };

        $scope.supportedProviders = [
            {
                id: 'apple',
                label: 'iCloud',
                icon: 'apple'
            },
            {
                id: 'exchange',
                label: 'Exchange',
                icon: 'exchange'
            },
            {
                id: 'outlook',
                label: 'Outlook.com',
                icon: 'outlook'
            },
            {
                id: 'office365',
                label: 'Office 365',
                icon: 'office'
            },
            {
                id: 'google',
                label: 'Google',
                icon: 'google'
            }
        ];

        $scope.connectProvider = function () {

            // For future reference: use datasources/calendar/connect/${providerId} for the new connector.
            return showAuthenticationPopUp(`${config.apiUrl}datasources/calendar/connect?id=${model.me.id}`, $window.isMobile ? '_system' : '').then(function () {

                return get(true);
            });
        };

        $scope.disconnect = function (account) {

            account.disconnecting = true;

            return calendars.disconnectAccount(account.id).then(function () {

                utils.showSuccessToast('Disconnected');

                return get();
            }).catch(function () {

                account.disconnecting = false;
                return get();
            });
        };

        $scope.changeActiveState = function (account, calendar) {

            return calendars.update(account.id, calendar.id, { active: calendar.active }).then(function () {

                return get();
            }).catch(function () {

                calendar.active = !calendar.active;
            });
        };

        function get(comesFromCreate) {

            return calendars.getAccounts().then(function (response) {

                if (comesFromCreate && response.data.length > calendarAccountsCount) {
                    calendarsyncprogress.startPolling();
                    utils.showSuccessToast('Great! Your calendar is connected. Salesflare is now syncing your meetings.');

                    if (model.me && model.me.team.subscribed) {
                        $timeout(sfSetupPanel.updateSetupSteps, 300);
                    }
                }

                calendarAccountsCount = response.data.length;

                for (let i = 0; i < response.data.length; ++i) {
                    const calendar = response.data[i];
                    calendar.avatar = 'https://lib.salesflare.com/providers/' + calendar.name.toLowerCase() + '.png';
                }

                $scope.calendarAccounts = response.data;
            });
        }
    }
})();
