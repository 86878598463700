(function () {
    'use strict';

    angular
        .module('salesflare.components.filters.group', [])
        .component('sfFiltersGroup', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/filters/group/filtersGroup.html',
            bindings: {
                index: '<',
                appliedGroup: '<',
                pipeline: '<',
                entity: '<',
                group: '<',
                filterFields: '<',
                deleteGroup: '&',
                onFilterUpdate: '&',
                onApply: '&'
            }
        });

    function controller($scope) {

        const vm = this;

        // Make sure all the loaded rules have indices, it's a way of identifying rules when rules get added/deleted later
        vm.$onInit = function () {

            vm.rules = angular.copy(vm.appliedGroup.filterRules) || [];
            vm.ruleIndex = 0;

            if (vm.rules.length > 0 ) {
                vm.rules.map(function (rule) {
                    rule.index = vm.rules.indexOf(rule);
                    vm.ruleIndex = rule.index;
                    return rule;
                });

                vm.ruleIndex += 1;
            }

            if (vm.ruleIndex === 0) {
                const firstRule = {
                    index: vm.ruleIndex
                };
                vm.ruleIndex += 1;

                vm.rules.push(firstRule);
            }
        };

        $scope.$on('setFilterGroupRules', (event, args) => {

            if (args.index !== vm.index) {

                return;
            }

            vm.rules = angular.copy(args.filterRules) || [];

            vm.rules.map((rule, idx) => {
                rule.index = idx;
                return rule;
            });

            vm.ruleIndex = vm.rules.length;
        });

        vm.addFilterRule = function () {

            const newRule = {
                index: vm.ruleIndex,
                disabled: false
            };
            vm.rules.push(newRule);
            vm.updateFilterRule(newRule.index, newRule);
            // Bump the index for the next rule to be added
            vm.ruleIndex += 1;
        };

        vm.loadFilter = function () {

            //TODO: Placeholder

        };

        vm.removeFilterRule = function (ruleIndex) {

            const foundRule = vm.rules.find(function (rule) {

                return rule.index === ruleIndex;
            });

            const index = vm.rules.indexOf(foundRule);
            if (index !== -1) {
                vm.rules.splice(index, 1);
            }

            vm.onFilterUpdate({ groupIndex: vm.index, updatedGroups: vm.rules });

            // TODO: check with Jasper on a better way to solve this
            if (vm.rules.length > 0 && foundRule.operator) {
                // Get new results with remaining filters when a rule is deleted
                return vm.onApply();
            }
        };

        // Update the local object and send the update to the filters controller
        vm.updateFilterRule = function (ruleIndex, newRule) {

            const foundRule = vm.rules.find(function (rule) {

                return rule.index === ruleIndex;
            });

            let index = vm.rules.indexOf(foundRule);

            if (index === -1 && newRule.index) {
                index = newRule.index;
            }

            if (index === -1) {
                return;
            }

            if (!newRule.index) {
                newRule.index = foundRule.index;
            }

            vm.rules[index] = newRule;
            vm.onFilterUpdate({ groupIndex: vm.index, updatedGroups: vm.rules });
        };

        vm.delete = function () {

            return vm.deleteGroup();
        };
    }
})();
