import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';

import { cloneDeep, isEqual } from 'lodash';

import { MediaService } from '@core/services/media.service';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'sfx-configure-pipelines',
    templateUrl: './configure-pipelines.component.html',
    styleUrls: ['./configure-pipelines.component.scss']
})
export class ConfigurePipelinesComponent implements OnInit {

    public currentUser: { [key: string]: any } = {};

    groups: any[] = [];
    pipelines: any[] = [];

    isDraggingPipeline = false;

    constructor(
        @Inject('dialogService') private dialogService: any,
        @Inject('utilsService') private utilsService: any,
        @Inject('modelService') public modelService: any,
        @Inject('groupsService') private groupsService: any,
        @Inject('pipelinesService') private pipelinesService: any,
        @Inject('filterService') private filterService: any,
        @Inject('flagsService') private flagsService: any,
        public media: MediaService,
        public cdRef: ChangeDetectorRef
    ) {

        this.currentUser = this.modelService.me;
    }

    ngOnInit(): void {

        this.get();
    }

    public get = (): any => {

        this.groupsService.get().then((groupsResponse: any) => {

            this.groups = groupsResponse.data.filter(function (groupObject: any) {

                return groupObject.id;
            }).map(function (groupObject: any) {

                return { id: groupObject.id, name: groupObject.name };
            });
        });

        return this.pipelinesService.get().then((pipelinesResponse: any) => {

            this.pipelines = pipelinesResponse.data;
        });
    }

    public canAccessMultipleCurrencies = () => {

        return this.flagsService.get('multipleCurrencies') === true;
    }

    public getCommaSeparatedGroupNames = (pipelineGroups: any) => {

        if (!this.groups) {
            return '';
        }

        if (pipelineGroups.length === this.groups.length) {
            return 'All groups';
        }

        return pipelineGroups.map((pipelineGroup: any) => {

            return pipelineGroup.name;
        }).join(', ').replace(/, ([^,]*)$/, ' and $1');
    };

    public duplicatePipeline = (pipelineToDuplicate: any) => {

        const pipeline = cloneDeep(pipelineToDuplicate);
        pipeline.name = `${pipeline.name} Copy`;
        delete pipeline.id;

        pipeline.stages = pipeline.stages.map((stage: any) => {

            delete stage.id;

            return stage;
        });

        pipeline.order = this.pipelines.length + 1;
        this.pipelines.push(pipeline);

        this.utilsService.showSuccessToast('Pipeline duplicated.');

        return this.pipelinesService.update(this.pipelines).finally(this.get);
    };

    public editPipeline = (pipelineToEdit: any) => {

        return this.dialogService.show({
            clickOutsideToClose: true,
            controller: 'EditPipelineDialogController',
            templateUrl: 'partials/editpipelinedialog.html',
            locals: {
                pipeline: pipelineToEdit,
                groups: this.groups
            },
            bindToController: true,
            onComplete: (scope: any, element: any) => {

                return element['0'].querySelectorAll('INPUT')[0].focus();
            }
        }).then((pipeline: any) => {

            if (pipelineToEdit) {
                this.pipelines = this.pipelines.map(function (pl) {

                    if (pl.id === pipeline.id) {
                        return pipeline;
                    }

                    return pl;
                });
            }
            else {
                pipeline.order = this.pipelines.length + 1;
                this.pipelines.push(pipeline);
            }

            return this.pipelinesService.update(this.pipelines).finally(this.get);
        });
    };

    public deletePipeline = (pipeline: any) => {

        pipeline.archived = true;

        return this.pipelinesService.update(this.pipelines).then(() => {

            return this.get().then(() => {

                const currentPipelineId = localStorage.getItem('current_pipeline_' + this.modelService.me.id);

                if (currentPipelineId && pipeline.id === Number(currentPipelineId)) {
                    this.filterService.setCurrentPipelineId(this.pipelines[0].id);
                }
            });
        }, this.get);
    };

    public createStage = () => {

        return this.dialogService.show({
            clickOutsideToClose: true,
            multiple: true,
            controller: 'EditStageDialogController',
            templateUrl: 'partials/editstagedialog.html',
            locals: {
                stage: null,
                pipelines: this.pipelines
            },
            bindToController: true,
            onComplete: (scope: any, element: any) => {

                return element['0'].querySelectorAll('INPUT')[0].focus();
            }
        }).then((stage: any) => {

            stage._dirty = true;

            const foundPipeline = this.pipelines.find((pipeline) => {

                return pipeline.id === stage.pipeline.id;
            });

            stage.order = foundPipeline.stages.length + 1;

            foundPipeline.stages.push(stage);

            delete stage.pipeline;

            return this.pipelinesService.update(this.pipelines).then(this.get);
        });
    };

    public updateStage = (stage: any, pipeline: any) => {

        return this.dialogService.show({
            clickOutsideToClose: true,
            multiple: true,
            controller: 'EditStageDialogController',
            templateUrl: 'partials/editstagedialog.html',
            locals: {
                stage: cloneDeep(stage),
                pipelines: this.pipelines,
                pipeline
            },
            bindToController: true,
            onComplete: (scope: any, element: any) => {

                return element['0'].querySelectorAll('INPUT')[0].focus();
            }
        }).then((editedStage: any) => {

            if (!isEqual(stage, editedStage)) {
                editedStage._dirty = true;
            }

            // eslint-disable-next-line no-shadow
            const currentPipeline = this.pipelines.find((pipeline: any) => {

                return pipeline.id === editedStage.pipeline.id;
            });

            let stageAlreadyInPipeline = false;
            for (let i = 0; i < currentPipeline.stages.length; ++i) {
                if (currentPipeline.stages[i].id === stage.id) {
                    currentPipeline.stages[i] = editedStage;
                    stageAlreadyInPipeline = true;
                    break;
                }
            }

            if (!stageAlreadyInPipeline) {
                currentPipeline.stages.push(editedStage);
            }

            if (editedStage.oldPipeline.id !== editedStage.pipeline.id) {
                // eslint-disable-next-line no-shadow
                const oldPipeline = this.pipelines.find((pipeline: any) => {

                    return pipeline.id === editedStage.oldPipeline.id;
                });

                for (let i = 0; i < oldPipeline.stages.length; ++i) {
                    if (oldPipeline.stages[i].id === editedStage.id) {
                        oldPipeline.stages.splice(i,1);
                        break;
                    }
                }
            }

            delete editedStage.pipeline;
            delete editedStage.oldPipeline;

            return this.pipelinesService.update(this.pipelines).then(this.get);
        });
    }

    public deleteStage = (stage: any) => {

        stage._dirty = true;
        stage.archived = true;

        return this.pipelinesService.update(this.pipelines).then(this.get, this.get);
    }

    public onDropStage = ($event: CdkDragDrop<string[]>, pipeline: any) => {

        const droppedPipeline = this.pipelines.find((p: any) => p.id === pipeline.id);

        const stages = droppedPipeline.stages;

        moveItemInArray(stages, $event.previousIndex, $event.currentIndex);

        stages.forEach((stage: any, index: number) => {

            stage.order = index + 1;
            stage._dirty = true;
        });

        this.pipelinesService.update(this.pipelines).then(this.get);
    };

    public setIsDraggingPipeline = (isDragging: boolean) => {

        this.isDraggingPipeline = isDragging;

        this.cdRef.detectChanges();
    }

    public onDropPipeline = ($event: CdkDragDrop<string[]>) => {

        moveItemInArray(this.pipelines, $event.previousIndex, $event.currentIndex);

        this.pipelines.forEach((pipeline: any, index: number) => {

            pipeline.order = index + 1;
        });

        this.pipelinesService.update(this.pipelines).then(this.get);
    };
}
