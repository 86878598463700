(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('userEmailService', userEmailService);

    function userEmailService(sfHttp, config) {

        this.create = function (userEmail) {

            return sfHttp.post(config.apiUrl + 'useremails', userEmail, { ignoreLoadingBar: false });
        };
    }
})();
