(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ToastController', ToastController);

    function ToastController($transitions, $scope, $mdToast, type, message, action) {

        // Supported types are `error`, `success` and `info`
        $scope.type = type;
        $scope.message = message;
        $scope.action = action;

        $scope.clickedAction = function () {

            return $mdToast.hide($scope.action);
        };

        // Cancel toast on state change so it is gone
        const removeStartTransitionHook = $transitions.onStart({}, () => {

            return $mdToast.cancel();
        });

        $scope.$on('$destroy', function () {

            $mdToast.cancel();
            return removeStartTransitionHook();
        });
    }
})();
