import { Component } from '@angular/core';

@Component({
    selector: 'sfx-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent {

    constructor() {}
}
