<sfx-toolbar enableDivider>
    Task settings
</sfx-toolbar>

<sfx-content>
    <sfx-section disableContentPadding>
        <mat-card appearance="outlined" class="setting-card" *ngFor="let setting of settings$ | async as settings">
            <mat-card-header
                    class="setting-card-header"
                    data-layout="row" data-layout-align="start center">
                <mat-icon mat-card-avatar fontSet="salesflare" fontIcon="sf-icon-{{ setting.icon }}" class="bg-gray"></mat-icon>
                <mat-card-title>
                    {{ setting.title }}
                </mat-card-title>
                <mat-card-subtitle>
                    {{ setting.subheader }}
                </mat-card-subtitle>
                <div class="toggle-container">
                    <mat-slide-toggle [(ngModel)]="setting.enabled" (ngModelChange)="save(setting, settings)">
                    </mat-slide-toggle>
                </div>
            </mat-card-header>
            <mat-card-content>
                <p>
                    {{ setting.description }}
                </p>

                <ng-container *ngIf="setting.input">

                    <p class="input-description">
                        {{ setting.input.description }}
                    </p>

                    <mat-form-field class="setting-input">
                        <mat-label>
                            {{ setting.input.label }}
                        </mat-label>
                        <input matInput [disabled]="setting.disabled"
                               [(ngModel)]="setting.input.value"
                               (change)="setting.input.inputChanged.next(setting.input.value)"
                               min=0 type="number" name="'settingInput'" aria-label="settingInput">
                    </mat-form-field>

                </ng-container>

            </mat-card-content>
        </mat-card>
    </sfx-section>
</sfx-content>
