(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('SuggestionsController', SuggestionsController);

    function SuggestionsController($rootScope, $scope, $stateParams, $mdMedia, $mdDialog, $timeout, account) {

        /**
         * TODO: perf
         * Instead of refreshing data on every accept do it when you close the dialog
         * Make sure that when closing and nothing was accepted (e.g.just pressing close)
         * you don't refresh
         */

        const accountId = $stateParams.account;
        let thread = null;

        // Virtual repeat + infinite scroll object that angular material uses
        $scope.suggestions = {
            toLoad: 0,
            numLoaded: 0,
            items: [],

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + 20;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad < index) {
                    this.toLoad += 20;

                    const bound = angular.bind(this, suggestionsResponse);

                    if (this.items.length === 0) {
                        return account.getSuggestions(accountId, $scope.filter, {}, 20, this.numLoaded).then(bound, null, bound);
                    }
                    else {
                        return account.getSuggestions(accountId, $scope.filter, {}, 20, this.numLoaded).then(angular.bind(this, function (response) {

                            this.items = [...this.items, ...response.data];
                            this.numLoaded = this.toLoad;
                        }));
                    }
                }
            },

            // Forces reset of object
            reload: function () {

                this.toLoad = 0;
                this.numLoaded = 0;
                this.items = [];
            }
        };

        get();

        $scope.$watch('filter', function (newValue, oldValue) {

            if (newValue !== oldValue) {
                $timeout.cancel(thread);

                thread = $timeout(function () {

                    return get();
                }, 750);
            }
        });

        $scope.back = function (reload) {

            if ($mdMedia('gt-sm')) {
                return $mdDialog.hide(reload);
            }
            else {
                return $rootScope.back();
            }
        };

        $scope.acceptSuggestion = function (contact) {

            contact._dirty = true;

            return account.acceptSuggestion(accountId, contact).then(function () {

                if ($mdMedia('gt-sm')) {
                    $rootScope.$broadcast('refreshData');
                }

                return get();
            });
        };

        $scope.rejectSuggestion = function (contact) {

            return account.rejectSuggestion(accountId, contact).then(function () {

                if ($mdMedia('gt-sm')) {
                    $rootScope.$broadcast('refreshData');
                }

                return get();
            });
        };

        $scope.rejectAllSuggestions = function () {

            return account.rejectAllSuggestions(accountId).then(function () {

                if ($mdMedia('gt-sm')) {
                    return $mdDialog.hide(true);
                }

                return $scope.back(true);
            });
        };

        $scope.acceptAllSuggestions = function () {

            if ($scope.suggestions.items.length >= 20) {
                const confirm = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .htmlContent('Are you sure you want to add 20+ contacts to this account?<br>This may slow down the account timeline significantly.')
                    .ok('Yes')
                    .cancel('No');

                return $mdDialog.show(confirm).then(function () {

                    return account.acceptAllSuggestions(accountId).then(function () {

                        if ($mdMedia('gt-sm')) {
                            return $mdDialog.hide(true);
                        }

                        return $scope.back(true);
                    });
                });
            }

            return account.acceptAllSuggestions(accountId).then(function () {

                if ($mdMedia('gt-sm')) {
                    return $mdDialog.hide(true);
                }

                return $scope.back(true);
            });
        };

        function get() {

            return $scope.suggestions.reload();
        }

        function suggestionsResponse(response) {

            this.items = response.data;
            this.numLoaded = this.toLoad;
        }
    }
})();
