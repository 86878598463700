(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('EditPictureDialogController', EditPictureDialogController);

    function EditPictureDialogController($scope, $mdDialog, $timeout, utils) {

        $scope.imageToCrop = this.picture;

        const originalPictureSrc = this.picture;
        let retryLoadingImageInCanvas = false;

        function handleFileSelect(evt) {
            const file = evt.currentTarget.files[0];
            const reader = new FileReader();

            reader.addEventListener('load', function (event) {

                // eslint-disable-next-line no-shadow
                $scope.$apply(function ($scope) {

                    $scope.showSpinner = true;
                    $scope.showCropArea = false;
                    $scope.imageToCrop = event.target.result;
                });
            });

            if (file) {
                reader.readAsDataURL(file);
            }
        }

        $timeout(function () {

            angular.element('#file-input').on('change', handleFileSelect);
        }, 700);

        $scope.closePictureDialog = $mdDialog.hide;

        $scope.savePicture = function () {

            $mdDialog.hide($scope.croppedImage);
        };

        $scope.onLoadDoneEvent = function () {

            $scope.showSpinner = false;
            $scope.showCropArea = true;
        };

        $scope.onLoadErrorEvent = function () {

            // We retried loading the image in the canvas but still got an error, show error toast.
            if (retryLoadingImageInCanvas) {
                retryLoadingImageInCanvas = false;
                $scope.showSpinner = false;
                $scope.showCropArea = false;
                return utils.showErrorToast('Failed loading image. Please close this dialog and try again or upload another image.');
            }

            // We retry loading the image in the canvas once with a query param to try and bust the cache
            retryLoadingImageInCanvas = true;
            $scope.imageToCrop = originalPictureSrc + '?x=1';
        };
    }
})();
