import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
    providedIn: 'root'
})
export class MediaService {
    private readonly DEFAULT_BREAKPOINTS: { [breakpoint: string]: string } = {
        'xs': 'screen and (min-width: 0px) and (max-width: 599.98px)',
        'sm': 'screen and (min-width: 960px) and (max-width: 1279.98px)',
        'md': 'screen and (min-width: 960px) and (max-width: 1279.98px)',
        'lg': 'screen and (min-width: 1280px) and (max-width: 1919.98px)',
        'xl': 'screen and (min-width: 1920px) and (max-width: 4999.98px)',
        'lt-sm': 'screen and (max-width: 599.98px)',
        'lt-md': 'screen and (max-width: 959.98px)',
        'lt-lg': 'screen and (max-width: 1279.98px)',
        'lt-xl': 'screen and (max-width: 1919.98px)',
        'gt-xs': 'screen and (min-width: 600px)',
        'gt-sm': 'screen and (min-width: 960px)',
        'gt-md': 'screen and (min-width: 1280px)',
        'gt-lg': 'screen and (min-width: 1920px)'
    };

    constructor(
        private breakpointObserver: BreakpointObserver
    ) {}

    public isActive(breakpoint: string) {
        return this.breakpointObserver.isMatched(this.DEFAULT_BREAKPOINTS[breakpoint]);
    }

    public hasTouch() {
        return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
    }
}
