(function () {
    'use strict';

    angular
        .module('salesflare.components.dashboards.filter', [])
        .component('sfDashboardsFilter', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/dashboards/filter/filter.html',
            bindings: {
                label: '<',
                persistChanges: '<',
                hidePipelineSelector: '<',
                onFilterChanged: '&'
            }
        });

    function controller($scope, $mdMedia, groups, users, pipelines, model, filterService, utils) {

        const vm = this;

        const today = new Date();

        vm.$mdMedia = $mdMedia;
        vm.me = model.me;
        vm.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        vm.years = [today.getUTCFullYear() - 3, today.getUTCFullYear() - 2, today.getUTCFullYear() - 1, today.getUTCFullYear(), today.getUTCFullYear() + 1, today.getUTCFullYear() + 2];
        vm.groups = [];
        vm.users = [];
        vm.pipelines = null;
        vm.currentPipeline = null;
        vm.filters = {};

        let initialFilter;

        // Do not call on initial update
        $scope.$watch('vm.range', setRange);
        $scope.$watch('vm.sFrom', setRange);
        $scope.$watch('vm.sTo', setRange);
        $scope.$watch('vm.month', setRange);
        $scope.$watch('vm.quarter', setRange);
        $scope.$watch('vm.year', setRange);
        $scope.$watch('vm.owner', setRange);

        vm.$onInit = function () {

            vm.persistChanges = vm.persistChanges !== false;

            init().then(function () {

                setRange(0, 1);
                initialFilter = Object.assign({}, vm.filters, vm.hidePipelineSelector ? {} : { pipeline: vm.currentPipeline.id });
            });
        };

        function init() {

            vm.filters = angular.copy(filterService.filters.insights);
            setFilterViewProperties(vm.filters);

            // Don't show groups when view scope is set to own data
            if (model.me.role.permissions.view !== 'OWN') {
                groups.get().then(function (response) {

                    vm.groups = response.data.map(function (group) {

                        // We create a unique id since our md-select is populated with both groups and users
                        group.uniqueId = 'group_' + group.id;
                        return group;
                    }).filter(function (group) {

                        if (model.me.role.permissions.view === 'GROUP' && model.me.group) {
                            return group.id === model.me.group;
                        }

                        return true;
                    });
                });
            }

            users.get(null, false, ['user.disabled', 'name']).then(function (response) {

                vm.users = response.data.map(function (user) {

                    // We create a unique id since our md-select is populated with both groups and users
                    user.uniqueId = 'user_' + user.id;
                    return user;
                }).filter(function (user) {

                    if (model.me.role.permissions.view === 'GROUP' && model.me.group) {
                        return user.group === model.me.group;
                    }

                    if (model.me.role.permissions.view === 'OWN') {
                        return user.id === model.me.id;
                    }

                    return true;
                });
            });

            // We don't need to trigger onFilterChanged here as `$onInit` will do this afterwards when doing `setRange` after this `init`
            return pipelines.getViewable().then(function (response) {

                vm.pipelines = response.pipelines;
                const currentPipelineId = store.get('current_pipeline_' + model.me.id);

                if (currentPipelineId) {
                    vm.currentPipeline = vm.pipelines.find(function (pipeline) {

                        return pipeline.id === currentPipelineId;
                    });
                }

                if (!vm.currentPipeline) {
                    vm.currentPipeline = vm.pipelines[0];

                    if (vm.persistChanges) {
                        filterService.setCurrentPipelineId(vm.pipelines[0].id);
                    }
                }
            });
        }

        $scope.$on('resetFilterForm', function () {

            setFilterViewProperties(initialFilter);

            if (!vm.hidePipelineSelector) {
                vm.currentPipeline = vm.pipelines.find(function (pipeline) {

                    return pipeline.id === initialFilter.pipeline;
                });
            }
        });

        vm.changePipeline = function (pipeline) {

            vm.currentPipeline = pipeline;

            if (vm.persistChanges) {
                filterService.setCurrentPipelineId(pipeline.id);
            }

            triggerOnFilterChanged();
        };

        function setFilterViewProperties(filter) {

            vm.month = angular.isDefined(filter.month) && filter.month >= 0 && filter.month <= 11 ? filter.month : today.getMonth();
            vm.quarter = angular.isDefined(filter.quarter) && filter.quarter >= 1 && filter.quarter <= 4 ? filter.quarter : Math.floor((today.getMonth() + 3) / 3).toString();
            vm.year = filter.year || new Date(vm.filter.from).getUTCFullYear();
            vm.range = filter.range;
            vm.owner = filter.owner;
            vm.sFrom = utils.UTCDateStringToLocalDateObject(filter.from?.toISOString ? filter.from.toISOString() : filter.from);
            vm.sTo = utils.UTCDateStringToLocalDateObject(filter.to?.toISOString ? filter.to.toISOString() : filter.to);
        }

        // Make sure to always use UTC dates for all the insights functionality
        function setRange(newValue, oldValue) {

            /*
             * We do the number casts on `vm.month` and `vm.year` to ensure the value is a Number
             * the `value` attribute on options of md-selects always convert the value to a String
             * We changed this to ng-value so this doesn't happen anymore, the Number casts are still here because there might still be strings in caches
             */
            /*
             * Make sure to always go from `start from date` to `end to date` e.g. `2016-12-01T00:00:00.000Z` -> `2016-12-21T23:59:59.999Z`
             */
            if (newValue !== oldValue) {
                switch (vm.range) {
                    case 'custom': {
                        if (vm.sFrom > vm.sTo) {
                            vm.sTo = vm.sFrom;
                        }

                        const sToDateAtTheEndOfTheDay = new Date(vm.sTo);
                        sToDateAtTheEndOfTheDay.setHours(23, 59, 59, 999);

                        const sFromDateAtStartOfTheDay = new Date(vm.sFrom);
                        sFromDateAtStartOfTheDay.setHours(0, 0, 0, 0);

                        vm.filters.to = utils.localDateObjectToUTCDateObject(sToDateAtTheEndOfTheDay);
                        vm.filters.from = utils.localDateObjectToUTCDateObject(sFromDateAtStartOfTheDay);
                        break;
                    }

                    case 'month':
                        vm.filters.from = new Date(Date.UTC(Number(vm.year), Number(vm.month), 1, 0, 0, 0, 0));
                        vm.filters.to = new Date(Date.UTC(Number(vm.year), Number(vm.month) + 1, 0, 23, 59, 59, 999));
                        break;
                    case 'quarter':
                        vm.filters.from = new Date(Date.UTC(Number(vm.year), (Number(vm.quarter) * 3) - 3, 1, 0, 0, 0));
                        vm.filters.to = new Date(Date.UTC(Number(vm.year), Number(vm.quarter) * 3, 0, 23, 59, 59, 999));
                        break;
                    case 'year':
                        vm.filters.from = new Date(Date.UTC(Number(vm.year), 0, 1, 0, 0, 0, 0));
                        vm.filters.to = new Date(Date.UTC(Number(vm.year), 11, 31, 23, 59, 59, 999));
                        break;
                }

                vm.filters.owner = vm.owner;
                vm.filters.range = vm.range;
                vm.filters.year = vm.year;
                vm.filters.quarter = vm.quarter;
                vm.filters.month = vm.month;

                triggerOnFilterChanged();

                if (vm.persistChanges) {
                    filterService.set('insights', vm.filters);
                }
            }
        }

        function triggerOnFilterChanged() {

            const filter = {
                to: vm.filters.to,
                from: vm.filters.from,
                range: vm.filters.range
            };

            if (vm.filters.owner.type === 'user') {
                filter.user = vm.filters.owner.id;
            }
            else {
                filter.group = vm.filters.owner.id;
            }

            if (!vm.hidePipelineSelector) {
                filter.pipeline = vm.currentPipeline && vm.currentPipeline !== 'all' ? {
                    id: vm.currentPipeline.id,
                    name: vm.currentPipeline.name,
                    recurring: vm.currentPipeline.recurring,
                    currency: vm.currentPipeline.currency
                } : null;
            }

            vm.onFilterChanged({
                $event: {
                    filter
                }
            });
        }
    }
})();
