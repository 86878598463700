(function () {
    'use strict';

    angular
        .module('salesflare.components.referralprogram.referees', [])
        .component('sfReferees', {
            templateUrl: 'app-ajs/components/referralprogram/referees/referees.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                referees: '<',
                currency: '<',
                onReferralListChange: '&'
            }
        });

    function controller($mdMedia, $mdDialog, referralsService) {

        const vm = this;
        vm.$mdMedia = $mdMedia;

        vm.statusStrings = {
            'started_trial': 'Started trial',
            'subscribed': 'Subscribed',
            'trial_expired': 'Trial expired'
        };

        const oneMonthAgo = new Date().setMonth(new Date().getMonth() - 1);

        vm.deleteReferral = function (referralId) {

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .title('Are you sure you want to delete this referral?')
                .ok('I\'m sure')
                .cancel('Cancel');

            return $mdDialog.show(confirm).then(function () {

                return referralsService.delete(referralId).then(function () {

                    return removeReferralFromView(referralId);
                });
            });
        };

        vm.moreThanOneMonthAgo = (date) => {

            if (!date) {
                return false;
            }

            return new Date(date) < oneMonthAgo;
        };


        function removeReferralFromView() {

            return vm.onReferralListChange();
        }
    }
})();
