(function () {
    'use strict';

    angular
        .module('salesflare.components.phoneNumber', [])
        .component('sfPhoneNumber', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/info/phonenumber.html',
            bindings: {
                phoneNumber: '<',
                person: '<',
                entityName: '<',
                showIcon: '<'
            }
        });

    function controller(helperFunctionsService) {

        const vm = this;

        vm.call = function (event) {

            if (vm.person) {
                return helperFunctionsService.callPerson(vm.person, vm.phoneNumber.number, event);
            }

            return helperFunctionsService.callNumber(vm.phoneNumber.number, event);
        };
    }
}());
