(function () {
    'use strict';

    angular
        .module('salesflare.components.import.list', [])
        .component('sfImportList', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/import/list/importlist.html',
            bindings: {
                importType: '@'
            }
        });

    function controller($mdMedia, $mdDialog, $state, $interval, imports, model) {

        const vm = this;
        let interval;
        const importableEntityMap = {
            account: 'accounts',
            opportunity: 'opportunities',
            contact: 'contacts'
        };

        vm.$mdMedia = $mdMedia;
        vm.complete = [];
        vm.inProgress = [];
        vm.pollingPeriod = 5;
        vm.showEmptyState = false;
        vm.me = model.me;

        vm.$onInit = function () {

            vm.state = $state;
            vm.emptyStateText = 'To import your ' + importableEntityMap[vm.importType] + ', drop a .csv file here or click the plus button.';

            return imports.getImports().then(function (response) {

                handleImportResponse(response);

                if (vm.inProgress.length === 0 && vm.complete.length === 0) {
                    vm.showEmptyState = true;
                }

                // Only poll if there are any imports that are not complete
                const startPolling = response.data.find(function (im) {

                    return im.status !== 'complete';
                });

                if (startPolling) {
                    interval = $interval(vm.poll, vm.pollingPeriod * 1000);
                }
            });
        };

        vm.poll = function () {

            return imports.getImports().then(function (response) {

                return handleImportResponse(response);
            });
        };

        vm.$onDestroy = function () {

            return $interval.cancel(interval);
        };

        vm.getSkipFile = function (completeImport) {

            return $mdDialog.show({
                clickOutsideToClose: true,
                controller: 'SkipFilePromptDialogController',
                templateUrl: 'partials/skipfilepromptdialog.html',
                bindToController: true,
                locals: {
                    importId: completeImport.userImport.id
                }
            });
        };

        /**
         * When the response from the api arrives, go to the mapping stage
         *
         * @param {Number} importId
         * @returns {void}
         */
        vm.goToMap = function (importId) {

            return $state.go('settings.importMap', { id: importId });
        };

        vm.removeImport = function (userImport, index) {

            if (userImport.status !== 'complete') {
                return imports.delete(userImport.id).then(function () {

                    vm.inProgress.splice(index, 1);
                    vm.showEmptyState = vm.inProgress.length === 0 & vm.complete.length === 0;
                });
            }

            return $mdDialog.show({
                clickOutsideToClose: true,
                templateUrl: 'partials/deleteimportdialog.html',
                controller: 'DeleteImportDialogController',
                locals: {
                    importType: userImport.type,
                    importId: userImport.id
                },
                bindToController: true
            }).then(function (result) {

                if (!result || !result.delete) {
                    return;
                }

                return imports.delete(userImport.id, result.deleteUpdated).then(function () {

                    vm.complete.splice(index, 1);
                    vm.showEmptyState = vm.inProgress.length === 0 & vm.complete.length === 0;
                });
            });
        };

        function handleImportResponse(response) {

            const allUserImports = response.data;
            const complete = [];
            const inProgress = [];

            for (const i in allUserImports) {
                if (allUserImports[i].type === vm.importType) {
                    let simpleDate = allUserImports[i].creation_date.split('T')[0];
                    simpleDate = simpleDate.split('-')[0].slice(2, 4) + '-' +
                        simpleDate.split('-')[1] + '-' + simpleDate.split('-')[2];
                    allUserImports[i].title = simpleDate + ' ' + allUserImports[i].file_name;

                    if (allUserImports[i].total_records === 0) {
                        allUserImports[i].completed = 0;
                    }
                    else {
                        allUserImports[i].completed = Math.round((allUserImports[i].processed_records / allUserImports[i].total_records) * 100);
                    }

                    if (allUserImports[i].status === 'complete') {
                        if (allUserImports[i].failed_records !== 0) {
                            allUserImports[i].skipFile = true;
                        }

                        complete.push(allUserImports[i]);
                    }
                    else {
                        inProgress.push(allUserImports[i]);
                    }
                }
            }

            vm.complete = complete;
            vm.inProgress = inProgress;
        }
    }
})();
