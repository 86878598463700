/*eslint angular/component-limit: 'off'*/
(function () {
    'use strict';

    angular
        .module('salesflare')
        .filter('interactionIcon', interactionIcon)
        .filter('helpResourceIcon', helpResourceIcon)
        .filter('attachmentIcon', attachmentIcon);

    function interactionIcon() {

        return function (input) {

            switch (input) {
                case 'document':
                    return '&#xe24d;';/*Insert_drive_file*/
                case 'webpage':
                    return '&#xe80b;';/*Public*/
                case 'forward':
                    return '&#xe157;';/*Link*/
                case 'message':
                    return '&#xe0b7;';/*Chat*/
                case 'email':
                    return '&#xe0be;';/*Email*/
                case 'meeting-live':
                    return '&#xe616;';/*Event_note*/
                case 'meeting-phone':
                    return '&#xe0cd;';/*Phone*/
                case 'meeting':
                    return '&#xe616;';/*Event_note*/
            }
        };
    }

    function helpResourceIcon() {
        return function (input) {
            switch (input) {
                case 'article':
                    return 'sf-icon-file-text';
                case 'video':
                    return 'sf-icon-video';
            }
        };
    }

    function attachmentIcon() {

        return function (input) {

            const types = input.split('/');
            const maintype = types[0];
            const subtype = types[1];

            switch (maintype) {
                case 'application':
                    switch (subtype) {
                        case 'pdf':
                            return 'sf-icon-file-pdf';
                        case 'x-zip':
                        case 'x-bzip':
                        case 'x-bzip2':
                        case 'x-zip-compressed':
                        case 'x-7z-compressed':
                        case 'x-rar-compressed':
                            return 'sf-icon-file-zip';
                        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
                        case 'msword':
                            return 'sf-icon-file-word';
                        case 'excel':
                        case 'vnd.ms-excel':
                        case 'x-excel':
                        case 'x-msexcel':
                        case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                            return 'sf-icon-file-excel';
                        case 'mspowerpoint':
                        case 'powerpoint':
                        case 'vnd.ms-powerpoint':
                        case 'x-mspowerpoint':
                        case 'vnd.openxmlformats-officedocument.presentationml.presentation':
                            return 'sf-icon-file-powerpoint';
                        default:
                            return 'sf-icon-file';
                    }

                case 'audio':
                    return 'sf-icon-file-audio';
                case 'video':
                    return 'sf-icon-file-video';
                case 'text':
                    return 'sf-icon-file-text';
                case 'image':
                    return 'sf-icon-file-image';
                default:
                    return 'sf-icon-file';
            }
        };
    }
})();
