(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('model', modelService);

    function modelService($window, $q, $exceptionHandler, $cookies) {

        const self = this;

        // Look for 'u' in url params before #, angular's $urlParams only does params after #
        const urlParams = {};
        const from = $window.location.href.indexOf('?') + 1;
        const to = $window.location.href.indexOf('#');

        let hashes;
        if (to === -1) {
            hashes = $window.location.href.slice(from).split('&');
        }
        else {
            hashes = $window.location.href.slice(from, to).split('&');
        }

        for (const hash of hashes) {
            const hashSplit = hash.split('=');
            urlParams[hashSplit[0]] = hashSplit[1];
        }

        // Url parameter u is the authenticated gmail user
        // This allows you to use multiple log-ins in multiple gmail tabs
        // by appending the user id (mail.google.com/u/{id}) to the key
        let key = 'token';
        if (urlParams.u) {
            key += urlParams.u;
        }

        this.token = null;
        this.me = null;

        // Get the Google Analytics Client Id from the cookies.
        this.gaClientId = $cookies.get('_ga')?.split('.').slice(2).join('.');

        // On mobile we use bearer auth
        // `isLoggedIn` also means something else depending on the strategy
        //      The actual end result is the same as `authentication.fetchMe` will handle both cases
        //      And is different/important to set right for initialization of the app
        if ($window.isMobile) {
            this.authStrategy = 'bearer';
            this.isLoggedIn = !!store.get(key);
        }
        else {
            this.authStrategy = 'cookie';
            this.isLoggedIn = !!this.me;
        }

        // Init cache
        let cacheStore;
        if (store.enabled) {
            cacheStore = new Cache.LocalStorageCacheStorage(this.me && this.me.id);
        }
        else {
            cacheStore = new Cache.BasicCacheStorage();
        }

        this.cache = new Cache(-1, false, cacheStore);

        this.getToken = function () {

            return store.get(key);
        };

        /**
         *
         * @param {'bearer'|'cookie'} authStrategy
         */
        this.setAuthStrategy = (authStrategy) => {

            this.authStrategy = authStrategy;
            this.isLoggedIn = !!store.get(key);
        };

        this.setToken = function (token) {

            // We only set the token when we are using the bearer strategy
            // This prevents setting the token while using cookie auth which would expose the token through local storage
            if (self.authStrategy === 'bearer') {
                self.isLoggedIn = true;
                return store.set(key, token);
            }

            return false;
        };

        this.initCache = function (userId) {

            if (!self.cache) {
                self.cache = new Cache(-1, false, new Cache.LocalStorageCacheStorage(userId));
            }
        };

        this.clear = function () {

            return $q(function (resolve) {

                self.isLoggedIn = false;

                // Do not remove notificationToken since next login also needs it
                store.remove(key);

                try {
                    self.cache.clear();
                }
                catch (err) {
                    // Jscache throws a SecurityError when removing an item from the cache when third party cookies are disabled in the browser
                    // Log the error and continue
                    if (err.message === 'Failed to read the \'localStorage\' property from \'Window\': Access is denied for this document.') {
                        $exceptionHandler(err);
                    }
                    else {
                        throw err;
                    }
                }

                delete self.me;
                return resolve();
            });
        };

        this.setCurrentPipelineId = (pipelineId) => {

            store.set('current_pipeline_' + this.me.id, pipelineId);
        };

        this.getCurrentPipelineId = () => {

            return Number(store.get('current_pipeline_' + this.me.id));
        };
    }
})();
