(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('WalkthroughController', WalkthroughController);

    function WalkthroughController($rootScope, $scope, $state, $stateParams, $timeout, $transitions, $mdDialog, statusBarService, sfWalkthrough, model, config, utils, authentication, flagsService) {

        $scope.isLoggedIn = model.isLoggedIn;
        $scope.isCompleted = false;
        // Used when pressing the browser's back button during the walkthrough
        $scope.fromState = $stateParams.state;
        // Used to hide the first screen, needed when instantly redirecting to the account feed steps
        $scope.loading = true;

        let manuallySkipped = false;

        if ($stateParams.skip) {
            return sfWalkthrough.goToState('tasks.today', true);
        }

        init();

        function init() {

            // Don't show status bar during WT
            statusBarService.setCanBeVisible(false);

            sfWalkthrough.init().then(() => {

                // When coming from the opportunities view of the walkthrough, we directly want to go the account feed
                if ($scope.fromState === 'opportunities') {
                    start();
                }

                $scope.loading = false;
            });

            $timeout(function () {

                return authentication.fetchMe({ preventLogoutOnUnsuccessfulAuth: true }).then($rootScope.$apply()).catch(angular.noop);
            });

            if (config.intercom) {
                Intercom('boot', {
                    app_id: config.intercom.appId,
                    hide_default_launcher: true, // We only ever want to open Intercom via our custom launcher, this prevents the bubble from showing for second and then hiding
                    custom_launcher_selector: '#intercom-chat-button'
                });
            }

            // Always show the user benefits when doing the offline walkthrough (except when pressing the browser's back button on the opportunities view)
            // Otherwise only show when the flag hasn't been set yet or when pressing the browser's back button from the account feed view
            if ((!model.isLoggedIn && $scope.fromState !== 'opportunities') || flagsService.get('userBenefits') === false || $scope.fromState === 'accounts') {
                $mdDialog.show({
                    fullscreen: true,
                    escapeToClose: false,
                    controller: 'UserBenefitsDialogController',
                    templateUrl: 'partials/userbenefits-dialog.html'
                }).then(() => {

                    if (model.isLoggedIn) {
                        flagsService.set('userBenefits', true);
                    }
                });
            }
        }

        $scope.startWalkthrough = function () {

            start();
        };

        function start() {
            sfWalkthrough.shepherdTour.on('complete', function () {

                if ($scope.isCompleted) {
                    return;
                }

                sfWalkthrough.setCompleted();

                $scope.isCompleted = true;
                $rootScope.fireworks = true;
                $rootScope.showBackdrop = true;

                // Timeout to make sure the elements we need next are in the DOM.
                $timeout(function () {
                    $rootScope.$apply();

                    const congratulationsElement = angular.element('#congratulations-message')[0];
                    const startTrialMessageElement = angular.element('#start-trial-message')[0];
                    const equippedMessageElement = angular.element('#get-equipped-message')[0];

                    let next = signup;

                    if (model.isLoggedIn) {
                        next = getEquipped;
                    }

                    return utils.animations.fadeIn(congratulationsElement, 1000, function () {

                        return utils.animations.fadeOut(congratulationsElement, 1500, next);
                    });

                    function signup() {

                        return utils.animations.fadeIn(startTrialMessageElement, 1000, function () {

                            return utils.animations.fadeOut(startTrialMessageElement, 1500, function () {

                                $rootScope.fireworks = false;

                                return sfWalkthrough.stopWalkthrough('opportunities.stages');
                            });
                        });
                    }

                    function getEquipped() {

                        return utils.animations.fadeIn(equippedMessageElement, 1000, function () {

                            return utils.animations.fadeOut(equippedMessageElement, 1500, function () {

                                $rootScope.fireworks = false;

                                if (flagsService.get('gamifiedTrial') === true) {
                                    return sfWalkthrough.stopWalkthrough('opportunities.stages');
                                }

                                return $mdDialog.show({
                                    fullscreen: true,
                                    escapeToClose: false,
                                    controller: 'WalkthroughDialogController',
                                    templateUrl: 'partials/walkthrough-dialog.html'
                                }).finally(function () {

                                    return sfWalkthrough.stopWalkthrough('opportunities.stages');
                                });
                            });
                        });
                    }
                });
            });

            if (model.isLoggedIn) {
                sfWalkthrough.setSkippedWalkthrough('no_not_finished');
            }

            return $state.go('accounts.account.feed', { id: 1 }).then(function () {

                sfWalkthrough.start();
            }).catch(function () {

                sfWalkthrough.start();
            });
        }

        $scope.skipWalkthrough = function (reason) {

            if (reason === 'manual') {
                manuallySkipped = true;
            }

            // Team needs to be updated
            sfWalkthrough.stopWalkthrough('accounts', reason);
        };

        const removeStartTransitionHook = $transitions.onStart({}, (transition) => {

            const toState = transition.to();
            const toParams = transition.params('to');

            // Set `skipped_walkthrough` to `auto` when moving to a state that doesn't start the walkthrough
            // This can happen when the screen is too small to trigger the walkthrough and you click on the main menu
            if (!manuallySkipped && (toState.name !== 'accounts.account.feed' || toParams.id !== 1)) {
                return sfWalkthrough.setSkippedWalkthrough('auto');
            }
        });

        $scope.$watch(function () {
            return location.hash;
        }, function (newVal, oldVal) {

            if (newVal.includes('skip=true') && !oldVal.includes('skip=true')) {
                return $state.go('tasks.today', {}, { reload: true });
            }
        });

        $scope.$on('$destroy', function () {

            return removeStartTransitionHook();
        });
    }
})();
