import { MatDialog } from '@angular/material/dialog';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from "angular";

(function () {
    'use strict';

    angular
        .module('salesflare.downgraded.services.matDialogService', [])
        .factory('matDialogService', downgradeInjectable(MatDialog));
})();
