(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('network', networkService);

    function networkService(sfHttp, config) {

        this.get = function (options) {

            const url = config.apiUrl + 'network';

            const request = {
                params: options
            };

            return sfHttp.get(url, request);
        };
    }
})();
