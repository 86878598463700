(function () {
    'use strict';

    angular
        .module('salesflare.components.pivotTable', [])
        .component('sfPivotTable', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/dashboards/pivotTable/pivotTable.html',
            bindings: {
                data: '<',
                label: '<'
            }
        });

    function controller() {

        const vm = this;

        ////////////////

        vm.$onChanges = function () {

            if (vm.data) {
                vm.columns = vm.data.columns;
                vm.rows = vm.data.rows;
                vm.values = vm.data.values;
            }
        };
    }
})();
