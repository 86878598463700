<sfx-toolbar enableDivider>
    Sign in & Security
</sfx-toolbar>

<sfx-content>
    <sfx-header>
        {{ PasswordSectionTitle }}
    </sfx-header>
    <sfx-section>
        <form #passwordForm="ngForm" id="passwordForm" name="passwordForm" class="password-form-container" data-layout="column" (ngSubmit)="onSubmit()">

            <div class="new-password-form-field-wrapper">
                <mat-form-field class="new-password-form-field" data-lpignore="true">
                    <mat-label>New Password</mat-label>
                    <input #newPasswordInput="ngModel" matInput type="password" name="newPassword" [(ngModel)]="newPassword" minlength="12" maxlength="100" required
                           (ngModelChange)="evaluatePassword()"
                    />
                    <mat-error *ngIf="newPasswordInput.hasError('required')">
                        Password is required.
                    </mat-error>
                    <mat-error *ngIf="newPasswordInput.hasError('minlength')">
                        Make sure your password is at least 12 characters long.
                    </mat-error>
                    <mat-error *ngIf="newPasswordInput.hasError('maxlength')">
                        Make sure your password is at most 100 characters long.
                    </mat-error>
                    <mat-error *ngIf="newPasswordInput.hasError('passwordStrength')
                && !newPasswordInput.hasError('minlength') && !newPasswordInput.hasError('maxlength')">
                        Please choose a stronger password.
                    </mat-error>
                </mat-form-field>
                <sfx-password-meter #passwordMeter [strength]="strength"></sfx-password-meter>
            </div>

            <mat-form-field class="confirm-password-form-field">
                <mat-label>Confirm Password</mat-label>
                <input #confirmPasswordInput="ngModel" matInput type="password" name="confirmPassword" [(ngModel)]="confirmPassword" [match]="passwordForm.controls['newPassword']" required/>
                <mat-error *ngIf="confirmPasswordInput.hasError('required')">
                    Password confirmation is required.
                </mat-error>
                <mat-error *ngIf="confirmPasswordInput.hasError('match')">
                    Your passwords do not match.
                </mat-error>
            </mat-form-field>
            <div>
                <button mat-button type="submit" [disabled]="passwordForm.invalid">Save</button>
            </div>
        </form>
    </sfx-section>
    <!-- Don't show two-factor auth section when setting a new password with a password reset link -->
    <sfx-header *ngIf="!HasToken()">
        Two-factor authentication
    </sfx-header>
    <sfx-section *ngIf="!HasToken()">
        <div>
            <mat-slide-toggle (change)="twoFAChange($event)" [checked]="twoFAEnabled" data-flex class="enable-2fa-toggle">
                Enable two-factor authentication
            </mat-slide-toggle>
        </div>
        <div>
            <button mat-button (click)="showRecoveryCodes()" [disabled]="!twoFAEnabled">Show Recovery Codes</button>
        </div>
    </sfx-section>
</sfx-content>
