import { Component, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { cloneDeep } from 'lodash';
import { MediaService } from '@core/services/media.service';

@Component({
    selector: 'sfx-permissions',
    templateUrl: './permissions.component.html',
    styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {
    @ViewChildren('pipelineGroupsSelect') pipelineGroupsSelect: any;
    @ViewChild('pipelinePermissionsForm') pipelinePermissionsForm: any;

    accessScopes: any[] = [];
    originalRolePermissions: any[] = [];
    originalPipelinePermissions: any[] = [];
    groups: any[] = [];
    pipelines: any[any] = [];
    roles: any[any] = [];

    isAdmin: boolean = this.modelService.me.role.name === 'Admin';
    isManager: boolean = this.modelService.me.role.name === 'Manager';

    constructor(
        @Inject('dialogService') private dialogService: any,
        @Inject('utilsService') private utilsService: any,
        @Inject('modelService') public modelService: any,
        @Inject('teamService') private teamService: any,
        @Inject('groupsService') private groupsService: any,
        @Inject('permissionService') private permissionService: any,
        @Inject('authenticationService') private authenticationService: any,
        @Inject('upgradeDialogService') private upgradeDialogService: any,
        @Inject('pipelinesService') private pipelinesService: any,
        public media: MediaService
    ) {}

    ngOnInit(): void {

        this.permissionService.getAllAccessScopes().then((response: any) => {

            this.accessScopes = response.data;
        });

        this.groupsService.get().then((response: any) => {

            this.groups = response.data.filter((group: any) => !!group.id).map((group: any) => {

                return { id: group.id, name: group.name };
            });
        });

        this.pipelinesService.get().then((response: any) => {

            this.pipelines = response.data;
            this.originalPipelinePermissions = response.data.map((pipeline: any) => {

                return { id: pipeline.id, groups: cloneDeep(pipeline.groups) };
            });
        });

        this.permissionService.getAllRoles().then((response: any) => {

            this.roles = response.data;
            this.originalRolePermissions = [...this.roles.map((role: any) => {

                return {
                    id: role.id,
                    role_permissions: role.role_permissions.map((permission: any) => {

                        return Object.assign({}, permission);
                    })
                };
            })];

            this.roles.forEach((role: any) => {

                if (!role.users || role.users.length === 0) {
                    role.userText = ('No one currently has the ' + role.name.toLowerCase() + ' role');
                    return;
                }

                let userText = 'This is currently ';

                if (role.name === 'Manager') {
                    userText = ('The ' + role.name.toLowerCase() + 's include ');
                }

                role.userText = (userText + role.users.map((user: any) => {

                    return ('<b>' + user.name + '</b>');
                }).join(', ')).replace(/, ([^,]*)$/, ' and $1');
            });
        });
    }

    public compareGroups = (o1: any, o2: any) => {

        return o1?.id === o2?.id;
    }

    public showPlanFlags = () => {

        const onTrial = this.modelService.me.team.payment_type !== 'free' && !this.modelService.me.team.subscribed && this.modelService.me.trial_expiry_date;
        return (onTrial || !this.modelService.me.plan_flags.permissions);
    }

    public updateRolePermissions = (roleId: number, rolePermissions: any[], updateAction: string) => {

        if (!this.modelService.me.plan_flags.permissions) {
            const storedPermissions = this.originalRolePermissions.find((rolePermission: any) => {

                return rolePermission.id === roleId;
            });
            const originalValue = storedPermissions.role_permissions.find((storedPermission: any) => {

                return storedPermission.action === updateAction;
            });
            const newValue = rolePermissions.find((storedPermission: any) => {

                return storedPermission.action === updateAction;
            });
            newValue.scope = originalValue.scope;

            return this.upgradeDialogService.show('permissions');
        }

        const cedRolePermission = rolePermissions.find((rolePermission: any) => {

            return rolePermission.action === 'CED';
        });
        const viewRolePermission = rolePermissions.find((rolePermission: any) => {

            return rolePermission.action === 'VIEW';
        });
        const cedAccessScope = this.accessScopes.find((accessScope: any) => {

            return accessScope.id === cedRolePermission.scope;
        });
        const viewAccessScope = this.accessScopes.find((accessScope: any) => {

            return accessScope.id === viewRolePermission.scope;
        });
        const isViewScopeTooRestrictive = (viewAccessScope.restrictiveness > cedAccessScope.restrictiveness);

        if (isViewScopeTooRestrictive) {
            if (updateAction === 'VIEW') {
                cedRolePermission.scope = viewRolePermission.scope;
            }
            else if (updateAction === 'CED') {
                viewRolePermission.scope = cedRolePermission.scope;
            }
        }

        const update: any = {
            role_permissions: rolePermissions.map(function (rolePermission) {

                return {
                    action: rolePermission.action,
                    scope: rolePermission.scope
                };
            })
        };

        this.permissionService.updateRole(roleId, update).then(() => {

            // Fetch me again to update new permissions on model.me
            this.authenticationService.fetchMe();

            this.utilsService.showSuccessToast('Role permissions updated.');
        });
    };

    public updatePipelineGroupAccess = (pipeline: any) => {

        if (!['Admin', 'Manager'].includes(this.modelService.me.role.name)) {
            return;
        }

        if (this.pipelinePermissionsForm.controls[`pipeline-${pipeline.id}`].invalid) {
            return;
        }

        const originalPipeline = this.originalPipelinePermissions.find((pipelinePermissions: any) => {

            return pipelinePermissions.id === pipeline.id;
        });

        if (!this.modelService.me.plan_flags.permissions) {
            pipeline.groups = cloneDeep(originalPipeline.groups);
            this.pipelineGroupsSelect.forEach((select: any) => select.close());
            return this.upgradeDialogService.show('permissions');
        }

        const pipelineCopy = cloneDeep(pipeline);

        if (this.modelService.me.group) {
            const updatedPipelineGroupsContainOwnGroup = !!(pipeline.groups.some((group: any) => {

                return group.id === this.modelService.me.group;
            }));
            const originalPipelineGroupsContainOwnGroup = !!(originalPipeline.groups.some((group: any) => {

                return group.id === this.modelService.me.group;
            }));

            if (originalPipelineGroupsContainOwnGroup && !updatedPipelineGroupsContainOwnGroup) {
                const confirmGroupRemoval = this.dialogService.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title('Are you sure?')
                    .htmlContent('If you remove access for your own group, you will not be able to see this pipeline anymore.')
                    .ok('I\'m sure')
                    .cancel('cancel');

                this.pipelineGroupsSelect.forEach((select: any) => select.close());

                return this.dialogService.show(confirmGroupRemoval).then(() => {

                    pipelineCopy.groups = pipeline.groups.map((group: any) => {

                        return group.id;
                    });

                    return this.pipelinesService.update(pipelineCopy).then(() => {

                        originalPipeline.groups = cloneDeep(pipeline.groups);
                    });
                }).catch(() => {

                    pipeline.groups = cloneDeep(originalPipeline.groups);
                });
            }
        }

        pipelineCopy.groups = pipeline.groups.map((group: any) => {

            return group.id;
        });

        return this.pipelinesService.update(pipelineCopy).then(() => {

            originalPipeline.groups = pipeline.groups;
        });
    };

    public getAccessScopes = (rolePermissions: any[any], action: 'CED' | 'NONE') => {

        // Initialize first
        if (!this.accessScopes) {
            return;
        }

        if (action === 'CED') {
            return this.accessScopes;
        }

        return this.accessScopes.filter((accessScope) => {

            return accessScope.id !== 'NONE';
        });
    };

    public getAccessScopeName = (scope: string) => {

        return this.accessScopes.find((accessScope) => accessScope.id === scope)?.name;
    }

    public openUpgradeDialog = () => {

        this.upgradeDialogService.show('permissions');
    }
}
