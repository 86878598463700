(function () {
    'use strict';

    angular
        .module('salesflare.components.filters', [
            'salesflare.components.filters.group',
            'salesflare.components.filters.rule',
            'salesflare.components.filters.operator'
        ])
        .component('sfFilters', {
            template: '<ng-include src="\'app-ajs/components/filters/filters.html\'"></ng-include>',
            controllerAs: 'vm',
            controller,
            bindings: {
                filters: '<',
                pipeline: '<',
                allPipelines: '<',
                hideToolbar: '<',
                hideSavedFilters: '<',
                entity: '<',
                group: '<',
                filterHeaderText: '<',
                filterFields: '<',
                onFilterApply: '&',
                onClose: '&',
                getDefaultFilters: '&',
                customErrorMessage: '@',
                setErrorMessage: '&'
            }
        });

    function controller($scope, $mdSidenav, $mdDialog, $mdMedia, $filter, $timeout, flagsService, filterService, savedFilterService, savedFiltersService, utils, sfDiscardDialog) {

        const vm = this;

        vm.filterGroups = [];
        vm.index = 0;
        vm.showBadFilterInfo = false;
        vm.faultyFilterRules = {};
        vm.savedFilters = [];
        vm.appliedSavedFilter = null;
        // This is the model used for the saved filter md-select
        // Since we sometimes have to show a dialog before actually changing the value of the model of the md-select we use this model as a buffer
        // When the md select model changes we have time to do the necessary checks and then decide whether we apply those changes to vm.appliedSavedFilter and actually apply the filter
        vm.savedFilterMdSelectModel = null;

        $scope.$on('resetFilterPane', (event, args) => {

            this.resetFilterGroups(args);
        });

        $scope.$on('setFilters', (event, args) => {

            /*
            Vm.filterGroups = [{
                index: vm.index,
                filterRules: args
            }];
             */
            const newRules = filterService.flatten(args);
            const oldRules = filterService.flatten(vm.filterGroups[0].filterRules);

            const mergedRules = filterService.intersectAndEnrich(oldRules, newRules);

            vm.filterGroups[0].filterRules = mergedRules;

            $scope.$broadcast('setFilterGroupRules', {
                index: 0,
                filterRules: mergedRules
            });

            vm.checkForErrors(mergedRules);
        });

        vm.$onInit = function () {

            const firstFilterGroup = {
                index: vm.index,
                filterRules: []
            };

            vm.entityPlural = vm.entity === 'opportunity' ? 'opportunities' : vm.entity + 's';
            vm.filterHeaderText = vm.filterHeaderText || (vm.entityPlural.charAt(0).toUpperCase() + vm.entityPlural.slice(1) + ' match all of these filters');

            vm.faultyFilterRules.firstLine = 'It looks like you are using filters that include custom fields, custom field values or entities that have been deleted.';
            vm.faultyFilterRules.secondLine = 'Please update the filters to continue.';

            if (vm.savedFiltersEnabled()) {
                getSavedFilters();
            }

            if (!vm.filterFields) {
                const options = {
                    allPipelines: vm.allPipelines,
                    pipelineId: vm.pipeline
                };

                return filterService.loadFilterFields(vm.entity, options)
                    .then(function (response) {

                        if (response) {
                            vm.filterFields = response;

                            vm.filterGroups.push(firstFilterGroup);
                            vm.filterGroups[0].filterRules = vm.filters;

                            if (vm.savedFiltersEnabled()) {
                                getSavedFilters();
                            }

                            vm.checkForErrors(vm.filters);
                        }
                    });
            }

            vm.filterGroups.push(firstFilterGroup);
            vm.filterGroups[0].filterRules = vm.filters;

            if (vm.savedFiltersEnabled()) {
                getSavedFilters();
            }

            vm.checkForErrors(vm.filters);
        };

        vm.checkForErrors = (filters) => {

            if (angular.isDefined(filters)) {

                let errorMessage = filterService.parseErrorMessageFromRules(filters);

                vm.faultyFilterRules.firstLine = errorMessage;
                vm.showBadFilterInfo = !!errorMessage;

                if (vm.customErrorMessage) {

                    errorMessage = filterService.parseErrorMessageFromRules(filters, vm.customErrorMessage);
                }

                vm.setErrorMessage({
                    $event: {
                        message: errorMessage
                    }
                });
            }
        };

        vm.$onChanges = function (changes) {

            // We want to reload filter fields whenever the following properties change: pipeline, allPipelines, entity
            if (
                (changes.pipeline && changes.pipeline.previousValue && !angular.equals({}, changes.pipeline.previousValue)) ||
                (changes.allPipelines && changes.allPipelines.previousValue && !angular.equals({}, changes.allPipelines.previousValue)) ||
                (changes.entity && changes.entity.previousValue && !angular.equals({}, changes.entity.previousValue))
            ) {

                return reloadFilterFields();
            }
        };

        function reloadFilterFields() {

            const options = {
                allPipelines: vm.allPipelines,
                pipelineId: vm.pipeline
            };
            return filterService.loadFilterFields(vm.entity, options)
                .then(function (response) {

                    if (response) {
                        vm.filterFields = response;
                    }
                });
        }

        vm.savedFiltersEnabled = function () {

            return flagsService.get('savedFilters') && !vm.hideSavedFilters;
        };

        vm.onSelectSavedFilter = function (savedFilter) {

            if (savedFilter && savedFilter.id && vm.appliedSavedFilter && vm.appliedSavedFilter.id && vm.appliedSavedFilter.id === savedFilter.id) {
                return;
            }

            if (!savedFilter && !vm.appliedSavedFilter) {
                return;
            }

            if (!vm.isSavedFilterDirty) {
                vm.appliedSavedFilter = savedFilter;
                return onSelectSavedFilter(savedFilter);
            }

            return sfDiscardDialog.show(false, 'saved filter').then(function () {

                vm.appliedSavedFilter = savedFilter;
                return onSelectSavedFilter(savedFilter);
            }).catch(function () {

                vm.savedFilterMdSelectModel = vm.appliedSavedFilter;
            });
        };

        function onSelectSavedFilter(savedFilter) {

            filterService.setSavedFilterId((savedFilter && savedFilter.id) || null, vm.entity);

            if (savedFilter === null) {
                // Do on next text so md-select's md-selected-text updates nicely
                return $timeout(function () {

                    resetFilterRules();
                    vm.applyFilters();
                });
            }

            return savedFilterService.get(savedFilter.id, vm.entity).then(function (response) {

                const firstFilterGroup = {
                    index: 0,
                    filterRules: []
                };
                vm.filters = response.data.filter_config.rules;
                vm.appliedSavedFilter.filter_config = response.data.filter_config;

                vm.filterGroups = [firstFilterGroup];
                vm.filterGroups[0].filterRules = vm.filters;
                vm.filters.forEach(function (rule) {

                    rule.disabled = false;
                });
                if (angular.isDefined(vm.filters)) {
                    vm.showBadFilterInfo = !!vm.filters.some(function (rule) {

                        return rule.disabled;
                    });
                }

                vm.applyFilters();
            });
        }

        vm.saveFilter = function () {

            const saveFilterDialogConfig = $mdDialog.saveFilterDialog({
                locals: {
                    savedFilter: {
                        name: vm.appliedSavedFilter && vm.appliedSavedFilter.name,
                        shared: vm.appliedSavedFilter ? vm.appliedSavedFilter.shared : true
                    },
                    showUpdateAndCreateChoice: !!vm.appliedSavedFilter,
                    entity: vm.entity
                },
                fullscreen: !$mdMedia('gt-sm')
            });

            return $mdDialog.show(saveFilterDialogConfig).then(function (savedFilterConfig) {

                const filter = {
                    name: savedFilterConfig.name,
                    shared: savedFilterConfig.shared,
                    filter_config: {
                        condition: 'AND',
                        rules: getRules()
                    }
                };
                const filterForHttp = angular.copy(filter);
                filterForHttp.filter_config.rules = filterService.cleanAdvancedFilterForHttp(filterForHttp.filter_config.rules);

                if (savedFilterConfig.saveAction === 'UPDATE_EXISTING') {
                    return savedFilterService.update(vm.appliedSavedFilter.id, vm.entity, filterForHttp).then(function () {

                        Object.assign(vm.appliedSavedFilter, filter);
                        vm.isSavedFilterDirty = false;

                        return utils.showSuccessToast('Saved filter updated.');
                    });
                }

                return savedFiltersService.create(vm.entity, filterForHttp).then(function (response) {

                    filter.id = response.data.id;
                    vm.appliedSavedFilter = filter;
                    vm.savedFilterMdSelectModel = filter;
                    vm.savedFilters.push(filter);
                    filterService.setSavedFilterId(vm.appliedSavedFilter.id, vm.entity);

                    vm.isSavedFilterDirty = isSavedFilterDirty();

                    return utils.showSuccessToast('Created a new saved filter.');
                });
            });
        };

        vm.manageSavedFilters = function () {

            return $mdDialog.show({
                controllerAs: 'vm',
                fullscreen: !$mdMedia('gt-sm'),
                multiple: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                templateUrl: 'app-ajs/components/managesavedfiltersdialog/managesavedfiltersdialog.html',
                controller: 'ManageSavedFiltersDialogController',
                locals: {
                    savedFilters: vm.savedFilters,
                    entity: vm.entity
                },
                bindToController: true
            }).finally(getSavedFilters);
        };

        // Close filter screen
        vm.toggleMenu = function () {

            vm.onClose();

            return $mdSidenav('filters').toggle();
        };

        // Not being used right now, might use later on
        vm.addFilterGroup = function () {

            const newFilterGroup = {
                index: vm.index,
                filterRules: []
            };
            vm.index += 1;

            vm.filterGroups.push(newFilterGroup);
        };

        // Not being used right now, might use later on
        vm.removeFilterGroup = function (filterGroupIndex) {

            const filterGroup = vm.filterGroups.find(function (group) {
                return group.index === filterGroupIndex;
            });

            const arrayIndex = vm.filterGroups.indexOf(filterGroup);
            if (arrayIndex !== -1 ) {
                vm.filterGroups.splice(arrayIndex, 1);
            }
        };

        // Gets called when one of the child components gets changed
        vm.updateFilterGroup = function (groupIndex, updatedGroups) {

            vm.showBadFilterInfo = !!updatedGroups.some(function (rule) {

                return rule.disabled;
            });

            const foundGroup = vm.filterGroups.find(function (group) {

                return groupIndex === group.index;
            });

            const arrayIndex = vm.filterGroups.indexOf(foundGroup);
            vm.filterGroups[arrayIndex].filterRules = angular.copy(updatedGroups);

            if (vm.filterGroups.length === 1 && vm.filterGroups[0].filterRules.length === 0) {
                vm.resetFilterGroups(true);
            }

            vm.checkForErrors(updatedGroups);
        };

        // Gets called by child components
        vm.applyFilters = function () {

            vm.onFilterApply({
                $event: {
                    rules: getRules(),
                    saved_filter_id: vm.appliedSavedFilter && vm.appliedSavedFilter.id
                }
            });

            vm.checkForErrors(getRules());

            vm.isSavedFilterDirty = isSavedFilterDirty();
        };

        vm.resetFilterGroups = function (noDefaultFilters) {

            if (!noDefaultFilters && vm.filterGroups.length > 0 && vm.filterGroups[0].filterRules !== vm.getDefaultFilters()) {
                const confirm = $mdDialog.confirm({ multiple: true })
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title('Are you sure you want to reset this filter?')
                    .textContent('You will lose your current progress.')
                    .ok('I\'m sure')
                    .cancel('cancel');

                return $mdDialog.show(confirm).then(function () {

                    resetFilterRules(noDefaultFilters);
                    vm.checkForErrors(getRules());
                    return vm.applyFilters();
                });
            }

            resetFilterRules(noDefaultFilters);
            return vm.applyFilters();
        };

        function resetFilterRules(noDefaultFilters) {

            vm.filterGroups = [];

            vm.index = 0;

            let filterRules = noDefaultFilters ? [] : (vm.appliedSavedFilter ? vm.appliedSavedFilter.filter_config.rules : (vm.getDefaultFilters() || []));
            filterRules = filterRules.map(function (rule) {
                // Do this to make sure applyFilters actually applies the new rules
                rule.disabled = false;
                return rule;
            });

            const firstFilterGroup = {
                index: vm.index,
                filterRules
            };
            vm.index += 1;

            vm.filterGroups.push(firstFilterGroup);
        }

        function getRules() {

            if (vm.filterGroups[0].filterRules.length === 0) {
                return [];
            }

            vm.filterGroups[0].filterRules = vm.filterGroups[0].filterRules.filter((rule) => {
                return rule.id || rule.operator;
            });

            let rules = angular.copy(vm.filterGroups[0].filterRules);

            // Only apply rules when all rules have been enabled / disabled
            const notReady = rules.find(function (rule) {

                return (rule.disabled !== false && rule.disabled !== true);
            });

            if (notReady) {
                return;
            }

            // Prep the rules for interpretation by the API
            rules = rules.map(function (rule) {

                if (rule.input === 'date' && rule.operator ) {
                    if (rule.operator === 'after' || rule.operator === 'on' || rule.operator === 'before') {
                        rule.value = $filter('date')(rule.value, 'yyyy-MM-dd');
                    }
                }

                return rule;
            });

            // Remove incomplete rules before sending them to the API
            const rulesToRemove = [];
            if (rules && rules.length > 0) {
                rules.forEach(function (rule, rulesIndex) {

                    delete rule.index;
                    if (!rule.id || !rule.operator
                        || (!rule.value && rule.value !== 0)
                        || rule.value.length === 0
                        || rule.operator === 'between'
                        || rule.operator === 'not_between') {

                        if (rule.operator && (rule.operator === 'between' || rule.operator === 'not_between')) {
                            if (!angular.isArray(rule.value) || rule.value.length !== 2
                                || (angular.isUndefined(rule.value[0]) || rule.value[0] === null)
                                || (angular.isUndefined(rule.value[1]) || rule.value[1] === null)) {

                                rulesToRemove.push(rulesIndex);
                            }
                        }
                        else if (!!rule.operator
                            && (rule.operator !== 'is_empty' && rule.operator !== 'is_not_empty'
                                && rule.operator !== 'is_null' && rule.operator !== 'is_not_null'
                                && rule.operator !== 'true' && rule.operator !== 'false')) {
                            rulesToRemove.push(rulesIndex);
                        }
                        else if (!rule.operator) {
                            rulesToRemove.push(rulesIndex);
                        }
                    }

                    delete rule.disabled;
                });

                rulesToRemove.reverse();
                // Splice the rules that need to be removed
                rulesToRemove.forEach(function (ruleIndex) {

                    rules.splice(ruleIndex, 1);
                });

                return rules;
            }
        }

        function getSavedFilters() {

            return savedFiltersService.get(vm.entity).then(function (response) {

                vm.savedFilters = response.data;

                const appliedSavedFilterId = filterService.getSavedFilterId(vm.entity);

                if (!appliedSavedFilterId) {
                    vm.isSavedFilterDirty = isSavedFilterDirty();
                    return;
                }

                const foundFilter = vm.savedFilters.find(function (savedFilter) {

                    return savedFilter.id === appliedSavedFilterId;
                });

                if (foundFilter) {
                    return savedFilterService.get(appliedSavedFilterId, vm.entity).then(function (filterResponse) {

                        vm.savedFilterMdSelectModel = filterResponse.data;
                        vm.appliedSavedFilter = filterResponse.data;

                        vm.isSavedFilterDirty = isSavedFilterDirty();
                    });
                }
            });
        }

        function isSavedFilterDirty() {

            if (vm.hideSavedFilters) {
                return;
            }

            if (!vm.appliedSavedFilter) {
                return filterService[('is' + (vm.entity.charAt(0).toUpperCase() + vm.entity.slice(1))  + 'FilterApplied')]();
            }

            return !filterService.isFilterEqual(vm.appliedSavedFilter.filter_config.rules, vm.filterGroups[0].filterRules);
        }
    }
})();
