(function () {
    'use strict';

    angular
        .module('salesflare.components.quotaPerRep', [])
        .component('sfQuotaPerRep', {
            templateUrl: 'app-ajs/components/dashboards/predefinedReports/quotaPerRep/quotaPerRep.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                data: '<'
            }
        });

    function controller(model) {

        const vm = this;

        vm.model = model;

        ////////////////
    }
})();
