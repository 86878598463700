<mat-toolbar
        [color]="Theme"
        [ngClass]="{'sf-watermark' : (!!this.dialogToolbar || !media.isActive('gt-sm')) && !this.disableWatermark}"
>
    <mat-toolbar-row data-layout="row" data-layout-align="start center" data-layout-gap="16px">
        <mat-icon track-id="close" fontSet="salesflare" fontIcon="sf-icon-close" class="button" (click)="back()"
                  *ngIf="!media.isActive('gt-sm') && !this.dialogToolbar && !this.disableBack"></mat-icon>
        <span class="ellipsis content-container" data-flex-layout="center center" [ngStyle]="{'height': height, 'width': width}">
            <ng-content>

            </ng-content>
        </span>
        <mat-icon id="dialog-close-icon" track-id="close" fontSet="salesflare" fontIcon="sf-icon-close" class="button" (click)="back()"
                  *ngIf="this.dialogToolbar && !this.disableBack"></mat-icon>
    </mat-toolbar-row>
</mat-toolbar>
<mat-divider *ngIf="enableDivider"></mat-divider>
