import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from '@core/services/notifications.service';

@Injectable({
    providedIn: 'root'
})
export class ClipboardNotificationService {

    constructor(
        private _snackBar: MatSnackBar,
        private notificationService: NotificationService
    ) {}


    handleClipboard(event: any) {
        // The CDK clipboard service creates a temporary textarea with left style '-999em' so I use it here to check if the copy event comes from there
        if (event.target.style.left === '-999em') {

            this.notificationService.success('Copied to clipboard!');
        }
    }

    public registerClipboardNotifications() {
        // eslint-disable-next-line angular/document-service
        window.document.addEventListener('copy', this.handleClipboard.bind(this));
    }
}
