(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('SupportController', SupportController);

    function SupportController($scope, $window, model, email) {

        $scope.user = model.me;

        $scope.meeting = function (person) {

            $window.open('https://' + person + '-salesflare.youcanbook.me', '_blank', 'noopener');
        };

        $scope.mailto = email.mailto;
    }
})();
