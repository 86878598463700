(function () {
    'use strict';

    angular
        .module('salesflare.components.reportFilterDialog', [])
        .factory('sfReportFilterDialog', sfReportFilterDialog);

    function sfReportFilterDialog($mdDialog, $mdMedia) {

        return {
            show
        };

        function show(locals) {

            return $mdDialog.show({
                clickOutsideToClose: true,
                escapeToClose: true,
                multiple: false,
                hasBackdrop: true,
                controller: 'ReportFilterDialogController',
                controllerAs: 'vm',
                templateUrl: 'app-ajs/components/dashboards/reportFilterDialog/reportFilterDialog.html',
                locals,
                bindToController: true,
                fullscreen: !$mdMedia('gt-sm')
            });
        }
    }
})();
