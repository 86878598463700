(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('TrackingScriptDialogController', TrackingScriptDialogController);

    function TrackingScriptDialogController($scope, $mdDialog, me) {

        init();

        function init() {
            new ClipboardJS('[data-clipboard-text]');

            me.get().then(function (response) {

                const trackingToken = response.data.team.tracking_token;
                $scope.script = '<!-- Salesflare tracking -->\n<script src="https://track.salesflare.com/flare.js"></script>\n<script>\n\tvar flare = new Flare();\n\tflare.track("' + trackingToken + '");\n</script>';
            });
        }

        $scope.back = function () {

            $mdDialog.hide(false);
        };
    }
})();
