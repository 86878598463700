import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';

import { ToolbarComponent } from './toolbar.component';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    exports: [
        ToolbarComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatDividerModule
    ],
    providers: [
        {
            provide: 'rootScope',
            deps: ['$injector'],
            useFactory: ($injector: any) => {

                return $injector.get('$rootScope');
            }
        }
    ]
})
export class ToolbarModule {}
