(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('TemplateSelectionController', TemplateSelectionController);

    /**
     * {function} panelRef.onScaled - called when panel is being scaled ergo on mobile
     * {function} panelRef.onHide - called when back button is pressed (can only happen when scaled ergo on mobile)
     *
     * @param {Object} $mdDialog
     * @param {Object} $mdMedia
     * @param {Object} $scope
     * @param {Object} $timeout
     * @param {Object} $state
     * @param {Object} $window
     * @param {Object} templatesService
     * @param {Object} model
     */
    function TemplateSelectionController($mdDialog, $mdMedia, $scope, $timeout, $state, $window, templatesService, model) {

        const vm = this;

        vm.model = model;

        let thread = null;
        let allTemplates = vm.templates || []; // This will be undefined when coming from the add-in for example
        let fromAddIn = false;

        if ($state.current.name === 'addInTemplateSelection') {
            fromAddIn = true;
        }

        if (fromAddIn) {
            vm.onSelectTemplate = function (template) {
                const message = {
                    function: 'insertTemplate',
                    data: template
                };

                $window.parent.postMessage(message, '*');
            };
        }

        vm.noTemplates = allTemplates.length === 0;

        $timeout(function () {

            angular.element('.search-input').attr('placeholder', 'Search ' + allTemplates.length + (allTemplates.length === 1 ? ' template' : ' templates'));
        });

        vm.manage = function () {

            if (fromAddIn) {
                const message = {
                    function: 'openTemplateManager',
                    data: {
                        templates: allTemplates,
                        createNew: false
                    }
                };

                return $window.parent.postMessage(message, '*');
            }
            else {
                const templateManagerDialogConfig = $mdDialog.templateManagerDialog();
                templateManagerDialogConfig._options.locals = {
                    templates: allTemplates,
                    onSelectTemplate: vm.onSelectTemplate
                };
                templateManagerDialogConfig._options.fullscreen = !$mdMedia('gt-sm');

                vm.closePanel();

                return $mdDialog.show(templateManagerDialogConfig).then(vm.onSelectTemplate, angular.noop);
            }
        };

        vm.create = function () {

            if (fromAddIn) {
                const message = {
                    function: 'openTemplateManager',
                    data: {
                        templates: allTemplates,
                        createNew: true
                    }
                };

                return $window.parent.postMessage(message, '*');
            }
            else {
                const templateManagerDialogConfig = $mdDialog.templateManagerDialog();
                templateManagerDialogConfig._options.locals = {
                    templates: allTemplates,
                    createNew: true,
                    onSelectTemplate: vm.onSelectTemplate
                };
                templateManagerDialogConfig._options.fullscreen = !$mdMedia('gt-sm');

                vm.closePanel();

                return $mdDialog.show(templateManagerDialogConfig).then(vm.onSelectTemplate, angular.noop);
            }
        };

        $scope.$watch('vm.search', function (newValue, oldValue) {

            if (newValue !== oldValue) {
                $timeout.cancel(thread);

                thread = $timeout(function () {

                    if (!vm.search) {
                        vm.templates = allTemplates;
                        return;
                    }

                    const templatesStartingWithSearchTerm = allTemplates.filter(function (template) {

                        return template.name.toLowerCase().indexOf(vm.search.toLowerCase()) === 0;
                    }).sort(sortTemplatesAlphabetically);
                    const templatesContainingSearchTerm = allTemplates.filter(function (template) {

                        return template.name.toLowerCase().indexOf(vm.search.toLowerCase()) > 0;
                    }).sort(sortTemplatesAlphabetically);

                    vm.templates = [...templatesStartingWithSearchTerm, ...templatesContainingSearchTerm];
                });
            }
        });

        vm.closePanel = function () {

            if (!fromAddIn) {
                // Using vm.mdPanelRef instead of injected mdPanelRef since controller is both used for component and panel
                return vm.mdPanelRef.close().then(function () {

                    return vm.mdPanelRef.destroy();
                });
            }
        };

        // Set on scope to allow reload from child scopes
        vm.get = function () {

            if (!vm.templates) {
                return templatesService.get({ limit: 10000 }, { ignoreLoadingBar: true }).then(function (response) {
                    vm.templates = response.data;
                    allTemplates = vm.templates;
                    vm.noTemplates = allTemplates.length === 0;
                    angular.element('.search-input').attr('placeholder', 'Search ' + allTemplates.length + (allTemplates.length === 1 ? ' template' : ' templates'));
                });
            }
        };

        vm.get();

        function sortTemplatesAlphabetically(a,b) {

            const lowerCasedAName = a.name ? a.name.toLowerCase() : '';
            const lowerCasedBName = b.name ? b.name.toLowerCase() : '';

            if (lowerCasedAName < lowerCasedBName) {
                return -1;
            }

            if (lowerCasedAName > lowerCasedBName) {
                return 1;
            }

            return 0;
        }
    }
})();
