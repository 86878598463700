import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

// eslint-disable-next-line no-shadow
export enum DragDropError {
    WRONG_EXTENSION = 'WRONG_EXTENSION',
    TOO_MANY_FILES = 'MAX_FILES',
    TOO_FEW_FILES = 'MIN_FILES',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

@Directive({
    standalone: true,
    selector: '[sfxDragDrop]'
})
export class DragDropDirective {

    @Input() maxFiles = 1;
    @Input() minFiles = 1;

    @Input() accept = '*';

    @Output() onError: EventEmitter<DragDropError> = new EventEmitter<DragDropError>();
    @Output() onDrop: EventEmitter<FileList> = new EventEmitter<FileList>();
    @Output() onDragOver: EventEmitter<DragEvent> = new EventEmitter<DragEvent>();
    @Output() onDragLeave: EventEmitter<DragEvent> = new EventEmitter<DragEvent>();

    constructor() {}

    @HostListener('dragover', ['$event']) _onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.onDragOver.emit(event);
    }

    @HostListener('dragleave', ['$event']) _onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.onDragLeave.emit(event);
    }

    @HostListener('drop', ['$event']) _onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        try {
            if (event.dataTransfer) {
                const files = event.dataTransfer.files;

                if (files.length > this.maxFiles) {
                    this.onError.emit(DragDropError.TOO_MANY_FILES);
                    return;
                }

                if (files.length < this.minFiles) {
                    this.onError.emit(DragDropError.TOO_FEW_FILES);
                    return;
                }

                for (const file of files) {
                    if (!file.name.endsWith(this.accept)) {
                        this.onError.emit(DragDropError.WRONG_EXTENSION);
                        return;
                    }
                }

                this.onDrop.emit(files);
            }
            else {
                throw new Error(DragDropError.UNKNOWN_ERROR);
            }
        }
        catch {
            this.onError.emit(DragDropError.UNKNOWN_ERROR);
        }
    }
}
