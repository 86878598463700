import { Component, Inject } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'sfx-recovery-codes-dialog',
    templateUrl: 'recovery-codes-dialog.component.html',
    styleUrls: ['./recovery-codes-dialog.component.scss']
})
export class SfxRecoveryCodesDialog {

    codes! : string[];

    constructor(
        @Inject('userService') private userService: any,
        @Inject('utilsService') private utilsService: any,
        private clipboard: Clipboard,
        public dialogRef: MatDialogRef<SfxRecoveryCodesDialog>
    ) {}

    ngOnInit() {

        return this.userService.getRecoveryCodes().then((response:any) => {

            if (response) {
                this.codes = [...response.data];
            }
        }).catch(() => {

            this.codes = [];
        });
    }

    public regenerate = () => {

        this.userService.regenerateRecoveryCodes().then((response:any) => {

            if (response) {
                this.codes = [...response.data];
            }
        });
    };

    public copyCodesString = () => {

        if (!this.codes) {
            return '';
        }

        let codesString = '';

        for (const code of this.codes) {
            codesString += code;
            codesString += '\n';
        }

        this.clipboard.copy(codesString);
        return this.utilsService.showSuccessToast('Copied to clipboard!');
    };

    public close = () => {

        this.dialogRef.close();
    };
}
