<sfx-navigation-toolbar>
    <div navigationToolbarTitle>
        Customize fields
    </div>
    <a navigationToolbarButton icon="sf-icon-accounts" ref=".account">
        Account
    </a>
    <a navigationToolbarButton icon="sf-icon-contact" ref=".contact">
        Contact
    </a>
    <a navigationToolbarButton icon="sf-icon-opportunities" ref=".opportunity">
        Opportunity
    </a>
</sfx-navigation-toolbar>

<sfx-content>
    <sfx-section disableContentPadding>
        <ui-view></ui-view>
    </sfx-section>
</sfx-content>