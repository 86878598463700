(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('validateService', validateService);

    function validateService(sfHttp, config) {

        /**
         * Will prevent an error toast by default
         *
         * @param {String} number
         * @returns {Promise}
         */
        this.phoneNumber = function (number) {

            return sfHttp.post(config.apiUrl + 'validate/phonenumber', { number }, { noToast: true });
        };
    }
})();
