(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('NotificationsController', NotificationsController);

    /**
     * {function} panelRef.onScaled - called when panel is being scaled ergo on mobile
     * {function} panelRef.onHide - called when back button is pressed (can only happen when scaled ergo on mobile)
     *
     * @param {Object} $animate
     * @param {Object} $mdDialog
     * @param {Object} $mdMedia
     * @param {Object} $mdSidenav
     * @param {Object} $rootScope
     * @param {Object} $scope
     * @param {Object} $state
     * @param {Object} $window
     * @param {Object} $transitions
     * @param {Object} notificationsService
     * @param {Object} bulkService
     * @param {Object} model
     * @param {Object} $timeout
     * @param {Object} usersettings
     * @param {Object} favicoService
     * @param {Object} sfSetupPanel
     */
    function NotificationsController($animate, $mdDialog, $mdMedia, $mdSidenav, $rootScope, $scope, $state, $window, $transitions, notificationsService, bulkService, model, $timeout, usersettings, favicoService, sfSetupPanel) {

        const vm = this;

        vm.$mdMedia = $mdMedia;
        vm.doneLoading = false;
        vm.isTouchEnabled = $window.touch;

        const emailCreditNotificationTypes = new Set([
            'email_credits_depleted',
            'bulk_find_email_done',
            'bulk_find_email_no_credits_left',
            'bulk_find_email_failed'
        ]);

        init();

        vm.notifications = {
            toLoad: 0,
            numLoaded: 0,
            stepAmount: 20,
            items: [],
            topIndex: 0,

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + this.stepAmount;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad < index) {
                    this.toLoad += this.stepAmount;

                    const boundFirstResponseHandler = angular.bind(this, function (response) {

                        this.items = getNotificationsWithSubheader(response.data);
                        this.numLoaded = this.stepAmount;

                        vm.doneLoading = true;
                    });
                    const boundSecondResponseHandler = angular.bind(this, function (response) {

                        this.items = getNotificationsWithSubheader([...this.items, ...response.data]);
                        this.numLoaded += this.stepAmount;
                    });

                    const responseHandler = this.items.length === 0 ? boundFirstResponseHandler : boundSecondResponseHandler;

                    return notificationsService.get({ limit: 20, offset: this.numLoaded }).then(responseHandler);
                }
            },

            // Forces reset of object
            reload: function () {

                this.toLoad = 0;
                this.numLoaded = 0;
                this.items = [];
                this.doneLoading = false;
                this.topIndex = 0;
            }
        };

        vm.markAsRead = function (notification) {

            return notificationsService.update(notification.id, { read: !notification.read }).then(function () {

                notification.read = !notification.read;
            });
        };

        vm.delete = function (notificationId) {

            vm.notifications.numLoaded--;

            vm.notifications.items = vm.notifications.items.filter(function (n) {

                return n.id !== notificationId;
            });

            return notificationsService.delete(notificationId);
        };

        vm.markAllAsRead = function () {

            return bulkService.updateNotifications({ filter: { read: false }, update: { read: true } }).then(function () {

                vm.notifications.items.forEach(function (notification) {

                    notification.read = true;
                });
            });
        };

        vm.deleteAll = function () {

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .textContent('Are you sure you want to clear all notifications?')
                .ok('Yes')
                .cancel('No');

            return $mdDialog.show(confirm).then(function () {

                return bulkService.updateNotifications( { filter: { archived: false }, update: { archived: true } } ).then(function () {

                    vm.notifications.items = [];
                });
            });
        };

        vm.onNotificationClicked = function (notification) {

            notificationsService.update(notification.id, { read: true });

            vm.closePanel();

            if (notification.type === 'setup_step_completed') {
                if ($mdMedia('gt-sm')) {
                    return sfSetupPanel.show();
                }

                return $state.go('setup');
            }
            else if (emailCreditNotificationTypes.has(notification.type)) {
                // On bulk find email completion we want to go to the contacts page
                // For other credit notifications we go to the plans or credits page
                if (notification.type === 'bulk_find_email_done' || notification.type === 'bulk_find_email_failed') {
                    $state.go('contacts.customers');
                    return top.focus();
                }

                const planId = model.me.team.plan;
                const userAmount = model.me.team.enabled_user_count;

                // Go to the plans page if the team is on the Growth plan, or if it's on the Pro plan with 5 or more users
                if (planId === 3 || (planId === 4 && userAmount >= 5)) {
                    $state.go('plans');
                }
                // Otherwise go to the credits page
                else {
                    $state.go('credits');
                }
            }
            else if (notification.type === 'refer_a_friend') {
                $state.go('settings.referralProgram');
            }
            else if (notification.account) {
                $state.go('accounts.account.feed', { id: notification.account }, { inherit: false });
            }
            else {
                $state.go('tasks.today');
            }

            return top.focus();
        };

        vm.openMenu = function (mdMenu, event) {

            return mdMenu.open(event);
        };

        vm.toggleMenu = function () {

            return $mdSidenav('menu').toggle();
        };

        vm.closePanel = function () {

            // Using vm.mdPanelRef instead of injected mdPanelRef since controller is both used for component and panel
            if (vm.mdPanelRef) {
                return vm.mdPanelRef.close().then(function () {

                    return vm.mdPanelRef.destroy();
                });
            }
        };

        function getNotificationsWithSubheader(items) {

            const notificationsWithSubheader = [];

            items.forEach(function (item, index) {

                if (index !== 0 && !items[index - 1].earlierSubheader && !items[index - 1].viewed && item.viewed) {
                    notificationsWithSubheader.push({ earlierSubheader: true });
                }

                notificationsWithSubheader.push(item);
            });

            return notificationsWithSubheader;
        }

        function init() {

            favicoService.reset();

            // Notifications are loaded inside a panel on full screen, redirect to /tasks when trying to access /notifications on full screen
            if ($state.is('notifications') && $mdMedia('gt-sm')) {
                return $state.go('tasks.today');
            }

            $scope.$watch(function () {

                return $mdMedia('gt-sm');
            }, function (newValue, oldValue) {

                if (newValue === true && oldValue === false) {
                    return $rootScope.back();
                }
                else if (newValue === false && oldValue === true) {
                    vm.closePanel();
                    return $state.go('notifications');
                }
            });

            if (vm.isTouchEnabled) {
                $timeout(function () {

                    const elementWidth = angular.element('.notification').width() || 200;
                    const trashIconAreaWidth = 20 + (16 * 2);

                    Swiped.init({
                        query: '.notification',
                        right: elementWidth - trashIconAreaWidth,
                        left: elementWidth - trashIconAreaWidth,
                        tolerance: elementWidth * 0.4,
                        onOpen: function () {

                            const swipedElement = this.elem;

                            $scope.$apply(function () {

                                $animate.addClass(swipedElement, 'collapse-list-item').then(function () {

                                    vm.delete(Number(swipedElement.id));
                                    swipedElement.style.cssText = '';
                                    angular.element(swipedElement).removeClass('collapse-list-item');
                                });
                            });
                        }
                    });
                }, 1000);
            }
        }


        if ($state.is('notifications')) {
            const removeStartTransitionHook = $transitions.onStart({}, (transition) => {
                const fromState = transition.from();
                const toState = transition.to();

                if (fromState.name && toState.name && fromState.name === 'notifications' && toState.name !== 'notifications') {
                    removeStartTransitionHook();

                    model.me.amountOfNotViewedNotifications = 0;
                    return usersettings.setLastVisitedNotificationsToNow();
                }
            });
        }
    }
})();
