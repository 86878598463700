// Import angularjs app
import './app-ajs/index'

import { enableProdMode } from '@angular/core';
import { NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UpgradeModule } from '@angular/upgrade/static';

import * as Sentry from "@sentry/browser";
import { ExtraErrorData as ExtraErrorDataIntegration, Dedupe as DedupeIntegration } from "@sentry/integrations";

import { UIRouter, UrlService } from '@uirouter/core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const pkg = require('../package.json');

if (environment.production || environment.staging) {
  enableProdMode();
}

if (environment.sentry.enabled) {
  // Utility functions for Sentry
  const crumb: any = function (node: any) {
    var idOrClass = (node.id && "#" + node.id) || ("" + node.classList && (" " + node.classList).replace(/ /g, "."));
    return node.tagName.toLowerCase() + idOrClass;
  };
  const crumbPath: any = function (node: any) {
    return node.parentNode ? crumbPath(node.parentNode).concat(crumb(node)) : [];
  };

  Sentry.init({
    dsn: "https://865649a6a7bb458e9b9f66226dea5773@sentry.io/1296737",
    environment: environment.env,
    release: pkg.version,
    integrations: [
      new ExtraErrorDataIntegration(),
      new DedupeIntegration()
    ],
    beforeBreadcrumb: function (breadcrumb) {
      if (breadcrumb.category === 'console' && breadcrumb.data && breadcrumb.data.extra && breadcrumb.data.extra.arguments && breadcrumb.data.extra.arguments.length > 0) {
        breadcrumb.data.extra.arguments = breadcrumb.data.extra.arguments.map(function (argument: any) {
          if (!argument) {
            return argument;
          }
          var tempArg = argument[0] || argument;
          var parsed = false;

          if (tempArg instanceof Element || tempArg instanceof Node || tempArg instanceof HTMLElement) {
            try {
              tempArg = crumbPath(argument).join(' > ');
            } catch (_) {
            }
            ;
            parsed = true;
          }
          return parsed ? tempArg : argument;
        })
      }
      return breadcrumb;
    }
  });
}

platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
  const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
  upgrade.bootstrap(document.body, ['salesflare']);

  // Intialize the Angular Module
  // get() the UIRouter instance from DI to initialize the router
  const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

  // Instruct UIRouter to listen to URL changes
  function startUIRouter() {
    urlService.listen();
    urlService.sync();
  }

  platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
});
