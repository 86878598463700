import { Component, Inject, OnInit } from '@angular/core';

interface NotificationSetting {
    value?: boolean;
    label: string;
    ariaLabel: string;
}

@Component({
    selector: 'sfx-notification-settings',
    templateUrl: './notification-settings.component.html',
    styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {

    public loading = false;

    notifications: Map<string, NotificationSetting> = new Map<string, NotificationSetting>([
        [
            'email',
            {
                label: 'Email',
                ariaLabel: 'Email'
            }
        ],
        [
            'email_open',
            {
                label: 'Email open',
                ariaLabel: 'Email opens'
            }
        ],
        [
            'forward',
            {
                label: 'Email click-through',
                ariaLabel: 'Email click-throughs'
            }
        ],
        [
            'meeting-live',
            {
                label: 'Meeting by teammate',
                ariaLabel: 'Meetings'
            }
        ],
        [
            'prep_meeting',
            {
                label: 'Upcoming meeting',
                ariaLabel: 'Upcoming meeting'
            }
        ],
        [
            'minutes_meeting',
            {
                label: 'Meeting notes',
                ariaLabel: 'Meeting notes'
            }
        ],
        [
            'meeting-phone',
            {
                label: 'Phone call by teammate',
                ariaLabel: 'Phone calls'
            }
        ],
        [
            'assignee',
            {
                label: 'Someone assigned you to an opportunity',
                ariaLabel: 'Someone assigned you to an opportunity'
            }
        ],
        [
            'stage_change',
            {
                label: 'Opportunity stage change',
                ariaLabel: 'Opportunity stage change'
            }
        ],
        [
            'webpage',
            {
                label: 'Webpage visit',
                ariaLabel: 'Webpage visit'
            }
        ],
        [
            'message',
            {
                label: 'Every internal note',
                ariaLabel: 'All internal notes'
            }
        ],
        [
            'mention',
            {
                label: 'Only internal notes that mention me',
                ariaLabel: 'Internal notes with mentions'
            }
        ],
        [
            'lead_hotness',
            {
                label: 'Lead/account hotness',
                ariaLabel: 'Lead hotness'
            }
        ],
        [
            'user_added',
            {
                label: 'Someone is added to an account',
                ariaLabel: 'Someone is added to an account'
            }
        ],
        [
            'user_removed',
            {
                label: 'Someone is removed from an account',
                ariaLabel: 'Someone is removed from an account'
            }
        ],
        [
            'task',
            {
                label: 'Task due',
                ariaLabel: 'Task due'
            }
        ],
        [
            'task_inactivity',
            {
                label: 'Suggested task when an account becomes inactive',
                ariaLabel: 'Suggested task when an account becomes inactive'
            }
        ],
        [
            'task_assigned',
            {
                label: 'Someone assigned a task to you',
                ariaLabel: 'Someone assigned a task to you'
            }
        ],
        [
            'setup_step_completed',
            {
                label: 'A step in the setup is completed',
                ariaLabel: 'A step in the setup is completed'
            }
        ]
    ]);

    constructor(
        @Inject('notifications') private notificationService: any
    ) {}

    public ngOnInit(): void {

        this._loadSettings();
    }

    private _loadSettings(): void {

        this.loading = true;

        this.notificationService.getSettings().then((response: any) => {

            for (const [key, value] of Object.entries<boolean>(response.data)) {

                this._setValue(key, value);
            }

            this.loading = false;
        });
    }

    private _getValue(key: string): boolean {

        return this.notifications.get(key)?.value || false;
    }

    private _setValue(key: string, value: boolean): void {

        if (this.notifications.has(key)) {

            const setting = this.notifications.get(key) as NotificationSetting;

            setting.value = value;

            this.notifications.set(key, setting);
        }
    }

    public updateSettings(updatedSettingKey: string): void {

        const apiSettings: { [key: string]: boolean } = {};

        switch (updatedSettingKey) {
            case 'mention':
                this._setValue('message', !this._getValue('mention'));
                break;
            case 'message':
                this._setValue('mention', !this._getValue('message'));
                break;
        }

        for (const [key, value] of this.notifications) {

            apiSettings[key] = value.value || false;
        }

        return this.notificationService.updateSettings(apiSettings)
            .then(() => {

                this._loadSettings();
            });
    }

    public keepOrder() {

        return 0;
    }
}
