(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('trackingService', trackingService);

    function trackingService($q, sfHttp, config, model) {

        let trackingEnabled = null;
        let allTrackingLinks = null;

        this.getDomains = function () {

            return sfHttp.get(config.apiUrl + 'tracking').then(function (response) {

                trackingEnabled = response.data.enabled;
                allTrackingLinks = response.data.urls;

                return $q.resolve(response.data);
            });
        };

        /**
         * This function has unit tests, use them! @see server code at 'src/test/unit/utils.js'
         * When modifying this also modify the client code, which is used for the gmail and outlook plugins
         *
         * @param {String} body
         * @param {String} guid
         * @returns {String}
         */
        this.addTrackingToBody = function (body, guid) {

            const teamTrackingDomain = model.me.team.email_tracking_domain;
            const teamTrackingSubdomain = model.me.team.email_tracking_subdomain;

            const baseUnsubscribeUrlWithoutProtocol = config.tracking.baseUnsubscribeUrl.replace('http://', '').replace('https://', '');
            const optOutUrls = [...allTrackingLinks.map((u) => `${u.url}/optout`), baseUnsubscribeUrlWithoutProtocol];

            // Remove tracking from links first
            body = this.removeTrackingFromBody(body);

            if (!trackingEnabled || !teamTrackingDomain.enabled) {
                return body;
            }

            // Randomize the subdomain to prevent deliverability issues for Microsoft
            const randomNumber = Math.floor(Math.random() * 1000);
            // Pad the number to 3 digits
            const paddedNumber = String(randomNumber).padStart(3, '0');

            const trackedUrl = `https://${paddedNumber}-${teamTrackingSubdomain}.${teamTrackingDomain.url}`;

            // Replace any links in `href`s with tracked links
            // Since we remove all tracking before adding it back again we don't need to exclude existing tracked links
            //                           quoteCG     urlCG   quoteCG2
            const reHref = /href=3?D?("|\\?\\?&quot;)(.+?)("|\\?\\?&quot;)/g;
            body = body.replace(reHref, (x, quoteCG, urlCG, quoteCG2) => {

                // Do not track opt out links
                if (optOutUrls.some((url) => urlCG.includes(url))) {
                    return x;
                }

                return `href=${quoteCG}${trackedUrl}/?u=${encodeURIComponent(urlCG.replace(/&amp;/g, '&')).replace(/%3A/g, ':')}&e=${guid}${quoteCG2}`;
            });

            // Replace links with tracked links
            // Excluding existing tracked links
            //                      hrefCG                        cssUrlCG              urlCG                                            p3              p4
            const reUrl = /(href=3?D?(?:"|\\?\\?&quot;)?)?(url\( *(?:&quot;|'|"))?(https?:\/\/[a-zA-Z0-9-]+[a-zA-Z0-9@:%_~#=+-.\/]*(\\?[^<^ ^"]*)?)(<br.*?>|<div>|<\/div>|<p>|<\/p>| )/g; // eslint-disable-line unicorn/no-unsafe-regex,  no-useless-escape
            body = body.replace(reUrl, (x, hrefCG, cssUrlCG, urlCG, p3, p4) => {

                // Do not replace links within hrefs, css url() declarations or opt out links
                if (hrefCG || cssUrlCG || optOutUrls.some((url) => urlCG.includes(url))) {
                    return x;
                }

                return `<a href="${trackedUrl}/?u=${encodeURIComponent(urlCG.replace(/&amp;/g, '&')).replace(/%3A/g, ':')}&e=${guid}">${urlCG}</a>${p4}`;
            });

            // Add image tracking
            body += `<img src="${trackedUrl}/img/${guid}" alt=""/>`;

            return body;
        };

        this.removeTrackingFromBody = function (body) {

            // Remove link tracking
            // We are matching all links instead of just tracked links because the regex would get expensive when we are working with many tracked (sub)domains
            // Check for each link whether we need to remove tracking or not
            //                                        quoteCG                           trackingCG                                           paramCG urlCG otherParamsCG quoteCG2
            const reTracked = /href=3?D?("|\\\\?&quot;)(https?:\/\/[^<]+?)("|\\\\?&quot;)/g;
            body = body.replace(reTracked, (x, quoteCG, fullUrlCG, quoteCG2) => {

                if (!fullUrlCG.includes('?u=') && !fullUrlCG.includes('&u=')) {
                    return x;
                }

                const urlSplit = fullUrlCG.split('?u=')[1] ? fullUrlCG.split('?u=')[1] : fullUrlCG.split('&u=')[1];
                const encodedPart = urlSplit.split('&')[0].split('&amp;')[0];
                const decodedUrl = decodeURIComponent(encodedPart);
                const originalUrl = removeNestedTracking(decodedUrl);

                return `href=${quoteCG}${originalUrl}${quoteCG2}`;
            });

            // Remove image tracking
            const baseImgUrl = config.tracking.imgUrl.replace('http://', '').replace('https://', '').split('/img')[0];
            const baseOldImgUrl = config.tracking.oldImgUrl.replace('http://', '').replace('https://', '').split('/img')[0];
            const imageTrackingUrls = [...allTrackingLinks.map((u) => u.url), baseImgUrl, baseOldImgUrl];

            // Match all img tags with src attributes and `/img/` in the url so that the regex is not too expensive
            // Escaping the - in +\-, is necessary because otherwise it means all characters from + to ,. Somehow eslint suggests to fix it incorrectly.
            //                                                          domainCG
            const reImg = /<img [^>]*src="[a-zA-Z0-9@:%_~#=+\-,.\/]*\/\/([^>]+?)\/img\/[^>]*>/g; // eslint-disable-line no-useless-escape
            body = body.replace(reImg, (x, domainCG) => {

                const domainContainsTrackingDomain = imageTrackingUrls.some((u) => domainCG.includes(u));

                if (!domainContainsTrackingDomain) {
                    return x;
                }

                return '';
            });

            return body;
        };

        /**
         * Remove nested tracking from a URL when needed
         *
         * @param {String} url
         * @returns {String}
         */
        function removeNestedTracking(url) {

            const urlContainsTracking = allTrackingLinks.some((u) => url.includes(u.url));

            if (!urlContainsTracking) {
                return url;
            }

            // Replace nested tracked links with untracked links
            //                                                        trackingCG                               paramCG urlCG otherParamsCG
            const reNestedTracking = new RegExp(`(https?://(?:${allTrackingLinks.map((u) => u.url).join('|')}))+([?]u=)(.+?)(&(?:amp;)?e=.+)`, 'g');
            url = url.replace(reNestedTracking, (x, trackingCG, paramCG, urlCG) => {

                const originalUrl = decodeURIComponent(urlCG);
                return removeNestedTracking(originalUrl);
            });

            return url;
        }
    }
})();
