(function () {
    'use strict';

    angular
        .module('salesflare.components.filesCustomfieldDisplay', [])
        .component('sfFilesCustomfieldDisplay', {
            templateUrl: 'app-ajs/components/customfields/filescustomfielddisplay.html',
            controllerAs: 'vm',
            controller,
            bindings: {
                files: '<',
                customfield: '<',
                showFieldsIcon: '<'
            }
        });

    function controller($window, config, utils) {

        const vm = this;

        vm.download = function (downloadUrl) {

            $window.open(downloadUrl, '_blank', 'noopener');
        };

        vm.convertDate = function (fileDate) {

            const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];

            if (!fileDate) {
                fileDate = Date.now();
            }

            const date = new Date(utils.UTCDateStringToLocalDateObject(fileDate));
            return monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
        };
    }
})();
