(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('MeetingController', MeetingController);

    function MeetingController($rootScope, $scope, $stateParams, $mdDialog, $timeout, $q, $mdMedia, $element, sfDiscardDialog, model, persons, meeting, account, utils, joditNotesService) {

        $scope.id = $stateParams.id;
        $scope.type = $stateParams.type;
        $scope.filter = '';
        $scope.notPartOfAccountNames = '';
        $scope.meeting = null;

        const endTimePreparation = new Date();
        endTimePreparation.setHours(endTimePreparation.getHours() + 1, 0, 0, 0);

        $scope.am_pm_notation = model.me.am_pm_notation;

        $scope.$mdMedia = $mdMedia;

        if (!$scope.id) {
            $scope.date = new Date();
            $scope.date.setMinutes(0);
            $scope.endDate = endTimePreparation;

            $scope.message = {
                hour: 'Hour is required',
                minute: 'Minute is required',
                meridiem: 'Meridiem is required'
            };
        }

        let thread = null;
        const accountId = $stateParams.account;
        let fullAccount;
        let accountContacts = [];

        get();

        $scope.$watch('meeting.participants', function (newVal) {

            if (!newVal) {
                return;
            }

            if (newVal.length > 0 && newVal[newVal.length - 1].title) {
                newVal = newVal.slice(0, -1);
                $scope.meeting.participants = $scope.meeting.participants.slice(0, -1);
            }

            const inValid = (newVal.length === 0 && !$scope.isCalendarMeeting);
            $scope.meetingForm.participants.$setValidity('noParticipants', !inValid);
            $scope.meetingForm.participants.warning = {};
            if (newVal.length === 0) {
                $scope.onlyUsers = false;
            }
            else {
                $scope.onlyUsers = true;
            }

            $scope.notPartOfAccountNames = '';

            for (const participant of newVal) {

                if (participant.type !== 'user') {
                    $scope.onlyUsers = false;
                }

                if (participant.account) {
                    $scope.meetingForm.participants.warning.notPartOfAccount = false;
                    $scope.notPartOfAccountNames = '';
                    break;
                }

                if (!participant.account && participant.type !== 'user') {
                    $scope.notPartOfAccountNames += ' ' + participant.name + ',';
                }
            }

            // Trim trailing ','
            $scope.notPartOfAccountNames = $scope.notPartOfAccountNames.slice(0, -1);
            $scope.meetingForm.participants.warning.onlyUsers = $scope.onlyUsers;
            $scope.meetingForm.participants.warning.notPartOfAccount = $scope.notPartOfAccountNames ? true : false;
        }, true);

        // Scope back gets called by routing or md-dialog onComplete function
        $scope.back = function (reload) {

            // We can't do the discard dialog check in the close function,
            // Since it would trigger when trying to create or save a meeting
            const type = $scope.type === 'meeting-phone' ? 'meeting-phone' : 'meeting-live';

            // Check if form changed before closing
            if ($scope.meetingForm.$dirty) {
                return sfDiscardDialog.show($scope.id === 'new', type).then(function () {

                    return close(reload);
                });
            }
            else {
                return close(reload);
            }
        };

        function close(reload) {

            if ($mdMedia('gt-sm')) {
                return $mdDialog.hide(reload);
            }

            return $rootScope.back();

        }

        $scope.searchPersons = function (queryString) {

            if (!queryString || queryString === '') {

                if (!fullAccount) {
                    return [];
                }

                const possibleSuggestions = [...accountContacts, ...fullAccount.users];

                const filteredSuggestions = possibleSuggestions.filter(function (contact) {

                    for (let i = 0; i < $scope.meeting.participants.length; ++i) {
                        if (contact.id === $scope.meeting.participants[i].id) {
                            return false;
                        }
                    }

                    return true;
                });

                if (filteredSuggestions.length > 0) {
                    filteredSuggestions.unshift({ title: 'Related to ' + fullAccount.name });
                }

                return filteredSuggestions;
            }

            return $q(function (resolve) {

                $timeout.cancel(thread);
                thread = $timeout(function () {

                    return persons.get(queryString).then(function (response) {

                        let possibleSuggestions = [];

                        if (fullAccount) {
                            possibleSuggestions = [...accountContacts, ...fullAccount.users];
                        }

                        let filteredPersons = response.data.filter(function (item) {

                            for (let i = 0; i < $scope.meeting.participants.length; ++i) {
                                if (item.id === $scope.meeting.participants[i].id) {
                                    return false;
                                }
                            }

                            for (const accountContact of accountContacts) {
                                if (item.id === accountContact.id) {
                                    return false;
                                }
                            }

                            if (fullAccount) {
                                for (let i = 0; i < fullAccount.users.length; ++i) {
                                    if (item.id === fullAccount.users[i].id) {
                                        return false;
                                    }
                                }
                            }

                            return true;
                        });

                        const filteredSuggestions = possibleSuggestions.filter(function (contact) {

                            for (let i = 0; i < $scope.meeting.participants.length; ++i) {
                                if (contact.id === $scope.meeting.participants[i].id) {
                                    return false;
                                }
                            }

                            return contact.name.includes(queryString) || contact.email.includes(queryString);
                        });

                        if (filteredSuggestions.length > 0) {
                            filteredSuggestions.unshift({ title: 'Related to ' + fullAccount.name });
                        }

                        if (filteredPersons.length > 0) {
                            filteredPersons.unshift({ title: 'Others' });
                        }

                        filteredPersons = [...filteredSuggestions, ...filteredPersons];

                        return resolve(filteredPersons);
                    });
                }, 750);
            });
        };

        $scope.delete = function () {

            let type = 'meeting';

            if ($scope.type === 'meeting-phone') {
                type = 'phone call';
            }

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .textContent('Are you sure you want to delete this ' + type + '?')
                .ok('Yes')
                .cancel('No');

            return $mdDialog.show(confirm).then(function () {

                meeting.delete($scope.meeting).then(function () {

                    $scope.meeting.deleted = true;
                    return close($scope.meeting);
                });
            });
        };

        $scope.save = function () {

            // Manually set touched so errors show up
            if ($scope.meeting.participants.length > 0) {
                $scope.meetingForm.$touched = true;
            }

            $scope.meetingForm.endDate.$setValidity('endDateAfterStartDate', true);
            if ($scope.date > $scope.endDate) {
                $scope.meetingForm.endDate.$setValidity('endDateAfterStartDate', false);
            }

            // Check if all required fields are filled in
            if ($scope.meetingForm.$invalid) {
                return;
            }

            $scope.updating = true;

            $scope.meeting.date = $scope.date;
            $scope.meeting.date.setMilliseconds(0);

            $scope.meeting.end_date = $scope.endDate;
            $scope.meeting.end_date.setMilliseconds(0);

            // Set seconds to 0 for everything but phone call since we do want the accuracy there
            if ($scope.type !== 'meeting-phone') {
                $scope.meeting.date.setSeconds(0);
                $scope.meeting.end_date.setSeconds(0);
            }

            // Delete deprecated minutes property returned on /GET meetings for older client support
            delete $scope.meeting.minutes;

            // Set notes & description.

            if ($scope.id) {

                return meeting.update($scope.meeting).then(function () {

                    // Return the updated meeting to apply to the old list
                    return close($scope.meeting);
                }).catch(function () {

                    $scope.updating = false;
                });
            }
            else {
                return meeting.create($scope.meeting).then(function () {

                    return close($scope.meeting);
                }).catch(function () {

                    $scope.updating = false;
                });
            }
        };

        function initEditor(field, readonly) {

            const jodit = joditNotesService.getJoditEditor(getJoditInitializationObject(),
                // On change sync body text with our model
                function (bodyText) {
                    if ($scope.meeting && ($scope.meeting[field] || bodyText !== '') && $scope.meeting[field] !== bodyText) {
                        $scope.meeting[field] = bodyText;
                        $scope.meetingForm.$dirty = true;
                    }
                }
            );
            if ($scope.meeting) {
                jodit.setEditorValue(utils.nl2br($scope.meeting[field]) || '');
            }

            return jodit;

            function getJoditInitializationObject() {

                return {
                    //Autofocus: true,
                    readonly,
                    queryStringToBindTo: $element.find('#' + field)[0],
                    placeholder: '',
                    previewButton: {
                        enabled: false
                    },
                    mergeFieldButton: {
                        enabled: false
                    },
                    attachmentsButton: {
                        enabled: false
                    },
                    templatesButton: {
                        enabled: false
                    },
                    sendTestButton: {
                        enabled: false
                    }
                };
            }
        }

        $scope.$watch('endDate', function (newVal) {

            $scope.meetingForm.endDate.$setValidity('endDateAfterStartDate', true);
            if ($scope.date > newVal) {
                $scope.meetingForm.endDate.$setValidity('endDateAfterStartDate', false);
            }
        }, true);

        $scope.$watch('date', function (newVal, oldVal) {

            $scope.meetingForm.endDate.$setValidity('endDateAfterStartDate', true);

            // When changing the start date, change the end date in the same way to improve UX
            if (newVal !== oldVal && angular.isFunction(newVal.getMonth) && angular.isDefined(oldVal) && angular.isFunction(oldVal.getMonth)) {
                const timeDiffMilli = newVal - oldVal;
                const newDateMilli = $scope.endDate.getTime() + timeDiffMilli;
                const newDate = new Date(newDateMilli);
                $scope.endDate = newDate;
            }

            if (newVal > $scope.endDate) {
                $scope.meetingForm.endDate.$setValidity('endDateAfterStartDate', false);
            }
        }, true);

        function get() {

            if (accountId) {
                account.get(accountId).then(function (response) {

                    fullAccount = response.data;
                });

                account.getContacts(accountId).then(function (response) {

                    accountContacts = response.data;
                });
            }

            if ($scope.id) {
                return meeting.get($scope.id).then(function (response) {

                    $scope.meeting = response.data;
                    $scope.isCalendarMeeting = !!$scope.meeting.calendar_id;

                    $scope.date = new Date($scope.meeting.date);

                    if ($scope.meeting.end_date) {
                        $scope.endDate = new Date($scope.meeting.end_date);
                    }

                    initEditor('notes');
                    initEditor('description', $scope.isCalendarMeeting);
                });
            }
            else {
                $scope.meeting = {
                    participants: [],
                    type: $scope.type
                };

                $timeout(function () {
                    initEditor('notes');
                    initEditor('description');
                }, 0);
            }
        }
    }
})();
