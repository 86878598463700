(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('helperFunctionsService', helperFunctionsService);


    function helperFunctionsService($window, $mdDialog, $q, flagsService, opportunityService, account, model, config, meeting) {

        const self = this;

        this.openWebsite = function (url, $event) {

            $event.stopPropagation();

            $window.open(url, '_blank', 'noopener');
        };

        this.callNumber = function (tel, $event) {

            $event.stopPropagation();

            if (config.mode === 'outlook' || config.mode === 'outlook-web') {
                $window.open('tel:' + tel.replace(/\s+/g, ''), '_blank', 'noopener');
            }
            else if (config.mode === 'mobile') {
                $window.open('tel:' + tel.replace(/\s+/g, ''), '_system', 'noopener');
            }
            else {
                $window.location.href = 'tel:' + tel.replace(/\s+/g, '');
            }
        };

        /**
         * @param {Object} person
         * @param {String} number
         * @param {Event} $event
         * @returns {Promise}
         */
        this.callPerson = function (person, number, $event) {

            let tel = number;
            if (!angular.isString(number)) {
                $event = number;
                tel = person.phone_number;
            }

            self.callNumber(tel, $event);

            if (model.me.id !== person.id && (angular.isUndefined($window.device) || (angular.isDefined($window.device) && device.platform !== 'Android'))) {
                const meetingData = {
                    type: 'meeting-phone',
                    date: new Date().toISOString(),
                    participants: [person]
                };

                return meeting.create(meetingData);
            }

            return $q.resolve();
        };

        this.skype = function (handle, person, $event) {

            $event.stopPropagation();

            const uri = 'skype:' + handle + '?chat';

            if (config.mode === 'outlook') {
                $window.open(uri, '_blank', 'noopener');
            }
            else {
                $window.location.href = uri;
            }

            if (model.me.id !== person.id && Object.keys(person).length > 0) {
                const meetingData = {
                    type: 'meeting-phone',
                    date: new Date().toISOString(),
                    participants: [person],
                    description: 'Skype call'
                };

                return meeting.create(meetingData);
            }
        };

        /**
         * Takes care of showing the dialog and possible warning and related flags
         *
         * @param {Object} opp
         * @param {Object} $event
         * @returns {void}
         */
        this.assign = function (opp, $event) {

            return $mdDialog.show({
                clickOutsideToClose: true,
                controller: 'AssignDialogController',
                templateUrl: 'partials/assigndialog.html',
                targetEvent: $event,
                locals: {
                    accountId: opp.account.id
                }
            }).then(function (assignee) {

                const obj = {
                    id: opp.id,
                    assignee
                };

                opp.assignee = assignee;

                // Flag is not an empty string which means we don't want to show the dialog
                if (flagsService.get('opportunityAssigneeWarning') === false) {
                    return opportunityService.update(obj).then(function () {

                        opp.assignee = assignee;
                    });
                }

                return account.getUsers(opp.account.id).then(function (response) {

                    const users = response.data;
                    let assigneeIsPartOfAccount = false;

                    for (const user of users) {
                        if (user.id === assignee.id) {
                            assigneeIsPartOfAccount = true;
                            break;
                        }
                    }

                    return opportunityService.update(obj).then(function () {

                        opp.assignee = assignee;

                        if (!assigneeIsPartOfAccount) {
                            const opportunityAssigneeWarningDialog = $mdDialog.confirm()
                                .textContent('When you assign an opportunity to a team member, he/she is added automatically to the account\'s team and can see the timeline.')
                                .clickOutsideToClose(true)
                                .ariaLabel('Opportunity assignee warning dialog')
                                .ok('Don\'t show again')
                                .cancel('Ok');

                            return $mdDialog.show(opportunityAssigneeWarningDialog).then(function () {

                                // Update the flag so we never show the dialog again
                                return flagsService.set('opportunityAssigneeWarning', false);
                            });
                        }
                    });
                });
            });
        };

        /**
         * Outlook plugin does the authentication flow in its own dialog
         * We send a message to the Outlook plugin to launch the OAuth dialog
         * When the flow is done it calls the callback with the redirect query params
         *
         * @param {String} url
         *
         * @returns {void}
         */
        this.handleOutlookPluginOAuthFlow = (url) => {

            return new Promise((resolve) => {

                $window.addEventListener('message', receiveMessage);

                function receiveMessage(event) {

                    if (event.data?.function === 'connectCallback') {
                        $window.removeEventListener('message', receiveMessage);
                        return resolve(event.data.queryParams ? new URLSearchParams(event.data.queryParams) : undefined);
                    }
                }

                const message = {
                    function: 'showOAuthDialog',
                    url
                };

                $window.parent.postMessage(message, '*');
            });
        };
    }
})();
