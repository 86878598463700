(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('company', companyService);

    function companyService(sfHttp, config) {

        this.create = function (company) {

            return sfHttp.post(config.apiUrl + 'companies', company);
        };

        this.get = function (id) {

            return sfHttp.get(config.apiUrl + 'companies/' + id, { cache: true });
        };
    }
})();
