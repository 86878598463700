import { Component, Inject, OnInit } from '@angular/core';
import { NotificationService } from '@core/services/notifications.service';

@Component({
    selector: 'sfx-import-settings',
    templateUrl: './import-settings.component.html',
    styleUrls: ['./import-settings.component.scss']
})
export class ImportSettingsComponent implements OnInit {

    public isDraggingFile = false;
    constructor(
        public notificationService: NotificationService,
        @Inject('importService') public importService: any,
        @Inject('stateService') public stateService: any
    ) {}

    public ngOnInit(): void {}

    public close = (): any =>  {

        this.stateService.go('settings');
    };

    public handleFileDrop = (fileList: FileList): any => {

        this.uploadFile(this.stateService.current.data.entity, fileList[0]);
    }

    public handleButtonFileUpload = (type: 'account' | 'contact' | 'opportunity', event: any): any => {

        const file: File = event.target.files[0];

        if (file) {
            this.uploadFile(type, file);
        }
        else {
            this.notificationService.error('Please upload a valid .csv file.');
        }
    }

    public handleDragOver = (): any => {

        this.isDraggingFile = true;
    }

    public handleDragLeave = (): any => {

        this.isDraggingFile = false;
    }
    uploadFile = (type: string, file: File): any => {

        this.importService.upload(type, file).then((importId: number) => {
            this.goToMap(importId);
        });
    }

    goToMap = (importId: number) => {

        this.stateService.go('settings.importMap', { id: importId });
    }
}
