(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('CompanyFeedController', CompanyFeedController);

    function CompanyFeedController($rootScope, $scope, $state, $window, $mdDialog, model, account, events, utils) {

        $scope.showSpeedDialTooltips = !$window.touch; // They use hover states (aka mouseenter/...) and that just breaks on mobile so better to disable it

        if ($rootScope.history[$rootScope.history.length - 1].fromState.name !== 'accounts') {
            $rootScope.history.pop();
        }

        function addCompany() {

            return account.create({ domain: $scope.company.domain }).then(function (response) {

                $rootScope.$broadcast('refreshData');
                $rootScope.$broadcast(events.account.createdFromCompany, { company: $scope.company.id, account: response.data.id });

                return $state.go($state.current.name.replace('company', 'account'), { id: response.data.id });
            });
        }

        $scope.addCompany = function () {

            if ($scope.company?.domain === model.me.team.domain) {
                return $mdDialog.show(utils.getAccountCreationConfirmDialog(true)).then(addCompany);
            }

            return addCompany();
        };
    }
})();
