(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('DowngradeConfirmationDialogController', DowngradeConfirmationDialogController);

    function DowngradeConfirmationDialogController($mdDialog, $exceptionHandler, authentication, billing, utils, plansService, stripeService) {

        const vm = this;
        const planFeatures = plansService.get();
        vm.planFeatures = [];
        vm.IMAPWillBeDisabled = false;
        vm.campaignLimitReached = false;
        vm.lostPlanFeatures = [];
        const futurePlan = vm.futurePlan ? vm.futurePlan : 3;
        const currentPlan = vm.currentPlan ? vm.currentPlan : 3;
        vm.futurePlan = futurePlan;
        vm.currentPlan = currentPlan;

        init();

        vm.downgrade = function () {

            if (vm.onTrial) {
                // When this dialog is shown when subscribing to the Growth plan on trial, we just want this dialog to act as a confirmation dialog
                return $mdDialog.hide(true);
            }
            else {
                return billing.downgrade(futurePlan, vm.downgradeReason)
                    .then(function (response) {

                        const planDowngradedText = 'Your plan has been successfully downgraded to the ' + vm.futurePlanName + ' plan.';

                        // `Requires action` basically means we need to do a SCA flow
                        if (response.data.status === 'requires_action') {
                            return stripeService.stripe.handleCardPayment(response.data.client_secret).then(function (result) {

                                if (result.error) {
                                    utils.showErrorToast(result.error.message);
                                    // Log the error
                                    const formattedError = new Error(result.error.code);
                                    formattedError.data = result.error;
                                    $exceptionHandler(formattedError);
                                }
                                else {
                                    utils.showSuccessToast(planDowngradedText);
                                }

                                authentication.fetchMe();
                                return $mdDialog.hide(true);
                            });
                        }

                        utils.showSuccessToast(planDowngradedText);

                        authentication.fetchMe();
                        return $mdDialog.hide(true);
                    })
                    .catch(function (err) {

                        utils.showErrorToast(err.data.message);
                    });
            }
        };

        vm.close = function () {

            return $mdDialog.cancel();
        };

        function init() {

            for (const plan in planFeatures) {
                if (Object.prototype.hasOwnProperty.call(planFeatures, plan)) {
                    vm.planFeatures.push({
                        name: plan,
                        title: plan.charAt(0).toUpperCase() + plan.slice(1),
                        features: planFeatures[plan].features
                    });
                }
            }

            for (const planIndex in vm.planFeatures) {
                switch (vm.planFeatures[planIndex].name) {
                    case ('pro'):
                        if (currentPlan >= 4 && futurePlan < 4) {
                            vm.lostPlanFeatures = [...vm.lostPlanFeatures, ...planFeatures[vm.planFeatures[planIndex].name].features];
                        }

                        break;
                    case ('enterprise'):
                        if (currentPlan >= 5 && futurePlan < 5) {
                            vm.lostPlanFeatures = [...vm.lostPlanFeatures, ...planFeatures[vm.planFeatures[planIndex].name].features];
                        }

                        break;
                    default:
                        break;
                }
            }

            vm.lostPlanFeatures = vm.lostPlanFeatures.map(function (feature) {

                const mappedFeature = {};
                if (feature.name) {
                    mappedFeature.name = feature.name;
                }

                if (feature.downgradeDescription) {
                    mappedFeature.downgradeDescription = feature.downgradeDescription;
                }

                return mappedFeature;
            });
        }
    }
})();
