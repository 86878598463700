(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('savedFilterService', savedFilterService);

    function savedFilterService(sfHttp, config) {

        this.get = function get(id, entity) {

            return sfHttp.get(config.apiUrl + 'savedfilters/' + entity + '/' + id);
        };

        this.update = function update(id, entity, savedFilter) {

            return sfHttp.put(config.apiUrl + 'savedfilters/' + entity + '/' + id, savedFilter);
        };

        this.delete = function (id, entity) {

            return sfHttp.delete(config.apiUrl + 'savedfilters/' + entity + '/' + id);
        };
    }
})();
