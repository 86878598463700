(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ReorderDashboardsDialogController', ReorderDashboardsDialogController);

    function ReorderDashboardsDialogController($mdDialog, dashboardsService, utils) {

        const vm = this;

        vm.reorder = function () {

            const update = vm.dashboards.map(function (dashboard, index) {

                return {
                    id: dashboard.id,
                    order: index
                };
            });
            return dashboardsService.update(update).then(function () {

                utils.showSuccessToast('Dashboard order saved');

                return $mdDialog.hide();
            });
        };

        vm.cancel = function () {

            return $mdDialog.cancel();
        };
    }
})();
