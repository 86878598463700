(function () {
    'use strict';

    angular
        .module('salesflare.components.restrictedDialog', [])
        .factory('sfRestrictedDialog', sfRestrictedDialog);

    function sfRestrictedDialog($mdDialog, $state, model) {

        let showing = false;

        return {
            check,
            hide,
            isShowing
        };

        function check(state = $state) {

            // Never show the restricted dialog in settings
            // The SCA flow would avoid the routing logic, making the dialog show up anyway
            if (!state.name || state.name.includes('settings')) {
                return;
            }

            if (!showing && model.me && model.me.restricted) {
                showing = true;

                return $mdDialog.show({
                    clickOutsideToClose: false,
                    escapeToClose: false,
                    templateUrl: 'app-ajs/components/restricteddialog/restricteddialog.html',
                    controller: 'RestrictedDialogController'
                }).finally(function () {

                    showing = false;
                });
            }
        }

        function hide() {

            if (showing) {
                return $mdDialog.hide();
            }
        }

        function isShowing() {

            return showing;
        }
    }
})();
