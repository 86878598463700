import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[speedDialAction]'
})
export class SpeedDialActionDirective {

    @Input() 'track-id': string;
    @Input() 'aria-label': string;
    @Input() label!: string;
    @Input() icon!: string;
    @Input() action!: any;
    @Input() 'action-type': 'button' | 'file';
    @Input() 'action-accept': string;

    constructor() {}
}
