(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('humanReadableAdvancedFilterService', humanReadableAdvancedFilterService);

    function humanReadableAdvancedFilterService($sanitize, $q, workflowService, filterService) {

        /**
         * @param {Object} filter An advanced filter object
         * @param {Object[]} filter.rules
         * @param {String} filter.condition
         * @param {String} entity
         * @returns {Promise.<String>} Human readable string version of the advanced filter
         */
        this.getHumanReadableAdvancedFilter = function (filter, entity) {

            if (!filter) {
                return $q.resolve('Filter contacts');
            }

            const prefix = '' + entity.charAt(0).toUpperCase() + entity.slice(1) + ' matches: ';

            if (filter.search) {
                return $q.resolve($sanitize(prefix + '<b>Search term</b> is `' + filter.search + '`'));
            }

            if (!filter.rules || filter.rules.length === 0) {
                return $q.resolve('Filter contacts');
            }

            const internalRule = filter.rules.find(function (rule) {

                return rule.internal;
            });
            const containsInternalRule = angular.isUndefined(internalRule) ? false : true;

            if (containsInternalRule) {
                if (internalRule.id === 'contact.id') {
                    const amount = angular.isArray(internalRule.value) ? internalRule.value.length : 'x';
                    return $q.resolve($sanitize('Contact is one of ' + amount + ' selected contacts'));
                }

                if (internalRule.id.startsWith('campaign')) {

                    const otherCampaignRuleLabels = getSecondaryRuleLabel(filter.rules, 'campaign');
                    const relationToCampaignString = otherCampaignRuleLabels.join(' or ');

                    return $q.resolve($sanitize('Contact ' + relationToCampaignString + ' a legacy campaign (no more contacts will enter).'));
                }
                else if (internalRule.id.startsWith('workflow_step_record') || internalRule.id.startsWith('workflow.send_email') || internalRule.id.startsWith('workflow.step.id')) {
                    const stepId = getIdValue(filter.rules, 'workflow_step_record.workflow_step');

                    const otherWorkflowStepRuleLabels = getSecondaryRuleLabel(filter.rules, 'workflow.send_email');
                    const relationToWorkflowStepString = otherWorkflowStepRuleLabels.join(' or ');

                    return workflowService.getStep(stepId).then(function (response) {

                        const step = response.data;

                        return $sanitize('Contact ' + relationToWorkflowStepString + ' ' + step.name + ' of the workflow ' + step.workflow.name);
                    });
                }
                else if (internalRule.id.startsWith('workflow')) {
                    const workflowId = getIdValue(filter.rules, 'workflow.id');

                    const otherWorkflowRuleLabels = getSecondaryRuleLabel(filter.rules, 'workflow');
                    const relationToWorkflowString = otherWorkflowRuleLabels.join(' or ');

                    return workflowService.get(workflowId).then(function (response) {

                        const workflow = response.data;

                        return $sanitize('Contact ' + relationToWorkflowString + ' the workflow ' + workflow.name);
                    });
                }
                else {
                    return $q.resolve($sanitize('Selected contacts'));
                }
            }

            let filterString = '';
            for (const ruleIndex in filter.rules) {
                const filterRule = filter.rules[ruleIndex];

                const conditionString = (ruleIndex === '0') ? null : (' ' + filter.condition.toLowerCase());
                const propertyString = '<b>' + filterRule.group + ' ' + filterRule.label + '</b> ';
                let operationString = getOperatorDisplayName(filterRule.operator, filterRule.input);

                let valueString = '';
                // Handle tags and auto completes
                if (filterRule.raw_value && angular.isArray(filterRule.raw_value)) {
                    if (filterRule.raw_value.length > 1) {
                        for (const rawValueIndex in filterRule.raw_value) {
                            const rawValue = filterRule.raw_value[rawValueIndex];
                            if (Number(rawValueIndex) === 0) {
                                valueString = rawValue.name;
                            }
                            else {
                                valueString += '` or `' + rawValue.name;
                            }
                        }
                    }
                    else {
                        valueString = filterRule.raw_value[0].name;
                    }
                }
                // Handle multi selects
                //When working with options, we need to get the selected option name back
                else if (filterRule.options && angular.isArray(filterRule.options) && angular.isArray(filterRule.value)) {
                    for (const valueIndex in filterRule.value) {
                        const value = filterRule.value[valueIndex];

                        const selectedOption = filterRule.options.find(function (option) {

                            return option.id === value;
                        });

                        if (Number(valueIndex) === 0) {
                            valueString = selectedOption ? selectedOption.name : ' ';
                        }
                        else {
                            valueString += '` or `' + (selectedOption ? selectedOption.name : ' ');
                        }
                    }
                }
                // Handle boolean rules before transforming them to the general rule format
                // Happens when rules are just added when creating or editing a filter
                else if (filterRule.value === '' && filterRule.type === 'boolean') {
                    valueString = '';

                    operationString = getOperatorDisplayName(filterRule.operator, filterRule.input);
                }
                else if (filterRule.type === 'boolean') {

                    const ruleValue = angular.isArray(filterRule.value) ? filterRule.value[0] : filterRule.value;

                    let key = ruleValue;

                    if (ruleValue === 'null') {
                        key = filterRule.operator === 'equal' ? 'is_null' : 'is_not_null';
                    }

                    operationString = getOperatorDisplayName(key, filterRule.input);

                    valueString = '';
                }
                else {
                    valueString = filterRule.value;
                }

                valueString = valueString ? ' `' + valueString + '`' : '';

                const ruleString = (conditionString ? conditionString + ' ' : '') + propertyString + operationString + valueString;
                filterString = filterString + ruleString;
            }

            return $q.resolve($sanitize(prefix + filterString));
        };

        ////////////

        function getOperatorDisplayName(operatorKey, inputType) {

            let operators;

            switch (inputType) {
                case 'text':
                case 'largetext':
                case 'url':
                    operators = filterService.operators.text;
                    break;
                case 'integer':
                    operators = filterService.operators.integer;
                    break;
                case 'date':
                    operators = filterService.operators.date;
                    break;
                case 'radio':
                case 'binaryradio':
                    operators = filterService.operators.boolean;
                    break;
                case 'single select':
                case 'multiselect':
                case 'autocomplete':
                case 'tags':
                    operators = filterService.operators.select;
                    break;
            }

            const operatorObject = operators.find(function (typeOperator) {

                return typeOperator.key === operatorKey;
            });

            return operatorObject && operatorObject.value;
        }

        function getIdValue(rules, id) {

            return rules.find(function (rule) {

                return rule.query_builder_id === id;
            }).value[0];
        }

        function getSecondaryRuleLabel(rules, entity) {

            const relevantRules = rules.filter(function (rule) {

                return rule.id.startsWith(entity);
            });

            let secondaryIdsForEntity = [];

            switch (entity) {
                case 'campaign':
                    secondaryIdsForEntity = ['campaign.received', 'campaign.opened', 'campaign.clicked', 'campaign.replied'];
                    break;
                case 'workflow':
                    secondaryIdsForEntity = ['workflow.in', 'workflow.exited', 'workflow.met_goal'];
                    break;

                case 'workflow.send_email':
                    secondaryIdsForEntity = ['workflow.send_email.received', 'workflow.send_email.opened', 'workflow.send_email.clicked', 'workflow.send_email.replied'];
                    break;
                default:
                    break;
            }

            return relevantRules.filter(function (rule) {

                return secondaryIdsForEntity.includes(rule.id);
            }).map(function (rule) {

                let label = 'interacted';
                let ruleLabel = rule.label;
                // Special case for workflow.send_email.received
                if (rule.id === 'workflow.send_email.received') {
                    ruleLabel = 'received step';
                }

                if (ruleLabel) {
                    label = ruleLabel.toLowerCase() === 'replied' ? 'replied to' : ruleLabel.toLowerCase();

                    const ruleValue = angular.isArray(rule.value) ? rule.value[0] : rule.value;
                    if (ruleValue === 'false') {
                        label = 'hasn\'t ' + label;
                    }
                }

                return label.toLowerCase();
            });

        }
    }
})();
