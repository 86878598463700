<sfx-toolbar [dialogToolbar]="true" (closeEvent)="close()">Recovery codes</sfx-toolbar>
<div mat-dialog-content class="dialog-content">
    <p>Recovery codes can be used to access your account in the event you lose access to your device and cannot receive two-factor authentication codes.</p>
    <p>Treat your recovery codes with the same level of attention as you would your password! We recommend saving them with a password manager such as Lastpass, 1Password, or Keeper.</p>
    <div class="codes-container" *ngIf="codes">
        <p class="recovery-code" *ngFor="let code of codes" data-flex="50">{{ code }}</p>
    </div>
</div>
<mat-dialog-actions align="end" class="primary">
    <button mat-button (click)="regenerate()">Regenerate</button>
    <button mat-button (click)="copyCodesString()">Copy</button>
</mat-dialog-actions>