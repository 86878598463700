(function () {
    'use strict';

    angular
        .module('salesflare.components.tasks.upcoming', [])
        .component('sfTasksUpcoming', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/tasks/upcoming/upcoming.html',
            bindings: {
                entityCountString: '<', // The amount of tasks in the db
                tasks: '<', // The md-virtual repeat object
                loadingTasks: '<',
                onDismissTask: '&',
                onSelectedChanged: '&', // Directly bound to the task event
                onEditTask: '&',
                onEditedTask: '&',
                onUpdateTask: '&',
                isTaskFilterApplied: '&',
                usedBadFilter: '<',
                badFilterError: '<',
                setFilters: '&',
                getSearch: '&'
            }
        });

    function controller($mdMedia) {

        const vm = this;

        vm.$onInit = function () {

            vm.doneQuote = getRandomDoneQuote();
            // Ideally this would be dynamic but virtual repeat only allows setting it on load basically
            vm.itemSize = $mdMedia('gt-sm') ? 48 : 72;
        };

        vm.onUpdateTaskProxy = function ($event) {

            return vm.onUpdateTask({
                $event
            });
        };

        vm.onDismissTaskProxy = function ($event) {

            return vm.onDismissTask({
                $event
            });
        };

        vm.onEditTaskProxy = function ($event) {

            return vm.onEditTask({
                $event
            });
        };

        vm.onEditedTaskProxy = function ($event) {

            return vm.onEditedTask({
                $event
            });
        };

        function getRandomDoneQuote() {

            const doneQuotes = [
                'All done! You\'re amazing. \u2728', // ✨
                'You\'re simply the best.',
                'Hell yeah! \uD83D\uDCAA',  // 💪
                'Done, done aaaand done.'
            ];
            return doneQuotes[Math.floor(Math.random() * doneQuotes.length)];
        }
    }
})();
