(function () {
    'use strict';

    angular
        .module('salesflare')
        .config(function ($mdDialogProvider) {

            $mdDialogProvider.addPreset('sendTestEmailConfigDialog', {
                options: function () {
                    return {
                        templateUrl: 'app-ajs/components/sendTestEmailConfigDialog/sendTestEmailConfigDialogTemplate.html',
                        controllerAs: 'vm',
                        controller: sendTestEmailConfigDialogController,
                        bindToController: true,
                        clickOutsideToClose: true,
                        escapeToClose: true,
                        multiple: true
                    };
                }
            });

            /*
             * Annotate the function so uglyfying (more specifically mangling) the file doesn't break it.
             * @ngInject
             */
            function sendTestEmailConfigDialogController($mdDialog, $q, $timeout, team) {

                const vm = this;
                let thread = null;

                vm.to = [];
                vm.mergeInSampleContactData = false;

                vm.cancel = $mdDialog.cancel;

                vm.send = function () {

                    return $mdDialog.hide({
                        to: vm.to.map(function (recipient) {

                            return recipient.email;
                        }),
                        mergeInSampleContactData: vm.mergeInSampleContactData
                    });
                };

                vm.searchDataSources = function (queryString) {

                    if (!queryString) {
                        return [];
                    }

                    return $q(function (resolve) {

                        $timeout.cancel(thread);
                        thread = $timeout(function () {

                            return team.getDataSources(queryString).then(function (response) {

                                const dataSources = response.data;

                                return resolve(dataSources.map(function (userDataSource) {

                                    return {
                                        name: userDataSource.user.name,
                                        email: userDataSource.email,
                                        picture: userDataSource.user.picture
                                    };
                                }));
                            });
                        }, 750);
                    });
                };
            }
        });
})();
