(function () {
    'use strict';

    angular
        .module('salesflare.components.fakeSticky', [])
        .directive('sfFakeSticky', sfFakeSticky);

    function sfFakeSticky($document, $timeout, $mdMedia) {

        return {
            restrict: 'A',
            scope: false,
            link
        };

        function link(scope, element) {

            element.addClass('fake-sticky');

            scope.$watch(function () {

                return $mdMedia('gt-sm');
            }, function (newValue, oldValue) {

                if (oldValue) {
                    return $timeout(registerOnScroll, 0, false, element);
                }
            });

            return $timeout(registerOnScroll, 0, false, element);
        }

        function registerOnScroll(element) {

            const scrollers = $document[0].querySelectorAll('.md-virtual-repeat-scroller');

            for (const scroller of scrollers) {
                const scrollerElement = angular.element(scroller);

                scrollerElement.on('scroll.sticky touchmove.sticky', scrollHandler(scroller, element));

                element.on('$destroy', unregisterOnScroll(scrollerElement));
            }
        }

        function scrollHandler(scroller, element) {

            return function actualScrollHandler() {

                if (scroller.scrollTop > 0) {
                    element.show();
                }
                else {
                    element.hide();
                }
            };
        }

        function unregisterOnScroll(scroller) {

            return function actualUnregisterOnScroll() {

                return scroller.off('scroll.sticky touchmove.sticky');
            };
        }
    }
})();
