(function () {
    'use strict';

    angular
        .module('salesflare.components.closingPercentage', [])
        .component('sfClosingPercentage', {
            templateUrl: 'app-ajs/components/dashboards/predefinedReports/closingPercentage/closingPercentage.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                data: '<'
            }
        });

    function controller(model) {

        const vm = this;

        vm.model = model;

        ////////////////

        vm.$onChanges = function (changes) {

            if (!changes || !changes.data || !changes.data.currentValue) {
                return;
            }

            if (vm.data.buckets.length === 0) {
                vm.data = {
                    no_data: true,
                    id: vm.data.id,
                    maxRepsInBucket: 9,
                    differenceInClosingPercentagePreviousPeriod: 2,
                    averageClosingPercentage: 24,
                    ranges: [
                        { name: 'low', description: 'Low performers', minRange: 12, maxRange: 16 },
                        { name: 'average', description: 'Average performers', minRange: 18, maxRange: 26 },
                        { name: 'high', description: 'High performers', minRange: 26, maxRange: 30 }
                    ],
                    buckets: [
                        { performanceRange: 'low', amount: 2, minRange: 12, maxRange: 14 },
                        { performanceRange: 'low', amount: 3, minRange: 14, maxRange: 16 },
                        { performanceRange: 'average', amount: 4, minRange: 16, maxRange: 18 },
                        { performanceRange: 'average', amount: 5, minRange: 18, maxRange: 20 },
                        { performanceRange: 'average', amount: 9, minRange: 22, maxRange: 24 },
                        { performanceRange: 'average', amount: 7, minRange: 24, maxRange: 26 },
                        { performanceRange: 'average', amount: 4, minRange: 26, maxRange: 28 },
                        { performanceRange: 'average', amount: 3, minRange: 28, maxRange: 30 },
                        { performanceRange: 'high', amount: 2, minRange: 30, maxRange: 32 },
                        { performanceRange: 'high', amount: 1, minRange: 32, maxRange: 34 }
                    ]
                };
            }
        };
    }
})();
