(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('trial', trialService);

    function trialService($interval, $timeout, sfHttp, statusBarService, utils, config, model, flagsService) {

        this.extend = function (reason) {

            return sfHttp.post(config.apiUrl + 'trial/extend', { reason });
        };

        const maxAmountOfDaysToUnlock = 30;

        let stop;
        let timeout;

        function stopPolling() {

            if (stop) {
                $interval.cancel(stop);
            }

            if (timeout) {
                $timeout.cancel(timeout);
            }

            stop = undefined;
            timeout = undefined;
        }

        function startPolling(pollFunction, delay) {

            if (!stop) {
                stop = $interval(pollFunction, delay || 30 * 60 * 1000);
            }
        }

        this.check = function (pollFunction) {

            if (!model.me.trial_expiry_date) {
                return stopPolling();
            }

            const expDate = new Date(model.me.trial_expiry_date);

            if (model.me.restricted || model.me.team.payment_type === 'free' || model.me.team.subscribed || (new Date() > expDate)) {
                return stopPolling();
            }

            startPolling(pollFunction);

            const planName = 'Pro';

            let trialStatusBarLinkText;

            const maxAmountOfDaysUnlocked = model.me.amount_of_trial_days_earned >= maxAmountOfDaysToUnlock;

            if (flagsService.get('gamifiedTrial') === false || maxAmountOfDaysUnlocked) {
                trialStatusBarLinkText = 'Subscribe';
            }
            else {
                trialStatusBarLinkText = 'Unlock more days';
            }

            const lastClosed = store.get('statusbar_trial_last_closed');

            // If not shown yet or at least 1 day after last close, show the trial status
            if (!lastClosed || moment().diff(moment(lastClosed), 'days') >= 1) {
                statusBarService.show({ type: 'trial', text: 'Your ' + planName + ' trial will expire ' + utils.getReadableTimeUntilTrialEnds(expDate), linkText: trialStatusBarLinkText });
            }

            // When trial should expire poll more frequently
            if (timeout) {
                $timeout.cancel(timeout);
            }

            timeout = utils.setLongTimeout(function () {

                stopPolling();
                return startPolling(pollFunction, 1000);
            }, expDate - Date.now());
        };
    }
})();
