(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('SignUpExceptionDialogController', SignUpExceptionDialogController);

    function SignUpExceptionDialogController($mdDialog) {

        const vm = this;

        vm.dialogId = 'createExceptionDialog';
        vm.title = 'Apply for an exception';
        vm.body = 'Please provide a reason for the exception and then login with LinkedIn to apply.';
        vm.ariaLabel = 'Reason';
        vm.label = 'Reason';
        vm.formId = 'exceptionForm';
        vm.required = true;
        vm.inputName = 'reason';
        vm.okText = 'Sign In with LinkedIn';
        vm.researcher = false;

        vm.hide = $mdDialog.hide;
        vm.cancel = $mdDialog.cancel;

        vm.securityResearcher = function () {

            vm.title = 'Sign up as a security researcher';
            vm.body = 'You can sign up to Salesflare as a security researcher with your @wearehackerone.com or @bugcrowdninja.com.';
            vm.researcher = true;

            // $window.open('https://www.salesflare.com/security', '_blank');
        };

        vm.validateEmailDomain = () => {

            if (!vm.researcher) {
                return;
            }

            const email = vm.model.toLowerCase();
            const domain = email.split('@')[1];

            if (domain === 'wearehackerone.com' || domain === 'bugcrowdninja.com') {
                vm[vm.formId].email.$setValidity('securityResearcherEmail', true);
            }
            else {
                vm[vm.formId].email.$setValidity('securityResearcherEmail', false);
            }
        };

        vm.signUpLinkedIn = function () {

            if (!vm[vm.formId].$valid) {
                return;
            }

            return vm.hide({ reason: vm.model, type: 'linkedin' });
        };

        vm.signupResearcher = function () {

            if (!vm[vm.formId].$valid) {
                return;
            }

            return vm.hide({ email: vm.model, type: 'security' });
        };
    }
})();
