(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ContactsListController', ContactsListController);

    function ContactsListController($scope, $timeout, $mdMedia, $state, $mdSidenav, contactsService, $mdDialog, $exceptionHandler, $transitions, utils, usersettings, filterService, bulkService, email, helperFunctionsService, sortService, workflowService) {

        const types = {
            CUSTOMERS: 'customer',
            MY_CONTACTS: 'mycontacts'
        };

        $scope.type.current = $state.current.name.includes(types.CUSTOMERS) ? types.CUSTOMERS : types.MY_CONTACTS;
        const currentTypeState = $scope.type.current === types.CUSTOMERS ? 'contacts.customers' : 'contacts.my';
        const entityCountObject = {
            viewTotalCount: 0,
            viewCurrentCount: 0,
            selectedCount: 0,
            canEditCount: 0,
            option: '',
            entity: 'contacts',
            entity_singular: 'contact',
            string: ''
        };

        $scope.bulk.allSelected = false;
        $scope.$mdMedia = $mdMedia;
        $scope.isContactFilterApplied = filterService.isContactFilterApplied;

        // Virtual repeat + infinite scroll object that angular material uses
        $scope.contacts = {
            toLoad: 0,
            numLoaded: 0,
            items: [],
            topIndex: 0,

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + 20;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad < index) {
                    this.toLoad += 20;

                    const boundFirstResponseHandler = angular.bind(this, contactsResponse);
                    const boundSecondResponseHandler = angular.bind(this, function (response) {

                        this.items = [...this.items, ...response.data];
                        this.numLoaded = this.toLoad;

                        if ($scope.bulk.allSelected) {
                            return setSelectedOnAllContacts();
                        }
                    });

                    let filterRules;
                    let putNewContactsFirst;

                    // Do not filter or put new first when searching
                    if ($scope.searchObject.getSearch()) {
                        filterRules = [];
                        putNewContactsFirst = false;
                    }
                    else {
                        filterRules = [...filterService.getFilter('contact'), ...filterService.getPredefinedFilters('person')[$scope.type.current]];
                        putNewContactsFirst = !filterService.isContactFilterApplied();
                    }

                    const filterGetOptions = {
                        search: $scope.searchObject.getSearch(),
                        filterRules,
                        limit: this.items.length === 0 ? this.toLoad : 20,
                        offset: this.items.length === 0 ? 0 : this.numLoaded,
                        orderBy: sortService.getCurrentSortOption('contact').order_by,
                        putNewFirst: putNewContactsFirst,
                        includeCount: false
                    };

                    if (this.items.length === 0) {
                        contactsService.filterGet(filterGetOptions).then(boundFirstResponseHandler, handleServerError, boundFirstResponseHandler);

                        // Do count calls after data calls as we want the data asap
                        // And only do this on initial load as the count doesn't change when loading more items
                        fetchCounts();
                        return;
                    }

                    return contactsService.filterGet(filterGetOptions).then(boundSecondResponseHandler, handleServerError, boundSecondResponseHandler);
                }
            },

            // Forces reset of object
            reload: function () {

                $scope.bulk.showBulkToolbar = false;
                $scope.bulk.allSelected = false;

                this.toLoad = 0;
                this.numLoaded = 0;
                this.items = [];
                this.doneLoading = false;
                this.topIndex = 0;
            }
        };

        function contactsResponse(response) {

            // Do not assign items to the model if we are currently searching and the response of the call was
            // not made with the same search string as the search string we currently have in our model.
            // This is done to prevent the full contacts list from showing when the call takes longer and the user
            // searches for something before the initial contacts call ended.
            if ($scope.searchObject.getSearch() && (angular.isUndefined(response.config.params.search) || response.config.params.search !== $scope.searchObject.getSearch())) {
                return;
            }

            const self = this;
            response.data = response.data.map(function (newContact) {

                const matched = self.items.find(function (item) {

                    return item.id === newContact.id;
                });

                if (matched) {
                    newContact.selected = matched.selected;
                }

                return newContact;
            });

            this.items = response.data;
            this.numLoaded = this.toLoad;
            this.doneLoading = true;

            if ($scope.bulk.allSelected) {
                setSelectedOnAllContacts();
            }

            $scope.sideNavObject.showSideNav = !(this.items.length === 0 && !$scope.selectedPerson.id);

            if (this.items.length > 0 && $mdMedia('gt-md') && $state.is(currentTypeState) && !$scope.selectedPerson.id) {
                return $scope.goToContact(response.data[0].id);
            }
        }

        function fetchCounts() {

            let filterRules;
            let defaultFilterRules;

            // Do not filter or put new first when searching
            if ($scope.searchObject.getSearch()) {
                filterRules = [];
                defaultFilterRules = [];
            }
            else {
                defaultFilterRules = filterService.getPredefinedFilters('person')[$scope.type.current];
                filterRules = [...filterService.getFilter('contact'), ...defaultFilterRules];
            }

            const totalCountOptions = {
                filterRules: defaultFilterRules,
                returnCountOnly: true
            };
            contactsService.filterGet(totalCountOptions).then(function (response) {

                return countResponseHandler(response, 'totalCount');
            }, handleServerError, function (response) {

                return countResponseHandler(response, 'totalCount');
            });

            const canEditCountOptions = {
                filterRules: [...filterRules, { id: 'contact.can_edit', operator: 'equal', value: [true] }],
                search: $scope.searchObject.getSearch(),
                returnCountOnly: true
            };
            contactsService.filterGet(canEditCountOptions).then(function (response) {

                return countResponseHandler(response, 'canEditCount');
            }, handleServerError, function (response) {

                return countResponseHandler(response, 'canEditCount');
            });

            if ($scope.searchObject.getSearch() || $scope.isContactFilterApplied()) {
                const currentCountOptions = {
                    filterRules,
                    search: $scope.searchObject.getSearch(),
                    returnCountOnly: true
                };
                contactsService.filterGet(currentCountOptions).then(function (response) {

                    return countResponseHandler(response, 'currentCount');
                }, handleServerError, function (response) {

                    return countResponseHandler(response, 'currentCount');
                });
            }
            else {
                // If we don't do a call for the current count, we need to reset it. Otherwise it will never update.
                entityCountObject.viewCurrentCount = 0;
            }
        }

        function countResponseHandler(response, type) {

            if (angular.isDefined(response.headers()['x-result-count'])) {
                entityCountObject.option = angular.isDefined($scope.searchObject.getSearch()) && $scope.searchObject.getSearch() !== '' ? 'search' :  ($scope.isContactFilterApplied() ? 'filter' : '');
                entityCountObject.sortString = sortService.getCurrentSortOption('contact').sort_string;

                if (type === 'totalCount') {
                    entityCountObject.viewTotalCount = Number.parseInt(response.headers()['x-result-count']);
                }

                if (type === 'currentCount') {
                    entityCountObject.viewCurrentCount = Number.parseInt(response.headers()['x-result-count']);
                }

                if (type === 'canEditCount') {
                    entityCountObject.canEditCount = Number.parseInt(response.headers()['x-result-count']);
                }

                entityCountObject.string = utils.getEntityCountString(entityCountObject);
            }
        }

        $scope.getEntityCountString = function () {

            return utils.getEntityCountString(entityCountObject);
        };


        $scope.goToContact = function (id) {

            if (id) {
                if ($mdMedia('md')) {
                    $mdSidenav('right').toggle();
                }

                $scope.selectedPerson.id = id;

                const goState = currentTypeState + '.contact';

                return $state.go(goState, { id });
            }
        };

        $scope.callPerson = helperFunctionsService.callPerson;

        //// bulk stuff
        $scope.bulk.bulkQuickActions = [];

        $scope.bulk.bulkQuickActions.splice(0, 0, {
            icon: 'sf-icon-delete',
            handler: function () {

                const confirm = $mdDialog.confirm()
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title('Warning!')
                    .htmlContent(`
                        <p>
                            You're about to delete ${entityCountObject.selectedCount + (entityCountObject.selectedCount > 1 ? ' contacts' : ' contact')}.
                            <br>
                            Are you sure you want to do this?
                        </p>
                    `)
                    .ok('I\'m sure')
                    .cancel('cancel');

                return $mdDialog.show(confirm).then(function () {

                    return bulkService.deleteContacts(getBulkOptions())
                        .then(function () {

                            return $state.reload();
                        })
                        .catch(function (err) {

                            $exceptionHandler(err);

                            return utils.showErrorToast();
                        });
                });
            },
            config: {
                toolTip: {
                    content: function () {
                        return 'Delete';
                    }
                }
            }
        }, {
            icon: 'sf-icon-edit',
            handler: function () {

                return $state.go('bulkEditContact', { options: getBulkOptions() });
            },
            config: {
                toolTip: {
                    content: function () {
                        return 'Edit';
                    }
                }
            }
        });

        // Find email requires an account context so it doesn't make sense to allow this for my contacts.
        // It will appear during search however since we have a mix of types then.
        toggleBulkFindEmail({ show: $scope.type.current === types.CUSTOMERS });

        // Returns options object with either the ids selected or the filter when all selected or searching
        function getBulkOptions(getUIFilterObject) {

            if ($scope.bulk.allSelected) {
                if ($scope.searchObject.getSearch()) {
                    return {
                        condition: 'AND',
                        rules: [],
                        search: $scope.searchObject.getSearch()
                    };
                }

                return {
                    condition: 'AND',
                    rules: [...filterService.getFilter('contact', { raw: !!getUIFilterObject }), ...filterService.getPredefinedFilters('person')[$scope.type.current]]
                };
            }

            return  {
                condition: 'AND',
                rules: [{
                    id: 'contact.id',
                    operator: 'in',
                    value: $scope.contacts.items.filter(function (contact) {

                        return contact.selected;
                    }).map(function (contact) {

                        return contact.id;
                    })
                }]
            };
        }

        $scope.bulk.onSelectAll = function ($event) {

            $scope.bulk.allSelected = $event.allSelected;

            if ($scope.bulk.allSelected) {
                entityCountObject.selectedCount = entityCountObject.canEditCount;
                entityCountObject.string = utils.getEntityCountString(entityCountObject);
            }
            else {
                $scope.bulk.showBulkToolbar = false;
                entityCountObject.selectedCount = 0;
                entityCountObject.string = utils.getEntityCountString(entityCountObject);
            }

            return setSelectedOnAllContacts();
        };

        $scope.checkShowBulkToolbar = function () {

            // Next tick so if a `selected` value needs to be updated it does that first before our check
            return $timeout(function () {

                $scope.bulk.showBulkToolbar = $scope.contacts.items.find(function (contact) {

                    return contact.selected;
                });

                $scope.bulk.allSelected = $scope.contacts.items.every(function (contact) {

                    return contact.selected;
                });

                if ($scope.bulk.showBulkToolbar) {
                    const selectedContacts = $scope.contacts.items.filter(function (contact) {

                        return contact.selected;
                    });

                    if ($scope.bulk.allSelected) {
                        entityCountObject.selectedCount = entityCountObject.canEditCount;
                    }
                    else {
                        entityCountObject.selectedCount = selectedContacts.length;
                    }

                    entityCountObject.string = utils.getEntityCountString(entityCountObject);
                }
                else {
                    entityCountObject.selectedCount = 0;
                    entityCountObject.string = utils.getEntityCountString(entityCountObject);
                }
            });
        };

        $scope.selectContact = function (contact) {

            if (contact.can_edit === false) {
                return;
            }

            contact.selected = true;

            return $scope.checkShowBulkToolbar();
        };
        //// end bulk stuff

        $scope.$watch(function () {

            return $mdMedia('gt-md');
        }, function (newValue, oldValue) {

            if (newValue && newValue !== oldValue && !$scope.selectedPerson.id) {
                return $scope.goToContact($scope.contacts.items[0].id);
            }

            return $scope.goToContact($scope.selectedPerson.id);
        });


        $scope.$watch(function () {

            return $mdMedia('gt-sm');
        }, function (newValue, oldValue) {

            if (oldValue) {
                $mdSidenav('right').close();
            }
        });

        $scope.$on('get', get);

        // This only triggers for classic filters
        $scope.$on('csv', function () {

            // Delete put_new_first property to ensure we always export alphabetically
            const filters = angular.copy(filterService.filters.contacts);
            delete filters.put_new_first;

            return contactsService.get($scope.searchObject.getSearch(), filters, 100000, 0, sortService.getCurrentSortOption('contact').order_by, true, $scope.type.current === types.CUSTOMERS, $scope.type.current === types.MY_CONTACTS);
        });

        // This only triggers for classic filters
        $scope.$on('excel', function () {

            // Delete put_new_first property to ensure we always export alphabetically
            const filters = angular.copy(filterService.filters.contacts);
            delete filters.put_new_first;

            return contactsService.get($scope.searchObject.getSearch(), filters, 100000, 0, sortService.getCurrentSortOption('contact').order_by, false, $scope.type.current === types.CUSTOMERS, $scope.type.current === types.MY_CONTACTS, true);
        });

        $scope.goToAccount = function (account) {

            if ($mdMedia('gt-sm')) {
                return $state.go('accounts.account.feed', { id: account.id, accountsId: account.id, name: account.name });
            }

            return $state.go('accounts.account.info', { id: account.id, name: account.name });
        };

        $scope.mailto = email.mailto;

        // If you use $scope.$destroy event and sign out the bearer token isn't available anymore therefore we use $rootScope $stateChangeStart event
        const removeStartTransitionHook = $transitions.onStart({}, (transition) => {

            const fromState = transition.from();
            const toState = transition.to();

            if (fromState.name && toState.name && fromState.name.includes(currentTypeState) && !toState.name.includes(currentTypeState)) {
                removeStartTransitionHook();

                if (!filterService.isContactFilterApplied()) {
                    if ($scope.type.current === types.CUSTOMERS) {
                        usersettings.setLastVisitedCustomersToNow().then(function () {

                            if ($scope.notViewedContacts) {
                                $scope.notViewedContacts.customers.amount_new = 0;
                            }
                        });
                    }
                    else if ($scope.type.current === types.MY_CONTACTS) {
                        usersettings.setLastVisitedMyContactsToNow().then(function () {

                            if ($scope.notViewedContacts) {
                                $scope.notViewedContacts.my_contacts.amount_new = 0;
                            }
                        });
                    }
                }
            }
        });

        $scope.$on('contact:account_created', function (event, person) {

            $scope.contacts.items = $scope.contacts.items.map(function (contact) {

                if (contact.id === person.id) {
                    if (!contact.account) {
                        if (contact.not_viewed) {
                            if ($scope.notViewedContacts.my_contacts.amount_new > 0) {
                                $scope.notViewedContacts.my_contacts.amount_new = $scope.notViewedContacts.my_contacts.amount_new - 1;
                            }
                        }

                        $scope.notViewedContacts.customers.amount_new += 1;
                    }

                    contact.account = person.account;
                }

                return contact;
            });
        });

        $scope.$watch('searchMode', (newValue, oldValue) => {

            if (newValue === oldValue) {
                return;
            }

            toggleBulkFindEmail({ show: newValue });
        });

        function get() {

            return $scope.contacts.reload();
        }

        function setSelectedOnAllContacts() {

            $scope.contacts.items.forEach(function (contact) {

                contact.selected = $scope.bulk.allSelected ? contact.can_edit : false;
            });
        }

        function handleServerError(response) {

            if (response.config.method === 'HEAD') {

                return;
            }

            $scope.contacts.numLoaded = $scope.contacts.toLoad;
            $scope.contacts.doneLoading = true;

            if (response && (response.status === 422 || response.status === 402)) {

                if (response.data.filter) {

                    const selectedRules = filterService.flatten(filterService.getFilter('contact', { raw: true }));
                    const hydratedRules = filterService.flatten(response.data.filter.rules);

                    const rulesToApply = filterService.intersectAndEnrich(selectedRules, hydratedRules);

                    $scope.filters = filterService.setFilter('contact', rulesToApply);

                    $scope.handleServerError($scope.filters);
                }
            }
        }

        function toggleBulkFindEmail({ show }) {

            if (!show) {
                $scope.bulk.bulkMenuItems = [];
                return;
            }

            $scope.bulk.bulkMenuItems = [
                {
                    label: 'Find email addresses',
                    handler: function () {

                        const confirm = $mdDialog.confirm()
                            .clickOutsideToClose(true)
                            .escapeToClose(true)
                            .title('Are you sure?')
                            .htmlContent(`
                                <p>
                                    This will use up to ${entityCountObject.selectedCount} email finding credits.
                                    <br>
                                    Salesflare will only search an email address if a contact name and account website are specified.
                                    <br>
                                    You'll get a notification when Salesflare is done looking for emails.
                                </p>
                            `)
                            .ok('I\'m sure')
                            .cancel('cancel');

                        return $mdDialog.show(confirm).then(function () {

                            return bulkService.findEmailsForContacts(getBulkOptions())
                                .then(function () {

                                    return $state.reload();
                                })
                                .catch(function (err) {

                                    $exceptionHandler(err);

                                    return utils.showErrorToast();
                                });
                        });
                    }
                },
                {
                    label: 'Send new workflow',
                    handler: () => {

                        return $state.go('composeWorkflow', {
                            filter: getBulkOptions(true)
                        });
                    }
                },
                {
                    label: 'Add to existing workflow',
                    handler: () => {

                        return $mdDialog.show({
                            controllerAs: 'vm',
                            fullscreen: !$mdMedia('gt-sm'),
                            multiple: true,
                            clickOutsideToClose: true,
                            escapeToClose: true,
                            onShowing: (scope, element) => {

                                element.children('md-dialog').addClass('add-to-workflow-dialog');
                            },
                            locals: {
                                showConfirmationDialog: true
                            },
                            templateUrl: 'app-ajs/components/workflows/workflow/addcontacttoworkflowdialog/addContactToWorkflowDialog.html',
                            controller: 'AddContactToWorkflowDialogController',
                            bindToController: true
                        }).then((result) => {

                            if (result.workflow) {
                                return workflowService.addToIndividualRecordFilter(result.workflow.id, getBulkOptions()).then(() => {
                                    $state.reload();
                                });
                            }
                        });
                    }
                }
            ];
        }
    }
})();
