(function () {
    'use strict';

    angular
        .module('salesflare.components.tasks.settings', [])
        .component('sfTaskSettings', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/tasks/settings/tasksettings.html'
        });

    function controller($mdMedia, $state, tasks, model, team) {

        const vm = this;

        vm.$onInit = function () {

            vm.$mdMedia = $mdMedia;

            return vm.get();
        };

        vm.get = function () {

            return tasks.getSettings().then(function (response) {

                const settings = response.data.filter(function (setting) {
                    // Remove the suggested_contact and suggested_account settings from task settings screen for now
                    // Since they are not being planned in the nearby future.
                    if (setting.name !== 'suggested_contact' && setting.name !== 'suggested_account') {
                        return true;
                    }

                    return false;
                }).map(function (setting) {

                    switch (setting.name) {
                        case 'email_reply':
                            setting.title = 'Email needing a reply';
                            setting.subheader = '';
                            setting.icon = 'email';
                            break;
                        case 'meeting_notes':
                            setting.title = 'Meeting notes';
                            setting.subheader = '';
                            setting.icon = 'meeting-live';
                            break;
                        case 'account_inactive':
                            setting.title = 'Account inactive for X days';
                            setting.subheader = '';
                            setting.icon = 'action-needed';

                            if (model.me.is_admin) {
                                setting.input = {
                                    description: 'Set the default # days to wait before an account becomes inactive for your team below. \n You can do this more in depth for each stage in the Configure Pipelines setting.',
                                    label: '# days',
                                    value: model.me.team.days_until_account_inactive,
                                    inputChanged: function () {

                                        // To counter the case where you backspace the value
                                        if (setting.input.value !== null) {
                                            return team.update({ days_until_account_inactive: setting.input.value }).then(function () {

                                                // We update the model here to ensure the user always sees the right value here (when leaving task settings and then coming back) so the user doesn't have to wait until the next /me call
                                                model.me.team.days_until_account_inactive = setting.input.value;
                                            });
                                        }
                                    }
                                };
                            }

                            break;
                        // Case 'suggested_contact':
                        //     setting.title = 'Suggested contacts';
                        //     setting.subheader = '';
                        //     setting.icon = 'person';
                        //     break;
                        // case 'suggested_account':
                        //     setting.title = 'Suggested account';
                        //     setting.subheader = '';
                        //     setting.icon = 'accounts';
                        //     break;
                    }

                    return setting;
                });

                // Sort the coming soon settings to the bottom
                vm.settings = settings.sort(function (setting) {

                    return setting.subheader === 'Coming soon...';
                });
            });
        };

        vm.save = function () {

            return tasks.saveSettings(vm.settings);
        };

        vm.back = function () {

            $state.go('settings');
        };
    }
})();
