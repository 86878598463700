(function () {
    'use strict';

    angular
        .module('salesflare.components.task', [])
        .component('sfTask', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/task/task.html',
            bindings: {
                task: '<',
                hideAccount: '<',
                hideDate: '<',
                selectable: '<',
                // Hooks
                onDismiss: '&',
                onUpdate: '&',
                onEdit: '&',
                onSelectedChanged: '&'
            }
        });

    function controller($state, $filter, $mdMedia, $document, $mdDialog, $mdPanel, $window, model, datasources, task, email, account, users, helperFunctionsService) {

        const vm = this;

        vm.$onInit = function () {

            vm.$mdMedia = $mdMedia;
            vm.$state = $state;
            vm.me = model.me;
            vm.isSelectable = false;
            // Sometimes $onInit is called after $onChanges which sets the isSelectable back to false. Below if block fixes this.
            if (vm.task) {
                vm.isSelectable = angular.isDefined(vm.selectable) ? (vm.selectable && vm.task.type !== 'meeting' && vm.task.can_edit) : vm.task.type !== 'meeting' && vm.task.can_edit;
            }

            vm.loading = false;
            vm.insufficientPermissionsMessage = 'You don’t have permission to edit this task';
            vm.typesMap = {
                manual_task: {
                    icon: 'check-box-circle',
                    actions: [{
                        handler: 'complete',
                        icon: 'check'
                    }]
                },
                email_reply: {
                    icon: 'email',
                    actions: [{
                        handler: 'reply',
                        icon: 'reply'
                    }]
                },
                account_inactive: {
                    icon: 'action-needed',
                    actions: [{
                        handler: 'complete',
                        icon: 'check'
                    }]
                },
                'meeting-live': {
                    icon: 'meeting-live',
                    actions: [{
                        handler: 'editMeeting',
                        icon: 'edit'
                    }]
                },
                'meeting-phone': {
                    icon: 'meeting-phone',
                    actions: [{
                        handler: 'editMeeting',
                        icon: 'edit'
                    }]
                },
                meeting_notes: {
                    icon: 'meeting-live',
                    actions: [{
                        handler: 'editMeeting',
                        icon: 'edit'
                    }]
                },
                // "new" task type for meetings = old 'meeting-live'
                meeting: {
                    icon: 'meeting-live',
                    actions: [{
                        handler: 'editMeeting',
                        icon: 'edit'
                    }]
                }
            };
        };

        vm.$onChanges = function () {

            if (!vm.task) {
                return;
            }

            vm.isSelectable = angular.isDefined(vm.selectable) ? (vm.selectable && vm.task.type !== 'meeting' && vm.task.can_edit) : vm.task.type !== 'meeting' && vm.task.can_edit;
            vm.task.plainDescription = $filter('htmlToPlaintext')(vm.task.description);
        };

        vm.goToAccount = function () {

            if (vm.task.account) {
                return $state.go('accounts.account.feed', { id: vm.task.account.id, name: vm.task.account.name });
            }
        };

        vm.editMeeting = function ($event) {

            let accountId = null;
            if (vm.task.account) {
                accountId = vm.task.account.id;
            }

            // If fullscreen, we call mdDialog.show. This is to avoid working with $scope
            if ($mdMedia('gt-sm')) {
                $mdDialog.show({
                    clickOutsideToClose: false,
                    escapeToClose: false,
                    templateUrl: 'partials/meeting.html',
                    controller: 'MeetingController',
                    onComplete,
                    locals: {
                        $stateParams: {
                            id: vm.task.meeting.id,
                            type: vm.task.type === 'meeting' ? 'meeting-live' : vm.task.type,
                            account: accountId
                        }
                    }
                }).then(function (updatedMeeting) {

                    if (updatedMeeting) {
                        const tmpTask = transformMeetingToTask(updatedMeeting, vm.task.type);
                        tmpTask.id = vm.task.id;
                        if (vm.task.completed) {
                            tmpTask.completed = vm.task.completed;
                            tmpTask.completion_date = vm.task.completion_date;
                            tmpTask.completor = vm.task.completor;
                        }

                        if (updatedMeeting.deleted) {
                            tmpTask.deleted = true;
                        }

                        vm.task = tmpTask;

                        // Check if meeting task needs to be completed or uncompleted
                        // This doesn't apply for meeting_notes
                        if (vm.task.type === 'meeting') {
                            if (new Date(vm.task.reminder_date) >= new Date(new Date().setHours(0, 0, 0, 0)) && vm.task.completed) {
                                vm.task.completed = false;
                            }

                            if (new Date(vm.task.reminder_date) < new Date(new Date().setHours(0, 0, 0, 0)) && !vm.task.completed) {
                                vm.task.completed = true;
                            }
                        }

                        return vm.onEdit({
                            $event: {
                                task: vm.task
                            }
                        });
                    }
                });
            }
            else {
                // If on mobile, call meeting like the account feed
                return $state.go('meeting', { id: $event.task.meeting.id, type: 'meeting-live' });
            }

        };

        vm.openWebsite = helperFunctionsService.openWebsite;

        /**
         * Close dialog helper functions
         * md-dialog close has no before close hooks
         */

        /**
         * @param {{}} scope scope of the dialog
         * @param {{}} element
         */
        function onComplete(scope, element) {

            exitDialogOnEscape(scope, element);
            exitDialogOnClickOutside(scope, element);
        }

        function exitDialogOnEscape(scope, element) {
            function keyHandlerFn(ev) {
                if (ev.keyCode === 27) {
                    ev.stopPropagation();
                    ev.preventDefault();

                    return scope.back();
                }
            }

            element.on('keydown', keyHandlerFn);
        }

        function exitDialogOnClickOutside(scope, element) {

            let sourceElem;

            function mousedownHandler(ev) {
                sourceElem = ev.target;
            }

            function mouseupHandler(ev) {
                if (sourceElem === element[0] && ev.target === element[0]) {
                    ev.stopPropagation();
                    ev.preventDefault();
                    return scope.back();
                }
            }

            element.on('mousedown', mousedownHandler);
            element.on('mouseup', mouseupHandler);
        }
        /**
         * End of close dialog helper functions
         */

        vm.complete = function complete() {

            // Return when already completed
            if (vm.task.completed) {
                return;
            }

            return task.update(vm.task.id, { completed: true }).then(function () {

                vm.task.completed = true;

                if (vm.onUpdate) {
                    return vm.onUpdate({
                        $event: {
                            task: vm.task
                        }
                    });
                }
            });
        };

        vm.unComplete = function unComplete() {

            // Return when already uncompleted
            if (!vm.task.completed) {
                return;
            }

            return task.update(vm.task.id, { completed: false }).then(function () {

                vm.task.completed = false;

                if (vm.onUpdate) {

                    return vm.onUpdate({
                        $event: {
                            task: vm.task
                        }
                    });
                }
            });
        };

        /**
         * Use loading indicator since getting raw email may take a few seconds
         *
         * @returns {void}
         */
        vm.reply = function reply() {

            vm.loading = true;

            return datasources.getMessage(vm.task.email.data_source, vm.task.email.service_message_id).then(function (response) {

                vm.loading = false;

                return email.compose(email.formatRawEmailToComposeObject('reply', response.data), 'reply');
            });
        };

        vm.snooze = function snooze(event) {

            const updateTask = angular.copy(vm.task);

            // eslint-disable-next-line unicorn/prefer-query-selector
            const elements = $document[0].getElementsByClassName('task-menu-' + updateTask.id);
            const rect = elements[0].getBoundingClientRect();

            const right = $window.innerWidth - rect.right;
            let top = rect.top;
            const left = 10;

            if (($window.innerHeight - top) < 250) {
                top = top - 250;
            }

            // We can't use relative positioning here
            // The virtual repeat used in the date picker would ruin the positioning of the panel
            let position = {};
            if (rect.right < 240) {
                position = $mdPanel.newPanelPosition()
                    .absolute()
                    .left(left + 'px')
                    .top(top + 'px');
            }
            else {
                position = $mdPanel.newPanelPosition()
                    .absolute()
                    .right(right + 'px')
                    .top(top + 'px');
            }

            return $mdPanel.open('reminderPanel', {
                openFrom: event,
                position,
                locals: {
                    reminder_date: updateTask.reminder_date
                },
                onRemoving: function (panelRef) {
                    const pickedDateTime = panelRef.config.locals.reminder_date;

                    if (!pickedDateTime) {
                        return;
                    }

                    return task.update(updateTask.id, { reminder_date: pickedDateTime }).then(function () {

                        updateTask.reminder_date = pickedDateTime;

                        if (vm.onUpdate) {
                            return vm.onUpdate({
                                $event: {
                                    task: updateTask
                                }
                            });
                        }
                    });
                }
            });
        };

        // eslint-disable-next-line no-shadow
        vm.isUpcoming = function isUpcoming(task) {

            if (!task) {
                return false;
            }

            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            tomorrow.setHours(0, 0, 0, 0);

            return (Date.parse(task.reminder_date) >= tomorrow);
        };

        // eslint-disable-next-line no-shadow
        vm.isOverdue = function isOverdue(task) {

            if (!task) {
                return false;
            }

            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            yesterday.setHours(23, 59, 59, 999);

            return (Date.parse(task.reminder_date) < yesterday);
        };

        vm.assign = function assign(event, editing) {

            const updateTask = angular.copy(vm.task);

            const position = $mdPanel.newPanelPosition()
                .relativeTo('.task-assign' + (vm.task.id ? '-' + vm.task.id : ''))
                .addPanelPosition($mdPanel.xPosition.CENTER, $mdPanel.yPosition.BELOW);

            let accountId = null;
            if (vm.task.account) {
                accountId = vm.task.account.id;
            }

            return $mdPanel.open({
                attachTo: angular.element($document[0].body),
                clickOutsideToClose: true,
                controller: 'AssignTaskPanelController',
                controllerAs: 'vm',
                templateUrl: 'partials/assigntaskpanel.html',
                openFrom: event,
                position,
                locals: {
                    task: updateTask,
                    users,
                    accountId,
                    editing
                },
                onRemoving: function () {

                    // The old users
                    const oldAssignees = vm.task.assignees.filter(function (oldAssignee) {
                        return angular.isDefined(oldAssignee);
                    });
                    const oldAssigneeIds = new Set(oldAssignees.map(function (oldAssignee) {
                        return oldAssignee.id;
                    }));
                    // The users after editing
                    const assignees = updateTask.assignees.filter(function (assignee) {
                        return angular.isDefined(assignee);
                    });
                    const assigneeIds = assignees.map(function (assignee) {
                        return assignee.id;
                    });
                    // Just the newly added assignees
                    const addedAssignees = assigneeIds.filter(function (assigneeId) {
                        return !oldAssigneeIds.has(assigneeId);
                    });

                    // If we have no newly added assignees, the task can just be updated and the list of tasks too
                    if (addedAssignees.length === 0) {
                        return updateTaskAndList(updateTask.id, assigneeIds, assignees);
                    }

                    // If there is no connection with an account, the same can be done
                    if (updateTask.account) {
                        // Check if assignees are part of the account users
                        return account.getUsers(updateTask.account.id, { noToast: true, noToastForStatusCode: [404] }).then(function (response) {

                            // eslint-disable-next-line no-shadow
                            const users = response.data;
                            const userIds = new Set(users.map(function (user) {
                                return user.id;
                            }));
                            const notPartOfAccount = addedAssignees.filter(function (assignee) {

                                return !userIds.has(assignee);
                            });

                            let newMemberId;
                            for (const newMemberIndex in notPartOfAccount) {
                                newMemberId = notPartOfAccount[newMemberIndex];
                                const confirm = $mdDialog.confirm()
                                    .clickOutsideToClose(true)
                                    .escapeToClose(true)
                                    .textContent('Do you want to add ' + assignees[assigneeIds.indexOf(newMemberId)].name + ' to the ' + updateTask.account.name + ' team as well?')
                                    .ok('Yes')
                                    .cancel('No');

                                $mdDialog.show(confirm).then(onConfirmDialogClosedConfirm, onConfirmDialogClosedDecline);
                            }

                            function onConfirmDialogClosedConfirm() {

                                updateAccountUsers(assignees, assigneeIds, newMemberId, updateTask);
                            }

                            function onConfirmDialogClosedDecline() {

                                updateTaskAndList(updateTask.id, assigneeIds, assignees);
                            }

                            return updateTaskAndList(updateTask.id, assigneeIds, assignees);
                        }).catch(function (err) {

                            if (err.status === 404) {
                                return updateTaskAndList(updateTask.id, assigneeIds, assignees);
                            }
                        });
                    }
                    else {
                        return updateTaskAndList(updateTask.id, assigneeIds, assignees);
                    }
                }
            });
        };

        function updateAccountUsers(assignees, assigneeIds, newMemberId, updateTask) {

            const usersToUpdate = assignees.filter(function (assignee) {

                if (assignee.id === newMemberId) {
                    assignee._dirty = true;
                }

                return assignee.id === newMemberId;
            });
            return account.updateUsers(updateTask.account.id, usersToUpdate).then(
                updateTaskAndList(updateTask.id, assigneeIds, assignees));
        }

        function updateTaskAndList(id, assigneeIds, assignees) {

            let updateNeeded = false;
            if (assigneeIds.length !== vm.task.assignees.length) {
                updateNeeded = true;
            }

            if (!updateNeeded) {
                const changedIds = vm.task.assignees.filter(function (assignee) {

                    return !assigneeIds.includes(assignee.id);
                });

                if (changedIds.length > 0) {
                    updateNeeded = true;
                }
            }

            // Only update when something changed
            if (updateNeeded) {
                vm.task.assignees = assignees;
                return task.update(id, { assignees: assigneeIds }).then(function () {

                    if (vm.onUpdate) {
                        return vm.onUpdate({
                            $event: {
                                task: vm.task
                            }
                        });
                    }
                });
            }
        }

        /**
         *
         * @param {Object} meeting
         * @param {'meeting' | 'meeting_notes'} type
         * @returns {Object}
         */
        function transformMeetingToTask(meeting, type) {

            // eslint-disable-next-line no-shadow
            const task = {
                type,
                selected: false
            };

            if (meeting.id) {
                task.meeting = {
                    id: meeting.id,
                    subject: meeting.subject,
                    conference_url: meeting.conference_url
                };
            }

            if (meeting.accounts) {
                task.account = meeting.accounts[0];
            }

            if (meeting.subject) {
                if (task.type === 'meeting') {
                    task.description = meeting.subject;
                    task.plainDescription = meeting.subject;
                }
                else {
                    task.description = 'Add notes to <b>' + meeting.subject + '</b>';
                    task.plainDescription = 'Add notes to ' + meeting.subject;
                }
            }

            if (meeting.creation_date) {
                task.creation_date = meeting.creation_date;
            }

            if (meeting.modification_date) {
                task.modification_date = meeting.modification_date;
            }

            if (meeting.participants) {
                task.assignees = meeting.participants.filter(function (participant) {

                    return participant.type && participant.type === 'user';
                });
            }

            if (task.type === 'meeting' && meeting.date) {
                task.reminder_date = meeting.date;
            }
            else if (task.type === 'meeting_notes' && meeting.end_date) {
                task.reminder_date = meeting.end_date;
            }

            return task;
        }

        vm.edit = function edit() {

            vm.task.editMode = true;

            if (vm.onEdit) {
                return vm.onEdit({
                    $event: {
                        task: vm.task
                    }
                });
            }
        };

        vm.dismiss = function dismiss() {

            const confirm = $mdDialog.confirm()
                .clickOutsideToClose(true)
                .textContent('Are you sure you want to dismiss this task?')
                .ok('Yes')
                .cancel('No');

            return $mdDialog.show(confirm).then(function () {

                return task.archive(vm.task.id).then(function () {

                    vm.task.archived = true;

                    if (vm.onDismiss) {
                        return vm.onDismiss({
                            $event: {
                                task: vm.task
                            }
                        });
                    }
                });
            }, angular.noop);
        };

        vm.select = function () {

            if (!vm.isSelectable) {
                return;
            }

            vm.task.selected = true;

            return vm.selectedChanged();
        };

        vm.selectedChanged = function () {

            if (vm.isSelectable && vm.onSelectedChanged) {
                return vm.onSelectedChanged({
                    $event: {
                        task: vm.task
                    }
                });
            }
        };
    }
}());
