(function () {
    'use strict';

    angular
        .module('salesflare.components.workflow.workflowCard', [])
        .component('sfWorkflowCard', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/workflows/workflow/card/workflowCard.html',
            bindings: {
                workflow: '<',
                showPlanFlags: '<',
                //
                onPlay: '&',
                onEdit: '&',
                onPause: '&',
                onArchive: '&'
            }
        });

    function controller($state) {

        const vm = this;

        vm.insufficientPermissionsMessage = 'You don\'t have permission to edit this workflow. Ask an admin.';

        vm.viewWorkflow = function (viewing) {

            if (!vm.workflow || !vm.workflow.id) {
                return;
            }

            if (viewing) {
                return $state.go('workflow-analytics', { id: vm.workflow.id, workflow: vm.workflow, viewing });
            }

            return $state.go('workflow', { id: vm.workflow.id });
        };

        vm.$onChanges = function (changes) {

            if (Object.keys(changes).length === 0) {
                return;
            }

            return changes;
        };

        vm.play = function () {

            return vm.onPlay({ workflowId: vm.workflow.id });
        };

        vm.edit = function () {

            return vm.onEdit({ workflowId: vm.workflow.id });
        };

        vm.pause = function () {

            return vm.onPause({ workflowId: vm.workflow.id });
        };

        vm.archive = function () {

            return vm.onArchive({ workflowId: vm.workflow.id });
        };
    }
})();
