<div class="manage-team-container" cdkDropListGroup>

    <sfx-toolbar enableDivider>
        Manage your team
    </sfx-toolbar>

    <sfx-content>
        <sfx-header>
            Team settings
        </sfx-header>
        <sfx-section *ngIf="team" data-layout="column">
            <form #teamForm="ngForm" id="teamForm" name="teamForm">
                <mat-form-field>
                    <mat-label>Team name</mat-label>
                    <input matInput #teamName="ngModel" name="teamName" [(ngModel)]="team.name" (ngModelChange)="teamNameChange()" required/>
                    <mat-error *ngIf="teamForm.controls['teamName']?.errors?.required">
                        Team name is required
                    </mat-error>
                </mat-form-field>
            </form>
            <mat-slide-toggle [(ngModel)]="team.add_all_users_to_accounts" (toggleChange)="onAddAllUsersToAccountsChange()" class="add-user-toggle">
                Add all enabled users to all existing and future accounts automatically
            </mat-slide-toggle>
        </sfx-section>
        <sfx-header>
            Outstanding invites
        </sfx-header>
        <sfx-section disableContentPadding>
            <ng-container *ngIf="modelService.me?.team?.payment_type !== 'samsung'">

                <ng-template #noInvites>
                    <p class="no-outstanding-invites-text">No outstanding invites.</p>
                </ng-template>

                <mat-list class="outstanding-invites-list" *ngIf="team?.invites?.length > 0; else noInvites">
                    <ng-container *ngFor="let invitation of team?.invites; last as isLast; first as isFirst">
                        <mat-list-item class="large invitation">
                            <div data-flex="grow" data-layout="start center" class="ellipsis invitation-wrapper">
                                <div data-layout="row" data-layout-align="start center" data-layout-gap="4px">
                                    <h1 class="ellipsis">
                                    <span class="ellipsis">
                                        {{ invitation.email }}
                                    </span>
                                    </h1>
                                    <span class="font-weight_normal text-color">-</span>
                                    <mat-select class="inline-dropdown ellipsis layout-row remove-gap" panelClass="inline-panel" [(ngModel)]="invitation.role" [ngModelOptions]="{ name: 'role' }" [placeholder]="invitation.role.name"
                                                [compareWith]="compareNames"
                                    >
                                        <mat-option (click)="updateInvite(invitation.id, { role: role.id })" *ngFor="let role of roles" [value]="role">{{ role.name }}</mat-option>
                                    </mat-select>
                                    <span class="font-weight_normal text-color">-</span>
                                    <mat-select class="inline-dropdown ellipsis layout-row" panelClass="inline-panel" [(ngModel)]="invitation.group" [ngModelOptions]="{ name: 'group' }" [placeholder]="invitation.group.name"
                                                [compareWith]="compareNames"
                                    >
                                        <mat-option [hidden]="group.disabledUsers" panelClass="inline-panel" (click)="updateInvite(invitation.id, { group: group.id })" *ngFor="let group of groups" [value]="group">{{ group.name }}</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div data-flex="none" *ngIf="media.isActive('gt-sm')" data-layout="row" data-layout-align="end center">
                                <div *ngIf="invitation.accepted">
                                    <button
                                            mat-button
                                            matTooltip="Copy invite link to clipboard"
                                            matTooltipPosition="above"
                                            [cdkCopyToClipboard]="invitation.url"
                                    >
                                        COPY LINK
                                    </button>
                                </div>

                                <button *ngIf="invitation.accepted" mat-button (click)="resendInvite(invitation.id)">
                                    RESEND
                                </button>

                                <button *ngIf="invitation.accepted" mat-button (click)="deleteInvite(invitation.id)">
                                    CANCEL
                                </button>

                                <button *ngIf="!invitation.accepted" mat-button (click)="acceptInvite(invitation.id)">
                                    ACCEPT
                                </button>

                                <button *ngIf="!invitation.accepted" mat-button (click)="deleteInvite(invitation.id)">
                                    REJECT
                                </button>
                            </div>
                            <div *ngIf="!media.isActive('gt-sm')" class="mat-secondary-container">
                                <div>
                                    <mat-menu #inviteMenu="matMenu" class="text-light-blue">
                                        <button
                                                *ngIf="invitation.accepted"
                                                mat-menu-item
                                                matTooltip="Copy invite link to clipboard"
                                                matTooltipPosition="left"
                                                [cdkCopyToClipboard]="invitation.url"
                                        >
                                            Copy link
                                        </button>
                                        <button *ngIf="invitation.accepted" mat-menu-item (click)="resendInvite(invitation.id)">
                                            Resend
                                        </button>
                                        <button *ngIf="invitation.accepted" mat-menu-item (click)="deleteInvite(invitation.id)">
                                            Cancel
                                        </button>
                                        <button *ngIf="!invitation.accepted" mat-menu-item (click)="acceptInvite(invitation.id)">
                                            Accept
                                        </button>
                                        <button *ngIf="!invitation.accepted" mat-menu-item (click)="deleteInvite(invitation.id)">
                                            Reject
                                        </button>
                                    </mat-menu>
                                    <button mat-icon-button [matMenuTriggerFor]="inviteMenu" class="line-height-36" aria-label="Open menu">
                                        <mat-icon class="action-menu-dots" fontSet="salesflare" fontIcon="sf-icon-menu-small-vertical"></mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-list-item>
                        <mat-divider *ngIf="!isLast"></mat-divider>
                    </ng-container>
                </mat-list>
            </ng-container>
        </sfx-section>
        <ng-container *ngFor="let group of groups; trackBy: group?.id; last as isLastGroup; first as isFirstGroup">
            <div
                    cdkDropList
                    [cdkDropListData]="group"
                    (cdkDropListDropped)="onDropListDropped($event)"
                    [cdkDropListDisabled]="group.disabledUsers"
                    [cdkDropListEnterPredicate]="canEnterDropzone"
            >
                <mat-divider></mat-divider>
                <sfx-header class="group-header" disableDividers (click)="hideShow(group)">
                    <div class="group-title-container" data-layout="row" data-layout-align="space-between center" data-layout-gap="4px" data-layout-gap-sm="16px">
                        <span data-flex="none">{{ group.name }}</span>
                        <span data-flex="grow" *ngIf="group.id && canAccessPermissions()" class="pipelines-text ellipsis font-weight_normal text-color">{{ ('- ' + getCommaSeparatedPipelineNames(group.pipelines)) }} </span>
                        <span data-flex="none" data-layout="row" data-layout-align="center center" data-layout-gap="4px">
                            <mat-icon *ngIf="group.id" track-id="update-group" fontSet="salesflare" fontIcon="sf-icon-edit" class="icon-20 icon-group-action icon-group-edit" (click)="updateGroup(group, $event);"></mat-icon>
                            <mat-icon *ngIf="group.id" track-id="delete-group" fontSet="salesflare" fontIcon="sf-icon-delete" class="icon-20 icon-group-action icon-group-remove" (click)="deleteGroup(group, $event);"></mat-icon>
                            <span data-layout="row" data-layout-align="center center" *ngIf="group.users.length">
                                <span class="group-count">{{ group.users.length }}</span>
                                <mat-icon fontSet="salesflare" fontIcon="sf-icon-collapse" class="icon-16 icon-group-action" *ngIf="!group.hide"></mat-icon>
                                <mat-icon fontSet="salesflare" fontIcon="sf-icon-expand" class="icon-16 icon-group-action" *ngIf="group.hide"></mat-icon>
                            </span>
                        </span>
                    </div>
                </sfx-header>
                <mat-divider class="test" *ngIf="(!group.hide && ( group.users.length > 0)) || isLastGroup"></mat-divider>
                <sfx-section disableContentPadding>
                    <mat-list
                            [hidden]="group.hide || draggingUser"
                    >
                        <button
                                *ngFor="let groupUser of group.users; last as isLast; first as isFirst"
                                mat-button class="user-item-button-container"
                        >

                            <mat-divider *ngIf="!isFirst"></mat-divider>
                            <mat-list-item
                                    (click)="this.stateService.go('user', { id: groupUser.id })"
                                    track-id="user" class="large"
                                    cdkDrag cdkDragBoundary=".manage-team-container"
                                    [cdkDragData]="groupUser"
                                    [cdkDragDisabled]="groupUser.disabled"
                                    [cdkDragStartDelay]="media.hasTouch()?300:0"
                                    cdkDragPreviewClass="dragging-user-item"
                                    (cdkDragStarted)="setItemDragState(groupUser, group,true)"
                                    (cdkDragEnded)="setItemDragState(groupUser, group, false)"
                                    [ngClass]="{ 'hide-user-item': groupUser.dragging, 'user-disabled': groupUser.disabled }"
                            >
                                <div class="user-item" data-layout="row" data-layout-align="start center">
                                    <sfx-avatar [src]="groupUser.picture"></sfx-avatar>
                                    <div #userItem class="mat-list-item-text">
                                        <div data-layout="row" data-layout-align="start center" data-layout-gap="4px">
                                            <div class="ellipsis" data-layout="row" data-layout-align="center center">
                                                <h1 class="ellipsis accent"  data-layout="row" data-flex data-layout-gap="4px">
                                                    <span>{{ groupUser.name }}</span>
                                                    <ng-container *ngIf="group.disabledUsers">
                                                        <span class="font-weight_normal text-color">-</span>
                                                        <span class="font-weight_normal text-color">{{ groupUser.originalGroup.name }}</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="groupUser.disabled || !modelService.me?.is_admin || modelService.me?.id === groupUser.id">
                                                        <span class="font-weight_normal text-color">-</span>
                                                        <span class="font-weight_normal text-color">{{ groupUser.role.name }}</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="groupUser.disabled && !group.disabledUsers">
                                                        <span class="font-weight_normal text-color">-</span>
                                                        <span class="font-weight_normal text-color">disabled</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="!groupUser.disabled && !(!modelService.me?.is_admin || modelService.me?.id === groupUser.id)">
                                                        <span class="font-weight_normal text-color separator-no-content">-</span>
                                                    </ng-container>
                                                </h1>
                                            </div>
                                            <mat-select
                                                    *ngIf="!groupUser.disabled && modelService.me?.is_admin && modelService.me?.id !== groupUser.id"
                                                    class="inline-dropdown"
                                                    [(ngModel)]="groupUser.role"
                                                    [placeholder]="groupUser.role.name"
                                                    (click)="onGroupUserSelectRole($event)"
                                                    panelClass="inline-panel"
                                                    [compareWith]="compareNames"
                                            >
                                                <mat-option
                                                        *ngFor="let role of roles"
                                                        data-layout="row"
                                                        (click)="updateUserRole(groupUser.id, role.id)"
                                                        [value]="role">
                                                    {{ role.name }}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                    <div>
                                        <button mat-button [color]="groupUser.disabled ? 'primary' : 'warn'"
                                                track-id="disable-user" (click)="disableUser($event, groupUser.id, !groupUser.disabled)"
                                                *ngIf="media.isActive('gt-sm') && (modelService.me?.id !== groupUser.id) && modelService.me?.is_admin && modelService.me?.team.payment_type !== 'samsung'">{{ groupUser.disabled ? "ENABLE" : "DISABLE" }}</button>
                                        <div *ngIf="!media.isActive('gt-sm') && (modelService.me?.id !== groupUser.id) && modelService.me?.is_admin && modelService.me?.team.payment_type !== 'samsung'">
                                            <mat-menu #enableDisableUser="matMenu" width="2">
                                                <button mat-menu-item
                                                        (click)="disableUser($event, groupUser.id, !groupUser.disabled)"
                                                >
                                                    <span>{{ groupUser.disabled ? "Enable" : "Disable" }}</span>
                                                </button>
                                            </mat-menu>
                                            <button mat-icon-button [matMenuTriggerFor]="enableDisableUser" aria-label="Open menu" (click)="openMenu($event)">
                                                <mat-icon class="action-menu-dots" fontSet="salesflare" fontIcon="sf-icon-menu-small-vertical"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="user-item-placeholder mat-list-item" *cdkDragPlaceholder></div>

                            </mat-list-item>

                        </button>

                    </mat-list>

                </sfx-section>

            </div>
        </ng-container>
    </sfx-content>
</div>

<sfx-speed-dial class="fab-dial">
    <button speedDialAction icon="sf-icon-group-add" label="Create group" [action]="this.createGroup"></button>
    <button speedDialAction icon="sf-icon-person-add" label="Invite team member" [action]="this.invite"></button>
</sfx-speed-dial>
