(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('dashboardService', dashboardService);

    function dashboardService(sfHttp, config) {

        this.getReports = function (dashboardId) {

            return sfHttp.get(config.apiUrl + 'dashboards/' + dashboardId + '/reports');
        };

        this.get = function (dashboardId) {

            return sfHttp.get(config.apiUrl + 'dashboards/' + dashboardId);
        };

        this.update = function (dashboardId, update) {

            return sfHttp.put(config.apiUrl + 'dashboards/' + dashboardId, update);
        };

        this.delete = function (dashboardId) {

            return sfHttp.delete(config.apiUrl + 'dashboards/' + dashboardId);
        };
    }
})();
