(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('contact', contactService);

    function contactService($q, sfHttp, config, contactsService, filterService, account, Upload, utils, model) {

        /**
         *
         * @param {Number} id
         * @param {Object} options
         * @param {Boolean} [options.expandAccount=false]
         * @param {Boolean} [options.noToast]
         * @param {Array.<Number>} [options.noToastForStatusCode] `noToast` needs to be `true` for this to apply
         * @returns {Promise.<Object>}
         */
        this.get = function (id, options) {

            return sfHttp.get(config.apiUrl + 'contacts/' + id, {
                cache: true,
                noToast: options && options.noToast,
                noToastForStatusCode: options && options.noToastForStatusCode
            }).then(function (contactResponse) {

                if (contactResponse.data.account && options && options.expandAccount) {
                    return account.get(contactResponse.data.account.id).then(function (accountResponse) {

                        contactResponse.data.account = accountResponse.data;

                        return $q.resolve(contactResponse);
                    });
                }

                return $q.resolve(contactResponse);
            });
        };

        this.getContactByFilter = function (filter, options) {

            const filterGetOptions = {
                search: (filter && filter.search) || '',
                filterRules: filterService.cleanAdvancedFilterForHttp(filter && filter.rules),
                limit: 1
            };

            // Also properly handle the case when no contacts are returned, for now we return null
            return contactsService.filterGet(filterGetOptions).then(function (contactResponse) {

                if (contactResponse.data.length === 0) {
                    contactResponse.data = null;
                    return $q.resolve(contactResponse);
                }

                contactResponse.data = contactResponse.data[0];

                if (contactResponse.data.account && options && options.expandAccount) {
                    return account.get(contactResponse.data.account.id).then(function (accountResponse) {

                        contactResponse.data.account = accountResponse.data;

                        return $q.resolve(contactResponse);
                    });
                }

                return $q.resolve(contactResponse);
            });
        };

        this.update = function (contact) {

            let pictureBlob;

            if (contact.picture && contact.picture.startsWith('data:image/')) {
                pictureBlob = utils.dataURIToBlob(contact.picture);
                delete contact.picture;
            }

            if (contact.name && (contact.firstname && contact.firstname !== '') && (contact.lastname && contact.lastname !== '')) {
                delete contact.name;
            }

            contact.owner = contact.owner.id || contact.owner;

            return sfHttp.put(config.apiUrl + 'contacts/' + contact.id, contact).then(function (response) {

                if (!pictureBlob) {
                    return response;
                }

                return Upload.upload({
                    url: config.apiUrl + 'contacts/' + contact.id + '/picture',
                    data: {
                        file: pictureBlob
                    }
                }).then(function () {

                    return response;
                });
            });
        };

        this.create = function (contact, preventOverwrite) {

            let url = config.apiUrl + 'contacts';
            let pictureBlob;

            if (preventOverwrite) {
                url += '?force=false';
            }

            if (contact.name && (contact.firstname && contact.firstname !== '') && (contact.lastname && contact.lastname !== '')) {
                delete contact.name;
            }

            if (contact.picture && contact.picture.startsWith('data:image/')) {
                pictureBlob = utils.dataURIToBlob(contact.picture);
                delete contact.picture;
            }

            return sfHttp.post(url, contact).then(function (response) {

                if (!pictureBlob) {
                    return response;
                }

                return Upload.upload({
                    url: config.apiUrl + 'contacts/' + response.data.id + '/picture',
                    data: {
                        file: pictureBlob
                    }
                }).then(function () {

                    return response;
                });
            });
        };

        this.delete = function (id, permanentDelete) {

            if (!permanentDelete) {
                return sfHttp.delete(config.apiUrl + 'contacts/' + id).then((response) => {

                    // Update it here so we don't need to refetch model.me entirely
                    model.me.has_archived_contacts = true;

                    return $q.resolve(response);
                });
            }

            return sfHttp.delete(config.apiUrl + 'contacts/' + id, { headers: { 'X-Salesflare-Permanent': 'true' } });
        };

        this.scanBusinessCard = function (imageData) {
            const url = config.apiUrl + 'contacts/scan';
            return sfHttp.post(url, { business_card: imageData }, { ignoreLoadingBar: false });
        };

        this.getNetworkContact = function (id) {

            return sfHttp.get(config.apiUrl + 'contacts/' + id + '/mutual', { cache: true });
        };

        this.getMutualContacts = function (id) {

            return sfHttp.get(config.apiUrl + 'contacts/' + id + '/mutualContacts', { cache: true });
        };

        this.getAmountOfNotViewedContacts = function () {

            return sfHttp.get(config.apiUrl + 'contacts/new');
        };

        this.splitName = function (fullName) {

            const request = {
                params: {
                    full_name: fullName
                }
            };
            return sfHttp.get(config.apiUrl + 'contacts/namesplit', request);
        };

        this.findEmail = function (data) {

            return sfHttp.post(config.apiUrl + 'contacts/email/search', data);
        };
    }
})();
