(function () {
    'use strict';

    angular
        .module('salesflare.components.workflowCompose', [])
        .component('sfWorkflowCompose', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/workflowcompose/workflowcompose.html',
            bindings: {
                /* General attributes */
                onWorkflowFormDirtyChanged: '&',
                onWorkflowDataChanged: '&',
                onWorkflowFormValidityChanged: '&',
                savingDraft: '<', // When saving a draft less fields are required, changing this parameter could possibly trigger a change of the emailForm's validity
                /* workflow specific attributes */
                filterText: '<',
                disableEditFilter: '<',
                showAutomaticSwitch: '<',
                filter: '<'
            }
        });

    function controller($document, $q, $mdDialog, $mdMedia, $scope, $timeout, $window, $compile, $sce, datasources, filterService, workflowsService, contactsService, contact, utils, fileService, model, email, joditService, workflowService, humanReadableAdvancedFilterService) {

        const vm = this;

        /**
         * 'send email' step defaults START
         */
        const unsubscribeUrl = '<div><a style="color: #6d7684;" href="{{ unsubscribe_url }}" target="_blank" rel="noopener noreferrer">Unsubscribe from our emails</a></div>';
        // We also match the preceding br added by the editor to make sure we remove that as well
        const unsubscribeUrlDetectionRegex = /((<div><br><\/div>)|(<br>))?<div><a[^>]*?{{ unsubscribe_url }}[^>]*?>.*?<\/a><\/div>/gm;
        const previewUnsubscribeUrl = '<div><a style="color: #6d7684;" href target="_blank" rel="noopener noreferrer">Unsubscribe from our emails</a></div>'; // Fake unsubscribe url
        const recordType = 'contact';
        let viewHTML = false;
        let viewPreview = false;
        let attachmentsContainer;
        let jodit;
        const maxAttachmentsSizes = {
            google: 25 * 1000 * 1000, // 25MB
            office365: 8 * 1000 *  1000, // 8MB // Set it to 8MB because of 150 megabytes (MB) upload limit in a 5 minute period which could cause issues for workflow emails (sending an email every 18s) (https://learn.microsoft.com/en-us/graph/throttling-limits#limits-per-app-id-and-mailbox-combination)
            smtp: 10 * 1000 * 1000, // 10MB
            individualFile: 10 * 1000 * 1000 // 10MB
        };
        let totalAttachmentsSize = 0;

        vm.preview = undefined;
        vm.$mdMedia = $mdMedia;
        vm.options = {
            includeUnsubscribeLink: false
        };
        /**
         * 'send email' step defaults END
         */

        /**
         * Schedule workflow preparation START
         */
        // If a scheduled date is picked, this will show true, don't confuse with the actual 'scheduled' status a workflow can have
        vm.isScheduledWorkflow = false;
        const closestHalfHourDate = getNextHalfHourDateTime();
        const halfHoursOfTheDay = utils.halfHoursOfTheDay.map(function (t) {

            return new Date(new Date().setHours(t[0], t[1], 0, 0));
        });

        const halfHoursOfTheDayAfterNow = halfHoursOfTheDay.filter(function (t) {

            return t >= closestHalfHourDate;
        });
        let timeChanged = false;

        vm.showBalkButton = false;

        vm.today = new Date();
        vm.date = new Date();

        vm.halfHoursOfTheDayForSetLive = utils.isToday(vm.date) ? halfHoursOfTheDayAfterNow : halfHoursOfTheDay;
        vm.time = closestHalfHourDate.getTime();
        /**
         * Schedule workflow preparation END
         */

        vm.halfHoursOfTheDay = halfHoursOfTheDay;
        vm.daysOfTheWeek = utils.getDaysOfTheWeekStartingOn(model.me.first_day_of_week);
        vm.daysToSendOn = [1, 2, 3, 4, 5]; // Weekdays
        vm.startHour = new Date(new Date().setHours(9, 0, 0, 0)).getTime();
        vm.endHour = new Date(new Date().setHours(18, 0, 0, 0)).getTime();

        vm.$onInit = function () {

            if (vm.filter && !isFilterFromSelectedContacts(vm.filter)) {
                vm.filter = vm.filter || {};
            }

            if (angular.isUndefined(vm.showWorkflowName)) {
                vm.showWorkflowName = true;
            }

            return init().then(function () {

                jodit = joditService.getJoditEditor(getJoditInitializationObject(),
                    // On change sync body text with our model
                    function (bodyText) {

                        vm.stepConfig.body = bodyText;
                    },
                    // Sync mode changes with our model
                    function (mode) {

                        viewHTML = mode === 'html';
                    }
                );

                if (vm.stepConfig) {
                    /*
                     * Adds the signature to the email body when:
                     *      creating a new (`!vm.stepConfig.body`) workflow from a data source (`vm.stepConfig.from`) with `add_signature_to_campaign` set to `true`
                     *      replying (all)/forwarding an email from a data source (`vm.stepConfig.from`) with `add_signature_to_replies` to `true`
                     */
                    if (!vm.stepConfig.body && vm.stepConfig.from && vm.stepConfig.from.email_signature && vm.stepConfig.from.add_signature_to_campaign) {
                        vm.stepConfig.body = ('<br><br><div class="sf-signature">' + vm.stepConfig.from.email_signature + '</div>');
                    }

                    jodit.setEditorValue(vm.stepConfig.body || '');
                }

                // Make sure we append it after the source
                // Basically wait for Jodit to add the jodit_source
                $timeout(function () {

                    // The container allows us to easily add the attachments in 1 place and apply some styling (like clear:both), check jodit.scss
                    attachmentsContainer = $compile(''
                        + '<div class="jodit-attachments-container">'
                        + '    <md-chip ng-repeat="file in vm.stepConfig.attachments" title="{{ file.file_name }}">'
                        + '        <div layout="row" ng-click="vm.download(file.file_url)">'
                        + '            <md-progress-circular ng-if="!file.file_type" class="spinner" md-diameter="16" md-mode="indeterminate"></md-progress-circular>'
                        + '            <md-icon ng-if="file.file_type" class="icon icon-16 md-font material-icons" md-font-icon="{{ file.file_type | attachmentIcon }}"></md-icon>'
                        + '            <span class="accent ellipsis">{{ file.file_name }}</span>'
                        + '            <md-icon class="icon icon-18 md-font material-icons sf-icon-close" ng-click="vm.removeAttachment(file.id, file.file_size)"></md-icon>'
                        + '        </div>'
                        + '    </md-chip>'
                        + '</div>'
                    )($scope);
                    angular.element(jodit.container).find('.jodit-workplace').append(attachmentsContainer);
                });
            });
        };

        vm.$onChanges = function (changes) {

            if (changes.savingDraft && !changes.savingDraft.isFirstChange()) {
                setFilterValidity();
            }
        };

        /**
         * Watch changes START
         */
        $scope.$watch('vm.workflowConfig', function (newVal, oldVal) {

            if (angular.equals(newVal, oldVal)) {
                return;
            }

            // When the message has changed the form is definitely dirty
            // This fixes the ng-quil-editor not setting the form to dirty
            $scope.workflowConfigForm.$setDirty();

            // Continuous workflows don't need a filter to be set live anymore
            if (newVal.continuous !== oldVal.continuous) {
                setFilterValidity();
            }

            if (vm.onWorkflowDataChanged && vm.stepConfig) {
                return vm.onWorkflowDataChanged({
                    $event: {
                        messageObject: getFormattedMessage(),
                        filterText: vm.to,
                        senderDataSource: vm.stepConfig.from
                    }
                });
            }
        }, true);


        $scope.$watch('vm.stepConfig', function (newVal, oldVal) {

            // The watch listener gets called with the same new and old val every time.
            // But we are only interested when the message has actually changed
            // We also don't want this triggered when initializing the `from` property
            if (angular.equals(newVal, oldVal) || !oldVal.from) {
                return;
            }

            // Only set custom message to null if it's not currently needed
            if (!$scope.workflowConfigForm.hiddenTo.$invalid) {
                vm.customMessage = null;
            }

            const isInvalid = vm.stepConfig && vm.stepConfig.from && totalAttachmentsSize > maxAttachmentsSizes[vm.stepConfig.from.type];

            if (isInvalid && $scope.workflowConfigForm.attachments.$valid) {
                const message = 'Total size of all attachments cannot be larger than ' + (maxAttachmentsSizes[vm.stepConfig.from.type] / 1000000) + 'MB';
                utils.showErrorToast(message);
                vm.customMessage = message;
            }

            $scope.workflowConfigForm.attachments.$setValidity('attachment size', !isInvalid);

            // If the from (data source) changes update the signature if needed
            if (jodit && oldVal.from && oldVal.from.email && (oldVal.from.email !== newVal.from.email)) {
                const dataSource = newVal.from;
                const addNewSignature = (
                    dataSource && dataSource.email_signature
                    && (dataSource.add_signature_to_campaign)
                );

                // If old signature -> remove
                const currentSignature = jodit.editor.querySelector('.sf-signature');
                if (currentSignature) {
                    jodit.editor.querySelector('.sf-signature').remove();
                    jodit.setEditorValue(); // Sync model
                }

                // If new sig
                //      if sf-message -> insertAdjacentHTML(beforebegin, sig) -- basically insert before sf-message
                //      else add to end
                if (addNewSignature) {
                    const sfMessage = jodit.editor.querySelector('.sf-message');
                    if (sfMessage) {
                        sfMessage.insertAdjacentHTML('beforebegin', '<div class="sf-signature">' + newVal.from.email_signature + '</div>');
                        jodit.setEditorValue(); // Sync model
                    }
                    else {
                        jodit.setEditorValue(jodit.getEditorValue() + '<div class="sf-signature">' + newVal.from.email_signature + '</div>');
                    }
                }
            }

            // When the message has changed the form is definitely dirty
            // This fixes the ng-quil-editor not setting the form to dirty
            $scope.workflowConfigForm.$setDirty();

            if (vm.onWorkflowDataChanged && vm.stepConfig) {
                return vm.onWorkflowDataChanged({
                    $event: {
                        messageObject: getFormattedMessage(),
                        filterText: vm.to,
                        senderDataSource: vm.stepConfig.from
                    }
                });
            }
        }, true);

        $scope.$watch('workflowConfigForm.$dirty', function () {

            if (vm.onWorkflowFormDirtyChanged) {
                return vm.onWorkflowFormDirtyChanged({
                    $event: {
                        $dirty: $scope.workflowConfigForm.$dirty
                    }
                });
            }
        });

        $scope.$watch('workflowConfigForm.$valid', function () {

            if (vm.onWorkflowFormValidityChanged) {
                return vm.onWorkflowFormValidityChanged({
                    $event: {
                        $valid: $scope.workflowConfigForm.$valid
                    }
                });
            }
        });
        /**
         * Watch changes END
         */

        vm.preventEnterSubmit = function ($event) {

            if ($event.keyCode === 13 && $event.target.nodeName === 'INPUT') {
                $event.preventDefault();
                $event.stopPropagation();
            }
        };

        vm.chooseAudience = function () {

            return $mdDialog.show({
                fullscreen: true,
                clickOutsideToClose: true,
                bindToController: true,
                controller: 'ChooseAudienceController',
                templateUrl: 'partials/chooseaudience.html',
                multiple: true,
                onShowing: function (scope, element) {

                    element.children('md-dialog').addClass('choose-audience-dialog');
                },
                locals: {
                    filter: vm.filter,
                    isFilterApplied
                }
            }).then(function (audience) {

                $scope.workflowConfigForm.hiddenTo.$setDirty();

                vm.filter = audience.filter;

                setTo();
                setFilterValidity();

                if (vm.onWorkflowDataChanged && vm.stepConfig) {
                    return vm.onWorkflowDataChanged({
                        $event: {
                            messageObject: getFormattedMessage(),
                            filterText: vm.to,
                            senderDataSource: vm.stepConfig.from
                        }
                    });
                }
            });
        };

        vm.selectIndividualContacts = function () {

            return $mdDialog.show({
                fullscreen: true,
                clickOutsideToClose: true,
                bindToController: true,
                controller: 'SelectIndividualContactsController',
                controllerAs: 'vm',
                templateUrl: 'partials/selectindividualcontacts.html',
                multiple: true,
                onShowing: function (scope, element) {

                    element.children('md-dialog').addClass('choose-audience-dialog');
                },
                locals: {
                    individualRecordFilter: vm.individualRecordFilter
                }
            }).then(function (audience) {

                if (audience) {
                    $scope.workflowConfigForm.hiddenTo.$setDirty();

                    vm.individualRecordFilter = audience.individualRecordFilter;

                    setTo();
                    setFilterValidity();

                    vm.workflowIndividualContactsText = getIndividualRecordsText();
                }
            });
        };

        vm.showingPreview = function () {

            return viewPreview;
        };

        vm.showingHTML = function () {

            return viewHTML;
        };

        vm.onRecipientsChanged = function () {

            if (vm.onWorkflowDataChanged && vm.stepConfig) {
                return vm.onWorkflowDataChanged({
                    $event: {
                        messageObject: getFormattedMessage(),
                        filterText: vm.to,
                        senderDataSource: vm.stepConfig.from
                    }
                });
            }
        };

        vm.focusElement = function (id) {

            return angular.element('#' + id).focus();
        };

        vm.clickElement = function (id) {

            return angular.element('#' + id).click();
        };


        /**
         * Attachment helper functions START
         */

        vm.uploadAttachments = function (files) {

            vm.showDragOverlay = false;

            if (!files || files.$error) {
                return;
            }

            vm.stepConfig.attachments = vm.stepConfig.attachments || [];

            files.forEach(function (file) {

                const displayFile = {
                    file_name: file.name,
                    file_size: file.size
                };

                if (file.size > Math.min(maxAttachmentsSizes.individualFile, maxAttachmentsSizes[vm.message.from.type])) {
                    const fileMessage = `A file cannot be larger than ${Math.min(maxAttachmentsSizes.individualFile, maxAttachmentsSizes[vm.message.from.type]) / 1000000}MB`;
                    return utils.showErrorToast(fileMessage);
                }

                if (totalAttachmentsSize + file.size > maxAttachmentsSizes[vm.stepConfig.from.type]) {
                    const message = 'Total file size cannot be larger than ' + (maxAttachmentsSizes[vm.stepConfig.from.type] / 1000000) + 'MB';
                    return utils.showErrorToast(message);
                }

                totalAttachmentsSize += file.size;

                vm.stepConfig.attachments.push(displayFile);

                return fileService.uploadFile(file, true, function (newFile) {

                    const index = vm.stepConfig.attachments.indexOf(displayFile);

                    if (!newFile) { // Ergo something went wrong
                        // if still in the list remove it
                        if (index !== -1) {
                            vm.stepConfig.attachments.splice(index, 1);
                        }

                        totalAttachmentsSize -= file.size;
                        return;
                    }

                    newFile.file_size = file.size;

                    if (newFile && index !== -1) {
                        vm.stepConfig.attachments[index] = newFile;
                    }
                });
            });

            // Scroll to bottom of the email
            return $timeout(function () {

                const element = angular.element(jodit.container).find('.jodit-workplace')[0];

                if (element) {
                    element.scrollTop = element.scrollHeight;
                }
            }, 100);
        };

        vm.removeAttachment = function (fileId, size) {

            vm.stepConfig.attachments = vm.stepConfig.attachments.filter(function (f) {

                return f.id !== fileId;
            });

            if (!vm.stepConfig.filesToDelete) {
                vm.stepConfig.filesToDelete = [];
            }

            vm.stepConfig.filesToDelete.push(fileId);
            totalAttachmentsSize = totalAttachmentsSize - size;
        };

        vm.download = function (downloadUrl) {

            $window.open(downloadUrl, '_blank', 'noopener');
        };

        vm.drag = function (isDragging, cl, event) {

            // Needs the sync apply to for it to actually show properly
            return $scope.$apply(function () {

                const files = event.dataTransfer && event.dataTransfer.items;

                if (!files) { // This can happen when you start draging but then drag away of the page
                    vm.showDragOverlay = false;
                }
                else if (isDragging && files.length > 1) {
                    vm.showDragOverlay = true;
                }
                else {
                    vm.showDragOverlay = isDragging && !files[0].type.includes('image');
                }
            });
        };
        /**
         * Attachment helper functions END
         */


        /**
         * Scheduling helper functions START
         */

        vm.onDateChanged = function () {

            if (utils.isToday(vm.date)) {
                vm.halfHoursOfTheDayForSetLive = halfHoursOfTheDayAfterNow;

                // When a time was selected that is not allowed today reset to nextHalfHour
                if (timeChanged && vm.time < getNextHalfHourDateTime().getTime()) {
                    vm.time = getNextHalfHourDateTime().getTime();
                    timeChanged = false;
                }
            }
            else {
                vm.halfHoursOfTheDayForSetLive = halfHoursOfTheDay;
            }

            if (!timeChanged) {
                if (utils.isToday(vm.date)) {
                    vm.time = getNextHalfHourDateTime().getTime();
                }
                else {
                    vm.time = new Date(new Date().setHours(9, 0, 0, 0)).getTime();
                }
            }

            return vm.onWorkflowDataChanged({
                $event: {
                    messageObject: getFormattedMessage(),
                    filterText: vm.to,
                    senderDataSource: vm.stepConfig.from
                }
            });
        };

        vm.onTimeChanged = function () {

            timeChanged = true;
            return vm.onWorkflowDataChanged({
                $event: {
                    messageObject: getFormattedMessage(),
                    filterText: vm.to,
                    senderDataSource: vm.stepConfig.from
                }
            });
        };

        vm.onScheduleTimeChanged = function () {

            if (vm.startHour > vm.endHour) {
                vm.endHour = vm.startHour + 1800000; //Move to next half hour slot
            }

            return vm.onWorkflowDataChanged({
                $event: {
                    messageObject: getFormattedMessage(),
                    filterText: vm.to,
                    senderDataSource: vm.stepConfig.from
                }
            });
        };

        vm.changeScheduleStatus = function () {

            if (vm.isScheduledWorkflow) {
                // Only set the status to 'scheduled' when actually scheduling the workflow
                // When we're saving a draft, we want to keep the status on 'draft' as well
                vm.workflowConfig.status = 'draft';
            }
            else {
                vm.time = getNextHalfHourDateTime().getTime();
                vm.date = new Date();
                vm.workflowConfig.status = 'draft';
            }

            return vm.onWorkflowDataChanged({
                $event: {
                    messageObject: getFormattedMessage(),
                    filterText: vm.to,
                    senderDataSource: vm.stepConfig.from
                }
            });
        };

        /**
         * @returns {Date}
         */
        function getNextHalfHourDateTime() {

            // Get next half hour date time from now
            let minutes = new Date().getMinutes();
            let hours = new Date().getHours();
            if (minutes < 30) {
                minutes = 30;
            }
            else {
                minutes = 0;
                hours++;
            }

            return new Date(new Date().setHours(hours, minutes, 0, 0));
        }
        /**
         * Scheduling helper functions END
         */

        ////////////////////////

        function init() {

            // Register a helper function for Handlebars, needed to parse our merge fields
            Handlebars.registerHelper('merge', handlebarsMergeHelper);

            const bulkModeWithOnlyIds = isFilterFromSelectedContacts(vm.filter);

            if (bulkModeWithOnlyIds) {
                vm.individualRecordFilter = angular.copy(vm.filter);
                vm.filter = {
                    rules: [],
                    condition: 'AND'
                };
            }

            const workflowDefault = {
                continuous: false,
                filter: vm.filter,
                individual_record_filter: vm.individualRecordFilter
            };

            const stepConfigDefault = {
                tracked: true,
                includeUnsubscribeLink: true
            };

            vm.workflowConfig = Object.assign({}, workflowDefault, vm.stepConfig);
            vm.stepConfig = Object.assign({}, stepConfigDefault, vm.stepConfig);

            // Never show the unsub url in the editor`
            vm.stepConfig.body = vm.stepConfig.body && vm.stepConfig.body.replace(unsubscribeUrlDetectionRegex, '');

            vm.showAutomaticSwitch = angular.isDefined(vm.showAutomaticSwitch) ? vm.showAutomaticSwitch : (!bulkModeWithOnlyIds && !(vm.filter && vm.filter.search));
            vm.disableEditFilter = vm.disableEditFilter || ((vm.filter || vm.individualRecordFilter) && (vm.filter.search || bulkModeWithOnlyIds));

            vm.showCc = (vm.stepConfig.cc && vm.stepConfig.cc.length > 0);

            if (vm.stepConfig.attachments && vm.stepConfig.attachments.length > 0) {
                totalAttachmentsSize = vm.stepConfig.attachments.reduce(function sum(total, attachment) {

                    return total + attachment.file_size;
                }, 0);
            }

            setTo();

            vm.workflowIndividualContactsText = getIndividualRecordsText();

            $timeout(setFilterValidity);

            return $q.all([
                function getEmailSources() {

                    // TODO: How do we want to pass data sources (From field)
                    // Skip check when we get passed a from.
                    // We assume we are editing and the from is already vetted
                    const skipSMTPCheck = !!vm.stepConfig.from;

                    return datasources.getEmailSources({ includeTeamDataSources: true }).then(function (response) {

                        vm.datasources = prepareDataSourcesForUse(response.data);

                        // Handle array of emails that should be used as the sending email address, if included in the available aliases
                        const dataSourceAliases = new Set(vm.datasources.map(function (dataSource) {

                            return dataSource.sendAs;
                        }));

                        if (angular.isArray(vm.stepConfig.potentialDataSources)) {
                            vm.stepConfig.from = vm.stepConfig.potentialDataSources.find(function (potentialDataSource) {

                                return dataSourceAliases.has(potentialDataSource.email);
                            });
                        }

                        vm.stepConfig.from = vm.stepConfig.from || response.data.find(function (dataSource) {

                            return dataSource.primary;
                        }) || response.data[0];

                        if (angular.isArray(vm.stepConfig.from) && vm.stepConfig.from.length > 0) {
                            vm.stepConfig.from = vm.stepConfig.from[0];
                        }

                        const fromInDataSources = vm.datasources.find(function (ds) {

                            return ds.sendAs === vm.stepConfig.from.email;
                        });

                        if (!fromInDataSources && vm.stepConfig.from) {
                            vm.datasources.unshift(vm.stepConfig.from);
                        }
                        else {
                            vm.stepConfig.from = fromInDataSources;
                        }

                        if (skipSMTPCheck) {
                            return;
                        }

                        return email.showConfigureSMTPSettingsDialogIfNeeded(vm.stepConfig.from);
                    });
                }(),
                function getMergeFields() {

                    return workflowsService.getMergeFields().then(function (response) {

                        const responseData = response.data;

                        const contactMergeFields = responseData.filter(function (mf) {

                            return mf.entity_type === 'contact';
                        });
                        contactMergeFields.unshift({ subheader: 'Contact' });

                        const accountMergeFields = responseData.filter(function (mf) {

                            return mf.entity_type === 'account';
                        });
                        accountMergeFields.unshift({ subheader: 'Account' });

                        vm.mergeFields = [...contactMergeFields, ...accountMergeFields];
                    });
                }()
            ]).then(function () {

                vm.doneLoading = true;
            }).catch(function () {

                vm.doneLoading = true;
            });
        }

        /**
         * List all aliases for each data source (in the order they're returned from the API), grouped per data source, with the primary data source first (and then in order of connecting them)
         * Order the aliases with the default alias first (in Gmail the default one set in Gmail, in Office 365 the main email address we connected with)
         *
         * @param {Array.<Object>} dataSources
         * @returns {Array.<Object>} preparedDataSources
         */
        function prepareDataSourcesForUse(dataSources) {

            let preparedDataSources = [];

            for (const i in dataSources) {
                const dataSource = dataSources[i];
                if (dataSource.aliases.length > 0) {
                    for (const j in dataSource.aliases) {
                        const aliasObject = dataSource.aliases[j];
                        const newDataSource = angular.copy(dataSource);
                        newDataSource.sendAs = aliasObject.email;
                        newDataSource.displayName = aliasObject.display_name;
                        preparedDataSources.push(newDataSource);
                    }
                }
            }

            // Dedupe when email addresses are shown twice
            //    *  1. default alias on one data source over non-default alias on another data source
            //    *  2. if the aliases are both non-default aliases, aliases on the primary data source have preference, or in general aliases higher up in the list specified in the first bullet point
            preparedDataSources = preparedDataSources.filter(function (dataSource, index, array) {

                const dupeDefaultAliasIndex = array.findIndex(function (ds) {

                    return ds.sendAs === dataSource.sendAs && ds.sendAs === ds.email;
                });

                if (dupeDefaultAliasIndex !== -1) {
                    return index === dupeDefaultAliasIndex;
                }

                const dupeAliasIndex = array.findIndex(function (ds) {

                    return ds.sendAs === dataSource.sendAs;
                });

                return index === dupeAliasIndex;
            });

            return preparedDataSources;
        }

        function getFormattedMessage() {

            // Workflow defaults
            const workflowObject = {
                exit_after_days: 30,
                goal: 'replied',
                record_type: recordType,
                steps: []
            };

            workflowObject.name = vm.workflowConfig.name;
            workflowObject.status = vm.workflowConfig.status ? vm.workflowConfig.status : (vm.isScheduledWorkflow ? 'scheduled' : 'live');
            workflowObject.schedule_date = vm.isScheduledWorkflow ? vm.date.setHours(new Date(vm.time).getHours(), new Date(vm.time).getMinutes(), 0, 0) : undefined;
            workflowObject.schedule_days = vm.daysToSendOn;
            workflowObject.schedule_time_start = utils.formatTimestamp(vm.startHour);
            workflowObject.schedule_time_end = utils.formatTimestamp(vm.endHour);
            workflowObject.continuous = vm.workflowConfig.continuous;

            if (vm.filter) {
                workflowObject.filter = angular.copy(vm.filter);
            }

            if (workflowObject.filter && workflowObject.filter.rules) {
                workflowObject.filter.rules = filterService.cleanAdvancedFilterForHttp(workflowObject.filter.rules);
            }

            if (vm.individualRecordFilter) {
                workflowObject.individual_record_filter = angular.copy(vm.individualRecordFilter);
            }

            if (workflowObject.individualRecordFilter && workflowObject.individual_record_filter.rules) {
                workflowObject.individual_record_filter.rules = filterService.cleanAdvancedFilterForHttp(workflowObject.individual_record_filter.rules);
            }

            // Step defaults
            const step = {
                action_type: 'send_email',
                name: 'Send an email', // Default
                order: 1,
                trigger: undefined, // Equals => 'entering workflow'
                trigger_after_days: 0,
                trigger_step_index: undefined
            };

            if (vm.stepConfig.filesToDelete) {
                step.filesToDelete = vm.stepConfig.filesToDelete;
            }

            const stepActionConfig = {
                data_source: vm.stepConfig.from && vm.stepConfig.from.id,
                message: {}
            };

            if (angular.isDefined(stepActionConfig.data_source)) {
                step.action_source = stepActionConfig.data_source;
            }

            if (vm.stepConfig.body !== null) {
                stepActionConfig.message.body = vm.stepConfig.body;
            }

            if (vm.stepConfig.subject) {
                stepActionConfig.message.subject = vm.stepConfig.subject;
            }

            if (vm.stepConfig.attachments) {
                stepActionConfig.message.attachments = vm.stepConfig.attachments.map(function (attachment) {

                    delete attachment.size;

                    return attachment;
                });
            }
            else {
                vm.stepConfig.attachments = [];
            }

            const aliasName = (vm.stepConfig.from && vm.stepConfig.from.displayName) || '';

            stepActionConfig.message.from = {
                email: vm.stepConfig.from && vm.stepConfig.from.sendAs,
                name: aliasName === '' ? model.me.name : aliasName
            };

            stepActionConfig.message.tracked = angular.isDefined(vm.stepConfig.tracked) ? vm.stepConfig.tracked : false;

            if (vm.stepConfig.includeUnsubscribeLink && vm.stepConfig.body && !vm.stepConfig.body.includes('{{ unsubscribe_url }}')) {
                stepActionConfig.message.body += '<br>' + unsubscribeUrl;
            }

            // Assemble the final workflow object
            step.action_config = stepActionConfig;
            workflowObject.steps.push(step);

            return workflowObject;
        }

        function setFilterValidity() {

            // Only set custom message to null if it's not currently needed
            const isInvalid = vm.stepConfig && vm.stepConfig.from && totalAttachmentsSize > maxAttachmentsSizes[vm.stepConfig.from.type];
            if (!isInvalid || !$scope.workflowConfigForm.attachments.$valid) {
                vm.customMessage = null;
            }

            // Setting a filter is not required when saving a draft or when working with continuous workflows
            if (vm.savingDraft || vm.workflowConfig.continuous) {
                return $scope.workflowConfigForm.hiddenTo.$setValidity('required', true);
            }

            if (vm.filter && vm.filter.rules) {
                const containsDisabledRules = vm.filter.rules.some(function (filterRule) {

                    return filterRule.disabled;
                });

                if (containsDisabledRules) {
                    return $scope.workflowConfigForm.hiddenTo.$setValidity('required', false);
                }
            }

            const filterApplied = isFilterApplied(vm.filter) ||  isFilterApplied(vm.individualRecordFilter);

            if (!filterApplied) {
                vm.customMessage = 'You can not save a workflow nor set it live without an audience if it\'s not continuous.';
            }

            $scope.workflowConfigForm.hiddenTo.$setValidity('required', filterApplied);
        }

        function setTo() {

            if (isFilterFromSelectedContacts(vm.filter)) {
                vm.to = vm.filter.rules[0].value.length + ' selected contact' + (vm.filter.rules[0].value.length > 1 ? 's' : '');
            }
            else if (isFilterApplied(vm.filter)) {
                if (!vm.filter.search) {
                    const copiedFilter = angular.copy(vm.filter);
                    delete copiedFilter.type;

                    if (!filterService.isContactFilterApplied(copiedFilter)) {
                        vm.sendingToAllContacts = true;
                    }
                }

                humanReadableAdvancedFilterService.getHumanReadableAdvancedFilter(vm.filter, recordType).then(function (filterText) {

                    // Filter out html tags when using disabled input field
                    if (vm.disableEditFilter && vm.filter.search) {
                        filterText = filterText.replace('<b>Search term</b>', 'Search term');
                    }

                    vm.to = filterText;
                });
            }
            else {
                vm.to = 'Filter contacts';
            }
        }

        function isFilterApplied(filter) {

            if (!filter) {
                return false;
            }

            if (filter.search) {
                return true;
            }

            if (filter.rules) {
                return filter.rules.length > 0;
            }

            return Object.keys(filter).length > 0;
        }


        function getIndividualRecordsText() {

            const recordRule = vm.individualRecordFilter?.rules[0];
            const recordAmount = recordRule?.value.length;
            const recordTypeCapitalized = recordType.charAt(0).toUpperCase() + recordType.slice(1);

            if (!recordAmount || recordAmount === 0) {
                return `Select individual ${recordType}s`;
            }

            return `${recordTypeCapitalized} is one of ${recordAmount} selected ${recordType}s`;
        }

        // eslint-disable-next-line no-shadow
        function showPreview(workplace, contact) {

            let preview = getPreview(vm.stepConfig.body, contact);
            if (!preview) {
                preview = '';
            }

            jodit.container.style['min-height'] = '';
            jodit.container.style.height = 'auto';
            vm.preview = $sce.trustAsHtml(preview);
            viewPreview = true;
            jodit.setReadOnly(true);
            workplace.hide();
        }

        // eslint-disable-next-line no-shadow
        function getPreview(workflowBody, contact) {

            const mergeObject = utils.getMergableContact(contact);
            workflowBody = workflowBody || '';

            if (vm.stepConfig.includeUnsubscribeLink) {
                if (workflowBody) {
                    workflowBody += '<br><br>';
                }

                workflowBody += previewUnsubscribeUrl;
            }

            let workflowSubject = vm.stepConfig.subject;

            // Check for subject
            if (workflowSubject) {
                workflowSubject = workflowSubject.replace(/\| fallback=/g, 'fallback=');
                workflowSubject = workflowSubject.replace(/{{/g, '{{ merge ');
                const subjectTemplate = Handlebars.compile(workflowSubject || '');
                const subjectPreview = subjectTemplate(mergeObject);
                vm.stepConfig.subjectPreview = subjectPreview;
            }

            return mergeFields(workflowBody, mergeObject);
        }

        function mergeFields(text, mergeObject) {

            // Check if text has merge fields
            if (text.includes('{{')) {
                // Remove | otherwise Handlebars will fail.
                text = text.replace(/\| fallback=/g, 'fallback=');
                text = text.replace(/{{/g, '{{ merge ');
                const template = Handlebars.compile(text || '');
                text = template(mergeObject);
            }

            return text;
        }

        function handlebarsMergeHelper(text, options) {

            // If we are handling files we don't need to escape since we want to put in the link tag
            if (angular.isArray(text) && options.data.root.files.length > 0) {
                const isFiles = text.some(function (t) {

                    return options.data.root.files.includes(t);
                });

                if (isFiles) {
                    text = text.join(', ').replace(/, ([^,]*)$/, ' & $1');
                }
            }

            if (options.hash.fallback === 'REPLACE_THIS') {
                options.hash.fallback = '';
            }

            return new Handlebars.SafeString(text || options.hash.fallback);
        }

        function isFilterFromSelectedContacts(filter) {

            return filter && filter.rules.length === 1 && filter.rules[0].id === 'contact.id';
        }

        function getJoditInitializationObject() {

            return {
                queryStringToBindTo: '.editor',
                previewButton: {
                    enabled: true,
                    // eslint-disable-next-line no-shadow
                    execute: function (jodit) {

                        return $scope.$applyAsync(function () {

                            const workplace = angular.element(jodit.workplace);

                            if (viewPreview) {
                                viewPreview = false;
                                jodit.setReadOnly(false);
                                jodit.container.style.height = '100%';
                                return workplace.show();
                            }

                            if (isFilterFromSelectedContacts(vm.filter)) {
                                const contactId = vm.filter.rules[0].value[0];

                                return contact.get(contactId, { expandAccount: true }).then(function (response) {

                                    showPreview(workplace, response.data);
                                });
                            }
                            else {
                                return contact.getContactByFilter(vm.filter, { expandAccount: true }).then(function (response) {

                                    showPreview(workplace, response.data);
                                });
                            }
                        });
                    }
                },
                mergeFieldButton: {
                    enabled: true,
                    mergeFields: vm.mergeFields
                },
                attachmentsButton: {
                    enabled: true,
                    onAttachments: vm.uploadAttachments
                },
                templatesButton: {
                    enabled: true,
                    onSelectTemplate: function (selectedTemplate, preventInsertTemplate) {

                        if (!preventInsertTemplate) {
                            // This code pastes the template into the editor
                            // The code is taken from https://github.com/xdan/jodit/blob/6b522897713fb7d3eecace7af431d93adaf497d3/src/core/selection/select.ts#L631
                            // Some changes are made so it doesn't insert a newline after inserting the template and it puts the cursor at the correct position
                            const node = jodit.createInside.div();
                            const fragment = jodit.createInside.fragment();
                            let lastChild;

                            if (jodit.selection.isFocused() && jodit.isEditorMode()) {
                                jodit.selection.focus();
                            }

                            node.innerHTML = selectedTemplate.html_body;

                            lastChild = node.lastChild;

                            if (!lastChild) {
                                return;
                            }

                            while (node.firstChild) {
                                lastChild = node.firstChild;
                                fragment.append(node.firstChild);
                            }

                            jodit.selection.insertNode(fragment, false);
                            jodit.selection.setCursorIn(lastChild);
                        }

                        if (selectedTemplate.subject && !vm.stepConfig.subject) {
                            vm.stepConfig.subject = selectedTemplate.subject;
                        }
                    }
                },
                sendTestButton: {
                    enabled: true,
                    execute: function () {

                        /*
                         * Renders a temporary form submit button in the DOM.
                         * We click it to submit the form and trigger the 'submit' event handler registered by the formErrorIndicator component to show form validation errors.
                         * We do it this way because making the 'Send email' button a submit button is not possible since Jodit automatically generates an anchor element for custom buttons.
                         * Submitting the form via JS was also not an option since that doesn't trigger the registered event handlers.
                         */
                        const hiddenEmailFormSubmitButton = $document[0].createElement('button');
                        hiddenEmailFormSubmitButton.setAttribute('type', 'submit');
                        hiddenEmailFormSubmitButton.setAttribute('form', $scope.workflowConfigForm.$name);
                        hiddenEmailFormSubmitButton.style.display = 'none';
                        angular.element(jodit.container).find('.jodit-toolbar__box')[0].append(hiddenEmailFormSubmitButton);
                        hiddenEmailFormSubmitButton.click();
                        hiddenEmailFormSubmitButton.remove();

                        return $scope.$applyAsync(function () {

                            const formattedMessage = getFormattedMessage();
                            const stepConfig = formattedMessage.steps[0].action_config.message;
                            stepConfig.data_source = formattedMessage.steps[0].action_config.data_source;

                            if (!$scope.workflowConfigForm.$valid || !stepConfig.body) {
                                return;
                            }

                            // If no id, it means it is a data source from another user so we can't check if smtp is still needed. The server will handle this.
                            if (!stepConfig.from.id) {
                                return sendTest(stepConfig, formattedMessage.filter);
                            }

                            return email.showConfigureSMTPSettingsDialogIfNeeded(vm.message.from).then(function () {

                                return sendTest(stepConfig, formattedMessage.filter);
                            });
                        });
                    }
                }
            };
        }

        function sendTest(stepConfig, filter) {

            return $mdDialog.show($mdDialog.sendTestEmailConfigDialog()).then(function (config) {

                const testEmailData = stepConfig;
                testEmailData.to = config.to;
                testEmailData.mergeInSampleContactData = config.mergeInSampleContactData;

                testEmailData.filter = angular.copy(filter);
                testEmailData.from = testEmailData.from.email;

                if (testEmailData.filter && testEmailData.filter.rules) {
                    testEmailData.filter.rules = filterService.cleanAdvancedFilterForHttp(testEmailData.filter.rules);
                }

                return workflowService.sendTestEmail(testEmailData);
            });
        }
    }
})();
