(function () {
    'use strict';

    angular
        .module('salesflare.components.revenueVsGoal', [])
        .component('sfRevenueVsGoal', {
            templateUrl: 'app-ajs/components/dashboards/predefinedReports/revenueVsGoal/revenueVsGoal.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                data: '<'
            }
        });

    function controller(model) {

        const vm = this;

        vm.model = model;

        ////////////////

        vm.$onChanges = function (changes) {

            if (!changes || !changes.data || !changes.data.currentValue) {
                return;
            }

            const total = vm.data.won_pipeline + vm.data.expected_pipeline > vm.data.target ? vm.data.won_pipeline + vm.data.expected_pipeline : vm.data.target;

            vm.data.wonBarPercentage = (vm.data.won_pipeline / total) * 100;
            vm.data.expectedBarPercentage = (vm.data.expected_pipeline / total) * 100;
            vm.data.targetBarPercentage = 100 - (vm.data.wonBarPercentage + vm.data.expectedBarPercentage);
        };
    }
})();
