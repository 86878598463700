(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('usersettings', usersettingsService);

    function usersettingsService(sfHttp, config) {

        this.get = function () {

            return sfHttp.get(config.apiUrl + 'usersettings');
        };

        this.update = function (data) {

            return sfHttp.put(config.apiUrl + 'usersettings', data);
        };

        this.setLastVisitedMyContactsToNow = function () {

            return sfHttp.post(config.apiUrl + 'usersettings/lastvisited/mycontacts');
        };

        this.setLastVisitedCustomersToNow = function () {

            return sfHttp.post(config.apiUrl + 'usersettings/lastvisited/customers');
        };

        this.setLastVisitedNotificationsToNow = function () {

            return sfHttp.post(config.apiUrl + 'usersettings/lastvisited/notifications');
        };

        this.setLastVisitedSuggestedAccounts = function (passedTime) {

            return sfHttp.post(config.apiUrl + 'usersettings/lastvisited/suggestedaccounts', { passed_time: passedTime });
        };
    }
})();
