import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

import { ToolbarModule } from '@shared/components/layout/toolbar/toolbar.module';

import { NavigationToolbarComponent } from './navigation-toolbar.component';
import { NavigationToolbarButtonDirective } from './navigation-toolbar-button/navigation-toolbar-button.directive';

@NgModule({
    declarations: [
        NavigationToolbarComponent,
        NavigationToolbarButtonDirective
    ],
    exports: [
        NavigationToolbarComponent,
        NavigationToolbarButtonDirective
    ],
    imports: [
        CommonModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        MatToolbarModule,
        ToolbarModule,
        UIRouterUpgradeModule
    ]
})
export class NavigationToolbarModule {}
