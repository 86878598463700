(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('stripeService', stripeService);

    /**
     * We use a service to get the Stripe instance,
     * this prevents calling `$window.Stripe()` multiple times which creates a new iframe each time
     *
     * @param {Object}  $window
     */
    function stripeService($window) {

        this.stripe = undefined;

        this.init = function (key) {

            // Always initiate with the english locale, to receive english errors
            this.stripe = $window.Stripe(key, { locale: 'en' });
        };
    }
})();
