(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('groups', groupsService);

    function groupsService(sfHttp, config) {

        this.get = function () {

            return sfHttp.get(config.apiUrl + 'groups', { cache: true });
        };
    }
})();
