import { OnInit, Component, Inject, ViewChild } from '@angular/core';
import { DialogContent } from '@shared/components/dialog/dialog.component';
import { Subject, BehaviorSubject, debounceTime, filter, forkJoin, mergeMap, of, tap } from 'rxjs';
import { NgModel } from '@angular/forms';

export interface EditTagDialogContentData {
    tag: any
}

@Component({
    selector: 'sfx-manage-tags-edit-tag-content',
    template: `
        <span>
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input required matInput [(ngModel)]="tagName" (ngModelChange)="onTagNameChanged($event)" #tagNameField="ngModel">
                <mat-error *ngIf="tagNameField.control.errors?.unique">
                    A tag with this name already exists.
                </mat-error>
                <mat-error *ngIf="tagNameField.control.errors?.required">
                    This field is required.
                </mat-error>
            </mat-form-field>
        </span>
    `,
    styles: ['.mat-form-field { width: 100% }']
})
export class EditTagDialogContentComponent implements OnInit, DialogContent<EditTagDialogContentData> {

    private loading = false;

    private tag: any = {};

    public tagName = '';
    public tagNameChanged = new Subject<string>();

    public validateState$ = new BehaviorSubject<'valid' | 'invalid' | 'validating'>('invalid');

    public get valid(): boolean {

        return this.loading ? false : !!this.tagNameField?.valid;
    }

    public errors: any = {};

    @ViewChild('tagNameField') tagNameField!: NgModel

    constructor(
        @Inject('tagsService') public tagsService: any
    ) {}

    public ngOnInit(): void {

        this.tagNameChanged
            .pipe(
                tap(() => {
                    this.loading = true;

                    this.validateState$.next('validating');
                }),
                filter((tag) => {

                    if (tag === '') {
                        this.loading = false;

                        this.validateState$.next('invalid');

                        return false;
                    }

                    return true;
                }),
                debounceTime(500),
                mergeMap((tag: string) => {
                    return forkJoin([
                        this.tagsService.get({
                            filterRules: [{
                                condition: 'AND',
                                'rules': [{
                                    id: 'tag.name',
                                    operator: 'equal',
                                    value: [tag]
                                }]
                            }]
                        }),
                        of(tag)
                    ]);
                })
            )
            .subscribe((result: any) => {

                const response = result[0];
                const tag = result[1];

                let uniqueError = null;

                for (const foundTag of response.data) {

                    if ((this.tag.id !== foundTag.id) && (tag.toLowerCase() === foundTag.name.toLowerCase())) {
                        uniqueError = true;
                        break;
                    }
                }

                this.loading = false;
                this._setError('unique', uniqueError);

                this.validateState$.next(uniqueError ? 'invalid' : 'valid');
            });
    }

    public onTagNameChanged(tag: any): void {
        this.tagNameChanged.next(tag);
    }

    private _setError(code: string, value: boolean | null) {

        if (value) {
            this.tagNameField.control.setErrors({ [code]: value });
        }
        else if (this.tagNameField.control.errors) {
            delete this.tagNameField.control.errors[code];
        }
    }

    setInitialState(context: EditTagDialogContentData) {

        this.tag = context.tag;
        this.tagName = context.tag.name;
        this.tagNameField?.control?.updateValueAndValidity();
    }

    getCurrentState(): EditTagDialogContentData {

        return { tag: { ...this.tag, name: this.tagName } };
    }
}
