(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('CustomFieldsSettingsController', CustomFieldsSettingsController);

    function CustomFieldsSettingsController($scope, $state, pipelines, model, customfields, filterService) {

        get();

        $scope.$on('loadPipelines', function () {

            return get();
        });

        $scope.changePipeline = function (pipeline) {

            $scope.currentPipeline = pipeline;
            filterService.setCurrentPipelineId(pipeline.id);

            return customfields.getOpportunityFields(true, $scope.currentPipeline.id).then(function (response) {

                return setCustomFields(response);
            });
        };

        function get() {

            $scope.itemClass = $state.current.url.replace('/', '');

            if ($scope.itemClass !== 'opportunity') {
                return;
            }

            return pipelines.get().then(function (pipelinesResponse) {

                $scope.pipelines = pipelinesResponse.data;
                const currentPipelineId = store.get('current_pipeline_' + model.me.id);

                if (currentPipelineId) {
                    $scope.currentPipeline = $scope.pipelines.find(function (pipeline) {

                        return pipeline.id === currentPipelineId;
                    });
                }

                if (!$scope.currentPipeline) {
                    filterService.setCurrentPipelineId($scope.pipelines[0].id);
                    $scope.currentPipeline = $scope.pipelines[0];
                }

                return customfields.getOpportunityFields(true, $scope.currentPipeline.id).then(function (response) {

                    return setCustomFields(response);
                });
            });
        }

        function setCustomFields(response) {

            $scope.standardFields = response.data.filter(function (field) {

                return field.isStandardField;
            });

            $scope.predefinedFields = response.data.filter(function (field) {

                return field.predefined_customfield;
            }).sort(function (a, b) {

                const aName = a.name.toLowerCase();
                const bName = b.name.toLowerCase();

                if (aName < bName) {
                    return -1;
                }

                if (aName > bName) {
                    return 1;
                }

                return 0;
            });

            $scope.customFields = response.data.filter(function (field) {

                return !field.isStandardField && !field.predefined_customfield;
            });
        }
    }
})();
