(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('permissionService', permissionService);

    function permissionService(sfHttp, config) {

        this.getAllRoles = function () {

            return sfHttp.get(config.apiUrl + 'roles');
        };

        this.getAllAccessScopes = function () {

            return sfHttp.get(config.apiUrl + 'roles/access_scopes');
        };

        this.updateRole = function (id, update) {

            return sfHttp.put(config.apiUrl + 'roles/' + id, update);
        };
    }
})();
