(function () {
    'use strict';

    angular
        .module('salesflare')
        .directive('sfSticky', sfStickyController);

    function sfStickyController($mdSticky) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                $mdSticky(scope, element);
            }
        };
    }
})();
