(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('WorkflowComposeDialogController', WorkflowComposeDialogController);

    function WorkflowComposeDialogController($rootScope, $scope, $stateParams, $mdDialog, $mdMedia, $state, $timeout, sfDiscardDialog, email, workflowsService, utils, fileService) {

        $scope.$mdMedia = $mdMedia;
        $scope.showAutomaticSwitch = $stateParams.showAutomaticSwitch;
        $scope.dialogTitle = $stateParams.dialogTitle;
        $scope.disableEditFilter = $stateParams.disableEditFilter;
        $scope.filter = angular.copy($stateParams.filter);
        $scope.filterText = $stateParams.filterText;

        $scope.savingDraft = false;

        let isEmailFormDirty = false;
        let isWorkflowFormValid = false;
        let workflowCreateObject = {};
        let senderDataSource = {};

        $scope.startWorkflow = function () {

            $scope.savingDraft = false;

            // Execute function on next tick because setting the savingDraft parameter can possibly cause the form validity to change
            return $timeout(function () {

                let stepBody = '';
                if (isWorkflowFormValid && workflowCreateObject.steps[0].action_config.message.body) {
                    stepBody = workflowCreateObject.steps[0].action_config.message.body;
                }

                if (!isWorkflowFormValid || !stepBody) {
                    return;
                }

                deleteFiles(workflowCreateObject);
                const status = $scope.isWorkflowScheduled() ? 'scheduled' : 'live';
                setWorkflowStatus(workflowCreateObject, status);

                return email.showConfigureSMTPSettingsDialogIfNeeded(senderDataSource).then(function () {

                    // Show confirmation dialog
                    const confirmDialogTitle = status === 'scheduled' ? 'Are you sure you want to schedule this email workflow?' : 'Are you sure you want to set this workflow live?';
                    let confirmDialogTextContent = '';
                    if (status === 'scheduled') {
                        if (workflowCreateObject.continuous) {
                            confirmDialogTextContent = 'It will start sending';
                        }
                        else {
                            confirmDialogTextContent = 'It will be sent';
                        }

                        confirmDialogTextContent += ' to all filtered and selected contacts on the schedule date.';
                    }
                    else {
                        confirmDialogTextContent = 'It will be sent to all filtered and selected contacts';
                        if (workflowCreateObject.continuous) {
                            confirmDialogTextContent += ' in the future.';
                        }
                        else {
                            confirmDialogTextContent += '.';
                        }
                    }

                    // Let confirmDialogTextContent = $scope.isWorkflowScheduled() ? 'Are you sure you want to schedule this email workflow for ' : 'Are you sure you want to send this email workflow to ';
                    // confirmDialogTextContent += $scope.filterText ? $scope.filterText.toLowerCase() : 'all selected contacts';
                    // confirmDialogTextContent += '?';

                    const confirm = $mdDialog.confirm({ multiple: true })
                        .clickOutsideToClose(true)
                        .title(confirmDialogTitle)
                        .textContent(confirmDialogTextContent)
                        .ok('Yes')
                        .cancel('No');

                    return $mdDialog.show(confirm).then(function () {

                        return workflowsService.create(workflowCreateObject).then(function (createResponse) {

                            close(true);

                            return utils.showSuccessToast('Your workflow is now sending.', 'VIEW').then(function (response) {

                                if (response === 'VIEW') {
                                    return $state.go('workflow', { id: createResponse.data.id });
                                }
                            });
                        });
                    });
                });
            });
        };

        $scope.saveDraft = function () {

            $scope.savingDraft = true;

            // Execute function on next tick because setting the savingDraft parameter can possibly cause the form validity to change
            return $timeout(function () {

                if (!isWorkflowFormValid) {
                    return;
                }

                deleteFiles(workflowCreateObject);
                setWorkflowStatus(workflowCreateObject, 'draft');

                return workflowsService.create(workflowCreateObject).then(function (createResponse) {

                    close(true);

                    return utils.showSuccessToast('Your draft has been saved.', 'VIEW').then(function (response) {

                        if (response === 'VIEW') {
                            return $state.go('workflow', { id: createResponse.data.id });
                        }
                    });
                });
            });
        };

        $scope.goToWorkflowDetailsView = function () {

            $scope.savingDraft = true;

            // Execute function on next tick because setting the savingDraft parameter can possibly cause the form validity to change
            return $timeout(function () {

                if (!isWorkflowFormValid) {
                    return;
                }

                deleteFiles(workflowCreateObject);
                setWorkflowStatus(workflowCreateObject, 'draft');

                workflowCreateObject.steps.push({
                    action_config: {
                        data_source: workflowCreateObject.steps[0].action_config && workflowCreateObject.steps[0].action_config.data_source,
                        message: {
                            attachments: [],
                            bcc: [],
                            body: undefined,
                            cc: [],
                            from: workflowCreateObject.steps[0].action_config && workflowCreateObject.steps[0].action_config.message && workflowCreateObject.steps[0].action_config.message.from,
                            to: [],
                            tracked: true
                        }
                    },
                    action_type: 'send_email',
                    name: 'Send an email',
                    order: 2,
                    trigger: 'sent',
                    trigger_after_days: null,
                    trigger_step: undefined,
                    trigger_step_index: 1
                });

                return workflowsService.create(workflowCreateObject).then(function (createResponse) {

                    close(false);
                    utils.showSuccessToast('Your draft has been saved.');
                    // We want the workflow details screen to instantly focus the added step
                    return $state.go('workflow', { id: createResponse.data.id, editing: true, focusedStepOrder: 2 });
                });
            });
        };

        $scope.onWorkflowFormDirtyChanged = function ($event) {

            isEmailFormDirty = $event.$dirty;
        };

        $scope.onWorkflowFormValidityChanged = function ($event) {

            isWorkflowFormValid = $event.$valid;
        };

        $scope.onWorkflowDataChanged = function ($event) {

            $scope.filterText = $event.filterText;
            workflowCreateObject = $event.messageObject;
            senderDataSource = $event.senderDataSource;

            if (workflowCreateObject && workflowCreateObject.attachments) {
                $scope.updating = !!workflowCreateObject.attachments.some(function (file) {

                    return !file.id;
                });
            }
            else {
                $scope.updating = false;
            }
        };

        $scope.back = function (reload) {

            if (!isEmailFormDirty && !workflowCreateObject.body) {
                discardUploadedFiles(workflowCreateObject);

                return close(reload);
            }

            return sfDiscardDialog.show(true, 'email workflow').then(function () {

                discardUploadedFiles(workflowCreateObject);

                return close(reload);
            });
        };

        function close(reload) {

            if ($mdMedia('gt-sm')) {
                // Only reload data when we're on the workflows screen
                if ($rootScope.$state.current.name !== 'workflows') {
                    return $mdDialog.hide(false);
                }

                return $mdDialog.hide(reload);
            }

            return $rootScope.back();
        }

        /**
         * Returns a boolean value, telling us if a workflow is scheduled or not
         *
         * @returns {Boolean}
         */
        $scope.isWorkflowScheduled = function () {

            if (!workflowCreateObject.schedule_date) {
                return false;
            }

            if (new Date(workflowCreateObject.schedule_date) > new Date()) {
                return true;
            }

            return false;
        };

        //When closing a workflow draft without saving delete the uploaded files
        function discardUploadedFiles(workflow) {

            if (!workflow.steps) {
                return;
            }

            workflow.steps.forEach(function (step) {
                if (step.action_config.message.attachments) {
                    step.action_config.message.attachments.forEach(function (file) {

                        fileService.deleteFile(file.id);
                    });
                }

            });
        }

        function deleteFiles(workflow) {

            if (!workflow.steps) {
                return;
            }

            workflow.steps.forEach(function (step) {

                if (!step.filesToDelete) {
                    return;
                }

                step.filesToDelete.forEach(function (fileId) {

                    fileService.deleteFile(fileId);
                });

                delete step.filesToDelete;
            });
        }

        /**
         * We assign a status to the workflow object
         * When it was already on scheduled, it may not be overruled by live
         *
         * @param {Object} workflow
         * @param {String} workflow.status
         * @param {String} newStatus
         */
        function setWorkflowStatus(workflow, newStatus) {

            if (angular.isDefined(newStatus)) {
                workflow.status = newStatus;
            }
        }
    }
})();
