(function () {
    'use strict';

    angular
        .module('salesflare.components.setupPanel', [])
        .factory('sfSetupPanel', sfSetupPanel);

    function sfSetupPanel($document, $mdMedia, $mdPanel) {

        let panelRef;

        return {
            show,
            close,
            isActive,
            updateSetupSteps
        };

        function close() {

            if (panelRef) {
                return panelRef.close();
            }
        }

        function show() {

            if (panelRef) {
                return;
            }

            return $mdPanel.open({
                clickOutsideToClose: false,
                propagateContainerEvents: true,
                controller: 'SetupController',
                controllerAs: 'vm',
                templateUrl: 'app-ajs/components/setup/setupPanelTemplate.html',
                panelClass: 'setup-panel',
                attachTo: $document[0].body,
                locals: {},
                position: $mdMedia('gt-sm') ? $mdPanel.newPanelPosition()
                    .absolute()
                    .bottom('16px')
                    .left((angular.element('.mainMenu').width() + 4) + 'px') : undefined,
                animation: $mdMedia('gt-sm') ? $mdPanel.newPanelAnimation()
                    .openFrom('#mainmenu-support-button')
                    .closeTo('#mainmenu-support-button')
                    .withAnimation($mdPanel.animation.SCALE) : undefined,
                onOpenComplete: function (mdPanelRef) {

                    panelRef = mdPanelRef[0];
                },
                onRemoving: function () {

                    panelRef = null;
                }
            });
        }

        function updateSetupSteps() {

            if (panelRef) {
                panelRef.config.scope.vm.getSetupSteps();
            }
        }

        function isActive() {

            return !!panelRef;
        }
    }
})();
