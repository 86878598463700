(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('joditService', joditService);

    function joditService($rootScope, $compile, $document, $window, $mdPanel, $timeout, utils, templatesService) {

        this.getJoditEditor = getJoditEditor;

        ////////////////////////

        /**
         * Gives you a preconfigured instance of a Jodit editor attached to the DOM
         *
         * @param {Object} joditOptions
         * @param {String} joditOptions.queryStringToBindTo
         * @param {String} [joditOptions.placeholder=Type an email]
         * @param {Object} joditOptions.previewButton
         * @param {Boolean} [joditOptions.previewButton.enabled=false]
         * @param {function(Jodit):void} [joditOptions.previewButton.execute]
         * @param {Object} joditOptions.templatesButton
         * @param {Boolean} [joditOptions.templatesButton.enabled=false]
         * @param {Object} joditOptions.attachmentsButton
         * @param {Boolean} [joditOptions.attachmentsButton.enabled=false]
         * @param {function(Array.<File>):void} [joditOptions.attachmentsButton.onAttachments]
         * @param {Object} joditOptions.mergeFieldButton
         * @param {Boolean} [joditOptions.mergeFieldButton.enabled=false]
         * @param {Array<Object>} [joditOptions.mergeFieldButton.mergeFields]
         * @param {Object} joditOptions.sendTestButton
         * @param {Boolean} [joditOptions.sendTestButton.enabled=false]
         * @param {Array<Object>} [joditOptions.sendTestButton.execute]
         * @param {function(String):void} onBodyChange
         * @param {function(('html' | 'editor')):void} onModeChange
         * @returns {Jodit}
         */
        function getJoditEditor(joditOptions, onBodyChange, onModeChange) {

            const joditConfig = {
                autofocus: joditOptions.autofocus ? true : false,
                uploader: {
                    insertImageAsBase64URI: true
                },
                allowResizeY: false,
                toolbarSticky: false,
                showCharsCounter: false,
                showWordsCounter: false,
                showXPathInStatusbar: false,
                useExtraClassesOptions: false,
                askBeforePasteFromWord: false,
                askBeforePasteHTML: false,
                processPasteHTML: true,
                disablePlugins: 'mobile,xpath,stat,search,imageProperties',
                height: '100%',
                buttons: `formatting${joditOptions.attachmentsButton.enabled ? ',|,attachment' : ''},|,link${joditOptions.templatesButton.enabled ? ',|,template' : ''}${joditOptions.mergeFieldButton.enabled ? ',|,mergeField' : ''},|,source${joditOptions.previewButton.enabled ? ',|,preview' : ''}${joditOptions.sendTestButton.enabled ? ',|,sendTest' : ''}`,
                activeButtonsInReadOnly: ['Preview'],
                showTooltip: true,
                useNativeTooltip: true,
                colorPickerDefaultTab: 'color',
                placeholder: joditOptions.placeholder || (joditOptions.templatesButton.enabled ? 'Type an email or use # to insert a template' : 'Type an email'),
                enter: 'DIV',
                spellcheck: true,
                dialog: { // Doesn't seem to work atm
                    resizable: false,
                    draggable: false,
                    buttons: [
                        'dialog.close'
                    ],
                    removeButtons: []
                },
                cleanHTML: {
                    timeout: 300,
                    removeEmptyElements: true,
                    fillEmptyParagraph: true,
                    replaceNBSP: true,
                    cleanOnPaste: false, // Is false as it would otherwise clean pasted html too much
                    replaceOldTags: {
                        i: 'em',
                        b: 'strong'
                    },
                    allowTags: false,
                    denyTags: {
                        style: true,
                        script: true
                    }
                },
                image: {
                    editClass: false,
                    editStyle: false
                },
                link: {
                    formTemplate: () => `
                    <form class="jodit-ui-form" dir="auto">
                        <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle">
                            <div class="jodit-ui-input jodit-ui-block__url jodit-ui-input_focused_true">
                                <span class="jodit-ui-input__label">URL</span>
                                <div class="jodit-ui-input__wrapper">
                                    <input id="url_input" class="jodit-ui-input__input" dir="auto" name="url" type="text"
                                           data-ref="url_input" ref="url_input" required="true" placeholder="https://">
                                </div>
                            </div>
                        </div>
                        <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle" data-ref="content_input_box"
                             ref="content_input_box">
                                <div class="jodit-ui-input jodit-ui-block__content">
                                    <span class="jodit-ui-input__label">Text</span>
                                    <div class="jodit-ui-input__wrapper"><input id="content_input" class="jodit-ui-input__input" dir="auto" name="content"
                                         type="text" data-ref="content_input" ref="content_input" placeholder="">
                                     </div>
                                </div>
                        </div>
                        <div class="jodit-ui-block jodit-ui-block_align_full jodit-ui-block_size_middle">
                            <button class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_unlink jodit-ui-button_variant_default jodit-ui-button_text-icons_true jodit-ui-block__unlink"
                                    type="button" role="button" aria-pressed="false" data-ref="unlink" ref="unlink" style="display: none;">
                                    <span class="jodit-ui-button__icon"></span>
                                    <span class="jodit-ui-button__text">Unlink</span>
                            </button>
                            <button class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_insert jodit-ui-button_variant_primary jodit-ui-button_text-icons_true jodit-ui-block__insert"
                                    type="submit" onclick="fixUrl();" role="button" aria-pressed="false" data-ref="insert" ref="insert">
                                <span class="jodit-ui-button__icon"></span>
                                <span class="jodit-ui-button__text">Insert</span>
                            </button>
                        </div>
                    </form>
                    `,
                    followOnDblClick: false,
                    noFollowCheckbox: false,
                    openInNewTabCheckbox: false,
                    modeClassName: false,
                    processVideoLink: false
                },
                sourceEditorNativeOptions: {
                    showGutter: false,
                    theme: 'ace/theme/chrome',
                    mode: 'ace/mode/html',
                    wrap: true,
                    highlightActiveLine: true
                },
                controls: {
                    textsize: {
                        icon: 'fontsize',
                        command: 'fontsize',
                        exec: function (editor, current, execOptions) {

                            if (!execOptions.control.args) {
                                return false;
                            }

                            return editor.execCommand(execOptions.control.command, false, execOptions.control.args[0]);
                        },
                        list: {
                            '10': 'Small',
                            '13': 'Normal',
                            '18': 'Large',
                            '32': 'Huge'
                        },
                        childTemplate: function (editor, key, value) {
                            return '<span class="jodit_list_element" style="font-size:' + key + 'px; line-height: 1;">' + value + '</span>';
                        },
                        tooltip: 'Format text size'
                    }
                },
                sourceEditorCDNUrlsJS: ['https://cdn.jsdelivr.net/npm/ace-builds@1.5.0/src-min-noconflict/ace.js'],
                beautifyHTMLCDNUrlsJS: ['https://cdn.jsdelivr.net/npm/js-beautify@1.13.0/js/lib/beautify-html.min.js']
            };

            // The paste handler expects there to be at least 1 handler.
            // Since we turn of the only internal one with `cleanOnPaste: false`
            // This means we need to at least create a dummy one here that does nothing but let the handler function properly
            // In our case however we will actually clean the HTML ourselves in this handler
            joditConfig.events = joditConfig.events || {};
            joditConfig.events.processPaste = function (event, html) {

                const purifiedBody = DOMPurify.sanitize(html);
                return purifiedBody;
            };

            joditConfig.events.getIcon = function (name, control, clearName) {

                switch (clearName) {
                    case 'brush':
                        return '<md-icon class="icon-16 md-font material-icons sf-icon-text-format"></md-icon>';
                    case 'eraser':
                        return '<md-icon class="icon-16 md-font material-icons sf-icon-clear-format"></md-icon>';
                }
            };

            if (joditOptions.mergeFieldButton.enabled) {
                if (!joditOptions.mergeFieldButton.mergeFields) {
                    throw new Error('`mergeFields` is required when `addMergeFieldButton === true`');
                }

                // Set up merge field button
                joditConfig.controls.mergeField = {
                    controlName: 'Merge field',
                    name: 'Merge field',
                    tooltip: 'Merge fields',
                    // eslint-disable-next-line unicorn/prefer-object-from-entries
                    list: joditOptions.mergeFieldButton.mergeFields.reduce(function (map, mergeField) {

                        const temp = {};
                        if (mergeField.api_field) {
                            temp[mergeField.api_field] = mergeField.display_name;
                        }
                        else if (mergeField.subheader) {
                            temp['subheader_' + mergeField.subheader] = mergeField.subheader;
                        }

                        return angular.merge({}, map, temp);
                    }, {}),
                    exec: function (jodit, current, execOptions) {

                        if (!execOptions.control.args) {
                            return false;
                        }

                        const apiField = execOptions.control.args[0];

                        return jodit.selection.insertHTML('{{ ' + apiField + ' | fallback="REPLACE_THIS" }}');
                    },
                    childTemplate: function (jodit, key, value) {

                        return '<div disabled="' + key.startsWith('subheader_') + '">' + value + '</div>';
                    }
                };
            }

            if (joditOptions.sendTestButton.enabled) {
                if (!joditOptions.sendTestButton.execute) {
                    throw new Error('`execute` is required when `sendTestButton.enabled === true`');
                }

                // Set up send test button
                joditConfig.controls.sendTest = {
                    controlName: 'Send test',
                    name: 'Send test',
                    tooltip: 'Send test',
                    exec: function () {

                        return joditOptions.sendTestButton.execute();
                    }
                };
            }

            if (joditOptions.previewButton.enabled) {
                // Set up preview button
                joditConfig.controls.preview = {
                    controlName: 'Preview',
                    name: 'Preview',
                    tooltip: 'Preview',
                    exec: function (jodit) {
                        const buttonIcon = angular.element(jodit.container).find('.jodit-toolbar-button_Preview button');
                        if (buttonIcon.text() === 'Preview') {
                            buttonIcon.text('Edit');
                        }
                        else {
                            buttonIcon.text('Preview');
                        }

                        return joditOptions.previewButton.execute(jodit);
                    },
                    isDisabled: function (jodit) {
                        return jodit.getMode() === Jodit.MODE_SOURCE;
                    }
                };
            }

            if (joditOptions.attachmentsButton.enabled) {
                joditConfig.controls.attachment = {
                    controlName: 'Attachment',
                    name: 'Attachment',
                    tooltip: 'Attachment',
                    exec: function (jodit, current, execOptions) {

                        /**
                         * Jodit adds a touchend handler on the container li element of our button
                         * this handler does a .preventDefault which breaks our handling on touch devices.
                         * In other words on touch devices the file picker doesn't show up.
                         *
                         * What we do here is detect whether the button is being clicked via a touch event and if so send out a new event
                         * to trigger the file picker.
                         * We can't do this for mouse clicks as this would trigger the file picker twice.
                         */
                        if (execOptions.originalEvent.type === 'touchend') {
                            execOptions.button.firstElementChild.click();
                        }
                    }
                };
            }

            if (joditOptions.templatesButton.enabled) {
                joditConfig.controls.template = {
                    controlName: 'Template',
                    name: 'Template',
                    tooltip: 'Templates',
                    exec: function (jodit, current, execOptions) {

                        /**
                         * Jodit adds a touchend handler on the container li element of our button
                         * this handler does a .preventDefault which breaks our handling on touch devices.
                         * In other words on touch devices the template panel doesn't show up.
                         *
                         * What we do here is detect whether the button is being clicked via a touch event and if so send out a new event
                         * to trigger the template panel.
                         * We can't do this for mouse clicks as this would trigger the template panel twice.
                         */
                        if (execOptions.originalEvent.type === 'touchend') {
                            execOptions.button.firstElementChild.click();
                        }
                    }
                };
            }

            joditConfig.controls.formatting = {
                controlName: 'Formatting',
                name: 'Formatting',
                tooltip: 'Formatting',
                exec: function (jodit, current, execOptions) {

                    /**
                     * Jodit adds a touchend handler on the container li element of our button
                     * this handler does a .preventDefault which breaks our handling on touch devices.
                     * In other words on touch devices the formatting toolbar doesn't show up.
                     *
                     * What we do here is detect whether the button is being clicked via a touch event and if so send out a new event
                     * to trigger the formatting toolbar.
                     * We can't do this for mouse clicks as this would trigger the formatting toolbar twice.
                     */
                    if (execOptions.originalEvent.type === 'touchend') {
                        execOptions.button.firstElementChild.click();
                    }
                }
            };

            const joditInstance = new Jodit(joditOptions.queryStringToBindTo, joditConfig);
            let aceEditor;

            if (joditOptions.maxHeight) {
                joditInstance.editor.style.maxHeight = joditOptions.maxHeight;
            }

            // Focus editor when clicking on container but not editor
            joditInstance.workplace.addEventListener('click', function (evt) {

                // If we are in source mode select the source editor
                if (joditInstance.getMode() === Jodit.MODE_SOURCE && !$document[0].activeElement.classList.contains('ace_text-input')) {
                    aceEditor = aceEditor || ace.edit($document[0].querySelector('.ace_editor'));

                    aceEditor.focus();
                    return aceEditor.navigateFileEnd();
                }
                else if (evt.target === evt.currentTarget) {
                    joditInstance.editor.focus();

                    // Put caret at the end of the text
                    const range = $document[0].createRange();
                    range.selectNodeContents(joditInstance.editor);
                    range.collapse(false);
                    const sel = $window.getSelection();
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
            });

            // The editor supports dropping images but since our special layout (see supporting files/scrollable/...)
            // It wouldn't support dropping files on the container instead of the smaller editor
            // Here we basically catch the drop on the container and send it to the editor to handle the drop
            joditInstance.container.addEventListener('drop', function (evt) {

                evt.preventDefault();
                evt.stopPropagation();

                const event = new Event('drop');
                event.dataTransfer = evt.dataTransfer;

                joditInstance.container.click();
                joditInstance.editor.dispatchEvent(event);
            });
            // Make sure the browser knows that the container is a drop target in all cases, otherwise the drop event doesn't fire in all cases
            // See https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#droptargets
            joditInstance.container.addEventListener('dragover', function (evt) {

                // Needs a every.call since dataTransfer.items is not a real array but a DataTransferList
                const allFilesAreImages = Array.prototype.every.call(evt.dataTransfer.items, function (i) {

                    return i.type.includes('image');
                });

                // Only stop the event when we want to handle it ourselves
                // ergo when 1 or more images
                if (allFilesAreImages) {
                    evt.preventDefault();
                    evt.stopPropagation();
                }
            });

            // Overwrite the min height every time jodit tries to set it since it messes with our layout
            joditInstance.events.on('setMinHeight',function () {

                joditInstance.container.style.minHeight = '';
                joditInstance.editor.style.minHeight = '';
            });

            // Listen on image events to make sure we don't exceed the max size limit
            joditInstance.events.on('afterInsertImage', function (image) {

                if (joditInstance.getEditorValue().length > (15 * 1000 * 1000)) {
                    image.remove();
                    return utils.showErrorToast('Adding this image will exceed the maximum total email size of 15MB');
                }
            });

            if (onBodyChange) {
                joditInstance.events.on('change', function (bodyText) {

                    return $rootScope.$applyAsync(function () {

                        return onBodyChange(bodyText);
                    });
                });
            }

            joditInstance.events.on('beforeSetValueToEditor', function (html) {

                if (!html) {
                    return;
                }

                const purifiedBody = DOMPurify.sanitize(html);
                return purifiedBody;
            });


            joditInstance.events.on('afterSetMode', function () {

                angular.element(joditInstance.container).find('.jodit-toolbar-button_source button').text((joditInstance.getMode() === Jodit.MODE_SOURCE) ? 'Hide HTML' : 'View HTML');

                if (onModeChange) {
                    return $rootScope.$applyAsync(function () {

                        switch (joditInstance.getMode()) {
                            case Jodit.MODE_SOURCE:

                                return onModeChange('html');
                            case Jodit.MODE_WYSIWYG:

                                return onModeChange('editor');
                        }
                    });
                }
            });

            // Prevent enter plugin without preventing the default behavior
            // If we don't do this then pressing enter won't select a template when doing a # template search but just insert an enter in the jodit editor
            joditInstance.events.on('beforeEnter', function () {

                return true;
            });

            const formattingToolbarButtonTemplate = ''
                + '<button class="jodit-toolbar-button__button" ng-click="vm.showFormattingPanel($event)" type="button">'
                + '     <md-icon md-font-icon="sf-icon-text-format" class="icon-16"></md-icon>'
                + '</button>';
            const formattingToolbarButtonScope = $rootScope.$new();
            formattingToolbarButtonScope.vm = {
                showFormattingPanel: function (event) {

                    // If panel is already opened, close panel on click
                    if (joditInstance.formattingPanelRef) {
                        return joditInstance.formattingPanelRef.close().then(function () {

                            joditInstance.formattingPanelRef.destroy();
                            joditInstance.formattingPanelRef = undefined;
                        });
                    }

                    // Position the panel above the toolbar:
                    // The easiest way is to attach the panel to the document body and then position the panel relative to the toolbar container.
                    // The problem with that solution is that the panel stays open when the editor is removed from the DOM since the scope of document body is never destroyed.
                    // For that reason we attach the panel to the jodit toolbar. The problem now is that the jodit elements are positioned relatively which causes the mdPanel calculations to fail.
                    // We overlap the toolbar with the panel and then set negative top margin to align the panel above the toolbar.
                    const position = $mdPanel.newPanelPosition()
                        .absolute()
                        .bottom(((angular.element(joditInstance.toolbar.container).height() + 2) + 'px')) // The +2px is an offset to make up for the box shadow of the panel
                        .left('8px'); // Align panel more the icon of the first button of the toolbar

                    $mdPanel.open({
                        attachTo: angular.element(joditInstance.container),
                        position,
                        clickOutsideToClose: false,
                        openFrom: event,
                        template:
                            '<div class="formatting-panel-toolbar-container-' + joditInstance.id + ' md-whiteframe-3dp"></div>',
                        propagateContainerEvents: true,
                        onOpenComplete: function (mdPanelRef) {

                            mdPanelRef[0].panelEl[0].style.position = 'absolute';
                            mdPanelRef[0].panelEl[0].style.backgroundColor = 'white';

                            // Keep a reference to the panel so we can close it when necessary
                            joditInstance.formattingPanelRef = mdPanelRef[0];

                            // We suffix the class name with the id of the jodit instance (timestamp of creation) so nothing weird happens when multiple jodit editors are open at once.
                            const formattingPanelToolbarContainerClassName = '.formatting-panel-toolbar-container-' + joditInstance.id;

                            // Build the toolbar and insert it in the formatting panel container.
                            new Jodit.modules.ToolbarCollection(joditInstance).build(['textsize', 'font','|','bold','underline','italic', 'brush','|','table','ul','ol','align', '|', 'eraser']).appendTo(angular.element(formattingPanelToolbarContainerClassName)[0]);
                        }
                    });
                }
            };

            // We need to wait for the next tick to be able to start modifying the dom
            // Since 3.3.8 the plugin system changed and became async https://github.com/xdan/jodit/compare/3.3.7...3.3.8?w=1#diff-70ea7a6a97e1a4e20f3beee88a8df185R66
            $timeout(function () {

                const linkScript = $document[0].createElement('script');
                linkScript.text = `function fixUrl() {

                    var contentInput = document.getElementById('content_input');
                    var urlInput = document.getElementById('url_input');
                    contentInput.value = contentInput.value?contentInput.value:urlInput.value;
                    urlInput.value = urlInput.value.replace(/^(?!https?:\\/\\/)(.*)/, 'https://$1');
                };`;
                $document[0].body.append(linkScript);

                // Use the container as reference for dom manipulation.
                // This is important when having more than 1 instance in the dom
                // We want to make sure we are only touching our current instance
                const container = angular.element(joditInstance.container);

                const formattingToolbarButton = $compile(formattingToolbarButtonTemplate)(formattingToolbarButtonScope);
                container.find('.jodit-toolbar-button_Formatting button').replaceWith(formattingToolbarButton);

                if (joditOptions.attachmentsButton.enabled) {
                    // We create our own compiled button here to get the file upload working out of the box
                    const attachmentToolbarButtonTemplate = ''
                        + '<button class="jodit-toolbar-button__button" ngf-pattern="" ngf-select ngf-change="vm.onAttachments($files)" accept="" ngf-multiple="true" type="button">'
                        + '     <md-icon md-font-icon="sf-icon-attachment" class="icon-16"></md-icon>'
                        + '</button>';
                    const attachmentToolbarButtonScope = $rootScope.$new();
                    attachmentToolbarButtonScope.vm = {
                        onAttachments: joditOptions.attachmentsButton.onAttachments
                    };

                    const attachmentToolbarButton = $compile(attachmentToolbarButtonTemplate)(attachmentToolbarButtonScope);
                    container.find('.jodit-toolbar-button_Attachment button').replaceWith(attachmentToolbarButton);
                }

                if (joditOptions.mergeFieldButton.enabled) {
                    const innerTemplate = `
                        <md-icon style="margin: 0 4px 0 0" md-font-icon="sf-icon-merge-fields" class="icon-16"></md-icon>
                        <span>Merge fields</span>
                    `;
                    // We need to compile the inner template for the md-icon to properly work
                    container.find('.jodit-toolbar-button_Merge_field button').empty().append($compile(innerTemplate)($rootScope.$new()));
                }

                if (joditOptions.templatesButton.enabled) {
                    angular.element(joditInstance.editor).textcomplete([{
                        match: /\B#(\w*)$/,
                        search: function (term, callback) {

                            const templateParams = { limit: 10000 };

                            if (term) {
                                templateParams.search = term;
                            }

                            return templatesService.get(templateParams, { ignoreLoadingBar: true }).then(function (response) {

                                return callback(response.data);
                            });
                        },
                        index: 1,
                        cache: true,
                        template: function (item) {

                            return '<md-list-item style="max-width: 450px; min-height: 48px; height: 48px;" class="md-2-line">'
                                + '    <div class="md-list-item-text">'
                                + '        <div style="font-size: 16px;" class="accent ellipsis">' + item.name + '</div>'
                                + '        <div style="color: #8493a8;" class="ellipsis">' + item.plain_text_body + '</div>'
                                + '    </div>'
                                + '</md-list-item>';
                        },
                        replace: function (item) {

                            joditOptions.templatesButton.onSelectTemplate(item, true);

                            // Since the jquery autocomplete plugin does not trigger a Jodit change event we need to fire it ourselves
                            $timeout(() => {

                                joditInstance.events.fire('change', joditInstance.getEditorValue());
                            }, 0);

                            return item.html_body;
                        }
                    }], {
                        placement: 'bottom',
                        rightEdgeOffset: 16
                    });

                    const templateToolbarButtonTemplate = ''
                        + '<button class="jodit-toolbar-button__button" ng-click="vm.onTemplates($event)" type="button">'
                        + '     <md-icon style="margin: 0 3px 0 0;" md-font-icon="sf-icon-bookmark-outline" class="icon-16"></md-icon>'
                        + '     <span>Templates</span>'
                        + '</button>';
                    const templateToolbarButtonScope = $rootScope.$new();
                    templateToolbarButtonScope.vm = {
                        onTemplates: function () {

                            const position = $mdPanel.newPanelPosition()
                                .relativeTo(container.find('.jodit-toolbar-button_Template'))
                                .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.ABOVE);
                            // Opening the panel and dialogs remove the focus/selection in the Jodit editor
                            // We save it here so we can restore it later to insert the template at the correct position
                            let savedRange;

                            if (joditInstance.selection.isFocused()) {
                                savedRange = joditInstance.selection.sel.getRangeAt(0);
                            }

                            return templatesService.get({ limit: 10000 }, { ignoreLoadingBar: true }).then(function (response) {

                                return $mdPanel.open('templateSelectionPanel', {
                                    attachTo: angular.element($document[0].body),
                                    position,
                                    locals: {
                                        templates: response.data,
                                        onSelectTemplate: function (selectedTemplate) {

                                            if (selectedTemplate) {

                                                // Restore range to insert the template at the cursor location before opening the template panels/dialogs
                                                if (savedRange) {
                                                    joditInstance.selection.sel.removeAllRanges();
                                                    joditInstance.selection.sel.addRange(savedRange);
                                                }

                                                joditOptions.templatesButton.onSelectTemplate(selectedTemplate);
                                            }
                                        }
                                    }
                                });
                            });
                        }
                    };

                    const templateToolbarButton = $compile(templateToolbarButtonTemplate)(templateToolbarButtonScope);
                    container.find('.jodit-toolbar-button_Template button').replaceWith(templateToolbarButton);
                }

                // If we added out custom buttons make sure they have text on the button
                // Jodit does not support mixed icon/text button toolbars so yeah :D
                if (joditOptions.previewButton.enabled) {
                    container.find('.jodit-toolbar-button_Preview button').text('Preview');
                }

                if (joditOptions.sendTestButton.enabled) {
                    container.find('.jodit-toolbar-button_Send_test button').text('Send test');
                }

                container.find('.jodit-toolbar-button_source button').text('View HTML');
            });

            return joditInstance;
        }
    }
})();
