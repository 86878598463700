(function () {
    'use strict';

    angular
        .module('salesflare.components.upgradeDialog', [])
        .factory('sfUpgradeDialog', sfUpgradeDialog);

    function sfUpgradeDialog($mdDialog, $mdMedia, config, model) {

        return {
            show
        };

        function show(flag) {

            if (config.segment && config.segment.key) {
                analytics.track('upgrade-intent', { gaClientId: model.gaClientId }, {
                    integrations: {
                        'Google Analytics': false
                    }
                });
                analytics.track('upgrade-intent-' + flag, { gaClientId: model.gaClientId }, {
                    integrations: {
                        'Google Analytics': false
                    }
                });
            }

            return $mdDialog.show({
                clickOutsideToClose: true,
                escapeToClose: true,
                multiple: true,
                hasBackdrop: true,
                controller: 'UpgradeDialogController',
                templateUrl: 'app-ajs/components/upgradedialog/upgradedialog.html',
                fullscreen: !$mdMedia('gt-sm'),
                locals: {
                    flag
                },
                bindToController: true
            });
        }
    }

})();
