(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('savedFiltersService', savedFiltersService);

    function savedFiltersService(sfHttp, config) {

        this.get = function get(entity, includeTeamFilters) {

            let url = (config.apiUrl + 'savedfilters/' + entity);

            if (includeTeamFilters) {
                url += '?include_team_filters=true';
            }

            return sfHttp.get(url);
        };

        this.create = function create(entity, savedFilter) {

            return sfHttp.post(config.apiUrl + 'savedfilters/' + entity, savedFilter);
        };
    }
})();
