(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ExtendTrialDialogController', ExtendTrialDialogController);

    function ExtendTrialDialogController($scope, $state, $mdDialog, trial, authentication) {

        $scope.extend = function () {

            return trial.extend($scope.reason).then(function () {

                // Refetch to basically prevent the restrict dialog from coming back
                return authentication.fetchMe().then(function () {

                    $mdDialog.hide();
                    return $state.reload();
                }).catch(angular.noop);
            });
        };

        $scope.back = function () {

            // Hide the extend trial dialog
            $mdDialog.hide();

            // Refetch to make the restricted dialog pop up again
            return authentication.fetchMe();
        };
    }
})();
