(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('DeleteImportDialogController', DeleteImportDialogController);

    function DeleteImportDialogController($scope, $mdDialog, $mdMedia) {

        const importableEntityMap = {
            account: 'accounts',
            opportunity: 'opportunities',
            contact: 'contacts'
        };
        const importId = this.importId;

        $scope.deleteUpdatedObject = { deleteUpdated: false };
        $scope.importType = this.importType;
        $scope.entityName = importableEntityMap[this.importType];
        $scope.textMap = {
            account: {
                warning: 'if there are opportunities linked to these accounts, they will also be deleted.'
            }
        };

        $scope.cancel = $mdDialog.cancel;

        $scope.showImportedEntities = function () {

            return $mdDialog.show({
                multiple: true,
                clickOutsideToClose: true,
                templateUrl: 'partials/viewimportedentitiesdialog.html',
                controller: 'ViewImportedEntitiesDialogController',
                locals: {
                    importId,
                    deleteUpdated: $scope.deleteUpdatedObject.deleteUpdated,
                    entityName: $scope.entityName
                },
                bindToController: true,
                fullscreen: !$mdMedia('gt-md')
            });
        };

        $scope.deleteImport = function () {

            return $mdDialog.hide({ delete: true, deleteUpdated: $scope.deleteUpdatedObject.deleteUpdated });
        };
    }
})();
