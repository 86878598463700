(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('contactsService', contactsService);

    function contactsService(sfHttp, config, filterService) {

        this.get = function (search, filters, limit, offset, orderBy, downloadCSV, onlyCustomers, onlyMyContacts, downloadExcel, options) {

            const url = config.apiUrl + 'contacts';

            const request = {
                cache: true,
                downloadCSV,
                downloadExcel,
                params: (filters && filterService.sanitizeContactFilterForHttp(angular.copy(filters))) || {}
            };

            if (search) {
                request.cache = false;
                request.ignoreLoadingBar = true;
                request.params.search = search;
            }

            if (limit) {
                request.params.limit = limit;
            }

            if (offset) {
                request.params.offset = offset;
            }

            if (orderBy) {
                request.params.order_by = orderBy;
            }

            if (onlyCustomers && !search) {
                request.params.type = 'customer';
            }
            else if (onlyMyContacts && !search) {
                request.params.type = 'mycontacts';
            }
            else {
                delete request.params.type;
            }

            if (options && options.returnCountOnly) {
                return sfHttp.head(url, request);
            }

            if (options && options.includeCount) {
                request.headers = { 'x-result-count': 'true' };
            }

            return sfHttp.get(url, request);
        };

        /**
         *
         * @param {Object} options
         * @param {String} [options.search]
         * @param {Array.<Object>} options.filterRules
         * @param {Boolean} [options.putNewFirst=false]
         * @param {Number} [options.limit]
         * @param {Number} [options.offset]
         * @param {String} [options.orderBy]
         * @param {Boolean} [options.downloadCSV=false]
         * @param {Boolean} [options.downloadExcel=false]
         * @param {Boolean} [options.returnCountOnly=false]
         * @param {Boolean} [options.includeCount=false]
         * @param {Boolean} [options.ignoreLoadingBar=false]
         * @returns {Promise}
         */
        this.filterGet = function (options) {

            const filterUrl = config.apiUrl + 'contacts';

            const request = {
                downloadCSV: options.downloadCSV,
                downloadExcel: options.downloadExcel,
                params: {},
                noToast: true,
                noToastForStatusCode: [402, 422]
            };

            if (options.filterRules) {
                request.params.q = {
                    condition: 'AND',
                    rules: filterService.cleanAdvancedFilterForHttp(options.filterRules)
                };

                if (options.putNewFirst) {
                    request.params.order_by = ['not_viewed desc'];
                }
            }

            if (options.search) {
                request.cache = false;
                request.ignoreLoadingBar = true;
                request.params.search = options.search === '' ? undefined : options.search;
            }

            if (options.limit) {
                request.params.limit = options.limit;
            }

            if (options.offset) {
                request.params.offset = options.offset;
            }

            if (options.orderBy) {
                request.params.order_by = request.params.order_by ? [...request.params.order_by, ...options.orderBy] : options.orderBy;
            }

            if (options.ignoreLoadingBar) {
                request.ignoreLoadingBar = true;
            }

            if (options.returnCountOnly) {
                return sfHttp.head(filterUrl, request);
            }

            if (options.includeCount) {
                request.headers = { 'x-result-count': 'true' };
            }

            request.withCredentials = true;

            return sfHttp.get(filterUrl, request);
        };

        this.getLinkedIn = (queryParams) => {

            return sfHttp.get(config.apiUrl + 'linkedin/contacts', {
                cache: false,
                params: queryParams
            });
        };

        this.create = function (contacts, { force, actor } = {}) {

            let url = config.apiUrl + 'contacts';
            const requestConfig = {};

            if (angular.isDefined(force)) {
                url += '?force=' + encodeURIComponent(force);
            }

            if (actor) {
                requestConfig.headers = {
                    'x-salesflare-actor': actor
                };
            }

            return sfHttp.post(url, contacts, requestConfig);
        };
    }
})();
