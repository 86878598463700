import { Component, Inject, OnInit } from '@angular/core';
import { MediaService } from '@core/services/media.service';

@Component({
    selector: 'sfx-billing-usage',
    templateUrl: './billing-usage.component.html',
    styleUrls: ['./billing-usage.component.scss']
})
export class BillingUsageComponent implements OnInit {

    constructor(
        @Inject('modelService') public modelService: any,
        @Inject('stateService') public stateService: any,
        public media: MediaService
    ) {}

    public ngOnInit(): void {}

    get Stripe(): boolean {

        return this.modelService.me.team.subscribed && this.modelService.me.team.payment_type === 'stripe';
    }

    public close = (): any =>  {

        this.stateService.go('settings');
    };
}
