(function () {
    'use strict';

    angular
        .module('salesflare.components.largerprospectfollowup.onboardingQuestions', [])
        .component('sfOnboardingQuestions', {
            templateUrl: 'app-ajs/components/largerprospectfollowup/onboardingQuestions/onboardingQuestions.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                answers: '<',
                onSave: '&'
            }
        });

    function controller($mdDialog, $mdMedia) {

        const vm = this;

        vm.$mdMedia = $mdMedia;

        vm.goalOptions = ['Follow up leads better', 'Collaborate better as a team', 'Send personal emails at scale', 'Track our performance', 'Find new leads'];
        vm.previousCRMOptions = ['Nothing', 'Spreadsheet', 'Salesforce', 'HubSpot', 'Pipedrive', 'Other'];
        vm.potentialUsersOptions = ['1-2', '3-5', '6-10', '11-25', '25+'];

        vm.$onInit = () => {

            vm.answers = vm.answers || {};
        };

        vm.next = () => {

            if (vm.questionsForm.$valid && vm.onSave) {
                return vm.onSave({
                    $event: {
                        answers: vm.answers
                    }
                });
            }
        };
    }
})();
