(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('plansService', plansService);

    function plansService() {

        const plans = {
            growth: {
                id: 3,
                name: 'Growth',
                price: {
                    annually: {
                        pricePerBillingCycle: 348,
                        pricePerMonth: 29
                    },
                    monthly: {
                        pricePerBillingCycle: 35,
                        pricePerMonth: 35
                    }
                },
                featureSummary: 'All CRM features you\'d expect, plus',
                features: [
                    {
                        // Flag: 'enrichContacts',
                        name: 'Automate your CRM data input',
                        description: 'Salesflare will automatically gather contact and company info from emails, email signatures, social profiles, and more. It will also log your meetings and phone calls for you.',
                        image: 'images/features/enrich_contacts.png',
                        plan: 'Growth'
                    },
                    {
                        // Flag: 'customFields',
                        name: 'Track emails, links and website visits',
                        description: 'Track when and how your customers are digitally engaging with you. Invaluable tracking information is stored per customer and is revealed to you as real-time notifications, so you can follow up in an informed way.',
                        image: 'images/features/custom_fields.png',
                        plan: 'Growth'
                    },
                    {
                        // Flag: 'maxPipelines',
                        name: 'Get a LinkedIn sidebar, email sidebar & mobile app',
                        description: 'Salesflare works right in LinkedIn as well as your Gmail or Outlook inbox, and has a mobile app for iOS and Android too. All of this without losing any desktop features.',
                        image: 'images/features/multiple_pipelines.png',
                        plan: 'Growth'
                    },
                    {
                        // Flag: 'crossEntityFiltering',
                        name: 'Send personalized email campaigns',
                        description: 'Send personalized emails from any mailbox connected to Salesflare. Choose until when your email should send. And track the responses.',
                        image: 'images/features/advanced_filtering.png',
                        plan: 'Growth'
                    },
                    {
                        // Flag: 'findEmail',
                        name: '25 email finding credits',
                        description: 'Find emails from within Salesflare and LinkedIn. Additional monthly email finding credits can be bought in Settings > Billing: 1500 for $39/$49, 5000 for $79/$99, or 15000 for $159/$199 (billed annually/monthly).',
                        image: 'images/features/find_email.png',
                        plan: 'Growth'
                    }
                ]
            },
            pro: {
                id: 4,
                name: 'Pro',
                price: {
                    annually: {
                        pricePerBillingCycle: 588,
                        pricePerMonth: 49
                    },
                    monthly: {
                        pricePerBillingCycle: 55,
                        pricePerMonth: 55
                    }
                },
                featureSummary: 'All features on the Growth plan, plus',
                features: [
                    {
                        flag: 'multiStepWorkflows',
                        name: 'Send workflows of multiple emails',
                        description: 'Follow up beyond the first mass or automated email. Send personalized email flows from any mailbox connected to Salesflare. And track the responses.',
                        downgradeDescription: 'You will lose the ability to send workflows with multiple steps. The ones currently active will be paused.',
                        image: 'images/features/email-workflows-multi-step.png',
                        plan: 'Pro'
                    },
                    {
                        flag: 'permissions',
                        name: 'Set user permissions',
                        description: 'Take control of your team’s data. Restrict what your team can see, edit or delete. Give them access to specific pipelines only. Create view-only users. And assign managers to user groups.',
                        downgradeDescription: 'Your permission settings will be reset to the default. All your colleagues will get unrestricted access to all data and pipelines.',
                        image: 'images/features/permissions.png',
                        plan: 'Pro'
                    },
                    {
                        flag: 'customDashboards',
                        name: 'Create custom dashboards ',
                        description: 'Customize your Insights dashboards and learn from your data. Add custom reports, re-order them in the dashboard, and even create new dashboards.',
                        downgradeDescription: 'You won\'t be able to see your custom reports or customize your Insights dashboards anymore. If you decide to upgrade again, you will regain access to your custom reports.',
                        image: 'images/features/custom_insights.jpg',
                        plan: 'Pro'
                    },
                    {
                        // Flag: 'findEmail',
                        name: '500 email finding credits',
                        description: 'Find emails from within Salesflare and LinkedIn. Additional monthly email finding credits can be bought in Settings > Billing: 1500 for $39/$49, 5000 for $79/$99, or 15000 for $159/$199 (billed annually/monthly).',
                        image: 'images/features/find_email.png',
                        plan: 'Pro'
                    }
                ]
            },
            enterprise: {
                id: 5,
                name: 'Enterprise',
                price: {
                    annually: {
                        pricePerBillingCycle: 1188,
                        pricePerMonth: 99
                    }
                },
                featureSummary: 'All features on the Pro plan, plus',
                features: [
                    {
                        // Flag: 'multiStepWorkflows',
                        name: 'Custom training',
                        description: 'Get training that is customized for your team so you can get the most out of Salesflare in no time.',
                        downgradeDescription: 'You can still access our self-service how-to videos and articles from the question mark in the main Salesflare menu.',
                        image: 'images/features/email_campaigns.png',
                        plan: 'Pro'
                    },
                    {
                        // Flag: 'permissions',
                        name: 'Data migration done for you',
                        description: 'We’ll take care of importing your current data into Salesflare for you, so you don\'t have to worry about it.',
                        downgradeDescription: 'You can still take care of any data migration yourself via the \'Import data\' tab in the settings menu.',
                        image: 'images/features/website_tracking.png',
                        plan: 'Pro'
                    },
                    {
                        // Flag: 'customDashboards',
                        name: 'Dedicated account manager',
                        description: 'Get a single point of contact at Salesflare who follows up on your personal needs and questions.',
                        downgradeDescription: 'You can still request general support via Intercom chat or by emailing support@salesflare.com',
                        image: 'images/features/hotness.png',
                        plan: 'Pro'
                    },
                    {
                        // Flag: 'findEmail',
                        name: 'Unlimited email finding credits',
                        description: 'Find emails from within Salesflare and LinkedIn.',
                        image: 'images/features/find_email.png',
                        plan: 'Pro'
                    }
                ]
            }
        };

        const creditPlans = {
            small: {
                name: 'Small',
                size: 'S',
                amount: 1500,
                price: {
                    annually: {
                        pricePerBillingCycle: 468,
                        pricePerMonth: 39
                    },
                    monthly: {
                        pricePerBillingCycle: 49,
                        pricePerMonth: 49
                    }
                }
            },
            medium: {
                name: 'Medium',
                size: 'M',
                amount: 5000,
                price: {
                    annually: {
                        pricePerBillingCycle: 948,
                        pricePerMonth: 79
                    },
                    monthly: {
                        pricePerBillingCycle: 99,
                        pricePerMonth: 99
                    }
                }
            },
            large: {
                name: 'Large',
                size: 'L',
                amount: 15000,
                price: {
                    annually: {
                        pricePerBillingCycle: 1908,
                        pricePerMonth: 159
                    },
                    monthly: {
                        pricePerBillingCycle: 199,
                        pricePerMonth: 199
                    }
                }
            },
            extra_large: {
                name: 'Extra Large',
                size: 'XL',
                amount: 50000,
                price: {
                    annually: {
                        pricePerBillingCycle: 3828,
                        pricePerMonth: 319
                    },
                    monthly: {
                        pricePerBillingCycle: 399,
                        pricePerMonth: 399
                    }
                }
            }
        };

        this.get = function () {

            return plans;
        };

        this.getCreditPlans = function () {

            // Since we remove the extra_large property on the /credits page, we return a copy of the object
            return { ...creditPlans };
        };

        this.getCreditPlanBySize = function (size) {

            for (const plan of Object.values(creditPlans)) {
                if (plan.size === size) {
                    return { ...plan };
                }
            }

            return null;
        };
    }
})();
