import { Component, Inject, OnInit } from '@angular/core';
import { MediaService } from '@core/services/media.service';

@Component({
    selector: 'sfx-regional-settings',
    templateUrl: './regional-settings.component.html',
    styleUrls: ['./regional-settings.component.scss']
})
export class RegionalSettingsComponent implements OnInit {
    currency: any;
    ampmNotation: any;
    firstDayOfWeek: any;
    currencies: any;
    timeZones: any;
    timeZone: any;

    // The options for the select element
    timeNotations: any = [
        {
            id: 0,
            notation: '24-hour clock',
            value: false
        },
        {
            id: 1,
            notation: '12-hour clock (AM-PM)',
            value: true
        }
    ];

    // The options for the select element
    startDays: any = [
        {
            id: 0,
            day: 'Sunday',
            value: 0
        },
        {
            id: 1,
            day: 'Monday',
            value: 1
        },
        {
            id: 2,
            day: 'Saturday',
            value: 6
        }
    ];

    constructor(
        @Inject('modelService') public modelService: any,
        @Inject('currenciesService') public currenciesService: any,
        @Inject('timeZoneService') public timeZoneService: any,
        @Inject('teamService') public teamService: any,
        @Inject('userSettingsService') public userSettingsService: any,
        @Inject('localeService') public localeService: any,
        public media: MediaService
    ) {}

    ngOnInit(): void {
        this.currency = this.modelService.me.team.currency;
        if (this.modelService.me.am_pm_notation) {
            this.ampmNotation = this.timeNotations[1];
        }
        else {
            this.ampmNotation = this.timeNotations[0];
        }

        this.firstDayOfWeek = this.startDays.find((day: any) => day.value === this.modelService.me.first_day_of_week);
        this.localeService.firstDayOfWeek = this.firstDayOfWeek.value;
        this.get();
    }

    public get = ():any => {

        this.currenciesService.get().then((response: any) => {

            this.currencies = response.data;
        });
        return this.timeZoneService.get().then((response: any) => {

            this.timeZones = response.data;
            this.timeZone = this.timeZones.find((timeZone: any) => timeZone.value === this.modelService.me.team.time_zone) || this.timeZones.find((timeZone: any) => timeZone.value === 'UTC');
        });
    }

    public savePersonalSettings = ():any => {
        const timeSettings = {
            am_pm_notation: this.ampmNotation.value,
            first_day_of_week: Number.parseInt(this.firstDayOfWeek.value)
        };

        if (this.ampmNotation && (this.modelService.me.am_pm_notation !== this.ampmNotation.value)) {
            timeSettings.am_pm_notation = this.ampmNotation.value;
            this.modelService.me.am_pm_notation = this.ampmNotation.value;
        }

        if (this.firstDayOfWeek && (this.modelService.me.first_day_of_week !== this.firstDayOfWeek.value)) {
            timeSettings.first_day_of_week = Number.parseInt(this.firstDayOfWeek.value);
            this.modelService.me.first_day_of_week = Number.parseInt(this.firstDayOfWeek.value);
            this.localeService.firstDayOfWeek = Number.parseInt(this.firstDayOfWeek.value);
        }

        // Update UserSettings with the changed values
        return this.userSettingsService.update(timeSettings);
    }

    public saveCurrency = ():any => {
        if (this.modelService.me.team.currency !== this.currency) {
            this.modelService.me.team.currency = this.currency;
            return this.teamService.updateCurrency(this.currency.id);
        }
    }

    public saveTimeZone = ():any => {
        if (this.modelService.me.team.time_zone !== this.timeZone.value) {
            this.modelService.me.team.time_zone = this.timeZone.value;
            return this.teamService.update({ time_zone: this.timeZone.value });
        }
    }

    public compareCurrencies = (currencyOne: any, currencyTwo: any): boolean => {
        return currencyOne && currencyTwo ? currencyOne.id === currencyTwo.id : currencyOne === currencyTwo;
    }
}
