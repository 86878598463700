(function () {
    'use strict';

    angular
        .module('salesflare.components.activityPerRep', [])
        .component('sfActivityPerRep', {
            templateUrl: 'app-ajs/components/dashboards/predefinedReports/activityPerRep/activityPerRep.html',
            controller: angular.noop,
            controllerAs: 'vm',
            bindings: {
                data: '<'
            }
        });
})();
