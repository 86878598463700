(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('tasks', tasksService);

    function tasksService(sfHttp, config) {

        this.get = function get(search, filters, params, options, downloadCSV, downloadExcel) {

            const url = config.apiUrl + 'tasks';

            const request = {
                downloadCSV,
                downloadExcel,
                params: search ? {} : (angular.copy(filters) || {})
            };

            // Create merged filter from regular params and user-applied filters
            request.params = angular.merge({}, params, request.params);

            if (angular.isArray(request.params.account)) {
                request.params.account = request.params.account.map(function (account) {

                    return account.id;
                });
            }

            if (search) {
                request.cache = false;
                request.ignoreLoadingBar = true;
                request.params.search = search === '' ? undefined : search;
            }

            if (options && options.ignoreLoadingBar) {
                request.ignoreLoadingBar = true;
            }

            return sfHttp.get(url, request);
        };

        this.create = function create(task) {

            const url = config.apiUrl + 'tasks';

            return sfHttp.post(url, task);
        };

        this.getSettings = function getSettings() {

            const url = config.apiUrl + 'tasks/settings';

            return sfHttp.get(url);
        };

        this.saveSettings = function saveSettings(settings) {

            const url = config.apiUrl + 'tasks/settings';

            return sfHttp.put(url, settings);
        };

        this.getTypes = function getTypes() {

            const url = config.apiUrl + 'tasks/types';

            return sfHttp.get(url);
        };


        /**
         * Eventhandlers
         *
         * These functions are used by the different task components
         * They pass events between these components, by triggering callbacks
         * that were stored at the initialization of the components
         */
        // addTask
        const onAddCallbacks = [];

        // KEEP add Task SINCE USED TO SIGNAL FROM editTaskController (add task from FAB)

        // Register listeners
        // Call this onInit in components or controllers
        this.onTaskAdded = function onTaskAdded(callback) {

            onAddCallbacks.push(callback);
        };

        // When a task is added, all callbacks of the components that need to know about it get triggered
        this.addTask = function addTask(task) {

            for (const onAddCallback of onAddCallbacks) {
                onAddCallback({ task });
            }
        };

        this.filterGet = function (search, filters, limit, offset, orderBy, downloadCSV, options, downloadExcel) {

            const filterUrl = config.apiUrl + 'tasks';

            const request = {
                downloadCSV,
                downloadExcel,
                params: {
                    q: {
                        condition: 'AND',
                        rules: filters
                    }
                },
                noToast: true,
                noToastForStatusCode: [402, 422]
            };

            if (search) {
                request.cache = false;
                request.ignoreLoadingBar = true;
                request.params.search = search === '' ? undefined : search;
            }

            if (limit) {
                request.params.limit = limit;
            }

            if (offset) {
                request.params.offset = offset;
            }

            if (orderBy) {
                request.params.order_by = orderBy;
            }

            if (options && options.ignoreLoadingBar) {
                request.ignoreLoadingBar = true;
            }

            if (options && options.returnCountOnly) {
                return sfHttp.head(filterUrl, request);
            }

            if (options && options.includeCount) {
                request.headers = { 'x-result-count': 'true' };
            }

            return sfHttp.get(filterUrl, request);
        };
    }
})();
