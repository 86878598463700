import { Component, Output, EventEmitter, ContentChildren, QueryList, Input } from '@angular/core';
import { MediaService } from '@core/services/media.service';

import { NavigationToolbarButtonDirective } from './navigation-toolbar-button/navigation-toolbar-button.directive';

@Component({
    selector: 'sfx-navigation-toolbar',
    templateUrl: './navigation-toolbar.component.html',
    styleUrls: ['./navigation-toolbar.component.scss'],
    host: {
        'class': 'sfx-navigation-toolbar'
    }
})
export class NavigationToolbarComponent {


    toggleState = false;

    @ContentChildren(NavigationToolbarButtonDirective) navButtons!: QueryList<NavigationToolbarButtonDirective>;

    @Output() click = new EventEmitter();

    @Output() closeEvent = new EventEmitter<boolean>();

    @Input() closeFunction: () => void;

    public close(): void {
        this.closeEvent.emit(true);
    }

    constructor(
        public media: MediaService
    ) {}
}
