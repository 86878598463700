/* eslint-disable camelcase */

import {
    AfterViewInit, OnInit,
    Component, Inject,
    ViewChild
} from '@angular/core';

import { NgModel } from '@angular/forms';
import { debounceTime, mergeMap, Observable, of, repeat } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiResponseModel } from '@shared/models/services/api.response.model';

interface VatMessage {
    class: string;
    message: string;
}

interface BillingAddress {

    country: string;
    company_name: string;
    street: string;
    city: string;
    region: string;
    zip: string;
    vat: string;
    notes: any;
    email: string;
}

interface BillingAddressResponse {
    data: BillingAddress & { vat_validated: boolean, vat_validation_token?: string | null };
}

interface VatData {
    validatingNumber: boolean;
    validNumber: boolean;
    vatValidationToken?: string | null;
}

@Component({
    selector: 'sfx-billing-usage-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss']
})
export class BillingUsageInvoiceComponent implements OnInit, AfterViewInit {

    private _teamAlreadyHasBillingAddress = false;

    public firstLoad = true;

    public processing = false;
    public billingAddress: BillingAddress = {
        country: '',
        company_name: '',
        street: '',
        city: '',
        region: '',
        zip: '',
        vat: '',
        notes: '',
        email: ''
    };

    public VAT: VatData = {
        validatingNumber: false,
        validNumber: false
    }

    public vatMessage: VatMessage | null = null;

    @ViewChild('vatInput', { read: NgModel }) vatInput: NgModel;

    constructor(
        @Inject('billingService') public billingService: any,
        @Inject('rootScope') public rootScope: any,
        @Inject('utilsService') private utils: any
    ) {}

    public ngOnInit(): void {

        if (this.rootScope.history[this.rootScope.history.length - 1].fromState.name.includes('billingSettings.')) {
            this.rootScope.history.pop();
        }

        this.billingService.getBillingAddress().then(
            this._handleBillingAddressResponse.bind(this),
            null,
            this._handleBillingAddressResponse.bind(this)
        );
    }

    public ngAfterViewInit(): void {

        this.setVatValidator();
    }

    public setVatValidator(): void {

        this.vatInput.valueChanges?.pipe(
            debounceTime(750),
            mergeMap<any, any>(() => {

                this.VAT.validatingNumber = true;
                this.VAT.validNumber = false;

                this._runValidationCycle();

                if (!this.billingAddress.vat) {
                    this.VAT.validatingNumber = false;
                    this.VAT.validNumber = false;

                    this._runValidationCycle();

                    return of(null);
                }

                const country = this.billingAddress.vat.slice(0, 2);
                const number = this.billingAddress.vat.slice(2);

                return this.billingService.validateVAT(country, number, { noToast: true });
            }),
            //eslint-disable-next-line @typescript-eslint/no-unused-vars
            catchError((err: { status: number }, caught: Observable<any>) => {

                this.VAT.validatingNumber = false;
                this.VAT.validNumber = false;
                this.VAT.vatValidationToken = null;

                if (err.status >= 400 && err.status !== 503) {
                    this.vatInput.control.setErrors({
                        'invalid': true
                    });
                }
                else if (err.status === 503) {
                    this.vatInput.control.setErrors({
                        'unknown': true
                    });
                }

                this._runValidationCycle();

                return of(null);
            }),
            repeat()
        )
            .subscribe({
                next: (response: ApiResponseModel<{ valid: boolean, vat_validation_token: string }>) => {

                    if (response?.data) {
                        this.VAT.validatingNumber = false;
                        this.VAT.validNumber = response.data.valid;
                        this.VAT.vatValidationToken = response.data.vat_validation_token;
                    }

                    this._runValidationCycle();
                },
                error: () => {
                    this.VAT.validatingNumber = false;
                    this.VAT.validNumber = false;

                    this.vatInput.control.setErrors({
                        'unknown': true
                    });

                    this._runValidationCycle();
                }
            });
    }

    public save(): void {

        this.processing = true;

        if (this._teamAlreadyHasBillingAddress) {
            return this.billingService.updateBillingAddress({ ...this.billingAddress, vat_validation_token: this.VAT.vatValidationToken })
                .then(() => {

                    this.processing = false;

                    this.billingService.getBillingAddress().then(this._handleBillingAddressResponse.bind(this));

                    return this.utils.showSuccessToast('Your invoicing details have been updated successfully.');
                })
                .catch(() => {

                    this.processing = false;
                });
        }

        return this.billingService.createBillingAddress({ ...this.billingAddress, vat_validation_token: this.VAT.vatValidationToken })
            .then(() => {

                this.processing = false;

                this.billingService.getBillingAddress().then(this._handleBillingAddressResponse.bind(this));

                return this.utils.showSuccessToast('Your invoicing details have been updated successfully.');
            })
            .catch(() => {

                this.processing = false;
            });
    }

    public _runValidationCycle(): void {

        this.vatMessage = this._getMessage();
    }

    public _getMessage(): VatMessage | null {

        if (this.VAT.validatingNumber) {
            return {
                class: '',
                message: 'Validating VAT number...'
            };
        }

        if (this.VAT.validNumber) {
            return {
                class: 'hint-valid',
                message: 'VAT number is validated.'
            };
        }

        if (!this.billingAddress.vat && this.vatInput.valid) {
            return {
                class: '',
                message: 'If you are VAT registered in Europe, please fill out your VAT number.'
            };
        }

        if (this.vatInput.errors?.pattern) {
            return {
                class: 'hint-error',
                message: 'Please start the VAT number with the 2 letter country code followed by the number.'
            };
        }

        if (this.vatInput.errors?.invalid) {
            return {
                class: 'hint-error',
                message: 'The VAT number provided is invalid. You can still save your invoice info but you will be charged VAT.'
            };
        }

        if (this.vatInput.errors?.unknown) {
            return {
                class: 'hint-warning',
                message: 'We are unable to check the validity of the VAT number at this time. You can still save your invoice info but you will be charged VAT.'
            };
        }

        return null;
    }

    private _handleBillingAddressResponse(response: BillingAddressResponse): void {

        this.firstLoad = false;

        if (response.data) {
            this.billingAddress = {
                country: response.data.country,
                company_name: response.data.company_name,
                street: response.data.street,
                city: response.data.city,
                region: response.data.region,
                zip: response.data.zip,
                vat: response.data.vat,
                notes: response.data.notes,
                email: response.data.email
            };

            this.VAT.validNumber = response.data.vat_validated;
            this.VAT.vatValidationToken = response.data.vat_validation_token;

            this._teamAlreadyHasBillingAddress = true;
        }
    }
}
