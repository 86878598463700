(function () {
    'use strict';

    angular
        .module('salesflare.components.chartBuilder', [])
        .component('sfChartBuilder', {
            templateUrl: 'app-ajs/components/dashboards/chartBuilder/chartBuilder.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                entity: '<',
                filter: '<',
                chartType: '<',
                segmentBy: '<',
                segmentByDatePeriod: '<',
                segmentByLimit: '<',
                viewBy: '<',
                viewByDatePeriod: '<',
                viewByLimit: '<',
                measureBy: '<',
                measureByAggregateFunction: '<',
                filterTimeOn: '<',
                compareToPreviousPeriod: '<',
                includeUnspecifiedViewByData: '<',
                includeUnspecifiedSegmentByData: '<',
                noDashboardTimeFilter: '<',
                groupAndReturnOtherSegmentData: '<',
                stackChart: '<',
                dashboardFilter: '<',
                reportForm: '<',
                onChartConfigChanged: '&'
            }
        });

    function controller($mdMedia, $document, $timeout, reportsService) {

        const vm = this;

        let viewBySegmentByOptions = [];
        let measureByOptions = [];

        vm.$mdMedia = $mdMedia;
        vm.chartTypes = [
            {
                type: 'column',
                icon: 'sf-icon-column-chart'
            },
            {
                type: 'bar',
                icon: 'sf-icon-bar-chart'
            },
            {
                type: 'line',
                icon: 'sf-icon-line-chart'
            },
            {
                type: 'scorecard',
                icon: 'sf-icon-scorecard'
            },
            {
                type: 'pie',
                icon: 'sf-icon-pie-chart'
            },
            {
                type: 'table',
                icon: 'sf-icon-table-chart'
            }
        ];
        vm.chartDimensions = {
            column: {
                segmentBy: 'top',
                measureBy: 'left',
                viewBy: 'bottom'
            },
            bar: {
                segmentBy: 'top',
                measureBy: 'bottom',
                viewBy: 'left'
            },
            line: {
                segmentBy: 'top',
                measureBy: 'left',
                viewBy: 'bottom'
            },
            scorecard: {
                segmentBy: null,
                measureBy: 'bottom',
                viewBy: null
            },
            pie: {
                segmentBy: 'top',
                measureBy: 'bottom',
                viewBy: null
            },
            table: {
                segmentBy: 'top',
                measureBy: 'bottom',
                viewBy: 'left'
            }
        };
        vm.datePeriods = ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'];
        vm.aggregateFunctionLabels = {
            count: 'count',
            sum: 'sum',
            avg: 'average'
        };
        vm.viewByLimits = [5, 7, 10, 15, 20, 50];
        vm.segmentByLimits = [5, 7, 10, 15, 20, 50];

        vm.$onChanges = function (changes) {

            if (changes && changes.chartType) {
                // Requirements of dimensions might have changed and form error might be incorrect
                vm.reportForm.$setPristine();
            }

            if (changes && changes.entity && changes.entity.currentValue) {
                return reportsService.getDimensions(changes.entity.currentValue).then(function (response) {

                    measureByOptions = response.data.filter(function (dimension) {

                        return dimension.allowedDimensions.measureBy;
                    });
                    vm.measureByIds = measureByOptions.map(function (dimension) {

                        return dimension.id;
                    });
                    viewBySegmentByOptions = response.data.filter(function (dimension) {

                        return dimension.allowedDimensions.viewBy && dimension.allowedDimensions.segmentBy;
                    });
                    vm.viewBySegmentByIds = viewBySegmentByOptions.map(function (dimension) {

                        return dimension.id;
                    });

                    // When switching the entity, reset chart data
                    if (changes.entity.previousValue && Object.keys(changes.entity.previousValue).length > 0) {
                        vm.chartData = null;
                        return;
                    }

                    setDimensionOptions();

                    return fetchChartData();
                });
            }

            // No need to fetch data when only `stackChart` changed, this prevents drawing the graph twice
            if (changes && changes.stackChart && Object.keys(changes).length === 1) {
                return;
            }

            setDimensionOptions();

            return fetchChartData();
        };

        function setDimensionOptions() {

            vm.viewByOption = vm.viewBy ? getViewBySegmentByOption(vm.viewBy) : null;
            vm.segmentByOption = vm.segmentBy ? getViewBySegmentByOption(vm.segmentBy) : null;
            vm.measureByOption = vm.measureBy ? getMeasureByOption(vm.measureBy) : null;
        }

        function getViewBySegmentByOption(id) {

            return viewBySegmentByOptions.find(function (option) {

                return option.id === id;
            });
        }

        function getMeasureByOption(id) {

            return measureByOptions.find(function (option) {

                return option.id === id;
            });
        }

        vm.getViewBySegmentByMatches = function (searchText) {

            if (!searchText) {
                return viewBySegmentByOptions;
            }

            return viewBySegmentByOptions.filter(function (viewBySegmentByOption) {

                return viewBySegmentByOption.label.toLowerCase().includes(searchText.toLowerCase())
                    // Don't show matches for already selected view/segment by values
                    && (!vm.segmentBy || vm.segmentBy !== viewBySegmentByOption.id)
                    && (!vm.viewBy || vm.viewBy !== viewBySegmentByOption.id);
            });
        };

        vm.getMeasureByMatches = function (searchText) {

            if (!searchText) {
                return measureByOptions;
            }

            return measureByOptions.filter(function (measureByOption) {

                return measureByOption.label.toLowerCase().includes(searchText.toLowerCase());
            });
        };

        vm.onViewByChanged = function (viewByOption) {

            // We manually set `viewByDatePeriod` to null here when we know we're not viewing by a date dimension anymore.
            // This is needed since angular won't do this on it's own when we remove the selector through the ng-if.

            // We also can manually set `viewByDate` here as we have this info. Doing so combines both changes into the same change event.
            // This prevents issues where we trigger the period change before we set `viewByDate` on the data response.
            // @see {https://github.com/Salesflare/Client/commit/c41926357308d734371fdd6a91e694dc29637ef3}
            if (!viewByOption || viewByOption.type !== 'date') {
                vm.viewByDatePeriod = null;
                vm.viewByDate = false;
            }

            // Remove focus when a match has been selected in the autocomplete
            if (viewByOption) {
                vm.onChartDimensionBlur('viewBy');
            }

            vm.viewBy = viewByOption ? viewByOption.id : null;
            // Make sure validity of fields is updated
            checkChartConfigValidity();
            vm.triggerChartConfigChanged();
        };

        vm.onSegmentByChanged = function (segmentBy) {

            // We manually set `segmentByDatePeriod` to null here when we know we're not segmenting by a date dimension anymore.
            // This is needed since angular won't do this on it's own when we remove the selector through the ng-if.

            // We also can manually set `segmentByDate` here as we have this info. Doing so combines both changes into the same change event.
            // This prevents issues where we trigger the period change before we set `segmentByDate` on the data response.
            // @see {https://github.com/Salesflare/Client/commit/c41926357308d734371fdd6a91e694dc29637ef3}
            if (!segmentBy || segmentBy.type !== 'date') {
                vm.segmentByDatePeriod = null;
                vm.segmentByDate = false;
            }

            vm.segmentBy = segmentBy ? segmentBy.id : null;
            // Make sure validity of fields is updated
            checkChartConfigValidity();
            vm.triggerChartConfigChanged();
        };

        vm.onMeasureByChanged = function (measureBy) {

            // Remove focus when a match has been selected in the autocomplete
            if (measureBy) {
                vm.onChartDimensionBlur('measureBy');
            }

            vm.measureBy = measureBy ? measureBy.id : null;
            // Update the available aggregate functions
            const defaultAggregateFunction = vm.measureByOption ? (vm.measureByOption.aggregateFunctions ? vm.measureByOption.aggregateFunctions[0] : null) : null;
            // Keep selected function only when the default is not `null` or the currently selected one is included in the available functions for the measureByOption
            vm.measureByAggregateFunction = (
                !defaultAggregateFunction ||
                !vm.measureByAggregateFunction ||
                !vm.measureByOption.aggregateFunctions.includes(vm.measureByAggregateFunction)
            ) ? defaultAggregateFunction : vm.measureByAggregateFunction;
            // Make sure validity of fields is updated
            checkChartConfigValidity();
            vm.triggerChartConfigChanged();
        };

        // We rotate the autocomplete by adding a class directly.
        // We tried using ng-class but that didn't update in time, which resulted in the dropdown rendering in the wrong place.
        // Then we were using ng-style but that doesn't allow setting more complex (on children) styles.
        // This version uses a class and still updates on time.
        /**
         * @param { 'measureBy' | 'viewBy' } dimension
         * @param {Object} event
         */
        vm.onChartDimensionFocus = function (dimension, event) {

            if (vm.chartDimensions[vm.chartType][dimension] === 'left') {
                angular.element('#' + dimension + 'AutoComplete')[0].classList.add('rotate');
            }

            const isClearButtonClicked = event && event.originalEvent && event.originalEvent.srcElement && event.originalEvent.srcElement.parentElement &&  event.originalEvent.srcElement.parentElement.localName === 'button';
            if (isClearButtonClicked) {
                vm[dimension + 'SearchText'] = '';

                // Add timeout so autocomplete dropdown gets positioned correctly
                $timeout(function () {

                    $document[0].getElementsByName(dimension + 'AutocompleteField')[0].focus();
                }, 200);
            }
        };

        /**
         * @param { 'measureBy' | 'viewBy' } dimension
         * @param {Object} event
         */
        vm.onChartDimensionBlur = function (dimension, event) {

            const isClearButtonClicked = event && event.originalEvent.relatedTarget && event.originalEvent.relatedTarget.localName === 'button' && event.originalEvent.relatedTarget.parentElement && event.originalEvent.relatedTarget.parentElement.parentElement && event.originalEvent.relatedTarget.parentElement.parentElement.id === dimension + 'AutoComplete';
            if (!isClearButtonClicked) {
                if (vm.chartDimensions[vm.chartType][dimension] === 'left') {
                    angular.element('#' + dimension + 'AutoComplete')[0].classList.remove('rotate');
                }
            }
        };

        vm.setChartType = function (type) {

            vm.chartType = type;

            vm.triggerChartConfigChanged();
        };

        vm.onCompareToPreviousPeriodChanged = function () {

            vm.triggerChartConfigChanged();
        };

        vm.triggerChartConfigChanged = function () {

            if (vm.onChartConfigChanged) {
                vm.onChartConfigChanged({
                    $event: {
                        chartConfig: getChartConfig()
                    }
                });
            }
        };

        ////////////////////////

        function fetchChartData() {

            const isChartConfigValid = checkChartConfigValidity();

            if (isChartConfigValid) {
                const getDataParams = Object.assign({}, getChartConfig(), {
                    from: vm.noDashboardTimeFilter ? new Date(null) : vm.dashboardFilter.from,
                    to: vm.noDashboardTimeFilter ? null : vm.dashboardFilter.to,
                    range: vm.dashboardFilter.range,
                    user: vm.dashboardFilter.user,
                    group: vm.dashboardFilter.group,
                    pipeline: vm.dashboardFilter.pipeline && vm.dashboardFilter.pipeline.id,
                    include_unspecified_view_by_data: vm.includeUnspecifiedViewByData,
                    include_unspecified_segment_by_data: vm.includeUnspecifiedSegmentByData,
                    group_and_return_other_segment_data: vm.groupAndReturnOtherSegmentData,
                    no_dashboard_time_filter: vm.noDashboardTimeFilter
                });

                return reportsService.getData(getDataParams).then(function (response) {

                    vm.chartData = response.data.data;
                    // If there's no actual data in the report, set data to null for the empty state to show for all chart types properly
                    if ((angular.isArray(vm.chartData) && vm.chartData.length === 0) || (angular.isArray(vm.chartData.data) && vm.chartData.data.length === 0)) {
                        vm.chartData = null;
                        vm.chartEmptyStateText = 'No data available';
                    }

                    vm.segmentByDate = response.data.segment_by_date;
                    vm.viewByDate = response.data.view_by_date;
                });
            }

            // Make sure to reset the chartData when we don't fetch new data, to update noData property
            vm.chartData = null;
            vm.chartEmptyStateText = 'Select what to report by to get started';
        }

        /**
         * Returns true if the chart config is valid, false if invalid
         *
         * @returns {Boolean}
         */
        function checkChartConfigValidity() {

            // Make sure all needed properties are here to fetch the data of the report
            const basePropsAvailable = vm.entity
                && vm.filter
                && vm.filterTimeOn
                && vm.chartType;

            // These properties should technically always be there since they are either passed from another component or required in the form with default values
            // Meaning if this happens there's probably something wrong in the code
            if (!basePropsAvailable) {
                return {
                    valid: false
                };
            }

            const measureByAvailable = vm.chartDimensions[vm.chartType].measureBy ? vm.measureBy : true;
            const viewByAvailable = vm.chartDimensions[vm.chartType].viewBy ? (
                vm.viewBy && vm.viewByOption && (
                    vm.viewByOption.type === 'date' ? vm.viewByDatePeriod : true // ViewByDatePeriod is required when our viewBy is a date
                )
            ) : true;
            // Segment by is only required for pie charts
            const segmentByAvailable = vm.chartType === 'pie' ? (
                vm.segmentBy && vm.segmentByOption && (
                    vm.segmentByOption.type === 'date' ? vm.segmentByDatePeriod : true // SegmentByDatePeriod is required when our segmentBy is a date
                )
            ) : (vm.segmentBy && vm.segmentByOption ? (
                // eslint-disable-next-line unicorn/no-nested-ternary
                vm.segmentByOption.type === 'date' ? vm.segmentByDatePeriod : true // SegmentByDatePeriod is required when our segmentBy is a date
            ) : true);

            // Set form validity for the dimension inputs
            vm.reportForm.measureByAutocompleteField.$setValidity('required', !!measureByAvailable);
            vm.reportForm.viewByAutocompleteField.$setValidity('required', !!viewByAvailable);
            vm.reportForm.segmentByAutocompleteField.$setValidity('required', !!segmentByAvailable);

            return !!(basePropsAvailable && measureByAvailable && viewByAvailable && segmentByAvailable);
        }

        function getChartConfig() {

            return {
                entity: vm.entity,
                filter: vm.filter,
                chart_type: vm.chartType,
                segment_by: vm.chartDimensions[vm.chartType].segmentBy ? vm.segmentBy : null,
                segment_by_date_period: vm.chartDimensions[vm.chartType].segmentBy ? (
                    vm.segmentByOption && vm.segmentByOption.type === 'date' ? vm.segmentByDatePeriod : null
                ) : null,
                segment_by_limit: vm.segmentByLimit,
                view_by: vm.chartDimensions[vm.chartType].viewBy ? vm.viewBy : null,
                view_by_date_period: vm.chartDimensions[vm.chartType].viewBy ? (
                    vm.viewByOption && vm.viewByOption.type === 'date' ? vm.viewByDatePeriod : null
                ) : null,
                view_by_limit: vm.viewByLimit,
                measure_by: vm.chartDimensions[vm.chartType].measureBy ? vm.measureBy : null,
                measure_by_aggregate_function: vm.chartDimensions[vm.chartType].measureBy ? vm.measureByAggregateFunction : null,
                filter_time_on: vm.filterTimeOn,
                compare_to_previous_period: vm.chartType === 'scorecard' ? vm.compareToPreviousPeriod : undefined
            };
        }
    }
})();
