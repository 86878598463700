(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('team', teamService);

    function teamService($q, $timeout, sfSetupPanel, sfHttp, config, model) {

        this.create = function (team) {

            return sfHttp.post(config.apiUrl + 'teams', team);
        };

        this.get = function () {

            return sfHttp.get(config.apiUrl + 'team');
        };

        this.update = function (update) {

            return sfHttp.put(config.apiUrl + 'team', update);
        };

        // Maintain parity with opportunities.get()
        this.getOpportunities = function (stage, search, filters, orderBy) {

            const url = config.apiUrl + 'team/opportunities';
            const request = {
                cache: true,
                params: angular.copy(filters) || {}
            };

            // Use if's to not override with undefined/null/false
            if (search) {
                request.cache = false;
                request.ignoreLoadingBar = true;
                request.params.search = search === '' ? undefined : search;
            }

            if (stage) {
                request.params.stage = stage;
            }

            if (orderBy) {
                request.params.order_by = orderBy;
            }

            return sfHttp.get(url, request);
        };

        this.getDataSources = function (search, limit) {

            const url = config.apiUrl + 'team/datasources';
            const request = {
                ignoreLoadingBar: true,
                params: {}
            };

            if (search) {
                request.params.search = search;
            }

            if (limit) {
                request.params.limit = limit;
            }

            return sfHttp.get(url, request);
        };

        this.getRelated = () => {

            return sfHttp.get(`${config.apiUrl}teams/related`, { ignoreLoadingBar: true });
        };

        this.getInvite = function (token) {

            return sfHttp.get(config.apiUrl + 'teams/invites/' + token, { ignoreLoadingBar: true });
        };

        this.addInvite = function (email) {

            return sfHttp.post(config.apiUrl + 'teams/invites', [email]).then(function (response) {

                if (model.me && model.me.team.subscribed) {
                    $timeout(sfSetupPanel.updateSetupSteps, 300);
                }

                return $q.resolve(response);
            });
        };

        this.createJoinTeamRequest = (teamId) => {

            return sfHttp.post(`${config.apiUrl}team/${teamId}/joinrequest`);
        };

        this.updateInvite = function (inviteId, update) {

            return sfHttp.put(config.apiUrl + 'teams/invites/' + inviteId, update);
        };

        this.resendInvite = function (inviteId) {

            return sfHttp.post(config.apiUrl + 'teams/invites/' + inviteId + '/resend');
        };

        this.acceptInvite = (inviteId) => {

            return sfHttp.post(`${config.apiUrl}teams/invites/${inviteId}/accept`);
        };

        this.deleteInvite = function (inviteId) {

            return sfHttp.delete(config.apiUrl + 'teams/invites/' + inviteId);
        };

        this.updateCurrency = function (id) {

            return sfHttp.post(config.apiUrl + 'teams/currency/' + id);
        };

        this.createAdmin = function (userId) {

            return sfHttp.post(config.apiUrl + 'teams/admins', { user: userId });
        };

        this.deleteAdmin = function (userId) {

            return sfHttp.delete(config.apiUrl + 'teams/admins', { data: { user: userId } });
        };
    }
})();
