(function () {
    'use strict';

    angular
        .module('salesflare.components.permissions', [])
        .component('sfPermissions', {
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/permissions/permissions.html',
            controller
        });

    function controller($mdMedia, $mdDialog, $mdSelect, $state, sfUpgradeDialog, permissionService, authentication, utils, pipelines, groups, model) {

        const vm = this;
        let accessScopes;
        let originalRolePermissions;
        let originalPipelinePermissions;
        vm.$mdMedia = $mdMedia;
        vm.isAdmin = model.me.role.name === 'Admin';
        vm.isManager = model.me.role.name === 'Manager';

        vm.showPlanFlags = function () {

            const onTrial = model.me.team.payment_type !== 'free' && !model.me.team.subscribed && model.me.trial_expiry_date;
            return (onTrial || !model.me.plan_flags.permissions);
        };

        vm.$onInit = function () {

            permissionService.getAllAccessScopes().then(function (response) {

                accessScopes = response.data;
            });

            groups.get().then(function (response) {

                vm.groups = response.data.filter(function (groupObject) {

                    return groupObject.id;
                }).map(function (groupObject) {

                    return { id: groupObject.id, name: groupObject.name };
                });
            });

            pipelines.get().then(function (response) {

                vm.pipelines = response.data;
                originalPipelinePermissions = response.data.map(function (pipeline) {

                    return { id: pipeline.id, groups: [...pipeline.groups] };
                });
            });

            return permissionService.getAllRoles().then(function (response) {

                vm.roles = response.data;
                originalRolePermissions = [...vm.roles.map(function (role) {

                    return { id: role.id, role_permissions: role.role_permissions.map(function (permission) {

                        return Object.assign({}, permission);
                    }) };
                })];

                vm.roles.forEach(function (role) {

                    if (!role.users || role.users.length === 0) {
                        role.userText = ('No one currently has the ' + role.name.toLowerCase() + ' role');
                        return;
                    }

                    let userText = 'This is currently ';

                    if (role.name === 'Manager') {
                        userText = ('The ' + role.name.toLowerCase() + 's include ');
                    }

                    role.userText = (userText + role.users.map(function (user) {

                        return ('<b>' + user.name + '</b>');
                    }).join(', ')).replace(/, ([^,]*)$/, ' and $1');
                });
            });
        };

        vm.updateRolePermissions = function (roleId, rolePermissions, updateAction) {

            if (!model.me.plan_flags.permissions) {
                // Set the changed value back to it's default value in the md-select
                const storedPermissions = originalRolePermissions.find(function (rolePermission) {

                    return rolePermission.id === roleId;
                });
                const originalValue = storedPermissions.role_permissions.find(function (storedPermission) {

                    return storedPermission.action === updateAction;
                });
                const newValue = rolePermissions.find(function (storedPermission) {

                    return storedPermission.action === updateAction;
                });
                newValue.scope = originalValue.scope;

                return $mdSelect.hide().then(function () {

                    return sfUpgradeDialog.show('permissions');
                });
            }

            const cedRolePermission = rolePermissions.find(function (rolePermission) {

                return rolePermission.action === 'CED';
            });
            const viewRolePermission = rolePermissions.find(function (rolePermission) {

                return rolePermission.action === 'VIEW';
            });
            const cedAccessScope = accessScopes.find(function (accessScope) {

                return accessScope.id === cedRolePermission.scope;
            });
            const viewAccessScope = accessScopes.find(function (accessScope) {

                return accessScope.id === viewRolePermission.scope;
            });
            const isViewScopeTooRestrictive = (viewAccessScope.restrictiveness > cedAccessScope.restrictiveness);

            if (isViewScopeTooRestrictive) {
                if (updateAction === 'VIEW') {
                    cedRolePermission.scope = viewRolePermission.scope;
                }
                else if (updateAction === 'CED') {
                    viewRolePermission.scope = cedRolePermission.scope;
                }
            }

            const update = {
                role_permissions: rolePermissions.map(function (rolePermission) {

                    return {
                        action: rolePermission.action,
                        scope: rolePermission.scope
                    };
                })
            };

            return permissionService.updateRole(roleId, update).then(function () {

                // Fetch me again to update new permissions on model.me
                authentication.fetchMe();

                return utils.showSuccessToast('Role permissions updated.');
            });
        };

        vm.updatePipelineGroupAccess = function (pipeline) {

            if (!['Admin', 'Manager'].includes(model.me.role.name)) {
                return;
            }

            if (!vm.pipelinePermissions[pipeline.id].$valid) {
                return;
            }

            const originalPipeline = originalPipelinePermissions.find(function (pipelinePermissions) {

                return pipelinePermissions.id === pipeline.id;
            });

            if (!model.me.plan_flags.permissions) {
                // Set the changed value back to its default value in the md-select
                pipeline.groups = angular.copy(originalPipeline.groups);

                return $mdSelect.hide().then(function () {

                    return sfUpgradeDialog.show('permissions');
                });
            }

            const pipelineCopy = angular.copy(pipeline);

            if (model.me.group) {
                const updatedPipelineGroupsContainOwnGroup = !!(pipeline.groups.some(function (group) {

                    return group.id === model.me.group;
                }));
                const originalPipelineGroupsContainOwnGroup = !!(originalPipeline.groups.some(function (group) {

                    return group.id === model.me.group;
                }));

                if (originalPipelineGroupsContainOwnGroup && !updatedPipelineGroupsContainOwnGroup) {
                    const confirmGroupRemoval = $mdDialog.confirm({ multiple: true })
                        .clickOutsideToClose(true)
                        .escapeToClose(true)
                        .title('Are you sure?')
                        .htmlContent('If you remove access for your own group, you will not be able to see this pipeline anymore.')
                        .ok('I\'m sure')
                        .cancel('cancel');

                    return $mdSelect.hide().then(function () {

                        return $mdDialog.show(confirmGroupRemoval).then(function () {

                            pipelineCopy.groups = pipeline.groups.map(function (groupObject) {

                                return groupObject.id;
                            });

                            return pipelines.update(pipelineCopy).then(function () {

                                originalPipeline.groups = angular.copy(pipeline.groups);
                            });
                        }).catch(function () {

                            pipeline.groups = angular.copy(originalPipeline.groups);
                        });
                    });
                }
            }

            pipelineCopy.groups = pipeline.groups.map(function (groupObject) {

                return groupObject.id;
            });

            return pipelines.update(pipelineCopy).then(function () {

                originalPipeline.groups = pipeline.groups;
            });
        };

        vm.getAccessScopes = function (rolePermissions, action) {

            // Initialize first
            if (!accessScopes) {
                return;
            }

            if (action === 'CED') {
                return accessScopes;
            }

            return accessScopes.filter(function (accessScope) {

                return accessScope.id !== 'NONE';
            });
        };

        vm.back = function () {

            $state.go('settings');
        };

        vm.openUpgradeDialog = function () {

            return sfUpgradeDialog.show('permissions');
        };
    }
})();
