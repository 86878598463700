import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { MediaService } from '@core/services/media.service';

import { NotificationService } from '@core/services/notifications.service';

import { ApiResponseModel as ApiResponse } from '@shared/models/services/api.response.model';
import { MatDialog } from '@angular/material/dialog';
import { SfxDialog } from '@shared/components/dialog/dialog.component';
import {
    OutlookAddInGuideDialogContentComponent
} from '@feature/settings/components/applications-integrations-settings/dialogs/outlook-add-in-guide-dialog-content.component';

@Component({
    selector: 'sfx-applications-integrations-settings',
    templateUrl: './applications-integrations-settings.component.html',
    styleUrls: ['./applications-integrations-settings.component.scss']
})
export class ApplicationsIntegrationsSettingsComponent implements OnInit {

    private initial = true;
    public calendarConnected = false;
    public chromePluginInstalled = false;

    public zaps: any[] = [];
    public currentUser: any = null;
    public zapierAppDirectoryElementId = 'zapier-app-directory';

    constructor(
        @Inject('calendarService') public calendarService: any,
        @Inject('calendarSyncProgressService') public calendarSyncProgressService: any,

        @Inject('config') public config: any,
        @Inject('dialogService') private dialogService: any,
        @Inject('modelService') public modelService: any,

        @Inject('utilsService') public utilsService: any,
        @Inject('windowService') public windowService: any,

        public media: MediaService,
        public notificationService: NotificationService,
        private matDialog: MatDialog,
        private _renderer2: Renderer2,
        @Inject(DOCUMENT) private _document: Document
    ) {

        this.currentUser = this.modelService.me;
    }

    public ngOnInit(): void {

        this.getCalendars();

        this.initializeZapierAppDirectory();
    }

    public go(url: string): void {

        this.windowService.open(url, '_blank', 'noopener');
    }

    public showScript(): Promise<any> {

        return this.dialogService.show({
            clickOutsideToClose: true,
            controller: 'TrackingScriptDialogController',
            templateUrl: 'partials/trackingscriptdialog.html',
            fullscreen: !this.media.isActive('gt-sm')
        });
    }

    public showGuide(): Promise<any> {

        return this.dialogService.show({
            clickOutsideToClose: true,
            controller: 'EmailTrackingDialogController',
            templateUrl: 'partials/emailtrackingdialog.html',
            fullscreen: !this.media.isActive('gt-sm')
        });
    }

    public showOutlookAddInGuide(): void {

        this.matDialog.open(SfxDialog, {
            panelClass: 'sfx-dialog-container',
            data: {
                title: 'Install the Salesflare Outlook add-in for Microsoft, Mac and web',
                theme: 'blue',
                content: OutlookAddInGuideDialogContentComponent
            }
        });
    }

    public initializeZapierAppDirectory(): void {

        const script = this._renderer2.createElement('script');
        script.src = `https://zapier.com/partner/embed/app-directory/wrapper.js?cache-buster-param=${Date.now()}&id=${this.zapierAppDirectoryElementId}&app=salesflare&theme=light&applimit=0&zaplimit=100&zapstyle=row&introcopy=hide`;
        script.type = 'module';
        this._renderer2.appendChild(this._document.body, script);
    }

    public installChromePlugin(): void {

        const link = 'https://chrome.google.com/webstore/detail/iodbiefeiiififbdcdckdbigakehaemb';

        this.chromePluginInstalled = true;
        return this.go(link);
    }

    public connectCalendar(): Promise<any> {

        return this.utilsService.popup(`${this.config.apiUrl}datasources/calendar/connect?id=${this.modelService.me.id}`, this.windowService.isMobile ? '_system' : '').then((queryParams: any) => {

            if (queryParams && queryParams.get('success') !== 'true') {
                if (queryParams.get('error') === 'UNAUTHORIZED_SCOPES') {

                    const insufficientPermissionsDialog = this.dialogService.confirm({ multiple: true })
                        .clickOutsideToClose(true)
                        .escapeToClose(true)
                        .title('Please enable all permissions')
                        .textContent('Salesflare needs all the requested permissions to be able to automate your CRM work.')
                        .ok('Enable')
                        .cancel('cancel');

                    return this.dialogService.show(insufficientPermissionsDialog)
                        .then(() => {

                            this.connectCalendar();
                        });
                }
            }

            this.getCalendars.bind(this);
        });
    }

    public getCalendars(): Promise<any> {

        return this.calendarService.getAccounts().then((response: ApiResponse<any[]>) => {

            if (!this.initial && response.data.length > 0) {

                this.calendarConnected = true;

                return this.calendarSyncProgressService.startPolling();
            }

            this.initial = false;
        });
    }
}
