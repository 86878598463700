import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    private _baseConfig: MatSnackBarConfig = {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 3000,
        panelClass: []
    };

    constructor(
        private _snackBar: MatSnackBar
    ) {}

    private _getConfigForState(state: 'success' | 'error' | 'info'): MatSnackBarConfig {
        return { ...this._baseConfig, panelClass: [state, 'sfx-snackbar'] };
    }

    public success(message: string, action = '') {

        this._snackBar.open(message, action, this._getConfigForState('success'));
    }

    public info(message: string, action = '') {

        this._snackBar.open(message, action, this._getConfigForState('info'));
    }

    public error(message: string, action = '') {

        this._snackBar.open(message, action, this._getConfigForState('error'));
    }
}
