(function () {
    'use strict';

    angular
        .module('salesflare.components.contextualHelp', [])
        .factory('sfContextualHelp', sfContextualHelp);

    function sfContextualHelp($mdDialog, help) {

        let helpData = null;
        let active = false;

        init();

        return {
            isActive,
            show
        };

        function show() {

            active = true;

            return $mdDialog.show({
                clickOutsideToClose: true,
                escapeToClose: true,
                skipHide: true,
                hasBackdrop: false,
                controller: 'ContextualHelpDialogController',
                templateUrl: 'app-ajs/components/contextualhelp/contextualhelpdialog.html',
                locals: {
                    help: helpData
                },
                bindToController: true,
                fullscreen: true
            }).finally(function () {

                active = false;
            });
        }

        function isActive() {

            return active;
        }

        function init() {

            return help.get().then(function (result) {

                helpData = result.data;
            });
        }
    }
})();
