const Sentry = require('@sentry/browser');

(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('authentication', authenticationService);

    function authenticationService($injector, sfHttp, $timeout, $window, $state, $q, $document, $mdDateLocale, $filter, $exceptionHandler, $mdDialog, $cookies, config, model, utils, notifications, syncprogress, calendarsyncprogress, importprogress, trial, statusBarService, sfRestrictedDialog, flagsService, filterService, emailMigrationService, billing, sfSetupPanel, trackingService) {

        const self = this;

        this.logIn = function (email, password, captchaToken, authenticationCode, recoveryCode) {

            const payload = {
                email,
                password,
                registrationId: notifications.mobileToken,
                token: captchaToken
            };

            if (authenticationCode) {
                payload.two_fa_token = authenticationCode;
            }

            if (recoveryCode) {
                payload.two_fa_recovery_code = recoveryCode;
            }

            return sfHttp.post(config.apiUrl + 'users/login', payload, { noToast: true });
        };

        this.logOut = function () {

            function logoutCallback() {

                return self.localLogOut();
            }

            return sfHttp.post(config.apiUrl + 'users/logout').then(logoutCallback, logoutCallback);
        };

        this.localLogOut = function () {

            if (config.intercom) {
                Intercom('shutdown');
                // Boot so Intercom is available again.
                Intercom('boot', {
                    app_id: config.intercom.appId,
                    custom_launcher_selector: '#intercom-chat-button',
                    hide_default_launcher: true // We only ever want to open Intercom via our custom launcher, this prevents the bubble from showing for second and then hiding
                });
            }

            return $q.all([
                function logOutMobileGoogle() {

                    if ($window.isMobile) {
                        return $window.plugins.googleplus.logout();
                    }
                }(),
                model.clear(),
                notifications.end(),
                statusBarService.clear(),
                syncprogress.stopPolling(),
                calendarsyncprogress.stopPolling(),
                function stopBackgroundService() {

                    if (device.platform === 'Android') {
                        cordova.plugins.Salesflare.stop(angular.noop, function (err) {

                            return $exceptionHandler(err);
                        });
                    }
                },
                sfSetupPanel.close()
            ]).then(function () {

                self.notifyPlugins();

                return $q.resolve();
            });
        };

        this.notifyPlugins = function () {

            // The other plugins use the client in an iframe to add tracking and there it checks if the user is logged in
            if (config.mode === 'outlook-web') {
                const message = {
                    function: 'user',
                    data: null
                };
                $window.parent.postMessage(message, '*');
            }
        };

        this.fetchMe = function (_options) {

            return fetch(angular.merge({}, { ignoreLoadingBar: true }, _options))
                .then(function () {

                    if (!model.isLoggedIn) {
                        return;
                    }

                    if (config.google && config.google.appTrackingId) {
                        // Initialize analytics, default filters and notifications (anything that depend on the logged in user)
                        ga('set', 'dimension1', model.me.team.name);
                        ga('set', 'dimension2', model.me.id);
                        ga('set', 'userId', model.me.id);
                    }

                    if (config.intercom) {
                        Intercom('update', {
                            user_id: model.me.id,
                            user_hash: model.me.intercom_hash
                        });

                        // Only set the cookie in production, since the user_hash on the website will always be the same as the one on production
                        if (config.env === 'production') {
                            // Set a cookie so that it can be used on the website
                            const expirationDate = new Date();
                            expirationDate.setFullYear(expirationDate.getFullYear() + 1);

                            const cookieData = {
                                user_hash: model.me.intercom_hash,
                                user_id: model.me.id
                            };

                            $cookies.put('salesflare-intercom-hash', angular.toJson(cookieData), { domain: '.salesflare.com', path: '/', expires: expirationDate });
                        }
                    }

                    if (config.segment && config.segment.key) {
                        const traits = {
                            name: model.me.name,
                            avatar: model.me.picture,
                            firstName: model.me.firstname,
                            lastName: model.me.lastname,
                            email: model.me.email,
                            isAdmin: model.me.is_admin,
                            teamId: model.me.team.id,
                            team: model.me.team.name,
                            subscribed: model.me.team.subscribed,
                            createdAt: new Date(model.me.creation_date).getTime() / 1000,
                            plan: utils.getPlanNameBySalesflarePlanId(model.me.team.plan),
                            company: {
                                id: model.me.team.id,
                                name: model.me.team.name,
                                createdAt: model.me.team.creation_date,
                                website: model.me.domain,
                                subscribed: model.me.team.subscribed,
                                sumo: !!(model.me.team.discount_code && model.me.team.discount_code.startsWith('SUMO')),
                                free_team: model.me.team.payment_type === 'free',
                                company_plan: utils.getPlanNameBySalesflarePlanId(model.me.team.plan)
                            }
                        };
                        const options = {
                            context: {
                                screen: {
                                    height: $window.screen.height,
                                    width: $window.screen.width,
                                    density: $window.devicePixelRatio
                                }
                            },
                            Intercom: {
                                user_hash: model.me.intercom_hash
                            }
                        };

                        analytics.identify(model.me.id, traits, options);
                        // Since the LI sidebar reloads each time you navigate on LI we don't want to track an authenticated event each time, that would pollute our Intercom event timeline on users
                        if (config.mode !== 'linkedin') {
                            analytics.track('authenticated', { gaClientId: model.gaClientId });
                        }

                        profitwell('start', {
                            'user_email': model.me.email,
                            'user_id': model.me.team.stripe_customer_id
                        });

                        Sentry.configureScope(function (scope) {

                            traits.id = model.me.id;

                            scope.setUser(traits);
                        });
                    }

                    // Defer to make startup a bit faster
                    $timeout(function () {

                        const flare = new Flare({ apiUrl: config.apiUrl, trackingToken: model.me.team.tracking_token });

                        return flare.identifyById(model.me.id, function (err) {

                            if (err) {
                                $exceptionHandler(err);
                            }
                        });
                    });

                    // If admin check if sca is needed
                    // We defer this since it does a call to Stripe and we don't need this instant on app load so we won't block app load with it
                    if (model.me.is_admin && model.me.team.payment_type === 'stripe') {
                        $timeout(function () {

                            const ignoreLoadingBar = _options && _options.ignoreLoadingBar ? true : false;

                            return billing.SCANeeded({ ignoreLoadingBar }).then(function (response) {

                                if (response.data && response.data.client_secret) {
                                    statusBarService.show({
                                        type: 'sca'
                                    });
                                }
                                else {
                                    statusBarService.hide('sca');

                                    if (model.me.is_delinquent && model.me.is_admin) {
                                        statusBarService.show({
                                            type: 'billing',
                                            text: 'Payment failed.',
                                            class: 'error',
                                            linkText: 'Please check your credit card.',
                                            linkTrackId: 'statusBar-billing-settings',
                                            onLinkClick: function () {

                                                return $state.go('settings.billingSettings.overview');
                                            }
                                        });
                                    }
                                }
                            });
                        }, 3000);
                    }

                    filterService.reset();

                    notifications.setAmountOfNotViewedNotifications();

                    let showPromoBar = !model.me.team.discount_code && angular.isDefined(model.me.team.promo_description) && (new Date(model.me.team.promo_redeem_by) > new Date());

                    if (showPromoBar && store.get('last_promo_statusbar_close_date')) {
                        const showAgainDate = new Date(store.get('last_promo_statusbar_close_date'));
                        showAgainDate.setDate(showAgainDate.getDate() + 7);
                        showPromoBar = showPromoBar && (showAgainDate < new Date());

                        if (showPromoBar) {
                            store.remove('last_promo_statusbar_close_date');
                        }
                    }

                    if (showPromoBar) {
                        statusBarService.show({ type: 'pro_discount', text: 'Upgrade to Pro before ' + $filter('date')(model.me.team.promo_redeem_by, 'MMM d') + utils.getDayOrdinal((new Date(model.me.team.promo_redeem_by)).getDate()) +  ' to get ' + model.me.team.promo_description + '.' });
                    }
                    else {
                        statusBarService.hide('pro_discount');
                    }

                    if (model.me.needs_password) {
                        statusBarService.show({ type: 'password', text: 'To login in the future, ' });
                    }
                    else {
                        statusBarService.hide('password');
                    }

                    if (model.me.sync_status !== 'done') {
                        $timeout(function () {

                            syncprogress.startPolling();
                        }, 10000);
                    }

                    if (model.me.calendar_sync_status && model.me.calendar_sync_status !== 'done' && !model.me.first_calendar_sync_done) {
                        calendarsyncprogress.startPolling();
                    }

                    if (model.me.is_importing) {
                        importprogress.startPolling();
                    }

                    if (model.me.data_sources.length === 0) {
                        const lastClosed = store.get('statusbar_email_nudge_last_closed');

                        // If not shown yet or at least 7 days after last close, show the nudge
                        if (!lastClosed || moment().diff(moment(lastClosed), 'days') >= 7) {
                            statusBarService.show({ type: 'email_connect_nudge', text: 'Connect your emails to start automating Salesflare.' });
                        }
                    }
                    else {
                        statusBarService.hide('email_connect_nudge');
                    }

                    if (model.me.has_recently_failed_workflows) {
                        const text = `${model.me.amount_of_failed_workflows === 1 ? 'One' : model.me.amount_of_failed_workflows} of your workflows encountered an error.`;
                        statusBarService.show({ type: 'workflow_failed', text });
                    }
                    else {
                        statusBarService.hide('workflow_failed');
                    }

                    // Show remaining time of trial if needed
                    // we pass fetchMe to prevent circular dep
                    trial.check(self.fetchMe);

                    emailMigrationService.check();

                    if (config.refiner && config.refiner.key) {
                        _refiner('setProject', config.refiner.key);
                        _refiner('identifyUser', {
                            id: model.me.id.toString(),
                            email: model.me.email,
                            name: model.me.name,
                            first_name: model.me.firstname,
                            last_name: model.me.lastname,
                            account: {
                                id: model.me.team.id.toString(),
                                name: model.me.team.name,
                                trial_expiry_at: new Date(model.me.trial_expiry_date).toISOString(),
                                campaign: model.me.team.campaign,
                                discount_code: model.me.team.discount_code
                            }
                        });
                    }

                    // Ask for the permissions needed (like phone call log read access)
                    // and start the background service
                    $document[0].addEventListener('deviceready', function () {

                        if (device.platform !== 'Android') {
                            return;
                        }

                        const CALL_LOG_PERMISSIONS_REJECTED = 'rejected_call_log_permissions_' + model.me.id;
                        if (!store.get(CALL_LOG_PERMISSIONS_REJECTED)) {
                            return cordova.plugins.Salesflare.hasPermissions(function (hasPermissions) {

                                if (hasPermissions === 'true') {
                                    const pluginConfig = {
                                        url: config.apiUrl + 'meetings/calllog',
                                        token: model.getToken(),
                                        teamId: model.me.team.id,
                                        syncDate: model.me.call_log_sync_date && new Date(model.me.call_log_sync_date).getTime(),
                                        minimumFetchInterval: 1
                                    };
                                    return cordova.plugins.Salesflare.configure(angular.noop, $exceptionHandler, pluginConfig);
                                }

                                const confirm = $mdDialog.confirm()
                                    .clickOutsideToClose(false)
                                    .escapeToClose(false)
                                    .title('🔄 Sync your call log?')
                                    .htmlContent('<p>Salesflare collects call log data to enable the automatic tracking of your calls for customer follow-up even when the app is closed or not in use. This data isn\'t shared with anyone else.</p>' +
                                        '<p>Read more in the <a href="https://salesflare.com/privacy#calllog" target="_blank" rel="noopener">Privacy Policy</a>.</p>')
                                    .ok('Accept')
                                    .cancel('Deny');
                                $mdDialog.show(confirm)
                                    .then(function () {

                                        return cordova.plugins.Salesflare.requestPermissions(function () {

                                            const pluginConfig = {
                                                url: config.apiUrl + 'meetings/calllog',
                                                token: model.getToken(),
                                                teamId: model.me.team.id,
                                                syncDate: model.me.call_log_sync_date && new Date(model.me.call_log_sync_date).getTime(),
                                                minimumFetchInterval: 1
                                            };
                                            return cordova.plugins.Salesflare.configure(angular.noop, $exceptionHandler, pluginConfig);
                                        }, function (errorString) {

                                            return $exceptionHandler(new Error(errorString));
                                        });
                                    })
                                    .catch(function () {

                                        store.set(CALL_LOG_PERMISSIONS_REJECTED, true);
                                    });
                            });
                        }
                    });

                    return notifications.init(self.fetchMe);
                })
                .catch((err) => {

                    // Log any error that is not simply an unauthorized error on the /me call
                    if (err.status !== 401) {
                        $exceptionHandler(err);
                    }

                    return $q.reject(err);
                });
        };

        function fetch(options) {

            return sfHttp.get(config.apiUrl + 'me', options).then(function (response) {

                if (!model.me && !$injector.get('sfWalkthrough').isShowing()) {
                    model.initCache(response.data.id);
                    model.isLoggedIn = true;
                }

                // Hide failed payment status bar when the is_delinquent property changes
                if (model && model.me && model.me.is_delinquent && !response.data.is_delinquent) {
                    statusBarService.hide('billing');
                }

                model.me = Object.assign(model.me || {}, response.data);

                // Initialize the flag service with the flags
                flagsService.init(model.me.flags);

                sfRestrictedDialog.check();

                // Set starting date of calendar service
                // Value = day of the week (0: Sunday, 1: Monday, ... , 6: Saturday)
                const startDays = [
                    {
                        id: 0,
                        value: 0
                    },
                    {
                        id: 1,
                        value: 1
                    },
                    {
                        id: 2,
                        value: 6
                    }
                ];
                const firstDayOfWeek = $filter('filter')(startDays, { value: Number.parseInt(model.me.first_day_of_week) }, true);
                if (firstDayOfWeek.length > 0) {
                    $mdDateLocale.firstDayOfWeek = firstDayOfWeek[0].value;
                }

                const message = {
                    function: 'user',
                    data: model.me
                };

                switch (config.mode) {
                    case 'outlook':

                        // Check if old version of plugin
                        if ($window.external.setUser) {
                            $window.external.setUser(angular.toJson(model.me));
                        }
                        else {
                            $window.external.notify(angular.toJson(message));
                        }

                        break;
                    case 'gmail':

                        $window.parent.postMessage(message, '*');

                        break;
                    case 'outlook-web':
                        $window.parent.postMessage(message, '*');

                        trackingService.getDomains().then(function (trackingResponse) {

                            $window.parent.postMessage({
                                function: 'setTrackingData',
                                data: {
                                    email_tracking_domain: model.me.team.email_tracking_domain,
                                    email_tracking_subdomain: model.me.team.email_tracking_subdomain,
                                    all_tracking_links: trackingResponse.urls,
                                    tracking_enabled: trackingResponse.enabled,
                                    img_url: config.tracking.imgUrl,
                                    old_img_url: config.tracking.oldImgUrl,
                                    base_unsubscribe_url: config.tracking.baseUnsubscribeUrl,
                                    tracking_disabled_on_team: flagsService.get('disableTracking')
                                }
                            }, '*');
                        });
                }
            }).catch(function (err) {

                if (err.status === 401) {
                    if (options && options.preventLogoutOnUnsuccessfulAuth) {
                        return $q.reject(err);
                    }

                    return self.logOut().finally(() => {
                        return $q.reject(err);
                    });
                }

                // Show the dialog, if one is already there it will take care of it
                return utils.showOfflineDialog();
            });
        }
    }
})();
