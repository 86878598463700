(function () {
    'use strict';

    angular
        .module('salesflare.components.dashboard', [])
        .component('sfDashboard', {
            templateUrl: 'app-ajs/components/dashboards/dashboard/dashboard.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                dashboard: '<',
                dashboardFilter: '<'
            }
        });

    function controller($document, $mdDialog, $state, $window, $timeout, model, dashboardService, filterService, reportService, pipelines, sfMoveReportDialog) {

        const vm = this;

        vm.unrenderedReports = [];
        vm.scoreCards = [];
        vm.charts = [];
        vm.canEditDashboards = ['Admin', 'Manager'].includes(model.me.role.name);
        vm.noDashboardTimeFilterText = ' (dashboard time filter not applied)';
        vm.reportCurrencyHtml = model.me.team.currency.html;

        let dashboardId;

        vm.$onInit = function () {

            if (vm.dashboard) {
                dashboardId = vm.dashboard && vm.dashboard.id;
                filterService.setCurrentDashboardId(dashboardId);
                setDocumentTitle();
                return getReports();
            }
            else {
                dashboardId = $state.params.id;
                filterService.setCurrentDashboardId(dashboardId);
                return getDashboardAndReports();
            }
        };

        function setDocumentTitle() {
            $document[0].title = ('Insights ' + vm.dashboard.name + ' | Salesflare');
        }

        function getReports() {
            return dashboardService.getReports(dashboardId).then(function (response) {

                const reports = response.data;
                vm.unrenderedReports =  [...reports];

                if (!vm.dashboardFilter.pipeline?.recurring) {
                    vm.unrenderedReports = vm.unrenderedReports.filter(function (report) {

                        return report.predefined_type !== 'mrr';
                    });
                }

                vm.scoreCards = reports.filter(function (report) {

                    return report.chart_type === 'scorecard' || (report.chart_type === 'predefined' && reportService.predefinedScoreCards.includes(report.predefined_type));
                });
                vm.charts = reports.filter(function (report) {

                    return (report.chart_type !== 'scorecard' && report.chart_type !== 'predefined') || (report.chart_type === 'predefined' && reportService.predefinedCharts.includes(report.predefined_type));
                });

                if ($state.current.name === 'printDashboard') {
                    vm.showSpinner = true;
                }

                vm.doneLoading = true;
            });
        }

        function getDashboardAndReports() {
            return dashboardService.get(dashboardId).then(function (dashboardResponse) {
                vm.dashboard = dashboardResponse.data;
                setDocumentTitle();
                return pipelines.getViewable().then(function (pipelinesResponse) {

                    const currentPipelineId = store.get('current_pipeline_' + model.me.id);
                    let currentPipeline;

                    if (currentPipelineId) {
                        currentPipeline = pipelinesResponse.pipelines.find(function (pipeline) {

                            return pipeline.id === currentPipelineId;
                        });
                    }

                    const insightsFilterCopy = angular.copy(filterService.filters.insights);
                    insightsFilterCopy.pipeline = currentPipeline;

                    if (insightsFilterCopy.owner.type === 'user') {
                        insightsFilterCopy.user = insightsFilterCopy.owner.id;
                    }
                    else {
                        insightsFilterCopy.group = insightsFilterCopy.owner.id;
                    }

                    vm.dashboardFilter = insightsFilterCopy;

                    getReports();
                });
            });
        }

        vm.$onChanges = function (changes) {

            vm.reportCurrencyHtml = vm.dashboardFilter?.pipeline?.currency?.html || model.me.team.currency.html;

            if (changes.dashboard && changes.dashboard.currentValue) {
                setDocumentTitle();
            }
        };

        vm.afterRender = (reportId) => {
            if ($state.current.name === 'printDashboard') {
                if (vm.unrenderedReports.length > 0) {
                    vm.unrenderedReports = vm.unrenderedReports.filter(function (report) {

                        return report.id !== reportId;
                    });
                    if (vm.unrenderedReports.length === 0) {

                        vm.showSpinner = false;
                        $window.scrollTo({ top: $window.document.body.scrollHeight, behavior: 'smooth' });
                        $timeout(function () {

                            $window.print();
                        }, 1000);
                    }
                }
            }
        };

        vm.addReport = function () {

            analytics.track('Create report started', { gaClientId: model.gaClientId });

            return $state.go('createReport', { dashboardId });
        };

        vm.editReport = function (reportId) {

            return $state.go('editReport', { id: reportId });
        };

        vm.downloadReport = function (report) {

            const titleHeight = 44;
            const reportContainerElement = $document[0].querySelector('#report-container-' + report.id);
            let scrollElement = $document[0].querySelector('#report-' + report.id);
            if (!(report.chart_type === 'predefined' || report.chart_type === 'scorecard' || report.chart_type === 'table')) {
                scrollElement = $document[0].querySelector('#canvas-' + report.id);
            }

            html2canvas(reportContainerElement, {
                useCORS: true,
                height: scrollElement.scrollHeight + titleHeight,
                onclone: function (clone) {
                    clone.querySelector('#report-container-' + report.id).style.height = (scrollElement.scrollHeight + titleHeight) + 'px';
                }
            }).then(function (canvasElement) {
                downloadCanvas(canvasElement, report.title);
            });
        };

        function downloadCanvas(canvasElement, name) {
            // Set white background
            const ctx = canvasElement.getContext('2d');
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = '#FFF';
            ctx.fillRect(0, 0, canvasElement.width, canvasElement.height);

            const MIME_TYPE = 'image/png';

            const imgURL = canvasElement.toDataURL(MIME_TYPE);

            const dlLink = $document[0].createElement('a');
            dlLink.download = name + '.png';
            dlLink.href = imgURL;
            dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':');

            $document[0].body.append(dlLink);
            dlLink.click();
            dlLink.remove();
        }

        vm.duplicateReport = function (reportId) {

            return $state.go('createReport', { dashboardId, reportId });
        };

        vm.moveReport = function (reportId) {

            const options = {
                reportId
            };

            return sfMoveReportDialog.show(options).then(function (newDashboardId) {

                return reportService.update(reportId, { dashboard: newDashboardId }).then(function () {

                    if (dashboardId !== newDashboardId) {
                        removeReportFromView(reportId);
                    }
                });
            });
        };

        vm.deleteReport = function (reportId) {

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .title('Are you sure you want to delete this report?')
                .ok('I\'m sure')
                .cancel('Cancel');

            return $mdDialog.show(confirm).then(function () {

                return reportService.delete(reportId).then(function () {

                    return removeReportFromView(reportId);
                });
            });
        };

        function removeReportFromView(reportId) {

            const removeScoreCardIndex = vm.scoreCards.map(function (scoreCard) {

                return scoreCard.id;
            }).indexOf(reportId);
            const removeChartIndex = vm.charts.map(function (chart) {

                return chart.id;
            }).indexOf(reportId);

            if (removeChartIndex >= 0) {
                vm.charts.splice(removeChartIndex, 1);
            }

            if (removeScoreCardIndex >= 0) {
                vm.scoreCards.splice(removeScoreCardIndex, 1);
            }
        }
    }
})();
