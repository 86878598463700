(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('me', meService);

    function meService(sfHttp, Upload, config, utils) {

        this.get = function () {

            return sfHttp.get(config.apiUrl + 'me', { ignoreLoadingBar: true });
        };

        this.update = function (me) {

            let pictureBlob;

            if (me.picture && me.picture.startsWith('data:image/')) {
                pictureBlob = utils.dataURIToBlob(me.picture);
                delete me.picture;
            }

            return sfHttp.put(config.apiUrl + 'me', me).then(function (response) {

                if (!pictureBlob) {
                    return response;
                }

                return Upload.upload({
                    url: config.apiUrl + 'me/picture',
                    data: {
                        file: pictureBlob
                    }
                }).then(function () {

                    return response;
                });
            });
        };
    }
})();
