(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('users', usersService);

    function usersService(sfHttp, config) {

        this.get = function (search, onlyEnabled, orderBy) {

            let url = config.apiUrl + 'users';

            const request = {
                cache: true
            };

            if (search) {
                request.cache = false;
                request.ignoreLoadingBar = true;
                url += '?search=' + search;
            }

            if (onlyEnabled) {
                request.params = {
                    onlyEnabled: true
                };
            }

            if (orderBy) {
                if (!request.params) {
                    request.params = {};
                }

                request.params.order_by = orderBy;
            }

            return sfHttp.get(url, request);
        };
    }
})();
