(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('TemplateManagerController', TemplateManagerController);

    function TemplateManagerController($mdDialog, $mdMedia, $timeout, $element, $q, $scope, $state, $stateParams, $window, workflowsService, joditService, model, sfDiscardDialog, templateService, templatesService, utils) {
        const vm = this;
        let thread = null;
        let allTemplates = vm.templates;
        let jodit;

        vm.me = model.me;
        vm.$mdMedia = $mdMedia;
        vm.editMode = false;
        vm.doneLoading = false;
        vm.templatePlaceholderName = 'Choose a name';
        vm.templatePlaceholderBody = 'Write a template';
        vm.showingMobileDetailsView = false;
        vm.noTemplates = allTemplates && allTemplates.length === 0;
        vm.fromAddIn = false;

        if ($state.current.name === 'addInTemplateManager') {
            vm.fromAddIn = true;
        }

        if (vm.fromAddIn) {
            vm.createNew = $stateParams.createNew;
            vm.onSelectTemplate = function (template) {
                const message = {
                    function: 'insertTemplate',
                    data: template
                };

                $window.parent.postMessage(message, '*');
            };
        }

        if ($mdMedia('gt-sm') || vm.fromAddIn) {
            vm.isLarge = true;
        }
        else {
            vm.isLarge = false;
        }

        vm.$onInit = init;

        function init() {
            if (vm.templates) {
                setSearchInputPlaceholder();
                setInitialTemplate();
            }
            else {
                templatesService.get({ limit: 10000 }, { ignoreLoadingBar: true }).then(function (response) {
                    vm.templates = response.data;
                    allTemplates = vm.templates;
                    vm.noTemplates = allTemplates.length === 0;
                    setSearchInputPlaceholder();
                    setInitialTemplate();
                });
            }

            return workflowsService.getMergeFields().then(function (response) {

                const responseData = response.data;

                const contactMergeFields = responseData.filter(function (mf) {

                    return mf.entity_type === 'contact';
                });
                contactMergeFields.unshift({ subheader: 'Contact' });

                const accountMergeFields = responseData.filter(function (mf) {

                    return mf.entity_type === 'account';
                });
                accountMergeFields.unshift({ subheader: 'Account' });

                vm.mergeFields = [...contactMergeFields, ...accountMergeFields];
                vm.doneLoading = true;
            });
        }

        function setInitialTemplate() {
            if (vm.createNew) {
                vm.create();
            }
            else {
                vm.selectedTemplate = vm.templates && vm.templates.length > 0 && vm.templates[0];
            }
        }

        vm.edit = function () {

            vm.editMode = true;
            vm.selectedTemplate.original = angular.copy(vm.selectedTemplate);
        };

        vm.selectTemplate = function (template) {

            template = template || (vm.templates && vm.templates.length > 0 && vm.templates[0]);

            return showDiscardDialogWhenNecessary().then(function () {

                vm.showingMobileDetailsView = true;
                vm.selectedTemplate = template;
                vm.selectedTemplate.original = angular.copy(template);

                if (vm.selectedTemplate.id) {
                    vm.editMode = false;
                }

                if (jodit) {
                    jodit.setEditorValue(vm.selectedTemplate.html_body || '');
                }
            }, angular.noop);
        };

        vm.back = function () {

            return showDiscardDialogWhenNecessary().then(function () {

                if (!vm.isLarge && vm.showingMobileDetailsView) {
                    if (vm.editMode) {
                        vm.editMode = false;
                        return;
                    }

                    vm.showingMobileDetailsView = false;
                    vm.editMode = false;
                    setSearchInputPlaceholder();
                    return;
                }

                if (vm.fromAddIn) {
                    const message = {
                        function: 'hide'
                    };

                    $window.parent.postMessage(message, '*');
                }
                else {
                    return $mdDialog.cancel();
                }
            }, angular.noop);
        };

        vm.insert = function () {

            vm.onSelectTemplate(vm.selectedTemplate);

            return $mdDialog.hide();
        };

        vm.cancel = function () {

            return showDiscardDialogWhenNecessary().then(function () {

                vm.editMode = false;

                if (!vm.selectedTemplate.id) {
                    removeSelectedTemplateFromView();
                    vm.selectTemplate();
                }
            }, angular.noop);
        };

        vm.create = function (template) {

            return showDiscardDialogWhenNecessary(true).then(function () {

                const newTemplate = {
                    name: template ? template.name : undefined,
                    subject: template ? template.subject : undefined,
                    html_body: template ? template.html_body : undefined,
                    shared: template ? template.shared : true,
                    created_by: {
                        id: model.me.id,
                        name: model.me.name,
                        picture: model.me.picture
                    }
                };

                vm.templates = [...vm.templates, newTemplate].sort(sortTemplatesAlphabetically);
                allTemplates = [...allTemplates, newTemplate].sort(sortTemplatesAlphabetically);
                vm.noTemplates = false;
                vm.selectTemplate(newTemplate);
                vm.editMode = true;
            }, angular.noop);
        };

        vm.save = function () {

            if (vm.templateForm.$invalid) {
                return;
            }

            vm.selectedTemplate.plain_text_body = jodit.text;
            vm.selectedTemplate.html_body = jodit.getEditorValue();

            if (!vm.selectedTemplate.id) {

                return templatesService.create(convertViewTemplateToPayload(vm.selectedTemplate)).then(function (response) {

                    saveTemplateHandler(response.data.id);
                });
            }

            return templateService.update(vm.selectedTemplate.id, convertViewTemplateToPayload(vm.selectedTemplate)).then(function () {

                saveTemplateHandler(vm.selectedTemplate.id);
            });
        };

        function saveTemplateHandler(id) {

            vm.editMode = false;
            vm.selectedTemplate.id = id;
            vm.templates.sort(sortTemplatesAlphabetically);

            utils.showSuccessToast('Template saved!');

            return templateService.get(id).then(function (response) {

                Object.assign(vm.selectedTemplate, response.data);
            });
        }

        vm.delete = function () {

            if (!vm.selectedTemplate.id) {
                return vm.cancel();
            }

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .textContent('Are you sure you want to delete this email template?')
                .ok('Yes')
                .cancel('No');

            return $mdDialog.show(confirm).then(function () {

                return templateService.delete(vm.selectedTemplate.id).then(function () {

                    removeSelectedTemplateFromView();
                    vm.showingMobileDetailsView = false;
                    vm.selectedTemplate = vm.templates && vm.templates.length > 0 && vm.templates[0];
                    vm.editMode = false;
                });
            }, angular.noop);
        };

        $scope.$watchGroup(['vm.editMode', 'vm.doneLoading'], function (newValue) {

            if (newValue[0] === true && newValue[1] === true) {
                jodit = initJoditEditor();
            }
        });

        $scope.$watch('vm.search', function (newValue, oldValue) {

            if (newValue !== oldValue) {
                $timeout.cancel(thread);

                thread = $timeout(function () {

                    if (!vm.search) {
                        vm.templates = allTemplates.sort(sortTemplatesAlphabetically);
                        return;
                    }

                    vm.templates = allTemplates.filter(function (template) {

                        return (template.name && template.name.toLowerCase().includes(vm.search.toLowerCase())) || (template.plain_text_body && template.plain_text_body.toLowerCase().includes(vm.search.toLowerCase()));
                    }).sort(sortTemplatesAlphabetically);
                });
            }
        });

        $scope.$watch(function watchAllTemplatesLength() {

            return allTemplates.length;
        }, setSearchInputPlaceholder);

        vm.getMobileToolbarHeader = function () {

            if (!vm.showingMobileDetailsView) {
                return 'Templates';
            }

            if (!vm.selectedTemplate.id) {
                return 'New Template';
            }

            return ((vm.editMode ? 'Edit' : 'View') + ' Template');
        };

        function initJoditEditor() {

            const joditInstance = joditService.getJoditEditor({
                queryStringToBindTo: $element.find('.editor')[0],
                placeholder: 'Write a template',
                previewButton: {
                    enabled: false
                },
                mergeFieldButton: {
                    enabled: true,
                    mergeFields: vm.mergeFields
                },
                templatesButton: {
                    enabled: false
                },
                attachmentsButton: {
                    enabled: false
                },
                sendTestButton: {
                    enabled: false
                }
            }, function (bodyText) {

                vm.selectedTemplate.html_body = bodyText;
                vm.selectedTemplate.plain_text_body = jodit.text;
            });

            if (vm.selectedTemplate && vm.selectedTemplate.html_body) {
                joditInstance.setEditorValue(vm.selectedTemplate.html_body);
            }

            if (vm.createNew) {
                if (joditInstance.defaultTimeout) {
                    $timeout(joditInstance.selection.focus, 300);
                }
                else {
                    joditInstance.selection.focus();
                }
            }

            return joditInstance;
        }

        function sortTemplatesAlphabetically(a, b) {

            const lowerCasedAName = a.name ? a.name.toLowerCase() : '';
            const lowerCasedBName = b.name ? b.name.toLowerCase() : '';

            if (lowerCasedAName < lowerCasedBName) {
                return -1;
            }

            if (lowerCasedAName > lowerCasedBName) {
                return 1;
            }

            return 0;
        }

        function convertViewTemplateToPayload(template) {

            return {
                name: template.name,
                subject: template.subject,
                html_body: template.html_body,
                plain_text_body: template.plain_text_body,
                shared: template.shared
            };
        }

        // Resolves the promise when the normal action can continue, rejects when 'discard' is pressed on the cancel dialog
        function showDiscardDialogWhenNecessary(preventSelectionOfDefaultTemplate) {

            if (!vm.editMode) {
                return $q.resolve();
            }

            const isNewTemplate = !vm.selectedTemplate.id;
            const nameChanged = vm.selectedTemplate.name !== (vm.selectedTemplate.original && vm.selectedTemplate.original.name);
            const subjectChanged = vm.selectedTemplate.subject !== (vm.selectedTemplate.original && vm.selectedTemplate.original.subject);
            const bodyChanged = vm.selectedTemplate.html_body !== (vm.selectedTemplate.original && vm.selectedTemplate.original.html_body);
            const privacySettingsChanged = vm.selectedTemplate.shared !== (vm.selectedTemplate.original && vm.selectedTemplate.original.shared);
            const templateChanged = nameChanged || subjectChanged || bodyChanged || privacySettingsChanged;

            if (!templateChanged && !isNewTemplate) {
                return $q.resolve();
            }

            return sfDiscardDialog.show(isNewTemplate, 'email template').then(function () {

                vm.editMode = false;

                if (vm.selectedTemplate.id) {
                    Object.assign(vm.selectedTemplate, vm.selectedTemplate.original);
                    return $q.resolve();
                }

                removeSelectedTemplateFromView();

                if (preventSelectionOfDefaultTemplate) {
                    vm.selectedTemplate = vm.templates && vm.templates.length > 0 && vm.templates[0];
                }
            }, null, $q.reject);
        }

        function removeSelectedTemplateFromView() {

            vm.templates = vm.templates.filter(function (t) {

                return !angular.equals(vm.selectedTemplate, t);
            });
            allTemplates = allTemplates.filter(function (t) {

                return !angular.equals(vm.selectedTemplate, t);
            });
            vm.noTemplates = allTemplates.length === 0;
        }

        function setSearchInputPlaceholder() {

            return $timeout(function () {

                angular.element('.search-input').attr('placeholder', 'Search ' + allTemplates.length + (allTemplates.length === 1 ? ' template' : ' templates'));
            });
        }
    }
})();
