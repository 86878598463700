import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MediaService } from '@core/services/media.service';

import { BillingInvoiceModel as BillingInvoice } from '@shared/models/billing.model';

import { ApiResponseModel as ApiResponse } from '@shared/models/services/api.response.model';
import { BillingHistoryResponseData } from '@shared/models/services/billing-service.response.model';
import { CdkVirtualScrollableElement } from '@angular/cdk/scrolling';
import { filter, map, pairwise, throttleTime } from 'rxjs';

interface ListEntry {
    invoice?: BillingInvoice | undefined;
    date?: string | undefined;
}

@Component({
    selector: 'sfx-billing-usage-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class BillingUsageHistoryComponent implements OnInit, AfterViewInit {

    public invoices: BillingInvoice[] = [];
    public entries: ListEntry[] = [];

    @ViewChild(CdkVirtualScrollableElement)
    scrollable!: CdkVirtualScrollableElement;

    public rowSize = 48;

    private lastInvoiceProcessedUnixTimestamp: number | undefined;
    private hasMore = false;
    private typeMap: { [key: string]: string } = {
        'credited': 'Credited',
        'debited': 'Debited balance by',
        'charge': 'Charged'
    };

    constructor(
        private datePipe: DatePipe,
        public media: MediaService,
        @Inject('billingService') public billingService: any,
        @Inject('rootScope') public rootScope: any,
        @Inject('utilsService') public utils: any
    ) {}

    public ngOnInit(): void {

        if (this.rootScope.history[this.rootScope.history.length - 1].fromState.name.includes('billingSettings.')) {
            this.rootScope.history.pop();
        }

        this.loadBatch();
    }

    public ngAfterViewInit(): void {

        this.scrollable.elementScrolled().pipe(
            map(() => this.scrollable.measureScrollOffset('bottom')),
            pairwise(),
            filter(([previousBottomOffset, currentBottomOffset]) => (this.hasMore && currentBottomOffset < previousBottomOffset && currentBottomOffset < this.rowSize * 2)),
            throttleTime(500)
        ).subscribe(() => {
            this.loadBatch();
        });
    }

    public loadBatch(): void {
        this.billingService.getBillingHistory(this.lastInvoiceProcessedUnixTimestamp).then(this._billingHistoryResponseHandler.bind(this));
    }

    public isSameMonth(firstDate: string | number, secondDate: string | number) {

        return this.utils.isSameMonth(firstDate, secondDate);
    }

    public isSameDay(firstDate: string | number, secondDate: string | number) {

        return this.utils.isSameDay(firstDate, secondDate);
    }

    public previousIndex(index: number): number {

        return Math.max(index - 1, 0);
    }

    private _billingHistoryResponseHandler(response: ApiResponse<BillingHistoryResponseData>): void {

        this.lastInvoiceProcessedUnixTimestamp = response.data.last_invoice_processed_timestamp;
        this.hasMore = response.data.has_more;

        if (response.data.lines) {

            this.invoices = [...this.invoices, ...response.data.lines.map((invoice) => {

                if (invoice.description === 'Monthly accrued balance' && invoice.type === 'credited') {
                    return;
                }

                return {
                    type: invoice.type,
                    chargeLine: this.typeMap[invoice.type],
                    unixTime: invoice.date,
                    date: this.datePipe.transform(new Date(invoice.date * 1000), 'yyyy-MM-dd'),
                    total: invoice.total / 100,
                    description: (invoice.description && invoice.description.split(' | VAT')[0].split('\n')[0]) || 'No description available',
                    currency: invoice.currency === 'eur' ? 'EUR' : 'USD',
                    invoice_pdf: invoice.invoice_pdf
                };
            }).filter((invoice) => !!invoice) as BillingInvoice[]];

            this.entries = this.invoices.flatMap((invoice, idx) => {

                if (idx === 0) {
                    return [{ date: invoice.date }, { invoice }];
                }
                else {
                    const previousEntry = this.invoices[idx - 1];

                    if (this.isSameMonth(previousEntry.date, invoice.date)) {

                        if (this.isSameDay(previousEntry.date, invoice.date)) {
                            invoice.hideDate = true;
                        }

                        return [{ invoice }];
                    }
                    else {
                        return [{ date: invoice.date }, { invoice }];
                    }
                }
            }) as ListEntry[];
        }
    }
}
