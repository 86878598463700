<sfx-toolbar enableDivider>
    Set permissions
</sfx-toolbar>

<sfx-content>
    <sfx-header disableDividers>
        Roles
    </sfx-header>
    <sfx-section disableContentPadding>
        <mat-card appearance="outlined" class="role-card" *ngFor="let role of roles">
            <mat-card-content>
                <h3>{{ role.name }}</h3>
                <p [innerHTML]="role.description"></p>
                <p>
                    <span [innerHTML]="role.userText"></span>
                    <span *ngIf="isAdmin"> (Admins can assign roles in <a href="" uiSref="settings.teamSettings">Manage your team</a>).</span>
                    <span *ngIf="!isAdmin"> (These roles can be defined by Admins).</span>
                </p>
                <p *ngIf="role.special_permissions && role.special_permissions.length > 0">
                    The built-in permissions they have are:
                </p>
                <ul>
                    <li *ngFor="let specialPermission of role.special_permissions">{{ specialPermission }}</li>
                </ul>
                <p>
                    <span>They are able to... </span>
                    <sfx-plan-flag *ngIf="showPlanFlags()" [flag]="'permissions'"></sfx-plan-flag>
                </p>
                <div class="role-permission" *ngFor="let rolePermission of role.role_permissions" data-layout="row" data-layout-align="start center" data-layout-gap="8px">
                    <div class="label">{{ rolePermission.action_name }}</div>

                    <mat-form-field class="no-subscript">
                        <mat-select [disabled]="!isAdmin" class="accent" [(ngModel)]="rolePermission.scope" (selectionChange)="updateRolePermissions(role.id, role.role_permissions, rolePermission.action)">
                            <mat-select-trigger>{{ getAccessScopeName(rolePermission.scope) }}</mat-select-trigger>
                            <mat-option *ngFor="let accessScope of getAccessScopes(role.role_permissions, rolePermission.action)" [value]="accessScope.id">
                                {{ accessScope.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="showPlanFlags() || (showPlanFlags() && !(isAdmin || isManager))" class="explanation">
                    <span>This can be set by admins<span *ngIf="showPlanFlags()"> on the <a (click)="openUpgradeDialog()" >Pro</a> plan</span>. Read more about what own or group data means <a href="https://howto.salesflare.com/en/articles/4664889-can-i-set-user-permissions" target="_blank" rel="noopener">in this article</a>.</span>
                </div>
            </mat-card-content>
        </mat-card>
    </sfx-section>
    <sfx-header disableDividers>
        Pipelines
    </sfx-header>
    <sfx-section disableContentPadding>
        <mat-card appearance="outlined" class="pipelines-card">
            <mat-card-content>
                <p>
                    Admins and managers can define which user groups have access to which opportunity pipelines.
                    <sfx-plan-flag *ngIf="showPlanFlags()" [flag]="'permissions'"></sfx-plan-flag>
                </p>
                <p>By default they are accessible by all user groups.</p>

                <form name="pipelinePermissionsForm" #pipelinePermissionsForm="ngForm">
                    <div class="pipeline-permissions" data-layout="row" *ngFor="let pipeline of pipelines">
                        <div class="label ellipsis">{{ pipeline.name }}</div>

                        <mat-form-field data-flex data-layout="column">
                            <!-- Required means that at least 1 group needs to be selected -->
                            <mat-select #pipelineGroupsSelect
                                    multiple
                                    required
                                    class="accent"
                                    [name]="'pipeline-' + pipeline.id"
                                    [compareWith]="compareGroups"
                                    [disabled]="!(isAdmin || isManager) || groups.length === 0"
                                    [(ngModel)]="pipeline.groups"
                                    (selectionChange)="updatePipelineGroupAccess(pipeline)">
                                <mat-option *ngFor="let group of groups" [value]="group">
                                    {{ group.name }}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="pipelinePermissionsForm.controls['pipeline-' + pipeline.id]?.hasError('required')">
                                Select at least one group
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <p *ngIf="groups.length === 0">Create a group in <a href="" uiSref="settings.teamSettings">Manage your team</a> first to be able to define which groups have access to which pipelines.</p>
                </form>

                <div class="explanation" *ngIf="showPlanFlags() || (!showPlanFlags() && !(isAdmin || isManager))">
                    <p>This can be set by admins<span *ngIf="showPlanFlags()"> on the <a (click)="openUpgradeDialog()" >Pro</a> plan</span>.</p>
                </div>
            </mat-card-content>
        </mat-card>
    </sfx-section>
</sfx-content>
