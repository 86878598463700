(function () {
    'use strict';

    angular
        .module('salesflare.components.closingPercentagePerRep', [])
        .component('sfClosingPercentagePerRep', {
            templateUrl: 'app-ajs/components/dashboards/predefinedReports/closingPercentagePerRep/closingPercentagePerRep.html',
            controller: angular.noop,
            controllerAs: 'vm',
            bindings: {
                data: '<'
            }
        });
})();
