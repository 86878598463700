(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('AssignTaskPanelController', AssignTaskPanelController);

    function AssignTaskPanelController(mdPanelRef, account, users) {

        const vm = this;

        get();

        vm.assignees = [];

        if (vm.task.assignees) {
            vm.assignees = angular.copy(vm.task.assignees);
        }

        vm.searchText = '';

        function get() {

            if (vm.task && vm.task.account) {
                return getUsers(vm.task.account.id).catch(function (err) {

                    // Can't view account, return all users
                    if (err.status === 404) {
                        return getUsers();
                    }
                });
            }

            return getUsers();
        }

        function getUsers(accountId) {

            if (accountId) {
                return account.getUsers(accountId, { noToast: true, noToastForStatusCode: [404] }).then(function (response) {

                    const assigneesCopy = angular.copy(vm.assignees).map(function (assignee) {

                        delete assignee.selected;
                        return assignee;
                    });

                    vm.users = response.data.map(function (user) {

                        const found = assigneesCopy.find(function (u) {

                            if (u.id === user.id) {
                                u.selected = true;
                            }

                            return u.id === user.id;
                        });

                        if (found) {
                            user.selected = true;
                        }

                        return user;
                    });

                    const leftOverAssignees = assigneesCopy.filter(function (assignee) {

                        if (assignee.selected) {
                            return false;
                        }

                        assignee.selected = true;
                        return true;
                    });

                    vm.users = [...vm.users, ...leftOverAssignees];
                });
            }
            else {
                return users.get(null, false).then(function (response) {

                    // When the task doesn't exist yet (new task), get all the users
                    if (!vm.task || !vm.task.assignees) {
                        vm.users = response.data;
                    }
                    else {
                        vm.users = response.data.map(function (user) {

                            const found = vm.assignees.find(function (u) {

                                return u.id === user.id;
                            });

                            if (found) {
                                user.selected = true;
                            }

                            return user;
                        }).filter(function (user) {

                            return user.selected || !user.disabled;
                        });
                    }
                });
            }
        }

        vm.getUsers = function () {

            if ((!vm.searchText || vm.searchText === '') && vm.accountId) {
                return account.getUsers(vm.accountId, { noToast: true, noToastForStatusCode: [404] }).then(handler).catch(function (err) {

                    if (err.status === 404) {
                        return users.get(vm.searchText).then(handler);
                    }
                });
            }

            return users.get(vm.searchText).then(handler);
        };

        function handler(response) {

            const assigneesCopy = angular.copy(vm.assignees);
            vm.users = response.data.map(function (user) {

                const found = assigneesCopy.find(function (u) {

                    if (u.id === user.id) {
                        u.selected = true;
                    }

                    return u.id === user.id;
                });

                if (found) {
                    user.selected = true;
                }

                return user;
            }).filter(function (user) {

                return user.selected || !user.disabled;
            });
        }

        vm.changeSelected = function (user) {

            if (user.selected) {
                return vm.assignees.push(user);
            }

            vm.assignees = vm.assignees.filter(function (u) {

                return u.id !== user.id;
            });
        };

        vm.cancel = function () {

            return mdPanelRef.close().then(function () {

                return mdPanelRef.destroy();
            });
        };

        vm.save = function () {

            vm.task.assignees = vm.assignees;
            return mdPanelRef.close().then(function () {

                return mdPanelRef.destroy();
            });
        };

    }
})();
