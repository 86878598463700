(function () {
    'use strict';

    angular
        .module('salesflare')
        .config(function ($mdDialogProvider) {

            $mdDialogProvider.addPreset('templateManagerDialog', {
                options: function () {
                    return {
                        templateUrl: 'app-ajs/components/templates/templateManager/templateManager.html',
                        controllerAs: 'vm',
                        controller: 'TemplateManagerController',
                        bindToController: true,
                        clickOutsideToClose: false,
                        escapeToClose: false,
                        multiple: true,
                        onComplete
                    };
                }
            });

            function onComplete(scope, element) {

                exitDialogOnEscape(scope, element);
                exitDialogOnClickOutside(element, scope);
            }

            /**
             * Dialog functions taken from Angular Material code
             *
             * @param {{}} scope scope object
             * @param {{}} element element object
             * @returns {undefined}
             */
            function exitDialogOnEscape(scope, element) {

                function keyHandlerFn(ev) {
                    if (ev.keyCode === 27) {
                        ev.stopPropagation();
                        ev.preventDefault();

                        return scope.vm.back();
                    }
                }

                element.on('keydown', keyHandlerFn);
            }

            function exitDialogOnClickOutside(element, scope) {

                let sourceElem;

                function mousedownHandler(ev) {
                    sourceElem = ev.target;
                }

                function mouseupHandler(ev) {
                    if (sourceElem === element[0] && ev.target === element[0]) {
                        ev.stopPropagation();
                        ev.preventDefault();

                        return scope.vm.back();
                    }
                }

                element.on('mousedown', mousedownHandler);
                element.on('mouseup', mouseupHandler);
            }
        });
})();
