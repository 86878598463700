(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('messageService', messageService);

    function messageService(sfHttp, config) {

        this.create = function (message) {

            return sfHttp.post(config.apiUrl + 'messages', message);
        };

        this.remove = function (messageId) {

            return sfHttp.delete(config.apiUrl + 'messages/' + messageId);
        };

        this.update = function (messageId, message) {

            return sfHttp.put(config.apiUrl + 'messages/' + messageId, message);
        };
    }
})();
