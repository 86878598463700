(function () {
    'use strict';

    angular
        .module('salesflare.components.stageUpdates', [])
        .component('sfStageUpdates', {
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/stageUpdates/stageUpdates.html',
            bindings: {
                stageUpdates: '<',
                opportunityStage: '<',
                showAll: '<'
            },
            controller
        });

    function controller() {

        const vm = this;

        vm.getStageArrowColor = function (stage) {

            if (stage.order > vm.opportunityStage.order) {
                return 'rgb(241, 242, 246)';
            }

            return 'rgb(' + stage.color + ')';
        };
    }
})();
