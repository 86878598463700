(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('CompanyInfoController', CompanyInfoController);

    function CompanyInfoController($rootScope, $scope, $stateParams, $state, $timeout, $mdSidenav, $mdDialog, account, model, network, email, helperFunctionsService, sfWalkthrough, events, utils) {

        $scope.id = $stateParams.id;
        $scope.type = $state.current.data.type;
        $scope.editable = true;
        $scope.showDetails = false;
        $scope.addCompanyDisabled = false;
        $scope.descriptionExtendable = false;
        $scope.descriptionExtended = false;

        // $stateParams.contacts are existing contacts found by the Gmail and LinkedIn sidebar,
        // or contact data found on LinkedIn
        try {
            $scope.extensionContacts = angular.fromJson($stateParams.contacts);
        }
        catch {
            $scope.extensionContacts = null;
        }

        // Use this to make sure the header gets rendered at initialization, to avoid weird loading on receiving additional contact data
        $scope.showRelatedContactsHeader = $scope.extensionContacts?.length > 0;

        // Must be the same as default_Limit on account entity in server
        let networkLimit = 21;

        // Check if the description is extendable when the description's element is loaded and its height is known
        $scope.$on('$viewContentLoaded', function () {

            $timeout(() => {

                isDescriptionExtendable();
            }, 0);
        });

        if ($scope.company) {
            showInfo();
        }
        else {
            $scope.$on('company:loaded', function (event, loadedCompany) {

                $scope.company = loadedCompany;
                const options = {
                    company: loadedCompany.id
                };

                return network.get(options).then(function (response) {

                    $scope.hasMoreNetwork = false;
                    $scope.company.network = response.data;

                    return prepareNetwork();
                });
            });
        }

        if ($state.current.name === 'accounts.company.info' && $rootScope.history[$rootScope.history.length - 1]?.fromState.name !== 'accounts') {
            $rootScope.history.pop();
        }

        $scope.$on('refreshData', function () {

            return $scope.get();
        });

        $scope.close = function () {

            return $mdSidenav('right').close();
        };

        $scope.changeDescriptionExtended = () => {

            $scope.descriptionExtended = !$scope.descriptionExtended;
        };

        function addCompany() {

            $scope.addCompanyDisabled = true;

            const accountCreationObject = $stateParams.privateCompanyInfo ? { ...$stateParams.privateCompanyInfo } : { domain: $scope.company.domain };

            return account.create(accountCreationObject).then(function (response) {

                const params = { id: response.data.id };

                $scope.addCompanyDisabled = false;

                $rootScope.$broadcast('refreshData');
                $rootScope.$broadcast(events.account.createdFromCompany, { company: $scope.company.id, account: response.data.id });

                if ($scope.accounts) {
                    $scope.accounts[$scope.accountCarousel.index].id = params.id;
                    $scope.accounts[$scope.accountCarousel.index].owner = model.me.id;
                    params.accounts = angular.toJson($scope.accounts);
                }

                // If the current state is a suggestion in the accounts list, go to the appropriate state
                if ($state.current.name.includes('accounts.suggestion')) {
                    return $state.go($state.current.name.replace('suggestion', 'account'), params);
                }

                return $state.go($state.current.name.replace('company', 'account'), params);
            });
        }

        $scope.addCompany = function () {

            if ($scope.company?.domain === model.me.team.domain) {
                return $mdDialog.show(utils.getAccountCreationConfirmDialog(true)).then(addCompany);
            }

            return addCompany();
        };

        $scope.loadNetwork = function () {

            const options = {
                company: $scope.id,
                offset: $scope.company.network.length
            };

            return network.get(options).then(function (response) {

                networkLimit += $scope.company.network.length;
                $scope.company.network = [...$scope.company.network, ...response.data];

                return prepareNetwork();
            });
        };

        $scope.mailto = email.mailto;

        $scope.openWebsite = helperFunctionsService.openWebsite;
        $scope.callNumber = helperFunctionsService.callNumber;

        $scope.toggleDescriptionExtended = () => {

            $scope.descriptionExtended = !$scope.descriptionExtended;
        };

        $scope.createSuggestion = (personObject, $event) => {

            $event.stopPropagation();

            if (sfWalkthrough.isShowing()) {
                return $mdDialog.show({
                    controller: 'CantDoDialogController',
                    controllerAs: 'vm',
                    templateUrl: 'app-ajs/components/cantDoDialog/cantDoDialog.html',
                    bindToController: true,
                    clickOutsideToClose: false
                });
            }

            const cleanedPersonObject = { ...personObject };
            delete cleanedPersonObject.creatable;
            delete cleanedPersonObject.role;

            return $state.go('createContact', {
                personObject: cleanedPersonObject,
                isLinkedInSuggestion: true
            });
        };

        // Contacts shown under `Related contacts` might not exist in Salesflare (LinkedIn)
        // Contacts shown under `People in my network` always exist in Salesflare and have ids
        $scope.goToContact = (contact, $event) => {

            if (contact.id) {
                if (contact.owned_by_user) {
                    $state.go('contact', { id: contact.id });
                }
                else {
                    $state.go('contact', { id: contact.id, type: 'network' });
                }
            }
            else if ($event) {
                $scope.createSuggestion(contact, $event);
            }
        };

        function showInfo() {
            //TODO: implement?
        }

        function prepareNetwork() {

            if ($scope.company.network.length >= networkLimit) {
                $scope.hasMoreNetwork = true;
                $scope.company.network.pop();
            }
            else {
                $scope.hasMoreNetwork = false;
            }

            // Don't show related contacts as network contacts as well
            if ($scope.extensionContacts?.length > 0) {
                $scope.company.network = $scope.company.network.filter((networkContact) => !$scope.extensionContacts.map((extensionContact) => extensionContact.id).includes(networkContact.id));
            }
        }

        function isDescriptionExtendable() {

            if ($scope.company?.description) {
                const longTextValueLength = $scope.company.description.length;
                let element;
                try {
                    element = angular.element('.sf-company-description')[0];
                }
                catch {
                    // Do nothing and use the fallback
                }

                if (element?.scrollHeight && element?.clientHeight) {
                    // When the scrollHeight is larger than the actual height of the element, it means there is overflowing content, so the description is extendable
                    $scope.descriptionExtendable = element.scrollHeight > element.clientHeight;
                }
                // Fallback is not ideal, since new line characters might surpass 5 lines
                else if (longTextValueLength > 170) {
                    $scope.descriptionExtendable = true;
                }
                else {
                    $scope.descriptionExtendable = false;
                }
            }
        }
    }
})();
