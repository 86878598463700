(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('NetworkController', NetworkController);

    function NetworkController($rootScope, $scope, $stateParams, $mdMedia, $mdDialog, $timeout, network, email) {

        const accountOrCompanyId = $stateParams.account;
        const type = $stateParams.type;
        let thread = null;

        $scope.$watch('filter', function (newValue, oldValue) {

            if (newValue !== oldValue) {
                $timeout.cancel(thread);

                thread = $timeout(function () {

                    return $scope.network.reload();
                }, 750);
            }
        });

        // Virtual repeat + infinite scroll object that angular material uses
        $scope.network = {
            toLoad: 0,
            numLoaded: 0,
            items: [],

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + 20;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad < index) {
                    this.toLoad += 20;

                    const bound = angular.bind(this, networkResponse);

                    if (this.items.length === 0) {
                        if (type === 'company') {
                            const initComOptions = {
                                company: accountOrCompanyId,
                                offset: 0,
                                search: $scope.filter
                            };

                            return network.get(initComOptions).then(bound, null, bound);
                        }
                        else {
                            const initAccOptions = {
                                account: accountOrCompanyId,
                                offset: 0,
                                search: $scope.filter
                            };

                            return network.get(initAccOptions).then(bound, null, bound);
                        }
                    }
                    else if (type === 'company') {
                        const comOptions = {
                            company: accountOrCompanyId,
                            offset: this.numLoaded,
                            search: $scope.filter
                        };

                        return network.get(comOptions).then(angular.bind(this, function (response) {

                            this.items = [...this.items, ...response.data];
                            this.numLoaded = this.toLoad;
                        }));
                    }
                    else {
                        const accOptions = {
                            account: accountOrCompanyId,
                            offset: this.numLoaded,
                            search: $scope.filter
                        };

                        return network.get(accOptions).then(angular.bind(this, function (response) {

                            this.items = [...this.items, ...response.data];
                            this.numLoaded = this.toLoad;
                        }));
                    }
                }
            },

            // Forces reset of object
            reload: function () {

                this.toLoad = 0;
                this.numLoaded = 0;
                this.items = [];
                this.doneLoading = false;
            }
        };

        function networkResponse(response) {

            this.items = response.data;
            this.numLoaded = this.toLoad;

        }

        $scope.back = function (reload) {

            if ($mdMedia('gt-sm')) {
                return $mdDialog.hide(reload);
            }
            else {
                return $rootScope.back();
            }
        };

        $scope.mailto = email.mailto;
    }
})();
