<sfx-content>
    <div class="empty-state-container" *ngIf="showEmptyState">
        <sfx-empty-state image="images/archive-2.png">
            <h1 class="accent">{{ emptyStateMessage }}</h1>
        </sfx-empty-state>
        <button mat-button href="https://www.youtube.com/watch?v=QCVkAYR4xcY" target="_blank" style="margin: auto" data-lity>Watch video</button>
    </div>
    <ng-container *ngIf="inProgress.length > 0">
        <sfx-header>
            In progress
        </sfx-header>
        <sfx-section disableContentPadding>
            <mat-list>
                <mat-list-item *ngFor="let importState of inProgress; let idx = index;">
                    <h1 class="ellipsis accent import-title">
                        {{ importState.creation_date | date: 'dd-MM-yy' }} {{ importState.file_name }}
                    </h1>
                    <div [ngSwitch]="importState.status" class="ellipsis accent import-state" data-flex>
                        <span *ngSwitchCase="'waiting'">{{importState.total_records}} records - fields not mapped yet</span>
                        <span *ngSwitchCase="'mapping'">{{importState.total_records}} records - fields partially mapped</span>
                        <span *ngSwitchCase="'mapped'">{{importState.total_records}} records - preparing import</span>
                        <span *ngSwitchCase="'in_process'">{{importState.processed_records}}/{{importState.total_records}} processed - importing</span>
                    </div>
                    <button mat-button *ngIf="importState.status === 'waiting' || importState.status === 'mapping'" [disabled]="modelService.me.role.permissions.ced === 'NONE'" (click)="goToMap(importState.id)">
                        Map
                    </button>
                    <mat-icon [matMenuTriggerFor]="menu" fontSet="salesflare" fontIcon="sf-icon-menu-small-vertical" class="icon-menu">
                    </mat-icon>
                    <mat-menu #menu>
                        <button mat-menu-item (click)="removeImport(importState, idx)" [disabled]="this.modelService.me.role.permissions.ced === 'NONE'">
                            <div data-layout="row" data-layout-align="start center">
                                <mat-icon fontSet="salesflare" fontIcon="sf-icon-delete" class="icon-22 mat-mdc-menu-item-accent"></mat-icon>
                                <p class="mat-mdc-menu-item-accent" data-flex>Delete import</p>
                            </div>
                        </button>
                    </mat-menu>
                </mat-list-item>
            </mat-list>
        </sfx-section>
    </ng-container>
    <ng-container *ngIf="complete.length > 0">
        <sfx-header>
            Complete
        </sfx-header>
        <sfx-section disableContentPadding>
            <mat-list>
                <mat-list-item *ngFor="let importState of complete; let idx = index;">
                    <h1 class="ellipsis accent import-title">
                        {{ importState.creation_date | date: 'dd-MM-yy' }} {{ importState.file_name }}
                    </h1>
                    <div class="ellipsis accent" data-flex> {{ importState.processed_records }}/{{ importState.total_records }} - {{ importState.failed_records }} failed </div>
                    <button mat-button color="accent" *ngIf="importState.skipFile" (click)="getSkipFile(importState)">
                        Fix import
                    </button>
                    <mat-icon [matMenuTriggerFor]="menu" fontSet="salesflare" fontIcon="sf-icon-menu-small-vertical" class="icon-menu">
                    </mat-icon>
                    <mat-menu #menu>
                        <button mat-menu-item (click)="removeImport(importState, idx)" [disabled]="this.modelService.me.role.permissions.ced === 'NONE'">
                            <div data-layout="row" data-layout-align="start center">
                                <mat-icon fontSet="salesflare" fontIcon="sf-icon-delete" class="icon-22 mat-mdc-menu-item-accent"></mat-icon>
                                <p class="mat-mdc-menu-item-accent" data-flex>Delete import</p>
                            </div>
                        </button>
                    </mat-menu>
                </mat-list-item>
            </mat-list>
        </sfx-section>
    </ng-container>
</sfx-content>


