import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[navigationToolbarButton]'
})
export class NavigationToolbarButtonDirective {

    @Input() 'track-id': string;
    @Input() 'aria-label': string;

    @Input() ref!: string;
    @Input() icon!: string;
    @Input() label!: string;

    public innerContent: any;
    constructor(private element: ElementRef) {}

    ngAfterContentInit(): void {

        this.innerContent = this.element.nativeElement.innerHTML;
    }
}
