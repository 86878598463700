import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MediaService } from '@core/services/media.service';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, filter } from 'rxjs';

@Component({
    selector: 'sfx-targets',
    templateUrl: './targets.component.html',
    styleUrls: ['./targets.component.scss']
})
export class TargetsComponent implements OnInit {
    @ViewChild('targetsForm') targetsForm!: FormControl;
    targetChanged = new Subject<void>();
    today: any = new Date();
    currentYear: any = this.today.getFullYear();
    currentPipeline: any = null;

    loadingState = false;

    targets: any = [];

    years: number[] = [
        this.currentYear - 2,
        this.currentYear - 1,
        this.currentYear,
        this.currentYear + 1,
        this.currentYear + 2
    ];

    pipelines: any = null;

    constructor(
        private cdRef: ChangeDetectorRef,
        @Inject('modelService') private modelService: any,
        @Inject('pipelinesService') private pipelinesService: any,
        @Inject('targetService') private targetService: any,
        public media: MediaService
    ) {}


    ngOnInit(): void {
        this.targetChanged
            .pipe(
                filter(() => {
                    return this.targetsForm.valid;
                }),
                debounceTime(1000)
            )
            .subscribe(() => {
                this.save();
            });

        return this.pipelinesService.get().then((response: any) => {

            this.pipelines = response.data;

            const currentPipelineId = this.modelService.getCurrentPipelineId();

            if (currentPipelineId) {

                this.currentPipeline = this.pipelines.find((pipeline: any) => {
                    return pipeline.id === currentPipelineId;
                });
            }

            if (!this.currentPipeline) {
                this.currentPipeline = this.pipelines[0];
            }

            this.getTargetsForYearAndPipeline();
        });
    }

    public getTargetsForYearAndPipeline = (): any => {

        return this.targetService.getTargets(this.currentYear, this.currentPipeline.id).then((response: any) => {
            this.targets = response.data;
        });
    }

    public save = (): any => {

        const targetsToBeSaved = this.targets.map((target: any) => {

            return {
                user: target.id,
                target: target.target,
                pipeline: this.currentPipeline?.id
            };
        });

        return this.targetService.createTargets(this.currentYear, targetsToBeSaved);
    }

    public targetChange = (): any => {

        this.targetChanged.next();
    }
}
