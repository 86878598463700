const { Carousel } = require('../utils/Carousel');

(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('AccountController', AccountController);

    function AccountController($rootScope, $scope, $state, $stateParams, $mdSidenav, config, events, account, sfWalkthrough) {

        $scope.account_id = $stateParams.id;
        $scope.config = config;
        $scope.mode = config.mode;
        $scope.showNoAccessEmptyState = false;
        $scope.account = null;
        $scope.accounts = null;
        $scope.accountCarousel = null;


        if ($scope.selected) {

            // Because you need the ID in the parent scope you make an empty selected object in the parent and then set an ID to it in the child so it's accessible in the parent to set the active class to the selected item
            $scope.selected.id = $scope.account_id;
        }

        if ($stateParams.accounts) {
            $scope.accounts = angular.fromJson($stateParams.accounts);

            for (let i = 0; i < $scope.accounts.length; ++i) {
                if ($scope.accounts[i].id === $scope.account_id) {
                    $scope.accountCarousel = new Carousel($scope.accounts, i);
                }
            }
        }

        $scope.back = function () {

            if ($rootScope.history[$rootScope.history.length - 1].fromState.data.shouldBeDialogInFullscreen) {
                return $state.go('accounts');
            }

            return $rootScope.back();
        };

        $scope.toggleRight = function () {

            return $mdSidenav('right').toggle();
        };

        $scope.prev = function () {

            const previousAccount = $scope.accountCarousel.previous;

            return $state.go(getInfoState(previousAccount), {
                id: previousAccount.id,
                accounts: angular.toJson($scope.accounts),
                name: previousAccount.name,
                privateCompanyInfo: $stateParams.privateCompanyInfo
            });
        };

        $scope.next = function () {

            const nextAccount = $scope.accountCarousel.next;

            return $state.go(getInfoState(nextAccount), {
                id: nextAccount.id,
                accounts: angular.toJson($scope.accounts),
                name: nextAccount.name,
                privateCompanyInfo: $stateParams.privateCompanyInfo
            });
        };

        // eslint-disable-next-line no-shadow
        function getInfoState(account) {

            return account.owner ? 'accounts.account.info' : 'accounts.company.info';
        }

        // Set on scope to allow reload from child scopes
        $scope.get = function () {

            // Only perform the GET when the account_id is defined (fixes getting an error when rapidly clicking away from accounts screen before it is loaded, see #860)
            if ($scope.account_id) {
                return account.get($scope.account_id, { noToast: true, noToastForStatusCode: [404] }).then(accountResponse, accountErrorResponse, accountResponse);
            }
        };

        $scope.hideElement = sfWalkthrough.hideElement;

        $scope.isWalkthroughShowing = sfWalkthrough.isShowing;

        /**
         * When `account.info` is a child of `account` you can just access it via `$scope.account`
         * Since this is not always the case (fullscreen) we also broadcast a `loaded` event
         * so non-childs that need the account can update their local `$scope.account`
         *
         * Attaching the network later is fine since we are passing by reference
         *
         * @param {{ data: Object }} response
         */
        function accountResponse(response) {

            $scope.account = response.data;

            // Reset empty state
            $scope.showNoAccessEmptyState = false;
            $scope.name = undefined;

            $rootScope.$broadcast(events.account.loaded, $scope.account);
        }

        function accountErrorResponse(response) {

            if (response.status === 404 && response.data.message === 'You have no access') {
                $scope.showNoAccessEmptyState = true;

                if ($stateParams.name) {
                    let noAccessEmptyStateText = 'You have no access to the ' + $stateParams.name + ' account';

                    if ($scope.accounts) {
                        const foundAccount = $scope.accounts.find((acc) => acc.id === $scope.account_id);

                        if (foundAccount && foundAccount.linked_contacts && foundAccount.linked_contacts.length > 0) {
                            const contacts = angular.isArray(foundAccount.linked_contacts) ? foundAccount.linked_contacts : [foundAccount.linked_contacts];
                            const contactNames = contacts.map((c) => c.firstname || c.name).join(', ').replace(/, ([^,]*)$/, ' and $1');
                            noAccessEmptyStateText += (' ' + contactNames + (contacts.length === 1 ? ' is ' : ' are ') + 'part of');
                        }
                    }

                    noAccessEmptyStateText += '. Ask an admin.';

                    $scope.noAccessEmptyStateText = noAccessEmptyStateText;
                    $scope.name = $stateParams.name;
                }
                else {
                    $scope.noAccessEmptyStateText = 'You have no access to this account. Ask an admin.';
                    $scope.name = undefined;
                }
            }
        }

        $scope.get();
    }
})();
