import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EnsureModuleLoadedOnceGuard } from './ensureModuleLoadedOnce.guard';

import { IconService } from './services/icon.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { ClipboardNotificationService } from '@core/services/clipboard-notifications.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationService } from '@core/services/notifications.service';

@NgModule({
    imports:
        [
            CommonModule,
            HttpClientModule,
            MatSnackBarModule
        ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        super(parentModule);
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                IconService,
                {
                    provide: APP_INITIALIZER,
                    useFactory: (IconSrv: IconService, ClipboardNotifSrv: ClipboardNotificationService) => {
                        IconSrv.registerIcons();
                        ClipboardNotifSrv.registerClipboardNotifications();
                    },
                    deps: [IconService, ClipboardNotificationService, HttpClient, MatSnackBar]
                },
                NotificationService
            ]
        };
    }
}
