(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ViewImportedEntitiesDialogController', ViewImportedEntitiesDialogController);

    function ViewImportedEntitiesDialogController($scope, $mdDialog, $timeout, $mdMedia, imports) {

        const importId = this.importId;
        const deleteUpdated = this.deleteUpdated;
        let allImportedEntities = [];
        let thread = null;

        $scope.$mdMedia = $mdMedia;
        $scope.entityName = this.entityName;
        $scope.capitalizedEntityName = this.entityName.charAt(0).toUpperCase() + this.entityName.slice(1);
        $scope.emptyStateText = 'No ' + this.entityName + ' associated with this import found.';
        $scope.emptyStateSearchText = 'No ' + this.entityName + ' found.';
        $scope.search = '';
        $scope.importedEntities = [];

        imports.getImportedEntities(importId, deleteUpdated).then(function (response) {

            $scope.importedEntities = response.data;
            allImportedEntities = response.data;

            $scope.doneLoading = true;
        });

        $scope.$watch('search', function (newValue, oldValue) {

            if (newValue !== oldValue) {
                $timeout.cancel(thread);

                thread = $timeout(function () {

                    if (!$scope.search) {
                        $scope.importedEntities = allImportedEntities;
                        return;
                    }

                    $scope.importedEntities = allImportedEntities.filter(function (importedEntity) {

                        const lowerCasedSearch = $scope.search.toLowerCase();

                        return (importedEntity.name && importedEntity.name.toLowerCase().includes(lowerCasedSearch))
                            || (importedEntity.domain && importedEntity.domain.toLowerCase().includes(lowerCasedSearch))
                            || (importedEntity.account_name && importedEntity.account_name.toLowerCase().includes(lowerCasedSearch))
                            || (importedEntity.email && importedEntity.email.toLowerCase().includes(lowerCasedSearch));
                    });
                }, 750);
            }
        });

        $scope.close = $mdDialog.cancel;
    }
})();
