(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ReportFilterDialogController', ReportFilterDialogController);

    function ReportFilterDialogController($mdMedia, $mdDialog) {

        const vm = this;
        vm.$mdMedia = $mdMedia;

        vm.onEntityFilterChanged = function ($event) {

            vm.filterRules = $event.rules;
        };

        vm.save = function () {

            return $mdDialog.hide({
                condition: 'AND',
                rules: vm.filterRules
            });
        };

        vm.cancel = $mdDialog.cancel;
    }
})();
