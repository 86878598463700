(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('flagsService', flagsService);

    function flagsService(sfHttp, config) {

        let flags = [];

        /**
         *
         * @param {Object[]} modelFlags
         * @param {Number} modelFlags.id
         * @param {String} modelFlags.name
         * @param {String} modelFlags.state
         * @returns {void}
         */
        this.init = function (modelFlags) {

            flags = modelFlags;
        };

        /**
         *
         * @param {String} flagName
         * @returns {String | undefined}
         */
        this.get = function (flagName) {

            return findFlag(flagName);
        };

        function findFlag(flagName) {

            const flag = flags.find(function (f) {

                return f.name === flagName;
            });

            return flag ? flag.state : undefined;
        }

        /**
         *
         * @param {String} flagName
         * @param {String} newState
         * @returns {Promise}
         */
        this.set = function (flagName, newState) {

            const foundFlag = flags.find(function (flag) {

                return flag.name === flagName;
            });

            if (!foundFlag) {
                throw new Error(flagName + ' is not a supported flag, create the flag first.');
            }

            if (foundFlag.plan) {
                throw new Error('Can\'t update plan flags.');
            }

            foundFlag.state = newState;

            return sfHttp.put(config.apiUrl + 'flags/' + foundFlag.name, { state: newState });
        };
    }
})();
