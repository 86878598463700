(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('BillingSettingsHistoryController', BillingSettingsHistoryController);

    function BillingSettingsHistoryController($rootScope, $scope, $filter, billing, utils) {

        if ($rootScope.history[$rootScope.history.length - 1].fromState.name.includes('billingSettings.')) {
            $rootScope.history.pop();
        }

        const typeMap = {
            'credited': 'Credited',
            'debited': 'Debited balance by',
            'charge': 'Charged'
        };

        let lastInvoiceProcessedUnixTimestamp = null;

        // Virtual repeat + infinite scroll object that angular material uses
        $scope.invoices = {
            toLoad: 0,
            numLoaded: 0,
            items: [],
            lastInvoiceProcessed: 0,
            hasMore: true,

            getItemAtIndex: function (index) {

                if (index > this.numLoaded && this.hasMore) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                if (this.hasMore) {
                    return this.numLoaded + 30;
                }

                return this.numLoaded;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad < index) {
                    this.toLoad += 30;

                    const bound = angular.bind(this, billingHistoryResponse);

                    if (this.items.length === 0) {
                        billing.getBillingHistory().then(bound, null, bound);
                    }
                    else {
                        billing.getBillingHistory(lastInvoiceProcessedUnixTimestamp).then(angular.bind(this, function (response) {

                            this.items = [...this.items, ...response.data];
                            lastInvoiceProcessedUnixTimestamp = response.data.last_invoice_processed_timestamp;
                            this.hasMore = response.data.has_more;
                            this.numLoaded = this.toLoad;
                        }));
                    }
                }
            },

            // Forces reset of object
            reload: function () {

                this.toLoad = 0;
                this.numLoaded = 0;
                this.lastInvoiceProcessed = 0;
                this.hasMore = true;
                this.items = [];
            }
        };

        $scope.isSameMonth = function (dt1, dt2) {

            return utils.isSameMonth(dt1, dt2);
        };

        function billingHistoryResponse(response) {

            lastInvoiceProcessedUnixTimestamp = response.data.last_invoice_processed_timestamp;
            this.hasMore = response.data.has_more;

            if (response.data.lines) {
                this.items = response.data.lines.map(function (invoice) {

                    if (invoice.description === 'Monthly accrued balance' && invoice.type === 'credited') {
                        return;
                    }

                    return {
                        type: invoice.type,
                        chargeLine: typeMap[invoice.type],
                        unixTime: invoice.date,
                        date: $filter('date')(new Date(invoice.date * 1000), 'yyyy-MM-dd'),
                        total: invoice.total / 100,
                        description: (invoice.description && invoice.description.split(' | VAT')[0].split('\n')[0]) || 'No description available',
                        currency: invoice.currency === 'eur' ? '&#128' : '&#36;',
                        invoice_pdf: invoice.invoice_pdf
                    };
                });
            }

            this.numLoaded = this.items.length;
        }
    }
})();
