(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('SkipFilePromptDialogController', SkipFilePromptDialogController);

    function SkipFilePromptDialogController($scope, $mdDialog, imports) {

        const self = this;

        $scope.download = function () {

            $mdDialog.hide();

            return imports.getSkipFile(self.importId);
        };

        $scope.cancel = function () {

            $mdDialog.hide();
        };
    }
})();
