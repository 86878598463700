(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('JoinExistingTeamDialogController', JoinExistingTeamDialogController);

    function JoinExistingTeamDialogController($mdDialog) {

        const vm = this;

        vm.$onInit = () => {

            let headerText = '';

            if (vm.relatedTeams.length > 1) {
                headerText += 'There are multiple Salesflare teams for your company';
            }
            else {
                headerText += 'There\'s already a Salesflare team for your company';
            }

            vm.headerText = headerText;

            vm.relatedTeams.forEach((relatedTeam) => {

                relatedTeam.adminNames = relatedTeam.admins.map((admin) => admin.name).join(', ').replace(/, ([^,]*)$/, ' and $1');
            });
        };

        vm.joinTeam = (id) => {

            return $mdDialog.hide(id);
        };

        vm.close = $mdDialog.hide;
    }
})();
