(function () {
    'use strict';

    /*
     * {validateForm} is the form to be checked for errors
     */
    angular
        .module('salesflare.components.formErrorIndicator', [])
        .component('sfFormErrorIndicator', {
            templateUrl: 'app-ajs/components/formerrorindicator/formerrorindicator.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                validateForm: '<', // Make sure your form contains the novalidate attribute!
                customMessage: '<'
            }
        });

    function controller($scope, $document) {

        const vm = this;

        vm.$onChanges = function (changes) {

            if (!changes.validateForm || !changes.validateForm.currentValue) {
                return;
            }

            vm.correctedAllErrorsSinceLastSubmit = false;

            const formElement = $document[0].getElementsByName(vm.validateForm.$name)[0];

            formElement.addEventListener('submit', function () {

                if (vm.validateForm.$invalid) {

                    // Use apply since this event is triggered outside of angular
                    $scope.$apply(function () {

                        vm.correctedAllErrorsSinceLastSubmit = false;

                        return checkErrors();
                    });
                }
            });

            $scope.$watch('validateForm.$submitted', function (newValue) {

                if (!newValue) {
                    return;
                }

                if (vm.validateForm.$submitted && !vm.correctedAllErrorsSinceLastSubmit && !vm.validateForm.$invalid) {
                    vm.correctedAllErrorsSinceLastSubmit = true;
                }
            });
        };

        function checkErrors() {

            if (angular.isUndefined(vm.validateForm)) {
                return;
            }

            if (angular.isUndefined(vm.validateForm.$error) || Object.keys(vm.validateForm.$error).length === 0) {
                if (vm.validateForm.$submitted) {
                    vm.correctedAllErrorsSinceLastSubmit = true;
                }

                return;
            }

            let errorMessage = '';
            const labels = [];
            let inputWithoutNameFound = false;

            for (const key in vm.validateForm.$error) {
                for (let i = 0; i < vm.validateForm.$error[key].length; ++i) {
                    const inputName = vm.validateForm.$error[key][i].$name;

                    //Check for nested forms first
                    const input = vm.validateForm.$error[key][i];
                    if (input.$submitted === true || input.$submitted === false) {
                        const nestedLabels = checkNestedErrors(input);
                        if (!angular.isArray(nestedLabels) || nestedLabels.length === 0) {
                            inputWithoutNameFound = true;
                        }
                        else {
                            for (const nestedLabel of nestedLabels) {
                                if (!labels.includes(nestedLabel)) {
                                    errorMessage += nestedLabel.toLowerCase() + ', ';
                                    labels.push(nestedLabel);
                                }
                            }

                            continue;
                        }
                    }

                    // We fetch the node based on the name of the input therefor the name should always be specified on each input of a form where the error indicator is used on
                    // We show a very generic error message when a name of an input is not specified to prevent the error indicator from showing weird stuff
                    if (!inputName) {
                        inputWithoutNameFound = true;
                    }

                    if (inputWithoutNameFound) {
                        break;
                    }

                    const inputNode = $document[0].getElementsByName(inputName)[0];
                    const inputContainer = inputNode.closest('md-input-container');

                    // Probably a nested form
                    if (!inputContainer) {
                        inputWithoutNameFound = true;
                        break;
                    }

                    let labelNode = inputContainer.querySelectorAll('label')[0];

                    if (!labelNode) {
                        labelNode = inputContainer.querySelectorAll('.label')[0];
                    }

                    let label = '';

                    if (labelNode) {
                        label = labelNode.innerHTML;
                    }
                    else {
                        label = inputNode.getAttribute('placeholder');
                    }

                    label = label.replace(' *', '');

                    // We do not want to add the label twice to the error message if the related input field contains multiple errors
                    if (!labels.includes(label)) {
                        errorMessage += label.toLowerCase() + ', ';
                        labels.push(label);
                    }
                }
            }

            if (inputWithoutNameFound) {
                errorMessage = 'Please check the form for invalid fields.';
            }
            else {
                // Trim trailing ', '
                errorMessage = errorMessage.slice(0, -2);
                errorMessage += '.';

                if (labels.length > 1) {
                    const indexOfLastComma = errorMessage.lastIndexOf(',');
                    errorMessage = errorMessage.slice(0, Math.max(0, indexOfLastComma)) + ' and' + errorMessage.slice(Math.max(0, indexOfLastComma + 1));
                    errorMessage = 'Check the following fields: ' + errorMessage;
                }
                else {
                    errorMessage = 'Check the following field: ' + errorMessage;
                }
            }

            if (vm.customMessage) {
                errorMessage = errorMessage + '<br>' + vm.customMessage;
            }

            vm.errorMessage = errorMessage;
        }

        function checkNestedErrors(nestedForm) {

            if (angular.isUndefined(nestedForm)) {
                return;
            }

            if (angular.isUndefined(nestedForm.$error) || Object.keys(nestedForm.$error).length === 0) {
                if (nestedForm.$submitted) {
                    vm.correctedAllErrorsSinceLastSubmit = true;
                }

                return;
            }

            const labels = [];
            let inputWithoutNameFound = false;

            for (const key in nestedForm.$error) {
                for (let i = 0; i < nestedForm.$error[key].length; ++i) {
                    const inputName = nestedForm.$error[key][i].$name;

                    //Check for nested forms first
                    const input = nestedForm.$error[key][i];
                    if (input.$submitted === true || input.$submitted === false) {
                        const nestedLabels = checkNestedErrors(input);
                        if (!angular.isArray(nestedLabels) || nestedLabels.length === 0) {
                            inputWithoutNameFound = true;
                        }
                        else {
                            for (const nestedLabel of nestedLabels) {
                                if (!labels.includes(nestedLabel)) {
                                    labels.push(nestedLabel);
                                }
                            }

                            continue;
                        }
                    }

                    // We fetch the node based on the name of the input therefor the name should always be specified on each input of a form where the error indicator is used on
                    // We show a very generic error message when a name of an input is not specified to prevent the error indicator from showing weird stuff
                    if (!inputName) {
                        inputWithoutNameFound = true;
                    }

                    if (inputWithoutNameFound) {
                        break;
                    }

                    const inputNode = $document[0].getElementsByName(inputName)[0];
                    const inputContainer = inputNode.closest('md-input-container');

                    // Probably a nested form
                    if (!inputContainer) {
                        inputWithoutNameFound = true;
                        break;
                    }

                    let labelNode = inputContainer.querySelectorAll('label')[0];

                    if (!labelNode) {
                        labelNode = inputContainer.querySelectorAll('.label')[0];
                    }

                    let label = '';

                    if (labelNode) {
                        label = labelNode.innerHTML;
                    }
                    else {
                        label = inputNode.getAttribute('placeholder');
                    }

                    label = label.replace(' *', '');

                    // We do not want to add the label twice to the error message if the related input field contains multiple errors
                    if (!labels.includes(label)) {
                        labels.push(label);
                    }
                }
            }

            return labels;
        }
    }
})();
