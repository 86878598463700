(function () {
    'use strict';

    angular
        .module('salesflare.components.plans', [])
        .component('sfPlans', {
            templateUrl: 'app-ajs/components/plans/plans.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                fromBilling: '<'
            }
        });

    function controller($state, $stateParams, $mdMedia, $mdDialog, sfDowngradeConfirmationDialog, sfUpgradeConfirmationDialog, model, plansService) { // Authentication, config, billing, team, utils

        const vm = this;

        let showTooltip = false;

        vm.$mdMedia = $mdMedia;
        vm.$state = $state;
        vm.plans = [];
        vm.buttonText = {};
        vm.currentPlanId = 0;
        vm.currency = '';
        vm.subscribedOrNonRestrictedSumo = model.me.team.subscribed || (model.me.team.discount_type === 'firstFreeThen50Off' && !model.me.restricted);
        vm.discount = model.me.team.discount_code;

        vm.$onInit = function () {

            vm.backToBilling = angular.isDefined(vm.fromBilling) ? vm.fromBilling : $stateParams.fromBilling;

            init();
        };

        vm.showTooltip = function (show) {

            if (angular.isUndefined(show)) {
                return showTooltip;
            }

            showTooltip = show;
        };

        vm.changePlan = function (newPlan) {

            let action;

            if (!(vm.subscribedOrNonRestrictedSumo)) {
                action = 'subscribe';
            }
            else if (vm.currentPlanId < newPlan.id) {
                action = 'upgrade';
            }
            else if (vm.currentPlanId > newPlan.id) {
                action = 'downgrade';
            }

            if (model.me.role.name !== 'Admin') {
                const adminAlert = $mdDialog.alert()
                    .title('Please ask an admin to ' + action)
                    .htmlContent('<div>You don\'t have the permissions to ' + action + '.</div><div>Please ask a team admin to ' + action + ' to your desired plan.</div>')
                    .multiple(true)
                    .ok('Got it');

                return $mdDialog.show(adminAlert);
            }

            if (action === 'subscribe') {
                // If we didn't come from the billing page, we want the tab closed when subscribing is finished.
                // Otherwise we end up with 2 tabs of the app, since checkout normally returns to the billing overview in the settings.
                return vm.$state.go('checkout', { plan: newPlan.name.toLowerCase(), closeTabOnFinish: !vm.backToBilling });
            }

            if (action === 'upgrade') {
                return sfUpgradeConfirmationDialog.show(newPlan).then(function () {

                    if (vm.backToBilling) {
                        return vm.$state.go('settings.billingSettings.overview');
                    }

                    init();
                });
            }

            if (action === 'downgrade') {
                let currentPlan;

                for (const plan in vm.plans) {
                    if (vm.plans[plan].id === vm.currentPlanId) {
                        currentPlan = vm.plans[plan];
                    }
                }

                const currentPlanName = currentPlan ? currentPlan.name : '';

                return sfDowngradeConfirmationDialog.show(currentPlanName, newPlan.id, vm.currentPlanId, newPlan.name).then(function (downgraded) {

                    if (downgraded) {
                        prepareButtonText();
                        $mdDialog.hide();
                        // If we came from the billing page, we didn't open the plans page in a new tab, so move back to the billing overview
                        if (vm.backToBilling) {
                            return vm.$state.go('settings.billingSettings.overview');
                        }
                    }

                    init();
                });
            }
        };

        function init() {

            vm.plans = plansService.get();
            vm.currentPlanId = model.me.team.plan ? model.me.team.plan : 0;
            prepareFeatureNames();
            prepareButtonText();
            prepareCurrency();
        }

        // This is a bit of a hacky fix to the problem that info icons would break to a new line on their own
        // We try to place the last word of the feature names together with the info icon in a whitespace: no-wrap span element
        // To do this, we need to get the last word of a feature in a separate property
        function prepareFeatureNames() {

            for (const planName in vm.plans) {
                const plan = vm.plans[planName];
                plan.features = plan.features.map(function (feature) {

                    if (!feature.name) {
                        return feature;
                    }

                    const n = feature.name.split(' ');
                    feature.lastWordName = n[n.length - 1];
                    return feature;
                });
            }
        }

        function prepareButtonText() {
            // The correct button needs to be disabled, also after down or upgrading
            vm.currentPlanId = model.me.team.plan;
            for (const planName in vm.plans) {
                const plan = vm.plans[planName];
                vm.buttonText[planName] = getButtonText(plan);
            }
        }

        function getButtonText(plan) {

            if (!vm.subscribedOrNonRestrictedSumo) {
                return 'Subscribe to ' + plan.name;
            }

            if (model.me.team.plan < plan.id) {
                return 'Upgrade to ' + plan.name;
            }

            if (model.me.team.plan > plan.id) {
                return 'Downgrade to ' + plan.name;
            }

            return 'Currently on ' + plan.name;
        }

        function prepareCurrency() {

            // Only show euro if it's the team currency else dollar
            if (model.me.team.billing_currency && model.me.team.billing_currency.html === '&#128;') {
                // Try to get the user location
                vm.currency = model.me.team.billing_currency.html;
            }
            else {
                vm.currency = '&#36;';
            }
        }
    }
})();
