import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'sfx-authentication-code-dialog',
    templateUrl: './authentication-code-dialog.component.html',
    styleUrls: ['./authentication-code-dialog.component.scss']
})
export class SfxAuthenticationCodeDialog {
    @ViewChild('authenticationCodeForm') authenticationCodeForm!: FormGroup;
    authenticationCode = '';
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { email: string, password: string, captchaToken: string },
        @Inject('authenticationService') private authenticationService: any,
        public dialogRef: MatDialogRef<SfxAuthenticationCodeDialog>
    ) {
        dialogRef.updateSize('360px');
    }

    public verify = () => {

        if (this.authenticationCodeForm?.invalid) {
            return;
        }

        return this.authenticationService.logIn(this.data.email, this.data.password, this.data.captchaToken, this.authenticationCode).then((response: any) => {

            return this.dialogRef.close({ token: response.data.token });
        }).catch(() => {

            this.authenticationCodeForm?.controls.authenticationCode.setErrors({ 'invalidAuthenticationCode': true });
        });
    };

    public close = (data?: any) => {

        this.dialogRef.close(data);
    };
}
