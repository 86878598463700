(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('templatesService', templatesService);

    function templatesService(sfHttp, config) {

        this.get = function (params, options) {

            const url = config.apiUrl + 'templates';

            // Use a head call to only receive the count
            if (params && params.countOnly && params.includeCount) {
                return sfHttp.head(url);
            }

            const request = {
                params
            };

            if (options && options.ignoreLoadingBar) {
                request.ignoreLoadingBar = true;
            }

            return sfHttp.get(url, request);
        };

        this.create = function (template) {

            return sfHttp.post(config.apiUrl + 'templates', template);
        };
    }
})();
