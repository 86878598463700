import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sfx-plan-flag',
    templateUrl: './plan-flag.component.html',
    styleUrls: ['./plan-flag.component.scss']
})
export class PlanFlagComponent implements OnInit {

    @Input() flag = '';
    @Input() inUpgradeDialog = '';
    plan = '';
    tooltipDescription = '';

    constructor(
        @Inject('upgradeDialogService') private upgradeDialogService: any
    ) {}

    ngOnInit(): void {

        const tooltipDescriptions: any = {
            multiStepWorkflows: 'Want to send multi-step email workflows?',
            permissions: 'Want to set permissions?',
            dashboards: 'Want to create custom reports and dashboards?'
        };

        if (!this.inUpgradeDialog) {
            this.tooltipDescription = tooltipDescriptions[this.flag];
        }

        this.plan = 'Pro'; // Currently you can only upgrade to the Pro plan
    }

    public showUpgradeDialogToTrial = ($event: any) => {

        // Prevent ng-clicks on parent elements from happening
        $event.stopPropagation();
        if (!this.inUpgradeDialog) {
            return this.upgradeDialogService.show(this.flag || 'multiStepWorkflows');
        }
    }
}
