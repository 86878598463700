(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('apikey', apiKeyService);

    function apiKeyService(sfHttp, config) {

        this.update = function (apiKey) {

            return sfHttp.put(config.apiUrl + 'apikeys/' + apiKey.id, apiKey);
        };

        this.create = function (apiKey) {

            return sfHttp.post(config.apiUrl + 'apikeys', apiKey);
        };

        this.delete = function (apiKey) {

            return sfHttp.delete(config.apiUrl + 'apikeys/' + apiKey.id);
        };
    }
})();
