(function () {
    'use strict';

    angular
        .module('salesflare.components.tasks.create', [])
        .component('sfTaskCreate', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/tasks/create/create.html',
            bindings: {
                onAddTask: '&', // Hook triggers after adding a task
                account: '<', // When account is provided, use as default and don't display account in view
                taskOriginal: '<',
                useMobileView: '<'
            }
        });

    function controller($q, $timeout, $mdMedia, $document, $mdDialog, $mdPanel, $window, $state, tasks, account, accounts, task, users, utils, model) {

        const vm = this;
        let input;

        vm.$onInit = function () {

            vm.$mdMedia = $mdMedia;
            vm.focused = false;
            vm.placeholder = 'Add a task...';
            vm.doneLoading = false;

            if (vm.taskOriginal && vm.taskOriginal.account && !Object.prototype.hasOwnProperty.call(vm.taskOriginal.account, 'can_edit')) {
                return account.get(vm.taskOriginal.account.id, { noToast: true, noToastForStatusCode: [404] }).then(function (response) {

                    vm.taskOriginal.account.can_edit = response.data.can_edit;

                    return init();
                }).catch(function (err) {

                    if (err.status === 404) {
                        vm.taskOriginal.account.can_edit = false;
                        return init();
                    }
                });
            }

            return init();
        };

        vm.$postLink = function () {

            $timeout(function () {

                let descriptionSelector = '.js-description';
                if (vm.task && vm.task.id) {
                    descriptionSelector += '-' + vm.task.id;
                }

                input = angular.element('sf-task-create ' + descriptionSelector);
            }, 0);
        };

        vm.isUpdate = function () {

            return vm.task && !!vm.task.id;
        };

        vm.saveTask = function () {

            if (!vm.task.description) {
                return;
            }

            if (!vm.task.reminder_date) {
                vm.task.reminder_date = new Date();
            }

            const updateTask = {
                description: vm.task.description,
                reminder_date: vm.task.reminder_date
            };

            if (vm.task.account) {
                updateTask.account = vm.task.account.id;
            }

            // Delete the account
            if (vm.task.account === null) {
                updateTask.account = null;
            }

            if (angular.isDefined(vm.task.assignees) && vm.task.assignees.length > 0) {
                updateTask.assignees = vm.task.assignees.map(function (assignee) {

                    return assignee.id;
                });
            }

            if (vm.task.id) {
                // Check if all assignees are in the account if there is one
                if (updateTask.account && updateTask.assignees && updateTask.assignees.length > 0) {

                    return checkIfAssigneesInAccount(updateTask, vm.task.assignees, vm.task.account).then(function () {

                        return task.update(vm.task.id, updateTask).then(function () {

                            vm.task.editMode = false;

                            // Trigger hook
                            return vm.onAddTask({
                                $event: {
                                    task: vm.task
                                }
                            });
                        });
                    });
                }
                else {
                    return task.update(vm.task.id, updateTask).then(function () {

                        vm.task.editMode = false;

                        // Trigger hook
                        return vm.onAddTask({
                            $event: {
                                task: vm.task
                            }
                        });
                    });
                }
            }


            const isUpcomingTask = (new Date().setHours(23, 59, 59, 999) < vm.task.reminder_date);

            // Check if assignees are within account
            if (updateTask.account && updateTask.assignees && updateTask.assignees.length > 0) {

                return checkIfAssigneesInAccount(updateTask, vm.task.assignees, vm.task.account).then(function () {

                    return tasks.create(updateTask).then(function (response) {

                        vm.task.id = response.data.id;
                        vm.task.type = 'manual_task';

                        // Trigger hook
                        vm.onAddTask({
                            $event: {
                                task: vm.task
                            }
                        });

                        reset();

                        if (isUpcomingTask && $state.current.name.includes('today')) {
                            return utils.showSuccessToast('Upcoming task created.', 'VIEW').then(function (toastResponse) {

                                if (toastResponse === 'VIEW') {
                                    return $state.go('tasks.upcoming');
                                }
                            });
                        }
                    });
                });
            }
            else {
                return tasks.create(updateTask).then(function (response) {

                    vm.task.id = response.data.id;
                    vm.task.type = 'manual_task';
                    vm.task.assignees.push(model.me);

                    // Trigger hook
                    vm.onAddTask({
                        $event: {
                            task: vm.task
                        }
                    });

                    reset();

                    if (isUpcomingTask && $state.current.name.includes('today')) {
                        return utils.showSuccessToast('Upcoming task created.', 'VIEW').then(function (toastResponse) {

                            if (toastResponse === 'VIEW') {
                                return $state.go('tasks.upcoming');
                            }
                        });
                    }
                });
            }
        };

        vm.searchAccounts = function searchAccounts(query) {

            return accounts.get(query).then(accountsResponse, null, accountsResponse);
        };

        vm.snooze = function snooze(event) {

            // eslint-disable-next-line unicorn/prefer-query-selector
            const elements = $document[0].getElementsByClassName('snooze-button' + (vm.task.id ? '-' + vm.task.id : ''));
            const rect = elements[0].getBoundingClientRect();

            const right = $window.innerWidth - rect.right;
            let top = rect.top;
            const left = 10;

            if (($window.innerHeight - top) < 250) {
                top = top - 250;
            }

            // We can't use relative positioning here
            // The virtual repeat used in the date picker would ruin the positioning of the panel

            let position = {};
            if (rect.right < 240) {
                position = $mdPanel.newPanelPosition()
                    .absolute()
                    .left(left + 'px')
                    .top(top + 'px');
            }
            else {
                position = $mdPanel.newPanelPosition()
                    .absolute()
                    .right(right + 'px')
                    .top(top + 'px');
            }

            return $mdPanel.open('reminderPanel', {
                openFrom: event,
                position,
                locals: {
                    reminder_date: vm.task.reminder_date,
                    isNew: !vm.task.id
                },
                onRemoving: function (panelRef) {
                    const pickedDateTime = panelRef.config.locals.reminder_date;

                    if (!pickedDateTime) {
                        return;
                    }

                    vm.task.reminder_date = pickedDateTime;
                }
            });
        };

        vm.focusDescription = function focusDescription() {

            // Be sure to do on next digest loop, angular throws issecdom errors
            return $timeout(function () {

                return input.focus();
            }, 0);
        };

        vm.saveTaskOnEnter = function saveTaskOnEnter($event) {

            if ($event.which === 13 && (!vm.task.account || vm.task.account.can_edit)) {
                return vm.saveTask();
            }
        };

        vm.onFocus = function onFocus() {

            vm.focused = true;
        };

        vm.onBlur = function onBlur() {

            vm.focused = false;
        };

        vm.assign = function assign(event) {

            const updateTask = angular.copy(vm.task);

            // eslint-disable-next-line unicorn/prefer-query-selector
            const elements = $document[0].getElementsByClassName('create-assign' + (vm.task.id ? '-' + vm.task.id : ''));
            const rect = elements[0].getBoundingClientRect();

            const right = $window.innerWidth - rect.right;
            let top = rect.top;
            const left = 10;

            if (($window.innerHeight - top) < 250) {
                top = top - 250;
            }

            let position = {};
            if (rect.right < 240) {
                position = $mdPanel.newPanelPosition()
                    .absolute()
                    .left(left + 'px')
                    .top(top + 'px');
            }
            else {
                position = $mdPanel.newPanelPosition()
                    .absolute()
                    .right(right + 'px')
                    .top(top + 'px');
            }

            let accountId = null;
            if (vm.task.account) {
                accountId = vm.task.account.id;
            }

            return $mdPanel.open({
                attachTo: angular.element($document[0].body),
                clickOutsideToClose: true,
                controller: 'AssignTaskPanelController',
                controllerAs: 'vm',
                templateUrl: 'partials/assigntaskpanel.html',
                openFrom: event,
                position,
                locals: {
                    task: updateTask,
                    users,
                    accountId,
                    editing: true
                },
                onRemoving: function () {

                    // The users after editing

                    let assignees = [];
                    if (updateTask.assignees) {
                        assignees = updateTask.assignees.filter(function (assignee) {
                            return angular.isDefined(assignee);
                        });
                    }

                    const assigneeIds = assignees.map(function (assignee) {
                        return assignee.id;
                    });

                    return updateTaskAndList(updateTask.id, assigneeIds, assignees);

                }
            });
        };

        function updateTaskAndList(id, assigneeIds, assignees) {

            vm.task.assignees = assignees;

            if (id) {
                return task.update(id, { assignees: assigneeIds }).then(function () {

                    vm.onAddTask({
                        $event: {
                            task: vm.task
                        }
                    });
                });
            }
        }


        function init() {

            if (vm.taskOriginal) {
                vm.task = angular.copy(vm.taskOriginal);
                vm.placeholder = 'Description';
            }

            if (!vm.task) {
                vm.task = {
                    description: undefined,
                    assignees: []
                };
            }

            if (vm.account) {
                vm.task.account = vm.account;
            }

            vm.searchText = undefined;
            vm.doneLoading = true;
        }

        function reset() {

            vm.task = undefined;
            vm.doneLoading = false;

            init();

            return vm.focusDescription();
        }

        function accountsResponse(response) {

            return $q.resolve(response.data);
        }

        function checkIfAssigneesInAccount(updateTask, assignees, accountFull) {

            return account.getUsers(updateTask.account).then(function (response) {

                // eslint-disable-next-line no-shadow
                const users = response.data;
                const userIds = new Set(users.map(function (user) {

                    return user.id;
                }));
                const notPartOfAccount = updateTask.assignees.filter(function (assignee) {

                    return !userIds.has(assignee);
                });

                // Multiple sequential dialogs might be possible when multiple users can be added to an account
                for (const newMemberIndex in notPartOfAccount) {
                    const newMemberId = notPartOfAccount[newMemberIndex];
                    const confirm = $mdDialog.confirm()
                        .clickOutsideToClose(true)
                        .escapeToClose(true)
                        .textContent('Do you want to add ' + assignees[updateTask.assignees.indexOf(newMemberId)].name + ' to the ' + accountFull.name + ' team as well?' )
                        .ok('Yes')
                        .cancel('No');

                    $mdDialog.show(confirm).then(function onConfirmDialogClosed() {

                        return onlyUpdateAccountUsers(assignees, newMemberId, updateTask);
                    });
                }
            });
        }

        function onlyUpdateAccountUsers(assignees, newMemberId, updateTask) {

            const usersToUpdate = assignees.filter(function (assignee) {

                if (assignee.id === newMemberId) {
                    assignee._dirty = true;
                }

                return assignee.id === newMemberId;
            });

            const accountId = updateTask.account.id ? updateTask.account.id : updateTask.account;

            if (angular.isNumber(accountId)) {
                return account.updateUsers(accountId, usersToUpdate);
            }
        }
    }
}());
