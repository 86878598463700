(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('AssignDialogController', AssignDialogController);

    function AssignDialogController($scope, $mdDialog, accountId, users, account) {

        getUsers();

        $scope.assign = function (user) {

            $mdDialog.hide(user);
        };

        $scope.$watch('searchText', function (newValue, oldValue) {

            if (newValue !== oldValue) {
                return getUsers();
            }
        });

        function getUsers() {

            function handler(response) {

                $scope.users = response.data;
            }

            if (!$scope.searchText || $scope.searchText === '') {
                account.getUsers(accountId).then(handler);
            }
            else {
                users.get($scope.searchText).then(handler);
            }
        }
    }
})();
