(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('templateService', templateService);

    function templateService(sfHttp, config) {

        this.get = function (id) {

            return sfHttp.get(config.apiUrl + 'templates/' + id);
        };

        this.update = function (id, template) {

            return sfHttp.put(config.apiUrl + 'templates/' + id, template);
        };

        this.delete = function (id) {

            return sfHttp.delete(config.apiUrl + 'templates/' + id);
        };
    }
})();
