(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('UpgradeConfirmationDialogController', UpgradeConfirmationDialogController);

    function UpgradeConfirmationDialogController($mdDialog, authentication, billing, utils) {

        const vm = this;
        vm.billingFrequency = '';

        init();

        vm.upgrade = function () {

            return billing.updatePlan(vm.upgradePlan.id).then(function () {

                const planUpgradedText = 'Congratulations! Your plan has been successfully upgraded to ' + vm.upgradePlan.name + '.';

                utils.showSuccessToast(planUpgradedText);

                authentication.fetchMe();
                return $mdDialog.hide(true);
            });
        };

        vm.close = function () {

            return $mdDialog.cancel();
        };

        function init() {

            billing.get().then(function (response) {


                if (response.data) {
                    vm.billingFrequency = response.data.billing_frequency;
                }
            });
        }
    }
})();
