(function () {
    'use strict';

    angular
        .module('salesflare')
        .config(function ($mdDialogProvider) {

            $mdDialogProvider.addPreset('saveFilterDialog', {
                options: function () {

                    return {
                        controllerAs: 'vm',
                        multiple: true,
                        clickOutsideToClose: true,
                        escapeToClose: true,
                        bindToController: true,
                        templateUrl: 'app-ajs/components/savefilterdialog/savefilterdialog.html',
                        controller: 'SaveFilterDialogController',
                        onShowing: function (scope, element) {

                            element.children('md-dialog').addClass('save-filter-dialog');
                        }
                    };
                }
            });
        });
})();
