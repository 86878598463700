import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';

import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';

import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { PricingSwitchComponent } from '@shared/components/pricing-switch/pricing-switch.component';
import { EditBillingFrequencyContentComponent } from '@feature/settings/components/billing-usage/dialogs/edit-billing-frequency-content.component';
import { UpgradePlanContentComponent } from '@feature/settings/components/billing-usage/dialogs/upgrade-plan-content.component';
import { DowngradePlanContentComponent } from '@feature/settings/components/billing-usage/dialogs/downgrade-plan-content.component';

import { BillingUsageComponent } from './billing-usage.component';
import { BillingUsageOverviewComponent } from './overview/overview.component';
import { BillingUsageInvoiceComponent } from './invoice/invoice.component';
import { BillingUsageHistoryComponent } from './history/history.component';

import { PlansComponent } from '@shared/components/plans/plans.component';
import { MaterialModule } from '@shared/bundles/material/material.module';
import { SfxLayoutModule } from '@shared/bundles/material/layout.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PlansComponent,
        PricingSwitchComponent,
        UIRouterUpgradeModule,
        SfxLayoutModule,
        FormsModule,
        CdkScrollableModule, ScrollingModule
    ],
    declarations: [
        EditBillingFrequencyContentComponent,
        UpgradePlanContentComponent,
        DowngradePlanContentComponent,
        BillingUsageComponent,
        BillingUsageOverviewComponent,
        BillingUsageInvoiceComponent,
        BillingUsageHistoryComponent
    ],
    providers: [
        DatePipe
    ]
})
export class BillingUsageModule {}
