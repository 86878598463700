(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('tips', tipsService);

    function tipsService($state, $timeout, utils, calendars) {

        const tips = {};
        const tipsTimers = {};

        this.show = function show(type) {

            if (!tips[type]) {
                throw new Error(type + ' is not a valid tip type to show');
            }

            return tips[type]();
        };

        this.cancel = function cancel(type) {

            if (!tipsTimers[type]) {
                throw new Error(type + ' is not a valid tip type to cancel');
            }

            return $timeout.cancel(tipsTimers[type]);
        };

        tips.calendar = function () {

            tipsTimers.calendar = $timeout(function () {

                const tipKey = 'TIP_CONNECT_CALENDAR';
                const tipLastShownKey = 'TIP_CONNECT_CALENDAR_LAST_SHOWN';

                const tipConnectCalendarTimesShown = store.get(tipKey);
                const tipConnectCalendarLastShown = store.get(tipLastShownKey);

                if ((!tipConnectCalendarLastShown || !utils.isSameDay(new Date(), new Date(tipConnectCalendarLastShown))) &&
                        (!tipConnectCalendarTimesShown || tipConnectCalendarTimesShown < 3)) {

                    return calendars.getAccounts(true).then(function (response) {

                        if (response.data.length > 0) {
                            return;
                        }

                        return utils.showInfoToast('TIP: Connect your calendar!', 'CONNECT').then(function (result) {

                            store.set(tipKey, (tipConnectCalendarTimesShown || 0 ) + 1);
                            store.set(tipLastShownKey, new Date());

                            if (result === 'CONNECT') {
                                return $state.go('settings.calendarSettings');
                            }
                        });
                    });
                }
            }, 1000);
        };
    }
})();
