(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('RequestPasswordController', RequestPasswordController);

    function RequestPasswordController($scope, password, utils) {

        $scope.email = null;

        $scope.request = function () {

            return password.request($scope.email).then(function () {

                const intercomContainer = angular.element('#intercom-container')[0];

                if (intercomContainer) {
                    intercomContainer.classList.add('visibility_hidden');
                }

                return $scope.back().then(function () {

                    return utils.showSuccessToast('Check your email for a link to reset your password').then(function () {

                        if (intercomContainer) {
                            intercomContainer.classList.remove('visibility_hidden');
                        }
                    });
                });
            });
        };
    }
})();
