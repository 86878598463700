(function () {
    'use strict';

    angular
        .module('salesflare.components.passwordmeter', [])
        .component('sfPasswordMeter', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/passwordmeter/passwordmeter.html',
            bindings: {
                passwordStrength: '<',
                maxStrength: '<',
                showsShortPasswordError: '<'
            }
        });

    function controller($mdMedia) {

        const vm = this;
        vm.colorClass = 'md-warn';
        vm.text = 'Very Weak';
        vm.maxStrength = vm.maxStrength ? vm.maxStrength : 4;

        vm.$onChanges = function (changes) {

            const hideStrengthText = vm.showsShortPasswordError && !$mdMedia('gt-sm');
            if (changes.passwordStrength && changes.passwordStrength.currentValue && !hideStrengthText) {
                vm.strengthPercentage = (changes.passwordStrength.currentValue.score / vm.maxStrength) * 100;
                if (vm.strengthPercentage === 0) {
                    vm.colorClass = 'weak';
                    vm.text = 'Very Weak';
                }
                else if (vm.strengthPercentage <= 40) {
                    vm.colorClass = 'weak';
                    vm.text = 'Weak';
                }
                else if (vm.strengthPercentage <= 60) {
                    vm.colorClass = 'moderate';
                    vm.text = 'Moderate';
                }
                else if (vm.strengthPercentage <= 80) {
                    vm.colorClass = 'strong';
                    vm.text = 'Strong';
                }
                else {
                    vm.colorClass = 'strong';
                    vm.text = 'Very Strong';
                }

            }

            if (hideStrengthText) {
                vm.text = ' ';
            }
        };
    }
}());
