(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('TeamSettingsController', TeamSettingsController);

    function TeamSettingsController($scope, $mdDialog, $state, utils, model, team, groups, group, user, billing, permissionService, pipelines) {

        $scope.model = model;
        $scope.team = null;
        $scope.groups = null;
        $scope.draggedUser = null;
        $scope.loading = true;

        let subscriptionCanceledAtPeriodEnd = false;

        $scope.canAccessPermissions = function () {

            // Check if model.me is here
            // When logging out change detection can still trigger this causing a lot of console errors
            return model.me && model.me.plan_flags.permissions;
        };

        $scope.$on('refreshData', function () {

            return $scope.get();
        });

        $scope.get = function get() {

            if (!$scope.billing) {
                $scope.loading = true;
            }

            pipelines.get().then(function (response) {

                $scope.pipelines = response.data.map(function (pipeline) {

                    return { id: pipeline.id, name: pipeline.name };
                });
            });

            return team.get().then(function (response) {

                $scope.team = response.data;
                $scope.team.add_all_users_to_accounts = !!$scope.team.add_all_users_to_accounts;

                return permissionService.getAllRoles().then(function (rolesResponse) {

                    $scope.roles = rolesResponse.data;

                    return groups.get().then((groupsResponse) => {

                        // We visually add all disabled users to a separate group
                        const disableUserGroup = groupsResponse.data.reduce((userGroup, groupObject) => {

                            const disabledUsers = groupObject.users.filter((u) => u.disabled);

                            if (disabledUsers.length > 0) {
                                userGroup.users.push(...disabledUsers.map((u) => {

                                    const userCopy = angular.copy(u);
                                    userCopy.originalGroup = groupObject.name;

                                    return userCopy;
                                }));
                            }

                            return userGroup;
                        }, { name: 'Disabled users', users: [], disabledUsers: true, hide: true });

                        // Remove disabled users from the normal groups
                        groupsResponse.data.forEach(function (groupObject) {

                            if (groupObject.users) {
                                groupObject.users = groupObject.users.filter((u) => !u.disabled);
                            }
                        });

                        $scope.groups = [...groupsResponse.data, disableUserGroup];

                        if (model.me.team.payment_type === 'stripe') {
                            return billing.get().then(function (billingResponse) {

                                subscriptionCanceledAtPeriodEnd = billingResponse.data.cancel_at_period_end;
                                $scope.loading = false;
                            });
                        }

                        $scope.loading = false;
                    });
                });
            });
        };

        $scope.invite = function invite() {

            if (model.me.team.discount_code && model.me.team.payment_type !== 'free' && model.me.team.discount_type === 'firstFreeThen50Off' && !model.me.team.subscribed && !(($scope.team.enabled_user_count + $scope.team.invites.length) < $scope.team.free_users + 1)) {
                let dialogHtmlContent = '<p>';

                if (model.me.team.free_users > 0) {
                    dialogHtmlContent += 'You\'re at your limit of ' + (model.me.team.free_users + 1) + ' free users. ';
                }

                dialogHtmlContent += 'Thanks to ';

                if (model.me.team.discount_code.lastIndexOf('SUMO', 0) === 0) {
                    dialogHtmlContent += 'Appsumo';
                }
                else {
                    dialogHtmlContent += model.me.team.discount_code;
                }

                dialogHtmlContent += ', you can add as many team members as you want at 50% discount! <br>Just hit subscribe and enter your credit card details.</p>';

                const confirm = $mdDialog.confirm()
                    .title('Let\'s get your team on Salesflare')
                    .clickOutsideToClose(true)
                    .htmlContent(dialogHtmlContent)
                    .ok('Subscribe')
                    .cancel('Cancel');

                return $mdDialog.show(confirm).then(function () {

                    return $state.go('checkout', { plan: utils.getPlanNameBySalesflarePlanId(model.me.team.plan) });
                });
            }

            return $mdDialog.show({
                clickOutsideToClose: true,
                controller: 'InviteDialogController',
                templateUrl: 'partials/invitedialog.html',
                multiple: true,
                onComplete: function (scope, element) {
                    element['0'].querySelectorAll('INPUT')[0].focus();
                },
                locals: {
                    roles: $scope.roles,
                    groups: $scope.groups.filter((groupObject) => !groupObject.disabledUsers)
                },
                bindToController: true
            // eslint-disable-next-line no-shadow
            }).then(function (invite) {

                return team.addInvite(invite).then(function () {

                    $scope.$broadcast('refreshData');
                });
            });
        };

        $scope.resendInvite = function (inviteId) {

            return team.resendInvite(inviteId).then(function () {

                utils.showSuccessToast('The email has been resent');
            });
        };

        $scope.deleteInvite = function (inviteId) {

            return team.deleteInvite(inviteId).then(function () {

                return $scope.get();
            });
        };

        $scope.createGroup = function () {

            return $mdDialog.show({
                clickOutsideToClose: true,
                controller: 'EditGroupDialogController',
                templateUrl: 'partials/editgroupdialog.html',
                locals: {
                    pipelines: $scope.pipelines
                },
                bindToController: true,
                onComplete: function (scope, element) {

                    return element['0'].querySelectorAll('INPUT')[0].focus();
                }
            }).then(function (newGroup) {

                return group.create({
                    name: newGroup.name,
                    pipelines: newGroup.pipelines.map(function (newGroupPipeline) {

                        return newGroupPipeline.id;
                    })
                }).then(function () {

                    return $scope.get();
                });
            });
        };

        $scope.updateGroup = function (groupObject, event) {

            if (groupObject.id) {
                event.stopPropagation();

                return $mdDialog.show({
                    clickOutsideToClose: true,
                    controller: 'EditGroupDialogController',
                    templateUrl: 'partials/editgroupdialog.html',
                    bindToController: true,
                    locals: {
                        group: groupObject,
                        pipelines: $scope.pipelines
                    },
                    onComplete: function (scope, element) {

                        return element['0'].querySelectorAll('INPUT')[0].focus();
                    }
                }).then(function (updatedGroupObject) {

                    return group.update(groupObject.id, {
                        name: updatedGroupObject.name,
                        pipelines: updatedGroupObject.pipelines.map(function (updatedGroupPipeline) {

                            return updatedGroupPipeline.id;
                        })
                    }).then(function () {

                        return $scope.get();
                    });
                });
            }
        };

        $scope.deleteGroup = function (item, event) {

            event.stopPropagation();

            const confirm = $mdDialog.confirm({ multiple: true })
                .clickOutsideToClose(true)
                .textContent('Are you sure you want to delete this group?')
                .ok('Yes')
                .cancel('No');

            return $mdDialog.show(confirm).then(function () {

                return group.delete(item).then($scope.get);
            });
        };

        $scope.disableUser = function (event, userId, disabled) {

            const button = event.currentTarget;
            button.disabled = true;

            if (!disabled && model.me.team.payment_type !== 'free' && model.me.team.discount_code && model.me.team.discount_type === 'firstFreeThen50Off' && !model.me.team.subscribed && !(($scope.team.enabled_user_count + $scope.team.invites.length) < $scope.team.free_users + 1)) {
                let dialogHtmlContent = '<p>';

                if (model.me.team.free_users > 0) {
                    dialogHtmlContent += 'You\'re at your limit of ' + (model.me.team.free_users + 1) + ' free users. ';
                }

                dialogHtmlContent += 'Thanks to ';

                if (model.me.team.discount_code.lastIndexOf('SUMO', 0) === 0) {
                    dialogHtmlContent += 'Appsumo';
                }
                else {
                    dialogHtmlContent += model.me.team.discount_code;
                }

                dialogHtmlContent += ', you can add as many team members as you want at 50% discount! <br>Just hit subscribe and enter your credit card details.</p>';

                button.disabled = false;

                const createSubscriptionConfirm = $mdDialog.confirm()
                    .title('Let\'s get your team on Salesflare')
                    .clickOutsideToClose(true)
                    .htmlContent(dialogHtmlContent)
                    .ok('Subscribe')
                    .cancel('Cancel');

                return $mdDialog.show(createSubscriptionConfirm).then(function () {
                    return $state.go('checkout');
                });
            }

            if (subscriptionCanceledAtPeriodEnd) {
                const confirm = $mdDialog.confirm()
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title('Are you sure?')
                    .textContent('Enabling or disabling users will re-instate your subscription.')
                    .ok('Yes')
                    .cancel('No');

                return $mdDialog.show(confirm).then(function () {

                    return user.disable(userId, { disabled }).then(() => {

                        setUserDisabledInGroups($scope.groups, userId, disabled);
                    }).finally(() => {

                        button.disabled = false;
                    });
                }, function () {

                    button.disabled = false;
                });
            }

            return user.disable(userId, { disabled }).then(() => {

                setUserDisabledInGroups($scope.groups, userId, disabled);
            }).finally(function () {

                button.disabled = false;
            });
        };

        /**
         * Enables/disables a user in memory
         * We want to keep the user in the same group so the user doesn't jump (from or to the 'Disabled users' group)
         * That's why we do it in mem instead of refetching the groups
         *
         * @param {Object[]} groupsArray
         * @param {Number} userId
         * @param {Boolean} disabled
         */
        function setUserDisabledInGroups(groupsArray, userId, disabled) {

            groupsArray.forEach((groupObject) => {

                const userObject = groupObject.users.find((u) => u.id === userId);

                if (userObject) {
                    userObject.disabled = disabled;
                }
            });
        }

        // eslint-disable-next-line no-shadow
        $scope.addOrRemoveAdmin = function (user) {

            if (user.is_admin) {
                return team.deleteAdmin(user.id).then(function () {

                    utils.showSuccessToast('The user\'s admin rights have been revoked.');
                    return $scope.get();
                });
            }

            team.createAdmin(user.id).then(function () {

                utils.showSuccessToast('The user now has admin rights!');
                return $scope.get();
            });
        };

        // eslint-disable-next-line no-shadow
        $scope.hideShow = function (group, $event) {

            if ($event.currentTarget.className.includes('md-sticky-clone')) {
                return;
            }

            if (group.hide) {
                group.hide = false;
            }
            else {
                group.hide = true;
            }
        };

        $scope.onDragStart = function (item) {

            $scope.draggedUser = item;
        };

        $scope.onDragStop = function () {

            $scope.draggedUser = null;
        };

        $scope.onGroupDropComplete = function (dragItem, dropItem) {

            $scope.draggedUser = null;

            //Add user to group
            //get groups
            if (dropItem.id) {
                return group.addUser(dropItem, dragItem).then(function () {

                    return $scope.get();
                });
            }

            return group.deleteUser({ id: 0 }, dragItem).then(function () {

                return $scope.get();
            });
        };

        $scope.onNameChange = function () {

            if (!$scope.team.name) {
                return;
            }

            team.update({ name: $scope.team.name }).then(function () {

                return utils.showSuccessToast('Team name has been saved');

            });
        };

        $scope.updateUserRole = function (userId, roleId) {

            return user.update(userId, { role: roleId }).then(function () {

                return utils.showSuccessToast('Role updated.');
            });
        };

        $scope.updateInvite = function (inviteId, update) {

            return team.updateInvite(inviteId, update).then(function () {

                return utils.showSuccessToast(update.role ? 'Role updated.' : 'Group updated.');
            });
        };

        $scope.getCommaSeparatedPipelineNames = function (groupPipelines) {

            if (groupPipelines.length === $scope.pipelines.length) {
                return 'All pipelines';
            }

            return groupPipelines.map(function (groupPipeline) {

                return groupPipeline.name;
            }).join(', ').replace(/, ([^,]*)$/, ' and $1');
        };

        $scope.onAddAllUsersToAccountsChange = function () {

            if ($scope.team.add_all_users_to_accounts) {
                return team.update({ add_all_users_to_accounts: false }).then(function () {

                    $scope.team.add_all_users_to_accounts = false;
                    return utils.showSuccessToast('Your team settings have been updated').catch(angular.noop);

                });
            }
            else {
                return $mdDialog.show({
                    template: ''
                        + '<md-dialog-content class="md-dialog-content">'
                        + '    <h2 class="md-title">Are you sure?</h2>'
                        + '    <div>All interactions (emails, meetings, phone calls, etc.) with accounts will be shared with all team members.</div>'
                        + '</md-dialog-content>'
                        + '<md-dialog-actions>'
                        + '    <md-button ng-click="hide()">Yes</md-button>'
                        + '    <md-button ng-click="cancel()">No</md-button>'
                        + '</md-dialog-actions>',
                    // eslint-disable-next-line no-shadow
                    controller: function ($scope, $mdDialog) {

                        $scope.hide = $mdDialog.hide;
                        $scope.cancel = $mdDialog.cancel;
                    },
                    clickOutsideToClose: true,
                    escapeToClose: true
                }).then(function () {

                    return team.update({ add_all_users_to_accounts: true }).then(function () {

                        $scope.team.add_all_users_to_accounts = true;
                        return utils.showSuccessToast('Your team settings have been updated').catch(angular.noop);
                    });
                },
                function () {

                    $scope.team.add_all_users_to_accounts = false;
                });
            }
        };

        $scope.get();
    }
})();
