<div class="configure-pipelines-container">
    <sfx-toolbar enableDivider>
        Configure pipelines
    </sfx-toolbar>

    <sfx-content>

        <div cdkDropList (cdkDropListDropped)="onDropPipeline($event)">
            <sfx-section
                    *ngFor="let pipeline of pipelines;"
                    data-layout="column"
                    disableContentPadding>
                <sfx-header cdkDrag
                            cdkDragBoundary=".configure-pipelines-container"
                            [cdkDragStartDelay]="media.hasTouch()?300:0"
                            (cdkDragStarted)="setIsDraggingPipeline(true)"
                            (cdkDragEnded)="setIsDraggingPipeline(false)">
                    <div class="placeholder pipeline-placeholder" *cdkDragPlaceholder></div>
                    <div class="grab" data-layout="row" data-flex>
                        <div class="pipeline-header-info" data-layout="row" data-flex data-layout-gap="4px">
                            <span>{{ pipeline.name }}</span>
                            <mat-icon *ngIf="pipeline.recurring" fontSet="salesflare" fontIcon="sf-icon-recurring"
                                      class="icon-20 recurring-pipeline-icon"></mat-icon>
                            <span *ngIf="canAccessMultipleCurrencies()" class="font-weight_normal text-color" [innerHTML]="(' - ' + (pipeline.currency?.html || currentUser.team.currency.html))"></span>
                            <span flex class="groups-text ellipsis font-weight_normal text-color"
                                  *ngIf="currentUser.plan_flags['permissions']">
                                {{ (' - ' + getCommaSeparatedGroupNames(pipeline.groups)) }}
                            </span>
                        </div>
                        <div class="pipeline-header-actions" data-layout="row" data-layout-gap="16px">
                            <button mat-icon-button class="button-20" disableRipple (click)="duplicatePipeline(pipeline)">
                                <mat-icon fontSet="salesflare" fontIcon="sf-icon-clone"
                                          class="icon-20 pipeline-header-icon-button"></mat-icon>
                            </button>

                            <button mat-icon-button class="button-20" disableRipple (click)="editPipeline(pipeline)">
                                <mat-icon fontSet="salesflare" fontIcon="sf-icon-edit"
                                          class="icon-20 pipeline-header-icon-button"></mat-icon>
                            </button>

                            <button mat-icon-button class="button-20" disableRipple (click)="deletePipeline(pipeline)">
                                <mat-icon fontSet="salesflare" fontIcon="sf-icon-delete"
                                          class="icon-20 pipeline-header-icon-button"></mat-icon>
                            </button>
                        </div>
                    </div>
                </sfx-header>

                <mat-list class="stages"
                          cdkDropList
                          (cdkDropListDropped)="onDropStage($event, pipeline)"
                          [hidden]="isDraggingPipeline">
                    <ng-container *ngFor="let stage of pipeline.stages; let i = index;">
                        <mat-list-item
                                cdkDrag
                                [cdkDragData]="pipeline"
                                [cdkDragStartDelay]="media.hasTouch()?300:0"
                                class="large stage">
                            <div class="placeholder stage-placeholder" *cdkDragPlaceholder></div>

                            <div data-layout="row" data-layout-align="center center" class="grab" data-flex>
                                <div class="stage-color-circle"
                                     [ngStyle]="{ 'background-color': 'rgb(' + stage.color + ')'}">
                                    <span>{{ i + 1 }}</span>
                                </div>
                                <div data-layout="column" data-flex>
                                    <h1 class="ellipsis accent">{{stage.name}}</h1>
                                    <h2>{{ stage.probability }}%</h2>
                                </div>
                                <div data-layout="row" data-layout-gap="6px">
                                    <button mat-icon-button class="button-40" (click)="updateStage(stage, pipeline)">
                                        <mat-icon fontSet="salesflare" fontIcon="sf-icon-edit"
                                                  class="blue"></mat-icon>
                                    </button>
                                    <button mat-icon-button class="button-40" [disabled]="!!stage.fixed_stage"
                                            (click)="deleteStage(stage)">
                                        <mat-icon fontSet="salesflare" fontIcon="sf-icon-delete"
                                                  class="blue"></mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </ng-container>
                </mat-list>
            </sfx-section>
        </div>
    </sfx-content>
</div>

<sfx-speed-dial class="fab-dial">
    <button speedDialAction icon="sf-icon-opportunities" label="Create stage" [action]="this.createStage"></button>
    <button speedDialAction icon="sf-icon-opportunities" label="Create pipeline" [action]="this.editPipeline"></button>
</sfx-speed-dial>
