import { Component } from '@angular/core';

import { PlanModel, PlansModel, PlanFeatureModel } from '@shared/models/plan.model';

import { DialogContent } from '@shared/components/dialog/dialog.component';

export interface DowngradePlanContentData {
    plans: PlansModel,
    currentPlan: PlanModel,
    futurePlan: PlanModel,
    onTrial: boolean
}

@Component({
    selector: 'sfx-billing-settings-downgrade-plan-content',
    template: `
        <h3 [hidden]="onTrial">Are you sure you want to downgrade from the {{ currentPlan.name }} plan?</h3>
        <h3 [hidden]="!onTrial">Are you sure you want to subscribe to the {{ futurePlan.name }} plan?</h3>
        <div class="text">
            <p>You will lose access to the following features on your {{ onTrial ? 'trial' : 'current plan' }}:</p>
            <ng-container *ngFor="let feature of lostFeatures">
                <div (mouseenter)="feature.showTooltip = true" (mouseleave)="feature.showTooltip = false">
                    <mat-icon fontSet="salesflare" fontIcon="report" class="icon-18"></mat-icon>
                    <p class="attention">{{ feature.name }}
                        <mat-icon *ngIf="feature.downgradeDescription" fontSet="salesflare" fontIcon="info" aria-label="info" class="icon-16 inline-icon failed info"
                                  [matTooltip]="feature.downgradeDescription" matTooltipPosition="below" matTooltipClass="plan-tooltip" [matTooltipDisabled]="!feature.showTooltip">
                        >
                        </mat-icon>
                    </p>
                </div>
            </ng-container>
        </div>
    `
})
export class DowngradePlanContentComponent implements DialogContent<DowngradePlanContentData> {

    public valid: true;

    public plans: PlansModel = {};
    public currentPlan: PlanModel;
    public futurePlan: PlanModel;

    public onTrial: boolean;

    public lostFeatures: PlanFeatureModel[] = [];

    constructor(
    ) {}

    setInitialState(context: DowngradePlanContentData) {

        this.plans = context.plans;
        this.currentPlan = context.currentPlan;
        this.futurePlan = context.futurePlan;

        this.onTrial = context.onTrial;

        this.prepareLostFeatures();
    }

    getCurrentState(): DowngradePlanContentData {

        return {
            plans: this.plans,
            currentPlan: this.currentPlan,
            futurePlan: this.futurePlan,
            onTrial: this.onTrial
        };
    }

    prepareLostFeatures(): void {

        Object.entries(this.plans).forEach(([planName, plan]) => {

            switch (planName) {
                case ('pro'):
                    if ((this.currentPlan.id >= 4) && (this.futurePlan.id < 4)) {
                        this.lostFeatures = [...this.lostFeatures, ...plan.features];
                    }

                    break;
                case ('enterprise'):
                    if ((this.currentPlan.id >= 5) && (this.futurePlan.id < 5)) {
                        this.lostFeatures = [...this.lostFeatures, ...plan.features];
                    }

                    break;
                default:
                    break;
            }
        });


    }
}
