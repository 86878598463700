(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('meeting', meetingService);

    function meetingService(sfHttp, config) {

        this.get = function (id, ignoreLoadingBar) {

            const request = {};

            if (ignoreLoadingBar) {
                request.ignoreLoadingBar = true;
            }

            return sfHttp.get(config.apiUrl + 'meetings/' + id, request);
        };

        this.create = function (meeting) {

            return sfHttp.post(config.apiUrl + 'meetings', meeting);
        };

        this.update = function (meeting) {

            return sfHttp.put(config.apiUrl + 'meetings/' + meeting.id, meeting);
        };

        this.delete = function (meeting) {

            return sfHttp.delete(config.apiUrl + 'meetings/' + meeting.id);
        };
    }
})();
