import {
    Directive, ElementRef,
    Injector,
    Input, Output
} from '@angular/core';

import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    standalone: true,
    selector: 'sfFilters'
})
export class SfFiltersComponentWrapper extends UpgradeComponent {
    // The names of the input and output properties here must match the names of the
    // `<` and `&` bindings in the AngularJS component that is being wrapped
    @Input() filters: any[];
    @Input() pipeline: any;
    @Input() allPipelines: any;
    @Input() hideToolbar: any;
    @Input() hideSavedFilters: any;
    @Input() entity: any;
    @Input() group: any;
    @Input() filterHeaderText: any;
    @Input() filterFields: any;

    @Output() onFilterApply: any;
    @Output() onClose: any;
    @Output() getDefaultFilters: any;
    @Output() setErrorMessage: any;

    constructor(elementRef: ElementRef, injector: Injector) {
        // We must pass the name of the directive as used by AngularJS to the super
        super('sfFilters', elementRef, injector);
    }
}
