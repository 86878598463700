(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('cities', citiesService);

    function citiesService(sfHttp, config) {

        this.get = function (search) {

            const request = {
                params: {}
            };

            if (search) {
                request.ignoreLoadingBar = true;
                request.params.search = search === '' ? undefined : '*' + search + '*';
            }

            return sfHttp.get(config.apiUrl + 'cities', request);
        };
    }
})();
