(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('BillingSettingsInvoiceController', BillingSettingsInvoiceController);

    function BillingSettingsInvoiceController($rootScope, $scope, billing, utils) {

        if ($rootScope.history[$rootScope.history.length - 1].fromState.name.includes('billingSettings.')) {
            $rootScope.history.pop();
        }

        $scope.processing = false;
        $scope.billingAddress = {};
        $scope.VAT = {
            validatingNumber: false,
            validNumber: false
        };

        let teamAlreadyHasABillingAddress = false;

        init();

        $scope.save = function () {

            $scope.processing = true;

            if (teamAlreadyHasABillingAddress) {
                return billing.updateBillingAddress($scope.billingAddress)
                    .then(function () {

                        $scope.processing = false;

                        billing.getBillingAddress().then(billingAddressResponse);

                        return utils.showSuccessToast('Your invoicing details have been updated successfully.');
                    })
                    .catch(function () {

                        $scope.processing = false;
                    });
            }

            return billing.createBillingAddress($scope.billingAddress)
                .then(function () {

                    $scope.processing = false;

                    billing.getBillingAddress().then(billingAddressResponse);

                    return utils.showSuccessToast('Your invoicing details have been updated successfully.');
                })
                .catch(function () {

                    $scope.processing = false;
                });
        };

        $scope.onVATChange = function () {

            $scope.invoiceForm.vat.$setValidity('valid', true);
            $scope.invoiceForm.vat.$setValidity('unknown', true);

            $scope.VAT.validatingNumber = true;
            $scope.VAT.validNumber = false;

            if (!$scope.billingAddress.vat) {
                $scope.VAT.validatingNumber = false;
                $scope.VAT.validNumber = false;
                return;
            }

            const country = $scope.billingAddress.vat.slice(0, 2);
            const number = $scope.billingAddress.vat.slice(2);

            return billing.validateVAT(country, number, { noToast: true })
                .then(function (response) {

                    $scope.VAT.validatingNumber = false;
                    $scope.VAT.validNumber = response.data.valid;
                })
                .catch(function (err) {

                    $scope.VAT.validatingNumber = false;
                    $scope.VAT.validNumber = false;

                    if (err.status >= 400 && err.status !== 503) {
                        $scope.invoiceForm.vat.$setValidity('valid', false);
                    }
                    else if (err.status === 503) {
                        $scope.invoiceForm.vat.$setValidity('unknown', false);
                    }
                });
        };

        function init() {

            return billing.getBillingAddress().then(billingAddressResponse, null, billingAddressResponse);
        }

        function billingAddressResponse(response) {

            if (response.data) {
                $scope.billingAddress = {
                    country: response.data.country,
                    company_name: response.data.company_name,
                    street: response.data.street,
                    city: response.data.city,
                    region: response.data.region,
                    zip: response.data.zip,
                    vat: response.data.vat,
                    notes: response.data.notes,
                    email: response.data.email
                };

                $scope.VAT.validNumber = response.data.vat_validated;

                teamAlreadyHasABillingAddress = true;
            }
        }
    }
})();
