(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('RestrictedDialogController', RestrictedDialogController);

    function RestrictedDialogController($scope, $mdDialog, $state, $sce, $timeout, $compile, email, model, flagsService) {

        // Compile the div that contains the extra info
        // This is needed to make sure angular bindings work
        // e.g. ng-clicks
        if (model.me.restricted_reason === 'CUSTOMER_ACCOUNT_DELETED') {
            $timeout(function () {

                // Taken from Stack Overflow but lost the link :/
                // Yes the -9999 is important
                return $compile(angular.element('#restricted-dialog .text>div'), null, -9999)($scope);
            }, 0);
        }

        $scope.model = model;
        $scope.textmap = {
            'TRIAL_EXPIRED': {
                true: {
                    title: 'Uh-oh! Your Salesflare free trial just ended.',
                    text: $sce.trustAsHtml('<p>'
                            + 'Subscribe below to continue working. You\'ll keep:'
                            + '<ul>'
                            + '    <li>Your fully automatic address book and to do list.</li>'
                            + '    <li>Access to the Salesflare apps, plugins and integrations.</li>'
                            + '    <li>All premium Salesflare intelligence features.</li>'
                            + '</ul>'
                        + '</p>'),
                    button: 'Subscribe'
                },
                false: {
                    title: 'Uh-oh! Your Salesflare free trial just ended.',
                    text: $sce.trustAsHtml('<p>'
                            + 'To continue working, ask your administrator to subscribe. You\'ll keep:'
                            + '<ul>'
                            + '    <li>Your fully automatic address book and to do list.</li>'
                            + '    <li>Access to the Salesflare apps, plugins and integrations.</li>'
                            + '    <li>All premium Salesflare intelligence features.</li>'
                            + '</ul>'
                        + '</p>')
                }
            },
            'STRIPE_SUBSCRIPTION_CANCELED': {
                true: {
                    title: 'Uh-oh! Your payment keeps failing',
                    text: $sce.trustAsHtml('<p>'
                            + 'Update your credit card information to keep using Salesflare. You might need to contact your bank to resolve the issue.'
                        + '</p>'),
                    button: 'Fix payment'
                },
                false: {
                    title: 'Uh-oh! Your team\'s payment keeps failing',
                    text: $sce.trustAsHtml('<p>'
                            + 'To keep using Salesflare, ask your administrator to update the credit card information and/or to check with the bank to resolve the issue.'
                        + '</p>')
                }
            },
            'CUSTOMER_ACCOUNT_DELETED': {
                true: {
                    title: 'Uh-oh! Your payment account has been deleted',
                    text: $sce.trustAsHtml('<p>'
                            + 'Contact support at <a ng-click="mailto(\'support@salesflare.com\', $event)">support@salesflare.com</a> to resolve the issue.'
                        + '</p>')
                },
                false: {
                    title: 'Uh-oh! Your payment account has been deleted',
                    text: $sce.trustAsHtml('<p>'
                            + 'Contact support at <a ng-click="mailto(\'support@salesflare.com\', $event)">support@salesflare.com</a> to resolve the issue.'
                        + '</p>')
                }
            },
            'BRIEFCASE_CANCELED': {
                true: {
                    title: 'Uh-oh! You canceled your Briefcase account',
                    text: $sce.trustAsHtml('<p>'
                            + 'To keep using Salesflare, you can subscribe to one of our plans.'
                        + '</p>'),
                    button: 'Subscribe'
                },
                false: {
                    title: 'Uh-oh! You canceled your Briefcase account',
                    text: $sce.trustAsHtml('<p>'
                            + 'To keep using Salesflare, ask your administrator to subscribe to one of our plans.'
                        + '</p>')
                }
            },
            'SCA_REQUIRED': {
                true: {
                    title: 'Uh-oh! Extra authentication required',
                    text: $sce.trustAsHtml('<p>'
                            + 'Because of European regulation to protect consumers, your bank requires extra authentication on your latest payment.'
                        + '</p>'),
                    button: 'AUTHENTICATE',
                    buttonGoTo: 'settings.billingSettings.overview'
                },
                false: {
                    title: 'Uh-oh! Extra authentication required',
                    text: $sce.trustAsHtml('<p>'
                            + 'Because of European regulation to protect consumers, your bank requires extra authentication on your latest payment. Ask your administrator to do this in Settings > Billing.'
                        + '</p>')
                }
            },
            'PAYMENT_FAILED': {
                true: {
                    title: 'Uh-oh! Your payment failed',
                    text: $sce.trustAsHtml('<p>'
                            + 'Update your credit card information to keep using Salesflare. You might need to contact your bank to resolve the issue.'
                        + '</p>'),
                    button: 'FIX PAYMENT',
                    buttonGoTo: 'settings.billingSettings.overview'
                },
                false: {
                    title: 'Uh-oh! your payment failed',
                    text: $sce.trustAsHtml('<p>'
                            + 'To keep using Salesflare, ask your administrator to update the credit card information and/or to check with the bank to resolve the issue.'
                        + '</p>')
                }
            },
            'FREEMIUM_CANCELED': {
                true: {
                    title: 'Uh-oh! Salesflare\'s free plan is no longer available',
                    text: $sce.trustAsHtml('<p>'
                            + 'To keep using Salesflare, you can subscribe to one of our plans.'
                        + '</p>'),
                    button: 'Subscribe'
                },
                false: {
                    title: 'Uh-oh! Salesflare\'s free plan is no longer available',
                    text: $sce.trustAsHtml('<p>'
                            + 'To keep using Salesflare, you can subscribe to one of our plans.'
                        + '</p>')
                }
            },
            'APPSTACK_LICENSE_SUSPENDED': {
                true: {
                    title: 'Uh-oh! Your Salesflare license through Samsung AppStack was suspended.',
                    text: $sce.trustAsHtml('<p>'
                        + 'To keep using Salesflare, you can resume your Salesflare license in '
                        + '<a href="https://www.samsung.com/us/business/account/login/" target="_blank" rel="noopener">your Samsung Business account</a>.'
                        + '</p>')
                },
                false: {
                    title: 'Uh-oh! Your Salesflare license through Samsung AppStack was suspended.',
                    text: $sce.trustAsHtml('<p>'
                        + 'To keep using Salesflare, you can resume your Salesflare license in '
                        + '<a href="https://www.samsung.com/us/business/account/login/" target="_blank" rel="noopener">your Samsung Business account</a>.'
                        + '</p>')
                }
            },
            'APPSTACK_LICENSE_CANCELED': {
                true: {
                    title: 'Uh-oh! Your Salesflare license through Samsung AppStack was canceled.',
                    text: $sce.trustAsHtml('<p>'
                        + 'Please <a id="intercom-chat-button" ng-click="mailto(\'support@salesflare.com\', $event)">contact support</a> to resolve the issue.'
                        + '</p>')
                },
                false: {
                    title: 'Uh-oh! Your Salesflare license through Samsung AppStack was canceled.',
                    text: $sce.trustAsHtml('<p>'
                        + 'Please <a id="intercom-chat-button" ng-click="mailto(\'support@salesflare.com\', $event)">contact support</a> to resolve the issue.'
                        + '</p>')
                }
            }
        };

        $scope.extend = function () {

            return $mdDialog.show({
                escapeToClose: false,
                clickOutsideToClose: false,
                templateUrl: 'partials/extendtrialdialog.html',
                controller: 'ExtendTrialDialogController'
            });
        };

        $scope.getRich = function () {

            return $mdDialog.hide().then(function () {

                return $state.go($scope.textmap[model.me.restricted_reason][!!model.me.is_admin].buttonGoTo || 'plans');
            });
        };

        $scope.canExtendTrial = function () {

            if (flagsService.get('gamifiedTrial') === false) {
                return !model.me.trial_extended;
            }

            return !model.me.trial_extended && model.me.amount_of_trial_days_earned <= 21;
        };

        $scope.mailto = email.mailto;
    }
})();
