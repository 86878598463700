import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'sfx-enable-two-f-a-dialog',
    templateUrl: './enable-two-f-a-dialog.component.html',
    styleUrls: ['./enable-two-f-a-dialog.component.scss']
})
export class SfxEnableTwoFADialog {
    @ViewChild('verificationCodeForm') verificationCodeForm!: FormGroup;
    verificationCode = '';
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { secret: string, qrUrl: string },
        @Inject('userService') private userService: any,
        public dialogRef: MatDialogRef<SfxEnableTwoFADialog>
    ) {}

    public enable = () => {

        return this.userService.enableTwoFA(this.verificationCode).then((response: any) => {

            if (response) {
                return this.dialogRef.close('enabled');
            }

            return this.dialogRef.close('disabled');

        }).catch((err: HttpErrorResponse) => {

            if (err.status === 400) {
                this.verificationCodeForm?.controls.verificationCode.setErrors({ 'invalidCode': true });
            }
        });
    };

    public close = () => {

        this.dialogRef.close();
    };
}
