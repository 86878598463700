(function () {
    'use strict';

    angular
        .module('salesflare.components.upgradeConfirmationDialog', [])
        .factory('sfUpgradeConfirmationDialog', sfUpgradeConfirmationDialog);

    function sfUpgradeConfirmationDialog($mdDialog) {

        return {
            show
        };

        function show(upgradePlan) {

            return $mdDialog.show({
                clickOutsideToClose: false,
                escapeToClose: false,
                multiple: true,
                hasBackdrop: true,
                controller: 'UpgradeConfirmationDialogController',
                controllerAs: 'vm',
                templateUrl: 'app-ajs/components/upgradeconfirmationdialog/upgradeConfirmationDialog.html',
                locals: {
                    upgradePlan
                },
                bindToController: true
            });
        }
    }
})();
