(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('user', userService);

    function userService(sfHttp, config) {

        this.get = function (id, options) {

            return sfHttp.get(config.apiUrl + 'users/' + id, {
                cache: true,
                noToast: options && options.noToast,
                noToastForStatusCode: options && options.noToastForStatusCode
            });
        };

        this.update = function (id, update) {

            return sfHttp.put(config.apiUrl + 'users/' + id, update);
        };

        this.disable = function (id, disabled) {

            return sfHttp.put(config.apiUrl + 'users/' + id + '/disable', disabled);
        };

        this.getTwoFA = function () {

            return sfHttp.get(config.apiUrl + '2fa');
        };

        this.enableTwoFA = function (verificationCode) {

            return sfHttp.post(config.apiUrl + '2fa', { token: verificationCode }, { noToast: true, noToastForStatusCode: [400] });
        };

        this.disableTwoFA = () => {

            return sfHttp.delete(`${config.apiUrl}2fa`);
        };

        this.getRecoveryCodes = function () {

            return sfHttp.get(config.apiUrl + '2fa/recovery');
        };

        this.regenerateRecoveryCodes = function () {

            return sfHttp.post(config.apiUrl + '2fa/recovery');
        };
    }
})();
