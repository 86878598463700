(function () {
    'use strict';

    angular
        .module('salesflare')
        .config(function ($mdDialogProvider) {

            $mdDialogProvider.addPreset('referAFriendDialog', {
                // @ngInject
                options: function () {
                    return {
                        templateUrl: 'app-ajs/components/referralprogram/referAFriendDialog/referAFriendDialog.html',
                        controllerAs: 'vm',
                        controller: referAFriendDialogController,
                        bindToController: true,
                        clickOutsideToClose: false,
                        escapeToClose: false,
                        multiple: true
                    };
                }
            });

            /*
             * Annotate the function so uglyfying (more specifically mangling) the file doesn't break it.
             * @ngInject
             */
            function referAFriendDialogController($mdDialog, model) {

                const vm = this;

                vm.me = model.me;

                vm.cancel = $mdDialog.cancel;
            }
        });
})();
