(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('AddContactToWorkflowDialogController', AddContactToWorkflowDialogController);

    function AddContactToWorkflowDialogController($mdDialog, $mdMedia, $scope, $timeout, workflowsService) {

        const vm = this;

        let thread = null;

        vm.search = '';
        vm.doneLoading = false;
        vm.$mdMedia = $mdMedia;

        vm.workflows = {
            toLoad: 0,
            numLoaded: 0,
            items: [],

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + 20;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad < index) {
                    this.toLoad += 20;

                    const bound = angular.bind(this, workflowsResponse);
                    const workflowsFilter = {
                        filters: {
                            condition: 'AND',
                            rules: [
                                {
                                    condition: 'OR',
                                    rules: [
                                        {
                                            id: 'workflow.status',
                                            value: [5], // Draft
                                            operator: 'equal'
                                        },
                                        {
                                            condition: 'AND',
                                            rules: [
                                                {
                                                    id: 'workflow.type',
                                                    value: [2], // Continuous
                                                    operator: 'equal'
                                                },
                                                {
                                                    condition: 'OR',
                                                    rules: [
                                                        {
                                                            id: 'workflow.status',
                                                            value: [1], // Live
                                                            operator: 'equal'
                                                        },
                                                        {
                                                            id: 'workflow.status',
                                                            value: [2], // Paused
                                                            operator: 'equal'
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    };

                    if (vm.contactId) {
                        workflowsFilter.filters.rules.push({
                            id: 'workflow.entered.id',
                            value: [vm.contactId],
                            operator: 'not_equal'
                        });
                    }

                    const workflowsGetRequestConfig = {
                        ...workflowsFilter,
                        search: vm.search,
                        limit: 20,
                        order_by: 'creation_date DESC'
                    };

                    if (this.items.length === 0) {
                        return workflowsService.get(workflowsGetRequestConfig, {}).then(bound, null, bound);
                    }

                    return workflowsService.get({ ...workflowsGetRequestConfig, offset: this.numLoaded }, {}).then(angular.bind(this, function (response) {

                        this.items = [...this.items, ...response.data];
                        this.numLoaded = this.toLoad;
                        vm.doneLoading = true;
                    }));
                }
            },

            // Forces reset of object
            reload: function () {

                this.toLoad = 0;
                this.numLoaded = 0;
                this.items = [];
                vm.doneLoading = false;
            }
        };

        get();

        vm.addContactToWorkflow = (workflow) => {

            if (vm.showConfirmationDialog) {
                const confirm = $mdDialog.confirm()
                    .clickOutsideToClose(true)
                    .escapeToClose(true)
                    .title('Are you sure?')
                    .htmlContent(`All the selected contacts will be added to the <b>${workflow.name}</b> workflow.`)
                    .multiple(true)
                    .ok('I\'m sure')
                    .cancel('cancel');

                return $mdDialog.show(confirm).then(() => {
                    $mdDialog.hide({ workflow });
                });
            }

            return $mdDialog.hide({ workflow });
        };

        vm.back = $mdDialog.cancel;

        function get() {

            return vm.workflows.reload();
        }

        function workflowsResponse(response) {

            this.items = response.data;
            this.numLoaded = this.toLoad;
            vm.doneLoading = true;
        }

        $scope.$watch('vm.search', function (newValue, oldValue) {

            if (newValue !== oldValue) {
                $timeout.cancel(thread);

                thread = $timeout(function () {

                    return get();
                }, 750);
            }
        });
    }
})();
