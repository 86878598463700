(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('ContextualHelpDialogController', ContextualHelpDialogController);

    function ContextualHelpDialogController($scope, $mdDialog, $mdMedia, $state, sfSetupPanel, flagsService) {

        $scope.$mdMedia = $mdMedia;
        $scope.isGamifiedTrialEnabled = (flagsService.get('gamifiedTrial') === true);

        const SEPARATOR = '.';
        // We use a dot notation to specify parent states
        // Some states have parent 'main' but should be linked to an entity, in that case we annotated them with a dash
        let tempName = $state.current.name.replaceAll('-', SEPARATOR);

        if (this.help[tempName]) {
            $scope.help = this.help[tempName];
        }
        else {
            do {
                tempName = tempName.slice(0, Math.max(0, tempName.lastIndexOf(SEPARATOR)));
                if (this.help[tempName]) {
                    $scope.help = this.help[tempName];
                    break;
                }
            }
            while (tempName.includes(SEPARATOR));
        }

        $scope.goToSetup = function () {

            $scope.close();

            if ($mdMedia('gt-sm')) {
                return sfSetupPanel.show();
            }

            return $state.go('setup');
        };

        $scope.goToReferralProgram = function () {

            $scope.close();

            return $state.go('settings.referralProgram');
        };

        $scope.close = function () {

            return $mdDialog.cancel();
        };
    }
})();
