import { Component } from '@angular/core';
import { DialogContent } from '@shared/components/dialog/dialog.component';

export interface DisableAutomatedTaggingContentData {
    deleteAutomatedTags: boolean
}

@Component({
    selector: 'sfx-manage-tags-disable-automated-tagging-content',
    template: `
        <p>
            Stop Salesflare from automatically adding tags to accounts.
        </p>
        <span>
            <mat-checkbox [(ngModel)]="deleteAutomatedTags">
                Remove tags where they've been added automatically as well.
            </mat-checkbox>
        </span>
    `
})
export class DisableAutomatedTaggingContentComponent implements DialogContent<DisableAutomatedTaggingContentData> {

    public deleteAutomatedTags = false;

    public valid: true;

    setInitialState(context: DisableAutomatedTaggingContentData) {

        this.deleteAutomatedTags = context.deleteAutomatedTags;
    }

    getCurrentState(): DisableAutomatedTaggingContentData {

        return {
            deleteAutomatedTags: this.deleteAutomatedTags
        };
    }
}
