(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('SettingsController', SettingsController);

    function SettingsController($scope, $state, $window, $mdMedia, $mdDialog, model, authentication) {

        $scope.me = model.me;
        $scope.onAndroid = ($window.device && $window.device.platform === 'Android');

        $scope.showPlanFlags = function () {

            // Check if model.me is here
            // When logging out change detection can still trigger this causing a lot of console errors
            const onTrial = model.me && model.me.team.payment_type !== 'free' && !model.me.team.subscribed && model.me.trial_expiry_date;
            return (onTrial || !model.me?.plan_flags.permissions);
        };

        $scope.logOut = function logOut() {

            if (model.isLoggedIn === true) {
                return authentication.logOut().finally(() => {

                    return $state.go('login');
                });
            }
        };

        $scope.go = function (url) {

            $window.open(url, '_blank', 'noopener');
            // eslint-disable-next-line no-useless-return
            return; // Otherwise we return window object
        };

        $scope.goToSetting = function (setting) {

            return $state.go('settings.' + setting);
        };

        $scope.$watch(function () {
            return $mdMedia('gt-sm');
        }, function (newValue, oldValue) {

            if (newValue && $state.is('settings')) {
                setStateFullscreen();
            }

            if (oldValue) {
                $mdDialog.hide(false);
            }
        });

        function setStateFullscreen() {

            if (model.me.role.name === 'Admin') {
                $scope.goToSetting('teamSettings');
            }
            else {
                $scope.goToSetting('permissionSettings');
            }
        }

        if ($mdMedia('gt-sm') && $state.is('settings')) {
            setStateFullscreen();
        }
    }
})();
