(function () {
    'use strict';

    angular
        .module('salesflare.components.opportunity', [])
        .component('sfOpportunity', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/opportunity/opportunity.html',
            bindings: {
                opportunityId: '<',
                pipeline: '<',
                onClose: '&'
            }
        });

    function controller($scope, $mdMedia, $state, $stateParams, $window, utils, opportunityService, model) {

        const vm = this;

        vm.$mdMedia = $mdMedia;
        vm.hidePipelineInformation = model.pipelines && model.pipelines.length === 1;
        vm.insufficientPermissionsMessage = 'You don’t have permission to edit this opportunity';

        vm.$onInit = function () {

            return get(true);
        };

        vm.goToAccount = function (account, event) {

            // Open in new tab when clicking on the shortcut icon
            if (event?.target?.classList.contains('open-account-in-new-tab-icon')) {
                const url = $state.href('accounts.account.feed', { id: account.id, name: account.name });
                $window.open(url, '_blank', 'noopener');
                return;
            }

            return $state.go('accounts.account.feed', { id: account.id, name: account.name });
        };

        vm.$onChanges = function (changes) {

            if (vm.opportunityId && (!changes.opportunityId || changes.opportunityId.currentValue !== changes.opportunityId.previousValue)) {
                return get(true);
            }
        };

        vm.back = function () {

            return vm.onClose();
        };

        vm.edit = function () {

            return $state.go('opportunity', { id: vm.opportunityId });
        };

        function get(resetShowAllStageUpdates) {

            vm.opportunityId = vm.opportunityId || $stateParams.id;

            if (!vm.opportunityId) {
                return vm.back();
            }

            // Collapse again when showing a different opportunity
            if (resetShowAllStageUpdates) {
                vm.showAllStageUpdates = false;
            }

            return opportunityService.get(vm.opportunityId).then(function (response) {

                vm.opportunity = response.data;

                if (!vm.opportunity.id) {
                    return vm.back();
                }

                return checkCloseDate();
            });
        }

        $scope.$on('refreshData', function () {

            return get(false);
        });

        function checkCloseDate() {

            vm.closeDateIsInPast = false;

            if ((vm.opportunity.stage.fixed_stage !== 1 && vm.opportunity.stage.fixed_stage !== 2) && vm.opportunity.stage.probability > 0 && vm.opportunity.stage.probability < 100 && new Date(vm.opportunity.close_date) < utils.getTodayAtMidnight()) {
                vm.closeDateIsInPast = true;
            }
        }
    }
}());
