(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('InviteDialogController', InviteDialogController);

    function InviteDialogController($scope, $mdDialog) {
        $scope.roles = this.roles;
        $scope.groups = this.groups;
        $scope.invite = {
            email: null,
            role: $scope.roles.find(function (role) {

                return role.name === 'Team member';
            }).id,
            group: null
        };

        $scope.save = function () {

            if ($scope.invite.email) {
                $mdDialog.hide($scope.invite);
            }
        };
    }
})();
