const { Carousel } = require('../utils/Carousel');

(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('CompanyController', CompanyController);

    function CompanyController($rootScope, $scope, $state, $stateParams, $mdSidenav, config, company, utils) {

        $scope.company_id = $stateParams.id;
        $scope.config = config;
        $scope.mode = config.mode;
        $scope.company = null;
        $scope.accounts = null;
        $scope.accountCarousel = null;

        if ($scope.selected) {

            //Because you need the ID in the parent scope you make an empty selected object in the parent and then set an ID to it in the child so it's accessible in the parent to set the active class to the selected item
            $scope.selected.id = $scope.company_id;
        }

        if ($stateParams.accounts) {
            $scope.accounts = angular.fromJson($stateParams.accounts);

            for (let i = 0; i < $scope.accounts.length; ++i) {
                if ($scope.accounts[i].id === $scope.company_id) {
                    $scope.accountCarousel = new Carousel($scope.accounts, i);
                }
            }
        }

        $scope.toggleRight = function () {

            return $mdSidenav('right').toggle();
        };

        $scope.prev = function () {

            const previousAccount = $scope.accountCarousel.previous;

            return $state.go(getInfoState(previousAccount), {
                id: previousAccount.id,
                accounts: angular.toJson($scope.accounts),
                name: previousAccount.name,
                privateCompanyInfo: $stateParams.privateCompanyInfo
            });
        };

        $scope.next = function () {

            const nextAccount = $scope.accountCarousel.next;

            return $state.go(getInfoState(nextAccount), {
                id: nextAccount.id,
                accounts: angular.toJson($scope.accounts),
                name: nextAccount.name,
                privateCompanyInfo: $stateParams.privateCompanyInfo
            });
        };

        // Set on scope to allow reload from child scopes
        $scope.get = function () {

            return company.get($scope.company_id).then(companyResponse, null, companyResponse);
        };

        function getInfoState(account) {
            return account.owner ? 'accounts.account.info' : 'accounts.company.info';
        }

        /*
         * When `company.info` is a child of `company` you can just access it via `$scope.company`
         * Since this is not always the case (fullscreen) we also broadcast a `loaded` event
         * so non-childs that need the account can update their local `$scope.company`
         */
        function companyResponse(response) {

            // Only merge private company info when domains match
            if (!$stateParams.privateCompanyInfo || $stateParams.privateCompanyInfo?.domain !== response.data.domain) {
                $scope.company = response.data;
                return $rootScope.$broadcast('company:loaded', $scope.company);
            }

            // If private company info was passed (from LinkedIn) merge it with our public info on that company
            // Give priority to the private info except for the 'Description' field
            const mergedCompanyData = angular.copy($stateParams.privateCompanyInfo);
            mergedCompanyData.description = response.data.description || mergedCompanyData.description;
            // Only add the private company address of the HQ if we have no address at all yet on the company record
            // Addresses from LinkedIn are often a bit differently formatted so we want to avoid dupes as much as possible
            mergedCompanyData.addresses = response.data.addresses?.length > 0 ? response.data.addresses : mergedCompanyData.addresses;
            mergedCompanyData.social_profiles = mergedCompanyData.social_profiles ? utils.unique([...mergedCompanyData.social_profiles, ...response.data.social_profiles], 'url') : response.data.social_profiles;
            mergedCompanyData.phone_numbers = mergedCompanyData.phone_numbers ? utils.unique([...mergedCompanyData.phone_numbers, ...response.data.phone_numbers], 'number') : response.data.phone_numbers;
            $scope.company = Object.assign({}, response.data, mergedCompanyData);

            return $rootScope.$broadcast('company:loaded', $scope.company);
        }

        $scope.get();
    }
})();
