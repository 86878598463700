(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('workflowsService', workflowsService);

    function workflowsService($q, $timeout, sfSetupPanel, sfHttp, config, filterService, model) {

        /**
         *
         * @param {Object} requestConfig
         * @param {String} requestConfig.search
         * @param {Object[]} requestConfig.filters - only accepts advanced filter rules
         * @param {Number} requestConfig.limit
         * @param {Number} requestConfig.offset
         * @param {String} requestConfig.orderBy
         * @param {Object} options
         * @param {Boolean} options.downloadCSV
         * @param {Boolean} options.downloadExcel
         * @param {Boolean} options.ignoreLoadingBar
         * @param {Boolean} options.returnCountOnly
         * @param {Boolean} options.includeCount
         * @returns {*}
         */
        this.get = function (requestConfig, options) {

            const filterUrl = config.apiUrl + 'workflows';

            const request = {
                downloadCSV: options.downloadCSV,
                downloadExcel: options.downloadExcel,
                params: {
                    q: {
                        condition: 'AND',
                        rules: filterService.cleanAdvancedFilterForHttp(requestConfig.filters) || []
                    }
                },
                noToast: true,
                noToastForStatusCode: [422]
            };

            if (requestConfig.search) {
                request.cache = false;
                request.ignoreLoadingBar = true;
                request.params.search = requestConfig.search === '' ? undefined : requestConfig.search;
            }

            if (requestConfig.limit) {
                request.params.limit = requestConfig.limit;
            }

            if (requestConfig.offset) {
                request.params.offset = requestConfig.offset;
            }

            if (requestConfig.orderBy) {
                request.params.order_by = requestConfig.orderBy;
            }

            if (options && options.ignoreLoadingBar) {
                request.ignoreLoadingBar = true;
            }

            if (options && options.returnCountOnly) {
                return sfHttp.head(filterUrl, request);
            }

            if (options && options.includeCount) {
                request.headers = { 'x-result-count': 'true' };
            }

            return sfHttp.get(filterUrl, request);
        };

        this.create = function (workflow) {

            const url = config.apiUrl + 'workflows';
            const workflowCopy = angular.copy(workflow);

            if (workflowCopy.filter && workflowCopy.filter.rules) {
                workflowCopy.filter.rules = filterService.cleanAdvancedFilterForHttp(workflowCopy.filter.rules);
            }

            if (workflowCopy.schedule_date) {
                workflowCopy.schedule_date = new Date(workflowCopy.schedule_date);
            }

            return sfHttp.post(url, workflowCopy).then(function (response) {

                if (model.me && model.me.team.subscribed) {
                    $timeout(sfSetupPanel.updateSetupSteps, 300);
                }

                return $q.resolve(response);
            });
        };

        this.getGoals = function () {

            const url = config.apiUrl + 'workflows/goals';

            return sfHttp.get(url);
        };

        this.getMergeFields = function () {

            // This is still on /campaigns for legacy reasons
            return sfHttp.get(config.apiUrl + 'campaigns/mergefields');
        };
    }
})();
