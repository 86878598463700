import { NgZone } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from "angular";

(function () {
    'use strict';

    angular
        .module('salesflare.downgraded.services.zoneService', [])
        .factory('zoneService', downgradeInjectable(NgZone));
})();
