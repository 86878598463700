(function () {
    'use strict';

    angular
        .module('salesflare')
        .service('notificationsService', notificationsService);

    function notificationsService(sfHttp, config) {

        /**
         *
         * @param {Object} requestConfig
         * @param {Object} requestConfig.filters
         * @param {Number} requestConfig.limit
         * @param {Number} requestConfig.offset
         * @param {Object} options
         * @param {Boolean} options.ignoreLoadingBar
         * @param {Boolean} options.returnCountOnly
         * @param {Boolean} options.includeCount
         * @returns {*}
         */
        this.get = function (requestConfig, options) {

            const url = config.apiUrl + 'notifications';
            const request = {
                cache: true,
                params: requestConfig && requestConfig.filters ? requestConfig.filters : {}
            };

            if (requestConfig.limit) {
                request.params.limit = requestConfig.limit;
            }

            if (requestConfig.offset) {
                request.params.offset = requestConfig.offset;
            }

            if (options && options.returnCountOnly) {
                return sfHttp.head(url, request);
            }

            if (options && options.includeCount) {
                request.headers = { 'x-result-count': 'true' };
            }

            return sfHttp.get(url, request);
        };

        this.update = function (notificationId, payload) {

            const url = config.apiUrl + 'notifications/' + notificationId;

            return sfHttp.put(url, payload);
        };

        this.delete = function (notificationId) {

            const url = config.apiUrl + 'notifications/' + notificationId;

            return sfHttp.delete(url);
        };
    }
})();
