(function () {
    'use strict';

    angular
        .module('salesflare.components.onLongPress', [])
        .directive('onLongPress', onLongPress);

    function onLongPress($timeout) {

        let timer;

        return {
            restrict: 'A',
            link: function ($scope, $elm, $attrs) {
                $elm.bind('touchstart', function () {
                    // Locally scoped variable that will keep track of the long press
                    $scope.longPress = true;

                    // We'll set a timeout for 600 ms for a long press
                    timer = $timeout(function () {
                        if ($scope.longPress) {
                            // If the touchend event hasn't fired,
                            // apply the function given in on the element's on-long-press attribute
                            $scope.$apply(function () {
                                $scope.$eval($attrs.onLongPress);
                            });
                        }
                    }, 500);
                });

                $elm.bind('touchend', function () {
                    // Prevent the onLongPress event from firing
                    $scope.longPress = false;

                    // Prevent on quick presses, unwanted onLongPress selection.
                    $timeout.cancel(timer);
                });
            }
        };
    }
})();
